import { toCurrency } from '@rps/js-utils/number';

export const resultRowsBasics = [
  {
    id: 'profit_potential',
    label: 'Profit Potential',
    key: 'result',
    transform: (data, row) => {
      return toCurrency(+row.profits?.potential || 0, 'ZAR', 'en-ZA');
    },
  },
  {
    id: 'weighted_average',
    label: 'Weighted Average',
    key: 'weighted',
    transform: (data, row) => {
      const average = toCurrency(row.weightedAverage, 'ZAR', 'en-ZA');
      return `${average} (${row.margins.wavg || 0}%)`;
    },
  },
  {
    id: 'average',
    label: 'Average',
    key: 'average',
    transform: (data, row) => {
      const average = toCurrency(row.average, 'ZAR', 'en-ZA');
      return `${average} (${row.margins.avg || 0}%)`;
    },
  },
  {
    id: 'savings',
    label: 'Customer Savings',
    key: 'savings',
    transform: (data, row) => {
      const savings = toCurrency(+row.profits?.csave || 0, 'ZAR', 'en-ZA');
      return `${savings} (${row.margins?.csave || 0}%)`;
    },
  },
];

export const resultRowProviders = [
  {
    id: 'eft',
    label: 'EFT',
    key: 'eft',
    transform: (data, row) => {
      const profit = toCurrency(+row.profits?.eft || 0, 'ZAR', 'en-ZA');
      return `${profit} (${row.margins?.eft || 0}%)`;
    },
  },
  {
    id: 'visamastercard',
    label: 'Credit Card',
    key: 'credit',
    transform: (data, row) => {
      const profit = toCurrency(
        +row.profits?.visamastercard || 0,
        'ZAR',
        'en-ZA'
      );
      return `${profit} (${row.margins?.visamastercard || 0}%)`;
    },
  },
  {
    id: 'ebucks',
    label: 'eBucks',
    key: 'ebucks',
    transform: (data, row) => {
      const profit = toCurrency(+row.profits?.ebucks || 0, 'ZAR', 'en-ZA');
      return `${profit} (${row.margins?.ebucks || 0}%)`;
    },
  },
  {
    id: 'discoverymiles',
    label: 'Discovery Miles',
    key: 'discoverymiles',
    transform: (data, row) => {
      const profit = toCurrency(
        +row.profits?.discoverymiles || 0,
        'ZAR',
        'en-ZA'
      );
      return `${profit} (${row.margins?.discoverymiles || 0}%)`;
    },
  },
  {
    id: 'snapscan',
    label: 'SnapScan',
    key: 'snapscan',
    transform: (data, row) => {
      const profit = toCurrency(+row.profits?.snapscan || 0, 'ZAR', 'en-ZA');
      return `${profit} (${row.margins?.snapscan || 0}%)`;
    },
  },
  {
    id: 'ucount',
    label: 'uCount',
    key: 'ucount',
    transform: (data, row) => {
      const profit = toCurrency(+row.profits?.ucount || 0, 'ZAR', 'en-ZA');
      return `${profit} (${row.margins?.ucount || 0}%)`;
    },
  },
  {
    id: 'zapper',
    label: 'Zapper',
    key: 'zapper',
    transform: (data, row) => {
      const profit = toCurrency(+row.profits?.zapper || 0, 'ZAR', 'en-ZA');
      return `${profit} (${row.margins?.zapper || 0}%)`;
    },
  },
];
