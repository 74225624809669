/**
 * @name rps-checkbox
 * @type {CheckBox}
 * A Checkbox component where the label can be on the left or the right.
 * 
 * 
 * @attributes + @properties 				|Changes trigger a re-render
 * Casing usages:
 * 	Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String} 	name 			| form name
 * 	@property {String} 	label 		| label to display
 * 	@property {Boolean} 	labelOnLeft	| label to display on the left or right of the checkbox
 * 	@property {Boolean} 	required 	| is input required
 * 	@property {Boolean} 	disabled 	| is this control disabled?
 * 	@property {Boolean} 	checked 		| is this checkbox checked
 * 	@property {Boolean} 	caching 		| does this control use Caching when reading and writing values
 * 	@property {String} 	customCss 	| Specify a custom .css file to include, use cssPath to override elements,
 * 													DO NOT override the styling definition by re-defining the css styling
 * 													Put this file into the *window.cssPath* folder @see {link README.md}
 * 													eg: /public/css (where the other css files are)
 * 													Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css			| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 
 * @methods
 * 	clear()									| Clear all the cached value used by this control (or any other control using the same name)
 * 	labelText(newLabelText)				| Change the label for this control
 * 
 * @properties (JS Only)
 * ====================
 * 	value (get and set)					| allow getters and setters for the value of the select.
 * 													eg: oControl.value = "newValue";
 * 													oe: console.log(oControl.value);
 * 	get isChecked							| Is this checkbox checked?
 * 	inputControl (get only)				| Return a reference to the input control
 * 	labelControl (get only)				| Return a reference to the label control
 * 
 * 
 * @events
 * 	input										| fires whenever checkbox is clicked
 * 
 * @callbacks
 * 	cbInput									| when the user input changes
 * 
 * @slots
 * No slots
 * 
 * @example @Lit
 * @basic
 * ======
 * import '@rps-wc/wc/rps-checkbox';
 * 
 * 	<rps-checkbox name="isOld" label="First name" required checked caching 
 * 		@input=${(e) => console.log('input', e)}
 * 	>
 * 	</rps-checkbox>
 * 
 * 
 * @example @ReactJS
 * @basic
 * =====
 * import '@rps-wc/wc/rps-checkbox';
 * 
 *  	useEffect(() => {
 *				input.current.cbInput = (e) => console.log(e);
 *  	}, [])
 * 
 * return (
 * 	<rps-checkbox name="isOld" label="First name" required ref={input} >
 * 	</rps-checkbox>
 * )
 * 
*/
import { CheckBox } from './src/input/CheckBox';
if (!window.customElements.get('rps-checkbox'))
	window.customElements.define('rps-checkbox', CheckBox);

