import { ValidationModel } from './base/ValidationModel.jsx';
import { isCategory } from '@odo/types/guards.ts';

export const isCategoryShop = cat =>
  isCategory(cat)
    ? cat?.breadcrumb && cat?.breadcrumb?.type === 'daily_shop'
    : cat?.type === 'daily_shop';

const ConditionsAndCategoryProperties = {
  isAlcoholic: false,
  isFragile: false,
  isHygienic: false,
  isParallelImport: false,
  isReferable: false,
  isSampleReceived: false,
  warrantyPeriod: null,
  additionalInfo: '',
  warranty: '',
  categories: [],
  isReturnableToSupplier: true,

  hasShop: false,
  clearanceSale: 'none',
};

const ConditionsAndCategoryValidators = {
  partial: {
    categoryChosen: {
      validator: x =>
        x.categories.filter(cat => !isCategoryShop(cat)).length > 0,
      error: 'You must choose at least one category',
    },
  },
  complete: {},
};

export class DealConditionsAndCategory extends ValidationModel {
  constructor() {
    super(ConditionsAndCategoryProperties, ConditionsAndCategoryValidators);

    this.isAlcoholic;
    this.isFragile;
    this.isHygienic;
    this.isParallelImport;
    this.isReferable;
    this.warrantyPeriod;
    this.additionalInfo;
    this.isReturnableToSupplier;
    this.categories;
    this.hasShop;
    this.clearanceSale;
    this.warranty;
    this.isSampleReceived;
  }
}

DealConditionsAndCategory.FIELDS = {
  IS_ALCOHOLIC: 'isAlcoholic',
  IS_FRAGILE: 'isFragile',
  IS_HYGIENIC: 'isHygienic',
  IS_PARALLEL_IMPORT: 'isParallelImport',
  IS_REFERABLE: 'isReferable',
  WARRANTY_PERIOD: 'warrantyPeriod',
  ADDITIONAL_INFO: 'additionalInfo',
  WARRANTY_COMMENTS: 'warranty',
  SUPPLIER_ACCEPTS_RETURNS: 'isReturnableToSupplier',
  CATEGORIES: 'categories',
  HAS_SHOP: 'hasShop',
  CLEARANCE_SALE: 'clearanceSale',
  IS_SAMPLE_RECEIVED: 'isSampleReceived',
};
