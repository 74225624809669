import { LitElement, html } from 'lit';


/**
 * This class is the base of all the other components in this library
 *
 * @export
 * @class CustomLitElement
 * @extends {LitElement}
 */
export class CustomLitElement extends LitElement {

	static get properties() {
		return {
			cssPath: { type: String },			// Base path where .css files are to be read from
			customCss: { type: String },		// Custom css file to reference
			css: { type: String },				// Custom css text to insert into the shadowDOM. convenient but adds extra css into app. use appropriately
			title: { type: String },			// title for control
			disabled: { type: Boolean },		// Is this control disabled?
		};
	}

	constructor() {
		super();
		this.cssPath = window.cssPath || '/css/';		// is the cssPath has been set on the windows object use it, otherwise use this default
		this.css = '';
		this.title ='';
		this.disabled = false;
	}


	/**
	 * Standard method to include all and optional css files
	 * main.css not needed as it will be pulled into the Page itself
	 *
	 * @param {boolean} [addShadow=false]
	 * @return {html}
	 * @memberof CustomLitElement
	 */
	addCss(addShadow = false) {
		const custom = this.customCss ? html`<link rel="stylesheet" href="${this.cssPath + this.customCss}"></link>` : '';
		const shadow = addShadow ? html`<link rel="stylesheet" href="${this.cssPath + 'shadow.css'}"></link>` : '';
		const cssText = this.css.length > 5 ? html`<style>${this.css}</style>` : '';

		return html`
			${custom}
			${shadow}
			${cssText}
		`;
	}

}



