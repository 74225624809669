import { queryCategoryBreadcrumbs } from '@odo/graphql/categories';
import type { ApiCategoryBreadcrumb } from '@odo/types/api';
import type { GetProductInterface } from '@odo/types/api-new';

export const loadBreadcrumbs = async ({
  categories,
  setLoading,
  onComplete,
  onError,
}: {
  categories: GetProductInterface['categories'];
  setLoading?: (status: boolean) => void;
  onComplete?: (breadcrumbs: ApiCategoryBreadcrumb[]) => void;
  onError?: () => void;
}) => {
  let breadcrumbs: ApiCategoryBreadcrumb[] | undefined;

  if (categories && (categories || []).length > 0) {
    try {
      setLoading && setLoading(true);

      const categoryIds: string[] = [];
      categories.forEach(
        ({ categoryId }) => !!categoryId && categoryIds.push(categoryId)
      );

      const categoryBreadcrumbs = await queryCategoryBreadcrumbs({
        categoryIds,
      });

      if (categoryBreadcrumbs) {
        breadcrumbs = categoryBreadcrumbs.breadcrumbs;
        onComplete && onComplete(breadcrumbs);
      }
    } catch (e) {
      onError && onError();
    } finally {
      setLoading && setLoading(false);
    }
  }

  return breadcrumbs;
};
