import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useUserDataSource } from 'hooks/userDataSource';

/**
 * Callback page used by Magento auth - should just close itself after setting the received auth tokens.
 */
export const LoginCheck = () => {
  const location = useLocation();
  const user = useUserDataSource();

  console.debug(`[LoginCheck] Received tokens:`, location.search);

  useEffect(() => {
    if (location.search) {
      console.debug(`[LoginCheck::useEffect] Setting auth tokens.`);
      user.tokens.setAuthTokens(location.search);

      window.close();
    }
  }, [location, user.tokens]);

  return (
    <div>
      <p>
        Login Check Page. This page should close on its own. If it doesn&apos;t,
        close it and try again if necessary.
      </p>
    </div>
  );
};
