import { html, css } from 'lit';
import { unsafeSVG } from 'lit/directives/unsafe-svg.js';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { DOM } from '../functions/DOM';		// workaround as SVG components cant have click handler
window.domFunction = DOM;

export class BarChart2d extends CustomLitElement {
	static styles = css`	
			:host {
				display: inline-block;
				width: max-content;
			}

			 .chart-container {
				 height: 100%;
			 }
		`;

	static get properties() {
		return {
			heading: { type: String },							// Heading for Chart
			data: { type: Array },								// Array of objects to display
			barColor: { type: String },						// Color for the bars
			hoverColor: { type: String },						// Color for the bars when the mouse hovers over them
			cbClick: { attribute: false },
		};
	}

	constructor() {
		super();
		this.heading = "A 2d Bar Chart with CSS3";
		this.data = [
			{ label: "Apples", value: 4, title: `<h3>Sales for 2008</h3>` },
			{ label: "Bananas", value: 8 },
			{ label: "Kiwis", value: 15, title: `<h3>Sales for 2010</h3>` },
			{ label: "Oranges", value: 16 },
			{ label: "Lemons", value: 23, title: `<h3>Sales for 2012</h3>` },
		];
		this.floating = undefined;		// store the floating elements reference here so CPU is not wasted.
		this.barColor = "#aaa";
		this.hoverColor = "red";

		this._convertToPercentage();
	}

	/**
	 * Process the Y-Axis values and data values. Add a property that is a % of the max amount.
	 * This % is needed to display the horizontal line on the chart.
	 * @private
	 * @memberof BarChart
	 */
	_convertToPercentage() {
		const max = Math.max(...this.data.map(e => e.value));

		let pos = 0;
		this.data.forEach(element => {
			// Store the element number as well.
			// This is passed back to the user when they click on the chart, so the developer knows what item was clicked on
			element.pos = pos;
			element.percentage = (element.value / max * 90).toFixed(0);	// leave some space on right for text for biggest item
			pos++;
		});
	}

	connectedCallback() {
		const oneBar = this.offsetHeight / this.data.length;
		this.barHeight = (oneBar / this.offsetHeight * 100).toFixed(2);

		super.connectedCallback();
	}


	/**
	 * Allows the changing of the data values after the chart has been rendered.
	 *
	 * @param {Array} newData
	 * @memberof BarChart
	 */
	setData(newData) {
		this.data = newData;
		this._convertToPercentage();
		this.requestUpdate();
	}

	/**
	 * Fire an event when an item is clicked on the chart.
	 * Returns the value and position of the chart value that was clicked
	 * @description - This method cant be called from itself.
	 * For some strange reason SVG elements cant have the normal event handlers in web-components
	 * Workaround is to fire a function, and find this control, and then invoke the method.
	 *
	 * @param {Object} control
	 * @memberof BarChart
	 */
	barClick(control) {
		const detail = {
			source: 'RPS-BAR-CHART2D',
			value: control.getAttribute("barvalue"),
			label: control.getAttribute("barlabel"),
			position: control.getAttribute("barelement"),
			id: this.id,
		};
		const e = new CustomEvent('click', { detail, bubbles: true, composed: true, cancelable: true });
		this.dispatchEvent(e);

		if (this.cbClick) this.cbClick(e);
	}

	/**
	 * @description - SVG needs to be created with the "unsafeSvg() method"
	 *
	 * @param {array} changedProperties
	 * @private
	 * @memberof BarChart2d
	 */
	_createSvg() {
		const bars = this.data.map((elem, i) => {
			const y = ((i + 1) * this.barHeight - this.barHeight / 2).toFixed(2);
			const title = elem.title ?? `${elem.value} ${elem.label}`;
			return `<g class="bar"
				onclick="new window.domFunction().walkUp(this, 'rps-bar-chart2d').barClick(this)"
				barvalue=${elem.value} barlabel="${elem.label}" barelement=${i}>
				<title>${title}</title>
				<rect width="${elem.percentage}%" height="${this.barHeight - 1}%" y="${i * this.barHeight}%"></rect>
				<text x="${elem.percentage}%" y="${y}%">${elem.value} ${elem.label}</text>
			</g>`;
		});

		return bars.join();
	}


	/**
	 * Generate the custom styling used in this chart
	 *
	 * @returns Custom Styling
	 * @memberof BarChart2D
	 */
	_customCss() {
		return html`
			<style>
				.bar {
					fill: ${this.barColor};
					/*height: 5rem;*/
					transition: fill .3s ease;
					cursor: pointer;
					font-family: Helvetica, sans-serif;

					text {
						fill: #555;
					}
				}

				.bar:hover,
				.bar:focus {
					fill: ${this.hoverColor} !important;

					text {
						fill: ${this.hoverColor};
					}
				}

				.chart:hover,
					.chart:focus {
					.bar {
						fill: ${this.barColor};
					}
				}
			</style>
		`;
	}


	render() {
		return html`
			${this.addCss()}
			${this._customCss()}

			<div class="chart-container">
				<figure>
					<figcaption>${this.heading}</figcaption>
				</figure>
				<svg class="chart" width="100%" height="100%" aria-labelledby="title desc" role="img">
					${unsafeSVG(this._createSvg())}
				</svg>
			</div>
		`;
	}
}

