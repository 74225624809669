/**
 * @name rps-bar-chart2d
 * @type {BarChart2d}
 * 	A 2D bar SVG chart with click events on the bars
 *
 *	@attributes + @properties
 *		@property {String}	barColor = "#aaa"				| Color for the bars
 *		@property {Boolean} 	hoverColor = 'red'			| Color for the bars when the mouse hovers over them]
 * 	@property {Array}		data								| The data to Display on the chart.
 * 																	  eg:
 * 																	  this.data = [
 * 																		  {label: "2008", value:25, title: `<h3>Sales for 2008</h3>`},
 * 																		  {label: "2009", value:50},
 * 																		  {label: "2010", value:75, title: `<h3>Sales for 2010</h3>`},
 * 																		  {label: "2011", value:100},
 * 																		  {label: "2012", value:50, title: `<h3>Sales for 2012</h3>`}
 * 																	  ];
 * 																	  The "title" is optional, and will display a HTML fragment as a "floating" element
 * 																	  If No "title" is passed it will automatically display the value for that bar.
 * 
 * 	@property {String}	customCss 						| Specify a custom .css file to include, use cssPath to override elements,
 * 																		  DO NOT override the styling definition by re-defining the css styling
 * 																		  Put this file into the *window.cssPath* folder
 * 																		  eg: /public/css (where the other css files are)
 * 																		  Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css								| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 	@property {String}	heading							| Heading for Chart
 * 
 * 
 * @events
 * 	@fires click												| returns the element that was clicked.
 * 												  						The chartValue(value of this element in data)
 * 												  						and pos(index on data array) are returned
 * 
 *
 * @callbacks
 * 	No callbacks
 *
 * @methods
 * 	No public Methods
 *
 * @properties
 * 	No public properties
 *
 *	@slots
 * 	no slots
 * 
 * @Styling
 * No classes for styling
 * 
 * @example+ @Lit
 * @basic
 * import '@rps-wc/rps-bar-chart2d';
 * 
 * 	onClicked(ev) {
 * 		console.log(ev.detail);
 * 	}
 *
 * <rps-bar-chart2d id='one' @click=${onClicked}>
 * </rps-bar-chart2d>
 * 
 * 
 * @advanced
 * import '@rps-wc/rps-bar-chart2d';
 * 
 * 	onClicked(ev) {
 * 		console.log(ev.detail);
 * 	}
 *
 * 	const setDataHandler(e) {
 * 		const data = [
 * 			{ label: "2008", value: 25, title: `<h3>Sales for 2008</h3>` },
 * 			{ label: "2009", value: 42 },
 * 			{ label: "2010", value: 75, title: `<h3>Sales for 2010</h3>` },
 * 			{ label: "2011", value: 100 },
 * 			{ label: "2012", value: 50, title: `<h3>Sales for 2012</h3>` },
 * 			{ label: "2013", value: 60 },
 * 			{ label: "2014", value: 89, title: `<h3>Sales for 2014</h3>` },
 * 		];
 * 		const chart = document.querySelector('rps-bar-chart2d');
 * 		chart.setData(data);
 * 		chart.heading = "Sales graph";
 * 		chart.barColor="cadetblue";
 * 		chart.hoverColor="blueviolet";
 * 	}
 * 
 * 	<button @click=${setDataHandler}>Set data manually</button>
 * 	<rps-bar-chart2d @click=${onClicked}>
 * 	</rps-bar-chart2d>
 * 
 * @example+ @React
 * import '@rps-wc/rps-bar-chart2d';
 * 
 * 	const onClicked = (ev) => {
 * 		console.log(ev.detail);
 * 	}
 *
 * 	return <rps-bar-chart2d onClick={onClicked}><rps-bar-chart2d>
 * 
 * 
 */
import { BarChart2d } from './src/charts/BarChart2d';
if (!window.customElements.get('rps-bar-chart2d'))
	window.customElements.define('rps-bar-chart2d', BarChart2d);

