/**
 * @name rps-card-flip
 * @type {CardFlip}
 * @notes
 * NB: Does not work properly in ReactJS, but DOES work perfectly in plain html and Web-components
 * ************************************************************************
 * This is a child of the <rps-card-flip-holder> but can be used on its own
 * 
 * @attributes + @properties 				| Changes trigger a re-render
 * Casing usages:								| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String} width 			| The width of the component
 * 	@property {String} height 			| The height of the component
 * 	@property {String} customCss 		| Specify a custom .css file to include, use cssPath to override elements,
 * 													DO NOT override the styling definition by re-defining the css styling
 * 													Put this file into the *window.cssPath* folder @see {link README.md}
 * 													eg: /public/css (where the other css files are)
 * 													Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String} css				| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 
 * @methods
 * 
 * @events
 * 	click										| when the user clicks on a card.
 * 												| if (event.target.tagName !== 'RPS-CARD-FLIP') return;
 * 												| dont respond to events inside the card, only the card. ie: when it was flipped
 * @callbacks
 * 	cbClick									| Called when the card is flipped
 * 
 * @slots
 * 	front										| front card
 * 	back										| back card
 * 
 * @example+ @Lit
 * @see {link /demo/index.html}
 * 
*/

import { CardFlip } from './src/spinners/CardFlip';
if (!window.customElements.get('rps-card-flip'))
	window.customElements.define('rps-card-flip', CardFlip);

