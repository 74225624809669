import type { InputHTMLAttributes } from 'react';
import { useId, useState } from 'react';
import {
  InputField,
  PrefixInputField,
  InputLabel,
  InputGroup,
  ErrorMessage,
} from './shared-styles';

export interface InputProps<TControlled extends true | false = true>
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value'> {
  /**
   * NOTE: inputs must be controlled by default.
   * If you want to make an exception for a specific input you can
   * change the default generic to false like so: `<Input<false> {...props} />`
   */
  value?: TControlled extends true
    ? NonNullable<InputHTMLAttributes<HTMLInputElement>['value']>
    : InputHTMLAttributes<HTMLInputElement>['value'];
  label?: string;
  required?: boolean;
  /**
   * TODO: phase out the withPrefix prop over time and eventually remove it
   * @deprecated use `prefix` instead
   */
  withPrefix?: boolean;
  prefix?: string;
  error?: string | boolean;
  tabularNums?: boolean;
  selectOnFocus?: boolean;
  /**
   * NOTE: for now we're gonna use our components alongside RPs in a few places.
   * While doing so (especially when they are directly next to each other),
   * it make sense to match RPs styling a little bit.
   *
   * TODO: BP-534 remove all styles and code for this once we know we won't be sharing screen real-estate
   */
  matchRPStyles?: boolean;
}

const Input = <TControlled extends true | false = true>({
  withPrefix,
  prefix: prefixProp,
  label,
  required,
  onFocus,
  onBlur,
  error,
  tabularNums,
  selectOnFocus,
  matchRPStyles,
  disabled,
  ...restProps
}: InputProps<TControlled>) => {
  const id = useId();
  const [isFocused, setIsFocused] = useState(false);

  const prefix = !prefixProp && withPrefix ? 'R' : prefixProp;

  return (
    <InputLabel
      htmlFor={id}
      className={[
        ...(isFocused ? ['focused'] : []),
        ...(error ? ['error'] : []),
      ].join(' ')}
    >
      {label}
      {required && <span>*</span>}

      <InputGroup
        className={[
          ...(disabled ? ['disabled'] : []),
          ...(!!prefix ? ['with-addon'] : []),
        ].join(' ')}
      >
        {!!prefix ? (
          <>
            <span
              className={[
                'input-group-addon',
                ...(isFocused ? ['focused'] : []),
                ...(error ? ['error'] : []),
                ...(disabled ? ['disabled'] : []),
                ...(matchRPStyles ? ['match-rp-styles'] : []),
              ].join(' ')}
            >
              {prefix}
            </span>

            <PrefixInputField
              id={id}
              name={id}
              className={[
                ...(isFocused ? ['focused'] : []),
                ...(error ? ['error'] : []),
                ...(tabularNums ? ['tabular-nums'] : []),
                ...(matchRPStyles ? ['match-rp-styles'] : []),
              ].join(' ')}
              onFocus={e => {
                setIsFocused(true);
                selectOnFocus && e.target.select();
                onFocus && onFocus(e);
              }}
              onBlur={e => {
                setIsFocused(false);
                onBlur && onBlur(e);
              }}
              disabled={disabled}
              autoComplete="off"
              {...restProps}
            />
          </>
        ) : (
          <InputField
            id={id}
            name={id}
            className={[
              ...(isFocused ? ['focused'] : []),
              ...(error ? ['error'] : []),
              ...(tabularNums ? ['tabular-nums'] : []),
              ...(matchRPStyles ? ['match-rp-styles'] : []),
            ].join(' ')}
            onFocus={e => {
              setIsFocused(true);
              selectOnFocus && e.target.select();
              onFocus && onFocus(e);
            }}
            onBlur={e => {
              setIsFocused(false);
              onBlur && onBlur(e);
            }}
            disabled={disabled}
            autoComplete="off"
            {...restProps}
          />
        )}
      </InputGroup>

      {error && typeof error === 'string' && (
        <ErrorMessage>{error}</ErrorMessage>
      )}
    </InputLabel>
  );
};

export default Input;
