import type { GridBoxProps } from '@odo/components/elements/layout/grid';
import { Grid } from '@odo/components/elements/layout/grid';
import type { CssColor } from '@odo/utils/css-color';
import { cssColor } from '@odo/utils/css-color';
import styled from '@odo/lib/styled';
import StatusDot from '@odo/components/elements/status-dot';
import { useEffect, useRef, useState } from 'react';
import useMedia from '@odo/hooks/use-media';
import { breakpointsEm } from '@odo/contexts/theme/provider';
import { FaSave as IconSave } from 'react-icons/fa';
import { Flex } from '@odo/components/elements/layout/flex';
import { conditionalJoin } from '@odo/utils/string';
import type { Status } from '@odo/screens/deal/editor/types';

export const statusColorMap: Record<Status, CssColor> = {
  valid: 'palette-turquoise',
  warning: 'palette-yellow',
  error: 'palette-pink',
};

export interface NavItemInterface {
  id: string;
  label: string;
  status?: Status;
  isActive?: boolean;
  onClick?: () => void;
  hasUnsavedChanges?: boolean;
}

const WhiteBar = styled(Grid)`
  width: 100%;
  pointer-events: none;

  @media screen and (min-width: 48em) {
    pointer-events: unset;
    box-shadow: 1px 2px 4px -2px hsl(240deg 33.33% 20% / 25%),
      1px 2px 8px -2px hsl(240deg 33.33% 20% / 10%);
  }
`;

WhiteBar.defaultProps = {
  justifyContent: ['space-between', null, 'flex-start'],
  px: [0],
  gap: [2, null, 3, 4],
  bg: ['none', cssColor('foreground')],
};

type MenuItemProps = GridBoxProps & {
  activeColor?: string;
};

const MenuItem = styled(Grid)<MenuItemProps>`
  --active-color: ${p => p.activeColor};

  background: ${cssColor('foreground')};
  color: ${cssColor('text')};
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;

  border-width: 2px;
  border-style: solid;
  border-color: transparent;

  cursor: pointer;
  user-select: none;
  pointer-events: all;

  position: relative;
  overflow: hidden;

  grid-area: 1 / 1;
  border-radius: 22px;

  &.active,
  &.expanded {
    box-shadow: 1px 2px 4px -2px hsl(240deg 33.33% 20% / 25%),
      1px 2px 8px -2px hsl(240deg 33.33% 20% / 10%);
  }

  &.active.expanded {
    border-color: var(--active-color);
  }

  @media screen and (min-width: 48em) {
    grid-area: unset;
    border-radius: unset;
    border-width: 0;

    &.active,
    &.expanded {
      box-shadow: unset;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;

      transition: transform 250ms ease;
      transform: translateY(3px);

      background-color: var(--active-color);
    }

    &.active {
      &:after {
        transform: translateY(0px);
      }
    }

    /* breathing room around the list */
    &:first-of-type {
      margin-left: 16px;
    }

    &:last-of-type {
      margin-right: 16px;
    }
  }

  @media (hover: hover) {
    &:hover {
      background: ${cssColor('foreground-alt')};
    }
  }
`;

MenuItem.defaultProps = {
  activeColor: cssColor('palette-blue'),
  p: '11px 12px 12px 12px',
  gap: 3,
  backgroundColor: [cssColor('foreground'), 'none'],
};

const MenuItemText = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const NAV_HEIGHT = 38;
const TRANSITION_DURATION = 35;

const EditorNav = ({ items }: { items: NavItemInterface[] }) => {
  const navRef = useRef<HTMLDivElement>(null);
  const isMobile = useMedia({
    query: `screen and (min-width: ${breakpointsEm[0]}em)`,
    invert: true,
  });

  const [expanded, setExpanded] = useState(false);

  /**
   * Close on outside click.
   * NOTE: only applicable on mobile and when expanded.
   */
  useEffect(() => {
    if (!isMobile || !expanded) return;

    const outsideClickClose = (e: MouseEvent | TouchEvent) => {
      if (
        navRef.current &&
        e.target instanceof Element &&
        !navRef.current.contains(e.target)
      ) {
        setExpanded(false);
      }
    };

    document.addEventListener('mousedown', outsideClickClose);
    document.addEventListener('touchstart', outsideClickClose);

    return () => {
      document.removeEventListener('mousedown', outsideClickClose);
      document.removeEventListener('touchstart', outsideClickClose);
    };
  }, [isMobile, expanded]);

  return (
    <WhiteBar
      ref={navRef}
      gridTemplateColumns={['1fr', `repeat(${items.length}, auto)`]}
    >
      {items.map((item, idx) => (
        <MenuItem
          key={item.id}
          alignItems="center"
          className={conditionalJoin([
            ['active', item.isActive],
            ['expanded', expanded],
          ])}
          activeColor={
            item.status ? cssColor(statusColorMap[item.status]) : undefined
          }
          gridTemplateColumns={[
            // mobile: always include space for save icon
            conditionalJoin([['auto', !!item.status], '1fr', 'auto']) || '',
            // tablet up: hide the save icon when not needed
            conditionalJoin([
              ['auto', !!item.status],
              '1fr',
              ['auto', !!item.hasUnsavedChanges],
            ]) || '',
          ]}
          onClick={() => {
            isMobile && setExpanded(!expanded);
            if (!isMobile || expanded) {
              item.onClick && item.onClick();
            }
          }}
          // calculated styles are put here instead of on the component or via CSS variables
          style={{
            ...(isMobile && {
              // always applied
              zIndex: item.isActive ? 25 : items.length - idx,
              transitionDuration: `${TRANSITION_DURATION * (idx + 1)}ms`,
              // expanded styles
              ...(expanded && {
                transform: `translateY(${idx * (NAV_HEIGHT + 16)}px)`,
                transitionDelay: `${
                  (items.length - idx) * (TRANSITION_DURATION / items.length)
                }ms`,
              }),
            }),
          }}
        >
          {item.status && <StatusDot bg={statusColorMap[item.status]} />}

          <MenuItemText>{item.label}</MenuItemText>

          {/* on mobile we "show" the transparent icon to take up space, from tablet we clear up that space */}
          <Flex display={!item.hasUnsavedChanges ? [null, 'none'] : undefined}>
            <IconSave
              color={
                item.hasUnsavedChanges
                  ? cssColor('palette-blue')
                  : 'transparent'
              }
            />
          </Flex>
        </MenuItem>
      ))}
    </WhiteBar>
  );
};

export default EditorNav;
