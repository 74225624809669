import { memo, useEffect, useState } from 'react';
import { GET_ODO_IMAGES } from 'gql/deals/getODOImages';
import { cacheGet, cacheGetAndSet } from '@odo/utils/cache';
import Loading from '@odo/components/elements/loading';
import { getImgixAssetUrl } from '@odo/utils/make-url';
import { IMAGE_CACHE_NAME } from '@odo/components/search/constants';
import client from '@odo/services/urql';
import type { GridProduct } from '@odo/components/search/types';
import type {
  QueryProductImagesArgs,
  QueryProductImagesOutput,
} from '@odo/types/api';
import { ImageType } from '@odo/types/api';

const Image = ({
  id,
  data,
  zoomCallback,
}: {
  id: string;
  data: GridProduct;
  zoomCallback?: (imgSrc: string) => void;
}) => {
  const [imgSrc, setImgSrc] = useState<string | undefined>(
    data.image || cacheGet({ cacheName: IMAGE_CACHE_NAME, cacheKey: id })
  );

  useEffect(() => {
    let isEffectActive = true;

    if (!imgSrc) {
      cacheGetAndSet<string>({
        cacheName: IMAGE_CACHE_NAME,
        cacheKey: id,
        setter: cachedValue => {
          if (cachedValue && isEffectActive) {
            setImgSrc(cachedValue);
          }
        },
        getter: async ({ cacheKey, setValue }) => {
          if (!cacheKey) return;

          let imageUrl: string | undefined;

          try {
            const { data } = await client
              .query<QueryProductImagesOutput, QueryProductImagesArgs>(
                GET_ODO_IMAGES,
                { productId: cacheKey },
                { requestPolicy: 'cache-and-network' }
              )
              .toPromise();

            if (data && data.getProductImages.length > 0) {
              const selectedImages = data.getProductImages.filter(
                ({ imageTypes }) => imageTypes.length > 0
              );

              if (selectedImages.length > 0) {
                const websiteImage = selectedImages.find(({ imageTypes }) =>
                  imageTypes.includes(ImageType.website)
                );
                if (websiteImage) {
                  imageUrl = websiteImage.url;
                } else {
                  const [firstSelected] = selectedImages;
                  imageUrl = firstSelected.url;
                }
              } else {
                const [firstImage] = data.getProductImages;
                if (firstImage && firstImage.url) {
                  imageUrl = firstImage.url;
                }
              }
            }
          } catch (e) {
            console.error(
              `Failed to load image for Product ID: ${cacheKey}. Details: `,
              e
            );
          }

          setValue(imageUrl || '/assets/image-placeholder.png');
        },
      });
    }

    return () => {
      isEffectActive = false;
    };
  }, [imgSrc, id]);

  return (
    <>
      {imgSrc && (
        <div
          style={{
            position: 'relative',
            ...(zoomCallback ? { cursor: 'pointer' } : {}),
          }}
          onClick={() => (zoomCallback ? zoomCallback(imgSrc) : null)}
        >
          <img
            style={{
              maxHeight: '100%',
              width: 'auto',
              maxWidth: '80%',
            }}
            alt="Zoom"
            src={getImgixAssetUrl({ url: imgSrc, width: 80 })}
          />

          {!!zoomCallback && (
            <div
              style={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                padding: '5px 6px',
                borderRadius: '5px',
                background: 'rgba(25, 25, 25, 0.35)',
                color: 'white',
              }}
            >
              <i className="fas fa-search" />
            </div>
          )}
        </div>
      )}
      {!imgSrc && <Loading isLoading />}
    </>
  );
};

const ImageMemo = memo(Image);

export default ImageMemo;
