import styles from './loading.module.css';

const Loading = ({
  isLoading,
  size = 20,
}: {
  isLoading: boolean;
  size?: number;
}) => (
  <div
    // NOTE: not ideal to ts-ignore, but this does work and is only temporary
    // the only other way around it is to use type assertions which are worse imho
    // @ts-ignore
    style={{ '--size': `${size}px` }}
    className={[
      styles.loadingElement,
      ...(isLoading ? [styles.loading] : []),
    ].join(' ')}
  />
);

export default Loading;
