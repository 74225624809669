import { InputNumericMasked } from '@odo/components/elements/form-fields';
import {
  useChangeProduct,
  useCurrentProduct,
} from '@odo/contexts/product-editor';

const OriginalStock = () => {
  const currentProduct = useCurrentProduct();
  const change = useChangeProduct();

  return (
    <InputNumericMasked
      label="Original Stock"
      value={currentProduct.originalStock?.string || ''}
      type="integer"
      onChange={originalStock => {
        change({
          fieldId: 'originalStock',
          label: 'Original Stock',
          apply: to => (to.originalStock = originalStock),
        });
      }}
      required
      selectOnFocus
    />
  );
};

export default OriginalStock;
