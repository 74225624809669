/**
 * @name rps-change-password
 * @type {ChangePassword}
 * A composite control for changing a users password.
 * 
 * 
 * @attributes + @properties 							| Changes trigger a re-render
 * Casing usages:											| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String} newPasswordLabel 		| The new password label. Default='New Password'
 * 	@property {String} newPlaceholder 			| The new password placeholder. Default='Enter New Password'
 * 	@property {String} confirmPasswordLabel 	| The confirm password label. Default='Confirm New Password'
 * 	@property {String} confirmPlaceholder 		| The confirm password placeholder. Default='Confirm New Password'
 * 	@property {String} confirmPasswordErrorMessage 	| The confirm password error message. 
 * 																Default='New password must match the Confirm Password'
 * 	@property {String} confirmButtonText 		| The confirm/change button text. Default='Change Password'
 *		@property {String} passwordLabel 			| The password label. Default="Password"
 * 	@property {String} passwordPattern 			| Password regex expression. Default='.{8,}'
 * 	@property {String} passwordPlaceholder 	| Password place holder text. Default='Enter Password'
 * 	@property {String} passwordErrorMessage 	| Password error message. Default='Passwords must be at least 8 long'
 * 	@property {String} customCss 					| Specify a custom .css file to include, use cssPath to override elements,
 * 																DO NOT override the styling definition by re-defining the css styling
 * 																Put this file into the *window.cssPath* folder @see {link README.md}
 * 																eg: /public/css (where the other css files are)
 * 																Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String} css							| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 												
 * @methods
 * 	None
 * 
 * @properties (JS Only)
 * 	formControl (get only)							| Retrieve the rps-form control in this control
 * 	modalControl (get only)							| Retrieve the rps-ok-modal control in this control
 * 	passwordControl (get only)						| Retrieve the current password control
 * 	newPasswordControl (get only)					| Retrieve new password control
 * 	confirmPasswordControl (get only)			| Retrieve confirm password control
 * 
 * 
 * @events
 * 	submit												| submit clicked
 * 	cancel												| cancel clicked
 * 
 * @callbacks
 * 	cbSubmit												| submit callback
 * 	cbCancel												| cancel callback
 * 
 * 
 * @slots
 * 	header
 * 
 * 
 * @example @lit
 * @basic
 * import '@rps-wc/wc/rps-change-password';
 * 
 * 	<rps-change-password @submit=${(e) => console.log(e)}>
 * 	</rps-change-password>
 * 
 * @example @lit
 * @advanced
 * import '@rps-wc/wc/rps-change-password';
 * 
 * 	<rps-change-password 
 * 		@submit=${(e) => console.log(e)}>
 * 		@cancel=${(e) => console.log(e)}>
 * 		newpasswordlabel="Enter password"
 * 		newplaceholder="New password"
 * 		confirmpasswordLabel="Repeat password"
 * 		confirmplaceholder="Confirm password"
 * 		confirmpassworderrormessage="Confirm password"
 * 		confirmbuttontext="Change"
 * 		passwordlabel="Enter password"
 * 		passwordpattern='.{8,}'
 * 		passwordplaceholder="Enter password"
 * 		passworderrormessage="password must be 5 chars long"
 * 	</rps-change-password>
 * 
 * 
 * 
 * 
 * @example @React
 * @basic
 * import '@rps-wc/wc/rps-change-password';
 * 
 * 	useEffect(() => {
 * 		change.current.cbSubmit= {e => console.log(e)}
 * 		change.current.cbCancel= {e => console.log(e)}
 * 	}, [])
 * 
 * 	<rps-change-password 
 * 		ref={change}
 * 		newpasswordlabel="Enter password"
 * 		newplaceholder="New password"
 * 		confirmpasswordLabel="Repeat password"
 * 		confirmplaceholder="Confirm password"
 * 		confirmpassworderrormessage="Confirm password"
 * 		confirmbuttontext="Change"
 * 		passwordlabel="Enter password"
 * 		passwordpattern='.{8,}'
 * 		passwordplaceholder="Enter password"
 * 		passworderrormessage="password must be 5 chars long"
 * 	</rps-change-password>
 * 
*/
import { ChangePassword } from './src/authentication/ChangePassword.js';
if (!window.customElements.get('rps-change-password'))
	window.customElements.define('rps-change-password', ChangePassword);

