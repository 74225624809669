import React, { useState } from 'react';
import { RPSCard } from '@rps/web-components/build/react-wrappers';
import { useUserDataSource } from 'hooks/userDataSource';
import { useSafeEffect } from 'hooks/useSafeEffect';
import { useNavigate } from 'react-router-dom';
import { iconNames } from '@rps/web-components/build/web-components';
import { ApolloClients } from 'services/apolloClients';
import { gql } from '@apollo/client';
import { useLoginController } from './useLoginController';
import styles from './Login.module.css';
import Button from '@odo/components/elements/button/old';
import LabelledInput from '@odo/components/elements/labelled-input';

const GET_OAUTH_ACCESS_TOKEN = gql`
  query GetOAuthAccessToken($access: AuthToken!) {
    getOauthAccessToken(access: $access) {
      status
      code
      message
    }
  }
`;

/**
 * Page component that shows a loading spinner while waiting for auth tokens to be fetched during initial auth process.
 */
export const LoginLoader = () => {
  const user = useUserDataSource();
  const navigate = useNavigate();
  const controller = useLoginController();

  const [readyToLogin, setReadyToLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useSafeEffect(() => {
    console.debug(
      `[LoginLoader::useSafeEffect@27] Checking state of tokens.`,
      user.tokens
    );
    console.debug(
      `[LoginLoader::useSafeEffect@27] Checking state of localStorage tokens.`,
      localStorage.getItem('authTokens'),
      localStorage.getItem('requestTokens')
    );

    if (user.tokens.authTokens && user.tokens.requestTokens) {
      const requestParams = new URLSearchParams(user.tokens.requestTokens);
      const authParams = new URLSearchParams(user.tokens.authTokens);
      const tokens = {
        request: {
          oauth_token: requestParams.get('oauth_token'),
          oauth_token_secret: requestParams.get('oauth_token_secret'),
        },
        auth: {
          oauth_token: authParams.get('oauth_token'),
          oauth_verifier: authParams.get('oauth_verifier'),
        },
      };

      if (tokens.request.oauth_token !== tokens.auth.oauth_token) {
        console.error(
          `[LoginLoader::useSafeEffect@45] Tokens not equivalent - something went wrong.`,
          tokens,
          user.tokens
        );
      }

      const fetchAccessToken = async () => {
        const client = new ApolloClients().odo;

        const { data } = await client.query({
          query: GET_OAUTH_ACCESS_TOKEN,
          variables: {
            access: {
              token: tokens.request.oauth_token,
              verifier: tokens.auth.oauth_verifier,
              secret: tokens.request.oauth_token_secret,
            },
          },
          errorPolicy: 'ignore',
          fetchPolicy: 'network-only',
        });

        try {
          if (data?.getOauthAccessToken?.status === 'SUCCESSFUL') {
            setReadyToLogin(true);
          } else {
            throw new Error('Fetch not successful. Tokens may have expired.');
          }
        } catch (e) {
          console.error(
            `[LoginLoader::fetchAccessToken] Failed to log in. Details: `,
            e
          );
          controller.setMessage(
            'Login failed. Check network connection and try again.'
          );
          user.tokens.setAuthTokens('');
          user.tokens.setRequestTokens('');
          navigate('/');
        }
      };

      fetchAccessToken();
    }
  }, [user.tokens.authTokens, user.tokens.requestTokens]);

  const loginHandler = async e => {
    e.preventDefault();

    if (!email || !password) return;

    try {
      /**
       * The original version of this ran the login twice, the first one without caring about the response.
       * I suspect this was to bypass a false negative or some delay on the authentication.
       * But from my own testing it doesn't seem necessary.
       * If there are issues we might need to consider adding it back.
       */
      const userData = await controller.directLoginAuth(email, password);
      if (userData) {
        user.actions.setUserData(userData);
        navigate('/');
      } else {
        throw new Error('User data not returned. User invalid?');
      }
    } catch (e) {
      console.error(
        `[LoginLoader::fetchAccessToken] Failed to log in. Details: `,
        e
      );
      controller.setMessage(
        'Login failed. Check network connection and try again.'
      );
      user.tokens.setAuthTokens('');
      user.tokens.setRequestTokens('');
      navigate('/');
    }
  };

  const cancel = () => navigate('/');

  return (
    <div className={styles.loginContainer}>
      <RPSCard css=":host { width: 48rem; }">
        <div slot="header" className={styles.loginHeader}>
          <rps-svg class="large" svg={iconNames.odo.blueLogo} />
        </div>

        {controller.message && (
          <rps-input-label text={controller.message} class="warning" />
        )}

        {!readyToLogin && (
          <div className={styles.loginLoader}>
            <rps-spinner-pause />
            Waiting for response from Magento...
          </div>
        )}

        {readyToLogin && (
          <form action="#" onSubmit={loginHandler}>
            <div className={styles.loginFields}>
              <div style={{ textAlign: 'center' }}>
                Almost finished. We just need you to login again here.
              </div>

              <LabelledInput
                label="Email"
                type="text"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />

              <LabelledInput
                label="Password"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />

              <Button
                size="full"
                type="submit"
                primary
                disabled={!email || !password}
                onClick={loginHandler}
              >
                LOG IN
              </Button>
            </div>
          </form>
        )}

        <Button size="full" outlined onClick={cancel}>
          CANCEL
        </Button>
      </RPSCard>
    </div>
  );
};
