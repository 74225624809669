import { ApolloClients } from 'services/apolloClients';
import { useContext } from 'react';
import { ModalContext } from 'contexts/Modal';
import { useMutation } from '@apollo/client';
import { ShowGraphQLErrorPopup } from 'components/uiComponents/GQLErrorPopup.jsx';

export const useODOMutation = (query, options) => {
  const modalContext = useContext(ModalContext);
  const clients = new ApolloClients();

  return useMutation(query, {
    ...options,
    client: clients.odoClient,
    onError: ShowGraphQLErrorPopup(modalContext),
  });
};
