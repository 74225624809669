import type { EditorProductInterface } from '@odo/types/portal';
import type { ReactNode } from 'react';

export enum Status {
  valid = 'valid',
  warning = 'warning',
  error = 'error',
}

export interface Validator {
  validate: (product: EditorProductInterface) => boolean;
  /**
   * Default: false
   * Set to true if you want to show the error/warning
   * when the validate function returns true instead of when it returns false.
   */
  invertValidation?: boolean;
  message: ReactNode;
  status: Status.error | Status.warning;
}
