/**
 * @name rps-dragdrop
 * @type {DragDrop}
 * A drag-drop container control
 * 
 * 
 * @attributes + @properties 						| Changes trigger a re-render
 * Casing usages:										| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String}	effectAllowed		| Optional: (values are "copy" or "move") default is "move"
 * 															determines whether objects are copied into the drop container or moved into it
 * 												
 * 	@property {Boolean}	allowDuplicates	| Are items with the same "Id" allowed to be duplicated in the drop container?
 * 
 * 	@property {String}	customCss			| Specify a custom .css file to include, use cssPath to override elements,
 * 															DO NOT override the styling definition by re-defining the css styling
 * 															Put this file into the *window.cssPath* folder
 * 															eg: /public/css (where the other css files are)
 * 															Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css					| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 												
 * 
 * 
 * @methods
 * 	clear()											| Remove all the dropped controls from the drop container
 * 
 * @events
 * 	drop												| Fired after an item has been dropped
 * 	dragover											| fired when a draggable item is dragged over this control
 * 	dragleave										| fired when a draggable item leaves this control
 * 
 * @callbacks
 * 	cbPreDrop										| called when an item is dropped. if the return value is false the drop event is cancelled
 * 	cbDrop											| called when the item has been dropped on the drop container
 * 	cbDragOver										| called when the item is dragged over the drop container
 * 	cbDragLeave										| called when the item leaved the drop container whilst being dragged
 * 
 * 
 * @slots
 * 	Default Slot									| Provide a place where items can be "pre-dropped"
 * 
 * 
 * @example @Lit
 * @basic
 * ======
 * 	import '@rps-wc/rps-dragdrop';
 * 
 * 	<rps-dragdrop name="old" label="are you old?" required checked readonly
 * 		cbPreDrop=${e => false}
 * 		@drop={(e) => console.log('dashboard:checkbox',e.target.checked)}>
 * 	</rps-dragdrop>
 * 
 * 
 * @example @Lit
 * @advanced
 * ======
 * 	import '@rps-wc/rps-dragdrop';
 * 
 * 	<rps-dragdrop name="old" label="are you old?" required checked readonly
 * 		cbPreDrop=${e => false}
 * 		@drop={(e) => console.log(e}>
 * 		@dragover={(e) => console.log(e}>
 * 		@dragleave={(e) => console.log(e}>
 * 	</rps-dragdrop>
 *
 * 
 * @example @ReactJS
 * @basic
 * ======
 * 	import '@rps-wc/rps-dragdrop';
 * 
 * 	useEffect(() => {
 * 		drag.cbPreDrop=${e => false}	// if return value is false then it prevents drop
 * 		drag.current.cbDrop= {e => console.log(e)}
 * 		drag.current.cbDragOver= {e => console.log(e)}
 * 		drag.current.cbDragLeave= {e => console.log(e)}
 * 	}, [])
 * 
 * 
 * 	<rps-dragdrop name="old" label="are you old?" required checked readonly ref={drag}>
 * 	</rps-dragdrop>
 *  
*/

import { DragDrop } from './src/dragdrop/DragDrop';
if (!window.customElements.get('rps-dragdrop'))
	window.customElements.define('rps-dragdrop', DragDrop);

