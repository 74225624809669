import { gql } from '@apollo/client';

export const ADD_PRODUCT_CATEGORIES = gql`
  mutation AddProductCategories($productId: ID!, $categories: [ID!]!) {
    addProductCategory(productId: $productId, categories: $categories) {
      ... on ResponseMessage {
        status
        code
        message
      }
    }
  }
`;
