import type { ReactNode } from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from '@odo/lib/styled';
import { colors } from '@odo/utils/css-color';
import type { Theme } from '@odo/lib/styled';

/**
 * @see https://typescale.com/
 *
 * NOTE: other than the "min" value, all of these can be freely tweaked.
 * The reason we cannot change min is coz it would throw all fonts across the site out.
 * That said, there shouldn't really be any good reasons for an even smaller font size.
 *
 * NOTE: in a few places we might want to use clamp for our fonts.
 * It's hard to know these in advance, so here are some references for if/when.
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/clamp
 * @see https://www.smashingmagazine.com/2022/01/modern-fluid-typography-css-clamp/
 *
 * NOTE: RedPanda forces the font-size to 62.5% globally.
 * If we reset that back to a sane value (ie. 16px like all browsers use as their default),
 * then all of their styles get thrown out of whack (even more so than usual).
 *
 * If we can't change the global, but still want our type scale to use `rem` as recommended,
 * we'll need to adjust the base of our scale.
 *
 * TODO: remove the adjust constant and fallback to using the base font size when we no longer have RP.
 */
const ADJUST_FOR_RED_PANDA_STUPIDITY = 1.6;
const BASE_FONT_SIZE = 16; // px
const PX_SCALE = BASE_FONT_SIZE * ADJUST_FOR_RED_PANDA_STUPIDITY;
const TYPE_SCALE = 1.2; // minor third
const TYPE_SCALE_MIN = -2; // less than the base font size
const TYPE_SCALE_MAX = 8; // from the base font size up to X

const getPxScale = (idx: number) => PX_SCALE * Math.pow(TYPE_SCALE, idx);
const pxToRem = (px: number) => px / BASE_FONT_SIZE;

const fontSizes = Array(TYPE_SCALE_MAX - TYPE_SCALE_MIN)
  .fill(null)
  // get the scale in px relative to base font size (using type scale)
  // convert px to rem
  // round to third decimal point
  // add rem unit
  .map(
    (_, idx) => `${+pxToRem(getPxScale(idx + TYPE_SCALE_MIN)).toFixed(3)}rem`
  );

export const breakpointsEm = [48, 64, 100];
export const spacesPx = [0, 4, 8, 16, 32, 64, 128, 256, 512];

const theme: Theme = {
  // colors (using our css variables)
  colors,
  // spacing (padding, margin, gap, etc.)
  space: spacesPx,
  // breakpoints
  breakpoints: breakpointsEm.map(n => `${n}em`),
  // typography
  fontSizes,
  lineHeights: { heading: 1.4, body: 1.3 },
  fonts: {
    heading: 'Montserrat, sans-serif',
    /**
     * @see https://systemfontstack.com/
     */
    body: '-apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
    mono: 'Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace',
  },
};

// we'll start by wrapping this around individual "screens" for now
// later on when RP is less prevalent (or removed entirely) we can have a single instance in the app root
const ThemeProvider = ({ children }: { children: ReactNode }) => (
  <StyledComponentsThemeProvider theme={theme}>
    {children}
  </StyledComponentsThemeProvider>
);

export default ThemeProvider;
