/**
 * Flatten an array of complex objects
 *
 * @param {Array} items An Array of JSON objects with multi levels of child objects
 * @param {Array} result An Array of flattened objects
 * @return {Array}  Flattened objects
 */
function deepFlatten(items, result, firstCall = true) {
	if (!result) result = [];

	for (const key in items) {
		const entry = items[key];

		if (Array.isArray(entry)) {
			entry.forEach(child => {
				deepFlatten(child, result, false);
			});
		} else if (typeof entry === 'object') {
			if (!firstCall) result.push(entry);
			deepFlatten(entry, result, false);
		}
	}
	
	if (typeof items === 'object') {
		result.push(items);
	}

	return result;
}


/**
 * Traverse a flat array create a grouping based on the parent attribute name, and child attribute names
 * @description returns a Map() object where the key is the [parentAttribute] and the value is an array of all the [childAttribute] values
 *
 * @param {Array} items Flat array of items to group
 * @param {String} parentAttribute Name fo the parent element
 * @param {String} childAttribute Name fo the child element
 * @return {Map} The Map (key, [children]) to return.
 */
function groupByProps(items, parentAttribute, childAttribute) {
	const grouping = new Map();

	items.forEach(item => {
		const parent = item[parentAttribute];
		const child = item[childAttribute];

		// Only add to Map() if both the parent and child elements are present on this object
		if (parent && child) {
			const groupedItem = grouping.get(parent);
			if (!groupedItem) {
				grouping.set(parent, [child]);
			} else {
				groupedItem.push(child);
			}
		}
	});

	return grouping;
}


export {
	deepFlatten,
	groupByProps,
}
