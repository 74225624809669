import { html, css } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import '../../rps-general-settings';
import { styles } from './css/profile.css.js';

export class Profile extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			logo: { type: String },
			menuItems: { type: Array },
			includeCss: { type: String },
		};
	}

	constructor() {
		super();
		this.logo = "../../images/person32.png";
		this.menuItems = [
			{
				"href": "#",
				"image": "fas fa-user fa-1x",
				"text": "Profile",
				"class": "bottom-border"
			},
			{
				"href": "#",
				"image": "fas fa-cog fa-1x",
				"text": "Settings",
				"class": ""
			},
			{
				"href": "https://duckduckgo.com/",
				"image": "fas fa-sign-out-alt fa-1x",
				"text": "Logout",
				"class": ""
			},
		];
		this.showSettings = false;
		document.body.style.overflow = 'hidden';										// because this is a child of the profile, it causes scroll bars when the modal displays. disable it
		this.extraIcons = [];
	}

	_setMenuVisibility(event) {
		// event.detail.target contains the "<li>" element that was clicked, in addition to the other information
		//const menu = this.shadowRoot.querySelector('rps-layout-menu');
		//return menu.toggleVisibility();
	}

	/* istanbul ignore */
	_menuItemClicked(event) {
		this._setMenuVisibility(event);

		if (event.detail.text.toLowerCase() === "settings") {
			event.preventDefault();

			const settings = this.shadowRoot.querySelector('rps-general-settings');	// See if the general settings control is a child
			if (settings) {
				const modal = settings.shadowRoot.querySelector('rps-modal');	// find the modal control
				//console.debug(modal);
				document.body.style.overflow = 'hidden';								// because this is a child of the profile, it causes scroll bars when the modal displays. disable it
				modal.show();																	// Open modal (it is in hidden state because it already exists was "closed")
			}


			this.showSettings = true;
			this.requestUpdate();
		}
	}

	/* istanbul ignore */
	_generalSettings() {
		if (this.showSettings) {
			// using manual styling here as this is a child of the Profile that is right aligned
			// we have to position it absolute
			return html`
				<rps-general-settings style="position:absolute; right:40%;"
					@confirm="${this.okGeneralSettings}" @cancel="${this.cancelGeneralSettings}"
				>
				</rps-general-settings>`;
		}
	}

	okGeneralSettings(event) {
		console.debug('GeneralSettings', 'ok clicked in modal in generalSettings');
		document.body.style.overflow = '';																			// allow body to scroll again
	}
	cancelGeneralSettings(event) {
		console.debug('GeneralSettings', 'cancel clicked in generalSettings');
		document.body.style.overflow = '';																			// allow body to scroll again
	}


	/**
	 * Add an Icon to the toolbar
	 * @example - const dom = new DOM();
	 * const profile = dom.findFirst(document.body, 'rps-profile');
	 * profile.addIcon(html`<i class="fas fa-adjust fa-lg profile-logo" @click=${clickHandler}></i>`);
	 * @param {*} litElement
	 * @memberof Profile
	 */
	addIcon(litElement) {
		this.extraIcons.push(litElement);
		this.requestUpdate();
	}


	/**
	 * Remove all the extra icons from the toolbar
	 *
	 * @memberof Profile
	 */
	clearExtraIcons() {
		this.extraIcons = [];
		this.requestUpdate();
	}


	/**
	 * Render all the extra icons that have been added to this toolbar
	 *
	 * @returns
	 * @memberof Profile
	 */
	renderExtraIcons() {
		return this.extraIcons.map(icon => {
			return icon;
		});
	}


	render() {
		const cssFile = this.includeCss ? `includeCss="${this.includeCss}"` : '';

		return html`
			${this.addCss()}

			<div class="profile-container">
				${this._generalSettings()}

				<div id="icon-holder">
					${this.renderExtraIcons()}
				</div>
				<i class="fas fa-user fa-lg profile-logo" @click=${this._setMenuVisibility}></i>
			</div>
			`;
	}
}

