import type { SetStateAction } from 'react';
import { createContext } from 'react';
import type { FilterInterface, SearchFiltersContextType } from './types';

const defaultSearchFiltersContext: SearchFiltersContextType = {
  isReady: false,
  filters: [],
  setFilters: (_product: SetStateAction<FilterInterface[]>) => void 0,
  isLoadingDeals: false,
  setIsLoadingDeals: (_loading: SetStateAction<boolean>) => void 0,
};

const SearchFiltersContext = createContext<SearchFiltersContextType>(
  defaultSearchFiltersContext
);

export default SearchFiltersContext;
