import { html } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import '../../rps-button.js';
import '../../rps-svg.js';
import { styles } from './css/wizard.css.js';

export class Wizard extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			buttons: { type: Array },
			cbClick: { attribute: false },
		};
	}

	constructor() {
		super();

		this.buttons = [];
	}

	/**
	 * Mark this button as "active", and the others as not active
	 *
	 * @param {String} buttonId - the Buttons Id that must be "pressed" (active)
	 * @memberof Wizard
	 */
	pressButton(buttonId) {
		const all = this.renderRoot.querySelectorAll(`rps-button`);
		all.forEach(e => e.toggleClass('active', false));

		this.toggleButtonClass(buttonId, 'active');
		this.requestUpdate();
	}

	/**
	 * Toggle a class for a button
	 *
	 * @param {String} buttonId - Buttons Id to toggle a css class
	 * @param {String} setClass - the class to add or remove from the button, Options are: ['active', 'success', 'warning', 'error']
	 * @param {boolean} [addClass=true] - add or remove class
	 * @memberof Wizard
	 */
	toggleButtonClass(buttonId, setClass, addClass = true) {
		const pressed = this.renderRoot.querySelector(`#${buttonId}`);
		if (pressed) {
			pressed.toggleClass(setClass, addClass);			// add desired class
		}
	}

	/**
	 * Unset all classes of the button
	 *
	 * @param {String} buttonId - Buttons Id to toggle a css class
	 * @param {String} setClass - the class to add or remove from the button, Options are: ['active', 'success', 'warning', 'error']
	 * @param {boolean} [addClass=true] - add or remove class
	 * @memberof Wizard
	 */
	resetButtonClass(buttonId) {
		const pressed = this.renderRoot.querySelector(`#${buttonId}`);
		if (pressed) {
			pressed.toggleClass('success', false);
			pressed.toggleClass('warning', false);
			pressed.toggleClass('error', false);
		}
	}


	/**
	 * Click event
	 *
	 * @param {Event} event
	 * @memberof Wizard
	 */
	click(event) {
		event.stopPropagation();

		this.pressButton(event.target.id);
		const detail = {
			event: 'click',
			source: this.tagName,
			buttonId: event.target.id,
			button: event.target,
		};
		const e = new CustomEvent('click', { detail, bubbles: true, composed: true, cancelable: true });
		console.debug('Wizard: Click', e);
		this.dispatchEvent(e);

		if (this.cbClick) this.cbClick(e);
	}

	/**
	 * Get the status of the Wizard button.
	 * @description Return the "worst" status for the button
	 *
	 * @param {String} id The button Id you want to interrogate
	 * @return {*} The Worst status for this button
	 * @memberof Wizard
	 */
	buttonStatus(id) {
		const button = this.renderRoot.querySelectorAll(`#${id}`);

		if (button.classList.contains('error')) return 'error';
		if (button.classList.contains('warning')) return 'warning';
		if (button.classList.contains('success')) return 'success';

		return;
	}

	/**
	 * Get the status of the Wizard.
	 * @description Return the "worst" status found in the list of buttons
	 *
	 * @readonly
	 * @memberof Wizard
	 */
	get status() {
		const all = this.renderRoot.querySelectorAll(`rps-button`);
		all.forEach(e => {
			if (e.classList.contains('error')) return 'error';
		});
		all.forEach(e => {
			if (e.classList.contains('warning')) return 'warning';
		});
		all.forEach(e => {
			if (e.classList.contains('success')) return 'success';
		});

		return;
	}

	/**
	 * Create the buttons and "lines between them"
	 *
	 * @return {html}
	 * @memberof Wizard
	 */
	createButtons() {
		const lastElem = this.buttons.length - 1;
		return html`${this.buttons.map((e, i) => {
			return html`
			<div class="wizard-action ${e.class ? e.class : ''}">
				<span></span>
				<div class="wizard-button">
					<rps-button id="${e.id}" class="outline wizard ${e.class ? e.class : ''}" type="button"
						@click=${this.click}
					>
						<rps-svg svg="${e.svg}"></rps-svg>
					</rps-button>
					<div class="wizard-text">${e.text}</div>
				</div>
			</div>`
		})}`;
	}

	render() {
		return html`
			${this.addCss()}
			<div class="wizard-container">
				${this.createButtons()}
			</div>
		`;
	}
}
