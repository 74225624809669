import { useCallback, useState } from 'react';
import SearchDeals from './deals';
import Dialog from '@odo/components/widgets/dialog';
import { Grid } from '@odo/components/elements/layout';

const Search = ({
  duplicateDeal,
}: {
  duplicateDeal: (dealId: string) => void;
}) => {
  const [previewImage, setPreviewImage] = useState<
    { src: string; width: number } | undefined
  >();

  const openImagePreview = useCallback(
    ({ src, width }: { src: string; width: number }) =>
      setPreviewImage({ src, width }),
    []
  );

  return (
    <>
      <SearchDeals
        openImagePreview={openImagePreview}
        duplicateDeal={duplicateDeal}
      />

      <Dialog
        title="Image Preview"
        isOpen={!!previewImage}
        close={() => setPreviewImage(undefined)}
      >
        {!!previewImage && (
          <Grid
            justifyContent="center"
            alignContent="center"
            width="600px"
            height="600px"
            maxWidth="80vw"
            maxHeight="80vh"
          >
            <img
              aria-label="Preview deal image"
              width={previewImage.width}
              style={{ maxWidth: '100%', maxHeight: '100%' }}
              src={previewImage.src}
            />
          </Grid>
        )}
      </Dialog>
    </>
  );
};

export default Search;
