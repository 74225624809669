/**
 * @name rps-tooltip
 * @type {Tooltip}
 * 	A generic tooltip control
 * 
 * @attributes + @properties 				| Changes trigger a re-render
 * Casing usages:								| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String} text 			| The text in the tooltip
 * 	@property {String} customCss 		| Specify a custom .css file to include, use cssPath to override elements,
 * 													DO NOT override the styling definition by re-defining the css styling
 * 													Put this file into the *window.cssPath* folder @see {link README.md}
 * 													eg: /public/css (where the other css files are)
 * 													Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String} css				| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 
 * @methods
 * 	None
 * 
 * @events
 * 	None
 * 
 * @slots
 * 	default									| Custom HTML can go in here
 * 
 * 
 * @examples + @generic + @Lit
 * @basic
 * import '@rps-wc/rps-tooltip';
 * 
 * <rps-tooltip text="add user">
 * 	Custom HTML if desired
 * </rps-tooltip>
 * 
 * 
*/
import { Tooltip } from './src/general/Tooltip';
if (!window.customElements.get('rps-tooltip'))
	window.customElements.define('rps-tooltip', Tooltip);

