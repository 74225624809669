import React from 'react';
import { last10Columns } from '../../services/calculatorProfitGridLast10';
import propTypes from 'prop-types';
import { RPSCard, RPSGrid } from '@rps/web-components/build/react-wrappers';

const Last10Grid = ({ data, populate }) => {
  const handleClick = ev => {
    if (populate) {
      populate(ev);
    }
  };

  const actionColumn = {
    headerText: '',
    data: {
      control: {
        textButton: {
          text: 'Load',
          cbClick: handleClick,
        },
      },
    },
  };

  return (
    <RPSCard css="div { width: 100%; } div .card-content { width: auto; }">
      <div slot="header">
        <h6>Calculation History</h6>
      </div>
      <div>
        <RPSGrid
          condenseCells
          data={data}
          columns={[...last10Columns, actionColumn]}
          pageSize={5}
        />
      </div>
    </RPSCard>
  );
};

Last10Grid.propTypes = {
  data: propTypes.any,
  populate: propTypes.func,
};

export default Last10Grid;
