import type { ApiCustomOption, ApiCustomOptionValue } from '@odo/types/api';
import { DependencyEnum, InputTypeEnum } from '@odo/types/api';
import type {
  Action,
  PastedOption,
} from '@odo/contexts/custom-options-editor/types';
import {
  isActionPasteOptions,
  ActionTypeEnum,
  isActionAddOption,
  isActionAddValue,
  isActionUpdateOption,
  isActionUpdateValue,
  isActionSwapOptions,
  isActionSwapValues,
  isActionRemoveOption,
  isActionRemoveValue,
} from '@odo/contexts/custom-options-editor/types';
import { getMaxGroupId, getMaxSortOrder } from '@odo/data/custom-options/utils';

const defaultOptionFields: Omit<ApiCustomOption, 'id' | 'productId'> = {
  type: InputTypeEnum.dropdown,
  dependency: DependencyEnum.no,
  sortOrder: 0,
};

const defaultValueFields: Omit<ApiCustomOptionValue, 'valueId'> = {
  title: '',
  price: 0,
  cost: 0,
  quantity: 0,
  sortOrder: 0,
};

const addChildGroupIdToValue = ({
  customOptions,
  parentValueId,
  newGroupIds,
}: {
  customOptions: ApiCustomOption[];
  parentValueId: ApiCustomOptionValue['valueId'];
  newGroupIds: number[];
}) => {
  // find the parent value and update its childrenGroupIds to include the new groupId
  let addedToParent = false;

  const childrenGroupIds = newGroupIds
    .map(groupId => groupId.toString())
    .join(',');

  customOptions.forEach(option =>
    (option.values || []).forEach(value => {
      if (value.valueId === parentValueId) {
        addedToParent = true;

        // set children group ids
        if (value.childrenGroupIds) {
          value.childrenGroupIds = value.childrenGroupIds.concat(
            `,${childrenGroupIds}`
          );
        } else {
          value.childrenGroupIds = childrenGroupIds;
        }
      }
    })
  );

  return addedToParent;
};

const removeChildGroupIdsFromValue = ({
  customOptions,
  parentValueId,
  groupIds,
}: {
  customOptions: ApiCustomOption[];
  parentValueId: ApiCustomOptionValue['valueId'];
  groupIds: string[];
}) => {
  customOptions.forEach(option =>
    (option.values || []).forEach(value => {
      if (value.valueId === parentValueId) {
        // set children group ids
        if (value.childrenGroupIds) {
          value.childrenGroupIds = value.childrenGroupIds
            .split(',')
            .filter(id => !groupIds.includes(id))
            .join(',');
        }
      }
    })
  );
};

const createEmptyOption = ({
  customOptions,
  productId,
  tmpOptionId,
  tmpValueId,
}: {
  customOptions: ApiCustomOption[];
  productId: ApiCustomOption['productId'];
  tmpOptionId: ApiCustomOption['id'];
  tmpValueId: ApiCustomOptionValue['valueId'];
}): { newGroupId: number; customOption: ApiCustomOption } => {
  // get the highest groupId from all option values and increment for our new group id
  const newGroupId = getMaxGroupId(customOptions) + 1;

  return {
    newGroupId,
    customOption: {
      ...defaultOptionFields,
      id: tmpOptionId,
      productId,
      sortOrder: getMaxSortOrder(customOptions) + 1,
      values: [
        {
          ...defaultValueFields,
          valueId: tmpValueId,
          groupId: newGroupId,
          sortOrder: 1,
        },
      ],
    },
  };
};

const createEmptyValue = ({
  customOption,
  customOptions,
  tmpValueId,
}: {
  customOption: ApiCustomOption;
  customOptions: ApiCustomOption[];
  tmpValueId: ApiCustomOptionValue['valueId'];
}) => {
  customOption.values = customOption.values || [];

  const newGroupId = getMaxGroupId(customOptions) + 1;

  const newValue: ApiCustomOptionValue = {
    ...defaultValueFields,
    valueId: tmpValueId,
    groupId: newGroupId,
    sortOrder: getMaxSortOrder(customOption.values) + 1,
  };

  return { newGroupId, newValue };
};

const createPastedOption = ({
  customOptions,
  productId,
  pastedOption,
}: {
  customOptions: ApiCustomOption[];
  productId: ApiCustomOption['productId'];
  pastedOption: PastedOption;
}): { newGroupIds: number[]; customOption: ApiCustomOption } => {
  // get the highest groupId from all option values and increment for each values new group id
  let maxGroupId = getMaxGroupId(customOptions);
  const newGroupIds: number[] = [];

  return {
    newGroupIds,
    customOption: {
      ...defaultOptionFields,
      id: pastedOption.tmpOptionId,
      productId,
      title: pastedOption.title,
      dependency:
        typeof pastedOption.parentValueId !== 'undefined'
          ? DependencyEnum.or
          : DependencyEnum.no,
      sortOrder: pastedOption?.sortOrder || getMaxSortOrder(customOptions) + 1,
      values: pastedOption.values.map(value => {
        const newGroupId = ++maxGroupId;
        newGroupIds.push(newGroupId);

        return {
          valueId: value.tmpValueId,
          title: value.title,
          sku: value.sku,
          price: value.price,
          cost: value.cost,
          quantity: value.quantity,
          groupId: newGroupId,
          sortOrder: value.sortOrder,
        };
      }),
    },
  };
};

const swapItems = <T extends { sortOrder?: number }>({
  list,
  movingCondition,
  swapWithCondition,
}: {
  list: T[];
  movingCondition: (item: T) => boolean;
  swapWithCondition: (swapWith: T) => boolean;
}) => {
  const moving = list.find(movingCondition);
  const swapWith = list.find(swapWithCondition);

  if (
    moving &&
    swapWith &&
    typeof moving.sortOrder !== 'undefined' &&
    typeof swapWith.sortOrder !== 'undefined'
  ) {
    const sortOrderSwapping = swapWith.sortOrder;
    swapWith.sortOrder = moving.sortOrder;
    moving.sortOrder = sortOrderSwapping;

    list.sort(
      (valueA, valueB) => (valueA.sortOrder || 0) - (valueB.sortOrder || 0)
    );
  }
};

export const actionReducerApply: Record<
  ActionTypeEnum,
  (args: { action: Action; customOptions: ApiCustomOption[] }) => void
> = {
  /**
   * Paste Options
   */
  [ActionTypeEnum.PasteOptions]: ({ customOptions, action }) => {
    if (isActionPasteOptions(action)) {
      action.options.forEach(pastedOption => {
        const { newGroupIds, customOption } = createPastedOption({
          customOptions,
          productId: action.productId,
          pastedOption,
        });

        if (typeof pastedOption.parentValueId !== 'undefined') {
          const addedToParent = addChildGroupIdToValue({
            customOptions,
            parentValueId: pastedOption.parentValueId,
            newGroupIds,
          });

          if (addedToParent) {
            customOptions.push(customOption);
          }
        } else {
          customOptions.push(customOption);
        }
      });
    }
  },

  /**
   * Add Option
   */
  [ActionTypeEnum.AddOption]: ({ customOptions, action }) => {
    if (isActionAddOption(action)) {
      const { newGroupId, customOption } = createEmptyOption({
        customOptions,
        productId: action.productId,
        tmpOptionId: action.tmpOptionId,
        tmpValueId: action.tmpValueId,
      });

      if (typeof action.parentValueId !== 'undefined') {
        const addedToParent = addChildGroupIdToValue({
          customOptions,
          parentValueId: action.parentValueId,
          newGroupIds: [newGroupId],
        });

        // add new option to list if the parent value was found
        if (addedToParent) {
          customOptions.push(customOption);
        }
      } else {
        customOptions.push(customOption);
      }
    }
  },

  /**
   * Add Value to Option
   */
  [ActionTypeEnum.AddValue]: ({ customOptions, action }) => {
    if (isActionAddValue(action)) {
      const customOption = customOptions.find(
        ({ id }) => id === action.optionId
      );
      if (customOption) {
        customOption.values = customOption.values || [];

        const { newGroupId, newValue } = createEmptyValue({
          customOption,
          customOptions,
          tmpValueId: action.tmpValueId,
        });

        if (typeof action.parentValueId !== 'undefined') {
          const addedToParent = addChildGroupIdToValue({
            customOptions,
            parentValueId: action.parentValueId,
            newGroupIds: [newGroupId],
          });

          // add new value to list if the parent value was found
          if (addedToParent) {
            customOption.values.push(newValue);
          }
        } else {
          customOption.values.push(newValue);
        }
      }
    }
  },

  /**
   * Update Option
   */
  [ActionTypeEnum.UpdateOption]: ({ customOptions, action }) => {
    if (isActionUpdateOption(action)) {
      const option = customOptions.find(({ id }) => id === action.id);
      if (option) {
        for (const field in action.fields) {
          option[field] = action.fields[field];
        }
      }
    }
  },

  /**
   * Update Value
   */
  [ActionTypeEnum.UpdateValue]: ({ customOptions, action }) => {
    if (isActionUpdateValue(action)) {
      const option = customOptions.find(({ id }) => id === action.optionId);
      if (option) {
        const value = (option.values || []).find(
          ({ valueId }) => valueId === action.valueId
        );
        if (value) {
          for (const field in action.fields) {
            value[field] = action.fields[field];
          }
        }
      }
    }
  },

  /**
   * Swap Options
   */
  [ActionTypeEnum.SwapOptions]: ({ customOptions, action }) => {
    if (isActionSwapOptions(action)) {
      swapItems<ApiCustomOption>({
        list: customOptions,
        movingCondition: ({ id }) => id === action.optionAId,
        swapWithCondition: ({ id }) => id === action.optionBId,
      });
    }
  },

  /**
   * Swap Values
   */
  [ActionTypeEnum.SwapValues]: ({ customOptions, action }) => {
    if (isActionSwapValues(action)) {
      const parentOption = customOptions.find(
        option => option.id === action.optionId
      );
      if (
        parentOption &&
        parentOption.values &&
        parentOption.values.length > 1
      ) {
        swapItems<ApiCustomOptionValue>({
          list: parentOption.values,
          movingCondition: ({ valueId }) => valueId === action.valueAId,
          swapWithCondition: ({ valueId }) => valueId === action.valueBId,
        });
      }
    }
  },

  /**
   * Remove Option
   */
  [ActionTypeEnum.RemoveOption]: ({ customOptions, action }) => {
    if (isActionRemoveOption(action)) {
      const option = customOptions.find(({ id }) => id === action.id);
      if (option) {
        const index = customOptions.indexOf(option);
        if (index !== -1) {
          customOptions.splice(index, 1);
        }

        // remove group ID from parent value
        if (typeof action.parentValueId !== 'undefined') {
          const groupIds: string[] = [];
          (option.values || []).forEach(({ groupId }) => {
            if (typeof groupId !== 'undefined') {
              groupIds.push(groupId.toString());
            }
          });

          if (groupIds.length > 0) {
            removeChildGroupIdsFromValue({
              customOptions,
              parentValueId: action.parentValueId,
              groupIds,
            });
          }
        }
      }
    }
  },

  /**
   * Remove Value
   */
  [ActionTypeEnum.RemoveValue]: ({ customOptions, action }) => {
    if (isActionRemoveValue(action)) {
      const parentOption = customOptions.find(
        ({ id }) => id === action.optionId
      );
      if (parentOption) {
        const value = (parentOption.values || []).find(
          ({ valueId }) => valueId === action.valueId
        );
        if (value) {
          const index = (parentOption.values || []).indexOf(value);
          if (index !== -1) {
            (parentOption.values || []).splice(index, 1);
          }

          // remove group ID from parent value
          if (
            typeof action.parentValueId !== 'undefined' &&
            typeof value.groupId !== 'undefined'
          ) {
            removeChildGroupIdsFromValue({
              customOptions,
              parentValueId: action.parentValueId,
              groupIds: [value.groupId.toString()],
            });
          }
        }
      }
    }
  },
};
