import type { SetStateAction } from 'react';
import { createContext } from 'react';
import type { DealChanges, SearchEditorContextType } from './types';

const defaultSearchEditorContext: SearchEditorContextType = {
  changes: [],
  setChanges: (_product: SetStateAction<DealChanges[]>) => void 0,
  isUploading: false,
  uploadChanges: () => void 0,
  setUploadChangesCallback: (
    _callback: SetStateAction<(() => void) | undefined>
  ) => void 0,
};

const SearchEditorContext = createContext<SearchEditorContextType>(
  defaultSearchEditorContext
);

export default SearchEditorContext;
