import Card from '@odo/components/elements/card';
import { Flex } from '@odo/components/elements/layout';
import { Text } from '@odo/components/elements/typography';
import { spacesPx } from '@odo/contexts/theme/provider';
import styled from '@odo/lib/styled';
import { cssColor } from '@odo/utils/css-color';

interface LegendLineProps {
  width: number;
  color: string;
}

const LINE_WIDTH = 2;
const BORDER_RADIUS = 8;
const DEFAULT_PADDING = spacesPx[2];
const DEFAULT_COLOR = cssColor('palette-blue');

const LegendLine = styled.span<LegendLineProps>`
  --color: ${props => props.color};

  margin-top: 15px;
  position: relative;
  height: ${LINE_WIDTH}px;
  width: ${props => `${props.width}px`};
  background: var(--color);
  border-bottom-left-radius: ${BORDER_RADIUS}px;
  border-bottom-right-radius: ${BORDER_RADIUS}px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: ${LINE_WIDTH}px;
    width: ${LINE_WIDTH}px;
    height: ${15 - LINE_WIDTH}px;
    background: var(--color);
    border-top-left-radius: ${BORDER_RADIUS}px;
    border-top-right-radius: ${BORDER_RADIUS}px;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
`;

const PackageLegend = ({
  base,
  width,
  color = DEFAULT_COLOR,
  padding = DEFAULT_PADDING,
}: {
  base?: number;
  width?: number;
  color?: string;
  padding?: number;
}) => {
  // don't render if we don't have our required parameters or the final width is too small
  if (!base || !width || width < 10) return null;

  return (
    <Flex
      justifyContent="center"
      style={{
        position: 'absolute',
        bottom: padding,
        left: padding,
        right: padding,
      }}
    >
      <Card mainProps={{ px: 3, py: 1 }}>
        <Flex alignItems="center" flexDirection="column" gap={1}>
          <LegendLine width={width} color={color} />
          <Text textAlign="center" fontWeight={800} color={color}>
            {base}cm
          </Text>
        </Flex>
      </Card>
    </Flex>
  );
};

export default PackageLegend;
