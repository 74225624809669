import React, { useState } from 'react';
import propTypes from 'prop-types';
import styles from './css/ImageCard.module.css';
import {
  RPSToolbar,
  RPSImage,
  RPSModal,
  RPSListContainer,
} from '@rps/web-components/build/react-wrappers';
import { iconNames } from '@rps/web-components/build/web-components';

const getBase64FromURL = async url => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};
const ImageCard = ({
  src,
  title,
  isDelete = false,
  onUpdate,
  image,
  isNew = false,
}) => {
  const isImage = image?.filePath || image?.fileContents;

  const [modalOpened, setModalOpened] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const removeSelected = () => {
    onUpdate(true);
    setModalOpened(false);
  };

  const showDeleteConfirm = () => {
    setModalOpened(true);
  };

  const handleToolbarClick = async ev => {
    switch (ev.detail.buttonId) {
      case 'delete': {
        showDeleteConfirm();
        break;
      }
      case 'download': {
        const tempLink = document.createElement('a');
        const dataUrl = await getBase64FromURL(src);
        let splitUrl = src.split('/');
        tempLink.setAttribute('href', dataUrl);
        tempLink.setAttribute('download', splitUrl[splitUrl.length - 1]);
        tempLink.click();
        break;
      }
      case 'openInNewTab': {
        const tempLink = document.createElement('a');
        tempLink.setAttribute('href', src);
        tempLink.setAttribute('target', '_blank');
        tempLink.click();
        break;
      }
      default:
        break;
    }
  };

  const toolbarButtons = [
    { id: 'delete', svg: iconNames.del, title: 'Delete Image' },
    { id: 'download', svg: iconNames.media, title: 'Download Image' },
    ...(!isNew
      ? [{ id: 'openInNewTab', svg: iconNames.link, title: 'Open in New Tab' }]
      : []),
  ];

  return (
    <div style={{ position: 'relative', display: 'block' }}>
      {!!isDelete && (
        <div className={styles.imageDelete} title="Save Changes to Delete">
          <div className={styles.imageDeleteDiagonal} />
        </div>
      )}

      <div className={styles.imageCard} style={{ background: 'white' }}>
        <>
          <span className={styles.header}>
            <span style={{ paddingRight: '2rem' }}>{title}</span>
            <RPSToolbar buttons={toolbarButtons} cbClick={handleToolbarClick} />
          </span>
          <RPSListContainer
            className="vertical"
            css="#list-container { gap: 1rem !important; }"
          >
            <div
              className={styles.imageContainer}
              style={{
                cursor: isImage ? 'pointer' : 'not-allowed',
                opacity: isImage ? '1.0' : '0.25',
                pointerEvents: isImage ? 'unset' : 'none',
              }}
              onClick={() => setSelectedImage(src)}
            >
              <RPSImage
                className="large"
                src={src}
                css="picture img { object-fit: contain; background: white; }"
              />
            </div>
          </RPSListContainer>
        </>

        <RPSModal
          popup
          opened={modalOpened}
          cbConfirm={removeSelected}
          cbCancel={() => setModalOpened(false)}
        >
          Are you sure you want to delete this image?
        </RPSModal>
      </div>
      {selectedImage && (
        <RPSModal
          popup
          opened={selectedImage || undefined}
          cbConfirm={() => {}}
          cbCancel={() => setSelectedImage('')}
        >
          <div
            style={{
              display: 'block',
              maxWidth: '100%',
              maxHeight: '500px',
              overflow: 'hidden',
            }}
          >
            <img
              alt="Modal preview"
              src={selectedImage}
              style={{
                display: 'block',
                width: '100%',
                maxHeight: '500px',
                objectFit: 'scale-down',
                overflow: 'hidden',
              }}
            />
          </div>
          <div slot="footer" />
        </RPSModal>
      )}
    </div>
  );
};

ImageCard.propTypes = {
  title: propTypes.string,
  src: propTypes.string,
  isDelete: propTypes.bool,
  isNew: propTypes.bool,
  image: propTypes.object,
  row: propTypes.bool,
  onUpdate: propTypes.func,
};
export default ImageCard;
