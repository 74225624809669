import { gql } from '@apollo/client';

export const GET_PRODUCT_INVENTORY = gql`
  query GetProductInventory($productId: String!) {
    getInventoryItems(
      filter: {
        select: { field: "productId", condition: IN, value: $productId }
      }
    ) {
      id
      qty
      minSaleQuantity
      maximumSaleQuantity
      useConfigMinSaleQty
      useConfigMaxSaleQty
      isInStock
      isApplyMaxSaleQtyToProductOptions
      isApplyMaxSaleQtyCustomerProfile
    }
  }
`;
