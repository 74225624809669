/**
 * @name rps-wizard
 * @type {Wizard}
 * A wizard control
 * 
 * @attributes + @properties 				| Changes trigger a re-render
 * Casing usages:								| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {Array} 	buttons		| A list of buttons to create
 * 													Default is [
 * 														{ id: "one", text: "Buyer Info", svg: iconNames.wizard.checklist, class: "success" },
 * 														{ id: "two", text: "Product", svg: iconNames.wizard.category, class: "active" },
 * 														{ id: "three", text: "Conditions & Category", svg: iconNames.wizard.deal, class: "warning" },
 * 														{ id: "four", text: "Pricing", svg: iconNames.wizard.delivery, class: "error" },
 * 														{ id: "five", text: "Visuals", svg: iconNames.wizard.media },
 * 														{ id: "six", text: "Shipping", svg: iconNames.wizard.pricing },
 * 														{ id: "seven", text: "Inventory", svg: iconNames.wizard.product },
 * 														{ id: "eight", text: "Summary", svg: iconNames.wizard.signOff },
 * 													];
 * 													The svg attribute is to select the svg to display
 * 													The Optional class is to "pre-select" the state of a button.
 * 													Possible values are ['active', 'success', 'warning', 'error']
 * 
 *  	@property {String} 	customCss	| Specify a custom .css file to include, use cssPath to override elements,
 * 													DO NOT override the styling definition by re-defining the css styling
 * 													Put this file into the *window.cssPath* folder @see {link README.md}
 * 													eg: /public/css (where the other css files are)
 * 													Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css			| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 												
 * 
 * @methods
 * 	toggleButtonClass(buttonId, setClass, addClass = true)	| Toggle a class on a button 
 * 														Options are: ['active', 'success', 'warning', 'error']
 * 	pressButton(buttonId)					| Manually click a button
 * 	resetButtonClass(buttonId)				| Manually remove all classes from the button and set it into its "default" state.
 * 	buttonStatus(id)							| Get the status of the Wizard.
 * 														ie: return the status on this order for the button. ['active', 'success', 'warning', 'error', undefined]
 * 	get status									| Return the "worst" status found in the list of buttons
 * 														ie: return the status on this order for all of the buttons. ['active', 'success', 'warning', 'error', undefined]
 * 
 * 
 * @events
 * 	click											| Fired when the user clicks on a wizard icon
 * 
 * @callbacks
 * 	cbClick										| Called when the user clicks on a wizard icon
 * 
 * @slots
 * 	None
 * 
 * @example+ @lit
 * @basic
 * import '@rps-wc/rps-wizard';
 * 
 * 		<rps-wizard @click=${clickHandler}>
 * 		</rps-wizard>
 * 
 * @example+ @React
 * @basic
 * import '@rps-wc/rps-wizard';
 * 
 *  	useEffect(() => {
 *				wiz.current.cbInput = (e) => console.log(e);
 *  	}, [])
 * 
 * 		<rps-wizard ref={wiz}>
 * 		</rps-wizard>
 * 
*/
import { Wizard } from './src/navigation/Wizard.js';
if (!window.customElements.get('rps-wizard'))
	window.customElements.define('rps-wizard', Wizard);

