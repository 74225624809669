import { html } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { ref, createRef } from 'lit/directives/ref.js';
import '../../rps-chip';
import { uuid } from '../functions/uuid';
import { styles } from './css/fileDragDrop.css.js';

export class FileDragDrop extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			fileMask: { type: String },
			createChip: { type: Object },
			cbDragOver: { attribute: false },
			cbDragLeave: { attribute: false },
			cbDrop: { attribute: false },
			cbPreDrop: { attribute: false },
		};
	}

	constructor() {
		super();
		this.dragging = false;
		this.files = [];			// contains the "Chip objects" created with each file dropped
		this.fileMask = "*";		// eg: "image/png, image/jpeg"
		this.fileObjects = new Map();		// contains the actual "file blob's"
		this.dropCtrl = createRef();
		this.fileInput = createRef();
	}


	/**
	 * Container object that all files are added to
	 *
	 * @readonly
	 * @memberof FileDragDrop
	 */
	get dropContainer() {
		return this.dropCtrl.value;
	}


	/**
	 * Fires when a droppable object is moved over the dropContainer
	 *
	 * @param {Event} ev
	 * @memberof FileDragDrop
	 */
	dragOver(ev) {
		ev.preventDefault();

		if (this.dragging === false) {
			this.dragging = true;
			console.debug('FileDragDrop:dragOver FirstTime');
			this.dropContainer.classList.toggle('dragging', true);

			if (this.cbDragOver) this.cbDragOver(e);
		}
	}


	/**
	 * Fires when a droppable object moves away from the drop container
	 *
	 * @param {Event} ev
	 * @memberof FileDragDrop
	 */
	dragLeave(ev) {
		ev.preventDefault();

		console.debug('FileDragDrop:dragLeave firing');
		this.dropContainer.classList.toggle('dragging', false);
		this.dragging = false;

		if (this.cbDragLeave) this.cbDragLeave(e);
	}


	/**
	 * When a droppable object is dropped onto the drop container
	 *
	 * @param {Event} ev
	 * @memberof FileDragDrop
	 */
	drop(ev) {
		this.dropContainer.classList.toggle('dragging', false);
		ev.stopPropagation();
		ev.preventDefault();

		// if there is a cbPreDrop and the return value is false, then dont drop
		if (this.cbPreDrop && this.cbPreDrop(ev) === false) {
			return
		}

		if (ev.dataTransfer && ev.dataTransfer.items) {
			for (let i = 0; i < ev.dataTransfer.items.length; i++) {
				// If dropped items aren't files, reject them
				if (ev.dataTransfer.items[i].kind === 'file') {
					const file = ev.dataTransfer.items[i].getAsFile();
					console.debug('... file[' + i + '].name = ' + file.name);

					// NB: If a custom HTML (chip) generator is passed, then use it,
					// otherwise create a chip with default attributes
					let chip;
					const id = uuid();
					if (this.createChip) {
						// as per passed function by user to generate the Chip
						chip = html`${this.createChip(file, id)}`;
					} else {
						// if no custom chip specified, create a default chip to use
						chip = html`<rps-chip id=${id} text="${file.name}" deletemode="confirm" hasborder></rps-chip>`;
					}
					this.fileObjects.set(id, file);
					this.files.push(chip);
				}
			}
		} else {
			for (let i = 0; i < this.fileInput.value.files.length; i++) {
				console.debug('... file[' + i + '].name = ' + this.fileInput.value.files[i].name);
				const file = this.fileInput.value.files[i];

				let chip;
				const id = uuid();
				if (this.createChip) {
					// as per passed function by user to generate the Chip
					chip = html`${this.createChip(file, id)}`;
				} else {
					// if no custom chip specified, create a default chip to use
					chip = html`<rps-chip id=${id} text="${file.name}" deletemode="confirm" hasborder></rps-chip>`;
				}
				this.fileObjects.set(id, file);
				this.files.push(chip);
			}
		}

		if (this.cbDrop) this.cbDrop(e);


		this.requestUpdate();
		console.debug('DragDrop:drop firing');
		this.dragging = false;
	}

	/**
	 * A collection of the Chips to display
	 * Already created as html`` objects
	 *
	 * @returns an array of HTML Chip objects
	 * @memberof FileDragDrop
	 */
	chips() {
		return this.files.map(file => {
			return file;
		});
	}


	/**
	 * When the user confirms that a Chip must be deleted
	 *
	 * @param {Event} event
	 * @memberof FileDragDrop
	 */
	modalConfirm(event) {
		event.stopPropagation();

		if (event.detail.type === "chip-delete" && event.detail.source === "RPS-CHIP") {
			console.debug("FileDragDrop:DeleteConfirmed");
			this.fileObjects.delete(event.detail.item.id);
		}
	}

	/**
	 * Clear the drop container.
	 * ie: remove all dropped controls
	 *
	 * @memberof DragDrop
	 */
	clear() {
		const children = Array.from(this.shadowRoot.querySelectorAll('rps-chip'));
		children.forEach(child => {
			child.remove();
		});
	}


	render() {
		return html`
			${this.addCss()}
			<div id="dropContainer" ${ref(this.dropCtrl)} @confirm=${this.modalConfirm}
				@drop=${this.drop} @dragover=${this.dragOver} @dragleave=${this.dragLeave}>
				<img id="dropImage" src=${this.cssPath + "images/drop.svg"}>
				<span id="inputContainer">
					<input type="file" id="file" multiple accept=${this.fileMask} @change=${this.drop} ${ref(this.fileInput)}>
					<label for="file" id="forFile">
						<strong class="btn btn-primary">Choose a file</strong>
						<span> or drag it here</span>.
					</label>
				</span>
				${this.chips()}
			</div>
		`;
	}
}
