import type {
  JustifyContentProps,
  AlignSelfProps,
  AlignContentProps,
  AlignItemsProps,
  PlaceContentProps,
  PlaceItemsProps,
  FlexDirectionProps,
  FlexWrapProps,
  FlexBasisProps,
  FlexGrowProps,
  FlexShrinkProps,
  GapProps,
  OrderProps,
  SpaceProps,
  BackgroundColorProps,
  LayoutProps,
  OpacityProps,
  BorderProps,
} from '@odo/lib/styled';
import styled, {
  compose,
  justifyContent,
  alignContent,
  alignItems,
  flexDirection,
  flexWrap,
  flexBasis,
  flexGrow,
  flexShrink,
  gap,
  alignSelf,
  order,
  space,
  color,
  layout,
  border,
  placeContent,
  placeItems,
} from '@odo/lib/styled';

export type FlexBoxProps = JustifyContentProps &
  AlignContentProps &
  AlignItemsProps &
  FlexDirectionProps &
  FlexWrapProps &
  GapProps &
  SpaceProps &
  BackgroundColorProps &
  OpacityProps &
  LayoutProps &
  BorderProps &
  PlaceContentProps &
  PlaceItemsProps;

export const Flex = styled.div<FlexBoxProps>`
  display: flex;
  ${compose(
    justifyContent,
    alignContent,
    alignItems,
    placeContent,
    placeItems,
    flexDirection,
    flexWrap,
    gap,
    space,
    color,
    layout,
    border
  )}
`;

export type FlexItemProps = FlexBasisProps &
  FlexGrowProps &
  FlexShrinkProps &
  AlignSelfProps &
  OrderProps &
  SpaceProps &
  LayoutProps;

export const FlexItem = styled.div<FlexItemProps>`
  ${compose(flexBasis, flexGrow, flexShrink, alignSelf, order, space, layout)}
`;
