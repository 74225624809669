import { html } from 'lit';
import { createRef, ref } from 'lit/directives/ref.js';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { styles } from './css/tileItem.css.js';
export class TileItem extends CustomLitElement {

	static styles = styles;

	static get properties() {
		return {
			cbClick: { attribute: false },
		};
	}

	constructor() {
		super();
		this.tile = createRef();
	}

	/**
	 * Fired when the TileItem is clicked on
	 *
	 * @param {Event} event
	 * @memberof TileItem
	 */
	divClicked(event) {
		event.preventDefault();
		event.stopPropagation();
		
		const detail = {
			source: this.tagName,
			id: this.id,
		};
		const e = new CustomEvent('click', { bubbles: true, cancelable: true, composed: true, detail });
		this.dispatchEvent(e);

		if (this.cbClick) this.cbClick(e);
	}

	/**
	 * Easy way to retrieve the TileItem control externally
	 *
	 * @readonly
	 * @memberof TileItem
	 */
	get tileItemControl() {
		return this.tile.value;
	}


	/**
	 * A method to toggle a class for the TileItem control
	 *
	 * @param {String} className - the name of the class to add or remove
	 * @param {boolean} [toAdd=true]
	 * @memberof TileItem
	 */
	toggleClass(className, toAdd = true) {
		this.classList.toggle(className, toAdd);
		this.tileItemControl.classList.toggle(className, toAdd);
		this.requestUpdate();
	}


	render() {
		return html`
			${this.addCss()}
			<div id="tileItem" 
				class="${this.className}" ${ref(this.tile)} @click=${this.divClicked}
				header="Create new deal" footer="delete deal" content="edit deal"
			>
				<div class="tile">
					<slot class="media" name="media"></slot>

					<slot class="slot-icon" name="slot-icon">${this.header}</slot>
					<slot class="slot-main">${this.content}</slot>
					<slot class="slot-footer" name="slot-footer">${this.footer}</slot>
				</div>
				<slot class="slot-heading" name="heading"></slot>
				<slot class="slot-caption" name="product-caption"></slot>
			</div>
		`;
	}

}

