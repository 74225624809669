import React from 'react';
import propTypes from 'prop-types';

const MinimalContainer = props => {
  return (
    <div>
      <div className="relative">
        <div>{props.children}</div>
      </div>
    </div>
  );
};

MinimalContainer.propTypes = {
  children: propTypes.any,
};

export default MinimalContainer;
