const pad = (number: number, length = 2) =>
  number.toString().padStart(length, '0');

export const dateObjectToIso = (date: Date, withTime = false) => {
  if (!(date instanceof Date)) {
    return '';
  }

  let dateString = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate()
  )}`;

  if (withTime) {
    dateString += ` ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
      date.getSeconds()
    )}`;
  }

  return dateString;
};

export const dateObjectTimeOnly = (date: Date, withSeconds = false) => {
  if (!(date instanceof Date)) {
    return '';
  }

  let timeString = `${pad(date.getHours())}:${pad(date.getMinutes())}`;

  if (withSeconds) {
    timeString += `:${pad(date.getSeconds())}`;
  }

  return timeString;
};

export const daysAway = (days: number) => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  date.setHours(0, 0, 0, 0);
  return date;
};

export const daysEnd = (days: number) => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  date.setHours(23, 59, 59, 59);
  return date;
};
