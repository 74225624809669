/**
 * Takes an object and makes it a reversible map (i.e.:
 * passing in the key gets back the value, passing in the value gets back the key).
 *
 * Useful for enum representation.
 * @template T
 * @param {T} obj
 * @returns {T}
 */
const makeReversibleMap = obj => {
  Object.keys(obj).forEach(key => {
    const val = obj[key];
    obj[val] = key;
  });

  if (!obj[0]) {
    obj[0] = null;
    obj[null] = 0;
  }

  return obj;
};

export const enumToString = (enumObject, enumValue) => {
  if (typeof enumObject[enumValue] === 'string') {
    return enumObject[enumValue];
  } else {
    return enumObject[enumObject[enumValue]];
  }
};

export const enumToNumber = (enumObject, enumValue) => {
  if (typeof enumObject[enumValue] === 'number') {
    return enumObject[enumValue];
  } else {
    return enumObject[enumObject[enumValue]];
  }
};

export const AdminCostEnum = makeReversibleMap({
  ADMINCOST_17_50: 1,
  ADMINCOST_10_00: 2,
  ADMINCOST_34_00: 3,
});

export const ConditionEnum = makeReversibleMap({
  NEW: 1,
  REFURBISHED: 2,
  EX_DEMO_STOCK: 3,
  DEMO_STOCK: 4,
  DAMAGED_PACKAGING: 5,
  NO_PACKAGING: 6,
  NO_TAGS: 7,
});

export const WarrantyPeriodEnum = makeReversibleMap({
  NO_WARRANTY: 0,
  MONTHS_3: 1,
  MONTHS_6: 2,
  MONTHS_12: 3,
  MONTHS_24: 4,
  MONTHS_36: 5,
});

export const TaxClassEnum = makeReversibleMap({
  NONE: 1,
  TAXABLE_GOODS: 2,
  SHIPPING: 3,
});

export const SupplierRepacksEnum = makeReversibleMap({
  SUPPLIER_REPACKS: 1,
  WE_REPACK: 2,
  NO_REPACK: 3,
});

export const PlatformEnum = makeReversibleMap({
  APP: 1,
  WEBSITE: 2,
  VODAPAY_MINI: 3,
});

export const AttributeSetEnum = makeReversibleMap({
  DEFAULT: 1,
  ONEDAYONLY: 2,
});

export const ProductTypeEnum = makeReversibleMap({
  BUNDLE: 1,
  CONFIGURABLE: 2,
  DOWNLOADABLE: 3,
  GROUPED: 3,
  SIMPLE: 4,
  VIRTUAL: 5,
});

export const BuyInStockEnum = makeReversibleMap({
  DIRECT: 1,
  CONSIGNMENT: 2,
  SALE_RETURN: 3,
});

export const CampaignEnum = makeReversibleMap({
  null: null,
  DINNER_DEAL: 1,
  LUNCHTIME_DEAL: 2,
});

export const ImageTypeEnum = makeReversibleMap({
  IMAGE: 'IMAGE',
  SMALL_IMAGE: 'SMALL_IMAGE',
  THUMBNAIL: 'THUMBNAIL',
});
