import { ApolloClients } from 'services/apolloClients';
import { useContext } from 'react';
import { ModalContext } from 'contexts/Modal';
import { useQuery } from '@apollo/client';
import { ShowGraphQLErrorPopup } from 'components/uiComponents/GQLErrorPopup.jsx';
import { useManualQuery } from './useManualQuery';

export const useODOQuery = (query, options) => {
  const modalContext = useContext(ModalContext);
  const clients = new ApolloClients();

  return useQuery(query, {
    ...options,
    client: clients.odoClient,
    onError: ShowGraphQLErrorPopup(modalContext),
  });
};

export const useODOLazyQuery = (query, options) => {
  const modalContext = useContext(ModalContext);
  const clients = new ApolloClients();

  return useManualQuery(query, {
    ...options,
    client: clients.odoClient,
    skip: true,
    onError: ShowGraphQLErrorPopup(modalContext),
  });
};
