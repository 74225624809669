/**
 * @name rps-standard-buttons
 * @type {StandardButtons}
 * 
 * @attributes + @properties 						| Changes trigger a re-render
 * Casing usages										| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property  {String} 	cancelText			| Text to display in the cancel button
 * 	@property  {String} 	confirmText			| Text to display in the confirm button
 * 	@property  {String} 	busyText				| Text to display after the confirm button has been pressed
 * 	@property  {Boolean} hideCancelButton	| Optionally hide the cancel (back button)
 * 	@property  {Boolean} hasSpinner			| Does this control have a spinner | default=false
 * 	@property  {String} 	customCss				| Specify a custom .css file to include, use cssPath to override elements,
 * 															DO NOT override the styling definition by re-defining the css styling
 * 															Put this file into the *window.cssPath* folder @see {link README.md}
 * 															eg: /public/css (where the other css files are)
 * 															Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css					| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 												
 * 
 * @methods
 * 	setStatus(status)
 * 		"busy"= 										| after confirm clicked, show that something is happening, and disable confirm button
 * 		"reset"= 									| initial state, all buttons enabled
 * 
 * @events
 * 	cancel
 * 	confirm
 * 
 * @callbacks
 * 	cbCancel
 * 	cbConfirm
 * 
 * @slots
 * 	slot-left									| Slotted content in the left of the button can be put in here (spinner goes here)
 * 
 * 
 * @example @lit
 * @basic
 * ====
 * import '@rps-wc/rps-svg';
 * 	<rps-standard-buttons @confirm=${this._confirm}>
 * 	</rps-standard-buttons>
 * 
 * @advanced
 * ========
 * import '@rps-wc/rps-svg';
 * 	<rps-standard-buttons @cancel=${this._cancel} @confirm=${this._confirm} canceltext="Cancel" confirmtext="OK" busyText="Busy">
 * 	</rps-standard-buttons>
 * 
 * 
 * @example @React
 * @basic
 * ====
 * 
*/
import { StandardButtons } from './src/buttons/StandardButtons.js';
if (!window.customElements.get('rps-standard-buttons'))
	window.customElements.define('rps-standard-buttons', StandardButtons);

