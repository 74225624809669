import { html } from 'lit';
import { ref, createRef } from 'lit/directives/ref.js';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { DOM } from '../functions/DOM';
import { styles } from './css/dragDrop.css.js';
import '../../rps-svg';
import { iconNames } from '../../svg-icons';

export class DragDrop extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			effectAllowed: { type: String },
			allowDuplicates: { type: Boolean },
			cbPreDrop: { attribute: false },
			cbDrop: { attribute: false },
			cbDragOver: { attribute: false },
			cbDragLeave: { attribute: false },
		};
	}

	constructor() {
		super();
		this.dragging = false;
		this.effectAllowed = "move";
		this.dropCtrl = createRef();
	}

	get dropContainer() {
		return this.dropCtrl.value;
	}


	/**
	 * Fires when an object is being dragged over the drag container
	 * Used to change the styling during dragging over the container
	 * @param {Event} ev
	 * @memberof DragDrop
	 */
	dragOver(ev) {
		ev.preventDefault();
		if (this.dragging === false) {
			this.dragging = true;
			ev.dataTransfer.effectAllowed = this.effectAllowed;
			console.debug('DragDrop:dragOver FirstTime');
			this.dropContainer.classList.toggle('dragging', true);

			if (this.cbDragOver) this.cbDragOver(ev);
		}
	}


	/**
	 * Fired when the dragged object exists the drag container area.
	 * Used to "reset" the styling of the drop container
	 * @param {Event} ev
	 * @memberof DragDrop
	 */
	dragLeave(ev) {
		ev.preventDefault();
		console.debug('DragDrop:dragLeave firing');
		this.dropContainer.classList.toggle('dragging', false);
		this.dragging = false;

		if (this.cbDragLeave) this.cbDragLeave(ev);
	}


	/**
	 * Fires when an object is dropped onto the drop container
	 * Copies or moves dropped object into container
	 * @param {Event} ev
	 * @memberof DragDrop
	 */
	drop(ev) {
		this.dropContainer.classList.toggle('dragging', false);
		ev.preventDefault();

		const data = ev.dataTransfer.getData("text");
		const droppedItem = new DOM().findId(document.body, data);
		console.debug('DROP', droppedItem);

		if (this.cbPreDrop) {
			if (this.cbPreDrop(droppedItem) === false) {
				return;
			}
		}

		// Only allow copy or move if there is no duplicate OR
		// duplicates are allowed
		const duplicate = this.dropContainer.querySelector(`#${data}`)
		if (!duplicate || this.allowDuplicates) {
			if (this.effectAllowed === "move") {
				this.dropContainer.appendChild(droppedItem);
			} else if (this.effectAllowed === "copy") {
				this.dropContainer.appendChild(droppedItem.cloneNode(true));
			}
		} else {
			console.debug(`DragDrop:drop: Not going to add id[#${data}] as it already exists, and duplicates are not allowed`);
		}

		console.debug('DragDrop:drop firing', data);
		this.dragging = false;

		if (this.cbDrop) this.cbDrop(e);
	}

	/**
	 * Clear the drop container.
	 * ie: remove all dropped controls
	 *
	 * @memberof DragDrop
	 */
	clear() {
		const children = Array.from(this.dropContainer.children);
		children.forEach(child => {
			if (child.id !== "dropImage") child.remove();
		});
	}

	render() {
		return html`
			${this.addCss()}
			<div id="dropContainer" ${ref(this.dropCtrl)}
				@drop=${this.drop} @dragover=${this.dragOver} @dragleave=${this.dragLeave}>
				<slot></slot>
				<rps-svg id="dropImage" svg=${iconNames.copy}><rps-svg>
			</div>
		`;
	}
}
