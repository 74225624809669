import { Text } from '@odo/components/elements/typography';
import Duplicate from '@odo/components/widgets/duplicate';
import prepCustomOptionTree from '@odo/data/custom-options/prep-custom-option-tree';
import { useAttributeContext } from '@odo/hooks/attributes';
import type { ProductLoaderResponse } from '@odo/screens/deal/editor/widgets/product-loader';
import { ProductLoader } from '@odo/screens/deal/editor/widgets/product-loader';
import type {
  CustomOptionTree,
  EditorProductInterface,
} from '@odo/types/portal';
import { useCallback, useState } from 'react';

const DuplicateById = ({
  id,
  close,
  openInNewTab,
}: {
  id: number;
  close: () => void;
  openInNewTab?: boolean;
}) => {
  const { isReady: areAttributesLoaded, attributes } = useAttributeContext();

  const [isLoading, setIsLoading] = useState(true);

  const [customOptions, setCustomOptions] = useState<
    CustomOptionTree[] | undefined
  >(undefined);
  const [product, setProduct] = useState<EditorProductInterface | undefined>();

  const productLoaded = useCallback(
    ({ editorProduct, rawProductData }: ProductLoaderResponse) => {
      setProduct(editorProduct);
      if (rawProductData?.customOptions) {
        setCustomOptions(prepCustomOptionTree(rawProductData.customOptions));
      }
      setIsLoading(false);
    },
    []
  );

  if (isLoading) {
    return <ProductLoader id={id} callback={productLoaded} />;
  }

  if (!product || !customOptions) {
    return (
      <Text>There was an error prepping the product for duplication.</Text>
    );
  }

  return (
    <Duplicate
      product={product}
      customOptions={customOptions}
      areAttributesLoaded={areAttributesLoaded}
      attributes={attributes}
      close={close}
      openInNewTab={openInNewTab}
    />
  );
};

export default DuplicateById;
