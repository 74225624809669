import { html } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { styles } from './css/gauge.css.js';

/**
 * A Gauge to display a percentage value.
 * Colors are important as they denote the "category" that this value falls into.
 * An event is also fired when the control renders that contains the percentage and color category
 * Can be used for reacting to the number of errors (percentage) that fall into the "red" category
 *
 * @export
 * @class Gauge
 * @extends {CustomLitElement}
 */
export class Gauge extends CustomLitElement {
	/**
	 * @description - Makes extensive use of the var(--someval) here.
	 * The CSS variables that are used are generated in the "style" for each HTML element, and can then be accessed
	 * Per ELEMENT in the styling.
	 *
	 * @readonly
	 * @static
	 * @memberof Gauge
	 */
	static styles = styles;

	static get properties() {
		return {
			sizeRem: { type: Number },							// Size of chart in REM
			heading: { type: String },							// Heading for Chart
			percentage: { type: Number },						// The value as a percentage to display
			fontSize: { type: String },						// Override the font size
			colors: { type: Array },							// Array of colors to use for the pie chart items
			cbColorChange: { attribute: false },
		};
	}

	constructor() {
		super();
		this.sizeRem = 10;
		this.fontSize = '1.4rem';								// default, as per scaling REM properly
		this.heading = "Gauge";
		this.colors = [{ max: 30, color: "green" }, { max: 60, color: "yellow" }, { max: 100, color: "red" }];
		this.percentage = 85;
	}

	/**
	 * Fires when a percentage changes
	 * Fires an event containing the percentage and color
	 *
	 * @param {Number} percentage
	 * @memberof Gauge
	 */
	colorChange() {
		const color = this.getColor();
		const detail = { source: this.tagName, percentage: this.percentage, color };
		const e = new CustomEvent('colorChange', { detail, bubbles: true, composed: true, cancelable: true });
		this.dispatchEvent(e);
		console.debug('Gauge: colorChange', detail);
		if (this.cbColorChange) this.cbColorChange(e);
	}


	/**
	 * Get the color to use for the heading for this Gauge
	 *
	 * @param {Number} percentage - optional
	 * @return {String} color to display the heading in.
	 * @memberof Gauge
	 */
	getColor() {
		let color = this.colors[0].max;
		for (let i = this.colors.length - 1; i >= 0; i--) {
			if (this.colors[i].max > this.percentage) {
				color = this.colors[i];
			}
		}
		return color;
	}

	render() {
		const perc = this.percentage / 100 / 2;			// percentage back to a value between 0 and 1. then take half of it
		const color = this.getColor().color;
		this.colorChange();										// fire an event with the new percentage color

		return html`
			${this.addCss()}
			<style>
				.container .gauge-c {
					transform:rotate(${perc}turn);
				}
			</style>

			<div class="gauge-container" style="font-size: ${this.fontSize}; display: block">
				<div class="container" style="--size: ${this.sizeRem}rem;">
					<div class="gauge-b"></div>
					<div class="gauge-c"></div>
					<div class="gauge-data" style="color:${color}">
						<h1 id="percent">${this.percentage}%
							<div id="heading">${this.heading}</div>
						</h1>
					</div>
				</div>
			</div>
			`;
	}
}

