import { InputNumeric } from '@odo/components/elements/form-fields';
import type { InputProps } from '@odo/components/elements/form-fields/input';
import {
  isInteger,
  isIntegerOnChange,
  isCurrency,
  isCurrencyOnChange,
  isPositiveCurrency,
  isPositiveCurrencyOnChange,
  isPositiveInteger,
  isPositiveIntegerOnChange,
  isNumeric,
  isNumericOnChange,
  isPositiveNumeric,
  isPositiveNumericOnChange,
} from '@odo/utils/validation';
import type { ChangeEvent } from 'react';
import type { EditorNumericInput } from '@odo/types/portal';

type InputMaskingType = 'integer' | 'decimal' | 'currency';

interface InputMaskingConfig {
  type: InputMaskingType;
  allowNegative?: boolean;
  onChange: (input: EditorNumericInput) => void;
}

interface Masking {
  validate: (e: ChangeEvent<HTMLInputElement>['target']['value']) => boolean;
  parse: (value: string) => number | undefined | null;
}

const createParser =
  (validator: (value: string) => boolean, parser: (value: string) => number) =>
  (value: string) =>
    validator(value) ? parser(value) : null;

const maskingMap: Record<
  InputMaskingType,
  { unsigned: Masking; positive: Masking }
> = {
  integer: {
    unsigned: {
      validate: isIntegerOnChange,
      parse: createParser(isInteger, value => parseInt(value, 10)),
    },
    positive: {
      validate: isPositiveIntegerOnChange,
      parse: createParser(isPositiveInteger, value => parseInt(value, 10)),
    },
  },
  decimal: {
    unsigned: {
      validate: isNumericOnChange,
      parse: createParser(isNumeric, value => parseFloat(value)),
    },
    positive: {
      validate: isPositiveNumericOnChange,
      parse: createParser(isPositiveNumeric, value => parseFloat(value)),
    },
  },
  currency: {
    unsigned: {
      validate: isCurrencyOnChange,
      parse: createParser(isCurrency, value => parseFloat(value)),
    },
    positive: {
      validate: isPositiveCurrencyOnChange,
      parse: createParser(isPositiveCurrency, value => parseFloat(value)),
    },
  },
};

const inputMasking =
  ({ type, allowNegative, onChange }: InputMaskingConfig) =>
  (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    const masking = type in maskingMap && maskingMap[type];
    if (!masking) {
      throw new Error('Invalid masking config');
    }

    const { validate, parse } = allowNegative
      ? masking.unsigned
      : masking.positive;

    if (!validate(value)) return;
    const parsed = parse(value);
    onChange({ string: value, number: parsed });
  };

const InputNumericMasked = ({
  type,
  allowNegative,
  onChange,
  ...rest
}: Omit<InputProps, 'onChange'> & InputMaskingConfig) => (
  <InputNumeric
    inputMode={type === 'integer' ? 'numeric' : 'decimal'}
    onChange={inputMasking({ type, allowNegative, onChange })}
    prefix={type === 'currency' ? 'R' : undefined}
    {...rest}
  />
);

export default InputNumericMasked;
