import type { EditorProductInterface } from '@odo/types/portal';

let db: IDBDatabase | undefined;

const DB_NAME = 'buyers-portal';
const DB_OBJECT_STORE = 'drafts';
const DB_VERSION = 1;

/**
 * NOTE: it's approx ~40 times slower to create the connection than to just re-use it.
 * 1.3ms vs 0.03ms in my local testing.
 * We keep the opened connection in a global variable to avoid this overhead.
 * And have the variable cleared whenever the connection is closed (hopefully).
 */
const createConnection = (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    if (db) {
      resolve(db);
      return;
    }

    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onupgradeneeded = () => {
      // NOTE: not yet sure how to handle migrations if we ever add new object stores or something
      request.result.createObjectStore(DB_OBJECT_STORE, {
        autoIncrement: true,
      });
    };

    request.onerror = () => {
      // close the db in case that was the issue, will automatically be re-opened for the next attempt
      db?.close();
      reject();
    };

    request.onsuccess = () => {
      db = request.result;

      // when db gets closed (whether via cleanup or manually or whatever) clear our reference to it
      db.onclose = () => (db = undefined);

      resolve(db);
    };
  });
};

/**
 * Shamelessly stolen from someone else's package (that was too big and used too much `any` for my liking).
 * Instead just going to use their promisify request wrapper coz it's quite cool and I can remove the `any`.
 * @see https://github.com/jakearchibald/idb/blob/v8.0.0/src/wrap-idb-value.ts#L47
 */
const promisifyRequest: <T>(request: IDBRequest<T>) => Promise<T> = <T>(
  request: IDBRequest<T>
) => {
  const promise = new Promise<T>((resolve, reject) => {
    const cleanup = () => {
      request.removeEventListener('success', success);
      request.removeEventListener('error', error);
    };
    const success = () => {
      resolve(request.result);
      cleanup();
    };
    const error = () => {
      reject(request.error);
      cleanup();
    };
    request.addEventListener('success', success);
    request.addEventListener('error', error);
  });

  return promise;
};

/**
 * Also stolen from the same package as above. But I built the commit into it.
 */
const commit = (tx: IDBTransaction): Promise<void> => {
  const promise = new Promise<void>((resolve, reject) => {
    const cleanup = () => {
      tx.removeEventListener('complete', complete);
      tx.removeEventListener('error', error);
      tx.removeEventListener('abort', error);
    };
    const complete = () => {
      resolve();
      cleanup();
    };
    const error = () => {
      reject(tx.error || new DOMException('AbortError', 'AbortError'));
      cleanup();
    };
    tx.addEventListener('complete', complete);
    tx.addEventListener('error', error);
    tx.addEventListener('abort', error);
  });

  tx.commit();

  return promise;
};

/**
 * NOTE: this uses the only field I've designated as "required" for a draft: `isNew`.
 * If we ever decide to remove that field, we'll need to update this type guard to use something else.
 */
const isValidProduct = (
  product: EditorProductInterface | unknown
): product is EditorProductInterface =>
  typeof (product as EditorProductInterface) === 'object' &&
  typeof (product as EditorProductInterface).isNew === 'boolean';

type TransactionMode = 'read' | 'write';

const transactionModeMap: Record<TransactionMode, IDBTransactionMode> = {
  read: 'readonly',
  write: 'readwrite',
};

const transact = async (mode: TransactionMode = 'read') => {
  const db = await createConnection();
  const tx = db.transaction(DB_OBJECT_STORE, transactionModeMap[mode]);
  const store = tx.objectStore(DB_OBJECT_STORE);
  return { tx, store };
};

export const countDrafts = async () => {
  const { tx, store } = await transact();
  const count = await promisifyRequest(store.count());
  await commit(tx);
  return count;
};

export const getDraft = async (id: number) => {
  const { tx, store } = await transact();
  const product = await promisifyRequest(store.get(id));
  await commit(tx);

  if (!isValidProduct(product)) {
    throw new Error('Error getting product from indexedDB');
  }

  return product;
};

export const getAllDrafts = async () => {
  const { tx, store } = await transact();
  const products = await promisifyRequest(store.getAll());
  await commit(tx);

  if (!Array.isArray(products) || !products.every(isValidProduct)) {
    throw new Error('Error getting products from indexedDB');
  }

  return products;
};

export const createDraft = async (product: EditorProductInterface) => {
  const { tx, store } = await transact('write');
  const id = await promisifyRequest(store.add(product));
  await commit(tx);

  if (typeof id !== 'number') {
    throw new Error('Unexpected ID type');
  }

  return id;
};

export const updateDraft = async (
  id: number,
  product: EditorProductInterface
) => {
  const { tx, store } = await transact('write');
  await promisifyRequest(store.put(product, id));
  await commit(tx);
};

export const deleteDraft = async (id: number) => {
  const { tx, store } = await transact('write');
  await promisifyRequest(store.delete(id));
  await commit(tx);
};
