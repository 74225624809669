/**
 * @name rps-button-strip
 * @type {ButtonStrip}
 * A control that displays many buttons as a single contrtol
 * 
 * 
 * @attributes + @properties 				| Changes trigger a re-render
 * Casing usages:								| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {Array } buttons			| A list of buttons to create
 * 													Default is [
 * 														{ id: "one", text: "First" },
 * 														{ id: "two", text: "Second", leftSlot: iconNames.add },
 * 														{ id: "three", text: "Third", rightSlot: iconNames.add, selected: true },
 * 														{ id: "four", text: "Fourth", leftSlot: iconNames.add, rightSlot: iconNames.del },
 * 														{ id: "five", text: "Fifth" },
 * 														{ id: "six", text: "Sixth" },
 * 													];
 * 												| @important object structure contains optional attributes [selected, leftSlot, rightSlot]
 * 													for pre-selecting a button, and optional images in the left and right slots
 * 												
 * 	@property {String } customCss		| Specify a custom .css file to include, use cssPath to override elements,
 * 													DO NOT override the styling definition by re-defining the css styling
 * 													Put this file into the *window.cssPath* folder @see {link README.md}
 * 													eg: /public/css (where the other css files are)
 * 													Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css			| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 
 * 
 * @properties (JS only)
 * 	get selectedIds						| Retrieve an Array all the selected ID's
 * 
 * @Styling
 * 	@example class="multi-select"
 * 	multi-select							| By setting this class the button-strip allows multiple button selections
 * 
 * 
 * @methods
 * 	setStatus(buttonId)					| Same as programmatically pressing a button
 * 
 * @events
 * 	click										| Button click event
 * 
 * @callbacks
 * 	cbClick									| Click event callback
 * 
 * @slots
 * 	None
 * 
 * 
 * @example {lit}
 * BASIC
 * =====
 * 	import '@rps-wc/rps-buttons-strip';
 * 
 * 	<rps-buttons-strip @click=${e => console.log(e)}>
 * 	</rps-buttons-strip>
 * 
 * @example {ReactJs}
 * BASIC
 * =====
 * 	import '@rps-wc/rps-buttons-strip';
 * 
 * 	useEffect(() => {
 * 		strip.current.cbClick= {e => console.log(e)}
 * 	}, [])
 * 
 * 	return (
 * 		<rps-buttons-strip ref={strip}>
 * 		</rps-buttons-strip>
 * 	)
 * 
 * 
*/
import { ButtonStrip } from './src/buttons/ButtonStrip';
if (!window.customElements.get('rps-button-strip'))
	window.customElements.define('rps-button-strip', ButtonStrip);

