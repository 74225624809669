import { Status, type Validator } from '@odo/screens/deal/editor/types';

export const conditionsSectionValidators: Validator[] = [];

export const shopAndCategoriesSectionValidators: Validator[] = [
  {
    validate: product => (product.categories || []).length > 0,
    message: 'You must choose at least one category.',
    status: Status.error,
  },
];

const validators: Validator[] = [
  ...conditionsSectionValidators,
  ...shopAndCategoriesSectionValidators,
];

export default validators;
