import { useCurrentDealSource } from 'hooks/useCurrentDealSource';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './css/BusySpinner.module.css';
import {
  ProductSource,
  useProduct,
  useSetProduct,
} from '@odo/contexts/product';
import { useApplyDefaultAttributes } from 'hooks/useApplyDefaultAttributes';

const NewDealPrep = ({ isDealReady, setIsDealReady }) => {
  const product = useProduct();
  const setProduct = useSetProduct();
  const [hasTmpId, setHasTmpId] = useState(false);
  const [productResetRequired, setProductResetRequired] = useState(true);

  const [areAttributesReady, applyDefaultAttributes] =
    useApplyDefaultAttributes();

  const { deal, update } = useCurrentDealSource();

  /**
   * NOTE: product context needs to be wrapped around the entire app @see /src/App.js
   * This means that sometimes the previous product data is still in context when this is running.
   * The suspicion is that this can cause unsaved custom options to carry over to other products.
   * We can replicate this for new deals, but not existing.
   * For now we'll reset the product data before continuing and hope that resolves the issue.
   * TODO: BP-412, BP-413: remove this reset.
   */
  useEffect(() => {
    setProduct(undefined);
    setProductResetRequired(false);
  }, [setProduct]);

  useEffect(() => {
    // wait for the product context to be reset
    if (productResetRequired) return;
    // add our tmpId to the product context for reference
    if (isDealReady && !hasTmpId && deal.meta.tmpId) {
      if (product?.id !== deal.meta.tmpId) {
        setProduct({ id: deal.meta.tmpId, source: ProductSource.portal });
      }
      setHasTmpId(true);
    }
  }, [productResetRequired, isDealReady, hasTmpId, deal, product, setProduct]);

  useEffect(() => {
    // wait for the product context to be reset
    if (productResetRequired) return;
    // we've already prepped this deal, let's not waste time doing it again
    if (isDealReady) return;
    // wait for our attributes to be ready for applying
    if (!areAttributesReady) return;

    applyDefaultAttributes({
      dealModel: deal,
      dealUpdate: update,
      isNew: true,
    });

    setIsDealReady(true);
  }, [
    productResetRequired,
    deal,
    update,
    isDealReady,
    setIsDealReady,
    areAttributesReady,
    applyDefaultAttributes,
  ]);

  // render a loader while we load & prep the deal model
  if (!isDealReady) {
    return (
      <div className={styles.busySpinnerContainerNonBlocking}>
        <rps-card>
          <div className={styles.busySpinner}>
            <rps-spinner-pause />
            Loading attributes from backend...
          </div>
        </rps-card>
      </div>
    );
  }

  return null;
};

NewDealPrep.propTypes = {
  isDealReady: PropTypes.bool,
  setIsDealReady: PropTypes.func,
};

export default NewDealPrep;
