/**
 * @name rps-input-modal
 * @type {InputModal}
 * 	An input modal. Typically used when the user is required to enter a single value in a modal popup
 *
 *	@attributes + @properties
 * 	@property { String }		label							| The label for the input control
 * 	@property { String }		type							| Input type to pass to modal (i.e.: number, text, currency, date, file, etc)
 * 	@property { Boolean }	headerText					| Modal header text. Default="Enter value"
 * 	@property { Boolean }	opened	= false			| Is the control in the opened state
 * 	@property {String}		customCss					| Specify a custom .css file to include, use cssPath to override elements,
 * 																		DO NOT override the styling definition by re-defining the css styling
 * 																		Put this file into the *window.cssPath* folder @see {link README.md}
 * 																		eg: /public/css (where the other css files are)
 * 																		Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}		css							| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 *
 *
 * @styling
 * Status:
 * 	help									 						| Displays the background color in the "help color"
 * 	info															| Displays the background color in the "info color"
 * 	success														| Displays the background color in the "success color"
 * 	warning														| Displays the background color in the "warning color"
 * 	error															| Displays the background color in the "error color"
 * @size
 * 	half															| Display the modal using "half" width of the screen
 * 	full															| Display the modal using "full" width of the screen
 *
 *
 * @events
 * 	confirm														| fires when the user clicks on the "confirm" button
 * 	cancel														| fires when the user clicks on the "cancel" button
 *
 * @callbacks
 * 	cbConfirm													| Callback for the confirm event
 * 	cbCancel														| Callback for the Cancel (close) event
 *
 * @methods
 * 	Show															| Show the modal
 * 	Hide															| Hide the modal
 *
 *
 * @properties (JS Only)
 * 	get inputControl()
 * 	get modalControl()
 *
 *	@slots
 * 	No Slots
 *
 *
 * @example @Lit
 * @basic
 * =====
 * import '@rps-wc/rps-input-modal';
 *
 * <rps-input-modal></rps-input-modal>
 *
 *
 */
import { InputModal } from './src/modal/InputModal.js';
if (!window.customElements.get('rps-input-modal'))
	window.customElements.define('rps-input-modal', InputModal);
