/**
 * @name rps-chip-list
 * @type {ChipList}
 * 	A control for creating multiple chips.
 *
 * @description
 * 	User enters text in an input, and presses 'Enter' to create a chip which is added to the list
 * 	where it can be deleted individually or en-mass.
 *
 * @attributes + @properties 										| Changes trigger a re-render
 * Casing usages														| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {Array} 	chips									| All the chips that have been created
 * 																			@description If you would like to add 'pre-selected' items to the chip list then
 * 																				add an array of items eg: [{id,text}]
 * 	@property {String}	placeholder							| The text to display in the placeholder
 * 	@property {String}	label = 'Chips'					| The input label
 * 	@property {String}	removeTitle = 'Remove all'		| The title (tooltip) of the delete all button
 * 	@property {Boolean}	required 							| Is a choice required
 * 	@property {Boolean} 	disabled 							| Is this control disabled?
 * 	@property {Boolean} 	readonly 							| Is this control readonly?
 * 	@property {Boolean} 	hideLabel 							| Must the label be hidden?
 * 	@property {String}	name 									| The form name
 * 	@property {String}	customCss 							| Specify a custom .css file to include, use cssPath to override elements,
 * 																			DO NOT override the styling definition by re-defining the css styling
 * 																			Put this file into the *window.cssPath* folder @see {link README.md}
 * 																			eg: /public/css (where the other css files are)
 * 																			Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css									| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 *
 *
 * @properties (JS Only)
 * 	get inputControl												| A reference to the input control.
 * 	get chipsContainer											| A reference to the Chips container
 *
 * @styling classes
 * 	help 																| Displays the color in the "help color"
 * 	info																| Displays the color in the "info color"
 * 	success															| Displays the color in the "success color"
 * 	warning															| Displays the color in the "warning color"
 * 	error																| Displays the color in the "error color"
 *
 * @methods
 * 	None
 *
 * @events
 * 	change															| Fires when a chip is added to or removed from the chip list
 *
 * @callbacks
 * 	cbChange															| Called when a chip is added to or removed from the chip list
 *
 * @slots
 * 	No slots
 *
 *
 * @example @Lit
 * @basic
 * ==============================
 * 	import '@rps-wc/wc/rps-chip-list';
 *
 * 	const chips = [
 * 		{ "id": "1", "text": "John"},
 * 		{ "id": "2", "text": "Mackie"},
 * 		{ "id": "3", "text": "Billy"},
 * 		{ "id": "4", "text": "baby"}
 * 	];
 *
 * 	<rps-chip-list .chips=${chips}>
 * 	</rps-chip-list>
 *
 *
 * @example @ReactJS
 * @basic
 * 	// import RPSChipList for the local React Project
 *
 * 	const chips = [
 * 		{ "id": "1", "text": "John"},
 * 		{ "id": "2", "text": "Mackie"},
 * 		{ "id": "3", "text": "Billy"},
 * 		{ "id": "4", "text": "baby"}
 * 	];
 *
 * 	<RPSChipList chips={chips}>
 * 	</RPSChipList>
 *
*/

import { ChipList } from './src/lists/ChipList';
if (!window.customElements.get('rps-chip-list'))
	window.customElements.define('rps-chip-list', ChipList);

