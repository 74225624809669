import { Grid } from '@odo/components/elements/layout/grid';
import { Flex, FlexItem } from '@odo/components/elements/layout/flex';
import { cssColor } from '@odo/utils/css-color';
import type { ButtonProps } from '@odo/components/elements/button';
import Button from '@odo/components/elements/button';
import styled from '@odo/lib/styled';

const ButtonStripWrapper = styled(Grid).attrs({ as: 'section' })`
  border: 1px solid #c2c2c2;
`;

ButtonStripWrapper.defaultProps = {
  p: 1,
  gap: 1,
  bg: cssColor('foreground-alt'),
  borderRadius: '32px',
};

const ButtonStripLabel = styled.label`
  color: ${cssColor('text')};
  text-indent: 12px;
`;

interface ButtonStripProps<T> {
  label: string;
  hue?: ButtonProps['hue'];
  options: T[];
  selectedOption?: T;
  onSelect: (option: T) => void;
}

const ButtonStrip = <T extends { id: string | number; label: string }>({
  options,
  selectedOption,
  onSelect,
  label,
  hue = 'grey',
}: ButtonStripProps<T>) => (
  <Flex flexDirection="column" gap={2} width="100%">
    <ButtonStripLabel>{label}</ButtonStripLabel>
    <ButtonStripWrapper gridTemplateColumns={`repeat(${options.length}, auto)`}>
      {options.map(option => (
        <FlexItem key={option.id} overflow="hidden" height="100%">
          <Button
            hue={hue}
            variant={option.id === selectedOption?.id ? 'solid' : 'flat'}
            onClick={() => onSelect(option)}
            width="100%"
            height="100%"
          >
            <span
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {option.label}
            </span>
          </Button>
        </FlexItem>
      ))}
    </ButtonStripWrapper>
  </Flex>
);

export default ButtonStrip;
