import { Input } from '@odo/components/elements/form-fields';
import { useChangeProduct } from '@odo/contexts/product-editor';
import type { EditorProductImage } from '@odo/types/portal';

const ImageLabel = ({
  image,
  hideInputLabel,
}: {
  image: EditorProductImage;
  hideInputLabel?: boolean;
}) => {
  const change = useChangeProduct();

  return (
    <Input
      label={!hideInputLabel ? 'Label' : undefined}
      value={image.label || ''}
      onChange={e => {
        const label = e.target.value;
        change({
          fieldId: `images.${image.id}.label`,
          label: 'Image Label',
          apply: to => {
            to.images = to.images
              ? to.images.map(i => (i.id === image.id ? { ...i, label } : i))
              : to.images;
          },
        });
      }}
    />
  );
};

export default ImageLabel;
