/**
 * Copy of the DropIndicator from `@atlaskit/pragmatic-drag-and-drop-react-drop-indicator`
 * but using our styled-system instead of @emotion/styled.
 * @see https://github.com/atlassian/pragmatic-drag-and-drop/blob/main/packages/react-drop-indicator/src
 */
import { breakpointsEm, spacesPx } from '@odo/contexts/theme/provider';
import styled from '@odo/lib/styled';
import { cssColor } from '@odo/utils/css-color';

const LINE_THICKNESS = 2;
const TERMINAL_SIZE = 8;

const terminalOffset = (LINE_THICKNESS - TERMINAL_SIZE) / 2;
const lineOffset = TERMINAL_SIZE / 2;

type Orientation = 'horizontal' | 'vertical';
type Edge = 'top' | 'right' | 'bottom' | 'left';

const lineOffsetCalc = (gap: number) =>
  `calc(-0.5 * (${gap + LINE_THICKNESS}px))`;

const gapMediaQuery = (gap: number, index: number) => `
  @media screen and (min-width: ${breakpointsEm[index - 1]}em) {
    --local-line-offset: ${lineOffsetCalc(gap)};
  }
`;

const Indicator = styled.div<{ gap: number[]; color?: string }>`
  --color: ${props => props.color || cssColor('palette-blue')};
  --local-line-offset: ${props => lineOffsetCalc(props.gap[0])};

  ${props => props.gap[1] && gapMediaQuery(props.gap[1], 1)}
  ${props => props.gap[2] && gapMediaQuery(props.gap[2], 2)}
  ${props => props.gap[3] && gapMediaQuery(props.gap[3], 3)}

  display: block;
  position: absolute;
  z-index: 1;
  pointer-events: none;
  background: var(--color);

  &::before {
    content: '';
    width: ${TERMINAL_SIZE}px;
    height: ${TERMINAL_SIZE}px;
    box-sizing: border-box;
    position: absolute;
    border: ${LINE_THICKNESS}px solid var(--color);
    border-radius: 50%;
  }

  &.orientation-horizontal {
    height: ${LINE_THICKNESS}px;
    left: ${lineOffset}px;
    right: 0;
    &::before {
      left: -${TERMINAL_SIZE}px;
    }
  }

  &.orientation-vertical {
    width: ${LINE_THICKNESS}px;
    top: ${lineOffset}px;
    bottom: 0;
    &::before {
      top: -${TERMINAL_SIZE}px;
    }
  }

  &.edge-top {
    top: var(--local-line-offset);
    &::before {
      top: ${terminalOffset}px;
    }
  }

  &.edge-right {
    right: var(--local-line-offset);
    &::before {
      right: ${terminalOffset}px;
    }
  }

  &.edge-bottom {
    bottom: var(--local-line-offset);
    &::before {
      bottom: ${terminalOffset}px;
    }
  }

  &.edge-left {
    left: var(--local-line-offset);
    &::before {
      left: ${terminalOffset}px;
    }
  }
`;

const edgeToOrientationMap: Record<Edge, Orientation> = {
  top: 'horizontal',
  bottom: 'horizontal',
  left: 'vertical',
  right: 'vertical',
};

const DropIndicator = ({
  edge,
  gap,
  color,
}: {
  edge: Edge;
  gap: number | number[];
  color?: string;
}) => (
  <Indicator
    className={[
      `orientation-${edgeToOrientationMap[edge]}`,
      `edge-${edge}`,
    ].join(' ')}
    // NOTE: the gap here doesn't use our styled-system coz it's part of a calculation
    // so we need to manually get the px from our theme file and match it with the supplied gap
    gap={(Array.isArray(gap) ? gap : [gap]).map(gap => spacesPx[gap])}
    color={color}
  />
);

export default DropIndicator;
