import React, { useEffect, useRef, useState } from 'react';
import ImageList from './ImageList';
import VideoList from './VideoList';
import { DealImagesAndVideos } from 'models/ImagesAndVideos';
import { useCurrentDealSource } from 'hooks/useCurrentDealSource';
import {
  RPSListContainer,
  RPSCard,
  RPSCheckbox,
} from '@rps/web-components/build/react-wrappers';
import { BusyPopup } from 'components/uiComponents/BusyPopup';
import { LoadingPopup } from 'components/uiComponents/LoadingPopup';
import { FormLayout } from 'components/deals/shared/FormLayout';
import { Deal } from 'models/Deal';
import { GET_ODO_IMAGES } from 'gql/deals/getODOImages';
import { CREATE_ODO_IMAGE } from 'gql/deals/createODOImage';
import { useODOMutation } from 'hooks/useODOMutation';
import { ApolloClients } from 'services/apolloClients';
import { useWindowDropzoneEffect } from 'hooks/useWindowDropzoneEffect';
import { uuid } from '@rps/js-utils/utils';
import toast from 'react-hot-toast';
import { concurrentImageUploadsEnabled } from 'config';

const IMAGE_UPLOAD_TOAST_ID = 'product-image-upload';

const IMAGE_UPLOAD_MAX_ATTEMPTS = 5;

const imageUploadToastOptions = {
  style: { background: '#0093D0', color: '#f2f2f2' },
  iconTheme: { primary: '#0093D0', secondary: '#fff' },
};

const ACCEPTED_FILE_TYPES = [
  'image/gif',
  'image/png',
  'image/jpeg',
  'image/webp',
];

const getNextHighestNumber = numbers => {
  let highest = 0;
  numbers.forEach(num => {
    if (num > highest) {
      highest = num;
    }
  });
  return highest;
};

const ImagesAndVideosScreen = () => {
  const fileInputRef = useRef();
  const hasImagePositionFixRun = useRef(false);
  const currentDeal = useCurrentDealSource();
  const handleInput = currentDeal.createInputHandler(
    Deal.MODELS.IMAGES_AND_VIDEOS
  );

  const [busyUploading, setBusyUploading] = useState(false);
  const [busyFetching, setBusyFetching] = useState(false);

  const fetchImages = async () => {
    if (currentDeal.deal.meta.id) {
      setBusyFetching(true);
      const clients = new ApolloClients();
      const res = await clients.odoClient.query({
        query: GET_ODO_IMAGES,
        variables: {
          productId: currentDeal.deal.meta.id,
        },
        errorPolicy: 'none',
        fetchPolicy: 'no-cache',
      });
      if (res?.data?.getProductImages?.length > 0) {
        const imagesSnapshot = [...currentDeal.deal.imagesAndVideos.images];
        currentDeal.deal.imagesAndVideos.images = res.data.getProductImages.map(
          image => {
            const original = imagesSnapshot.find(({ id }) => id === image.id);
            return {
              ...image,
              ...(typeof original !== 'undefined' ? original : {}),
              enabled: !image.excludeImageTypes,
            };
          }
        );
        currentDeal.update();
      } else {
        currentDeal.deal.imagesAndVideos.images = [];
        currentDeal.update();
      }
      setBusyFetching(false);
    }
  };

  const [createODOImage] = useODOMutation(CREATE_ODO_IMAGE);

  const uploadImages = imageFiles => {
    if (!currentDeal.deal.meta.id) return;

    const uploadAll = async () => {
      setBusyUploading(true);

      let imageUploadCounter = 0;
      let imageUploadFailures = 0;
      const totalImages = imageFiles.length;

      const loadingToastId = toast.loading(
        `Uploading images: ${imageUploadCounter}/${totalImages}`,
        { id: IMAGE_UPLOAD_TOAST_ID, ...imageUploadToastOptions }
      );

      const positions = currentDeal.deal.imagesAndVideos.images.map(
        ({ position }) => position
      );

      let nextPosition = getNextHighestNumber(positions);

      const uploadImage = async file => {
        const position = ++nextPosition;
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.addEventListener('load', async ev => {
            try {
              const dataMatch = ev.target.result.match(/^data:(.+);.+,(.+)/);
              if (dataMatch) {
                const image = {
                  // NOTE: when we do concurrent uploads, the API assigns the same position to all these images
                  // so for now we will manually set an image position unique to each image
                  mimetype: dataMatch[1],
                  image: dataMatch[2],
                  filename: uuid(),
                  position,
                };

                let error = 'Failed after multiple attempts';
                try {
                  let completed = false;
                  let attempts = 0;
                  do {
                    attempts++;

                    const result = await createODOImage({
                      variables: {
                        productId: +currentDeal.deal.id,
                        imageFiles: [image],
                      },
                      errorPolicy: 'ignore',
                    });

                    const createdImages = result?.data?.createProductImages;
                    if (
                      typeof createdImages !== 'undefined' &&
                      createdImages !== null &&
                      Array.isArray(createdImages)
                    ) {
                      const [firstImage] = createdImages;
                      if (
                        typeof firstImage === 'number' ||
                        (typeof firstImage.id === 'string' &&
                          firstImage.id !== '')
                      ) {
                        completed = true;
                        imageUploadCounter++;

                        toast.loading(
                          `Uploading images: ${imageUploadCounter}/${totalImages}`,
                          {
                            id: IMAGE_UPLOAD_TOAST_ID,
                            ...imageUploadToastOptions,
                          }
                        );

                        resolve();
                        return;
                      }
                    }
                  } while (attempts < IMAGE_UPLOAD_MAX_ATTEMPTS && !completed);
                } catch (e) {
                  error = e;
                }

                imageUploadFailures++;
                reject(error);
              }
            } catch (error) {
              console.error(
                '[ImagesAndVideosScreen] Error uploading Image(s): ',
                error
              );
            }

            reject();
          });

          reader.readAsDataURL(file);
        });
      };

      // wait for all image uploads
      if (concurrentImageUploadsEnabled) {
        await Promise.allSettled(
          imageFiles
            .filter(file => ACCEPTED_FILE_TYPES.includes(file?.type))
            .map(uploadImage)
        );
      } else {
        for (const image of imageFiles.filter(file =>
          ACCEPTED_FILE_TYPES.includes(file?.type)
        )) {
          await uploadImage(image);
        }
      }

      setBusyUploading(false);
      toast.dismiss(loadingToastId);

      if (imageUploadFailures > 0) {
        toast.error(`Failed to upload: ${imageUploadFailures}/${totalImages}`, {
          duration: 7500,
        });
      }

      fetchImages();

      if (fileInputRef && fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    };

    uploadAll();
  };

  useWindowDropzoneEffect(uploadImages, [
    currentDeal.deal.imagesAndVideos.images,
  ]);

  const handleManualUpload = ev => {
    const imageList = [];
    for (let i = 0; i < ev.target.files.length; i++) {
      imageList.push(ev.target.files[i]);
    }
    uploadImages(imageList);
  };

  /**
   * Fix all image positions (ensure there are no duplicates).
   */
  useEffect(() => {
    // abort if we've run this before
    if (hasImagePositionFixRun.current) return;

    const images = currentDeal.deal.imagesAndVideos.images;

    let uniquePosList = [];
    const hasDuplicates = images.some(img => {
      if (uniquePosList.includes(img.position)) return true;
      uniquePosList.push(img.position);
      return false;
    });

    // loop over images in order and give them incrementing positions
    if (hasDuplicates) {
      let pos = 0;
      images.forEach(img => {
        img.position = pos++;
        img.changed = true;
      });
      currentDeal.update();
    }

    // ensure we don't run this again while still mounted (cannot avoid it on dismount and remount)
    hasImagePositionFixRun.current = true;
  }, [currentDeal]);

  return (
    <FormLayout model={Deal.MODELS.IMAGES_AND_VIDEOS}>
      <RPSListContainer className="horizontal">
        <RPSCard css=":host { width: 100%; }">
          <div slot="header">
            <h5>Images</h5>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            <RPSCheckbox
              label="Photographed by Studio"
              cbInput={handleInput(
                DealImagesAndVideos.FIELDS.IS_PHOTOGRAPHED_BY_STUDIO
              )}
              checked={currentDeal.deal.imagesAndVideos.isPhotographedByStudio}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: '8px',
              ...(!currentDeal.deal.meta.id && {
                opacity: '0.25',
                pointerEvents: 'none',
                cursor: 'not-allowed',
              }),
            }}
          >
            <input
              ref={fileInputRef}
              type="file"
              onInput={handleManualUpload}
              multiple
              accept={ACCEPTED_FILE_TYPES.join(',')}
              style={{ cursor: 'pointer' }}
            />
            <em>Accepted file types: {ACCEPTED_FILE_TYPES.join(', ')}</em>
          </div>

          {currentDeal.deal.imagesAndVideos.images.length > 0 && (
            <ImageList
              images={currentDeal.deal.imagesAndVideos.images}
              css="picture img { object-fit: contain  }"
            />
          )}
        </RPSCard>

        <RPSCard css=":host { width: 100%; }">
          <div slot="header">
            <h5>Videos</h5>
          </div>
          <VideoList />
        </RPSCard>
      </RPSListContainer>

      <BusyPopup show={busyUploading} message="Uploading Images..." />
      <LoadingPopup message="Loading images..." open={busyFetching} />
    </FormLayout>
  );
};

export default ImagesAndVideosScreen;
