/**
 * @name rps-update-user
 * @type {UpdateUser}
 * A control for updating a users rights
 * 
 * // NB: to pre-selecting items can be done in 1 of the following 3 ways
 * 1. adding a property "selected" to the items (boolean)
 * 2. passing a separate array of objects "preSelectItems" where the property "id" is used to mark the item as selected
 * 3. the function "displayFunction" can set the value there as well, see example below. This property is "optional"
 * 
 * 
 * @attributes + @properties 				| Changes trigger a re-render
 * Casing usages:								| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String} customCss		| Specify a custom .css file to include, use cssPath to override elements,
 * 													DO NOT override the styling definition by re-defining the css styling
 * 													Put this file into the *window.cssPath* folder @see {link README.md}
 * 													eg: /public/css (where the other css files are)
 * 													Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css			| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 												
 * 
 * 
 * @example+ @Lit
 * import '@rps-wc/rps-update-user';
 * 
 * 	const users = [
 * 				{"id": 1, "firstName": "John",	"lastName": "Smith", "email": "john.smith@redpandasoftware.co.za", "class": ""},
 * 				{"id": 2, "firstName": "Mackie", "lastName": "Smith", "email": "mackie.smith@redpandasoftware.co.za", "class": "", selected: true},
 * 				{"id": 3, "firstName": "Billy", "lastName": "Bob", "email": "billy.bob@redpandasoftware.co.za", "class": "" }
 * 			];
 * 	const claimDisplayFields = ["firstName", "lastName"] 					// this fields to display in the "claims" section
 * 	const userDisplayFields = ["firstName", "email"] 						// the fields to display in the "users" section
 * 	const claims=[ {id:1, text: "reports"},{id:2, text: "CRUD"}];
 * 	const existingClaims = [{ id: 1 }];											// a list of claims that this user already has
 * 	
 * 	<rps-update-user @users-selected="${selectedHandler}"
 * 		headerTitle="Select User's rights"
 * 		placeholder="enter some text"9
 * 		includeCss="./custom.css"
 * 		claimDisplayFields={claimDisplayFields}
 * 		userDisplayFields={userDisplayFields}
 * 		claims={claims}
 * 		existingClaims={existingClaims}
 * 		users={users}
 * 	>
 * 	</rps-update-user>
 * 
*/

import { UpdateUser } from './src/user/UpdateUser';
if (!window.customElements.get('rps-update-user'))
	window.customElements.define('rps-update-user', UpdateUser);

