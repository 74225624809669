import { html } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { DOM, findByCallback } from '../../rps-functions';
import { styles } from './css/tree.css.js';
import '../../rps-svg';
import { iconNames } from '../../svg-icons';

export class Tree extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			data: { type: Array },
			cbClick: { attribute: false },
			cbClickOptions: { attribute: false },
		};
	}


	constructor() {
		super();
		this.data = [
			{
				id: 'a',
				text: 'Dans Family',
				leftSvg: iconNames.calendar,
				right: {
					svg: iconNames.error,
					tooltip: "my tooltip!!"
				},
				children: [
					{
						id: 'a1',
						text: 'family jannie',
						leftSvg: iconNames.dealAdd,
						children: [
							{
								id: 'a11',
								text: 'Mackie pet',
								leftSvg: iconNames.dealEdit,
							},
							{
								id: 'a12',
								text: 'Fluffy Pet',
								leftSvg: iconNames.dealUpload,
							},
						],
					},
					{
						id: 'a2',
						text: 'family sarie',
						rightSvg: iconNames.angleDown,
					},
				],
			},
			{
				id: 'b',
				text: 'Mikes family',
				leftSvg: iconNames.search,
			},
			{
				id: 'c',
				text: 'Sammys family',
				text: 'Sammys family',
			},
		];
		this.lastSelected;
	}

	/**
	 * Collapse the tree and its children
	 * @notes Toggles the "open" class on all <li> elements fot this "id" and all children
	 *
	 * @param {String} id The Id for the element to toggle the open class for
	 * @memberof Tree
	 */
	collapseUnder(id) {
		const dom = new DOM();
		//const tree = dom.findFirst(document.body, 'rps-tree');
		//let found = dom.findId(tree, id);
		let found = dom.findId(this.renderRoot, id);
		found.classList.toggle('open');

		const children = Array.from(found.querySelectorAll('li'));
		children.forEach(element => {
			element.classList.toggle('open');
		});
	}

	/**
	 * Fired when the user clicks on the <a>
	 *
	 * @param {Event} e
	 * @memberof Tree
	 */
	_click(e) {
		console.debug(e);

		e.stopPropagation();

		const dom = new DOM();
		const li = dom.walkUp(e.target, 'LI');
		const span = li.querySelector('span');
		const sourceObject = findByCallback(this.data, (item) => item.id === li.id);

		if (this.lastSelected) this.lastSelected.classList.toggle('selected', false);

		li.classList.toggle('open');
		span.classList.toggle('selected', true);
		this.lastSelected = span;

		const detail = {
			control: e.target,
			sourceObject,
			source: this.tagName,
			id: li.id,
			name: this.name,
		};

		e = new CustomEvent('click', { bubbles: true, cancelable: true, composed: true, detail });
		this.dispatchEvent(e);

		if (this.cbClick) this.cbClick(e);
	}

	/**
	 * Fired when the right icon or customRenderer object is clicked on
	 *
	 * @param {Event} e
	 * @memberof Tree
	 */
	_clickOptions(e) {
		console.debug(e);

		e.stopPropagation();
		e.preventDefault();

		const dom = new DOM();
		const li = dom.walkUp(e.target, 'LI');
		const sourceObject = findByCallback(this.data, (item) => item.id === li.id);

		li.classList.toggle('open');

		const detail = {
			control: e.target,
			sourceObject,
			source: this.tagName,
			id: li.id,
			name: this.name,
		};

		e = new CustomEvent('clickOptions', { bubbles: true, cancelable: true, composed: true, detail });
		this.dispatchEvent(e);

		if (this.cbClickOptions) this.cbClickOptions(e);
	}

	_renderItems(tree, className = '') {
		if (!tree) return '';													// if there are no items in this collection, end processing.
		if (tree.length === 0) return ''; 									// Same for zero-length arrays

		const items = tree.map(item => {
			if (item.customRender) {
				return html`
				<li id="${item.id}" @click=${this._click} class="custom-render">
					<div class="custom-content">${item.customRender(item)}</div>
					${this._renderItems(item.children)}
				</li>
			`;
			} else {
				return html`
				<li id="${item.id}">
					${html`
						<span @click=${this._click}>
							<div class="left-slot">
								${item.leftSvg ? html`<rps-svg svg="${item.leftSvg}" title="${item.text}"></rps-svg>` : ''}
							</div>
							${item.middle && item.middle.customRender
							? item.middle.customRender(item)
							: html`<a href="#">${item.text}</a>`
						}
							<div class="right-slot">
								${item.right && item.right.svg ? html`<rps-svg svg="${item.right.svg}" title="${item.right.tooltip}"
										@click=${this._clickOptions}></rps-svg>` : ''}
								${item.right && item.right.customRender ? item.right.customRender(item) : ''}
							</div>
						</span>
					`}
					${this._renderItems(item.children)}
				</li>
			`;
			}
		});

		return html`<ul class="${className}">${items}</ul>`;
	}

	render() {
		return html`
		${this.addCss()}
		<div class="tree-container ${this.className || 'default'}">
			${this._renderItems(this.data, 'tree')}
		</div>
		`;
	}
}


