/**
 * @name rps-layout-header
 * @type {LayoutHeader} 
 * 	The layout header component of the system.
 * 
 * 
 * @attributes + @properties 				| Changes trigger a re-render
 * Casing usages								| Attribute in html (lower case), Property in JS (Camel Case)
 *  
 * 	@property {String} headerTitle	| The title to show in the header (for the web-site)
 * 	@property {String} logo				| The image (url) fot the web-site image displayed in the header
 * 	@property {String} version			| The software version
 * 	@property {Array}	 toolBarButtons| The Array of items used to generate the toolbar
 * 													Default = [
 * 														{ id: "settings", svg: `${iconNames.settings}`, title: "Settings" },
 * 														{ id: "user", svg: `${iconNames.user}`, title: "User" },
 * 													];
 * 	@property {String} css				| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 	@property {String} customCss		| Specify a custom .css file to include, use cssPath to override elements,
 * 													DO NOT override the styling definition by re-defining the css styling
 * 													Put this file into the *window.cssPath* folder @see {link README.md}
 * 													eg: /public/css (where the other css files are)
 * 													Suggested use: use sub-folders, dont put it all into the base
 * 
 * @properties (JS only)
 * 	get burgerDivControl					| A reference to the burger menu control.
 * 	get offlineControl					| A reference to the offline control.
 * 	get offlineToastControl				| A reference to the offline toast control.
 * 
 * @methods
 * 	menuDisplayToggle						| Show or hide the left menu.
 * 	rightMenuDisplayToggle				| Show or hide the right menu.
 * 
 * @events
 * 	None
 * 
 * @callbacks
 * 	None
 * 
 * @slots
 * 	None
 * 
 * @example
 * 	Not normally manually instantiated as its part of <rps-layout>
 * 	If you want to implement it the refer to 
 * 		@link {see src/layout/Layout.js} for an example of how this is done
 * 
 * */

import { LayoutHeader } from './src/layout/LayoutHeader';
if (!window.customElements.get('rps-layout-header'))
	window.customElements.define('rps-layout-header', LayoutHeader);

