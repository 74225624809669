import React from 'react';
import Routes from './routing/Routes.jsx';

import { DataSourceProvider, DataSources } from './contexts/DataSource';
import { CurrentDealProvider } from './contexts/CurrentDealContext';
import { ModalProvider } from './contexts/Modal';
import { useModalProvider } from './hooks/useModalProvider';
import { Toaster } from 'react-hot-toast';
import styles from './App.module.css';

/**
 * NOTE: ProductProvider & CustomOptionsEditorProvider belongs only above the deal editor wizard/screen.
 * but for now it needs to be above the RedPanda CurrentDealProvider,
 * so that it can be fed the deal id from there after saving new deals,
 * and so that we can save custom options from the header.
 * TODO: BP-534: move this down as many layers as possible once we use our Product(Editor)Provider.
 */
import { ProductProvider } from '@odo/contexts/product';
import { CustomOptionsEditorProvider } from '@odo/contexts/custom-options-editor';
import { ThemeProvider } from '@odo/contexts/theme';

const App = () => {
  const modalProvider = useModalProvider();

  return (
    <>
      <ThemeProvider>
        <div className={styles.toaster}>
          <Toaster reverseOrder position="bottom-right" />
        </div>
      </ThemeProvider>

      <ModalProvider value={modalProvider}>
        <DataSourceProvider value={DataSources}>
          <ProductProvider>
            <CustomOptionsEditorProvider>
              <CurrentDealProvider>
                <Routes />
              </CurrentDealProvider>
            </CustomOptionsEditorProvider>
          </ProductProvider>
        </DataSourceProvider>
      </ModalProvider>
    </>
  );
};

export default App;
