
/**
 * A class dealing with DOM elements related to web-components
 *
 * @export
 * @class DOM
 */
export class DOM {

	/**
	 * Walk up the DOM until the DOM tagname is found
	 *
	 * @param {Object} DOMElement - The DOM element to start walking up from
	 * @param {string} tagName - The element that needs to be found
	 * @memberof DOM
	 */
	walkUp(DOMElement, tagName) {
		tagName = tagName.toUpperCase();				// TagNames are ALWAYS in upper case.
		let desiredElement = DOMElement;

		while (true) {
			//console.debug('DOM:walkUp TagName', desiredElement.tagName);
			if (desiredElement.tagName === tagName) return desiredElement;		// Walk successful, exit

			desiredElement.parentNode ? desiredElement = desiredElement.parentNode : desiredElement = desiredElement.host;
			if (!desiredElement) return undefined;										// Walk failed, DOM walked and nothing matched
		}
	}

	/**
	 * Walk up the DOM until the DOM tagname is found
	 *
	 * @param {Object} DOMElement - The DOM element to start walking up from
	 * @param {string} id - The id of the element that needs to be found
	 * @memberof DOM
	 */
	walkUpById(DOMElement, id) {
		let desiredElement = DOMElement;

		while (true) {
			//console.debug('DOM:walkUpById id', desiredElement.id);
			if (desiredElement.id === id) return desiredElement;		// Walk successful, exit

			desiredElement.parentNode ? desiredElement = desiredElement.parentNode : desiredElement = desiredElement.host;
			if (!desiredElement) return undefined;										// Walk failed, DOM walked and nothing matched
		}
	}

	/**
	 * Walk up the DOM tree until a parent is found with the desired class
	 *
	 * @param {DOMElement} DOMElement
	 * @param {String} className
	 * @return {DOMElement} 
	 * @memberof DOM
	 */
	walkUpByClass(DOMElement, className) {
		let desiredElement = DOMElement;

		while (true) {
			//console.debug('DOM:walkUpById id', desiredElement.id);
			if (desiredElement.classList.contains(className) === true) return desiredElement;		// Walk successful, exit

			desiredElement.parentNode ? desiredElement = desiredElement.parentNode : desiredElement = desiredElement.host;
			if (!desiredElement) return undefined;										// Walk failed, DOM walked and nothing matched
		}
	}


	/**
	 * Search for an HTML element by [id], walking up the DOM
	 * As well as searching in all the children for each level
	 *
	 * @param {Object} DOMElement
	 * @param {string} id - The HTML elements ID
	 * @returns - The found HTML element node, or undefined
	 * @memberof DOM
	 */
	findId(DOMElement, id) {
		const ignore = ["SCRIPT", "LINK", "#COMMENT", "#TEXT"];

		let currentNode = DOMElement;

		//console.debug('DOM:findId', currentNode.nodeName, currentNode.id);
		if (currentNode.id === id) return currentNode;

		let childNodes = currentNode.childNodes;
		// For web-components
		if (currentNode.renderRoot) {
			const webComponentChild = currentNode.renderRoot.querySelector(`#${id}`);
			if (webComponentChild) {
				return webComponentChild;
			}
			childNodes = currentNode.renderRoot.childNodes;
		} else {
			// quick scan of Children
			const desiredChild = currentNode.querySelector(`#${id}`);
			if (desiredChild) return desiredChild;
		}

		let foundChild;
		for (let index = 0; index < childNodes.length; index++) {
			const child = childNodes[index];
			const upperNode = child.nodeName.toUpperCase();

			if (ignore.includes(upperNode) == false) {
				if (child.id === id) return child;

				foundChild = this.findId(child, id);
				if (foundChild) break;
			}

		}

		return foundChild;
	}

	/**
	 * Search for an HTML element by [id], walking up the DOM
	 * As well as searching in all the children for each level
	 *
	 * @param {Object} DOMElement
	 * @param {string} selectors - The HTML elements ID
	 * @description - See https://developer.mozilla.org/en-US/docs/Web/API/Document/querySelector for more information
	 * https://developer.mozilla.org/en-US/docs/Web/CSS/Attribute_selectors
	 * This only searches children of the "DOMElement" for the matching selectors
	 * @returns - The first DOM element that matches or undefined
	 * @memberof DOM
	 */
	findFirst(DOMElement, selectors) {
		const ignore = ["SCRIPT", "LINK", "#COMMENT", "#TEXT"];

		let currentNode = DOMElement;

		//console.debug('DOM:findFirst', currentNode.nodeName, currentNode.id);
		if (currentNode.selectors === selectors) return currentNode;

		let childNodes = currentNode.childNodes;
		// For web-components
		if (currentNode.renderRoot) {
			const webComponentChild = currentNode.renderRoot.querySelector(selectors);
			if (webComponentChild) {
				return webComponentChild;
			}
			childNodes = currentNode.renderRoot.childNodes;
		} else {
			// quick scan of Children
			const desiredChild = currentNode.querySelector(selectors);
			if (desiredChild) return desiredChild;
		}

		let foundChild;
		for (let index = 0; index < childNodes.length; index++) {
			const child = childNodes[index];
			const upperNode = child.nodeName.toUpperCase();

			if (ignore.includes(upperNode) == false) {
				foundChild = this.findFirst(child, selectors);
				if (foundChild) break;
			}
		}

		return foundChild;
	}

	/**
	 * Search for an HTML element by [id], walking up the DOM
	 * As well as searching in all the children for each level
	 *
	 * @param {Object} DOMElement
	 * @param {string} selectors - The HTML elements ID
	 * @description - See https://developer.mozilla.org/en-US/docs/Web/API/Document/querySelector for more information
	 * https://developer.mozilla.org/en-US/docs/Web/CSS/Attribute_selectors
	 * This only searches children of the "DOMElement" for the matching selectors
	 * @param {Array} result - Optional - The array to put the results into
	 * @description - Will be created if not passed
	 *
	 * @returns - An array of the matching DOM elements that matches or undefined
	 * @memberof DOM
	 */
	findAll(DOMElement, selectors, result) {
		const ignore = ["SCRIPT", "LINK", "#COMMENT", "#TEXT"];
		let currentNode = DOMElement;
		if (!result) result = [];

		//console.debug('DOM:findAll', currentNode.nodeName, currentNode.id);
		let childNodes = currentNode.childNodes;
		// For web-components
		if (currentNode.renderRoot) {
			const webComponentChildren = currentNode.renderRoot.querySelectorAll(selectors);
			if (webComponentChildren) result.push(...webComponentChildren);
			childNodes = currentNode.renderRoot.childNodes;
		} // else {
		// 	// quick scan of Children
		// 	const desiredChildren = currentNode.querySelectorAll(selectors);
		// 	if (desiredChildren) result.push(...desiredChildren);
		// }

		for (let index = 0; index < childNodes.length; index++) {
			const child = childNodes[index];
			const upperNode = child.nodeName.toUpperCase();

			if (ignore.includes(upperNode) == false) {
				this.findAll(child, selectors, result);
			}
		}

		return result;
	}

}

