/**
 * @name rps-reload-page
 * @type {ReloadPage}
 * A modal control that is used when the page needs to be reloaded, eg: REST calls fail
 * 
 * 
 * @attributes + @properties 					| Changes trigger a re-render
 * Casing usages:									| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String} 	headerText		| Header text for the modal
 * 	@property {String} 	confirmText		| Confirm button text
 * 	@property {String} 	customCss		| Specify a custom .css file to include, use cssPath to override elements,
 * 														DO NOT override the styling definition by re-defining the css styling
 * 														Put this file into the *window.cssPath* folder @see {link README.md}
 * 														eg: /public/css (where the other css files are)
 * 														Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css				| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 												
 * @styling
 * Status:		
 * 	help									 		| Displays the background color in the "help color"
 * 	info											| Displays the background color in the "info color"
 * 	success										| Displays the background color in the "success color"
 * 	warning										| Displays the background color in the "warning color"
 * 	error											| Displays the background color in the "error color"
 * 
 * 
 * @methods
 * 	None
 *  
 * @properties (JS Only)
 * 	None
 * 
 * @events
 * 	None
 * 
 * @callbacks
 * 	None
 * 
 * @slots
 * 	default 										| ie: the children elements other than slotted content
 * 														Default= Click "Retry" to re-connect.<br/>
 * 																	Or contact support
 * 
*/
import { ReloadPage } from './src/modal/ReloadPage';
if (!window.customElements.get('rps-reload-page'))
	window.customElements.define('rps-reload-page', ReloadPage);

