import { html } from 'lit';
import { createRef, ref } from 'lit/directives/ref.js';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import '../../rps-input';
import '../../rps-chip';
import '../../rps-button-icon';
import { styles } from './css/chip-list.css.js';
import { iconNames } from '../../svg-icons';
import { uuid } from '../../rps-functions';

export class ChipList extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			placeholder: { type: String },
			label: { type: String },
			removeTitle: { type: String },
			chips: { type: Array },								// All the items that have been selected
			required: { type: Boolean },						// is input required
			readonly: { type: Boolean },						// is this control a readonly control?
			disabled: { type: Boolean },
			hideLabel: { type: Boolean },						// Must the input-label be hidden?
			name: { type: String },								// form name
			cbChange: { attribute: false },
		};
	}

	constructor() {
		super();
		this.placeholder = "Chip Text, enter to create chip";
		this.label = 'Chips';
		this.name = 'chips';
		this.removeTitle = "Remove all";
		this.inputRef = createRef();
		this.chipsRef = createRef();
		this.chips = [];
		this.required = false;
		this.disabled = false;
		this.hideLabel = false;
		this.readonly = false;
		this.cbChange = null;
	}

	/**
	 * Return a reference to the input control
	 *
	 * @readonly
	 * @memberof ChipList
	 */
	get inputControl() {
		return this.inputRef.value;
	}

	/**
	 * A reference to the Chips container
	 *
	 * @readonly
	 * @memberof ChipList
	 */
	get chipsContainer() {
		return this.chipsRef.value;
	}

	/**
	 * If the user presses the 'Enter' key, add the text as a new chip
	 *
	 * @param {event} event
	 * @memberof ChipList
	 */
	keyPressed(event) {
		//console.debug('InputModal:keyPressed', event);
		if (event.key === 'Enter') {
			const newItem = { id: uuid(), text: event.target.value };
			this.chips.push(newItem);
			this.fireChangedEvent();
			event.target.value = '';
			this.requestUpdate();
		}
	}

	/**
	 * Fires a 'change' event and calls the cbChange callback if it is set.
	 * Fires off when a chip is added to the list or removed from the list.
	 */
	fireChangedEvent() {
		console.debug('ChipList:fireChangedEvent');
		const detail = { chips: this.chips, source: this.tagName, control: this };
		const event = new CustomEvent('change', { bubbles: true, cancelable: true, composed: true, detail });
		this.dispatchEvent(event);
		if (this.cbChange) {
			this.cbChange(detail);
		}
	}

	/**
	 * Remove all the "selected" items and add them back into the "list for selection"
	 *
	 * @memberof ChipList
	 */
	_reset(event) {
		console.debug('ChipList:_reset', event);
		this.inputControl.inputControl.value = '';
		this.fireChangedEvent();
		this.inputControl.focus();
		this.chips = [];
	}

	/**
	 * Remove a single item from the selected list
	 *
	 * @param {Event} event
	 * @memberof ChipList
	 */
	removeItem(event) {
		console.debug('ChipList:removeItem', event);
		const id = event.target.id;
		const pos = this.chips.findIndex(e => e.id === id);

		if (pos < 0) {
			console.error('ChipList:removeItem could not delete chip with id', pos);
			return;
		}

		this.chips.splice(pos, 1);
		this.fireChangedEvent();
		this.requestUpdate();
	}


	/**
	 * Display the selected items in the <rps-input> slot
	 *
	 * @return {html}
	 * @memberof ChipList
	 */
	renderChips() {
		return this.chips.map(e => {
			return html`
				<div class="selected">
					<rps-chip id="${e.id}" text="${e.text}" deleteMode="manual" @delete=${this.removeItem}></rps-chip>
				</div>
			`;
		});

	}

	render() {
		return html`
			${this.addCss()}
			<div class="chips-container">
				<div class="chips-input-container">
					<rps-input
						id="${this.name + 'Chips'}" name="${this.name}"
						label="${this.label}"
						class="${this.className}" css="${this.css}"
						?required=${this.required} ?disabled=${this.disabled} ?readonly=${this.readonly} hide=${this.hideLabel}
						${ref(this.inputRef)}
						@keydown=${this.keyPressed}
						type="text"
						placeholder="${this.placeholder}"
					>
						<div slot="chips" ${ref(this.chipsRef)}>
							${this.renderChips()}
						</div>
					</rps-input>
					<rps-button-icon title="${this.removeTitle}" class="secondary ${this.className}"
						css="${this.css}" svg="${iconNames.del}" @click=${this._reset}>
					</rps-button-icon>
				</div>
			</div>
		`;
	}
}

