/**
 * Helpers for RedPanda models.
 * Remove once we no longer use any of their models.
 */

import { queryCategoryBreadcrumbs } from '@odo/graphql/categories';
import {
  mutationCreateProduct,
  mutationUpdateProduct,
  queryProduct,
  queryProductBySKU,
} from '@odo/graphql/product';
import {
  apiProductToRpsDeal,
  rpsDealApplyImageChanges,
  rpsDealToCreateProductInput,
  rpsDealToUpdateProductInput,
} from '@odo/transformers/rps';
import type {
  ApiCategoryBreadcrumb,
  ApiProduct,
  ProductInventory,
} from '@odo/types/api';
import type { RpsDeal } from '@odo/types/rps';

/**
 * NOTE: this is just the stitch together for RP deals.
 * Some of the functions used inside this function can be re-used by our future implementation.
 * But this function itself will become obsolete once we no longer use RPS deals.
 */
export const loadRpsDeal = async ({
  id,
  dealModel,
}: {
  id: ApiProduct['id'];
  dealModel: RpsDeal;
}) => {
  try {
    const product = await queryProduct({ id });

    if (product) {
      let breadcrumbs: ApiCategoryBreadcrumb[] | undefined;
      try {
        if (product.categories && (product.categories || []).length > 0) {
          const categoryBreadcrumbs = await queryCategoryBreadcrumbs({
            categoryIds: product.categories.map(({ categoryId }) => categoryId),
          });

          if (categoryBreadcrumbs) {
            breadcrumbs = categoryBreadcrumbs.breadcrumbs;
          }
        }
      } catch (e) {
        // failed to load category breadcrumbs... live on
      }

      apiProductToRpsDeal({ product, breadcrumbs, deal: dealModel });
      return { product, dealModel };
    }
  } catch (e) {
    console.error(e);
  }
};

export const saveRpsDeal = async ({
  id,
  dealModel,
  newDealModel,
}: {
  id?: ApiProduct['id'];
  dealModel: RpsDeal;
  newDealModel: RpsDeal;
}) => {
  let productId: ApiProduct['id'] | undefined;
  // we need the stockId and latestQty after update for custom options to know whether we need to make further updates
  let stockId: ProductInventory['id'] | undefined;
  let latestQty: ProductInventory['qty'] | undefined;

  // check if the sku is still available before we go ahead (don't bother if they never changed it)
  if (!id || dealModel.changes.some(({ field }) => field === 'sku')) {
    const allowed = await queryProductBySKU({ sku: dealModel.product.sku, id });
    if (!allowed) {
      throw new Error('Your sku is no longer available.');
    }
  }

  if (id) {
    const product = await queryProduct({ id });
    if (!product) {
      throw new Error(
        'Failed to load product from magento before save for diffing.'
      );
    }

    const newDeal = apiProductToRpsDeal({ product, deal: newDealModel });

    dealModel.applyChangesTo(newDeal);
    rpsDealApplyImageChanges({ fromDeal: dealModel, toDeal: newDeal });

    const updateProductInput = rpsDealToUpdateProductInput({ deal: newDeal });
    const updatedProduct = await mutationUpdateProduct({
      id,
      product: updateProductInput,
    });

    if (!updatedProduct) {
      throw new Error('Update product mutation failed.');
    }

    let breadcrumbs: ApiCategoryBreadcrumb[] | undefined;
    try {
      if (
        updatedProduct.categories &&
        (updatedProduct.categories || []).length > 0
      ) {
        const categoryBreadcrumbs = await queryCategoryBreadcrumbs({
          categoryIds: updatedProduct.categories.map(
            ({ categoryId }) => categoryId
          ),
        });

        if (categoryBreadcrumbs) {
          breadcrumbs = categoryBreadcrumbs.breadcrumbs;
        }
      }
    } catch (e) {
      // failed to load category breadcrumbs... live on
    }

    // clear changes on the model AFTER save is successful, but before we need the cleared model
    dealModel.clearChanges();

    apiProductToRpsDeal({
      product: updatedProduct,
      breadcrumbs,
      deal: dealModel,
    });

    productId = id;
    stockId = updatedProduct.inventory.id;
    latestQty = updatedProduct.inventory.qty;
  } else {
    const createProductInput = rpsDealToCreateProductInput({ deal: dealModel });
    const createdProduct = await mutationCreateProduct({
      product: createProductInput,
    });

    if (createdProduct) {
      try {
        let breadcrumbs: ApiCategoryBreadcrumb[] | undefined;
        if (
          createdProduct.categories &&
          (createdProduct.categories || []).length > 0
        ) {
          const categoryBreadcrumbs = await queryCategoryBreadcrumbs({
            categoryIds: createdProduct.categories.map(
              ({ categoryId }) => categoryId
            ),
          });

          if (categoryBreadcrumbs) {
            breadcrumbs = categoryBreadcrumbs.breadcrumbs;
          }
        }

        apiProductToRpsDeal({
          product: createdProduct,
          breadcrumbs,
          deal: dealModel,
        });
      } catch (e) {
        // there's an API error here in the response body, ignoring for now as we refetch on navigation anyway
      }

      productId = createdProduct.id;
      stockId = createdProduct.inventory.id;
      latestQty = createdProduct.inventory.qty;
    } else {
      throw new Error('Create product mutation failed.');
    }
  }

  return { productId, stockId, latestQty };
};
