/**
 * @name rps-title-bar
 * @type {TitleBar}
 * 	A generic title-bar control
 * 
 * @attributes + @properties 				| Changes trigger a re-render
 * Casing usages:								| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {Array} buttons 			| An array of buttons, @see {link ./rps-toolbar.js for more details}
 * 													Default = [
 * 														{ id: "cut", svg: `${iconNames.format.cut}`, title: "Cut" },
 * 														{ id: "copy", svg: `${iconNames.format.copy}`, title: "Copy" },
 * 														{ id: "paste", svg: `${iconNames.format.paste}`, title: "Paste" },
 * 													];
 * 	@property {String} customCss 		| Specify a custom .css file to include, use cssPath to override elements,
 * 													DO NOT override the styling definition by re-defining the css styling
 * 													Put this file into the *window.cssPath* folder @see {link README.md}
 * 													eg: /public/css (where the other css files are)
 * 													Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String} css				| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 
 * @styling (classNames)
 * 	@example 								| eg: <rps-title-bar class="h1">My title</rps-title-bar>
 * 	h1											| Style the "title" as a H1 
 * 	h2											| Style the "title" as a H2 
 * 	h3											| Style the "title" as a H3 
 * 	h4											| Style the "title" as a H4 
 * 	h5											| Style the "title" as a H5 
 * 	h6											| Style the "title" as a H6 
 * 
 * @methods
 * 	None
 * 
 * @events
 * 	See @link {./rps-toolbar.js} for more details
 * 
 * @slots
 * 	default									| Title to display. (the text or HTML that is used for the "title")
 * 
 * 
 * @examples + @generic + @Lit
 * @basic
 * import '@rps-wc/rps-title-bar';
 * 
 * <rps-title-bar class="h1">
 * 	My Title or HTML
 * </rps-title-bar>
 * 
 * 
*/
import { TitleBar } from './src/general/TitleBar.js';
if (!window.customElements.get('rps-title-bar'))
	window.customElements.define('rps-title-bar', TitleBar);

