/*
This class handles the custom controls, and rendering of the <rps-grid>
render, body and controls and their logic are in this class
Code splitted for maintainability and readability
*/

import { html } from 'lit';
import { GridBase } from './GridBase';
import { ref } from 'lit/directives/ref.js';
import '../../rps-button';
import '../../rps-button-icon';
import '../../rps-toolbar';
import { iconNames } from '../../svg-icons';

export class Grid extends GridBase {

	/**
	 * Update the Grid with the new Data
	 *
	 * @param {Object} data - The edited data to update the data source with
	 * @param {String} rowId - The Id that identified the data to update
	 * @param {Object} col - The column object (from column definitions) that determines the data to update
	 * @memberof Grid
	 */
	_updateGrid(data, rowId, col) {
		// ensure that numbers are numbers
		if (col.data && col.data.type === 'number' || col.data && col.data.type === 'currency') data = data * 1;

		// if the data is filtered, update the filtered data
		if (this.filteredData && this.filteredData.length > 0) {
			const row = this.filteredData.find(e => e.id === rowId);
			if (row === undefined) {
				console.error(`GRID:_updateGrid(filteredData) - Error finding rowId [${rowId}]`, 'Please ensure that all IDs are strings');
				return;
			}
			row[col.key] = data;
		}

		// update the original data set as well
		const row = this.data.find(e => e.id === rowId);
		if (row === undefined) {
			console.error(`GRID:_updateGrid(data) - Error finding rowId [${rowId}]`, 'Please ensure that all IDs are strings');
			return;
		}
		row[col.key] = data;

		if (this.hasCustomFooters) this.requestUpdate();
	}

	/**
	 * Button with Image
	 *
	 * @param {Object} data - data for this cell
	 * @param {Number} columnNumber - column number
	 * @param {String} rowId - the rows ID
	 * @param {Object} control - the control definition for this button
	 * @returns
	 * @memberof Grid
	 */
	_imageButtonControl(data, columnNumber, rowId, control, classes = '') {
		const alt = control.alt ? control.alt : '';
		const templateClass = this._templateClass(columnNumber);

		return html`
		<span class="cell ${templateClass}">
			<rps-button-icon svg=${control.image} rowId=${rowId} columnNumber=${columnNumber} alt="${alt}"
				class="small ${classes}"
				@click=${(event) => this._rowButtonClick(event, control.cbClick)}>
			<rps-button-icon>
		</span>`;
	}

	/**
	 * Button with text
	 *
	 * @param {Object} data - data for this cell
	 * @param {Number} columnNumber - column number
	 * @param {String} rowId - the rows ID
	 * @param {Object} control - the control definition for this button
	 * @returns
	 * @memberof Grid
	 */
	_textButtonControl(data, columnNumber, rowId, control, classes = '') {
		const templateClass = this._templateClass(columnNumber);

		if (typeof control.text === 'function') {
			const row = this.data.find(e => e.id === rowId);
			if (row === undefined) console.warn('Grid:_textButtonControl:rowId', rowId, 'not found in data', this.data);
			return html`
			<span class="cell ${templateClass}">
				<rps-button
					class="small tertiary ${classes}" rowId=${rowId} columnNumber=${columnNumber}
						@click=${(event) => this._rowButtonClick(event, control.cbClick)}
				>

					${control.text(row, rowId, columnNumber, control, classes)}
				</rps-button>
			</span>`;
		} else {
			return html`
			<span class="cell ${templateClass}">
				<rps-button class="small tertiary ${classes}" rowId=${rowId} columnNumber=${columnNumber}
					@click=${(event) => this._rowButtonClick(event, control.cbClick)}
				>${control.text}</rps-button>
			</span>`;
		}

	}

	/**
	 * Fires when a button in the grid is clicked
	 * This fires a 'click' event
	 * @param {Object} event
	 * @memberof Grid
	 */
	_rowButtonClick(event, onClick) {
		console.debug('grid:_rowButtonClick', event);
		const rowId = event.target.getAttribute('rowId');
		const columnNumber = event.target.getAttribute('columnNumber') * 1;
		const detail = {
			rowId,
			columnNumber,
			type: "row-button-click",
			source: this.tagName,
			target: event.target		// Allow access to the original element
		};

		if (onClick) {				// Custom handler passes in columns, either onClick or cbInput. Both map to this function
			onClick(detail);
		} else {					// default event
			event = new CustomEvent('click', { detail, bubbles: true, cancelable: true, composed: true })
			this.dispatchEvent(event);
		}
	}


	/**
	 * Fires when the delete button is clicked
	 * Deletes data from the filtered data and from the original data
	 * forces a re-render of page control, and grid
	 *
	 * @param {Event} event
	 * @memberof Grid
	 */
	_deleteRow(event, onClick) {
		console.debug('grid:deleteRow: just event', event);
		const parent = event.target.parentElement;
		const del = parent.querySelector('rps-button-icon');

		const rowId = del.getAttribute('rowId');

		if (this.filteredData && this.filteredData.length > 0) {
			const pos = this.filteredData.findIndex(e => e.id === rowId);
			if (pos < 0) {
				console.error(`GRID:_deleteRow(filteredData) - Error finding rowId [${rowId}]`, 'Please ensure that all IDs are strings');
				return;
			}
			this.filteredData.splice(pos, 1);
		}

		const pos = this.data.findIndex(e => e.id === rowId);
		if (pos < 0) {
			console.error(`GRID:_deleteRow(data) - Error finding rowId [${rowId}]`, 'Please ensure that all IDs are strings');
			return;
		}
		const deletedItem = this.data.splice(pos, 1);
		this.itemCount = this.data2Display.length;
		this._deleteClick(deletedItem, rowId, onClick);			// notify user that this row was deleted

		// update the Pagination control if necessary
		const pageCtrl = this.pageControl;						// get reference to page control, and if it exists, update it
		if (pageCtrl) {
			pageCtrl.itemCount = this.itemCount;
			pageCtrl.currentPage = Math.min(pageCtrl.currentPage, pageCtrl.pageCount);
			pageCtrl.pageHasChanged(pageCtrl.currentPage);
		}

		this.requestUpdate();
	}


	/**
	 * Fire an event notifying clients that a row was deleted
	 *
	 * @param {Object} deletedItem - The row object that was deleted
	 * @param {String} rowId		 - Row Id that was deleted
	 * @memberof Grid
	 */
	_deleteClick(deletedItems, rowId, onClick) {
		const deletedItem = deletedItems && deletedItems.length > 0 ? deletedItems[0] : deletedItems;
		console.debug('grid:deleteClick', deletedItem);
		const detail = { rowId, type: "row-delete-click", source: this.tagName, deletedItem };

		if (onClick) {
			onClick(detail);
		} else {
			const event = new CustomEvent('click', { detail, bubbles: true, cancelable: true, composed: true })
			this.dispatchEvent(event);
		}
	}


	/**
	 * Extract the template class for the given column
	 *
	 * @param {Number} columnNumber
	 * @return {String} The template class
	 * @memberof Grid
	 */
	_templateClass(columnNumber) {
		const columns = this.getColumns();
		let templateClass = '';

		if (columns[columnNumber]?.template?.slot) {
			templateClass = `template-${columns[columnNumber].template?.slot}`
		}
		return templateClass;
	}

	/**
	 * Create the 'delete' button in the grid
	 *
	 * @param {Object} data - data for this cell
	 * @param {String} rowId - row id
	 * @param {Object} control - control that defines thedelete control
	 * @returns
	 * @memberof Grid
	 */
	_deleteControl(data, columnNumber, rowId, control, classes = '') {
		const templateClass = this._templateClass(columnNumber);

		return html`
			<span class="cell ${templateClass} ${classes}">
				<rps-button-icon svg=${iconNames.del} rowId=${rowId} class="small"
					@click=${(event) => this._deleteRow(event, control.cbClick)}>
				<rps-button-icon>
			</span>`;
	}

	/**
	 * Custom Html or control
	 * NB: This control MUST be rendered by using html``
	 * ie: plain html, or LitElement control
	 * See example in grid.html, or rps-grid.js
	 *
	 * @param {Object} data - data for this cell
	 * @param {Number} columnNumber - column number
	 * @param {String} rowId - the rows ID
	 * @param {Object} control - the control definition for this button
	 * @returns
	 * @memberof Grid
	 */
	_customControl(data, columnNumber, rowId, control, classes = '') {
		if (rowId === undefined) console.warn('Grid:_customControl [rowId] is undefined', rowId);

		const row = this.data.find(e => e.id === rowId);
		if (row === undefined) console.warn('Grid:_customControl rowId', rowId, 'not found in data', this.data);

		if (this.template) {
			const col = this.getColumns()[columnNumber];
			return html`
				<span class="template-${col.template?.slot}">
					${control.render(data, row, rowId, columnNumber, classes)}
				</span>
			`;
		} else {
			return control.render(data, row, rowId, columnNumber, classes);
		}
	}



	body() {
		console.debug('grid:renderBody');
		const start = this.pageSize * (this.currentPage - 1);				// start of data to display on this page
		const stop = start + this.pageSize										// last item to display on this page
		const pageData = this.data2Display.slice(start, stop);			// extract only this pages data

		return html`${pageData.map(row => {
			const cells = this.getColumns().map((col, index) => {
				const { data, textStyle } = this._formatData(row[col.key], col, row);

				if (col.data && col.data.control) {
					if (col.data.control.deleteButton) {
						return this._deleteControl(data, index, row.id, col.data.control.deleteButton, col.data.control.deleteButton.classes);
					} else if (col.data.control.imageButton) {
						return this._imageButtonControl(data, index, row.id, col.data.control.imageButton, col.data.control.imageButton.classes);
					} else if (col.data.control.textButton) {
						return this._textButtonControl(data, index, row.id, col.data.control.textButton, col.data.control.textButton.classes);
					} else if (col.data.control.customControl) {
						return this._customControl(data, index, row.id, col.data.control.customControl, col.data.control.customControl.classes);
					}
				}

				return html`<span class="cell ${textStyle} ${col.template?.slot ? 'template-' + col.template?.slot : ''}">${data}</span>`;
			}, this);
			if (this.template) {
				return html`<div class="row">${cells}</div>`;
			} else {
				return cells;
			}
		})}`;
	}

	_addCssWithTemplate() {
		if (this.template) {
			const newCss = `
				div.grid {
					gap: 2.4rem;
					border: none !important;
					background: transparent !important;
					padding-bottom: 2.4rem;
				}
				.grid div {
					display: grid;
					grid-template-areas:
						${this.template.areas};
					grid-auto-columns: minmax(${this.template.minWidth || '12rem'}, ${this.template.maxWidth || 'auto'});
					gap: ${this.template.gap || '0'};

					background: var(--background-primary);
					display: grid;
					padding: 2.4rem;
					box-shadow: var(--box-shadow-l);
					border-radius: var(--border-radius-l);
				}

				${this.getColumns().map((col, index) => {
				return `
						.grid div .template-${col.template?.slot} {
							grid-area: ${col.template?.slot};
						}
					`;
			}).join('')}
			`;

			return html`
				${this.addCss()}
				<style>${newCss}</style>
			`
		} else {
			return this.addCss();
		}
	}

	/**
	 * toggle the selected state for the layout toolbar when the user clicks on an item
	 *
	 * @param {event} event
	 * @return {*} 
	 * @memberof Grid
	 */
	layoutToolbarClick(event) {
		if (event.detail === 1) return;

		console.debug('Grid:toolbarClick', event);
		this.toolbarControl.setActive(event.detail.buttonId);

		const id = event.detail.buttonId;
		if (id === "gridView") {
			this.hideHeader = false;
			this.oldTemplate = this.template;
			this.template = '';
		} else if (id === "cardView") {
			this.hideHeader = true;
			this.template = this.oldTemplate;
		}
	}

	/**
	 * Create a custom toolbar (in addition to the layout toolbar)
	 *
	 * @return {html} 
	 * @memberof Grid
	 */
	customToolbar() {
		if (this.toolbarButtons.length === 0) return '';

		return html`
			<rps-toolbar .buttons=${this.toolbarButtons} class="grid-toolbar">
			</rps-toolbar>
		`;
	}

	/**
	 * Create a toolbar to toggle the grid view and template view
	 *
	 * @return {html} 
	 * @memberof Grid
	 */
	layoutToolbar() {
		if (!this.template && !this.oldTemplate) return '';

		return html`
			<rps-toolbar @click=${this.layoutToolbarClick} .buttons=${this.layoutButtons} class="grid-toolbar" 
				${ref(this.toolbarRef)}>
			</rps-toolbar>
		`;
	}

	render() {
		return html`
			${this._addCssWithTemplate()}
			${this.customStyling()}
			<div class="grid-container ${this.className}">
				<div class="grid-tools">
					${this.filter()}
					${this.customToolbar()}
					${this.layoutToolbar()}
				</div>
				<div class="grid">
					${this.header()}
					${this.body()}
					${this.footer()}
				</div>
				${this.pagination()}
			</div>
		`;
	}
}
