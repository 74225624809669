/**
 * @name rps-login
 * @type {Login}
 * 
 * 
 * @attributes + @properties	 						| Changes trigger a re-render
 * Casing usages											| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String} icon 						| The icon to use. Default = iconNames.redPanda.logo
 * 	@property {String} userNameLabel 			| The username label. Default="User name"
 * 	@property {String} passwordLabel 			| The password label. Default="Password"
 * 	@property {String} userNamePattern 			| Username regex expression. Default='.{5,}'
 * 	@property {String} passwordPattern 			| Password regex expression. Default='.{8,}'
 * 	@property {String} userNamePlaceholder 	| Username place holder text. Default='Enter your email'
 * 	@property {String} passwordPlaceholder 	| Password place holder text. Default='Enter Password'
 * 	@property {String} userNameErrorMessage 	| Username error message. Default='Usernames must be a valid email'
 * 	@property {String} passwordErrorMessage 	| Password error message. Default='Passwords must be at least 8 long'
 * 	@property {String} confirmButtonText 		| Confirm button text. Default='Login'
 * 	@property {String} forgotLinkText 			| Forgot password link text. Default='Forgot password'
 * 	@property {String} customCss 					| Specify a custom .css file to include, use cssPath to override elements,
 * 																DO NOT override the styling definition by re-defining the css styling
 * 																Put this file into the *window.cssPath* folder @see {link README.md}
 * 																eg: /public/css (where the other css files are)
 * 																Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String} css							| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 	@property {String} userNameType				| What type is the userName input control?
 * 																Default = 'email'
 * 													
 * 
 * @properties (JS Only)
 * 	userNameControl (get only)						| Reference to the Username control
 * 	passwordControl (get only)						| Reference to the Password control
 * 
 * @methods
 * 	None
 * 
 * @events
 * 	forgot												| When the user clicks on the forgot password link
 * 	submit												| When the forms values have been successfully validated, 
 * 																and the "login button" has been clicked
 * 	cancel												| cancel button clicked
 * 			
 * @callbacks
 * 	cbForgot												| Callback function
 * 	cbSubmit												| Callback function
 * 	cbCancel												| Callback function
 * 
 * @slots
 * 	header
 * 
 * @example {lit}
 * @Basic
 * ======
 * import '@rps-wc/rps-login';
 * 
 * 	<rps-login @submit=${selectHandler} @forgot=${forgotHandler}>
 * 	</rps-login>
 * 
*/
import { Login } from './src/authentication/Login';
if (!window.customElements.get('rps-login'))
	window.customElements.define('rps-login', Login);

