import Button from '@odo/components/elements/button';
import Card from '@odo/components/elements/card';
import { Checkbox, Input } from '@odo/components/elements/form-fields';
import { Flex, FlexItem } from '@odo/components/elements/layout';
import {
  FaChevronLeft as IconChevronLeft,
  FaChevronRight as IconChevronRight,
} from 'react-icons/fa';
import {
  TbTrash as IconTrash,
  TbTrashOff as IconTrashOff,
} from 'react-icons/tb';
import type { EditorProductVideo } from '@odo/types/portal';
import Tooltip from '@odo/components/widgets/tooltip';
import VideoPreview from './video-preview';
import { useChangeProduct } from '@odo/contexts/product-editor';

const VideoGridItem = ({
  video,
  isSelected,
  toggleSelected,
  canMoveLeft,
  canMoveRight,
  moveLeft,
  moveRight,
}: {
  video: EditorProductVideo;
  isSelected: boolean;
  toggleSelected: (select: boolean) => void;
  canMoveRight: boolean;
  canMoveLeft: boolean;
  moveRight: () => void;
  moveLeft: () => void;
}) => {
  const change = useChangeProduct();

  return (
    <Card
      isSelected={isSelected}
      header={
        <Flex justifyContent="space-between" width="100%">
          <Checkbox
            label=""
            checked={isSelected}
            onChange={e => toggleSelected(!!e.target.checked)}
          />

          <Flex gap={1}>
            <Tooltip
              showDelay={1250}
              content={() =>
                video.shouldDelete ? 'Cancel Delete' : 'Delete Image'
              }
            >
              <Button
                hue="grey"
                variant="flat"
                px={0}
                py={0}
                onClick={() => {
                  const shouldDelete = !video.shouldDelete;
                  change({
                    fieldId: `videos.${video.id}.shouldDelete`,
                    label: shouldDelete ? 'Delete Video' : 'Cancel Delete',
                    apply: to => {
                      to.videos = to.videos
                        ? to.videos.map(i =>
                            i.id === video.id ? { ...i, shouldDelete } : i
                          )
                        : to.videos;
                    },
                  });
                }}
              >
                {video.shouldDelete ? (
                  <IconTrashOff size={14} />
                ) : (
                  <IconTrash size={14} />
                )}
              </Button>
            </Tooltip>
          </Flex>
        </Flex>
      }
    >
      <Flex flexDirection="column" gap={[2, 3]}>
        <FlexItem alignSelf="center">
          <VideoPreview video={video} dimensions={295} />
        </FlexItem>

        <Flex width="100%" justifyContent="space-between">
          <Tooltip
            showDelay={1250}
            content={() => 'Move Left'}
            disabled={!canMoveLeft}
          >
            <Button
              hue="grey"
              variant={!canMoveLeft ? 'flat' : 'outlined'}
              disabled={!canMoveLeft}
              px={1}
              py={1}
              onClick={moveLeft}
            >
              <IconChevronLeft />
            </Button>
          </Tooltip>

          <Tooltip
            showDelay={1250}
            content={() => 'Move Right'}
            disabled={!canMoveRight}
          >
            <Button
              hue="grey"
              variant={!canMoveRight ? 'flat' : 'outlined'}
              disabled={!canMoveRight}
              px={1}
              py={1}
              onClick={moveRight}
            >
              <IconChevronRight />
            </Button>
          </Tooltip>
        </Flex>

        <Input
          key={video.id}
          label="Video URL:"
          value={video.url || video.raw}
          readOnly
        />
      </Flex>
    </Card>
  );
};

export default VideoGridItem;
