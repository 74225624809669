import { gql } from '@apollo/client';

export const GET_ODO_IMAGES = gql`
  query GetImages($productId: ID) {
    getProductImages(productId: $productId) {
      id
      position
      filePath
      url
      label
      excludeImageTypes
      imageTypes
    }
  }
`;
