/**
 * @name rps-button-icon
 * @type {ButtonIcon}
 * 	Composite component that combines two simple rps-input[type=date] components into a
 * 	single component for selecting a start date and end date.
 *		Provides basic
 *		The input event fires off once both pickers have a date selected.
 *
 *	@attributes + @properties
 * 	@property {String}	customCss						| Specify a custom .css file to include, use cssPath to override elements,
 * 																		DO NOT override the styling definition by re-defining the css styling
 * 																		Put this file into the *window.cssPath* folder @see {link README.md}
 * 																		eg: /public/css (where the other css files are)
 * 																		Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css								| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 	@property {String} 	svg = "iconNames.add"		| svg to display
 *		@property {Boolean} 	disabled = false				| Is this control disabled
 *
 * @events
 * 	@fires click												| Fired when the button is clicked on
 *
 * @callbacks
 * 	@cbClick														| callback when the button is clicked
 *
 * @methods
 * 	get buttonControl											| Retrieves the <button> control
 * 	toggleClass(className, toAdd = true)				| A method to toggle a class for the button control
 * 																	| className = the className to add or remove
 *
 * @properties
 * 	No properties
 *
 *	@slots
 * 	No slots
 * 
 * @Styling
 * Colors:
 * 	primary:		Primary colors used for the button
 * 	secondary:  Secondary colors used for the button
 * 	tertiary: 	Tertiary colors used for the button
 * Sizes:
 * 	small:		Small button
 * 	medium:		Medium size button
 * 	large:		Large size button
 * Filling:		
 * 	outline: 	Only an outline for the button displays, no filling
 * 	solid:		The contents of the button are filled, not only the outline
 * Status:		
 * 	help: 		Displays the background color in the "help color"
 * 	info:			Displays the background color in the "info color"
 * 	success:		Displays the background color in the "success color"
 * 	warning:		Displays the background color in the "warning color"
 * 	error:		Displays the background color in the "error color"
 * 
 * @tooltip
 * 	@example <rps-button data-tooltip="Message you want to display on hover"></rps-button>
 * 	data-tooltip-top											| Display Tooltip at top of component
 * 	data-tooltip-right										| Display Tooltip on right of component
 * 	data-tooltip-bottom										| Display Tooltip at bottom of component
 * 	data-tooltip-left											| Display Tooltip on left of component
 * 	data-tooltip												| Display Tooltip at bottom of component (Default)
 * 
 * 
 * @example+ @Lit
 * import '@rps-wc/rps-button-icon';
 * import { iconNames } from '@rps-wc/svg-icons';
 * 
 * 	onClicked(ev) {
 * 		console.log(ev.detail);
 * 	}
 * 
 * <rps-button-icon @click=${onClicked} id='one' svg=${iconNames.del}>
 * </rps-button-icon>
 *
 *
 * @example+ @React + @basic
 * import '@rps-wc/rps-button-icon';
 * import { iconNames } from '@rps-wc/svg-icons';
 * 
 * 	const onClicked = (ev) => {
 * 		console.log(ev.detail);
 * 	}
 *
 * 	return <rps-button-icon onClick={onClicked} svg={iconNames.del}><rps-button-icon>
 * 
 * @example+ @React + @advanced
 * import '@rps-wc/rps-button-icon';
 * import { iconNames } from '@rps-wc/svg-icons';
 * 
 * 	useEffect(() => {
 * 		button.current.cbClick= {e => console.log(e)}
 * 	}, [])
 *
 * 	return <rps-button-icon ref={button} svg={iconNames.del}><rps-button-icon>
 * 
 */
import { ButtonIcon } from './src/buttons/ButtonIcon.js';
if (!window.customElements.get('rps-button-icon'))
	window.customElements.define('rps-button-icon', ButtonIcon);

