import type { GridProduct } from '@odo/components/search/types';
import { Flex } from '@odo/components/elements/layout';
import { Text } from '../grid';

const AccountLoaders = ({ data }: { data: GridProduct }) => (
  <Flex flexDirection="column" rowGap="5px" style={{ fontSize: '11px' }}>
    <Text style={{ fontWeight: 'bold' }}>Manager:</Text>
    {data.buyer}
    <Text style={{ fontWeight: 'bold' }}>Loader:</Text>
    {data.salesAssistant}
  </Flex>
);

export default AccountLoaders;
