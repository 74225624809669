import React, { useState, useContext, useMemo } from 'react';
import { useCurrentDealSource } from '../../hooks/useCurrentDealSource';
import { useNavigate } from 'react-router-dom';
import {
  RPSButton,
  RPSModal,
  RPSGridInput,
  RPSCard,
  RPSListContainer,
  RPSImage,
  RPSSwitchSimple,
  RPSCheckbox,
} from '@rps/web-components/build/react-wrappers';
import { iconNames } from '@rps/web-components/build/web-components';
import { dealListColumnDefinition } from './data/dealListColumnDefinition';
import {
  CREATE_ODO_PRODUCT,
  UPDATE_ODO_PRODUCT,
} from '../../gql/deals/uploadDeal';
import { GET_ODO_PRODUCT } from '../../gql/deals/getDeal';
import { Deal } from '../../models/Deal.jsx';
import styles from './css/BusySpinner.module.css';
import { ErrorCard } from './../uiComponents/ErrorCard';
import { useODOMutation } from 'hooks/useODOMutation';
import { ModalContext } from 'contexts/Modal';
import { useODOLazyQuery } from 'hooks/useODOQuery';
import { generatePath } from 'react-router-dom';

/**
 * Displays a list of the current working deal set, as well as a button to upload
 * all deals that are ready to upload.
 */
export const DealList = () => {
  const currentDeal = useCurrentDealSource();
  const navigate = useNavigate();
  const modalContext = useContext(ModalContext);

  const [modal, setModal] = useState(null);
  const [errors, setErrors] = useState(null);
  const [demoMode, setDemoMode] = useState('grid');
  const [selected, setSelected] = useState([]);
  const [showDeleteSelectedModal, setShowDeleteSelectedModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const [createProduct, { error }] = useODOMutation(CREATE_ODO_PRODUCT);
  const [updateProduct] = useODOMutation(UPDATE_ODO_PRODUCT);
  const fetchODODeal = useODOLazyQuery(GET_ODO_PRODUCT);

  const getName = index => {
    return currentDeal.dealList[index]?.product.name || 'N/A';
  };

  // This uploadDeal method differs from the CurrentDealContext method in that
  // it doesn't do anything with the results, but collects the successes and removes them
  // from the current editor list.
  // TODO: BP-413 see if we can reduce the amount of API calls made to complete this upload.
  const uploadDeal = async (deal, index) => {
    const id = deal.localId || deal.meta.id;
    return new Promise(async (resolve, reject) => {
      try {
        const skuExists = await currentDeal.isSKUInUse(
          deal.product.sku,
          deal.meta.id
        );
        if (skuExists) {
          modalContext.pushToast(
            `SKU ${deal.product.sku} already in use! Cannot upload.`,
            'error',
            15
          );
          reject(
            `DealList Index ${index}: SKU ${deal.product.sku} already in use or invalid`
          );
          return;
        }
        if (id) {
          const existing = await fetchODODeal({ productId: id });
          const existingDeal = Deal.fromGraphQLDeal(existing.data.getProduct);
          existingDeal.conditionsAndCategory.category =
            await currentDeal.fetchDealCategories(id, existingDeal);

          deal.applyChangesTo(existingDeal);
          const flatDeal = await existingDeal.toGraphQLDeal();
          await updateProduct({
            variables: {
              productId: id,
              input: flatDeal,
            },
          });

          await currentDeal.updateDealInventory(id, flatDeal.inventory);
          await currentDeal.saveOrUpdateDealCategories(
            existingDeal.conditionsAndCategory.category,
            id
          );

          currentDeal._addToRecentDealsList(existingDeal);
        } else {
          const dealData = await deal.toGraphQLDeal();
          const { data } = await createProduct({
            variables: {
              input: dealData,
            },
          });

          if (data?.createProduct) {
            await currentDeal.updateDealInventory(
              data.createProduct.id,
              dealData.inventory
            );
            await currentDeal.saveOrUpdateDealCategories(
              deal.conditionsAndCategory.category,
              data.createProduct.id
            );
          }

          // Do second "update" to work around issue with Inventory creation
          await updateProduct({
            variables: {
              productId: +data.createProduct.id,
              input: dealData,
            },
          });

          currentDeal._addToRecentDealsList(deal);
        }
        resolve(index);
      } catch (e) {
        console.error(
          `[DealList]: Failed to upload Deal ${index}. Details:`,
          error
        );
        reject();
      }
    });
  };

  /** Event/Callback Handlers */
  const handleDelete = index => {
    console.debug(`DealList: Delete confirmation of deal at position ${index}`);
    setModal(
      <RPSModal
        opened
        popup
        header="Are you sure?"
        cbCancel={() => setModal(null)}
        cbConfirm={() => {
          console.debug(
            `DealList: Confirmed, deleting deal at position ${index}`
          );
          currentDeal.removeDeal(index);
          setModal(null);
        }}
      >
        <p>Are you sure you want to delete &quot;{getName(index)}&quot;?</p>
      </RPSModal>
    );
  };

  const handleDeleteSelected = () => {
    console.debug(`DealList: Deleting of multiple deals: ${selected}`);
    currentDeal.removeDeals(selected);
    setSelected([]);
    setShowDeleteSelectedModal(false);
  };

  const handleEdit = index => {
    console.debug(`DealList: Changing selected deal to ${index}`);

    const realId = currentDeal.dealList[index].meta?.id;

    currentDeal.dealList[index].id = realId || '';
    currentDeal.dealList[index].localId = realId || '';
    currentDeal.update();
    currentDeal.selectDeal(index);
    console.debug(
      'DealList: Navigating to Deal Editor: Product Information page'
    );

    setTimeout(() => {
      navigate(
        generatePath('/deals/editor/:dealId?/buyer-and-supplier', {
          dealId: realId || undefined,
        })
      );
    }, 0);
  };

  const handleShowErrors = deal => {
    setErrors(deal.getErrors());
  };

  const handleUpload = async () => {
    const dealsToRemove = [];
    const dealPromises = [];
    const dealIndexMap = [];

    console.debug('DealList: Uploading Deals');

    const _queueDeal = async (deal, index) => {
      if (deal.getValidity.status !== '') {
        console.debug(
          `DealList: Queuing deal '${deal.product.name}' for upload.`
        );
        try {
          dealPromises.push(await uploadDeal(deal, index));
          dealIndexMap.push(index);
        } catch (e) {
          modalContext.pushToast(
            `Error uploading deal ${index + 1}`,
            'error',
            4
          );
          console.error(
            `[DealList:handleUpload] Error uploading deal index ${index}, details:`,
            e
          );
        }
      }
    };

    setLoading(true);
    if (selected.length > 0) {
      let index = 0;
      for (const deal of currentDeal.dealList.filter((x, index) =>
        selected.includes(index)
      )) {
        await _queueDeal(deal, index++);
      }
    } else {
      let index = 0;
      for (const deal of currentDeal.dealList) {
        await _queueDeal(deal, index++);
      }
    }

    console.debug(`DealList: Uploaded ${dealsToRemove.length} deals`);
    currentDeal.removeDeals(dealIndexMap);
    setSelected([]);
    setLoading(false);

    if (dealIndexMap.length > 0) {
      //setToast(<RPSToast opened className="success" text={`Uploaded ${dealsToRemove.length} deals!`} expireseconds={3} />);
      modalContext.pushToast(
        `Uploaded ${dealIndexMap.length} deals!`,
        'success',
        3
      );
    } else {
      //setToast(<RPSToast opened className="error" text="No valid deals to upload!" expireseconds={4} />);
    }
  };

  /** Computed data value for RPSGrid */
  const dealData = useMemo(
    () =>
      currentDeal.dealList.map((deal, index) => {
        // Adding an id key to the deal to make looking up the clicked-on deal easier.
        deal['id'] = index;
        return deal;
      }),
    [currentDeal.dealList]
  );

  const toggleRenderMode = () => {
    if (demoMode === 'cards') {
      setDemoMode('grid');
    } else {
      setDemoMode('cards');
    }
  };

  const selectDeal = index => {
    let newSelected = [...selected];
    if (newSelected.findIndex(x => x === index) === -1) {
      newSelected.push(index);
    } else {
      newSelected = newSelected.filter(x => x !== index);
    }
    setSelected([...newSelected]);
  };

  /** Column Definition for RPSGrid */
  const columns = dealListColumnDefinition(
    handleEdit,
    handleDelete,
    selected,
    selectDeal,
    handleShowErrors
  );

  const handleSelectAll = () => {
    if (selected.length > 0) {
      setSelected([]);
    } else {
      const newSelected = [];
      dealData.forEach((d, index) => newSelected.push(index));
      setSelected([...newSelected]);
    }
  };

  console.debug('DealList: Render');

  return (
    <RPSCard css=":host { width: 100%; }">
      {/* Busy popup - shows when deals are in the process of uploading */}
      {loading && (
        <div className={styles.busySpinnerContainer}>
          <rps-card>
            <div className={styles.busySpinner}>
              <rps-spinner-pause />
              Uploading...
            </div>
          </rps-card>
        </div>
      )}
      <div slot="header">
        <h5>Drafts (Unsaved Deals):</h5>
      </div>

      <RPSListContainer
        className="horizontal"
        css="#list-container { align-items: center; }"
      >
        {/* Upload Deals button */}
        <RPSButton cbClick={handleUpload}>
          {selected.length > 0 ? 'Upload Selected Deals' : 'Upload All Deals'}
          <div slot="slot-right">
            <rps-svg svg={iconNames.uploadOutline} />
          </div>
        </RPSButton>
        <RPSButton className="outline secondary" cbClick={handleSelectAll}>
          {selected.length > 0 ? 'Deselect All' : 'Select All'}
        </RPSButton>
        <RPSButton
          disabled={selected.length === 0}
          className="error"
          cbClick={() => setShowDeleteSelectedModal(true)}
        >
          <div slot="slot-right">
            <rps-svg svg={iconNames.del} />
          </div>
          Delete Selected
        </RPSButton>
        <RPSSwitchSimple
          label="Render as Cards"
          checked={demoMode === 'cards'}
          cbInput={toggleRenderMode}
        />
      </RPSListContainer>

      {demoMode === 'grid' && (
        <RPSGridInput columns={columns} data={dealData} />
      )}
      {demoMode === 'cards' && (
        <RPSListContainer className="horizontal">
          {dealData.map((deal, dealIndex) => {
            const hasImage = deal.imagesAndVideos.images.length > 0;
            let image = '/assets/image-placeholder.png';
            let status = '';
            let cardBorder = '';

            // Find first active image
            if (hasImage) {
              deal.imagesAndVideos.images.every(i => {
                if (i.enabled) {
                  image = i.url;
                  return false;
                } else {
                  return true;
                }
              });
            }

            switch (deal.getValidity.status) {
              case 'partial':
                status = 'Partial';
                cardBorder = '4px solid var(--state-warning)';
                break;
              case 'complete':
                status = 'Complete';
                cardBorder = '4px solid var(--state-success)';
                break;
              default:
                status = 'Has Errors';
                cardBorder = '4px solid var(--state-error)';
                break;
            }

            return (
              <RPSCard
                key={deal.id}
                css={`
                  :host {
                    min-width: 29%;
                    border: ${cardBorder};
                    border-radius: var(--border-radius-l);
                  }
                `}
              >
                <div slot="header">
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <RPSCheckbox
                      label=""
                      className="small"
                      checked={selected.includes(dealIndex)}
                      cbInput={() => selectDeal(dealIndex)}
                    />
                    <strong>
                      {[
                        deal.product.name || 'Untitled Deal',
                        ...(deal.product.sku ? [deal.product.sku] : []),
                      ].join(' - ')}
                    </strong>
                  </span>
                </div>
                <RPSListContainer className="horizontal">
                  <RPSImage
                    css=":host img { max-width: 9rem; max-height: 9rem; height: 9rem !important; border: 1px solid #eee; object-fit: contain !important; }"
                    className="small"
                    src={image}
                  />
                  <RPSListContainer className="vertical">
                    <span>
                      <b>Status:</b> {status}
                    </span>
                    <span>
                      <b>Cost:</b> R
                      {(+deal.priceAndCustomOptions.cost).toFixed(2)}
                    </span>
                    <span>
                      <b>Price:</b> R
                      {(+deal.priceAndCustomOptions.price).toFixed(2)}
                    </span>
                  </RPSListContainer>
                </RPSListContainer>
                <RPSListContainer className="horizontal">
                  <RPSButton
                    className="small"
                    cbClick={() => handleEdit(dealIndex)}
                  >
                    Edit Deal
                  </RPSButton>
                  <RPSButton className="small error">Delete Deal</RPSButton>
                </RPSListContainer>
              </RPSCard>
            );
          })}
        </RPSListContainer>
      )}

      {modal}
      <RPSModal
        opened={showDeleteSelectedModal}
        popup
        header="Are you sure?"
        cbCancel={() => setShowDeleteSelectedModal(false)}
        cbConfirm={handleDeleteSelected}
      >
        <p>
          Are you sure you want to delete {selected.length} deals? This cannot
          be undone, and you will lose all unsaved changes.
        </p>
      </RPSModal>

      <RPSModal
        css="#modal-container { width: 75%; }"
        opened={errors?.required.length > 0 || errors?.optional.length > 0}
        popup
        header="List of Errors"
        cbCancel={() => setErrors(null)}
        cbConfirm={() => setErrors(null)}
      >
        <ErrorCard errors={errors} />
      </RPSModal>
    </RPSCard>
  );
};
