
/**
 * @name rps-accordion
 * @type {Accordion}
 * 	An Accordion component for displaying grouped items
 * 
 * @description When the type is 'text' you can use a custom in the panel 
 * 	eg: {control: "text", iconName: iconNames.cardView }
 *
 *	@attributes + @properties
 * 	@property {String}	customCss						| Specify a custom .css file to include, use cssPath to override elements,
 * 																		DO NOT override the styling definition by re-defining the css styling
 * 																		Put this file into the *window.cssPath* folder @see {link README.md}
 * 																		eg: /public/css (where the other css files are)
 * 																		Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css								| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 	@property {Array}		panels							| A list of panels to create
 * 																	| The "htmlOrText" can contain plain text or html`<HTML HERE>`
 * 																		Default is [
 * 																			{
 * 																				id: "pan1",
 * 																				control: "label",				// options = "label", "checkbox", "text"
 * 																				label: "Section 1",
 * 																				classNames: '',				// optional
 * 																				required: true,				// optional
 * 																				disabled: false,				// optional
 * 																			},
 * 																			{
 * 																				id: "pan2",
 * 																				control: "checkbox",			// options = "label", "checkbox", "text"
 * 																				label: "Section 2",
 * 																				classNames: 'error',			// optional
 * 																				required: true,				// optional
 * 																				disabled: false,				// optional
 * 																			},
 * 																			{
 * 																				id: "pan3",
 * 																				control: "label",				// options = "label", "checkbox", "text"
 * 																				label: "Section 3",
 * 																				classNames: 'warning',		// optional
 * 																				required: true,				// optional
 * 																				disabled: false,				// optional
 * 																			},
 * 																			{
 * 																				id: "pan4",
 * 																				control: "text",					// options = "label", "checkbox", "text"
 * 																				label: "Section 4",
 * 																				classNames: '',					// optional
 * 																				iconName: iconNames.cardView,	// optional
 * 																				required: true,					// optional
 * 																				disabled: false,					// optional
 * 																			},
 * 																		];
 *
 * @events
 * 	click															| Fired when the panel <rps-button> is clicked on
 *
 * @callbacks
 * 	cbClick														| Callback for when the panel <rps-button> is clicked on
 *
 * @methods
 * 	get buttonControls										| Retrieves all the <rps-button> controls
 * 	get panelControls											| Retrieves all the panels
 *
 * @properties
 * 	No properties
 *
 *	@slots
 * 	@NB slots are dynamically generated using the "id" of each panel.
 * 
 * 
 * 
 * @example+ @Lit
 * import { iconNames } from '@rps-wc/svg-icons';
 * 
 * 	onClicked(ev) {
 * 		console.log(ev.detail);
 * 	}
 * 
 * <rps-accordion @click=${onClicked} id='one'>
 * 	<div slot="pan1">
 * 		<h3>Add images here</h3>
 * 		some rubbish<br/>
 * 		some more
 * 	</div>
 * 	<div slot="pan2">
 * 		<h3>Add videos here</h3>
 * 		video 1<br/>
 * 		video 2
 * 	</div>
 * 	<div slot="pan3">
 * 		<h3>Add form controls here</h3>
 * 		inputs<br/>
 * 		some more
 * 	</div>
 * 	<div slot="pan4">
 * 		<h3>Add complex controls here</h3>
 * 		some controls<br/>
 * 		some more
 * 	</div>
 * </rps-accordion>
 *
 *
 * @example+ @React
 * import { iconNames } from '@rps-wc/svg-icons';
 * 
 * 	useEffect(() => {
 * 		accordion.current.cbClick= {e => console.log(e)}
 * 	}, [])
 * 
 * 	return 
 * 		<rps-accordion ref={accordion}>
 *			 	<div slot="pan1">
 *			 		<h3>Add images here</h3>
 *			 		some rubbish<br/>
 *			 		some more
 *			 	</div>
 *			 	<div slot="pan2">
 *			 		<h3>Add videos here</h3>
 *			 		video 1<br/>
 *			 		video 2
 *			 	</div>
 *			 	<div slot="pan3">
 *			 		<h3>Add form controls here</h3>
 *			 		inputs<br/>
 *			 		some more
 *			 	</div>
 *			 	<div slot="pan4">
 *			 		<h3>Add complex controls here</h3>
 *			 		some controls<br/>
 *			 		some more
 *			 	</div>
 * 		<rps-accordion>
 */
import { Accordion } from './src/lists/Accordion';
if (!window.customElements.get('rps-accordion'))
	window.customElements.define('rps-accordion', Accordion);

