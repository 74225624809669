/**
 * @name rps-bar-chart3d
 * @type {BarChart3d}
 * 	A 3D CSS3 chart with click events on the bars
 * @description
 * 	The Chart is set up to display items as a percentage of 100.
 * 	ie: Pass items that are already a percentage value of 100.
 *
 *	@attributes + @properties
 * 	@property {Number}	barHeightRem					| Optional: default is 22]
 * 	@property {Number}	barWidthRem						| Optional: default is 12.5]
 * 	@property {Number}	innerBarWidthRem				| Optional: Default is this.barWidthRem * 0.8
 * 	@property {Number}	barOffsetRem					| Optional: Default is this.barWidthRem * 0.2
 * 	@property {Number}	yAxis								| Optional: The points to display on the yAxis. Default (is 25%, 50%, 75%, 100%)
 * 	@property {Array}		data								| The data to Display on the chart.
 * 																	  eg:
 * 																	  this.data = [
 * 																		  {label: "2008", value:25, title: `<h3>Sales for 2008</h3>`},
 * 																		  {label: "2009", value:50},
 * 																		  {label: "2010", value:75, title: `<h3>Sales for 2010</h3>`},
 * 																		  {label: "2011", value:100},
 * 																		  {label: "2012", value:50, title: `<h3>Sales for 2012</h3>`}
 * 																	  ];
 * 																	  The "title" is optional, and will display a HTML fragment as a "floating" element
 * 																	  If No "title" is passed it will automatically display the value for that bar.
 * 
 * 	@property {String}	customCss 						| Specify a custom .css file to include, use cssPath to override elements,
 * 																		  DO NOT override the styling definition by re-defining the css styling
 * 																		  Put this file into the *window.cssPath* folder
 * 																		  eg: /public/css (where the other css files are)
 * 																		  Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css								| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 	@property {String}	heading							| Heading for Chart
 * 
 * 
 * @events
 * 	@fires click												| returns the element that was clicked.
 * 																		The chartValue(value of this element in data)
 * 																		and pos(index on data array) are returned
 * 
 *
 * @callbacks
 * 	No callbacks
 *
 * @methods
 * 	No public Methods
 *
 * @properties
 * 	setData(newData)											| Use this method when you want to update an existing chart with new values
 * 	setYAxis(newYAxis)										| Use this method when you want to update an existing chart with a new yAxis
 *
 *	@slots
 * 	no slots
 * 
 * @Styling
 * No classes for styling
 * 
 * @example+ @Lit
 * @basic
 * import '@rps-wc/rps-bar-chart3d';
 * 
 *
 * <rps-bar-chart3d @click=${e => console.log(e)}>
 * </rps-bar-chart3d>
 * 
 * 
 * @advanced
 * import '@rps-wc/rps-bar-chart3d';
 * 
 * 	onClicked(ev) {
 * 		console.log(ev.detail);
 * 	}
 *
 * 
 * 	const yAxis = [
 * 		{label: "12.5%", value:12.5},
 * 		{label: "25%", value:25},
 * 		{label: "37.5%", value:37.5},
 * 		{label: "50%", value:50},
 * 		{label: "75%", value:75},
 * 		{label: "100%", value:100},
 * 	];
 * 
 *   	const data = [
 * 		{label: "2008", value:25, title: `<h3>Sales for 2008</h3>`},
 * 		{label: "2009", value:50},
 * 		{label: "2010", value:75, title: `<h3>Sales for 2010</h3>`},
 * 		{label: "2011", value:100},
 * 		{label: "2012", value:50, title: `<h3>Sales for 2012</h3>`}
 * 	];
 * 
 * 
 * 	<rps-bar-chart3d
 * 		.yAxis=${yAxis} .data=${data}
 * 		heading="My chart" barheightrem="30" barwidthrem="15" innerbarwidthRem="10" baroffsetrem="1.5"
 * 		@click=${this.onClicked}
 * 	>
 * 	</rps-bar-chart3d>
 *  
 * @example+ @React
 * import '@rps-wc/rps-bar-chart3d';
 * 
 * 	const yAxis = [
 * 		{label: "12.5%", value:12.5},
 * 		{label: "25%", value:25},
 * 		{label: "37.5%", value:37.5},
 * 		{label: "50%", value:50},
 * 		{label: "75%", value:75},
 * 		{label: "100%", value:100},
 * 	];
 * 
 *   	const data = [
 * 		{label: "2008", value:25, title: `<h3>Sales for 2008</h3>`},
 * 		{label: "2009", value:50},
 * 		{label: "2010", value:75, title: `<h3>Sales for 2010</h3>`},
 * 		{label: "2011", value:100},
 * 		{label: "2012", value:50, title: `<h3>Sales for 2012</h3>`}
 * 	];
 * 
 * 	useEffect(() => {
 * 		chart.current.cbClick= {e => console.log(e)}
 * 	}, [])
 *
 * 	return <rps-bar-chart3d
 * 				yAxis={JSON.stringify(yAxis)} data={JSON.stringifydata)}
 * 				heading="My chart" barheightrem={30} barwidthrem={15} innerbarwidthRem={10} baroffsetrem={1.5}
 * 				ref={chart}
 * 			><rps-bar-chart3d>
 * 
 */
import { BarChart3d } from './src/charts/BarChart3d';
if (!window.customElements.get('rps-bar-chart3d'))
	window.customElements.define('rps-bar-chart3d', BarChart3d);

