/**
 * @name rps-image
 * @type {Image} A control to display (and a small amount of manipulation) of images
 * 	A toolbar can be added to the image by passing in an array of buttons.
 * 
 * @attributes + @properties 				| Changes trigger a re-render
 * Casing usages:								| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String} src 				| The image URL
 * 	@property {Array} images		 	| A list of images. Each element has a "src" and "speed"
 * 													Based on the estimated (or user-defined) speed the relevant image will be displayed to the user in the array
 * 													@example 
 * 														const images = [
 * 															{ src: "url1", speed: "fast" },
 * 															{ src: "url2", speed: "medium" },
 * 															{ src: "url3", speed: "slow" }
 * 														];
 * 	@property {Array} imageSources 	| A list of image sources.
 * 													used to offer the same media content in multiple file formats 
 * 													in order to provide compatibility with a broad range of browsers 
 * 													given their differing support for image file formats and media file formats.
 * 	@property {Boolean} canExpand 	| Can this image be clicked on and display a larger image in a modal? (adds a "zoom" into the toolbar)
 * 	@property {String} label 			| The label
 * 	@property {String} alt				| An alternative text if the element cant be displayed
 * 	@property {Array } buttons			| A list of buttons to create in the toolbar
 * 													Default is [
 * 														{ id: "expand", svg: iconNames.search, title: "Zoom in"},
 * 													];
 * 	@property {String} customCss		| Specify a custom .css file to include, use cssPath to override elements,
 * 													DO NOT override the styling definition by re-defining the css styling
 * 													Put this file into the *window.cssPath* folder @see {link README.md}
 * 													eg: /public/css (where the other css files are)
 * 													Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String} css				| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 *
 * @property (JS Only, Camel case)
 * 	get modalControl						| A reference to the modal
 * 	get imageControl						| A reference to the <img> control
 * 
 * @methods
 * 	generateScaledImage(img, scale, newWidth)	| Generate a scaled image (data URL)
 * 													@img = the <img> to use as the source
 * 													@scale = a scaling to use (scale > 0 && <= 1)
 * 													@newWidth = New width to use. The height is automatically scaled to this ration.
 * 													scale or newWidth can be passed. (either or)
 * 													@returns the new Data URL of the scaled image
 * 	setImages(images)						| Sets the images (based on internet speed)
 * 													@see {link @property images} for more details
 * 
 * @events
 * 	click										| fired when the user clicks on the image
 * 	toolbarClick							| fired when the user clicks on a button in the toolbar
 * 
 * @callbacks
 * 	cbClick									| Called when the click event fires
 * 	cbToolbarClick							| Called when the user clicks on a button in the toolbar
 * 
 * @slots
 * 	None
 * 
*/
import { Image } from './src/media/Image';
if (!window.customElements.get('rps-image'))
	window.customElements.define('rps-image', Image);

