import { html, css } from 'lit';
import { BaseInputControl } from '../baseClasses/BaseInputControl';
import { iconNames } from '../../svg-icons.js';
import { ref } from 'lit/directives/ref.js';
import '../../rps-svg.js';
import '../../rps-input-label.js';
import { styles } from './css/switch.css.js';

export class Switch extends BaseInputControl {
	static styles = styles;

	static get properties() {
		return {
			labelOnLeft: { type: Boolean },
		};
	}

	constructor() {
		super();
		this.label = " ";
		this.labelOnLeft = false;
	}


	render() {
		const alignment = this.labelOnLeft ? 'label-left' : 'label-right';

		return html`
			${this.addCss()}

			<div class="toggle ${this.className} ${alignment}" ${ref(this.container)}>

				<input type="checkbox" name=${this.name} id=${this.name + 'Input'}
					?checked=${this.checked} ?disabled=${this.disabled} ?required=${this.required} ?readonly=${this.readonly}
					@input=${this.inputChanged}
					${ref(this.inputCtrl)}
				/>

				<ul class="toggle-button" ${ref(this.container)}>
					<li class="button"></li>
					<li class="true">
						<span class="simple"></span>
						<span class="yn">Yes</span>
						<span class="oo">On</span>
						<span class="ic"><rps-svg svg="${iconNames.checked}"></rps-svg></span>
					</li>
					<li class="false">
						<span class="simple"></span>
						<span class="yn">No</span>
						<span class="oo">Off</span>
						<span class="ic"><rps-svg svg="${iconNames.del}"></rps-svg></span>
					</li>
				</ul>
				<rps-input-label
					class="inline"
					?required=${this.required} ?disabled=${this.disabled} hide=${this.hideLabel}
					text="${this.label}"
					${ref(this.labelCtrl)}
				></rps-input-label>
			</div>
		`;
	}

}
