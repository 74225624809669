import { useRef } from 'react';
import { useOnScreen } from '@odo/hooks/display';
import { chunkArray } from '@odo/utils/array';
import ColumnHeading from './column-heading';
import type { ColumnType, RowType, SORT_DIR } from './types';
import styles from './grid.module.css';

const Row = ({
  row,
  rowHeight,
  columns,
  gridTemplateColumns,
}: {
  row: RowType;
  rowHeight: number;
  columns: ColumnType[];
  gridTemplateColumns: string;
}) => (
  <div
    className={[styles.row, styles.rowAlternating].join(' ')}
    style={{ height: `${rowHeight}px`, gridTemplateColumns }}
  >
    {columns.map(({ id, render: CellRender }) => (
      <div key={`${row.rowId}-${id}`}>
        <CellRender id={row.rowId} data={row.data} />
      </div>
    ))}
  </div>
);

const SearchableRow = ({
  row,
  rowHeight,
}: {
  row: RowType;
  rowHeight: number;
}) => (
  <div style={{ height: `${rowHeight}px` }}>{(row.terms || []).join(' ')}</div>
);

const LazyRows = ({
  columns,
  gridTemplateColumns,
  rows,
  rowHeight,
  rootMargin = '0px',
  showByDefault = false,
}: {
  columns: ColumnType[];
  gridTemplateColumns: string;
  rows: RowType[];
  rowHeight: number;
  rootMargin?: string;
  showByDefault?: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const show = useOnScreen({ ref, showByDefault, rootMargin });

  return (
    <div ref={ref} className={styles.rowChunk}>
      {show ? (
        <>
          {rows.map(row => (
            <Row
              key={row.rowId}
              row={row}
              rowHeight={rowHeight}
              columns={columns}
              gridTemplateColumns={gridTemplateColumns}
            />
          ))}
        </>
      ) : (
        <>
          {rows.map(row => (
            <SearchableRow key={row.rowId} row={row} rowHeight={rowHeight} />
          ))}
        </>
      )}
    </div>
  );
};

const ColumnHeadings = ({
  columns,
  gridTemplateColumns,
  orderBy,
}: {
  columns: ColumnType[];
  gridTemplateColumns: string;
  orderBy?: { field: string; direction: SORT_DIR };
}) => (
  <div className={styles.header} style={{ gridTemplateColumns }}>
    {columns.map(col => (
      <span key={col.id}>
        <ColumnHeading column={col} orderBy={orderBy} />
      </span>
    ))}
  </div>
);

const Grid = ({
  columns,
  rows,
  rowHeight,
  rowChunkSize = 10,
  rootMargin,
  pageSize,
  setPageSize,
  pageSizeOptions,
  orderBy,
  footerCenterCopy,
}: {
  columns: ColumnType[];
  rows: RowType[];
  rowHeight: number;
  rowChunkSize?: number;
  rootMargin?: string;
  pageSize: number;
  setPageSize: (size: number) => void;
  pageSizeOptions: number[];
  orderBy?: { field: string; direction: SORT_DIR };
  footerCenterCopy?: string;
}) => {
  const gridTemplateColumns = columns.map(col => col.width).join(' ');

  return (
    <div className={styles.wrapper}>
      <ColumnHeadings
        columns={columns}
        gridTemplateColumns={gridTemplateColumns}
        orderBy={orderBy}
      />

      <div style={{ overflowY: 'scroll' }}>
        {chunkArray(rows, rowChunkSize).map((rowChunk, idx) => (
          <LazyRows
            key={idx}
            columns={columns}
            gridTemplateColumns={gridTemplateColumns}
            rows={rowChunk}
            rowHeight={rowHeight}
            rootMargin={rootMargin}
            // NOTE: this has been disabled to bypass an async race condition inside of a useEffect for the images
            // TODO: re-enable when we can
            // showByDefault={idx <= 0}
          />
        ))}
      </div>

      <div className={styles.footer}>
        <div className={styles.limitSelect}>
          <span>Deals to load:</span>

          <select
            defaultValue={pageSize}
            onChange={e => setPageSize(Number(e.target.value))}
          >
            {pageSizeOptions.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        {footerCenterCopy && <div>{footerCenterCopy}</div>}

        <div>
          Showing <strong style={{ fontWeight: 'bold' }}>{rows.length}</strong>{' '}
          deals
        </div>
      </div>
    </div>
  );
};

export default Grid;
