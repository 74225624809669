import { Fragment } from 'react';
import styled from '@odo/lib/styled';

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  row-gap: 5px;
`;
const Label = styled.span`
  font-weight: bold;
  font-size: 11px;
`;
const Value = styled.span`
  font-size: 11px;
`;

/**
 * Similar to a definition list, but laid out in a grid.
 */
const LabelledGrid = ({
  rows,
}: {
  rows: { label: string; value: string }[];
}) => (
  <Grid>
    {rows.map(({ label, value }, idx) => (
      <Fragment key={idx}>
        <Label>{label}:</Label>
        <Value>{value}</Value>
      </Fragment>
    ))}
  </Grid>
);

export default LabelledGrid;
