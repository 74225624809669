import { useCurrentProduct } from '@odo/contexts/product-editor';
import { useEffect, useRef } from 'react';
import { dismiss, notification } from '@odo/utils/toast';
import { useSetUniqueImagePositions } from '@odo/screens/deal/editor/images-and-videos/hooks';

const TOAST_ID = 'unique-image-positions';

const UniqueImagePositions = () => {
  const currentProduct = useCurrentProduct();
  const setUniquePositions = useSetUniqueImagePositions();

  const hasCompleted = useRef(false);

  useEffect(() => {
    if (
      !currentProduct.images ||
      currentProduct.images.length === 0 ||
      hasCompleted.current
    ) {
      return;
    }

    // prevent this effect from running again
    hasCompleted.current = true;

    // check if there are any duplicate positions
    const uniquePositions: number[] = [];
    const hasDuplicates = currentProduct.images.some(({ position }) => {
      if (typeof position !== 'number') return false;
      if (uniquePositions.includes(position)) return true;

      uniquePositions.push(position);
      return false;
    });

    if (!hasDuplicates) return;

    // set the unique positions automatically
    const { undo } = setUniquePositions();

    // notify users and give them a chance to undo if they prefer
    const toastId = notification(
      'All images have been assigned a unique position for reordering. You can undo this change, but you might have issues reordering the images without it.',
      {
        id: TOAST_ID,
        duration: Infinity,
        messageOptions: {
          dismissible: true,
          action: {
            label: 'Undo',
            callback: () => {
              undo();
              dismiss(toastId);
            },
          },
        },
      }
    );
  }, [currentProduct.images, setUniquePositions]);

  return null;
};

export default UniqueImagePositions;
