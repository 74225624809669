export const templateType = [
  { id: 'no', text: 'No' },
  { id: 'yes', text: 'Yes' },
];

// TODO: refactor/rename this to avoid confusion (eg. productTypeOptions)
export const dealType = [
  { id: 'product', text: 'Product' },
  { id: 'voucher', text: 'Voucher' },
  { id: 'supplierDispatch', text: 'Supplier Dispatch' },
  { id: 'parallelImport', text: 'Parallel Import' },
  { id: 'other', text: 'Other' },
];

export const dealSubTypeA = {
  product: [
    { id: 'consumablesAndPerishables', text: 'Consumables & Perishables' },
    { id: 'alcohol', text: 'Alcohol' },
    { id: 'other', text: 'Other' },
  ],
  voucher: [
    { id: 'personalisedProduct', text: 'Personalised Product' },
    { id: 'travelAndExperiences', text: 'Travel & Experiences' },
    { id: 'other', text: 'Other' },
  ],
};

export const dealSubTypeB = {
  alcohol: [
    { id: 'wine', text: 'Wine' },
    { id: 'liquorAndBeer', text: 'Liquor & Beer' },
    { id: 'other', text: 'Other' },
  ],
};

export const DESCRIPTION_BASE = `
<h5>About</h5>
<p>###</p>
<h5>Product Features</h5>
<ul>
<li>###</li>
</ul>
<h5>Product Specifications</h5>
<ul>
	<li>###</li>
</ul>
`;

export const DESCRIPTION_WINE = `
<h5>About</h5>
<p>xxx</p>
<h5>Product Features</h5>
<ul>
<li>xxx</li>
</ul>
<h5>Product Specifications</h5>
<ul>
	<li>Alcohol by Volume:</li>
	<li>Region:</li>
</ul>
`;

export const DESCRIPTION_BEER = `
<h5>About</h5>
<p>xxx</p>
<h5>Product Features</h5>
<ul>
<li>xxx</li>
</ul>
<h5>Product Specifications</h5>
<ul>
	<li>Alcohol by Volume:</li>
	<li>Expiry date:</li>
	<li>Volume: 330ml x 24 bottles</li>
	<li>Region:</li>
</ul>
`;

export const DESCRIPTION_ALCOHOL = `
<h5>About</h5>
<p>xxx</p>
<h5>Product Features</h5>
<ul>
<li>xxx</li>
</ul>
<h5>Product Specifications</h5>
<ul>
	<li>Alcohol by Volume:</li>
	<li>Expiry date:</li>
	<li>Volume:</li>
	<li>Region:</li>
</ul>
`;

export const DESCRIPTION_PERISHABLES = `
<h5>About</h5>
<p>xxx</p>
<h5>Product Features</h5>
<ul>
<li>xxx</li>
</ul>
<h5>Product Specifications</h5>
<ul>
	<li>Expiry date:</li>
</ul>
`;

export const DESCRIPTION_PERSONALIZED_VOUCHER = `
<h5>Please Note</h5>
<ul>
	<li>Your personalised voucher code(s) will be emailed to you within 2 working days of placing your order. Should you not receive the voucher within that time frame, please check your Spam or Junk folder before contacting us.</li>
	<li>We will not be charging you shipping but you will be charged between R50 &amp; R100 on redemption of the voucher because the supplier needs to effect delivery to you depending on your location. Ts &amp; Cs Apply.</li>
	<li>You have until the end of xxxxx to redeem your voucher.</li>
	<li>Please note that by purchasing this deal, you confirm that the supplier may get in touch with you directly in order to facilitate the deal.</li>
	<li>This deal is non-refundable after purchasing from OneDayOnly.</li>
</ul>
<h5>How it works</h5>
<ul>
	<li>xxx</li>
</ul>
`;

export const DESCRIPTION_TRAVEL_VOUCHER = `
<h5>About</h5>
<ul>
	<li>xxx</li>
</ul>
<h5>This Package Includes</h5>
<ul>
	<li>xxx</li>
</ul>
<h5>How it works</h5>
<ul>
	<li>XXXX will handle all reservations, so please make ALL reservations with them.</li>
	<li>It is recommended to make a booking within 24 hours of receiving your redemption code.</li>
	<li>This deal cannot be used with another promotion.</li>
	<li>This deal is non-refundable after purchasing from OneDayOnly.</li>
	<li>Once you have placed your order, a redemption code will be sent to you within two working days after payment</li>
	<li>Please contact XXXX with your redemption code at hand.</li>
	<li>Select your desired dates which are subject to availability.</li>
	<li>You will receive an email with a confirmation of your booking.</li>
	<li>*<strong>Subject to availability: The establishment has the available dates mentioned, we always recommend booking within 24 hours of receiving your redemption code to avoid disappointment.</strong></li>
</ul>
<p>Please note that by purchasing this deal, you confirm that the supplier may get in touch with you directly in order to facilitate the deal.</p>
`;

export const DESCRIPTION_PARALLEL_IMPORT = `
<h5>Please Note</h5>
<p>This product is a parallel import.
This has nothing to do with the authenticity of the product (It is completely authentic),
but means it has been imported without the approval, or license of the registered owner
of the trademark and therefore no guarantee or warranty in respect of such goods will be
honoured or fulfilled by any official or licensed importer of such goods.
However, any warranty claims will be covered by OneDayOnly or the relevant third party seller,
in accordance with our standard return/refund policy.
</p>
<h5>About</h5>
<p>xxx</p>
<h5>Product Features</h5>
<ul>
	<li>xxx</li>
</ul>
<h5>Product Specifications</h5>
<ul>
	<li>xxx</li>
</ul>
`;
