import { html } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { styles } from './css/summary.css.js';

export class Summary extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			data: { type: Array },
			header: { type: String },
			footer: { type: String },
		};
	}

	constructor() {
		super();
		this.data = [];
		this.header = '';
		this.footer = '';
		// this.data = [
		// {
		// 	id: 'a',
		// 	term: 'Term',
		// 	description: 'Description',
		// };
	}

	_renderItems(description) {
		if (!description || description.length === 0) return '';				// Same for zero-length arrays

		const items = description.map(item => {
			return html`
				<dt id="${item.id}">
					${item.term}
				</dt>
				<dd id="${item.id}" class="${item.itemState}">
					${item.description}
				</dd>
			`;
		});

		return html`<dl>${items}</dl>`;
	}

	_renderFooter(descriptionResult) {
		if (!descriptionResult) return '';											// if there are no items in this collection, end processing.
		if (descriptionResult.length === 0) return ''; 							// Same for zero-length arrays

		const items = descriptionResult.map(item => {
			return html`
				<dt id="${item.id}">
					${item.term}
				</dt>
				<dd id="${item.id}" class="${item.itemState}">
					${item.description}
				</dd>
			`;
		});

		return html`<dl>${items}</dl>`;
	}


	render() {
		return html`
			${this.addCss(true)}
			
			<div class="summary-container ${this.className}">
				<div class="header">
					<slot name="left"></slot>
					<h6><slot name="heading">${this.header}</slot></h6>
					<slot name="right"></slot>
				</div>
				<div class="body">
					${this._renderItems(this.data, 'descriptionList')}
					<slot></slot>
				</div>
				<div class="footer">
					${this._renderFooter(this.result, 'descriptionResult')}
					<span>${this.footer}</span>
				</div>
			</div>
		`;
	}
}
