/**
 * @name rps-carousel
 * @type {Carousel}
 * A Carousel control
 * 
 * @attributes + @properties 						| Changes trigger a re-render
 * Casing usages:										| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String}	customCss			| Specify a custom .css file to include, use cssPath to override elements,
 * 															DO NOT override the styling definition by re-defining the css styling
 * 															Put this file into the *window.cssPath* folder @see {link README.md}
 * 															eg: /public/css (where the other css files are)
 * 															Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css					| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 	@property {Array} 	values 				| List of items to display in the carousel, can be a number, string, html
 * 	@property {Boolean} 	isHorizontal 		| Display this in horizontal or vertical mode, default id horizontal
 * 	@property {Number} 	selectedIndex 		| What item index must display in the front?, default is "0"
 * 	@property {String} 	widthRem 			| optional: the width in 'rem' of a unit (one of the cells)
 * 	@property {String} 	heightRem 			| optional: the height in 'rem' of a unit (one of the cells)
 * 	@property {String} 	fontWeight 			| optional: Font weight, default is 'bold'
 * 	@property {String} 	textColor 			| optional: default is 'white'
 * 	@property {String} 	textAlign 			| optional: default is 'center'
 * 	@property {Boolean} 	showFrame 			| optional: Display a "photo like frame around the highlighted element?", default=showFrame
 * 	@property {String} 	perspectiveRem 	| Optional: the view distance from the carousel in 'rem', default=100
 * 	@property {Boolean} 	backgroundColors	| Optional: default=false. If set to true, then change the cells background colors automatically to different values
 * 	@property {String} 	background 			| Optional: default=rgba(76, 175, 80, 0.8)
 * 															NB: Use either 'backgroundColors' or 'background'
 * 															One preselected colors for you, the other uses your background and opacity
 * 												
 * 
 * @methods
 * 	setCurrentPage(currentPage)				| Set the active page
 * 	next()											| rotate the carousel to the next position
 * 	previous()										| rotate the carousel to the previous position
 * 	orientationChange()							| change the display vertical <-> horizontal
 * 	setValues(values)								| The contents of the carousel can be changed by using this method
 * 
 * @events
 * 	click												| When the user clicks on a cell
 * 
 * @callbacks
 * 	cbClick											| Called when the user clicks on a cell
 * 
 * SLOTS:
 * 	None
 * 
 * @example+ @Lit
 * @basic
 * @see {link /demo/easy/spinners/carousel.html}
 * 
 * @advanced
 * @see {link /demo/complex/carousel.html}
 * 
*/

import { Carousel } from './src/spinners/Carousel';
if (!window.customElements.get('rps-carousel'))
	window.customElements.define('rps-carousel', Carousel);

