import { html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import '../../rps-layout-header';
import { styles } from './css/layout.css.js';
import { iconNames } from '../../svg-icons';

export class Layout extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			headerTitle: { type: String },
			logo: { type: String },
			version: { type: String },
			menuItems: { type: Array },
			toolBarButtons: { type: Array },
			cbClickMenu: { attribute: false },
			cbClickMenuOptions: { attribute: false },
			cbClickToolbar: { attribute: false },
			cbClickLogo: { attribute: false },
		};
	}

	constructor() {
		super();

		this.menuItems = [];
		this.toolBarButtons = [
			{ id: "settings", svg: `${iconNames.settings}`, title: "Settings" },
			{ id: "user", svg: `${iconNames.user}`, title: "User" },
		];

		this.menuContainerRef = createRef();
		this.rightMenuContainerRef = createRef();
		this.layoutHeaderRef = createRef();
		this.menuRef = createRef();

		this.menuOpened = false;

		this.version = "Version 1.0.0 (2022-01-04)";

		// 'pre-select' an estimated internet speed
		window.internetSpeed = 'fast';
		if (navigator.connection) {
			const type = navigator.connection.effectiveType || '4g';		// estimated connection type
			const rtt = navigator.connection.rtt || 200;						// return round trip to server in nearest 25ms

			// set if medium
			if (type === "3g" || (rtt >= 400 && rtt <= 600)) {
				window.internetSpeed = 'medium';
			} else if (type === "2g" || type === "slow-2g" || rtt > 600) {
				window.internetSpeed = 'slow';
			}
		}
	}

	/**
	 * A reference to the menu <rps-tree>
	 *
	 * @readonly
	 * @memberof Layout
	 */
	get menu() {
		return this.menuRef.value;
	}

	/**
	 * A reference to the <rps-layout-header>
	 *
	 * @readonly
	 * @memberof Layout
	 */
	get layoutHeader() {
		return this.layoutHeaderRef.value;
	}

	/**
	 * A reference to the menu container
	 *
	 * @readonly
	 * @memberof Layout
	 */
	get menuContainer() {
		return this.menuContainerRef.value;
	}

	/**
	 * A reference to the right menu container
	 *
	 * @readonly
	 * @memberof Layout
	 */
	get rightMenuContainer() {
		return this.rightMenuContainerRef.value;
	}

	/**
	 * Toggle the expansion of the menu
	 *
	 * @memberof Layout
	 */
	toggleMenu() {
		this.menuContainer.classList.toggle('show');
		this.menu.classList.toggle('aside-open');
		this.menu.requestUpdate();
	}

	/**
	 * Toggle the expansion of the right menu
	 *
	 * @memberof Layout
	 */
	toggleRightMenu() {
		this.rightMenuContainer.classList.toggle('show');
	}

	/**
	 * Fired when the user clicks on one of the toolbar icons
	 *
	 * @param {Event} ev
	 * @memberof Layout
	 */
	toolbarClick(ev) {
		if (typeof ev.detail === 'object' && ev.detail.source === 'RPS-TOOLBAR') {
			console.debug(`Layout:toolbarClick:`, ev.detail);
			ev.preventDefault();
			ev.stopPropagation();

			const event = new CustomEvent('clickToolbar', { bubbles: true, cancelable: true, composed: true, detail: ev.detail });
			this.dispatchEvent(event);

			if (this.cbClickToolbar) this.cbClickToolbar(event.detail);
		}
	}

	/**
	 * Fired when the user clicks on the logo
	 *
	 * @param {Event} ev
	 * @memberof Layout
	 */
	logoClick(ev) {
		console.debug(`Layout:logoClick:`, ev.detail);
		ev.preventDefault();
		ev.stopPropagation();

		const event = new CustomEvent('clickLogo', { bubbles: true, cancelable: true, composed: true, detail: ev.detail });
		this.dispatchEvent(event);

		if (this.cbClickLogo) {
			this.cbClickLogo(event);
		}
	}

	/**
	 * Fires when the user clicks on a menu item
	 *
	 * @param {Event} ev
	 * @memberof Layout
	 */
	menuClick(ev) {
		if (typeof ev.detail === 'object') {
			console.debug(`Layout:menuClick:`, ev.detail);
			ev.preventDefault();
			ev.stopPropagation();

			if (this.menuContainer) {
				this.menuContainer.classList.remove('show');
				this.requestUpdate();
			}

			const event = new CustomEvent('clickMenu', { bubbles: true, cancelable: true, composed: true, detail: ev.detail });
			this.dispatchEvent(event);

			if (this.cbClickMenu) this.cbClickMenu(event.detail);
		}
	}

	/**
	 * Fires when the user clicks on a "icon" in the menu
	 *
	 * @param {Event} ev
	 * @memberof Layout
	 */
	menuOptionsClick(ev) {
		if (typeof ev.detail === 'object') {
			console.debug(`Layout:menuOptionsClick`, ev.detail);
			ev.preventDefault();
			ev.stopPropagation();

			if (this.menuContainer) {
				this.menuContainer.classList.remove('show');
				this.requestUpdate();
			}

			const event = new CustomEvent('clickMenuOptions', { bubbles: true, cancelable: true, composed: true, detail: ev.detail });
			this.dispatchEvent(event);

			if (this.cbClickMenuOptions) this.cbClickMenuOptions(event.detail);
		}
	}

	/**
	 * Intercepts clicks on content area and, if the menu was open, closes it
	 *
	 * @param {Event} ev
	 */
	interceptContentClick(ev) {
		if (this.menuContainer.classList.contains('show')) {
			ev.preventDefault();
			ev.stopPropagation();

			this.menuContainer.classList.remove('show');
			this.requestUpdate();
		}
	}

	render() {
		return html`
			${this.addCss()}
			<div class="layout ${this.className}" >

				<div class="popup">
					<slot name="popup"></slot>
				</div>

				<nav>
					<slot name="nav">
						<rps-layout-header
							${ref(this.layoutHeaderRef)}
							class="${this.className}"
							@click=${this.toolbarClick}
							@clickLogo=${this.logoClick}
							.toolBarButtons=${this.toolBarButtons}
							.version=${this.version}
							.headerTitle=${this.headerTitle}
							.logo="${this.logo}">
						</rps-layout-header>
					</slot>
				</nav>

				<main @click=${this.interceptContentClick}>

					<aside id="toggleAside" class="aside-left" ${ref(this.menuContainerRef)}>
						<div class="aside-content">
							<slot name="aside-left">
								<rps-tree
									${ref(this.menuRef)}
									@click=${this.menuClick}
									@clickOptions=${this.menuOptionsClick}
									class="aside"
									.data=${this.menuItems}>
								</rps-tree>
							</slot>
						</div>
					</aside>

					<section>
						<div class="toast">
							<slot name="toast"></slot>
						</div>

						<div class="non-scroll">
							<slot name="sticky-header"></slot>
						</div>

						<div class="content-scroll">
							<header>
								<slot name="header"></slot>
							</header>

							<article>
								<slot name="main"></slot>
							</article>

							<footer>
								<slot name="footer"></slot>
							</footer>
						</div>
					</section>

					<aside class="aside-right" ${ref(this.rightMenuContainerRef)}>
						<div class="aside-content">
							<slot name="aside-right"></slot>
						</div>
					</aside>

				</main>
			</div>
		`;
	}
}

