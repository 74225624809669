/**
 * @name rps-tile
 * @type TileItem
 * A tile control
 * 
 * 
 * 
 * @attributes + @properties 						| Changes trigger a re-render
 * Casing usages:										| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String}	customCss 			| Specify a custom .css file to include, use cssPath to override elements,
 * 															DO NOT override the styling definition by re-defining the css styling
 * 															Put this file into the *window.cssPath* folder @see {link README.md}
 * 															eg: /public/css (where the other css files are)
 * 															Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css					| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 												
 * 
 * 
 * @Styling (Default: primary, large, solid)
 * ========================================
 * @Tile Colors
 * 	primary:		Primary colors used for the button
 * 	secondary:  Secondary colors used for the button
 * 	tertiary: 	Tertiary colors used for the button
 * @Sizes
 * 	small:		Small button
 * 	medium:		Medium size button
 * 	large:		Large size button
 * disabled:		Disable this button
 * @Filling		
 * 	outline: 	Only an outline for the button displays, no filling
 * 	solid:		The contents of the button are filled, not only the outline
 * @Shape and Shading
 * 	square:		Square Shape
 * 	rounded:		Rounded corners
 * 	shaded:		Shading for tile
 * @Orientation allow slots to display top,bottom OR left,right
 * 	vertical:	Display slotted items in a vertical layout
 * 	horizontal:	Display slotted items in a horizontal layout
 * 
 * 
 * @methods
 * 	get tileItemControl						| a readonly property to retrieve the Tile
 * 	toggleClass(className, toAdd = true)| Add or remove a class to this control
 * 
 * @events
 * 	click											| Fired when the tile is clicked on
 * 
 * @slots
 * 	slot-first									| Optional slotted content to the left (or top)
 * 	slot-last									| Optional slotted content to the right (or bottom)
 * 
 * 
 * 
 * @example+ @lit
 * @basic
 * =====
 * import '@rps-wc/rps-tile-item';
 * 
 * 	<rps-tile-item @click=${e => console.log(e)}>
 * 	</rps-tile-item>
 * 
 * 
 * @advanced , both slots used
 * ========
 * import '@rps-wc/rps-tile-item';
 * 
 * 	<rps-tile-item @click=${e => console.log(e)} 
 * 		class="secondary outline vertical">
 * 		<div slot="slot-first">
 * 			<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>
 * 		</div>
 * 		Manage Deal
 * 		<div slot="slot-last">
 * 			<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"/></svg>
 * 		</div>
 * 	</rps-tile-item>
 * 
 * 
 * @example+ @React
 * @basic
 * =====
 * import '@rps-wc/rps-tile-item';
 * 
 * 	<rps-tile-item cbClick={e => console.log(e)}>
 * 	</rps-tile-item>
 * 
 * 
 * @advanced , both slots used
 * ========
 * import '@rps-wc/rps-tile-item';
 * 
 * 	<rps-tile-item cbClick={e => console.log(e)} class="secondary outline vertical">
 * 		<div slot="slot-first">
 * 			<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>
 * 		</div>
 * 		Manage Deal
 * 		<div slot="slot-last">
 * 			<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"/></svg>
 * 		</div>
 * 	</rps-tile-item>
 * 
 * 
 * 
*/
import { TileItem } from './src/lists/TileItem.js';
if (!window.customElements.get('rps-tile-item'))
	window.customElements.define('rps-tile-item', TileItem);

