/*
const myItems = [
			{"id": 1, "firstName": "John",	"lastName": "Smith", "email": "john.smith@redpandasoftware.co.za", "class": ""},
			{"id": 2, "firstName": "Mackie", "lastName": "Smith", "email": "mackie.smith@redpandasoftware.co.za", "class": "", selected: true},
			{"id": 3, "firstName": "Billy", "lastName": "Bob", "email": "billy.bob@redpandasoftware.co.za", "class": "" }
		];
const preSelectItems = [{ id: 1}];		// a list of items that must be pre-selected, only the "id" property is used

<rps-remove-user
	mode="multi || single"
	layout="row || column"
	@items-selected="${selectedHandler}"
	placeholder="enter some text"
	displayItems=["firstName", "lastName"]
	users={myItems}
	includeCss="./custom.css"
>
</rps-remove-user>

ATTRIBUTES + PROPERTIES: 				[Changes trigger a re-render]
Casing usages:
	* Attribute in html (lower case), 
	* Property in JS (Camel Case)
========================
customCss: { type: String },			[Specify a custom .css file to include, use cssPath to override elements,
													DO NOT override the styling definition by re-defining the css styling
													Put this file into the *window.cssPath* folder @see {link README.md}
													eg: /public/css (where the other css files are)
													Suggested use: use sub-folders, dont put it all into the base
												]
@property {String}	css			| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.


*/
import { RemoveUser } from './src/user/RemoveUser';
if (!window.customElements.get('rps-remove-user'))
	window.customElements.define('rps-remove-user', RemoveUser);

