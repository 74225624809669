/**
 * TODO: when refactoring this should be structured better and use types.
 * But while part of RedPandas codebase (because it's dependent on the deal context),
 * it would be a waste of time to add new structures.
 */

import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Deal } from '../../models/Deal.jsx';
import { DealPriceAndCustomOptions } from '../../models/PriceAndCustomOptions.jsx';
import { useCurrentDealSource } from 'hooks/useCurrentDealSource';
import { calcWeightAreaSurcharge } from '@odo/helpers/calculations/shipping.ts';
import { notification } from '@odo/utils/toast.tsx';
import { isNumeric } from '@odo/utils/validation.ts';
import { DealShipping } from 'models/Shipping.jsx';
import { useCustomOptionsEditorContext } from '@odo/contexts/custom-options-editor';

export const SURCHARGE_WEIGHT_KEY = 'WEIGHT_AREA';

const SURCHARGE_TOAST_ID = 'auto-weight-area-surcharge';

const areaMap = {
  CAPE_TOWN: 'cpt',
  JOHANNESBURG: 'jhb',
};

const getFloatOrUndefined = value => {
  if (typeof value === 'number') {
    return value;
  } else if (typeof value === 'string' && value !== '') {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? undefined : parsed;
  } else {
    return;
  }
};

export const getSurchargeTotal = allSurcharges =>
  allSurcharges.reduce((total, surcharge) => {
    if (isNumeric(surcharge.value)) {
      return total + parseFloat(surcharge.value);
    } else {
      return total;
    }
  }, 0);

let weightAreaSurchargeTimeoutId;

/**
 * NOTE: could've been a custom hook.
 * But the value of a component wrapped around the logic
 * is that you can conditionally render the component
 * to enable/disable the underlying logic.
 * Whereas hooks need to always be called.
 * Also not much point making a hook when it needs to be tied to RP deal context for now.
 */
const DealSideEffects = memo(() => {
  const { deal, update } = useCurrentDealSource();

  const [initialized, setInitialized] = useState(false);

  const prevWeightAreaSurcharge = useRef();

  useEffect(() => {
    if (Array.isArray(deal.priceAndCustomOptions.surcharges)) {
      const surcharge = deal.priceAndCustomOptions.surcharges.find(
        s => s.key === SURCHARGE_WEIGHT_KEY
      );
      if (surcharge && surcharge.value === '') {
        prevWeightAreaSurcharge.current = undefined;
      } else if (
        surcharge &&
        surcharge.value !== Math.abs(prevWeightAreaSurcharge.current) &&
        (typeof surcharge.value !== 'string' ||
          parseFloat(surcharge.value) !==
            Math.abs(prevWeightAreaSurcharge.current))
      ) {
        prevWeightAreaSurcharge.current = Math.abs(
          typeof surcharge.value === 'string'
            ? parseFloat(surcharge.value)
            : surcharge.value
        );
      }
    }

    setInitialized(true);
  }, [deal.priceAndCustomOptions.surcharges]);

  const weightAreaSurcharge = useMemo(() => {
    const [area, supplierDelivers, weight, length, width, depth] = [
      deal.product.area,
      deal.shipping.isDeliveredBySupplier,
      deal.shipping.weight,
      deal.shipping.length,
      deal.shipping.width,
      deal.shipping.height,
    ];

    const surcharge = calcWeightAreaSurcharge({
      area: area in areaMap ? areaMap[area] : area,
      supplierDelivers: !!supplierDelivers,
      weight: getFloatOrUndefined(weight),
      length: getFloatOrUndefined(length),
      width: getFloatOrUndefined(width),
      depth: getFloatOrUndefined(depth),
    });

    return surcharge;
  }, [
    deal.product.area,
    deal.shipping.isDeliveredBySupplier,
    deal.shipping.weight,
    deal.shipping.length,
    deal.shipping.width,
    deal.shipping.height,
  ]);

  useEffect(() => {
    if (initialized) {
      const applySurcharge = (weightAreaSurcharge, deal, update) => {
        if (weightAreaSurcharge !== prevWeightAreaSurcharge.current) {
          // ensure we don't run the set again when the deal changes
          prevWeightAreaSurcharge.current = weightAreaSurcharge;

          const allSurcharges = [
            ...(deal.priceAndCustomOptions.surcharges || []).filter(
              s => s.key !== SURCHARGE_WEIGHT_KEY
            ),
            {
              key: SURCHARGE_WEIGHT_KEY,
              value:
                typeof weightAreaSurcharge !== 'undefined'
                  ? (weightAreaSurcharge * -1).toFixed(2)
                  : '',
            },
          ];

          // recalculate the surcharge total as well
          const total = getSurchargeTotal(allSurcharges);

          deal.set(
            Deal.MODELS.PRICE_AND_CUSTOM_OPTIONS,
            DealPriceAndCustomOptions.FIELDS.SURCHARGES,
            allSurcharges
          );
          deal.set(
            Deal.MODELS.PRICE_AND_CUSTOM_OPTIONS,
            DealPriceAndCustomOptions.FIELDS.SURCHARGE,
            parseFloat(total).toFixed(2)
          );
          update();

          if (typeof weightAreaSurcharge === 'undefined') {
            notification('Weight/area surcharge cleared', {
              id: SURCHARGE_TOAST_ID,
            });
          } else {
            notification(
              <span>
                Weight/area surcharge automatically updated to:{' '}
                <b>R{weightAreaSurcharge}</b>
              </span>,
              { id: SURCHARGE_TOAST_ID }
            );
          }
        }
      };

      weightAreaSurchargeTimeoutId = setTimeout(
        () => applySurcharge(weightAreaSurcharge, deal, update),
        500
      );

      return () => clearTimeout(weightAreaSurchargeTimeoutId);
    }
  }, [initialized, weightAreaSurcharge, deal, update]);

  /**
   * NOTE: sync custom options cumulative qty here (not product qty).
   */
  const cumulativeQty = useRef();
  const { getCumulativeQty } = useCustomOptionsEditorContext();

  useEffect(() => {
    const nextCumulativeQty = getCumulativeQty();

    // if next cumulative quantity has changed update the deal model data.
    if (
      typeof nextCumulativeQty !== 'undefined' &&
      nextCumulativeQty !== cumulativeQty.current
    ) {
      cumulativeQty.current = nextCumulativeQty;

      deal.set(
        Deal.MODELS.SHIPPING,
        DealShipping.FIELDS.CUMULATIVE_CUSTOM_OPTIONS_QTY,
        nextCumulativeQty
      );
      update();
    }
  }, [getCumulativeQty, deal, update]);

  return null;
});

export default DealSideEffects;
