import { InputNumericMasked } from '@odo/components/elements/form-fields';
import {
  useChangeProduct,
  useCurrentProduct,
} from '@odo/contexts/product-editor';

const OriginalStock = ({ disabled }: { disabled?: boolean }) => {
  const currentProduct = useCurrentProduct();
  const change = useChangeProduct();

  return (
    <InputNumericMasked
      label="Rebate (%)"
      value={currentProduct.rebateDiscount?.string || ''}
      disabled={disabled}
      type="decimal"
      onChange={rebateDiscount => {
        change({
          fieldId: 'rebateDiscount',
          label: 'Rebate (%)',
          apply: to => (to.rebateDiscount = rebateDiscount),
        });
      }}
    />
  );
};

export default OriginalStock;
