/**
 * @name rps-forgot-password
 * @type {ForgotPassword}
 * 	A forgot password component.
 * 
 * 
 * @attributes + @properties 									| Changes trigger a re-render
 * Casing usages:													| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String}	userNameLabel = 'User name'| The username label.
 * 	@property {String}	userNamePattern = '.{5,}'	| Username regex expression.
 * 	@property {String}	userNamePlaceholder = 'Enter your email' | Username place holder text.
 * 	@property {String}	userNameErrorMessage = 'Usernames must be a valid email'	| Username error message.
 * 	@property {String}	confirmButtonText = 'Login'| Confirm button text.
 * 	@property {String}	customCss 						| Specify a custom .css file to include, use cssPath to override elements,
 * 																		DO NOT override the styling definition by re-defining the css styling
 * 																		Put this file into the *window.cssPath* folder @see {link README.md}
 * 																		eg: /public/css (where the other css files are)
 * 																		Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css								| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 	@property {String}	userNameType					| What type is the userName input control?
 * 																		Default = 'email'
 * 													
 * @properties (JS Only)
 * 	inputControl (get only)									| Return a reference to the input control
 * 
 * @methods
 * 	None
 * 
 * @events
 * 	submit														| Submit button clicked
 * 	cancel														| Cancel button clicked
 *
 * @callbacks
 * 	cbSubmit														| Callback for the submit event
 * 	cbCancel														| Callback for the cancel event
 * 
 * @slots
 * 	header
 * 
 * 
 * @example {Lit}
 * import '@rps-wc/wc/rps-forgot-password';
 * 
 * 	<rps-forgot-password @reset=${(e) => console.log(e.detail)}>
 * 	</rps-forgot-password>
 * 
 * 
 * @example {React}
 * import '@rps-wc/wc/rps-forgot-password';
 * 
 * 	const iconClick = (ev) => {
 * 		console.log(ev.detail);
 * 	}
 * 
 * 	<rps-forgot-password cbReset={okHandler}
 *  		usernamelabel="user"
 * 		usernamepattern=".{5,}"
 * 		usernameplaceholder="enter username"
 * 		usernameerrormessage="invalid username"
 * 		confirmbuttontext="Forgot"
 *  	>
 * 	</rps-forgot-password>
 * 
 * 
 */
import { ForgotPassword } from './src/authentication/ForgotPassword';
if (!window.customElements.get('rps-forgot-password'))
	window.customElements.define('rps-forgot-password', ForgotPassword);

