import { html, css } from 'lit';
import { ref, createRef } from 'lit/directives/ref.js';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import '../../rps-standard-buttons.js';
import '../../rps-svg.js';
import { iconNames } from '../../svg-icons.js';
import { styles } from './css/modal.css.js';

export class Modal extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			opened: { type: Boolean },
			popup: { type: Boolean },
			allowNoConfirm: { type: Boolean },
			headerText: { type: String },
			cancelText: { type: String },
			confirmText: { type: String },
			busyText: { type: String },
			cbConfirm: { attribute: false },
			cbCancel: { attribute: false },
		};
	}


	constructor() {
		super();
		this.opened = false;
		this.popup = false;
		this.allowNoConfirm = false;
		this.headerText = "Confirm";
		this.cancelText = "Cancel";
		this.confirmText = "Confirm";
		this.busyText = "BUSY";
		this.modalContainer = createRef();
		this.buttons = createRef();
		this.svg = createRef();
	}

	/**
	 * Hide the modal
	 *
	 * @param {Event} event
	 * @memberof Modal
	 */
	hide(event) {
		if (event) {
			event.stopPropagation();
		}

		this.removeAttribute('opened');

		// when called from ReactJS, this check is necessary
		if (this.modalContainer.value) {
			this.modalContainer.value.classList.remove('show');
			this.modalContainer.value.setAttribute('aria-hidden', 'true');
		}
	}


	/**
	 * Show the modal
	 *
	 * @memberof Modal
	 */
	show() {
		this.setAttribute('opened', '');

		// when called from ReactJS, this check is necessary
		if (this.modalContainer.value) {
			this.modalContainer.value.classList.add('show');
			this.modalContainer.value.setAttribute('aria-hidden', 'false');
		}
	}

	/**
	 * Allows for the status change in the standard buttons
	 * @see {link rps-standard-buttons} for statuses
	 *
	 * @param {String} status
	 * @memberof Modal
	 */
	setStatus(status) {
		this.standardButtonsControl.setStatus(status);
	}

	/**
	 * The <rps-standard-buttons> used
	 *
	 * @readonly
	 * @memberof Modal
	 */
	get standardButtonsControl() {
		return this.buttons?.value;
	}

	/**
	 * The <rps-svg> used
	 *
	 * @readonly
	 * @memberof Modal
	 */
	get svgControl() {
		return this.svg?.value;
	}

	/**
	 * Modal close button was clicked
	 *
	 * @param {Event} event
	 * @memberof Modal
	 */
	_cancel(event) {
		event.stopPropagation()
		event.preventDefault();

		console.debug('Modal: cancel', event);
		const detail = {
			source: this.tagName,
			id: this.id,
			name: this.name,
		};
		const e = new CustomEvent('cancel', { detail, bubbles: true, composed: true, cancelable: true });
		this.dispatchEvent(e);

		if (this.cbCancel) this.cbCancel(e);

		this.hide(event);
	}


	/**
	 * Confirm button clicked
	 *
	 * @param {Event} event
	 * @memberof Modal
	 */
	_confirm(event) {
		event.stopPropagation()
		event.preventDefault();

		console.debug('Modal: confirm', event);
		const detail = {
			source: this.tagName,
			id: this.id,
			name: this.name,
		};
		const e = new CustomEvent('confirm', { detail, bubbles: true, composed: true, cancelable: true });
		this.dispatchEvent(e);
		if (this.cbConfirm) this.cbConfirm(e);

		this.hide(event);
	}


	/**
	 * Generate the HTML for the icon
	 *
	 * @return {html}
	 * @memberof Modal
	 */
	_getStatusIcon() {
		if (this.classList.contains('info')) {
			return html`<rps-svg svg="${iconNames.info}"></rps-svg>`;
		} else if (this.classList.contains('help')) {
			return html`<rps-svg svg="${iconNames.help}" /></rps-svg>`;
		} else if (this.classList.contains('success')) {
			return html`<rps-svg svg="${iconNames.check}" /></rps-svg>`;
		} else if (this.classList.contains('warning')) {
			return html`<rps-svg svg="${iconNames.warning}" /></rps-svg>`;
		} else if (this.classList.contains('error')) {
			return html`<rps-svg svg="${iconNames.error}" /></rps-svg>`;
		}

		return '';
	}

	/**
	 * User pressed a Key
	 *
	 * @param {event} event
	 * @memberof Modal
	 */
	keyPressed(event) {
		if (this.allowNoConfirm) {
			console.debug('Modal:keyPressed', event.key);
			if (event.key === 'Enter') this._confirm(event);
			if (event.key === 'Escape') this._cancel(event);
		}
	}

	/**
	 * Container was clicked (outside the modal. ie: background)
	 *
	 * @param {*} event
	 * @memberof Modal
	 */
	containerClick(event) {
		event.stopPropagation();

		console.debug('Modal:containerClick', event);
		if (this.allowNoConfirm && event.target.classList.contains('popup-container')) {
			event.preventDefault();
			this._cancel(event);
		}
	}

	render() {
		const op = this.getAttribute('opened');
		const display = !!(op !== undefined & op !== null);

		setTimeout(() => {
			// Set Focus to the modal so they keyboard event can be intercepted to confirm or close it
			if (this.allowNoConfirm) {
				this.renderRoot.querySelector('.popup-container').focus();
			}
		}, 100);

		return html`
		${this.addCss(true)}

		<div class="popup-container ${display ? 'show' : ''}" ?popup=${this.popup} aria-hidden="${display}"
			@keydown=${this.keyPressed} tabindex="-1" @click="${this.containerClick}">
			<div class="modal ${this.className}" ${ref(this.modalContainer)} id="modal-container">

				<div class="header">
					<div class="state-icon">${this._getStatusIcon()}</div>

					<slot name="header">
						<h6>${this.headerText}</h6>
					</slot>

					<rps-svg aria-label="Close" @click=${this._cancel} id="modal-close" svg="${iconNames.del}" ${ref(this.svg)}></rps-svg>

				</div>
				<div class="body">
					<slot></slot>
				</div>
				<slot name="footer">
					<div class="footer">
						<rps-standard-buttons ${ref(this.buttons)}
							canceltext=${this.cancelText} confirmtext=${this.confirmText} busytext=${this.busyText}
							@cancel=${this._cancel} @confirm=${this._confirm}
							class="${this.className}"
						>
						</rps-standard-buttons>
					</div>
				</slot>
			</div>
		</div>
	`;
	}
}
