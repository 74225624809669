/**
 * @name rps-gauge
 * @type {Gauge}
 * 
 * @attributes + @properties 				| Changes trigger a re-render
 * Casing usages:								| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {Number} sizeRem 		| Size of chart in REM. Default = 10
 * 	@property {Number} percentage 	| Size of chart in REM. Default = 85
 * 	@property {String} fontSize 		| Size of chart in REM. Default = '1.4rem'
 * 	@property {Array} colors 			| 
 * 													Array of colors to use for the pie chart items
 * 													Default = | { max: 30, color: "green" }, { max: 60, color: "yellow" }, { max: 100, color: "red" }
 * 												
 * 	@property {String} customCss		| Specify a custom .css file to include, use cssPath to override elements,
 * 													DO NOT override the styling definition by re-defining the css styling
 * 													Put this file into the *window.cssPath* folder @see {link README.md}
 * 													eg: /public/css (where the other css files are)
 * 													Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String} css				| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 												
 * 	@property {String} heading			| Heading for Chart. Default = "Gauge"
 * 
 * 
 * @methods
 * 	getColor()								| gets the color object that this percentage falls into
 * 
 * @events
 * 	colorChange								| Fired when the "color" changes
 * 													Colors are important as they denote the "category" that this value falls into.
 * 													Fired when the control renders that contains the percentage and color category
 * 													Can be used for reacting to the number of errors (percentage) that fall into the "red" category
 * 
 * @callbacks
 * 	cbColorChange							| Called when the "color" changes
 * 													@see {link @event colorChange} for details
 * 
 * @slots
 * 	None
 * 
*/
import { Gauge } from './src/charts/Gauge';
if (!window.customElements.get('rps-gauge'))
	window.customElements.define('rps-gauge', Gauge);

