import { html } from 'lit';
import { createRef, ref } from 'lit/directives/ref.js';
import { BaseInputControl } from '../baseClasses/BaseInputControl';
import { iconNames } from '../../svg-icons.js';
import '../../rps-svg.js';
import '../../rps-input-label';
import '../../rps-card';
import { styles } from './css/toast.css.js';

export class Toast extends BaseInputControl {
	static styles = styles;

	static get properties() {
		return {
			text: { type: String },
			required: { type: Boolean },
			disabled: { type: Boolean },
			expireSeconds: { type: Number },
			position: { type: String },
			effect: { type: String },
			repeat: { type: Boolean },
			repeatSeconds: { type: Number },
			opened: { type: Boolean },
			cbClose: { attribute: false },
			cbExpire: { attribute: false },
		};
	}

	constructor() {
		super();
		this.text = "My Label";
		this.required = false;
		this.disabled = false;
		this.labelCtrl = createRef();
		this.svgCtrl = createRef();
		this.cardCtrl = createRef();
		this.expireSeconds = 5;
		this.position = "topCenter";		// "bottomLeft", "bottomCenter", "bottomRight", "topLeft", "topCenter", "topRight"
		this.effect = "slide";				// "fade", "slide"
		this.repeat = false;
		this.repeatSeconds = 0;				// only used if repeat === true.
		this.opened = false;
	}

	/**
	 * The <rps-input-label> control for this Toast
	 *
	 * @readonly
	 * @memberof Toast
	 */
	get labelControl() {
		return this.labelCtrl?.value;
	}

	/**
	 * The <rps-svg> control for this Toast
	 *
	 * @readonly
	 * @memberof Toast
	 */
	get svgControl() {
		return this.svgCtrl?.value;
	}

	/**
	 * The <rps-card> control for this Toast
	 *
	 * @readonly
	 * @memberof Toast
	 */
	get cardControl() {
		return this.cardCtrl?.value;
	}

	/**
	 * Fired when the opened attribute is set
	 * Either by code or as an attribute then start the timer to hide the toast
	 *
	 * @param {String} name The attribute name
	 * @param {*} old old value
	 * @param {*} value new value
	 * @memberof Toast
	 */
	attributeChangedCallback(name, old, value) {
		super.attributeChangedCallback(name, old, value);

		// Has the opened attribute been added to the attributes?
		// dont process the removal, only the addition
		if (name === "opened" && old === null) {
			console.debug('Toast:attributeChangedCallback opened attribute added', name, old, value);
			// when modal is displayed set the timeout
			setTimeout(() => {
				if (this.hasAttribute('opened')) {
					console.debug('Toast: Expire');
					this.hide(true);
				}
			}, this.expireSeconds * 1000);
		}
	}


	/**
	 * Force the toast to hide
	 *
	 * @param {Boolean} [expired = false] Fire the expired event?
	 * @memberof Toast
	 */
	hide(expired = false) {
		console.debug('Toast:hide');
		this.toggleAttribute('opened', false);

		// expired=true comes from the timeout event
		if (expired) {
			const detail = {
				source: this.tagName,
				id: this.id,
				name: this.name,
				labelControl: this.labelControl,
				svgControl: this.svgControl,
			};
			const e = new CustomEvent('expired', { bubbles: true, cancelable: true, composed: true, detail });
			this.dispatchEvent(e);

			if (this.cbExpire) this.cbExpire(e);

			this._repeatDisplay();
		}
	}

	/**
	 * Called when the toast expires and is hidden.
	 * @description repeat the display of the toast to the user until they click on the close button
	 * 
	 * @memberof Toast
	 */
	_repeatDisplay() {
		if (this.repeat && this.repeatSeconds > 0) {
			setTimeout(() => {
				this.show();
			}, this.repeatSeconds * 1000);
		}
	}

	/**
	 * Force the toast to display
	 *
	 * @memberof Toast
	 */
	show() {
		console.debug('Toast:show');
		this.toggleAttribute('opened', true);
	}

	/**
	 * User clicked on the close button
	 *
	 * @param {Event} e
	 * @memberof Toast
	 */
	close(e) {
		e.stopPropagation();

		console.debug('Toast:Close', e);
		this.toggleAttribute('opened', false);

		const detail = {
			source: this.tagName,
			id: this.id,
			name: this.name,
			labelControl: this.labelControl,
			svgControl: this.svgControl,
		};
		e = new CustomEvent('close', { bubbles: true, cancelable: true, composed: true, detail });
		this.dispatchEvent(e);

		if (this.cbClose) this.cbClose(e);
	}

	render() {
		return html`
			${this.addCss(true)}	
			<rps-card class="toast ${this.position} ${this.effect} ${this.repeat}" ${ref(this.cardCtrl)}
				?opened=${this.opened}>
				<div slot="header">
					<rps-input-label ${ref(this.labelCtrl)} 
						?required=${this.required} ?disabled=${this.disabled} 
						text=${this.text}
						class="${this.className} inline"
					></rps-input-label>
				</div>

				<div slot="right">
					<rps-svg ${ref(this.svgCtrl)} svg=${iconNames.del} @click=${this.close}></rps-svg>
				</div>
			</rps-card>
		`;
	}
}

