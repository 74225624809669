import { gql } from '@apollo/client';

export const CREATE_ODO_IMAGE = gql`
  mutation CreateProductImages($productId: ID!, $imageFiles: [ImageInput!]!) {
    createProductImages(productId: $productId, imageFiles: $imageFiles) {
      ... on Image {
        id
        position
        url
        imageTypes
        label
        excludeImageTypes
      }
      ... on ResponseMessage {
        status
        code
        message
      }
    }
  }
`;
