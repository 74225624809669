import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import EditorNav from '@odo/components/widgets/editor-nav/editor-nav';
import { useCurrentProduct } from '@odo/contexts/product-editor';
import type { Validator } from '@odo/screens/deal/editor/types';
import { validateProduct } from '@odo/screens/deal/editor/helpers';
import { buyerAndSupplierValidators } from '@odo/screens/deal/editor/buyer-and-supplier';
import { productValidators } from '@odo/screens/deal/editor/product';
import { conditionsAndCategoryValidators } from '@odo/screens/deal/editor/conditions-and-category';
import { priceAndCustomOptionsValidators } from '@odo/screens/deal/editor/price-and-custom-options';
import { shippingAndInventoryValidators } from '@odo/screens/deal/editor/shipping-and-inventory';
import { imagesAndVideosValidators } from '@odo/screens/deal/editor/images-and-videos';
import { summaryValidators } from '@odo/screens/deal/editor/summary';

const BASE_PATH = '/new/deals/editor';

const nav: { id: string; label: string; validators: Validator[] }[] = [
  {
    id: 'buyer-and-supplier',
    label: 'Buyer & Supplier',
    validators: buyerAndSupplierValidators,
  },
  { id: 'product', label: 'Product', validators: productValidators },
  {
    id: 'conditions-and-category',
    label: 'Conditions & Categories',
    validators: conditionsAndCategoryValidators,
  },
  {
    id: 'price-and-custom-options',
    label: 'Price & Custom Options',
    validators: priceAndCustomOptionsValidators,
  },
  {
    id: 'shipping-and-inventory',
    label: 'Shipping & Inventory',
    validators: shippingAndInventoryValidators,
  },
  {
    id: 'images-and-videos',
    label: 'Images & Videos',
    validators: imagesAndVideosValidators,
  },
  { id: 'summary', label: 'Summary', validators: summaryValidators },
];

const Nav = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentProduct = useCurrentProduct();

  const [active, setActive] = useState<string | undefined>();
  const [statuses, setStatuses] = useState({});

  const go = useCallback(
    (to: string) => {
      const navItem = nav.find(({ id }) => id === to);
      if (!navItem) return;

      // TODO: scroll to top?

      setActive(to);
      navigate(
        generatePath(`${BASE_PATH}/:dealId?/${navItem.id}`, {
          dealId: params?.dealId || null,
        })
      );
    },
    [navigate, params]
  );

  const navItems = useMemo(() => {
    return nav.map(n => ({
      ...n,
      isActive: active === n.id,
      status: statuses[n.id] || undefined,
      onClick: () => go(n.id),
      // these are placeholders for demonstration
      // TODO: remove these in favour of data driven version
      ...(['buyer-and-supplier', 'shipping-and-inventory'].includes(n.id) && {
        hasUnsavedChanges: true,
      }),
    }));
  }, [active, statuses, go]);

  /**
   * Update active item on location change.
   */
  useEffect(() => {
    const activeNavItem = nav.find(({ id }) => location.pathname.endsWith(id));
    if (activeNavItem) {
      setActive(activeNavItem.id);
    }
  }, [location]);

  /**
   * Re-run validators on product change.
   */
  useEffect(() => {
    setStatuses(statuses => {
      const nextStatuses = { ...statuses };
      nav.forEach(n => {
        nextStatuses[n.id] = validateProduct(
          currentProduct,
          n.validators
        ).status;
      });
      return nextStatuses;
    });
  }, [currentProduct]);

  return <EditorNav items={navItems} />;
};

export default Nav;
