import React, { useMemo } from 'react';
import { useCurrentDealSource } from 'hooks/useCurrentDealSource';
import { Deal } from 'models/Deal.jsx';
import {
  DealConditionsAndCategory,
  isCategoryShop,
} from 'models/ConditionsAndCategory.jsx';
import { RPSCard } from '@rps/web-components/build/react-wrappers';
import CategorySelector from '@odo/components/widgets/category-selector';
import { isCategory } from '@odo/types/guards.ts';
import { CategoryTypeEnum } from '@odo/types/api.ts';
import { splitArray } from '@odo/utils/array.ts';

const getId = cat => (isCategory(cat) ? cat.categoryId : cat.id);
const getName = cat =>
  isCategory(cat)
    ? cat?.breadcrumb?.breadcrumb || cat.categoryName
    : cat.breadcrumb;

export const CategorySection = () => {
  const currentDeal = useCurrentDealSource();
  const model = currentDeal.deal.conditionsAndCategory;

  const [shops, categories] = useMemo(() => {
    if (!model.categories) return [[], []];

    return splitArray(
      model.categories.sort((catA, catB) =>
        getName(catA).localeCompare(getName(catB))
      ),
      isCategoryShop
    );
  }, [model.categories]);

  const handleShopAdd = shop => {
    currentDeal.deal.set(
      Deal.MODELS.CONDITIONS_AND_CATEGORY,
      DealConditionsAndCategory.FIELDS.CATEGORIES,
      [
        ...model.categories.filter(
          cat => !shops.map(getId).includes(getId(cat))
        ),
        shop,
      ]
    );
    currentDeal.update();
  };

  const handleCategoryAdd = category => {
    currentDeal.deal.set(
      Deal.MODELS.CONDITIONS_AND_CATEGORY,
      DealConditionsAndCategory.FIELDS.CATEGORIES,
      [
        ...model.categories.filter(cat => getId(cat) !== getId(category)),
        category,
      ].sort((catA, catB) => getName(catA).localeCompare(getName(catB)))
    );
    currentDeal.update();
  };

  const handleCategoryRemove = category => {
    currentDeal.deal.set(
      Deal.MODELS.CONDITIONS_AND_CATEGORY,
      DealConditionsAndCategory.FIELDS.CATEGORIES,
      [...model.categories.filter(cat => getId(cat) !== getId(category))]
    );
    currentDeal.update();
  };

  return (
    <RPSCard>
      <div slot="header">
        <h5>Shop & Categories</h5>
      </div>

      <CategorySelector
        label="Shop"
        placeholder="Search for shop..."
        categoryType={CategoryTypeEnum.dailyShop}
        categories={shops}
        addCategory={handleShopAdd}
        removeCategory={handleCategoryRemove}
        filterResults={results =>
          [...results].sort((a, b) =>
            a.activeFromDate <= b.activeFromDate ? 1 : -1
          )
        }
        closeOnSelect
      />

      <CategorySelector
        label="Categories"
        placeholder="Search for categories..."
        categories={categories}
        addCategory={handleCategoryAdd}
        removeCategory={handleCategoryRemove}
        required
      />
    </RPSCard>
  );
};
