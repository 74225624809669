/*
	Not used on its own, but called by the layout-header component


ATTRIBUTES + PROPERTIES: 				[Changes trigger a re-render]
Casing usages:
	* Attribute in html (lower case), 
	* Property in JS (Camel Case)
========================
customCss: { type: String },			[Specify a custom .css file to include, use cssPath to override elements,
													DO NOT override the styling definition by re-defining the css styling
													Put this file into the *window.cssPath* folder @see {link README.md}
													eg: /public/css (where the other css files are)
													Suggested use: use sub-folders, dont put it all into the base
												]
@property {String}	css			| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.


METHODS:
clearExtraIcons()							[Remove all the extra icons from the toolbar]
addIcon()									[Add an Icon to the toolbar]

EVENTS:
input											[fires whenever input value is changed]

SLOTS:
*/
import { Profile } from './src/layout/Profile';
if (!window.customElements.get('rps-profile'))
	window.customElements.define('rps-profile', Profile);

