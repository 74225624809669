import type { Attribute, AttributeOption } from '@odo/contexts/attributes';
import { AttributeCode } from '@odo/types/api';
import type { GetProductInterface } from '@odo/types/api-new';
import type { EditorProductInterface } from '@odo/types/portal';

export type SupplierOptionType = AttributeOption & { id: string };

export const attributeToSupplierOption = (
  attribute: AttributeOption
): SupplierOptionType => ({ ...attribute, id: attribute.key });

export const getSupplierOptionLabel = (supplierOption: SupplierOptionType) => {
  const parts = [supplierOption.originalData.value, `ID: ${supplierOption.id}`];

  const tradingName = (supplierOption.metadata || []).find(
    ({ key }) => key === 'trading_name'
  );
  if (tradingName && tradingName.value) parts.push(tradingName.value);

  return parts.join(' | ');
};

/**
 * NOTE: biz seems to not want this data shown. But not sure why it's there.
 * Leaving this in here for now in case there was some confusion and we need it later.
 */
export const getSupplierOptionBuyer = (supplierOption: SupplierOptionType) =>
  supplierOption?.metadata?.find(meta => meta.key === 'buyer')?.value;

export const getSupplierFromOption = (
  supplierOption: SupplierOptionType
): EditorProductInterface['supplier'] => ({
  id: supplierOption.id,
  label: getSupplierOptionLabel(supplierOption),
});

/**
 * We would hope that there are no scenarios where a product has a surcharge that's not in the attributes.
 * But we can't be certain, so we'll also include any surcharges that are in the product, using a set to de-dupe them.
 */
export const getAllSurcharges = ({
  surcharges,
  attributes,
}: {
  surcharges?: GetProductInterface['surcharges'];
  attributes?: Attribute[];
}): EditorProductInterface['surcharges'] => {
  const surchargeTypeOptions =
    attributes?.find(attr => attr.id === AttributeCode.surcharges)?.options ||
    [];

  // the set guarantees uniqueness
  const ids = new Set<string>();

  // get all ids from attributes
  surchargeTypeOptions.forEach(({ value }) => ids.add(value));
  // get all ids from the product data
  surcharges?.forEach(({ key }) => ids.add(key));

  // convert the set to an array and then map over it to create each surcharge object
  return Array.from(ids).map(id => ({
    id,
    label: surchargeTypeOptions.find(option => option.value === id)?.label,
    value: !surcharges
      ? { string: '', number: 0 }
      : (() => {
          // self-executing anonymous function with a return statement
          // to get a variable for formatting without repeating work
          const val = surcharges.find(s => s.key === id)?.value;
          return {
            string: val || '',
            number:
              val === '' || typeof val === 'undefined'
                ? 0
                : val === null
                ? null
                : parseFloat(val),
          };
        })(),
  }));
};
