import React, { useState, useMemo, useEffect } from 'react';
import {
  RPSButtonStrip,
  RPSCard,
  RPSCheckbox,
  RPSChipList,
} from '@rps/web-components/build/react-wrappers';
import { useCurrentDealSource } from '../../../hooks/useCurrentDealSource';
import { Deal } from 'models/Deal.jsx';
import { DealProduct } from 'models/Product.jsx';
import { dateObjectToIso } from '@odo/utils/date';
import { useAttributeOptions } from '@odo/hooks/attributes';
import { AttributeCode } from '@odo/types/api';
import {
  Input,
  InputNumeric,
  Select,
} from '@odo/components/elements/form-fields';
import { handleODOInput } from 'utils/odo-migration/forms';
import { debounce } from '@odo/utils/debounce';
import { queryProductBySKU } from '@odo/graphql/product';
import { FaChevronRight as IconRight } from 'react-icons/fa';
import { Flex } from '@odo/components/elements/layout/flex';
import { Grid } from '@odo/components/elements/layout/grid';
import Switch from '@odo/components/elements/switch';
import styled from '@odo/lib/styled';

const DateTimeButton = styled(Flex).attrs({ as: 'button' })`
  background: white;
  border: 1px solid #c2c2c2;
  border-radius: 12px;
  height: 34px;
  cursor: pointer;
  font: var(--button-text-m);
  & span {
    white-space: nowrap;
  }
  &:hover {
    background: #f5f5f5;
  }
`;

DateTimeButton.defaultProps = {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 2,
  p: '5px 15px',
};

const TimeFieldWrapper = styled(Flex)`
  transition: all 0.25s ease-in-out;
  transform: translateY(-45px);
  opacity: 0;
  height: 0;

  &.active {
    transform: translateX(0);
    opacity: 1;
    margin-top: 16px;
    height: 35px;
  }
`;

const isSkuValid = async ({ sku, id, signal, callback }) => {
  if (!sku) return;
  try {
    const res = await queryProductBySKU({ sku, id, signal });
    callback(res);
  } catch (e) {
    callback(undefined);
  }
};

const debouncedIsSkuValid = debounce(isSkuValid, 750);

/**
 * Splits features into a list if it's a string.
 * @param {String|Array} features
 * @returns {String[]}
 */
const splitFeaturesIntoList = features => {
  if (features instanceof Array) return features;
  if (typeof features === 'string') {
    return features.split('|').map(feature => feature.trim());
  }

  return []; // No valid features given
};

export const ProductInformationSection = () => {
  const currentDeal = useCurrentDealSource();
  const handleInput = currentDeal.createInputHandler(Deal.MODELS.PRODUCT);

  const model = currentDeal.deal.product;

  const areaOptions = useAttributeOptions(AttributeCode.area);
  const taxClassOptions = useAttributeOptions(AttributeCode.taxClass);
  const conditionOptions = useAttributeOptions(AttributeCode.condition);
  const buyInStockTypeOptions = useAttributeOptions(
    AttributeCode.buyInStockType
  );

  const areaOptionsFiltered = useMemo(
    () => areaOptions.filter(a => a.key !== 'NONE'),
    [areaOptions]
  );

  const featureChipList = splitFeaturesIntoList(
    currentDeal.deal.product.features
  ).map((feature, index) => ({ id: `chip${index}`, text: feature }));

  const updateChips = detail => {
    const newFeatures = detail.chips.map(feature => feature.text);
    currentDeal.deal.set(
      Deal.MODELS.PRODUCT,
      DealProduct.FIELDS.FEATURES,
      newFeatures
    );
    currentDeal.update();
  };

  const activeFromDate = dateObjectToIso(new Date(model.activeFromDate), false);
  const activeToDate = dateObjectToIso(new Date(model.activeToDate), false);

  const equalizeDates = () => {
    currentDeal.deal.set(
      Deal.MODELS.PRODUCT,
      DealProduct.FIELDS.ACTIVE_TO_DATE,
      model.activeFromDate
    );
    currentDeal.update();
  };

  const toggleTimedDeal = () => {
    currentDeal.deal.set(
      Deal.MODELS.PRODUCT,
      DealProduct.FIELDS.IS_TIMED_DEAL,
      !model.isTimedDeal
    );
    currentDeal.update();
  };

  const handleNormalInput = field => e => {
    currentDeal.deal.set(Deal.MODELS.PRODUCT, field, e.target.value);
    currentDeal.update();
  };

  const handleSKUInput = ev => {
    const skuRegex = /^[a-z0-9\-\|\/\+\&\.]*$/i;
    const skuRegexExclude = /[^A-Z0-9\-\|\/\+\&\.]/gi;
    const match = ev.target.value.match(skuRegex);
    const replace = ev.target.value.replace(skuRegexExclude, '');
    if (replace || match) {
      currentDeal.deal.set(
        Deal.MODELS.PRODUCT,
        DealProduct.FIELDS.SKU,
        replace || match[0]
      );
      currentDeal.update();
    }
  };

  const [validatingSKU, setValidatingSKU] = useState(false);
  const [skuValidity, setSkuValidity] = useState();

  useEffect(() => {
    if (model.sku) {
      const controller = new AbortController();

      let isActive = true;
      setValidatingSKU(true);

      controller.signal.addEventListener('abort', () => (isActive = false));

      debouncedIsSkuValid({
        sku: model.sku,
        id: currentDeal.deal.meta.id,
        signal: controller.signal,
        callback: inUse => {
          if (isActive) {
            setSkuValidity(inUse);
            setValidatingSKU(false);
          }
        },
      });

      return () => controller.abort();
    }
  }, [model.sku, currentDeal.deal.meta.id]);

  return (
    <RPSCard>
      <div slot="header">
        <h5>Product Information</h5>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          gap: '2rem',
          width: 'fit-content',
        }}
      >
        <RPSCheckbox
          label="Deal Enabled"
          checked={model.status}
          cbInput={handleInput(DealProduct.FIELDS.ENABLED)}
        />
        <RPSCheckbox
          label="Preview Only"
          checked={model.isPreviewOnly}
          cbInput={handleInput(DealProduct.FIELDS.IS_PREVIEW_ONLY)}
        />
      </div>
      <Input
        label="Brand"
        value={model.brand}
        onChange={handleODOInput({
          currentDeal,
          model: Deal.MODELS.PRODUCT,
          field: DealProduct.FIELDS.BRAND,
        })}
        required
        selectOnFocus
        matchRPStyles
      />

      <Input
        label="Name"
        value={model.name}
        onChange={handleODOInput({
          currentDeal,
          model: Deal.MODELS.PRODUCT,
          field: DealProduct.FIELDS.NAME,
        })}
        minLength={3}
        required
        selectOnFocus
        matchRPStyles
      />

      <Input
        label="Short Name"
        value={model.shortName}
        onChange={handleODOInput({
          currentDeal,
          model: Deal.MODELS.PRODUCT,
          field: DealProduct.FIELDS.SHORT_NAME,
        })}
        minLength={3}
        required
        selectOnFocus
        matchRPStyles
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '7px',
          marginBottom: '-5px',
        }}
      >
        <Input
          label="SKU"
          value={model.sku}
          onChange={handleSKUInput}
          error={skuValidity === false ? true : undefined}
          minLength={2}
          required
          selectOnFocus
          matchRPStyles
        />

        <div style={{ height: '24px', overflow: 'hidden' }}>
          {validatingSKU ? (
            <rps-spinner-pause />
          ) : (
            <>
              {!model.sku && (
                <rps-input-label class="error" text="SKU is required." />
              )}

              {skuValidity === false && (
                <rps-input-label
                  class="error"
                  text="SKU already in use, please select another."
                />
              )}

              {skuValidity === true && (
                <rps-input-label class="success" text="SKU is available." />
              )}

              {typeof skuValidity === 'undefined' && (
                <rps-input-label
                  class="warning"
                  text="Could not validate SKU."
                />
              )}
            </>
          )}
        </div>
      </div>

      <Input
        label="URL Key"
        value={model.url}
        onChange={handleODOInput({
          currentDeal,
          model: Deal.MODELS.PRODUCT,
          field: DealProduct.FIELDS.URL,
        })}
        required
        selectOnFocus
        matchRPStyles
      />

      {/* active from - to */}
      <Grid
        gridTemplateColumns="auto auto auto auto"
        justifyContent="flex-start"
        alignItems="flex-end"
        gap="0 16px"
      >
        <Flex style={{ zIndex: 1, background: 'white' }}>
          <InputNumeric
            required
            type="date"
            max="9999-12-31"
            label="Active From"
            placeholder={activeFromDate}
            value={activeFromDate}
            onChange={handleNormalInput(DealProduct.FIELDS.ACTIVE_FROM_DATE)}
            style={{ padding: '7px 5px', textIndent: '2px' }}
            matchRPStyles
          />
        </Flex>

        <DateTimeButton onClick={equalizeDates}>
          <span>MAKE SAME</span>
          <IconRight />
        </DateTimeButton>

        <Flex style={{ zIndex: 1, background: 'white' }}>
          <InputNumeric
            required
            type="date"
            max="9999-12-31"
            label="Active To"
            placeholder={activeToDate}
            value={activeToDate}
            onChange={handleNormalInput(DealProduct.FIELDS.ACTIVE_TO_DATE)}
            style={{ padding: '7px 5px', textIndent: '2px' }}
            matchRPStyles
          />
        </Flex>

        <DateTimeButton onClick={toggleTimedDeal}>
          <span>DEAL TIME</span>
          <Switch
            checked={model.isTimedDeal}
            onClick={toggleTimedDeal}
            activeColor="#29b5ef"
            width={2.5}
          />
        </DateTimeButton>

        <TimeFieldWrapper
          flexDirection="column"
          className={model.isTimedDeal ? 'active' : undefined}
        >
          <InputNumeric
            type="time"
            min="00:00"
            max="23:59"
            defaultValue="00:00"
            value={model.activeFromTime}
            onChange={handleNormalInput(DealProduct.FIELDS.ACTIVE_FROM_TIME)}
            style={{ padding: '6px 8px', textIndent: 'unset', width: 'auto' }}
            matchRPStyles
          />
        </TimeFieldWrapper>

        {/* empty column */}
        <div />

        <TimeFieldWrapper
          flexDirection="column"
          className={model.isTimedDeal ? 'active' : undefined}
        >
          <InputNumeric
            type="time"
            min="00:00"
            max="23:59"
            defaultValue="23:59"
            value={model.activeToTime}
            onChange={handleNormalInput(DealProduct.FIELDS.ACTIVE_TO_TIME)}
            style={{ padding: '6px 8px', textIndent: 'unset', width: 'auto' }}
            matchRPStyles
          />
        </TimeFieldWrapper>
      </Grid>

      <Select
        label="Condition"
        value={model.condition}
        onChange={handleODOInput({
          currentDeal,
          model: Deal.MODELS.PRODUCT,
          field: DealProduct.FIELDS.CONDITION,
        })}
        options={conditionOptions.map(option => ({
          id: option.value,
          value: option.value,
          label: option.label,
        }))}
        matchRPStyles
      />

      <RPSChipList
        label="Feature-Tags"
        placeholder="Enter a feature name and press Enter to add it to the list."
        chips={featureChipList}
        cbChange={updateChips}
      />
      <RPSButtonStrip
        text="Tax Class"
        cbClick={handleInput(DealProduct.FIELDS.TAXABLE_CLASS)}
        buttons={taxClassOptions
          .filter(
            option => !['PLEASE_SELECT', 'SHIPPING'].includes(option.value)
          )
          .map(option => ({
            id: option.value,
            text: option.label,
          }))}
        selected={model.taxClass?.id || model.taxClass}
      />
      <RPSButtonStrip
        text="Areas"
        cbClick={handleInput(DealProduct.FIELDS.AREA)}
        selected={model.area}
        buttons={areaOptionsFiltered.map(option => ({
          id: option.value,
          text: option.label,
        }))}
        required
      />
      <RPSButtonStrip
        text="Buy-in Stock Type"
        cbClick={handleInput(DealProduct.FIELDS.BUY_IN_STOCK_TYPE)}
        selected={model.buyInStockType || 'NONE'}
        buttons={buyInStockTypeOptions.map(option => ({
          id: option.value,
          text: option.label,
        }))}
      />
    </RPSCard>
  );
};
