export const hasCustomOptionsButtons = [
  { id: 'no', text: 'No' },
  { id: 'yes', text: 'yes' },
];

// TODO: BP-309 remove usage of this array and then delete it.
export const AdminFeeOptions = [
  { id: 'NONE', text: 'None' },
  { id: 'ADMINCOST_0_00', text: 'R0.00' },
  { id: 'ADMINCOST_10_00', text: 'R10.00' },
  { id: 'ADMINCOST_17_50', text: 'R17.50' },
  { id: 'ADMINCOST_34_00', text: 'R34.00' },
];
