export class Validator {
  constructor(validators) {
    this.validators = validators;
  }

  static get silent() {
    return Validator._silent;
  }
  static set silent(v) {
    Validator._silent = v;
  }

  _errorMessage(validatorKey, key, errorMessage) {
    let msg = `Validation '${validatorKey}:${key}' failed. ${
      errorMessage ? `Error Message: ${errorMessage}` : ''
    }`;
    if (!Validator.silent) console.debug(msg);
    return msg;
  }

  runValidators(state) {
    let status = '';
    let errors = {};
    let hasErrors = false;

    if (!Validator.silent) console.debug('Running validators on:', state);

    for (const validatorKey in this.validators) {
      const validator = this.validators[validatorKey];
      let validatorIsValid = true;
      if (errors[validatorKey] === undefined) errors[validatorKey] = [];

      for (const key in validator) {
        if (typeof validator[key].validator === 'function') {
          try {
            const validatorStatus = validator[key].validator(state);

            if (!validatorStatus) {
              hasErrors = true;
              validatorIsValid = false;
              if (typeof validator[key].error === 'function') {
                errors[validatorKey].push(
                  this._errorMessage(
                    validatorKey,
                    key,
                    validator[key].error(state)
                  )
                );
              } else if (typeof validator[key].error !== 'undefined') {
                errors[validatorKey].push(
                  this._errorMessage(validatorKey, key, validator[key].error)
                );
              } else {
                errors[validatorKey].push(
                  this._errorMessage(validatorKey, key)
                );
              }
            }
          } catch (err) {
            hasErrors = true;
            errors[validatorKey].push(
              this._errorMessage(
                validatorKey,
                key,
                `Failed with Exception: ${err}`
              )
            );
          }
        } else {
          hasErrors = true;
          console.warn(
            `Validator:_runValidators: Expected function for validator ${validatorKey} and field ${key}`
          );
          if (!Validator.silent) validatorIsValid = false;
          break;
        }
      }

      if (validatorIsValid && !hasErrors) {
        status = validatorKey;
        if (!Validator.silent)
          console.debug('Validation status changed to', status);
      }
    }

    return { status, errors, isValid: !!status };
  }
}

Validator.silent = true;
