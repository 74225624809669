/*
This class allows for input controls inside the grid of  <rps-grid-input>
*/
import { html } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { Grid } from './Grid';
import '../../rps-plus-minus';
import '../../rps-checkbox';
import '../../rps-input';
import '../../rps-dropdown';
import { DOM } from '../functions/DOM';

export class GridInput extends Grid {
	/**
	 *	NB: With input controls this is needed to "refresh the page properly"
	 * when using the input controls the display on different pages does not "update"
	 * but displays the old input control for that row.
	 *
	 * @param {*} changedProperties
	 * @memberof GridInput
	 */
	updated(changedProperties) {
		let inputs = this.renderRoot.querySelectorAll('rps-input');
		inputs.forEach(a => {
			// For some reason the input controls dont "display properly"
			// manually compare and set the value if not correct
			// exclude the filter input control
			if (a.value !== undefined
				&& a.name !== 'filter' && a.label !== 'filter'
				&& a.defaultValue !== a.value) {
				a.value = a.defaultValue;
			}
		});

		inputs = this.renderRoot.querySelectorAll('rps-dropdown');
		inputs.forEach(a => {
			// For some reason the input controls dont "display properly"
			// manually compare and set the value if not correct
			if (a.defaultValue !== a.value) {
				a.value = a.defaultValue;
			}
		});

		inputs = this.renderRoot.querySelectorAll('rps-plus-minus');
		inputs.forEach(a => {
			// For some reason the input controls dont "display properly"
			// manually compare and set the value if not correct
			if (a.defaultValue !== a.value) {
				a.value = a.defaultValue;
			}
		});

		inputs = this.renderRoot.querySelectorAll('rps-checkbox');
		const data = this.data;
		inputs.forEach(a => {
			const rowId = a.getAttribute('rowid');
			const row = data.find(e => e.id == rowId);
			const columnKey = a.getAttribute('columnkey');

			const dataValue = row[columnKey];
			//console.log('columnkey',dataValue);

			if (a.inputControl && dataValue !== a.inputControl.checked) {
				a.inputControl.checked = dataValue;
			}
		});
	}

	/**
	* Create a "+-" control
	*
	* @param {Object} data - data to display in the control
	* @param {String} textStyle - custom formatting needed for this data type, eg: right align
	* @param {Number} columnNumber - The column number in the grid
	* @param {String} rowId - the Id for this row
	* @returns
	* @memberof GridInput
	*/
	_plusMinusControl(data, columnNumber, rowId, control, column, textStyle) {
		data = Math.round(data, 0);
		return html`
			<span class="cell ${textStyle}">
				<rps-plus-minus class="small plus-minus" hidelabel
					defaultValue=${data}
					data=${data} columnnumber=${columnNumber} rowid=${rowId} columnkey=${column.key}
					min=${ifDefined(column.min)}
					max=${ifDefined(column.max)}
					@input=${(event) => this._rowButtonClick(event, control.cbInput, 'RPS-PLUS-MINUS')}
				>
				</rps-plus-minus>
			</span>
			`;
	}


	_checkboxControl(data, columnNumber, rowId, control, column, classes = '') {
		const label = control.label ? control.label : "";
		const customCss = control.customCss ? control.customCss : "";
		const row = this.data.find(e => e.id == rowId);
		const checked = row[column.key] ? row[column.key] : control.checked;

		return html`
		<span class="cell">
			<rps-checkbox type="checkbox" class="small ${classes}" name="${column.key}"
				?required=${control.required} ?checked=${checked} ?disabled=${control.disabled}
				rowid=${rowId} columnnumber=${columnNumber} columnkey=${column.key}
				@input=${(event, cbInput) => this._rowButtonClick(event, control.cbInput, 'RPS-CHECKBOX')}
				customcss="${customCss}" label="${label}"
			>
			</rps-checkbox>
		</span>
		`;
	}

	_dropdownControl(data, columnNumber, rowId, control, column, classes = '')  {
		const row = this.data.find(e => e.id == rowId);
		// did not use "??" as not all JS bundlers use es6 or later
		// use all possible attributes for the rps-dropdown
		if (row === undefined) console.error('Grid:_dropdownControl:rowId', rowId, 'not found in data', this.data);

		const displayText = row[column.key];

		return html`
			<span class="cell">
				<rps-dropdown name="${column.key}" defaultvalue="${displayText}"
					@input=${(event) => this._rowButtonClick(event, control.cbInput, 'RPS-DROPDOWN')}
					.items=${control.items}
					?required=${control.required} ?readonly=${control.readonly}
					class="small ${classes}" rowid=${rowId} columnnumber=${columnNumber}
					css="${ifDefined(control.css)}" columnkey=${column.key} customcss="${ifDefined(control.customCss)}"
					errormessage="${ifDefined(control.errorMessage)}"
					label="${ifDefined(control.label)}" placeholder="${ifDefined(control.placeHolder)}"
				>
				</rps-dropdown>
			</span>`;

	}


	/**
	 * Add rps-input controls to grid
	 *
	 * @param {Array} data
	 * @param {Number} columnNumber
	 * @param {String} rowId
	 * @param {Object} control
	 * @param {Object} column
	 * @returns
	 * @memberof Grid
	 */
	_inputControl(data, columnNumber, rowId, control, column, classes = '') {
		const row = this.data.find(e => e.id == rowId);
		// did not use "??" as not all JS bundlers use es6 or later
		// use all possible attributes for the rps-input
		const type = column.data.type ? column.data.type : 'text';		// 'number' or 'text', use text if not specified

		if (row === undefined) console.error('Grid:_inputControl:rowId', rowId, 'not found in data', this.data);
		const displayText = typeof control.transform === 'function' ? control.transform(row[column.key], row, this.data) : data;
		// control.cbInput is an optional cbInput handler for this control


		return html`
			<span class="cell">
				<rps-input name="${rowId}" defaultvalue="${displayText}"
					?required=${control.required} ?readonly=${control.readonly}
					class="small ${classes}" rowid=${rowId} columnnumber=${columnNumber}
					@input=${(event) => this._rowButtonClick(event, control.cbInput, 'RPS-INPUT')}
					css="${ifDefined(control.css)}" columnkey=${column.key} customcss="${ifDefined(control.customCss)}"
					type="${type}" errormessage="${ifDefined(control.errorMessage)}"
					label="${ifDefined(control.label)}" placeholder="${ifDefined(control.placeHolder)}"
					min=${ifDefined(control.min)} max=${ifDefined(control.max)}
					pattern="${ifDefined(control.pattern)}"
					minLength=${ifDefined(control.minLength)} maxLength=${ifDefined(control.maxLength)}
					id="${rowId}"
				>
				</rps-input>
			</span>`;

	}

	/**
	 * Button with Image
	 *
	 * @param {Object} data - data for this cell
	 * @param {Number} columnNumber - column number
	 * @param {String} rowId - the rows ID
	 * @param {Object} control - the control definition for this button
	 * @returns
	 * @memberof Grid
	 */
	_imageButtonControl(data, columnNumber, rowId, control, classes = '') {
		const alt = control.alt ? control.alt : '';

		return html`
		<span class="cell">
			<rps-button-icon svg=${control.image} rowId=${rowId} columnNumber=${columnNumber} alt="${alt}"
				class="small ${classes}"
				@click=${(event) => this._rowButtonClick(event, control.cbClick, 'RPS-BUTTON-ICON')}>
	 		</rps-button-icon>
		</span>`;
	}


	/**
	 * Fires when a button in the grid is clicked
	 * This fires a 'click' event
	 * @param {Object} event
	 * @memberof Grid
	 */
	_rowButtonClick(event, cbInput, tagname) {
		event.stopPropagation();
		event.preventDefault();

		console.debug('grid:_rowButtonClick', event);

		let rpsControl = event.target;

		// try find the parent element (IF it is a rps-input, rps-checkbox or rps-dropdown)
		if (rpsControl.tagName !== "SPAN" && rpsControl.tagName !== "I") {
			rpsControl = new DOM().walkUp(event.target, tagname);
		}

		const rowId = rpsControl.getAttribute('rowid');
		const columnNumber = rpsControl.getAttribute('columnnumber') * 1;
		const columnKey = rpsControl.getAttribute('columnkey');
		const row = this.data.find(e => e.id == rowId);
		if (!row) {
			console.error(`Grid:_rowButtonClick Cannot find the Row with Id: ${rowId}`);
			return;
		}

		const detail = {
			rowId,
			row,
			columnNumber,
			type: "row-button-click",
			source: this.tagName,
			target: rpsControl		// Allow access to the original element
		};

		if (rpsControl.tagName === "RPS-CHECKBOX") {
			row[columnKey] = event.target.inputControl.checked;
		} else {
			row[columnKey] = rpsControl.value;
		}

		if (cbInput) {				// Custom handler passes in columns
			cbInput(detail);
		} else {					// default event
			event = new CustomEvent('click', { detail, bubbles: true, cancelable: true, composed: true })
			this.dispatchEvent(event);
		}

		this.requestUpdate();
	}


	body() {
		//console.debug('gridInput:renderBody');
		const start = this.pageSize * (this.currentPage - 1);				// start of data to display on this page
		const stop = start + this.pageSize										// last item to display on this page
		const pageData = this.data2Display.slice(start, stop);			// extract only this pages data

		return html`${pageData.map(row => {
			return this.getColumns().map((col, index) => {
				const { data, textStyle } = this._formatData(row[col.key], col, row);

				if (col.data && col.data.control) {
					if (col.data.control.plusMinus) {
						return this._plusMinusControl(data, index, row.id, col.data.control.plusMinus, col, textStyle);
					} else if (col.data.control.deleteButton) {
						return this._deleteControl(data, row.id, col.data.control.deleteButton, col.data.control.deleteButton.deleteButton);
					} else if (col.data.control.imageButton) {
						return this._imageButtonControl(data, index, row.id, col.data.control.imageButton, col.data.control.imageButton.classes);
					} else if (col.data.control.textButton) {
						return this._textButtonControl(data, index, row.id, col.data.control.textButton, col.data.control.textButton.classes);
					} else if (col.data.control.customControl) {
						return this._customControl(data, index, row.id, col.data.control.customControl, col.data.control.customControl.classes);
					} else if (col.data.control.checkbox) {
						return this._checkboxControl(data, index, row.id, col.data.control.checkbox, col, col.data.control.checkbox.classes);
					} else if (col.data.control.input) {
						return this._inputControl(data, index, row.id, col.data.control.input, col, col.data.control.input.classes);
					} else if (col.data.control.dropdown) {
						return this._dropdownControl(data, index, row.id, col.data.control.dropdown, col, col.data.control.dropdown.classes);
					}
				}

				return html`<span class="cell ${textStyle}">${data}</span>`;
			}, this);
		})}`;
	}


}
