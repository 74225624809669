/**
 * @name rps-text-editor
 * @type {TextEditor}
 * A fully featured Rich Text Editor control: Outputs plain HTML.
 *
 * @attributes + @properties
 *  None
 *
 * @methods
 *  getValue()                  | Returns raw HTML from the current editor state
 *  setValue(value)             | Sets innerHTML of the editor
 *
 * @events
 *  input                       | Fired when changes occur in the editor
 *
 * @callbacks
 *  cbInput                     | Callback triggers when input event fires
 *
 * @example @lit
 *  @see /demo/editor/textEditor.html for use-case
 *  <rps-text-editor @input=${(ev) => { console.log(ev.detail.value) }}>
 *      <template>
 *          <h1>Pre-populated HTML</h1>
 *          <p>This gives your text editor an initial value</p>
 *      </template>
 *  </rps-text-editor>
 *
 * @example @ReactJS
 * <RPSTextEditor cbInput={(ev) => { console.log(ev.detail.value) }}>
 *  <template>
 *      Initial pre-populated HTML. Can also be left empty,
 *      and the template tags can be omitted entirely.
 *  </template>
 * </RPSTextEditor>
 */

import { TextEditor } from "./src/editor/TextEditor.js";
if (!customElements.get('rps-text-editor')) {
    customElements.define('rps-text-editor', TextEditor);
}