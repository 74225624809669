/**
 * @name rps-list-container
 * @type {ListContainer}
 * A container for items.
 * They can be rendered in a row of column layout
 * 
 * @attributes + @properties 					| Changes trigger a re-render
 * Casing usages:									| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String} 	customCss		| Specify a custom .css file to include, use cssPath to override elements,
 * 														DO NOT override the styling definition by re-defining the css styling
 * 														Put this file into the *window.cssPath* folder @see {link README.md}
 * 														eg: /public/css (where the other css files are)
 * 														Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css				| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 												
 * 
 * @Styling (Default: primary, large, solid)
 * ========================================
 * @Sizes
 * 	small:		Small button
 * 	medium:		Medium size button
 * 	large:		Large size button
 * @Orientation allow slots to display top,bottom OR left,right
 * 	vertical:	Display slotted items in a vertical layout
 * 	horizontal:	Display slotted items in a horizontal layout
 * 
 * 
 * 
 * @methods
 * 	itemControl(id)					| Retrieve an item in the container list by id
 * 
 * @events
 * 	None
 * 
 * @slots
 * 	Default slot
 * 
 * @example+ @Lit
 * @basic
 * =====
 * import '@rps-wc/rps-list-container';
 * import '@rps-wc/rps-tile-item';
 * 
 * 	<rps-list-container></rps-list-container>
 * 
 * @advanced , use slot
 * ========
 * import '@rps-wc/rps-list-container';
 * import '@rps-wc/rps-tile-item';
 * import { iconNames } from '@rps-wc/svg-icons';
 * 
 * 	<rps-list-container class="secondary vertical">
 * 		<rps-tile-item @click=${e => console.log(e)}>
 * 			<rps-svg svg="${iconNames.dealUpload}"></rps-svg>
 * 			Upload Deal<rps-svg svg="${iconNames.angleRight}"></rps-svg>
 * 		</rps-tile-item>
 * 		<rps-tile-item @click=${e => console.log(e)}>
 * 			<rps-svg svg="${iconNames.dealUpload}"></rps-svg>
 * 			Upload Deal<rps-svg svg="${iconNames.angleRight}"></rps-svg>
 * 		</rps-tile-item>
 * 	</rps-list-container>
 * 
 * 
 * @example+ @React
 * @basic
 * =====
 * import '@rps-wc/rps-list-container';
 * import '@rps-wc/rps-tile-item';
 * 
 * 	<rps-list-container></rps-list-container>
 * 
 * @advanced , use slot
 * ========
 * import '@rps-wc/rps-list-container';
 * import '@rps-wc/rps-tile-item';
 * import { iconNames } from '@rps-wc/svg-icons';
 * 
 * 	<rps-list-container class="secondary vertical">
 * 		<rps-tile-item cbClick=${e => console.log(e)}>
 *				<rps-svg svg="{iconNames.dealUpload}"></rps-svg>
 * 			Upload Deal<rps-svg svg="{iconNames.angleRight}"></rps-svg>
 * 		</rps-tile-item>
 * 		<rps-tile-item cbClick=${e => console.log(e)}>
 *				<rps-svg svg="{iconNames.dealUpload}"></rps-svg>
 * 			Upload Deal<rps-svg svg="{iconNames.angleRight}"></rps-svg>
 * 		</rps-tile-item>
 * 	</rps-list-container>
 * 
*/
import { ListContainer } from './src/lists/ListContainer';
if (!window.customElements.get('rps-list-container'))
	window.customElements.define('rps-list-container', ListContainer);

