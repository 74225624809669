import { gql } from '@apollo/client';

export const REMOVE_PRODUCT_CATEGORIES = gql`
  mutation RemoveProductCategories($productId: ID!, $categories: [ID!]!) {
    removeProductCategory(productId: $productId, categories: $categories) {
      status
      code
      message
    }
  }
`;
