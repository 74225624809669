export const excludedBuyers = [
  'BRADLEY',
  'CHRISTOPHER',
  'DANIEL',
  'DANIELLE',
  'ELIZABETH',
  'JAMES_HUGHES',
  'JAMES_C',
  'JANA',
  'JULIA',
  'MICHAELA',
  'EMMA',
  'JACQUES',
  'TAREK',
  'MEGAN_P',
  'SARAH_B',
  'LIEZE',
  'LAUREN',
  'KIRSTIN',
  'KIRSTEN',
  'RILEE',
  'MARAIS',
  'WAREHOUSE',
  'PIETRIE_COMMS',
  'SASHA',
  'JULENE',
  'ELZAAN',
  'LIENTJIE',
];

export const removedItems = ['NONE', 'LOW_MARGIN_DEAL', 'LUNCHTIME_DEAL'];
