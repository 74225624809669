import { html, css } from 'lit';
import { createRef, ref } from 'lit/directives/ref.js';
import { BaseInputControl } from '../baseClasses/BaseInputControl';
import '../../rps-form';
import '../../rps-input';
import '../../rps-card';

export class ForgotPassword extends BaseInputControl {

	static styles = css`
		:host {
		  display: block;
		}
	 `;

	static get properties() {
		return {
			userNameLabel: { type: String },
			userNamePattern: { type: String },
			userNamePlaceholder: { type: String },
			userNameErrorMessage: { type: String },
			userNameType: { type: String },
			confirmButtonText: { type: String },
			cbSubmit: { attribute: false },
			cbCancel: { attribute: false },
		};
	}

	constructor() {
		super();
		this.userNameLabel = "Email";
		this.userNamePattern = '.{5,}'; 				//'[A-Za-z]{3,}';		// at least 3 long characters
		this.userNamePlaceholder = "Enter your email";
		this.userNameErrorMessage = 'Usernames must be a valid email';
		this.userNameType = 'email';
		this.confirmButtonText = "Reset Password";

		this.input = createRef();
	}

	/**
	 * return a reference to the input control
	 *
	 * @readonly
	 * @memberof ForgotPassword
	 */
	get inputControl() {
		return this.input.value;
	}


	/**
	 * Fires when the user clicks on the "forgot password" link
	 *
	 * @param {Event} e
	 * @memberof ForgotPassword
	 */
	reset(e) {
		e.stopPropagation();
		console.debug('ForgotPassword:sumbit', 'reset password clicked');

		const detail = {
			value: this.inputControl.value,
			source: this.tagName,
			id: this.id,
			name: this.name,
			inputControl: this.inputControl,
		};

		e = new CustomEvent('submit', { detail, bubbles: true, composed: true, cancelable: true });
		this.dispatchEvent(e);

		if (this.cbSubmit) this.cbSubmit(e);
	}

	/**
	 * Fires when the user clicks on the "forgot password" link
	 *
	 * @param {Event} e
	 * @memberof ForgotPassword
	 */
	cancel(e) {
		e.stopPropagation();
		console.debug('ForgotPassword:cancel', 'cancel clicked');

		const detail = {
			value: this.inputControl.value,
			source: this.tagName,
			id: this.id,
			name: this.name,
			inputControl: this.inputControl,
		};

		e = new CustomEvent('cancel', { detail, bubbles: true, composed: true, cancelable: true });
		this.dispatchEvent(e);

		if (this.cbCancel) this.cbCancel(e);
	}


	render() {
		return html`
		${this.addCss(true)}
	
		<rps-card>
			<div slot="header"><h2>Forgot Password</h2></div>
			<rps-form 
				@submit=${this.reset} 
				@cancel=${this.cancel} 
				confirmText="${this.confirmButtonText}" 
			>
				<rps-input 
					name="userName" 
					label="${this.userNameLabel}" 
					required
					placeholder="${this.userNamePlaceholder}"
					errormessage="${this.userNameErrorMessage}"
					pattern="${this.userNamePattern}"
					type="${this.userNameType}"
					required
					caching
					${ref(this.input)}
				>
				</rps-input>
			</rps-form>
		</rps-card>
		`;
	}
}

