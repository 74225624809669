/**
 * @name rps-input-label
 * @type {InputLabel}
 * 	A label control, typically used inside input controls
 *
 *	@attributes + @properties
 * 	@property {String}	text							| The text to display in the label
 * 	@property {Boolean}	required	= false			| Indicate if this is required
 * 	@property {Boolean}	disabled	= false			| Is the control disabled
 * 	@property {String}	customCss					| Specify a custom .css file to include, use cssPath to override elements,
 * 																		DO NOT override the styling definition by re-defining the css styling
 * 																		Put this file into the *window.cssPath* folder @see {link README.md}
 * 																		eg: /public/css (where the other css files are)
 * 																		Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css							| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 *		@property {Boolean} 	disabled = false			| Is this control disabled
 * 	@property {Boolean}	hideStatusIcon				| Hide the status icon?
 *
 * @events
 * 	No public events, but a @click event can be added as per usual HTML standards if required.
 *
 * @callbacks
 * 	No callbacks
 *
 * @methods
 * 	No public methods
 * 																	| className = the className to add or remove
 *
 *	@slots
 * 	Default														| Override the "this.text" with your own custom HTML
 * 
 * @Styling
 * 	inline:		Larger label used next to Radio Buttons, Checkboxes
 * 
 * Status:		
 * 	help: 		Displays the background color in the "help color"
 * 	info:			Displays the background color in the "info color"
 * 	success:		Displays the background color in the "success color"
 * 	warning:		Displays the background color in the "warning color"
 * 	error:		Displays the background color in the "error color"
 * 
 * 
 * @example @Lit
 * @basic
 * =====
 * import '@rps-wc/rps-input-label';
 * 
 * <rps-input-label text="My Label"></rps-input-label>
 * <rps-input-label class="inline warning" required text="Warning message"></rps-input-label>
 * 
 * 
 */
import { InputLabel } from './src/input/InputLabel.js';
if (!window.customElements.get('rps-input-label'))
	window.customElements.define('rps-input-label', InputLabel);
