import { Checkbox, Input, Select } from '@odo/components/elements/form-fields';
import { Grid } from '@odo/components/elements/layout';
import ErrorBoundary from '@odo/components/widgets/error-boundary';
import {
  useChangeProduct,
  useCurrentProduct,
} from '@odo/contexts/product-editor';
import { useAttributeOptions } from '@odo/hooks/attributes';
import { shopAndCategoriesSectionValidators } from '@odo/screens/deal/editor/conditions-and-category/validators';
import SectionWrapper from '@odo/screens/deal/editor/elements/section-wrapper';
import { Overscroll } from '@odo/screens/deal/editor/elements/styles';
import { validateProduct } from '@odo/screens/deal/editor/helpers';
import { AttributeCode, CategoryTypeEnum } from '@odo/types/api';
import CategorySelector from '@odo/screens/deal/editor/conditions-and-category/widgets/category-selector';
import { getCategoryLabel } from '@odo/screens/deal/editor/conditions-and-category/helpers';
import { useProduct } from '@odo/contexts/product-new';
import { SHOW_UNNECESSARY_FIELDS_WHEN_DIFFERENT } from '@odo/screens/deal/editor/constants';

const alcoholWarningSlug = 'Not for persons under the age of 18';
const parallelImportSlug = 'Parallel Import';

const SpecialConditionsSection = () => {
  const originalProduct = useProduct();
  const currentProduct = useCurrentProduct();
  const change = useChangeProduct();

  const warrantyPeriodOptions = useAttributeOptions(
    AttributeCode.warrantyPeriod
  );
  const conditionOptions = useAttributeOptions(AttributeCode.condition);

  const setAdditionalInfo = ({
    parallel,
    alcohol,
  }: {
    parallel: boolean;
    alcohol: boolean;
  }) => {
    const nextAdditionalInfo = parallel
      ? parallelImportSlug
      : alcohol
      ? alcoholWarningSlug
      : '';

    if (currentProduct.additionalInfo === nextAdditionalInfo) return;

    change({
      fieldId: 'additionalInfo.auto',
      label: 'Additional Info (Auto)',
      apply: to => (to.additionalInfo = nextAdditionalInfo),
    });
  };

  return (
    <SectionWrapper title="Special Conditions">
      <Grid gap="24px" gridTemplateColumns={['1fr', '1fr 1fr']}>
        <Grid gap="24px" gridTemplateColumns="1fr">
          <Checkbox
            label="Over 18 Notice"
            checked={!!currentProduct.isAlcoholic}
            onChange={e => {
              const checked = !!e.target.checked;
              change({
                fieldId: 'isAlcoholic',
                label: 'Over 18 Notice',
                apply: to => (to.isAlcoholic = checked),
              });

              setAdditionalInfo({
                parallel: !!currentProduct.isParallelImport,
                alcohol: checked,
              });
            }}
          />

          <Checkbox
            label="Is Fragile?"
            checked={!!currentProduct.isFragile}
            onChange={e => {
              const checked = !!e.target.checked;
              change({
                fieldId: 'isFragile',
                label: 'Is Fragile?',
                apply: to => (to.isFragile = checked),
              });
            }}
          />
        </Grid>

        <Grid gap="24px" gridTemplateColumns="1fr">
          <Checkbox
            label="Hygiene Notice"
            checked={!!currentProduct.isHygienic}
            onChange={e => {
              const checked = !!e.target.checked;
              change({
                fieldId: 'isHygienic',
                label: 'Hygiene Notice',
                apply: to => (to.isHygienic = checked),
              });
            }}
          />

          <Checkbox
            label="Parallel Import"
            checked={!!currentProduct.isParallelImport}
            onChange={e => {
              const checked = !!e.target.checked;
              change({
                fieldId: 'isParallelImport',
                label: 'Parallel Import',
                apply: to => (to.isParallelImport = checked),
              });

              setAdditionalInfo({
                parallel: checked,
                alcohol: !!currentProduct.isAlcoholic,
              });
            }}
          />
        </Grid>
      </Grid>

      <Select
        label="Warranty"
        value={currentProduct.warrantyPeriod?.id || ''}
        options={[
          { id: '', value: '', label: 'Please select...' },
          ...warrantyPeriodOptions.map(option => ({
            id: option.value,
            value: option.value,
            label: option.label.replace(/warrantyperiod/i, ''),
          })),
        ]}
        onChange={e => {
          const value = e.target.value;
          const warrantyPeriod = warrantyPeriodOptions.find(
            warrantyPeriod => warrantyPeriod.value === value
          );
          change({
            fieldId: 'warrantyPeriod',
            label: 'Warranty',
            apply: to => {
              to.warrantyPeriod = warrantyPeriod
                ? {
                    id: warrantyPeriod.value,
                    label: warrantyPeriod.label,
                  }
                : undefined;
            },
          });
        }}
      />

      <Input
        label="Warranty Comments"
        value={currentProduct.warranty || ''}
        onChange={e => {
          const value = e.target.value;
          change({
            fieldId: 'warranty',
            label: 'Warranty Comments',
            apply: to => (to.warranty = value),
          });
        }}
        autoComplete="on"
      />

      <Select
        label="Condition"
        value={currentProduct.condition?.id || ''}
        onChange={e => {
          const value = e.target.value;
          const condition = conditionOptions.find(
            condition => condition.value === value
          );
          change({
            fieldId: 'condition',
            label: 'Condition',
            apply: to => {
              to.condition = condition
                ? {
                    id: condition.value,
                    label: condition.label,
                  }
                : undefined;
            },
          });
        }}
        options={[
          { id: '', value: '', label: 'Please select...' },
          ...conditionOptions.map(option => ({
            id: option.value,
            value: option.value,
            label: option.label,
          })),
        ]}
      />

      <Input
        label="Additional Info"
        value={currentProduct.additionalInfo || ''}
        disabled={
          !!currentProduct.isParallelImport || !!currentProduct.isAlcoholic
        }
        onChange={e => {
          const value = e.target.value;
          change({
            fieldId: 'additionalInfo',
            label: 'Additional Info',
            apply: to => (to.additionalInfo = value),
          });
        }}
        autoComplete="on"
      />

      {SHOW_UNNECESSARY_FIELDS_WHEN_DIFFERENT &&
        !!originalProduct.isSampleReceived && (
          <Checkbox
            label="Sample Received"
            checked={!!currentProduct.isSampleReceived}
            onChange={e => {
              const checked = !!e.target.checked;
              change({
                fieldId: 'isSampleReceived',
                label: 'Sample Received',
                apply: to => (to.isSampleReceived = checked),
              });
            }}
          />
        )}

      {SHOW_UNNECESSARY_FIELDS_WHEN_DIFFERENT &&
        !originalProduct.isReturnableToSupplier && (
          <Checkbox
            label="Supplier Accepts Returns?"
            checked={!!currentProduct.isReturnableToSupplier}
            onChange={e => {
              const checked = !!e.target.checked;
              change({
                fieldId: 'isReturnableToSupplier',
                label: 'Supplier Accepts Returns?',
                apply: to => (to.isReturnableToSupplier = checked),
              });
            }}
          />
        )}
    </SectionWrapper>
  );
};

const ShopAndCategoriesSection = () => {
  const currentProduct = useCurrentProduct();
  const change = useChangeProduct();

  const status = validateProduct(
    currentProduct,
    shopAndCategoriesSectionValidators
  ).status;

  return (
    <SectionWrapper title="Shop & Categories" status={status}>
      <CategorySelector
        label="Shop"
        placeholder="Search for shop..."
        categoryType={CategoryTypeEnum.dailyShop}
        categories={currentProduct.shops || []}
        addCategory={shop => {
          change({
            fieldId: `shops.add.${shop.id}`,
            label: `Set Daily Shop: "${getCategoryLabel(shop)}"`,
            // NOTE: the underlying data structure is an array. but we only allow one shop from BP FE.
            // so whenever adding a shop we'll remove any other options.
            apply: to => (to.shops = [shop]),
          });
        }}
        removeCategory={shop => {
          change({
            fieldId: `shops.remove.${shop.id}`,
            label: `Remove Daily Shop: "${getCategoryLabel(shop)}"`,
            apply: to =>
              (to.shops = [
                ...(to.shops || []).filter(({ id }) => id !== shop.id),
              ]),
          });
        }}
        closeOnSelect
      />

      {/* NOTE: for now we hide this selector when there are no permanent shops for a deal */}
      {/* TODO: remove this condition later if/when we decide to support loading of permanent deals */}
      {(currentProduct.permanentShops || []).length > 0 && (
        <CategorySelector
          label="Permanent Shop"
          placeholder="Search for permanent shop..."
          categoryType={CategoryTypeEnum.permanent}
          categories={currentProduct.permanentShops || []}
          addCategory={permanentShop => {
            change({
              fieldId: `permanentShops.add.${permanentShop.id}`,
              label: `Set Permanent Shop: "${getCategoryLabel(permanentShop)}"`,
              // NOTE: the underlying data structure is an array. but we only allow one permanent shop from BP FE.
              // so whenever adding a shop we'll remove any other options.
              apply: to => (to.permanentShops = [permanentShop]),
            });
          }}
          removeCategory={permanentShop => {
            change({
              fieldId: `permanentShops.remove.${permanentShop.id}`,
              label: `Remove Permanent Shop: "${getCategoryLabel(
                permanentShop
              )}"`,
              apply: to =>
                (to.permanentShops = [
                  ...(to.permanentShops || []).filter(
                    ({ id }) => id !== permanentShop.id
                  ),
                ]),
            });
          }}
          closeOnSelect
        />
      )}

      <CategorySelector
        label="Categories"
        placeholder="Search for categories..."
        categories={currentProduct.categories || []}
        addCategory={category => {
          change({
            fieldId: `categories.add.${category.id}`,
            label: `Add Category: "${getCategoryLabel(category)}"`,
            apply: to =>
              (to.categories = [
                // ensure we don't add the same category twice
                ...(to.categories || []).filter(({ id }) => id !== category.id),
                category,
              ]),
          });
        }}
        removeCategory={category => {
          change({
            fieldId: `categories.remove.${category.id}`,
            label: `Remove Category: "${getCategoryLabel(category)}"`,
            apply: to =>
              (to.categories = [
                ...(to.categories || []).filter(({ id }) => id !== category.id),
              ]),
          });
        }}
        required
      />
    </SectionWrapper>
  );
};

const ConditionsAndCategoryScreen = () => (
  <ErrorBoundary>
    <Grid gridTemplateColumns={['1fr', '1fr 1fr']} gap={[3, 4]}>
      <SpecialConditionsSection />
      <ShopAndCategoriesSection />
    </Grid>
    <Overscroll />
  </ErrorBoundary>
);

export default ConditionsAndCategoryScreen;
