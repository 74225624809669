/**
 * @name rps-spinner-dots
 * @type {SpinnerDots}
 * 	A Spinner that has "dots" in it
 * 
 * @attributes + @properties 					| Changes trigger a re-render
 * Casing usages									| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String} customCss			| Specify a custom .css file to include, use cssPath to override elements,
 * 														DO NOT override the styling definition by re-defining the css styling
 * 														Put this file into the *window.cssPath* folder @see {link README.md}
 * 														eg: /public/css (where the other css files are)
 * 														Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css				| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 												
 * 
 * @methods
 * 	hide()											| hide this spinner
 * 	show()											| display this spinner
 * 
 * @events
 * 	None
 * 
 * @slots
 * 	None
 * 
 * @callback
 * 	None
 * 
 * @example @Lit
 * @basic
 * ==============================
 * import '@rps-wc/wc/rps-spinner-dots';
 * 
 * 
 *  	const myFunc = () => {
 *			bounce.value.hide();
 *			bounce.value.show();
 * 	}
 * 
 * 	<rps-spinner-dots ref=${bounce}></rps-spinner-dots>
 * 
 * 
 * @example @ReactJS
 * @basic
 * ==============================
 * import '@rps-wc/wc/rps-spinner-dots';
 * 
 *  	const myFunc = () => {
 *			bounce.current.hide();
 *			bounce.current.show();
 * 	}
 * 
 * 
 * return <rps-spinner-dots ref={bounce}></rps-spinner-dots>
 * 
*/
import { SpinnerDots } from './src/spinners/SpinnerDots';
if (!window.customElements.get('rps-spinner-dots'))
	window.customElements.define('rps-spinner-dots', SpinnerDots);

