import propTypes from 'prop-types';
import React from 'react';
import Login from '../components/authentication/Login';
import { useUserDataSource } from '../hooks/userDataSource';

const PrivateRoute = ({ children }) => {
  const user = useUserDataSource();

  if (user.state.logged_in) {
    return children;
  } else {
    // set this as the last location so that the user is taken back to the page they originally intended to visit.
    localStorage.setItem('lastLocation', window.location.pathname);
    return <Login />;
  }
};

PrivateRoute.propTypes = {
  children: propTypes.any,
};

export { PrivateRoute };
