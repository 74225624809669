import { html, css } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';

/**
 * A control to display a countdown that fires a 'cancel' event when the time runs out
 *
 * @export
 * @class CountDown
 * @extends {CustomLitElement}
 */
export class CountDown extends CustomLitElement {

	static styles = css`
		:host {
		}

		.countdown-value{
			display: contents;
		}
	 `;

	static get properties() {
		return {
			preText: { type: String },		// text to display before the remaining time
			postText: { type: String },	// text to display after the remaining time
			hours: { type: Number },
			minutes: { type: Number },
			seconds: { type: Number },
			start: { type: Number },		// From when to start the countdown, override with your own custom time, or leave to use now
			cbStop: { attribute: false },
		};
	}

	constructor() {
		super();
		this.hours = 0;
		this.minutes = 1;
		this.seconds = 0;
		this.start = new Date().getTime();
		this.remaining = '';
		this.preText = 'Time remaining ';
		this.postText = '';

		this.hoursMs = 1000 * 60 * 60;
		this.minutesMs = 1000 * 60;
		this.secondsMs = 1000;
	}


	/**
	 * When the control is first rendered, start the timer
	 *
	 * @memberof CountDown
	 */
	firstUpdated() {
		super.firstUpdated()
		this.startTimer();
	}


	/**
	 * Start the countdown, automatically called when control is first rendered
	 * @example .startTimer() or .startTimer( new Date().getTime() )
	 * @description Defaults to new Date().getTime()
	 *
	 * @param {Date} start - Starting time to countdown from
	 * @memberof CountDown
	 */
	startTimer(start) {
		if (start) this.start = start;

		// need to determine the ending time
		const hoursTemp = this.hours * this.hoursMs;
		const minutesTemp = this.minutes * this.minutesMs;
		const secondsTemp = this.seconds * this.secondsMs;
		this.ending = this.start + hoursTemp + minutesTemp + secondsTemp;

		this.timerFunc = setInterval(() => {
			// Get today's date and time
			const now = new Date().getTime();
			// Find the time diff between now and the count down date
			const time = this.ending - now;
			if (time > 0) {
				let hours = Math.floor((time % (this.hoursMs * 24)) / (this.hoursMs));
				let minutes = Math.floor((time % (this.hoursMs)) / (this.minutesMs));
				const seconds = Math.floor((time % (this.minutesMs)) / this.secondsMs);
				hours = hours > 0 ? hours + "h " : '';
				minutes = minutes > 0 ? minutes + "m " : '';

				this.remaining = hours + minutes + seconds + "s";
				this.requestUpdate();
			} else {
				clearInterval(this.timerFunc);
				this._stop();
			}

		}, 1000);

	}


	/**
	 * Stop the countdown, time has elapsed
	 *
	 * @memberof CountDown
	 */
	_stop() {
		const detail = { source: this.tagName, started: this.start, ended: this.ending };
		const e = new CustomEvent('stop', { detail, bubbles: true, composed: true, cancelable: true });
		this.dispatchEvent(e);

		if (this.cbStop) this.cbStop(e);
	}


	render() {
		return html`
			<div class="countdown-container">
				<slot name="preTextSlot">
					<span class="countdown-pre-text">${this.preText}</span>
				</slot>
				<span class="countdown-value"> ${this.remaining}</span>
				<slot name="postTextSlot">
					<span class="countdown-post-text">${this.postText}</span>
				</slot>
			</div>
	 `;
	}
}
