import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

/**
 *	Reusable `react-beautiful-dnd` Droppable wrapper: a div container to render a list of drag-and-droppable items into.
 *
 * @param {Object} props
 * @param {*|*[]} props.children 			- children to render (i.e.: Contents of list)
 * @param {String} props.name 				- unique name (id) for this dropzone
 * @param {Function} props.onDragEnd 		- callback event fired when compatible item is dragged into compatible position
 * @param {Object} props.overrides 			- List of overrides to add to the container div (i.e: style)
 * @param {"vertical"|"horizontal"} props.direction - Direction the list will be sorted (React-DND only supports uni-
 * 																	directional lists with no plans to support grid layouts)
 */
export const DropZone = ({
  children,
  name,
  onDragEnd,
  overrides,
  direction,
}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`${name}-dropzone`} direction={direction}>
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            {...overrides}
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

DropZone.propTypes = {
  onDragEnd: PropTypes.func,
  children: PropTypes.any,
  name: PropTypes.string,
  overrides: PropTypes.any,
  direction: PropTypes.string,
};
