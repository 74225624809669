import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataSourceContext } from '../contexts/DataSource';
import { useSafeEffect } from './useSafeEffect';

/**
 * Hooks into the User DataSource Context to provide data for currently
 * @hook
 * @returns {Object} userDataSource
 */
export const useUserDataSource = () => {
  const sourceContext = useContext(DataSourceContext);
  const [state, setState] = useState(sourceContext.user.state);
  const userDataSource = sourceContext.user;
  const navigate = useNavigate();
  // const [loginMutation] = useMutation(LOGIN);
  const [requestTokens, _setRequestTokens] = useState(
    sourceContext.user.state?.requestTokens
  );
  const [authTokens, _setAuthTokens] = useState(
    sourceContext.user.state?.authTokens
  );

  const setAuthTokens = val => {
    console.debug(
      `[useUserDataSource::setAuthTokens@26] Setting auth tokens.`,
      val
    );
    localStorage.setItem('authTokens', val);
    _setAuthTokens(val);
  };

  const setRequestTokens = val => {
    console.debug(
      `[useUserDataSource::setRequestTokens@32] Setting request tokens.`,
      val
    );
    localStorage.setItem('requestTokens', val);
    _setRequestTokens(val);
  };

  useSafeEffect(
    isMounted => {
      const intervalHandle = setInterval(() => {
        if (isMounted) {
          const existingAuthTokens = localStorage.getItem('authTokens');
          const existingRequestTokens = localStorage.getItem('requestTokens');

          if (existingAuthTokens === null || existingAuthTokens === undefined)
            return;
          if (
            existingRequestTokens === null ||
            existingRequestTokens === undefined
          )
            return;

          if (authTokens !== existingAuthTokens) {
            if (existingAuthTokens) setAuthTokens(existingAuthTokens);
          }
          if (requestTokens !== existingRequestTokens) {
            if (existingRequestTokens) setRequestTokens(existingRequestTokens);
          }
        }
      }, 500);

      if (state.logged_in && intervalHandle) {
        clearInterval(intervalHandle);
      }
      return () => {
        clearInterval(intervalHandle);
      };
    },
    [authTokens, requestTokens, state]
  );

  // Handler for handling user state updates
  useEffect(() => {
    const handleNotify = newState => {
      setState(newState);
    };

    userDataSource.subscribe(handleNotify);

    return () => {
      userDataSource.publish({ refresh_scheduled: false });
      userDataSource.unsubscribe(handleNotify);
    };
  }, [userDataSource]);

  const setUserData = userData => {
    userDataSource.publish({
      logged_in: true,
      name: `${userData.firstname} ${userData.lastname}`,
      username: userData.username,
      token: userData.email,
      userId: userData.email,
      email: userData.email,
      loginTime: new Date().toISOString(),
    });
  };

  const logout = async (withNav = true) => {
    sourceContext.refresh_scheduled = false;
    userDataSource.publish(
      { logged_in: false, name: '', busy: false, refresh_scheduled: false },
      false
    );
    if (withNav) {
      navigate('/');
      window.location.reload();
    }
  };

  const expireSession = () => {
    sourceContext.refresh_scheduled = false;
    userDataSource.publish(
      { logged_in: false, name: '', busy: false, refresh_scheduled: false },
      false
    );

    localStorage.setItem(
      'authError',
      'Session Expired, please log in again before continuing. (Note: Your work is safe!)'
    );
    localStorage.setItem('lastLocation', window.location.pathname);
  };

  return {
    state,
    actions: {
      logout,
      setUserData,
      expireSession,
    },
    tokens: {
      authTokens,
      requestTokens,
      setAuthTokens,
      setRequestTokens,
    },
  };
};
