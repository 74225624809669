/**
 * @name rps-spinner-bounce
 * @type {SpinnerBounce}
 * 	A Spinner that has a "bounce" in it
 * 
 * @attributes + @properties 					| Changes trigger a re-render
 * Casing usages									| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String} 	customCss		| Specify a custom .css file to include, use cssPath to override elements,
 * 														DO NOT override the styling definition by re-defining the css styling
 * 														Put this file into the *window.cssPath* folder @see {link README.md}
 * 														eg: /public/css (where the other css files are)
 * 														Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css				| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 												
 * 
 * @methods
 * 	hide()										| hide this spinner
 * 	show()										| display this spinner
 * 
 * @events
 * 	None
 * 
 * @slots
 * 	None
 * 
 * @callback
 * 	None
 * 
 * @example {Lit}
 * BASIC
 * ==============================
 * import '@rps-wc/wc/rps-spinner-bounce';
 * 
 * 
 *  	const myFunc = () => {
 *			bounce.value.hide();
 *			bounce.value.show();
 * 	}
 * 
 * 	<rps-spinner-bounce ref=${bounce}></rps-spinner-bounce>
 * 
 * 
 * @example {ReactJS}
 * BASIC
 * ==============================
 * import '@rps-wc/wc/rps-spinner-bounce';
 * 
 *  	const myFunc = () => {
 *			bounce.current.hide();
 *			bounce.current.show();
 * 	}
 * 
 * 
 * return <rps-spinner-bounce ref={bounce}></rps-spinner-bounce>
 * 
 *  
*/
import { SpinnerBounce } from './src/spinners/SpinnerBounce';
if (!window.customElements.get('rps-spinner-bounce'))
	window.customElements.define('rps-spinner-bounce', SpinnerBounce);

