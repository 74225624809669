import { html, css } from 'lit';
import { createRef, ref } from 'lit/directives/ref.js';
import { BaseInputControl } from '../baseClasses/BaseInputControl';
import '../../rps-form';
import '../../rps-input';
import '../../rps-card';
import '../../rps-ok-modal';

export class ChangePassword extends BaseInputControl {

	static styles = css`
		:host {
		  display: block;
		}
	 `;

	static get properties() {
		return {
			passwordLabel: { type: String },
			passwordPattern: { type: String },
			passwordPlaceholder: { type: String },
			passwordErrorMessage: { type: String },

			newPasswordLabel: { type: String },
			newPlaceholder: { type: String },

			confirmPasswordLabel: { type: String },
			confirmPlaceholder: { type: String },
			confirmPasswordErrorMessage: { type: String },

			confirmButtonText: { type: String },
			hideCancelButton: { type: Boolean },

			cbSubmit: { attribute: false },
			cbCancel: { attribute: false },
		};
	}

	constructor() {
		super();
		this.confirmButtonText = "Change Password";
		this.hideCancelButton = false;

		this.passwordLabel = "Password";
		this.passwordPattern = '.{8,}';				// at least 8 long
		this.passwordPlaceholder = "Enter Password";
		this.passwordErrorMessage = 'Passwords must be at least 8 long';

		this.newPasswordLabel = "New Password";
		this.newPlaceholder = "Enter New Password";

		this.confirmPasswordLabel = "Confirm New Password";
		this.confirmPlaceholder = "Confirm New Password";
		this.confirmPasswordErrorMessage = '[New password] must match the [Confirm Password]';

		this.modal = createRef();
		this.form = createRef();
		this.password = createRef();
		this.newPassword = createRef();
		this.confirmPassword = createRef();
	}

	/**
	 * Retrieve the password control
	 *
	 * @readonly
	 * @memberof ChangePassword
	 */
	get passwordControl() {
		return this.password.value;
	}

	/**
	 * Retrieve the new password control
	 *
	 * @readonly
	 * @memberof ChangePassword
	 */
	get newPasswordControl() {
		return this.newPassword.value;
	}

	/**
	 * Retrieve the confirm password control
	 *
	 * @readonly
	 * @memberof ChangePassword
	 */
	get confirmPasswordControl() {
		return this.confirmPassword.value;
	}

	/**
	 * Retrieve the form control
	 *
	 * @readonly
	 * @memberof ChangePassword
	 */
	get formControl() {
		return this.form.value;
	}

	/**
	 * Retrieve the modal control
	 *
	 * @readonly
	 * @memberof ChangePassword
	 */
	get modalControl() {
		return this.modal.value;
	}

	/**
	 * Fires when the user clicks on the "Change password and the data entered is valid"
	 *
	 * @param {Event} e
	 * @memberof ChangePassword
	 * @private
	 */
	change(e) {
		e.stopPropagation();

		console.debug('Login:change', 'forgot password clicked');

		if (this.newPasswordControl.value !== this.confirmPasswordControl.value) {
			console.debug('ChangePassword: change', '[new password] does not match [confirm password]');
			this.modalControl.show();
			return;
		}

		const detail = {
			id: this.id,
			source: this.tagName,
			password: this.passwordControl.value,
			newPassword: this.newPasswordControl.value,
			confirmPassword: this.confirmPasswordControl.value,
		};

		e = new CustomEvent('submit', { detail, bubbles: true, composed: true, cancelable: true });
		this.dispatchEvent(e);

		if (this.cbSubmit) this.cbSubmit(e);
	}


	/**
	 * Fires when the user clicks on the "Change password and the data entered is valid"
	 *
	 * @param {Event} e
	 * @memberof ChangePassword
	 * @private
	 */
	cancel(e) {
		e.stopPropagation();

		console.debug('Login:change', 'forgot password clicked');

		if (this.newPasswordControl.value !== this.confirmPasswordControl.value) {
			console.debug('ChangePassword: change', '[new password] does not match [confirm password]');
			this.modalControl.show();
			return;
		}

		const detail = {
			id: this.id,
			source: this.tagName,
			password: this.passwordControl.value,
			newPassword: this.newPasswordControl.value,
			confirmPassword: this.confirmPasswordControl.value,
		};

		e = new CustomEvent('cancel', { detail, bubbles: true, composed: true, cancelable: true });
		this.dispatchEvent(e);

		if (this.cbCancel) this.cbCancel(e);
	}

	/**
	 * Compare the passwords, and display error if necessary
	 *
	 * @param {Event} e
	 * @memberof ChangePassword
	 * @private
	 */
	comparePasswords(e) {
		e.stopPropagation();

		if (this.newPasswordControl.isValid && this.confirmPasswordControl.isValid) {
			const newP = this.newPasswordControl.value;
			const confirmP = this.confirmPasswordControl.value;
			if (newP !== confirmP) {
				this.confirmPasswordControl.displayError(false);
			}
		}

	}

	render() {
		return html`
		${this.addCss(true)}
	
		<div>
			<rps-card>
				<div slot="header"><h2>Change Password</h2></div>

				<rps-form 
					@submit=${this.change} 
					@cancel=${this.cancel}
					${ref(this.form)}
					confirmText="${this.confirmButtonText}"
					?hideCancelButton=${this.hideCancelButton}
				>
					<rps-input name="password" label="${this.passwordLabel}" required
						placeholder="${this.passwordPlaceholder}"
						errormessage="${this.passwordErrorMessage}"
						type="password"
						pattern="${this.passwordPattern}"
						caching
						${ref(this.password)}
					>
					</rps-input>

					<rps-input name="newPassword" label="${this.newPasswordLabel}" required
						placeholder="${this.newPlaceholder}"
						errormessage="${this.passwordErrorMessage}"
						type="password"
						pattern="${this.passwordPattern}"
						caching
						${ref(this.newPassword)}
					>
					</rps-input>

					<rps-input name="confirmPassword" label="${this.confirmPasswordLabel}" required
						placeholder="${this.confirmPlaceholder}"
						errormessage="${this.confirmPasswordErrorMessage}"
						type="password"
						pattern="${this.passwordPattern}"
						caching
						${ref(this.confirmPassword)}
						@input=${this.comparePasswords}
					>
					</rps-input>

				</rps-form>
			</rps-card>
		<div>`;
	}
}

