/**
 * NOTE: this is the urql exchange for logging API requests and responses.
 */

import { mapExchange } from 'urql';
import {
  type DefinitionNode,
  type ExecutableDefinitionNode,
  Kind,
} from 'graphql';
import { addApiLog, addApiLogResult } from './cache';
import uuid from '@odo/utils/uuid';
import { dateObjectToIso } from '@odo/utils/date';

/**
 * NOTE: some responses are too large to be stored in the cache.
 * Add anything to this list that seems extreme.
 * getAttributes for instance is over 1MB when stringified.
 */
const EXCLUDED_RESULTS = ['getAttributes', 'getCategorySearchBreadcrumbs'];

const isNamedDefinition = (
  def: DefinitionNode
): def is ExecutableDefinitionNode =>
  (def as ExecutableDefinitionNode).kind === Kind.OPERATION_DEFINITION ||
  (def as ExecutableDefinitionNode).kind === Kind.FRAGMENT_DEFINITION;

export const apiLogsExchange = mapExchange({
  onOperation: operation => {
    // we only want to track queries and mutations
    if (!['query', 'mutation'].includes(operation.kind)) return;

    const startTime = Date.now();

    const namedDef = operation.query.definitions.find(def =>
      isNamedDefinition(def)
    );
    if (namedDef && isNamedDefinition(namedDef)) {
      /**
       * NOTE: the operations have a key which "seems" unique,
       * but that key is actually a signature of the query/mutation.
       * So if we have two queries with the same signature, they will have the same key.
       * But we want to track them separately, so we're gonna generate our own unique ID.
       */
      const id = uuid();

      operation.context.uuid = id;

      addApiLog({
        id,
        meta: {
          kind: operation.kind,
          url: operation.context.url,
          name: namedDef?.name?.value || null,
          startTime,
          startTimeLocal: dateObjectToIso(new Date(startTime), true),
          // store the current window location if we can get it
          location:
            typeof window !== 'undefined' ? window.location.href : undefined,
        },
        request: {
          variables: operation.variables || null,
          query: {
            name: namedDef?.name?.value || null,
            body: operation.query.loc?.source.body || null,
          },
        },
      });
    }
  },
  onResult: ({ operation, data }) => {
    if (typeof operation.context.uuid === 'string') {
      addApiLogResult({
        id: operation.context.uuid,
        result:
          // if there is an error that doesn't get caught, data might be null, which is technically an object
          data !== null &&
          typeof data === 'object' &&
          Object.keys(data).some(key => EXCLUDED_RESULTS.includes(key))
            ? { note: '*REDACTED-DUE-TO-SIZE-LIMITS*', keys: Object.keys(data) }
            : data,
        endTime: Date.now(),
      });
    }
  },
});
