/**
 * @name rps-layout
 * @type {Layout}
 * 	The layout component of the system.
 *
 * @description This component sets the "window.internetSpeed" based on the internet connection.
 * 	This is used as a guideline to determine what resources to pull from the server.
 * 	@see {link <rps-image>} for an example of this.
 * 	The users profile should allow the user to override this value
 *
 *
 * @attributes + @properties 				| Changes trigger a re-render
 * Casing usages								| Attribute in html (lower case), Property in JS (Camel Case)
 *
 * 	@property {String} headerTitle	| The title to show in the header (for the web-site)
 * 	@property {String} logo				| The image (url) fot the web-site image displayed in the header
 * 	@property {String} version			| The software version
 * 	@property {Array}	 menuItems		| @see {link <rps-tree>} for more details on the contents for this array
 * 	@property {Array}	 toolBarButtons| The Array of items used to generate the toolbar
 * 													@see {link <rps-toolbar>} for more details
 * 													Default = [
 * 														{ id: "settings", svg: `${iconNames.settings}`, title: "Settings" },
 * 														{ id: "user", svg: `${iconNames.user}`, title: "User" },
 * 													];
 * 	@property {String} css				| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 	@property {String} customCss		| Specify a custom .css file to include, use cssPath to override elements,
 * 													DO NOT override the styling definition by re-defining the css styling
 * 													Put this file into the *window.cssPath* folder @see {link README.md}
 * 													eg: /public/css (where the other css files are)
 * 													Suggested use: use sub-folders, dont put it all into the base
 * @classes
 * 	hasmenu									| Specify that this has a menu
 *
 * @properties (JS only)
 * 	get menuContainer						| A reference to the menu container.
 * 	get rightMenuContainer				| A reference to the right menu container.
 * 	get menu									| A reference to the menu.
 * 	get layoutHeader						| A reference to the <rps-layout-menu>.
 *
 * @methods
 * 	toggleMenu								| Allows the user to "show" or "hide" the left menu
 * 	toggleRightMenu						| Allows the user to "show" or "hide" the right menu
 *
 * @events
 * 	clickMenu								| Fired when the user clicks on a menu item
 * 	clickMenuOptions						| Fired when the user clicks on a menu option item (icon in menu)
 * 	clickToolbar							| Fired when the user clicks on a toolbar item
 * 	clickLogo								| Fired when the user clicks on the layout logo
 *
 *
 * @callbacks
 * 	cbClickMenu								| Called when the user clicks on a menu item
 * 	cbClickMenuOptions					| Called when the user clicks on a menu option item (icon in menu)
 * 	cbClickToolbar							| Called when the user clicks on a toolbar item
 * 	cbClickLogo								| Called when the user clicks on the layout logo
 *
 * @slots
 * 	popup										| A placeholder for: Efficiently unleash cross-media information without cross-media value. Quickly maximize timely deliverables for real-time schemas. Dramatically maintain clicks-and-mortar solutions without functional solutions. Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition. Organically grow the holistic world view of disruptive innovation via workplace diversity and empowerment.Bring to the table win-win survival strategies to ensure proactive domination.
 * 	nav										| A placeholder for:
 * 	aside-left								| A placeholder for: The menu
 * 	toast										| A placeholder for: A toast control
 * 	sticky-header							| A placeholder for: A custom sticky header control
 * 	header									| A placeholder for: A custom header control
 * 	main										| A placeholder for: Your app controls go here
 * 	footer									| A placeholder for: A custom footer control
 * 	aside-right								| A placeholder for: The right menu
 *
 * @example+ @lit
 * @see {link demo/easy/layout/layout.html} for an example
 *
 * @example+ @ReactJS
 * @see {link "One Day only Project"} for a React example
 * */

import { Layout } from './src/layout/Layout';
if (!window.customElements.get('rps-layout'))
	window.customElements.define('rps-layout', Layout);

