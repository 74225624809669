// Wrapper to temporarily enforce proper checkbox behavior

import { RPSCheckbox } from '@rps/web-components/build/react-wrappers';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// FIXME: RPSCheckbox Behavior needs to be addressed in Components
// Controlled value updates don't work as inner checkbox input doesn't get
// updated on value change (But component's internal checked value does).
// Similarly, indeterminate states aren't easily set and don't look correct.

// This is a temporary workaround.
export const CheckboxWrapper = ({
  checked,
  label,
  indeterminate = false,
  ...rest
}) => {
  const ref = useRef();

  useEffect(() => {
    const refCurrent = ref.current;

    if (refCurrent) {
      setTimeout(() => {
        refCurrent.checked = checked;
        if (refCurrent.inputControl) {
          refCurrent.inputControl.defaultChecked = checked;
          refCurrent.inputControl.checked = checked;
        }

        if (!checked) {
          refCurrent.value = '';
        }
        refCurrent.requestUpdate();
      }, 0);
    }
  }, [checked, indeterminate]);

  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ opacity: indeterminate ? '0.5' : '1.0' }}>
        <RPSCheckbox label="" innerRef={ref} {...rest} />
      </span>
      <span>{label}</span>
    </span>
  );
};

CheckboxWrapper.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  indeterminate: PropTypes.bool,
};
