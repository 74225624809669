import {
  RPSCard,
  RPSSwitchSimple,
} from '@rps/web-components/build/react-wrappers';
import { useCurrentDealSource } from '../../../hooks/useCurrentDealSource';
import { Deal } from '../../../models/Deal.jsx';
import { DealBuyerAndSupplier } from '../../../models/BuyerAndSupplier.jsx';
import { DealPriceAndCustomOptions } from 'models/PriceAndCustomOptions.jsx';
import { Input } from '@odo/components/elements/form-fields';
import { handleODOInput } from 'utils/odo-migration/forms';
import SupplierSelector from '@odo/components/widgets/supplier-selector';

export const SupplierSection = () => {
  const currentDeal = useCurrentDealSource();
  const { deal, createInputHandler } = currentDeal;
  const handleInput = createInputHandler(Deal.MODELS.BUYER_AND_SUPPLIER);

  const handleSetSupplier = supplier => {
    currentDeal.deal.set(
      Deal.MODELS.BUYER_AND_SUPPLIER,
      DealBuyerAndSupplier.FIELDS.SUPPLIER,
      supplier.id
    );
    currentDeal.update();
  };

  const handleSupplierRemove = () => {
    currentDeal.deal.set(
      Deal.MODELS.BUYER_AND_SUPPLIER,
      DealBuyerAndSupplier.FIELDS.SUPPLIER,
      undefined
    );
    currentDeal.update();
  };

  return (
    <RPSCard css=":host { width: 100%; }">
      <div slot="header">
        <h5>Supplier</h5>
      </div>

      <SupplierSelector
        label="Supplier"
        placeholder="Filter suppliers"
        supplier={deal?.buyerAndSupplier?.supplier}
        setSupplier={handleSetSupplier}
        removeSupplier={handleSupplierRemove}
        closeOnSelect
        matchRPStyles
      />

      <Input
        label="Rebate (%)"
        value={deal.priceAndCustomOptions.rebateDiscount}
        onChange={handleODOInput({
          currentDeal,
          model: Deal.MODELS.PRICE_AND_CUSTOM_OPTIONS,
          field: DealPriceAndCustomOptions.FIELDS.REBATE,
        })}
        matchRPStyles
        selectOnFocus
      />

      <div>
        <RPSSwitchSimple
          cbInput={handleInput(DealBuyerAndSupplier.FIELDS.NEW_SUPPLIER)}
          checked={deal.buyerAndSupplier.isSupplierNew}
          name="dealHeaderInfoThumbnail"
          label="Is Supplier New?"
        />
      </div>
    </RPSCard>
  );
};
