import type {
  ApiAttribute,
  AttributeCode,
  AttributeData,
} from '@odo/types/api';
import { createContext } from 'react';

export type AttributeID = AttributeCode;

export interface AttributeOption {
  key: string;
  value: string;
  label: string;
  metadata?: {
    key: string;
    value: string;
  }[];
  /**
   * NOTE: surcharges attribute from API keeps the label in the value.
   * So far it's the only attribute that I need to get the value for in a renderer.
   * Otherwise I wasn't even passing it down. But it seems I need it now somehow.
   * TODO: see if we can come up with a better solution
   */
  originalData: AttributeData;
}

export interface Attribute extends ApiAttribute {
  id: AttributeID;
  options: AttributeOption[];
}

export interface AttributeContextType {
  attributes: Attribute[];
  loading: boolean;
  isReady: boolean;
  getAttribute: (attr: AttributeID) => Attribute | undefined;
  getAttributeOptions: (attr: AttributeID) => AttributeOption[];
}

const defaultContext: AttributeContextType = {
  attributes: [],
  loading: false,
  isReady: false,
  getAttribute: () => undefined,
  getAttributeOptions: () => [],
};

const AttributeContext = createContext<AttributeContextType>(defaultContext);

export default AttributeContext;
