import { useCallback, useContext, useRef, useState, useMemo } from 'react';
import { dateObjectToIso } from '@odo/utils/date';
import { gql } from 'urql';
import { createClient } from '@odo/services/urql';
import toast from 'react-hot-toast';
import type {
  ApiCategory,
  InputCategoriesSelect,
  QueryCategoriesArgs,
  QueryCategoriesOutput,
} from '@odo/types/api';
import { CategoryFilterFields } from '@odo/types/api';
import { debounce } from '@odo/utils/debounce';
import DropdownInput from '@odo/components/elements/dropdown-input';
import Tooltip from '@odo/components/widgets/tooltip/tooltip';
import { cssColor } from '@odo/utils/css-color';
import { FiAlertCircle } from 'react-icons/fi';
import type { FilterInterface } from '../filters';
import { ReadFilterContext } from '../filters';
import { FilterType } from '@odo/contexts/search/filters/types';

const ERROR_TOAST_ID = 'categories-search-error';
const SEARCH_LIMIT = 20;
const MAX_PAGE_NUMBER = 1;
const SHOP_CATEGORY_ID = '2';

const GET_CATEGORIES = gql`
  query getCategories($filter: InputCategoryFilter) {
    getCategories(filter: $filter) {
      id
      name
      parentCategory
      activeFromDate
      isActive
    }
  }
`;

const getCategoryDateFilter = (
  activeDateFilter: FilterInterface | undefined,
  isFromDate: boolean
): InputCategoriesSelect | null => {
  if (activeDateFilter === undefined) return null;
  return {
    field: isFromDate
      ? CategoryFilterFields.activeFromDate
      : CategoryFilterFields.activeToDate,
    condition: activeDateFilter.exact
      ? 'IN'
      : isFromDate
      ? 'GREATER_THAN_OR_EQUALS_TO'
      : 'LESS_THAN_OR_EQUALS_TO',
    value:
      activeDateFilter.value instanceof Date
        ? dateObjectToIso(activeDateFilter.value, true)
        : typeof activeDateFilter.value === 'string'
        ? activeDateFilter.value
        : '',
  };
};

const searchShops = async ({
  query,
  signal,
  setIsLoading,
  setResults,
  filterFromDate = null,
  filterToDate = null,
}: {
  query: string;
  signal: AbortSignal;
  setIsLoading: (loading: boolean) => void;
  setResults: (results: ApiCategory[]) => void;
  filterFromDate: InputCategoriesSelect | null;
  filterToDate: InputCategoriesSelect | null;
}) => {
  const fetchCategories = async (signal: AbortSignal) => {
    let isActive = true;
    setIsLoading(true);

    signal.addEventListener('abort', () => (isActive = false));

    try {
      const { data } = await createClient({ signal })
        .query<QueryCategoriesOutput, QueryCategoriesArgs>(
          GET_CATEGORIES,
          {
            filter: {
              limit: SEARCH_LIMIT,
              page: MAX_PAGE_NUMBER,
              orderBy: {
                field: 'activeFromDate',
                direction: 'DESC',
              },
              select: [
                {
                  field: CategoryFilterFields.parentId,
                  condition: 'IN',
                  value: SHOP_CATEGORY_ID,
                },
                {
                  field: CategoryFilterFields.name,
                  condition: 'LIKE',
                  value: query,
                },
                ...(filterFromDate ? [filterFromDate] : []),
                ...(filterToDate ? [filterToDate] : []),
              ],
            },
          },
          { requestPolicy: 'network-only' }
        )
        .toPromise();

      if (isActive && data?.getCategories) {
        setResults(data.getCategories);
        setIsLoading(false);
      }
    } catch (e) {
      if (!isActive) return;

      console.error(e);
      toast.error(
        e instanceof Error && typeof e.message === 'string'
          ? e.message
          : 'Error loading categories',
        {
          id: ERROR_TOAST_ID,
          duration: 10000,
        }
      );
      isActive && setIsLoading(false);
    }
  };

  fetchCategories(signal);
};

const ShopSearch = ({
  onResult,
  disabled,
}: {
  disabled?: boolean;
  onResult: (result: ApiCategory) => void;
}) => {
  const controllerRef = useRef<AbortController>();
  const readFilters = useContext(ReadFilterContext);
  const [results, setResults] = useState<ApiCategory[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<
    ApiCategory | undefined
  >();
  const activeFromDateFilter = readFilters.find(
    filter =>
      filter.active &&
      filter.key === 'activeFromDate' &&
      filter.type === FilterType.date
  );
  const activeToDateFilter = readFilters.find(
    filter =>
      filter.active &&
      filter.key === 'activeToDate' &&
      filter.type === FilterType.date
  );

  const activeFromDate = useMemo(
    () => getCategoryDateFilter(activeFromDateFilter, true),
    [activeFromDateFilter]
  );
  const activeToDate = useMemo(
    () => getCategoryDateFilter(activeToDateFilter, false),
    [activeToDateFilter]
  );

  const onInput = useCallback(
    (query: string) => {
      if (controllerRef.current) controllerRef.current.abort();

      controllerRef.current = new AbortController();

      debounce(
        searchShops,
        500,
        controllerRef.current.signal
      )({
        query,
        signal: controllerRef.current.signal,
        setIsLoading,
        setResults,
        filterFromDate: activeFromDate,
        filterToDate: activeToDate,
      });
    },
    [activeFromDate, activeToDate]
  );

  const onSelect = useCallback(
    (result: ApiCategory) => {
      setSelectedOption(result);
      onResult(result);
    },
    [onResult, setSelectedOption]
  );

  const isOptionSelected = useCallback(
    (option: ApiCategory) => option.id === selectedOption?.id,
    [selectedOption]
  );

  return (
    <span
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '0.5rem',
        flex: 'auto',
      }}
    >
      <DropdownInput<ApiCategory>
        disabled={disabled}
        results={results}
        onInput={onInput}
        onSelect={onSelect}
        loading={isLoading}
        selectedOption={selectedOption?.name}
        isOptionSelected={isOptionSelected}
        renderOption={(option: ApiCategory) => (
          <>
            {option.name}
            <br />
            <strong>
              Active date:{' '}
              {dateObjectToIso(new Date(option.activeFromDate), false)}
            </strong>
          </>
        )}
      />
      <Tooltip
        showDelay={500}
        hideDelay={100}
        content={() =>
          'Not applying the filter will display all deals. Apply & leave blank to display deals without shops.'
        }
        color={cssColor('palette-blue')}
        placement="right"
      >
        <FiAlertCircle
          size="2rem"
          style={{ paddingTop: '5px' }}
          color={cssColor('palette-blue')}
        />
      </Tooltip>
    </span>
  );
};

export default ShopSearch;
