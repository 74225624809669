import { html } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { styles } from './css/forwardsBackwards.css.js';
import { iconNames } from '../../svg-icons';
import '../../rps-svg';

export class ForwardsBackwards extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			currentPage: { type: Number },
			pages: { type: Array },
			hideConfirmButton: { type: Boolean },
			hidePages: { type: Boolean },
			cbPageHasChanged: { attribute: false },
			cbFinished: { attribute: false },
			cbPageClick: { attribute: false },
			layout: { type: String },								// 'column' or 'row' Default='row'
		};
	}

	constructor() {
		super()
		this.currentPage = 0;
		this.hideConfirmButton = false;
		this.hidePages = false;
		this.pages = [
			{
				htmlOrText: html`<h5>Start journey</h5>`,
				svg: iconNames.clear
			},
			{ htmlOrText: 'Play videos', svg: iconNames.uploadOutline },
			{ htmlOrText: 'Chess competition' },
			{ htmlOrText: 'Disaster', svg: iconNames.view },
			{ htmlOrText: 'Beach party', svg: iconNames.time },
		];
		this.layout = 'row';
	}

	/**
	 * The amount of items
	 *
	 * @readonly
	 * @memberof ForwardsBackwards
	 */
	get itemCount() {
		return this.pages.length;
	}


	/**
	 * Fired when the page has changed
	 *
	 * @param {Number} currentPage
	 * @memberof ForwardsBackwards
	 */
	pageHasChanged(currentPage) {
		this.currentPage = currentPage;

		const detail = {
			currentPage,
			source: this.tagName,
			id: this.id,
			name: this.name,
		};
		const event = new CustomEvent('pageHasChanged', { detail, bubbles: true, cancelable: true, composed: false });
		this.dispatchEvent(event);

		if (this.cbPageHasChanged) this.cbPageHasChanged(event);
	}


	/**
	 * Fired when a specific page has been clicked on
	 *
	 * @param {Event} event
	 * @memberof ForwardsBackwards
	 */
	pageClick(event) {
		event.stopPropagation();

		const detail = {
			currentPage: this.currentPage,
			source: this.tagName,
			control: e.target,
			id: this.id,
			name: this.name,
		};
		event = new CustomEvent('pageClick', { detail, bubbles: true, cancelable: true, composed: false });
		this.dispatchEvent(event);

		if (this.cbPageClick) this.cbPageClick(event);
	}

	/**
	 * Fired when the previous page has been clicked on
	 *
	 * @param {Event} event
	 * @memberof ForwardsBackwards
	 */
	pagePrevious(event) {
		event.stopPropagation();

		if (this.currentPage > 0) {
			this.pageHasChanged(this.currentPage - 1);
		}
	}

	/**
	 * Fired when all the pages have finished, and this is the "FINAL" button, after the pages
	 *
	 * @param {*} event
	 * @memberof ForwardsBackwards
	 */
	finished(event) {
		event.stopPropagation();

		const detail = { currentPage: this.currentPage + 1, source: this.tagName };
		event = new CustomEvent('finished', { detail, bubbles: true, cancelable: true, composed: false });
		this.dispatchEvent(event);

		if (this.cbFinished) this.cbFinished(event);
	}


	/**
	 * Fired when the next page has been clicked on
	 *
	 * @param {*} event
	 * @memberof ForwardsBackwards
	 */
	pageNext(event) {
		event.stopPropagation();

		if (this.currentPage < this.itemCount - 1) {
			this.pageHasChanged(this.currentPage + 1);
		}
	}


	/**
	 * render the first page(s)
	 *
	 * @return {html} 
	 * @memberof ForwardsBackwards
	 * @private
	 */
	firstPages() {
		const disabled = this.currentPage <= 0 ? true : false;
		const svg = this.layout === "row" ? iconNames.angleLeft : iconNames.angleUp;

		return html`
			<div class="svg-wrapper">
				<rps-svg svg=${svg} ?disabled=${disabled} class="previous ${this.className}" @click=${this.pagePrevious}></rps-svg>
			</div>
		`;
	}


	/**
	 * Create the last pages
	 *
	 * @return {html} 
	 * @memberof ForwardsBackwards
	 * @private
	 */
	lastPages() {
		const svg = this.layout === "row" ? iconNames.angleRight : iconNames.angleDown;
		let image;

		// Last page
		if (this.currentPage >= this.itemCount - 1) {
			if (this.hideConfirmButton) {
				image = html`<rps-svg svg=${svg} disabled class="next ${this.className}"></rps-svg>`;
			} else {
				image = html`<rps-svg svg=${iconNames.checked} class="success ${this.className}" @click=${this.finished}></rps-svg>`;
			}
		} else {
			// normal
			image = html`<rps-svg svg=${svg} class="next ${this.className}" @click=${this.pageNext}></rps-svg>`;
		}

		return html`
			<div class="svg-wrapper">
				${image}
			</div>
		`;
	}

	/**
	 * Create pages between the buttons
	 *
	 * @return {html} 
	 * @memberof ForwardsBackwards
	 * @private
	 */
	displayPage() {
		if (!!this.pages == false
			|| this.hidePages
			|| this.currentPage >= this.itemCount - 1
			|| this.currentPage >= this.pages.length - 1) return;

		const pos = this.currentPage;
		const image = this.pages[pos].svg;
		const svg = image ? html`<rps-svg svg=${image} class="${this.className}"></rps-svg>` : '';
		return html`
			<span class="content-display">
				<div @click=${this.pageClick}>${this.pages[pos].htmlOrText}</div>
				${svg}
			</span>
			`;
	}

	render() {
		return html`
				${this.addCss()}

				<div id="flex-container" class="${this.className} ${this.layout}" style="--direction:${this.layout};">
					${this.firstPages()}
					${this.displayPage()}
					<div class="main-slot">
						<slot></slot>
					</div>
					${this.lastPages()}
				</div>
			`;
	}
}

