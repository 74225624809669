/**
 * @name rps-radio
 * @type {Radio}
 * A Radio group component where the label can be on the left or the right.
 * 
 * @attributes + @properties 						| Changes trigger a re-render
 * Casing usages:
 * 	* Attribute in html (lower case), 
 * 	* Property in JS (Camel Case)
 * 	@property {Boolean}	required 			| is a choice required
 * 	@property {Boolean} 	disabled 			| is this control disabled?
 * 	@property {String}	name 					| form name
 * 	@property {String}	label 				| label to display
 * 	@property {String}	defaultValue 		| default value
 * 	@property {String}	layout = 'column'	| 'column' = under each other, 'row' = next to each other
 * 	@property {Boolean} 	labelOnLeft			| label to display on the left or right of the radio button
 * 	@property {Array}		items					| An array of objects to display, 
 * 															[{label,value}, {label,value}]
 * 	@property {Boolean}	caching 				| does this control use Caching when reading and writing values
 * 	@property {String}	customCss 			| Specify a custom .css file to include, use cssPath to override elements,
 * 															DO NOT override the styling definition by re-defining the css styling
 * 															Put this file into the *window.cssPath* folder @see {link README.md}
 * 															eg: /public/css (where the other css files are)
 * 															Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css					| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 
 * @styling classes		
 * 	help 												| Displays the color in the "help color"
 * 	info												| Displays the color in the "info color"
 * 	success											| Displays the color in the "success color"
 * 	warning											| Displays the color in the "warning color"
 * 	error												| Displays the color in the "error color"
 * 
 * @methods
 * 	clear()											| Clear all the cached value used by this control (or any other control using the same name)
 * 
 * @events
 * 	input												| fires whenever input value is changed
 * 
 * @callbacks
 * 	cbInput											| when the user input changes
 * 
 * @slots
 * 	No slots
 * 
 * 
 * @example @Lit
 * @basic
 * ==============================
 * 	import '@rps-wc/wc/rps-radio';
 * 
 * 	const married = [{ label: "Married", value: "married" }, { label: "Single", value: "single" }];
 * 
 * 	<rps-radio name="radgroup1" 
 * 		items=${JSON.stringify(married)}
 * 		@input=${(e) => console.log('radio-person1', e.target.value)}
 * 		layout="row" 
 * 		label="Marital status"
 * 		defaultvalue="single"
 * 		labelonleft required caching
 * 	></rps-radio>
 * 
 *  
 * @example @ReactJS
 * @basic
 * import '@rps-wc/wc/rps-radio';
 * 
 * const married = [{ label: "Married", value: "married" }, { label: "Single", value: "single" }];
 * 
 *  	useEffect(() => {
 *				input.current.cbInput = (e) => console.log(e);
 *  	}, [])
 * 
 * 
 * return (
 * 	<rps-radio name="radgroup1" 
 * 		ref={input}
 * 		items=${JSON.stringify(married)}
 * 		layout="row" 
 * 		label="Marital status"
 * 		defaultvalue="single"
 * 		labelonleft required caching
 * 	></rps-radio>
 * )
 * 
 * 
*/

import { Radio } from './src/input/Radio';
if (!window.customElements.get('rps-radio'))
	window.customElements.define('rps-radio', Radio);

