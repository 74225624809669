import React, { useCallback, useEffect } from 'react';
import { useCurrentDealSource } from '../../../hooks/useCurrentDealSource';
import { Deal } from 'models/Deal.jsx';
import { DealShipping } from 'models/Shipping.jsx';
import { useAttributeOptions } from '@odo/hooks/attributes';
import { AttributeCode } from '@odo/types/api';
import { isPositiveCurrencyOnChange } from '@odo/utils/validation';
import { handleODOInput } from 'utils/odo-migration/forms';
import { isPositiveNumeric } from './validation'; // Regex validation for RP
// Styles
import {
  RPSSwitchSimple,
  RPSCheckbox,
} from '@rps/web-components/build/react-wrappers';
import { Input, Select, Checkbox } from '@odo/components/elements/form-fields';
import { Flex } from '@odo/components/elements/layout/flex';
import styled from '@odo/lib/styled';

const CheckBox = styled(Checkbox)`
  border-radius: 6px;
  border: 2px solid #c9c9c9;
  background-color: #f6f6f7;
  margin-right: 5px;
  &::after {
    top: 5px;
    left: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
  }
`;

export const ShippingSection = () => {
  const currentDeal = useCurrentDealSource();
  const model = currentDeal.deal.shipping;
  const handleInput = currentDeal.createInputHandler(Deal.MODELS.SHIPPING);

  const supplierRepacksOptions = useAttributeOptions(
    AttributeCode.supplierRepacks
  );
  const customerDeliveryTimeOptions = useAttributeOptions(
    AttributeCode.customerDeliveryTime
  );

  const handleApplyShippingInput = ev => {
    currentDeal.deal.set(
      Deal.MODELS.SHIPPING,
      DealShipping.FIELDS.APPLY_SHIPPING,
      ev.detail.checked
    );
    currentDeal.update();
  };

  useEffect(() => {
    if (!model.isShippingApplied) {
      currentDeal.deal.set(
        Deal.MODELS.SHIPPING,
        DealShipping.FIELDS.SHIPPING_COST,
        null
      );
    }
  }, [currentDeal, currentDeal.deal, model.isShippingApplied]);

  const onCustomerDeliveryTimeChange = useCallback(
    ev => {
      // find the correct attribute as provided by the API
      const selected = customerDeliveryTimeOptions.find(
        c => c.key === ev.target.value
      );

      if (selected) {
        // extra logic based on metadata
        const requireShippedIndividually =
          (selected.metadata || []).find(
            ({ key }) => key === 'require_shipped_individually'
          )?.value || '0';

        if (requireShippedIndividually === '1') {
          currentDeal.deal.set(
            Deal.MODELS.SHIPPING,
            DealShipping.FIELDS.INDIVIDUAL_SHIPPING,
            true
          );
        } else {
          currentDeal.deal.set(
            Deal.MODELS.SHIPPING,
            DealShipping.FIELDS.INDIVIDUAL_SHIPPING,
            false
          );
        }

        currentDeal.deal.set(
          Deal.MODELS.SHIPPING,
          DealShipping.FIELDS.CUSTOMER_DELIVERY_TIME,
          selected.key
        );

        currentDeal.update();
      }
    },
    [currentDeal, customerDeliveryTimeOptions]
  );

  return (
    <section>
      <h5>Shipping</h5>
      <div style={{ marginBottom: '0.7rem' }}>
        <RPSSwitchSimple
          label="Does shipping apply?"
          checked={model.isShippingApplied}
          cbInput={handleApplyShippingInput}
        />
      </div>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '2rem',
        }}
      >
        <Input
          label="Length (cm)"
          value={model.length?.toString()}
          onChange={e =>
            isPositiveNumeric(e.target.value) &&
            handleODOInput({
              currentDeal,
              model: Deal.MODELS.SHIPPING,
              field: DealShipping.FIELDS.LENGTH,
            })(e)
          }
          inputMode="decimal"
          min={0}
          max={Number.MAX_SAFE_INTEGER}
          step={0.01}
          required
          selectOnFocus
          matchRPStyles
        />

        <Input
          label="Width (cm)"
          value={model.width?.toString()}
          onChange={e =>
            isPositiveNumeric(e.target.value) &&
            handleODOInput({
              currentDeal,
              model: Deal.MODELS.SHIPPING,
              field: DealShipping.FIELDS.WIDTH,
            })(e)
          }
          inputMode="decimal"
          min={0}
          max={Number.MAX_SAFE_INTEGER}
          step={0.01}
          required
          selectOnFocus
          matchRPStyles
        />

        <Input
          label="Height (cm)"
          value={model.height?.toString()}
          onChange={e =>
            isPositiveNumeric(e.target.value) &&
            handleODOInput({
              currentDeal,
              model: Deal.MODELS.SHIPPING,
              field: DealShipping.FIELDS.HEIGHT,
            })(e)
          }
          inputMode="decimal"
          min={0}
          max={Number.MAX_SAFE_INTEGER}
          step={0.01}
          required
          selectOnFocus
          matchRPStyles
        />
      </div>

      <div style={{ display: 'grid', gap: '2rem', marginTop: '2rem' }}>
        <Flex
          flexDirection="column"
          justifyContent="start"
          gap="2rem"
          width="fit-content"
        >
          <CheckBox
            color="#8bcae7"
            size={31}
            label="Must be shipped individually?"
            checked={model.isShippedIndividually}
            onChange={ev => {
              currentDeal.deal.set(
                Deal.MODELS.SHIPPING,
                DealShipping.FIELDS.INDIVIDUAL_SHIPPING,
                ev.target.checked
              );
              currentDeal.update();
            }}
          />

          <RPSCheckbox
            label="Supplier delivers?"
            checked={model.isDeliveredBySupplier}
            cbInput={handleInput(DealShipping.FIELDS.SUPPLIER_DELIVERS)}
          />
        </Flex>
        <Select
          label="Supplier repacks?"
          value={model.supplierRepacks}
          onChange={handleODOInput({
            currentDeal,
            model: Deal.MODELS.SHIPPING,
            field: DealShipping.FIELDS.SUPPLIER_REPACKS,
          })}
          options={[
            { id: '', value: '', label: 'Please select...' },
            ...supplierRepacksOptions
              .filter(option => option.value.toLowerCase() !== 'none')
              .map(option => ({
                id: option.value,
                value: option.value,
                label: option.label,
              })),
          ]}
          required
          matchRPStyles
        />

        <Select
          label="Customer Delivery Time"
          value={model.customerDeliveryTime}
          onChange={onCustomerDeliveryTimeChange}
          options={[
            /**
             * NOTE: we don't want to allow users to clear this input (biz reason...)
             * but we do want to show the empty option if nothing is selected.
             * so we'll conditionally add the empty option when needed.
             */
            ...(!model.customerDeliveryTime
              ? [{ id: '', value: '', label: 'Please select...' }]
              : []),
            ...customerDeliveryTimeOptions.map(option => ({
              id: option.value,
              value: option.value,
              label: option.label,
            })),
          ]}
          matchRPStyles
        />

        <Input
          label="Manual Shipping Cost (Optional, VAT Inclusive)"
          value={!model.isShippingApplied ? '' : model.shippingCost}
          disabled={!model.isShippingApplied}
          onChange={e =>
            isPositiveCurrencyOnChange(e.target.value) &&
            handleODOInput({
              currentDeal,
              model: Deal.MODELS.SHIPPING,
              field: DealShipping.FIELDS.SHIPPING_COST,
            })(e)
          }
          withPrefix
          selectOnFocus
          matchRPStyles
        />
      </div>
    </section>
  );
};
