import React, { createContext, useEffect, useState } from 'react';
import { Deal } from 'models/Deal.jsx';
import { DataSource } from '@rps/js-utils/datasource';
import PropTypes from 'prop-types';
import { GET_ODO_PRODUCT, GET_DEAL_BY_SKU } from './../gql/deals/getDeal';
import { debounce } from './../utils/debounce';
import { ApolloClients } from './../services/apolloClients';
import { GET_PRODUCT_CATEGORIES } from './../gql/deals/getCategories';
import { REMOVE_PRODUCT_CATEGORIES } from './../gql/deals/removeProductCategories';
import { ADD_PRODUCT_CATEGORIES } from './../gql/deals/addProductCategories';
import { GET_PRODUCT_INVENTORY } from 'gql/deals/getProductInventory';
import { UPDATE_INVENTORY_ITEM } from 'gql/deals/updateInventory';
import { Cache as ODOCache } from '@odo/services/data-source';
import odoUuid from '@odo/utils/uuid';

const initialDataSource = new DataSource({
  deal: new Deal(),
  dealList: [new Deal()],
});

const MAX_LOCAL_DEALS = 50;

const createRecentDealEntry = deal => ({
  id: deal.id || deal.meta?.id,
  brand: deal.product.brand,
  name: deal.product.name,
  image: (deal.imagesAndVideos.images || []).find(({ imageTypes }) =>
    imageTypes.includes('THUMBNAIL')
  )?.url,
});

export const CurrentDealContext = createContext();

export const CurrentDealProvider = ({ children }) => {
  const ROOT_DEAL = 0;

  const dataSource = initialDataSource;
  const [currentDeal, setCurrentDeal] = useState(
    dataSource.state.deal || new Deal()
  );
  const [currentDealList, setCurrentDealList] = useState(
    dataSource.state.dealList
  );
  const [busyUploading, setBusyUploading] = useState(false);
  const [recentDealList, setRecentDealList] = useState([]);

  // In-memory var for holding deals not in the working set, i.e.: For duplicating a deal
  const [tempDeal, setTempDeal] = useState(new Deal());

  // Fetch initial data from cache if it exists
  useEffect(() => {
    const handleNotify = newState => {
      setCurrentDeal(newState.deal);
      setCurrentDealList(newState.dealList);
    };

    const _loadCachedData = async () => {
      const cache = new ODOCache();
      await cache.configure('localStorage');
      const result = await cache.get('local-deal-list');
      const recentDeals = await cache.get('recent-deal-list');
      if (result) {
        const newList = JSON.parse(result)
          .filter(deal => !deal.meta.id)
          .map(Deal.fromInternalDeal)
          // NOTE: we need this tmpId for deal drafts to connect custom options
          // but not all usages of fromInternalDeal should generate it
          .map(deal => {
            if (!deal.meta.tmpId) {
              deal.meta.tmpId = odoUuid();
            }
            return deal;
          });

        dataSource.publish({ dealList: newList, deal: newList[ROOT_DEAL] });
      } else {
        dataSource.publish({ dealList: [new Deal()] });
      }

      if (recentDeals) {
        const newRecentDealList = JSON.parse(recentDeals);
        setRecentDealList(newRecentDealList);
      }
    };

    _loadCachedData();

    dataSource.subscribe(handleNotify);

    return () => {
      dataSource.unsubscribe(handleNotify);
    };
  }, [dataSource]);

  /**
   * @internal
   * Saves current working deals to cache
   */
  const _saveDealsToCache = debounce(async () => {
    console.debug('[CurrentDealContext] Saving deals to cache.');
    const cache = new ODOCache();
    await cache.configure('localStorage');
    await cache.set(
      'local-deal-list',
      JSON.stringify(
        dataSource.state.dealList
          .filter(deal => !deal.meta.id)
          .slice(0, MAX_LOCAL_DEALS)
      )
    );
  }, 500);

  const _saveDealsToCacheImmediate = async () => {
    console.debug('[CurrentDealContext] Saving deals to cache (immediate).');
    const cache = new ODOCache();
    await cache.configure('localStorage');
    await cache.set(
      'local-deal-list',
      JSON.stringify(
        dataSource.state.dealList
          .filter(deal => !deal.meta.id)
          .slice(0, MAX_LOCAL_DEALS)
      )
    );
  };

  /**
   * @async
   * Saves recent deal list
   */
  const _addToRecentDealsList = async deal => {
    console.debug('[CurrentDealContext] Updating recent deals list.');

    let newRecentDeals = recentDealList.filter(
      existing => existing.id != deal.id
    );
    newRecentDeals.unshift(createRecentDealEntry(deal));
    newRecentDeals = newRecentDeals.slice(0, 12);
    setRecentDealList(newRecentDeals);

    const cache = new ODOCache();
    await cache.configure('localStorage');
    await cache.set('recent-deal-list', JSON.stringify(newRecentDeals));
  };

  /**
   * Saves the current deal back to the cache.
   * @example:
   * ```js
   * const currentDeal = useCurrentDealSource();
   *
   * // Perform some modification(s) on the deal:
   * currentDeal.deal.product.name = "New Name";
   *
   * // Persist changes
   * currentDeal.update(currentDeal.deal);
   * ```
   * @param {Deal} newState
   */
  const update = async opt => {
    dataSource.state.dealList[0] = new Deal(currentDeal);
    dataSource.publish({
      dealList: [...dataSource.state.dealList],
      deal: dataSource.state.dealList[ROOT_DEAL],
    });
    if (opt?.immediateCache) {
      await _saveDealsToCacheImmediate();
    } else {
      _saveDealsToCache();
    }
  };

  /**
   * Create a new Deal on the stack. And ignore/remove all previous versions.
   *
   * @param {Deal} existing
   */
  const newDealComplete = async (existing = undefined, newId = false) => {
    // if the deal is already in our list remove it
    if (existing && existing.meta.id) {
      const existingDealIndex = dataSource.state.dealList.findIndex(
        deal => deal.meta.id == existing.meta.id
      );
      if (existingDealIndex >= 0) {
        removeDeal(existingDealIndex);
      }
    }

    const newDeal = new Deal(existing);
    if (newId) {
      newDeal.id = undefined;
      if (newDeal.meta) newDeal.meta.id = undefined;
    }
    dataSource.state.dealList.unshift(newDeal);
    dataSource.publish({
      dealList: [...dataSource.state.dealList],
      deal: dataSource.state.dealList[ROOT_DEAL],
    });

    await _saveDealsToCache();
  };

  /**
   * Create a new Deal on the stack.
   *
   * If you pass an existing Deal, it copies it into the stack, otherwise it creates a new one.
   *
   * @param {Deal} existing
   */
  const newDeal = async (existing = undefined, newId = false) => {
    // Don't re-create existing deal on the stack if it's already loaded into the editor.
    if (existing && !newId) {
      const existingDealIndex = dataSource.state.dealList.findIndex(
        deal => deal.meta.id == existing.meta.id
      );
      if (existingDealIndex >= 0) {
        selectDeal(existingDealIndex);
        return;
      }
    }

    const newDeal = new Deal(existing);
    if (newId) {
      newDeal.id = undefined;
      if (newDeal.meta) newDeal.meta.id = undefined;
    }
    dataSource.state.dealList.unshift(newDeal);
    dataSource.publish({
      dealList: [...dataSource.state.dealList],
      deal: dataSource.state.dealList[ROOT_DEAL],
    });

    await _saveDealsToCache();
  };

  /**
   * Switch the currently active deal.
   *
   * @param {Number} index
   */
  const selectDeal = index => {
    if (index > dataSource.state.dealList.length - 1) {
      return;
    }

    const targetDeal = dataSource.state.dealList.splice(index, 1);
    dataSource.state.dealList.unshift(...targetDeal);

    dataSource.publish({
      dealList: [...dataSource.state.dealList],
      deal: dataSource.state.dealList[ROOT_DEAL],
    });
    _saveDealsToCache();
  };

  /**
   * Remove a single deal from the list.
   *
   * @param {Number} index - Current index in the list to remove
   */
  const removeDeal = index => {
    dataSource.state.dealList.splice(index, 1);

    if (dataSource.state.dealList.length === 0) {
      const newDeal = new Deal();
      dataSource.state.dealList.unshift(newDeal);
    }

    dataSource.publish({
      dealList: [...dataSource.state.dealList],
      deal: dataSource.state.dealList[ROOT_DEAL],
    });
    _saveDealsToCache();
  };

  /**
   * Remove multiple deals from the list.
   *
   * @param {Number[]} indexes - array of indexes to remove from the list
   */
  const removeDeals = indexes => {
    dataSource.state.dealList = dataSource.state.dealList.filter(
      (deal, index) => !indexes.includes(index)
    );

    if (dataSource.state.dealList.length === 0) {
      const newDeal = new Deal();
      dataSource.state.dealList.unshift(newDeal);
    }

    dataSource.publish({
      dealList: [...dataSource.state.dealList],
      deal: dataSource.state.dealList[ROOT_DEAL],
    });
    _saveDealsToCache();
  };

  const selectTempDeal = deal => {
    setTempDeal(deal);
  };

  /**
   * Returns true if the sku is already in use by another productId
   * @async
   * @param {String} sku - sku to check
   * @param {String} [productId] - current productId
   * @return {Promise<Boolean>} result of check
   */
  const isSKUInUse = async (sku, productId) => {
    // an empty sku means this sku is not "in use", handle other validation downstream.
    if (!sku) return false;

    const client = new ApolloClients().odo;
    const res = await client.query({
      query: GET_DEAL_BY_SKU,
      variables: {
        sku: sku,
      },
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only',
    });

    if (
      (Array.isArray(res.data.getProducts) &&
        res.data.getProducts?.length === 0) ||
      res.data.getProducts[0].id == productId
    ) {
      return false;
    }
    return true;
  };

  /**
   * Fetch categories for a given productId
   *
   * @param {String} productId
   * @return {Promise<Object[]>} - Array of categories
   */
  const fetchDealCategories = async productId => {
    const client = new ApolloClients().odo;
    try {
      const { data } = await client.query({
        query: GET_PRODUCT_CATEGORIES,
        variables: {
          productId: productId.toString(),
        },
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
      });

      if (data?.getProductCategories) {
        return data.getProductCategories.map(x => x.categoryId);
      } else {
        return [];
      }
    } catch (e) {
      console.error(
        `[CurrentDealProvider:fetchDealCategories] Error while fetching categories for Product with ID ${productId}. Details: `,
        e
      );
      return [];
    }
  };

  const removeCategoriesFromProduct = async (productId, categoryIds) => {
    const client = new ApolloClients().odo;
    if (categoryIds.length === 0) {
      return true;
    }
    try {
      const { data } = await client.mutate({
        mutation: REMOVE_PRODUCT_CATEGORIES,
        variables: {
          productId,
          categories: categoryIds,
        },
        errorPolicy: 'ignore',
      });

      if (data?.errors) {
        console.error(
          `[CurrentDealProvider:removeCategoriesFromProduct] Error removing categories ${JSON.stringify(
            categoryIds
          )} from Product with ID ${productId}. Details: `,
          data.errors
        );
        return false;
      } else {
        return true;
      }
    } catch (e) {
      console.error(
        `[CurrentDealProvider:removeCategoriesFromProduct] Error removing categories ${JSON.stringify(
          categoryIds
        )} from Product with ID ${productId}. Details: `,
        e
      );
    }
  };

  const addCategoriesToProduct = async (productId, categoryIds) => {
    const client = new ApolloClients().odo;
    if (categoryIds.length === 0) {
      return true;
    }
    try {
      const { data } = await client.mutate({
        mutation: ADD_PRODUCT_CATEGORIES,
        variables: {
          productId,
          categories: categoryIds,
        },
      });

      if (data?.errors) {
        console.error(
          `[CurrentDealProvider:addCategoriesToProduct] Error while adding categories ${JSON.stringify(
            categoryIds
          )} for Product with ID ${productId}. Details:`,
          data.errors
        );
        return false;
      } else {
        return true;
      }
    } catch (e) {
      console.error(
        `[CurrentDealProvider:addCategoriesToProduct] Error adding categories ${JSON.stringify(
          categoryIds
        )} to Product with ID ${productId}. Details: `,
        e
      );
    }
  };

  const saveOrUpdateDealCategories = async (categories, productId) => {
    try {
      const existingCategories = await fetchDealCategories(productId);
      // First remove categories as necessary
      if (Array.isArray(existingCategories)) {
        const categoriesToRemove = [];
        existingCategories.forEach(category => {
          if (!categories.find(x => x == category)) {
            categoriesToRemove.push(category);
          }
        });

        await removeCategoriesFromProduct(productId, categoriesToRemove);
      }

      const categoriesToAdd = [];
      categories.forEach(category => {
        if (!existingCategories.find(x => x == category)) {
          categoriesToAdd.push(category);
        }
      });

      await addCategoriesToProduct(productId, categoriesToAdd);
    } catch (e) {
      console.error(
        `[currentDealProvider:saveOrUpdateDealCategories] Error saving/updating deal categories ${JSON.stringify(
          categories
        )} for Product with ID ${productId}. Details: `,
        e
      );
    }
  };

  const fetchDeal = async dealId => {
    const client = new ApolloClients().odo;
    const res = await client.query({
      query: GET_ODO_PRODUCT,
      variables: {
        productId: dealId,
      },
      errorPolicy: 'none',
    });
    if (res?.data?.getProduct) {
      return res.data.getProduct;
    } else {
      console.warn(`Failed to load deal with id: ${dealId}`);
    }
  };

  const fetchDealInventoryId = async productId => {
    const client = new ApolloClients().odo;
    const { data } = await client.query({
      query: GET_PRODUCT_INVENTORY,
      variables: {
        productId: productId,
      },
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only',
    });

    if (data?.getInventoryItems?.length > 0) {
      const stockId = data.getInventoryItems[0].id;
      return stockId;
    } else {
      return undefined;
    }
  };

  const fetchDealInventoryToDeal = async (productId, targetDeal) => {
    const client = new ApolloClients().odo;
    const { data } = await client.query({
      query: GET_PRODUCT_INVENTORY,
      variables: {
        productId: productId,
      },
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only',
    });

    if (data?.getInventoryItems?.length > 0) {
      const inventory = data.getInventoryItems[0];
      targetDeal.shipping.setMany({
        ...targetDeal.shipping._properties,
        ...inventory,
        ...{ maxSaleQuantity: inventory.maximumSaleQuantity },
      });
    }
  };

  const updateDealInventory = async (productId, inventory) => {
    const client = new ApolloClients().odo;
    const stockId = await fetchDealInventoryId(productId);
    await client.mutate({
      mutation: UPDATE_INVENTORY_ITEM,
      variables: {
        stockId,
        inventoryItem: {
          ...inventory,
        },
      },
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only',
    });
  };

  const createInputHandler = model => {
    return (field, filterRegex) => ev => {
      if (ev.detail.source === 'RPS-BUTTON-STRIP') {
        currentDeal.set(model, field, ev.detail.buttonId);
      } else if (
        ev.detail.source === 'RPS-CHECKBOX' ||
        ev.detail.source === 'RPS-SWITCH'
      ) {
        currentDeal.set(model, field, ev.detail.checked);
      } else {
        let value = ev.detail.value || ev.detail.checked || '';
        if (filterRegex) {
          value = value.replace(filterRegex, '');
        }
        currentDeal.set(model, field, value);
      }
      update();
    };
  };

  const contextState = {
    /** @type {Deal} */
    deal: currentDeal,
    /** @type {Deal[]} */
    dealList: currentDealList,
    removeDeal,
    removeDeals,
    selectDeal,
    newDeal,
    newDealComplete,
    update,
    /** @type {Deal} */
    tempDeal,
    selectTempDeal,
    createInputHandler,
    uploadBusy: busyUploading,
    setBusyUploading,
    _addToRecentDealsList,
    recentDealList,
    isSKUInUse,
    fetchDeal,
    fetchDealCategories,
    saveOrUpdateDealCategories,
    fetchDealInventoryToDeal,
    updateDealInventory,
  };

  return (
    <CurrentDealContext.Provider value={contextState}>
      {children}
    </CurrentDealContext.Provider>
  );
};

CurrentDealProvider.propTypes = {
  children: PropTypes.any,
};
