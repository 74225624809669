/**
 * @name rps-modal
 * @type {Modal}
 * A modal that can display in its parent or as a popup
 * @description The attribute "popup" determines if this is a popup modal or inline
 *
 *
 * @attributes + @properties 					| Changes trigger a re-render
 * Casing usages:									| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {Boolean} 	opened			| Must this modal display? default=false
 * 	@property {Boolean} 	popup				| Popup the modal. ie: outside its parent default=false
 * 	@property {Boolean}	allowNoConfirm	| Allow an 'Enter key' to confirm, and allow 'Escape key' to cancel for modal? (No clicking, keyboard driven)
 * 	@property {String} 	headerText		| Header text for the modal
 * 	@property {String} 	confirmText		| Confirm button text
 * 	@property {String} 	busyText			| text to display after the confirm button has been pressed
 * 	@property {String} 	customCss		| Specify a custom .css file to include, use cssPath to override elements,
 * 														DO NOT override the styling definition by re-defining the css styling
 * 														Put this file into the *window.cssPath* folder @see {link README.md}
 * 														eg: /public/css (where the other css files are)
 * 														Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css				| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 *
 * @styling
 * Status:
 * 	help									 		| Displays the background color in the "help color"
 * 	info											| Displays the background color in the "info color"
 * 	success										| Displays the background color in the "success color"
 * 	warning										| Displays the background color in the "warning color"
 * 	error											| Displays the background color in the "error color"
 * @size
 * 	half											| Display the modal using "half" width of the screen
 * 	full											| Display the modal using "full" width of the screen
 * 	
 *  PLEASE NOTE: The sizes below don't follow the standard, since the normal small medium & large influences the button size too which is undesired behavior.
 *	sm												| Display modal at max-width 320px
 *	md												| Display modal at max-width 640px
 *	lg												| Display modal at max-width 1000px
 *
 * @methods
 * 	hide											| Hide the Modal
 * 	show											| Display the modal
 *
 * @properties (JS Only)
 * 	get standardButtonsControl				| The <rps-standard-buttons> control reference
 * 	get svgControl								| The <rps-svg> control reference
 *
 * @events
 * 	confirm										| fires when the user clicks on the "confirm" button
 * 	cancel										| fires when the user clicks on the "cancel" button
 *
 * @callbacks
 * 	cbConfirm									| Callback for the confirm event
 * 	cbCancel										| Callback for the Cancel (close) event
 *
 *
 * @slots
 * 	header
 * 	default (ie: the children elements other than slotted content )
 * 	footer
 *
*/
import { Modal } from './src/modal/Modal';
if (!window.customElements.get('rps-modal'))
	window.customElements.define('rps-modal', Modal);

