import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RPSModal } from '@rps/web-components/build/react-wrappers';

export const GQLErrorPopup = ({ error }) => {
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (error) {
      setOpened(true);
    }
  }, [error]);

  if (!error) {
    return <></>;
  }
  return (
    <>
      <RPSModal opened={opened} popup className="error">
        <div slot="header">
          <h5>GraphQL Errors</h5>
          <em>Message: {error.message}</em>
          <ul>
            {error.graphQLErrors.length > 0 &&
              error.graphQLErrors.map((err, index) => {
                if (Array.isArray(err)) {
                  return err.map((e, index) => (
                    <li key={index}>
                      [{e.status}|{e.code}]: {e.message}
                    </li>
                  ));
                } else {
                  return (
                    <li key={index}>
                      [{err.status}|{err.code}]: {JSON.stringify(err.message)}
                    </li>
                  );
                }
              })}
            {error.clientErrors.length > 0 &&
              error.clientErrors.map((err, index) => {
                return (
                  <li key={index}>
                    [{err.status}|{err.code}]: {err.message}
                  </li>
                );
              })}
            {error.networkError?.result?.errors?.length > 0 &&
              error.networkError.result.errors.map((err, index) => {
                console.error(`GraphQL Network Error: ${err}`);
                return <li key={index}>[NetworkError]: {err.message}</li>;
              })}

            {error.message && <li>{error.message}</li>}
          </ul>
        </div>
      </RPSModal>
    </>
  );
};

export const ShowGraphQLErrorPopup = modalContext => error => {
  modalContext.open(<GQLErrorPopup error={error} />);
};

GQLErrorPopup.propTypes = {
  error: PropTypes.any,
};
