import type { GridProduct } from '@odo/components/search/types';
import { Flex } from '@odo/components/elements/layout';
import { Text } from '../grid';

const Dates = ({ data }: { data: GridProduct }) => {
  return (
    <Flex flexDirection="column" rowGap="5px">
      <Text style={{ fontWeight: 'bold' }}>Active From:</Text>
      <Text>{data.activeFromDate}</Text>
      <Text style={{ fontWeight: 'bold' }}>Active To:</Text>
      <Text>{data.activeToDate}</Text>
    </Flex>
  );
};

export default Dates;
