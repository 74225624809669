import { cssColor } from '@odo/utils/css-color';
import { Flex } from '@odo/components/elements/layout/flex';
import { Heading } from '@odo/components/elements/typography';
// custom icons stolen from elsewhere coz FA icons weren't cutting it
import { ReactComponent as IconExclamation } from '@odo/assets/svg/exclamation-circle.svg';
import { ReactComponent as IconValid } from '@odo/assets/svg/check-circle.svg';
import type { Validity } from '@odo/screens/deal/editor/helpers';
import type { Validator } from '@odo/screens/deal/editor/types';
import { Status } from '@odo/screens/deal/editor/types';
import styled from '@odo/lib/styled';

const MessageContainer = styled(Flex)<{ lineColor: string }>`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background: ${props => props.lineColor};
    border-radius: 10px;
  }
`;

MessageContainer.defaultProps = {
  gap: 2,
  pl: 2,
  flexDirection: 'column',
};

const Messages = ({
  label,
  messages,
  color,
}: {
  label: string;
  messages: Validator['message'][];
  color: string;
}) => (
  <MessageContainer lineColor={color}>
    <Flex pl="2px" pr={2} pb={1} gap={2} alignItems="center">
      <IconExclamation color={color} width={16} height={16} />
      <Heading fontWeight={800} fontSize={[1, 2]} color={color}>
        {label}
      </Heading>
    </Flex>

    <ul
      style={{
        paddingLeft: '24px',
        paddingRight: '4px',
        margin: '0',
      }}
    >
      {messages.map((message, idx) => (
        <li key={idx} style={{ lineHeight: '1.6em', color: cssColor('text') }}>
          {message}
        </li>
      ))}
    </ul>
  </MessageContainer>
);

const StatusMessages = ({ validity }: { validity: Validity | undefined }) => {
  if (!validity?.status) return null;

  const { status, errors, warnings } = validity;

  return (
    <>
      {status === Status.valid && (
        <Flex pl="2px" pr={2} gap={2} alignItems="center">
          <IconValid
            color={cssColor('palette-turquoise')}
            width={16}
            height={16}
          />
          <Heading
            fontWeight={800}
            fontSize={[1, 2]}
            color={cssColor('palette-turquoise')}
          >
            No issues
          </Heading>
        </Flex>
      )}

      {errors.length > 0 && (
        <Messages
          label="Errors"
          messages={errors}
          color={cssColor('palette-pink')}
        />
      )}

      {warnings.length > 0 && (
        <Messages
          label="Warnings"
          messages={warnings}
          color={cssColor('palette-yellow')}
        />
      )}
    </>
  );
};

export default StatusMessages;
