import toast from 'react-hot-toast';
import { notification } from '@odo/utils/toast';
import type { ApiLog } from './types';
/**
 * NOTE: I hate importing untyped stuff like this.
 * but it would be worse to duplicate the data.
 * replace later with a typed implementation.
 */
import metadata from 'buildData.json';
import { version } from '../../../config';
import { dateObjectToIso } from '@odo/utils/date';
import { getUserInfo } from '@odo/utils/user';

const buildVersion = `${metadata.majorVersion}.${metadata.minorVersion}.${metadata.bugfix}/c${metadata.commitRevision}`;

const CACHE_KEY = 'api-logs';
const CACHE_VERSION = 1.0;
const MAX_ENTRIES = 50;

interface ApiLogsCache {
  v: number;
  logs: ApiLog[];
}

const isValidApiLog = (apiLog: ApiLog | unknown): apiLog is ApiLog =>
  typeof (apiLog as ApiLog).id === 'string' &&
  typeof (apiLog as ApiLog).meta === 'object' &&
  typeof (apiLog as ApiLog).meta.startTime === 'number' &&
  typeof (apiLog as ApiLog).meta.kind === 'string' &&
  typeof (apiLog as ApiLog).meta.url === 'string' &&
  typeof (apiLog as ApiLog).request === 'object' &&
  typeof (apiLog as ApiLog).request.variables === 'object' &&
  typeof (apiLog as ApiLog).request.query === 'object' &&
  // optional fields
  ['undefined', 'number'].includes(typeof (apiLog as ApiLog).meta.endTime) &&
  ['undefined', 'number'].includes(typeof (apiLog as ApiLog).meta.execTime) &&
  // string | null checks require more effort coz typeof null !== 'null' (and it never will apparently)
  (typeof (apiLog as ApiLog).meta.name === 'string' ||
    (apiLog as ApiLog).meta.name === null) &&
  (typeof (apiLog as ApiLog).request.query.name === 'string' ||
    (apiLog as ApiLog).request.query.name === null) &&
  (typeof (apiLog as ApiLog).request.query.body === 'string' ||
    (apiLog as ApiLog).request.query.body === null);

const isValidApiLogsCache = (
  cache: ApiLogsCache | unknown
): cache is ApiLogsCache =>
  typeof (cache as ApiLogsCache).v === 'number' &&
  Array.isArray((cache as ApiLogsCache).logs) &&
  (cache as ApiLogsCache).logs.every(isValidApiLog);

const getCache = () => {
  try {
    const cached = window.localStorage.getItem(CACHE_KEY);
    if (cached) {
      const parsed: unknown = JSON.parse(cached);
      // ensure logs are valid and from the current version
      // TODO: later we can consider having migrations scripts that we run if the version is outdated
      if (
        isValidApiLogsCache(parsed) &&
        Math.floor(parsed.v) === Math.floor(CACHE_VERSION)
      ) {
        return parsed;
      } else {
        toast.error('Cached logs are no longer valid');
        window.localStorage.removeItem(CACHE_KEY);
      }
    }
  } catch (e) {
    console.log('Error while loading logs');
    console.error(e);
  }
};

const setCache = (cache: ApiLogsCache) => {
  try {
    // limit the number of entries before storing
    cache.logs = cache.logs.slice(-1 * MAX_ENTRIES);

    const stringified = JSON.stringify(cache);
    if (stringified) {
      window.localStorage.setItem(CACHE_KEY, stringified);
    }
  } catch (e) {
    console.error('Failed to stringify cache');
  }
};

export const addApiLog = async (apiLog: ApiLog) => {
  const cache = getCache();
  if (cache) {
    cache.logs.push(apiLog);
    setCache(cache);
  } else {
    setCache({
      v: CACHE_VERSION,
      logs: [apiLog],
    });
  }
};

export const addApiLogResult = async ({
  id,
  result,
  endTime,
}: {
  id: string;
  result: unknown;
  endTime: number;
}) => {
  const cache = getCache();
  if (cache) {
    const apiLog = cache.logs.find(log => log.id === id);
    if (apiLog) {
      apiLog.meta.endTime = endTime;
      apiLog.meta.endTimeLocal = dateObjectToIso(new Date(endTime), true);
      apiLog.meta.execTime = endTime - apiLog.meta.startTime;
      apiLog.result = result;
      setCache(cache);
    }
  }
};

const pad = (number: number, length = 2) =>
  number.toString().padStart(length, '0');

const dateObjectToIsoFilename = (date: Date, withTime = false) => {
  if (!(date instanceof Date)) {
    return '';
  }

  let dateString = `${date.getFullYear()}${pad(date.getMonth() + 1)}${pad(
    date.getDate()
  )}`;

  if (withTime) {
    dateString += `T${pad(date.getHours())}${pad(date.getMinutes())}${pad(
      date.getSeconds()
    )}`;
  }

  return dateString;
};

export const exportApiLogs = async () => {
  const currentUser = getUserInfo();
  const cache = getCache();
  if (cache) {
    const data = {
      meta: {
        exportedAt: Date.now(),
        exportedAtLocal: dateObjectToIso(new Date(), true),
        buildVersion,
        environment: version,
        user: currentUser ? { ...currentUser } : {},
      },
      logs: cache.logs,
    };
    const json = JSON.stringify(data, null, 2);
    const blob = new Blob([json], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `api-logs-${dateObjectToIsoFilename(new Date(), true)}.json`;
    a.click();
  } else {
    notification('No logs to export');
  }
};
