import { html } from 'lit';
import { ref } from 'lit/directives/ref.js';
import { BaseInputControl } from '../baseClasses/BaseInputControl';
import { iconNames } from '../../svg-icons';
import '../../rps-svg';
import '../../rps-input-label'; // REMOVE
import '../../rps-chip';
import { styles } from './css/input.css.js';

export class Input extends BaseInputControl {
	static styles = styles;

	static get properties() {
		return {
			min: { type: Number },
			max: { type: Number },
			minLength: { type: Number },
			maxLength: { type: Number },
			type: { type: String },
			culture: { type: String },
			currency: { type: String },
			cbIconClick: { attribute: false },
			accept: { type: String },
			fileReaderType: { type: String },
			filesData: { attribute: false },
			selectOnFocus: { type: Boolean }
		};
	}

	constructor() {
		super();
		this.min = 0;
		this.max = 30;
		this.minLength = 0;
		this.maxLength = undefined;
		this.type = "text";
		this.culture = 'en-ZA';
		this.currency = 'ZAR';
		this.accept = '';					// Used when the type='file'
		this.fileReaderType = 'readAsDataURL'; // possible values are: ['readAsDataURL', 'readAsArrayBuffer', 'readAsBinaryString', 'readAsText']
		this.filesData = [];
		this.selectOnFocus = false;
	}

	/**
	 * Get the file data when reading in files
	 *
	 * @param {event} event
	 * @return {*}
	 * @memberof Input
	 */
	async getFileData(event) {
		if (!event.target?.files) return;

		const reader = new FileReader();
		const me = this;

		reader.onload = function (e) {
			me.filesData.push(e.target.result);
		};

		const files = event.target.files;
		if (files) {
			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				if (this.fileReaderType === 'readAsDataURL') {
					await reader.readAsDataURL(file);
				} else if (this.fileReaderType === 'readAsArrayBuffer') {
					await reader.readAsArrayBuffer(file);
				} else if (this.fileReaderType === 'readAsBinaryString') {
					await reader.readAsBinaryString(file);
				} else if (this.fileReaderType === 'readAsText') {
					await reader.readAsText(file);
				}
			}
		}
	}



	/**
	 * return the Currency symbol for the culture and currency
	 *
	 * @return {String} Currency symbol
	 * @memberof Input
	 */
	currencySymbol() {
		const curr = new Intl.NumberFormat(this.culture, {
			style: 'currency',
			currency: this.currency
		});
		const money = curr.format(0);
		const index = money.indexOf("0");
		if (index < 0) {
			console.warn(`Input:currencySymbol, failed to extract currency for culture[${this.culture}] and currency [${this.currency}]`);
			return '';
		}

		return money.substring(0, index - 1);
	}

	/**
	 * Validate this control and display error message / color if invalid
	 *
	 * @param {Event} e - the Event that fired this method
	 * @return {Boolean} - Is this control valid
	 * @memberof Input
	 */
	validate(e) {
		const isValid = super.validate();
		this.labelText(isValid);
		return isValid;
	}


	/**
	 * Force the error message to display (or not)
	 *
	 * @param {boolean} [isError=true]
	 * @memberof Input
	 */
	displayError(isError = true) {
		this.labelControl.classList.toggle('warning', !isError);
		this.container.value.classList.toggle('warning', !isError);
		this.labelControl.text = isError ? this.label : this.errorMessage;
	}

	/**
	 * Fires when the FontAwesome icon is clicked
	 * @description - For some reason the event wont fire on the icon when its inside the input.
	 * Therefore attach to control and filter
	 * @param {Event} event
	 * @memberof Input
	 */
	iconClicked(event) {
		console.debug('Input:iconClick', event.target.tagName);
		event.preventDefault();
		event.stopPropagation();

		const detail = {
			control: this,
			value: this.inputControl.value,
			source: this.tagName,
		};
		event = new CustomEvent('iconClick', { bubbles: true, cancelable: true, composed: true, detail });
		this.dispatchEvent(event);
		if (this.cbIconClick) this.cbIconClick(event);
	}

	/**
	 * toggle the icons used in the password control.
	 *
	 * @memberof Input
	 * @private
	 */
	togglePassword() {
		if (this.type === "password") {
			if (this.inputControl.type === "text") {
				this.inputControl.type = "password";
			} else {
				this.inputControl.type = "text";
			}

			const detail = {
				control: this,
				value: this.inputControl.value,
				source: this.tagName,
			};
			const event = new CustomEvent('iconClick', { bubbles: true, cancelable: true, composed: true, detail });
			this.dispatchEvent(event);
			if (this.cbIconClick) this.cbIconClick(event);
		}
	}

	/**
	 * Create the relevant right icon for this input type
	 *
	 * @return {*}
	 * @memberof Input
	 */
	createIcon() {
		let icon = '';

		if (this.type === 'text' || this.type === 'currency' || this.type === 'number') {
			// dont set an icon
		} else if (this.type === 'location') {
			icon = html`<rps-svg class="location-icon" svg="${iconNames.location}" @click=${this.iconClicked}></rps-svg>`;
		} else if (this.type === 'url') {
			icon = html`<rps-svg class="url-icon" svg="${iconNames.link}" @click=${this.iconClicked}></rps-svg>`;
		} else if (this.type === 'password') {
			icon = html`
				<rps-svg class="password-show-icon" svg="${iconNames.passwordShow}" @click=${this.togglePassword}></rps-svg>
				<rps-svg class="password-hide-icon" svg="${iconNames.passwordHide}" @click=${this.togglePassword}></rps-svg>
			`;
		} else if (this.type === 'date') {
			icon = html`<rps-svg class="calendar-icon" svg="${iconNames.dateTime}" @click=${this.iconClicked}></rps-svg>`;
		} else if (this.type === 'datetime' || this.type === 'datetime-local') {
			icon = html`<rps-svg class="datetime-icon" svg="${iconNames.dateTime}" @click=${this.iconClicked}></rps-svg>`;
		} else if (this.readonly) {
			icon = html`<rps-svg class="readonly-icon" svg="${iconNames.readOnly}" @click=${this.iconClicked}></rps-svg>`;
		} else {
			icon = html`<rps-svg class="${this.type}-icon" svg="${this.type}" @click=${this.iconClicked}></rps-svg>`;
		}

		return icon;
	}

	_focus(ev) {
		super._focus(ev);
		if (this.inputCtrl.value && this.selectOnFocus) {
			this.inputCtrl.value.select();
		}
	}


	render() {
		const isPassword = this.type === "password" ? "password" : "";

		return html`
			${this.addCss()}
			<div class="input ${this.css} ${this.className}" ${ref(this.container)} >
				<rps-input-label ${ref(this.labelCtrl)}
					?required=${this.required} ?disabled=${this.disabled}
					text="${this.label}"
					hide=${this.hideLabel}
				></rps-input-label>

				<div class="input-container">
					<input class="${this.className} ${isPassword}" name="${this.name}" type="${this.type}" id="${this.name + 'Input'}"
						${ref(this.inputCtrl)}
						value="${this.defaultValue}"
						pattern="${this.pattern}"
						placeholder="${this.placeHolder}"
						minlength=${this.minLength} maxlength=${this.maxLength}
						min=${this.min} max=${this.max}
						?required=${this.required} ?disabled=${this.disabled} ?readonly=${this.readonly}
						accept="${this.accept}"
						@input=${this.inputChanged}
						@focus=${this._focus}
						@blur=${this._blur}
						multiple>

					<div class="slot-left">
						<span class="currency">${this.currencySymbol()}</span>
					</div>

					<div class="slot-chips">
						<slot name="chips"></slot>
					</div>

					<div class="slot-right">
						${this.createIcon()}
					</div>

					<div class="input-states"></div>
				</div>
			</div>
			`;
	}

}

