import { html, css } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { iconNames } from '../../svg-icons.js';
import '../../rps-svg.js';
import { styles } from './css/pagination.css.js';
export class Pagination extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			pageSize: { type: Number },
			itemCount: { type: Number },
			currentPage: { type: Number },
			onPageHasChanged: { attribute: false },
		};
	}

	constructor() {
		super()
		this.currentPage = 1;
		this.pageSize = 10;
	}

	/**
	 * Move to the first Page
	 *
	 * @memberof Pagination
	 */
	firstPage() {
		this.pageHasChanged(1);
	}

	/**
	 * Move to the last page
	 *
	 * @memberof Pagination
	 */
	lastPage() {
		this.pageHasChanged(this.pageCount);
	}

	/**
	 * Move to the next page
	 *
	 * @memberof Pagination
	 */
	nextPage() {
		if (this.currentPage < this.pageCount) {
			this.pageHasChanged(this.currentPage + 1);
		}
	}

	/**
	 * Move to the previous page
	 *
	 * @memberof Pagination
	 */
	previousPage() {
		if (this.currentPage > 1) {
			this.pageHasChanged(this.currentPage - 1);
		}
	}


	/**
	 * The number of pages in this grid
	 *
	 * @readonly
	 * @memberof Pagination
	 */
	get pageCount() {
		return Math.ceil(this.itemCount / this.pageSize);
	}

	/**
	 * The number of "full" pages in this grid.
	 * @description dont count pages that dont have the same amount of items as the [this.pageCount]
	 *
	 * @readonly
	 * @memberof Pagination
	 */
	get fullPageCount() {
		return Math.floor(this.itemCount / this.pageSize);
	}

	/**
	 * How many items are on the last page?
	 * @description This can be useful when you need to process the last page, and need to know how many items are displayed on it.
	 *
	 * @readonly
	 * @memberof Pagination
	 */
	get lastPageItemCount() {
		return this.itemCount % this.pageSize;
	}


	/**
	 * Fired when the page has changed
	 *
	 * @param {Number} currentPage
	 * @memberof Pagination
	 * @private
	 */
	pageHasChanged(currentPage) {
		this.currentPage = currentPage;

		const detail = { currentPage, source: this.tagName };
		const event = new CustomEvent('pageHasChanged', { detail, bubbles: true, cancelable: true, composed: false });
		this.dispatchEvent(event);

		if (this.onPageHasChanged) this.onPageHasChanged(currentPage);
	}


	/**
	 * Fired when a specific page has been clicked on
	 *
	 * @param {Event} event
	 * @param {Number} pageNumber
	 * @private
	 * @memberof Pagination
	 */
	pageClick(event, pageNumber) {
		event.preventDefault();
		if (this.currentPage !== pageNumber)
			this.pageHasChanged(pageNumber);
	}


	/**
	 * Fired when the first page has been clicked on
	 *
	 * @param {Event} event
	 * @private
	 * @memberof Pagination
	 */
	pageFirst(event) {
		event.preventDefault();
		this.firstPage();
	}


	/**
	 * Fired when the previous page has been clicked on
	 *
	 * @param {Event} event
	 * @memberof Pagination
	 * @private
	 */
	pagePrevious(event) {
		event.preventDefault();
		this.previousPage();
	}


	/**
	 * Fired when the next page has been clicked on
	 *
	 * @param {*} event
	 * @memberof Pagination
	 * @private
	 */
	pageNext(event) {
		event.preventDefault();
		this.nextPage();
	}


	/**
	 * Fired when the last page has been clicked on
	 *
	 * @param {Event} event
	 * @memberof Pagination
	 * @private
	 */
	pageLast(event) {
		event.preventDefault();
		this.lastPage();
	}


	/**
	 * render the first page(s)
	 *
	 * @return {html} 
	 * @memberof Pagination
	 * @private
	 */
	firstPages() {
		if (this.currentPage <= 1) return;

		return html`
			<span><rps-svg svg="${iconNames.pageFirst}" @click=${this.pageFirst}></rps-svg></span>
			<span><rps-svg svg="${iconNames.angleLeft}" @click=${this.pagePrevious}></rps-svg></span>
			`;
	}


	/**
	 * Create a list of pages in the center
	 *
	 * @return {html} 
	 * @memberof Pagination
	 * @private
	 */
	pageList() {
		const tabCount = 5;
		const pageCentre = Math.ceil(tabCount / 2);

		const pages = Array.from(
			{ length: this.pageCount < tabCount ? this.pageCount : tabCount },
			(v, k) => k + (this.currentPage < pageCentre || this.pageCount < tabCount ? 1 : this.currentPage + (pageCentre - 1) < this.pageCount ? this.currentPage - (pageCentre - 1) : this.pageCount - (tabCount - 1))
		).map((item) => (
			html`<span class="${this.currentPage !== item ? 'page-numbers' : 'current-page text-muted'}" @click=${(e) => this.pageClick(e, item)}>${item}</span>`
		));

		return html`${pages.map(e => e)}`;
	}


	/**
	 * Create the last pages
	 *
	 * @return {html} 
	 * @memberof Pagination
	 * @private
	 */
	lastPages() {
		if (this.currentPage >= this.pageCount) return;

		return html`
			<span><rps-svg svg="${iconNames.angleRight}" @click=${this.pageNext}></rps-svg></span>
			<span><rps-svg svg="${iconNames.pageLast}" @click=${this.pageLast}></rps-svg></span>
		`;
	}

	render() {
		// for some readon it comes through as a NaN sometimes, in those cases dont display
		if (isNaN(this.itemCount) || this.pageSize >= this.itemCount) return;


		return html`
			${this.addCss()}

			<div class="pagination">
				${this.firstPages()}
				${this.pageList()}
				${this.lastPages()}
			</div>
		`;
	}
}
