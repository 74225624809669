import { html, css } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { iconNames } from '../../svg-icons';
import '../../rps-svg';
import { styles } from './css/breadCrumb.css.js';

export class BreadCrumb extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			pages: { type: Array },
			separator: { attribute: false },
			cbClick: { attribute: false },
		};
	}

	constructor() {
		super()
		this.pages = [
			{ text: "Add Customer", href: "/customer/1", id: 1 },
			{ text: "Edit John", href: "/customer/1/edit", id: 2 },
			{ text: "Address", href: "/customer/1/edit/address", id: 3 },
		];
		this.separator = iconNames.angleRight; // iconNames.angleLeft
	}

	/**
	 * reset all the pages in the breadcrumb
	 *
	 * @example - { text: "Address", href: "/customer/1/edit/address", id: 1 },
	 * @param {Object} page - as per example
	 * @memberof BreadCrumb
	 */
	setPages(newList) {
		this.pages = newList;
		this.requestUpdate();
	}

	/**
	 * Add a page to the List
	 * @example - { text: "Address", href: "/customer/1/edit/address", id: 1 },
	 * @param {Object} page - as per example
	 * @memberof BreadCrumb
	 */
	push(page) {
		this.pages.push(page);
		this.requestUpdate();
	}

	/**
	 * remove the last page from the breadcrumb
	 *
	 * @memberof BreadCrumb
	 */
	pop() {
		this.pages.pop();
		this.requestUpdate();
	}

	/**
	 * Fired when a specific page has been clicked on
	 *
	 * @param {Event} event
	 * @param {Number} pageNumber
	 * @memberof Pagination
	 */
	pageClick(event) {
		const page = event.target.getAttribute('pagenumber');
		const href = event.target.getAttribute('href');
		const text = event.target.getAttribute('text');

		event.stopPropagation();
		const detail = {
			page,
			href,
			text,
			source: this.tagName,
			target: event.target,
		};
		event = new CustomEvent('click', { detail, bubbles: true, cancelable: true, composed: false });
		this.dispatchEvent(event);

		if (this.cbClick) this.cbClick(event);
	}

	/**
	 * Create a list of pages to render
	 *
	 * @return {html} 
	 * @memberof Pagination
	 */
	pageList() {
		const pages = this.pages.map((item, i) => (
			html`
				<a href="#" id="${item.id}" text=${item.text} pageNumber=${i} @click=${this.pageClick}>${item.text}</a>
				<rps-svg svg=${this.separator}></rps-svg>
			`
		));

		return html`${pages.map(e => e)}`;
	}


	render() {
		return html`
			${this.addCss()}

			<div class="breadcrumbs">
				${this.pageList()}
			</div>
		`;
	}
}
