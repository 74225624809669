import React, { useState, useRef, useEffect } from 'react';
import { useSafeEffect } from 'hooks/useSafeEffect';
import { useCurrentDealSource } from 'hooks/useCurrentDealSource';
import { usePreviousValue } from 'hooks/usePreviousValue';
import { useAttributeOptions } from '@odo/hooks/attributes';
import { AttributeCode } from '@odo/types/api';
import { Deal } from 'models/Deal.jsx';
import ImageCard from './ImageCard';
import Switch from '@odo/components/elements/switch';
import {
  RPSCard,
  RPSListContainer,
} from '@rps/web-components/build/react-wrappers';
import { DealSizeChart } from 'models/SizeChart';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import './css/TextEditor.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import textOverrides from './css/TextOverrides.module.css';
import { Select } from '@odo/components/elements/form-fields';
import { Flex } from '@odo/components/elements/layout/flex';
import { handleODOInput } from 'utils/odo-migration/forms';
import infoStyles from './css/SizeInfo.module.css';

const ACCEPTED_FILE_TYPES = [
  'image/gif',
  'image/png',
  'image/jpeg',
  'image/webp',
];
const placeholder = '/assets/image-placeholder.png';
const blockFormatter = contentBlock => {
  const type = contentBlock.getType();
  if (type === 'header-five') {
    return textOverrides.productDescriptionH5;
  }
};

const styleMap = {
  H5: {
    textDecoration: 'line-through',
  },
};

const editorToolbarOptions = {
  options: [
    'inline',
    'blockType',
    'list',
    'textAlign',
    'fontFamily',
    'fontSize',
    'link',
    'remove',
    'history',
  ],
  inline: {
    options: ['bold', 'underline', 'italic', 'monospace', 'superscript'],
  },
  blockType: {
    options: ['Normal', 'H5', 'Blockquote', 'Code'],
  },
  fontSize: {
    options: [10, 11, 12, 14, 15, 16, 17, 18],
  },
  fontFamily: {
    options: ['Open Sans', 'Montserrat', 'sans-serif'],
  },
  link: {
    defaultTargetOption: '_blank',
  },
};
const isSizeInfoSet = sizeChartModel => {
  let isSet = false;
  const excludedFields = [
    'ID',
    'SM_DESKTOP_IMAGE',
    'SM_TABLET_IMAGE',
    'SM_MOBILE_IMAGE',
  ];
  for (const [key, field] of Object.entries(DealSizeChart.FIELDS)) {
    if (excludedFields.includes(key)) {
      continue;
    }
    isSet =
      sizeChartModel[field] !== '' &&
      sizeChartModel[field] !== false &&
      sizeChartModel[field] !== null;
    if (['mobile', 'tablet', 'desktop'].includes(field)) {
      isSet = sizeChartModel[field].url !== '';
    }
    if (field === DealSizeChart.FIELDS.MEASUREMENT) {
      isSet = sizeChartModel[field] !== 0;
    }
    if (isSet) {
      break;
    }
  }
  return isSet;
};

export const ProductSizeChartSection = () => {
  const currentDeal = useCurrentDealSource();
  const sizeChartModel = currentDeal.deal.sizeChart;

  const [showSizeInfo, setShowSizeInfo] = useState(
    isSizeInfoSet(sizeChartModel)
  );
  const [localEditorState, setLocalEditorState] = useState(null);

  const [isDesktopImageNew, setIsDesktopImageNew] = useState(false);
  const [isTabletImageNew, setIsTabletImageNew] = useState(false);
  const [isMobileImageNew, setIsMobileImageNew] = useState(false);
  // const handleInput = currentDeal.createInputHandler(Deal.MODELS.SIZE_CHART);
  const editorRef = useRef();
  const fileInputDesktopRef = useRef();
  const fileInputTabletRef = useRef();
  const fileInputMobileRef = useRef();

  const previousSizeChartValue = usePreviousValue(sizeChartModel);

  const howToMeasureOptions = useAttributeOptions(
    AttributeCode.sizeChartMeasurements
  );

  const handleShowSizeInfo = () => {
    setShowSizeInfo(!showSizeInfo);
  };

  const handleDesktopCheckbox = val => {
    currentDeal.deal.set(
      Deal.MODELS.SIZE_CHART,
      DealSizeChart.FIELDS.DESKTOP_DELETE,
      val
    );
    currentDeal.update();
  };

  const handleTabletCheckbox = val => {
    currentDeal.deal.set(
      Deal.MODELS.SIZE_CHART,
      DealSizeChart.FIELDS.TABLET_DELETE,
      val
    );
    currentDeal.update();
  };

  const handleMobileCheckbox = val => {
    currentDeal.deal.set(
      Deal.MODELS.SIZE_CHART,
      DealSizeChart.FIELDS.MOBILE_DELETE,
      val
    );
    currentDeal.update();
  };

  const handleManualUpload = ev => {
    const imageName = ev.target.name;
    const reader = new FileReader();
    if (ev.target.files.length > 0) {
      const file = ev.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        const url = reader.result;
        const dataMatch = url.match(/^data:(.+);.+,(.+)/);
        const sizeChartModelImage = {
          fileName: file.name,
          fileMimeType: file.type,
          fileLabel: file.name,
          fileContents: dataMatch[2],
          filePath: url,
          delete: null,
        };
        switch (imageName) {
          case 'desktop':
            setIsDesktopImageNew(true);
            currentDeal.deal.set(
              Deal.MODELS.SIZE_CHART,
              DealSizeChart.FIELDS.DESKTOP_DELETE,
              false
            );
            break;
          case 'tablet':
            setIsTabletImageNew(true);
            currentDeal.deal.set(
              Deal.MODELS.SIZE_CHART,
              DealSizeChart.FIELDS.TABLET_DELETE,
              false
            );
            break;
          case 'mobile':
            setIsMobileImageNew(true);
            currentDeal.deal.set(
              Deal.MODELS.SIZE_CHART,
              DealSizeChart.FIELDS.MOBILE_DELETE,
              false
            );
            break;
          default:
            break;
        }
        currentDeal.deal.set(
          Deal.MODELS.SIZE_CHART,
          DealSizeChart.FIELDS[imageName.toUpperCase()],
          sizeChartModelImage
        );
        currentDeal.update();
      };
    }
  };

  useSafeEffect(() => {
    if (previousSizeChartValue === ' ' || !previousSizeChartValue) {
      if (editorRef.current) {
        editorRef.current.value = sizeChartModel.recommendation;
        editorRef.current.content = sizeChartModel.recommendation;
      }
    }
    //when deal has been saved reset the input values
    if (!currentDeal.deal.changes.length) {
      fileInputDesktopRef.current.value = null;
      fileInputTabletRef.current.value = null;
      fileInputMobileRef.current.value = null;
      setShowSizeInfo(isSizeInfoSet(sizeChartModel));
    }
  }, [currentDeal.deal]);

  useEffect(() => {
    if (
      localEditorState ||
      !sizeChartModel.recommendation ||
      sizeChartModel.recommendation === ' '
    ) {
      return;
    }
    const contentBlock = htmlToDraft(sizeChartModel.recommendation);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      setLocalEditorState(EditorState.createWithContent(contentState));
    }
  }, [localEditorState, sizeChartModel]);

  const handleEditorStateChange = editorState => {
    const newValue = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setLocalEditorState(editorState);
    currentDeal.deal.set(
      Deal.MODELS.SIZE_CHART,
      DealSizeChart.FIELDS.RECOMMENDATION,
      newValue
    );
    currentDeal.update();
  };

  return (
    <RPSCard>
      <div slot="header" className={infoStyles.sizeInfo}>
        <h5>Size Info</h5>
        <div className={infoStyles.sizeInfoToggle}>
          <label style={{ padding: '5px' }}>
            {showSizeInfo ? 'Hide' : 'Show'}{' '}
          </label>
          <Switch
            onClick={handleShowSizeInfo}
            checked={showSizeInfo}
            width="4"
            height="4"
            activeColor="#59afd4"
          />
        </div>
      </div>
      <RPSListContainer
        className={`vertical ${
          showSizeInfo ? infoStyles['bodyShow'] : infoStyles['bodyHide']
        }`}
      >
        <Select
          label="How to Measure Image"
          value={sizeChartModel.measurement}
          onChange={handleODOInput({
            currentDeal,
            model: Deal.MODELS.SIZE_CHART,
            field: DealSizeChart.FIELDS.MEASUREMENT,
          })}
          options={howToMeasureOptions.map(option => ({
            id: option.value,
            value: option.originalData.value,
            label: option.label,
          }))}
          matchRPStyles
        />
        <Flex className={infoStyles.imageCardRow}>
          <Flex className={infoStyles.imageCard}>
            <ImageCard
              row={false}
              key="desktop"
              title="Desktop"
              index={1}
              isDelete={sizeChartModel.desktopDelete}
              image={sizeChartModel.desktop}
              data={sizeChartModel}
              src={
                sizeChartModel.desktop?.fileContents &&
                (isDesktopImageNew || !sizeChartModel.desktop?.url)
                  ? sizeChartModel.desktop?.filePath
                  : sizeChartModel.desktop?.url || placeholder
              }
              isNew={isDesktopImageNew && !sizeChartModel.desktop?.url}
              onUpdate={handleDesktopCheckbox}
            />
            <input
              ref={fileInputDesktopRef}
              type="file"
              name="desktop"
              onInput={handleManualUpload}
              accept={ACCEPTED_FILE_TYPES.join(',')}
              style={{ cursor: 'pointer', paddingTop: '2rem' }}
            />
            {!isDesktopImageNew && sizeChartModel.desktop?.fileName
              ? sizeChartModel.desktop.fileName
              : ''}
            <em>Accepted file types: {ACCEPTED_FILE_TYPES.join(', ')}</em>
          </Flex>
          <Flex className={infoStyles.imageCard}>
            <ImageCard
              row={false}
              key="tablet"
              title="Tablet"
              index={0}
              isDelete={sizeChartModel.tabletDelete}
              image={sizeChartModel.tablet}
              data={sizeChartModel}
              src={
                sizeChartModel.tablet?.fileContents &&
                (isTabletImageNew || !sizeChartModel.tablet?.url)
                  ? sizeChartModel.tablet?.filePath
                  : sizeChartModel.tablet?.url || placeholder
              }
              isNew={isTabletImageNew && !sizeChartModel.tablet?.url}
              onUpdate={handleTabletCheckbox}
            />
            <input
              ref={fileInputTabletRef}
              type="file"
              name="tablet"
              onInput={handleManualUpload}
              accept={ACCEPTED_FILE_TYPES.join(',')}
              style={{ cursor: 'pointer', paddingTop: '2rem' }}
            />
            {!isTabletImageNew && sizeChartModel.tablet?.fileName
              ? sizeChartModel.tablet.fileName
              : ''}
            <em>Accepted file types: {ACCEPTED_FILE_TYPES.join(', ')}</em>
          </Flex>
          <Flex className={infoStyles.imageCard}>
            <ImageCard
              row={false}
              key="mobile"
              title="Mobile"
              index={0}
              isDelete={sizeChartModel.mobileDelete}
              image={sizeChartModel.mobile}
              data={sizeChartModel}
              src={
                sizeChartModel.mobile?.fileContents &&
                (isMobileImageNew || !sizeChartModel.mobile?.url)
                  ? sizeChartModel.mobile?.filePath
                  : sizeChartModel.mobile?.url || placeholder
              }
              isNew={isMobileImageNew && !sizeChartModel.mobile?.url}
              onUpdate={handleMobileCheckbox}
            />
            <input
              ref={fileInputMobileRef}
              type="file"
              name="mobile"
              onInput={handleManualUpload}
              accept={ACCEPTED_FILE_TYPES.join(',')}
              style={{ cursor: 'pointer', paddingTop: '2rem' }}
            />
            {!isMobileImageNew && sizeChartModel.mobile?.fileName
              ? sizeChartModel.mobile.fileName
              : ''}
            <em>Accepted file types: {ACCEPTED_FILE_TYPES.join(', ')}</em>
          </Flex>
        </Flex>
        <label>Recommendation</label>
        <Editor
          blockStyleFn={blockFormatter}
          editorState={localEditorState}
          wrapperClassName="react-draft-wrapper"
          editorClassName="react-draft-editor"
          onEditorStateChange={handleEditorStateChange}
          toolbarStyle={{
            borderBottom: '1px solid var(--component-primary)',
          }}
          customStyleMap={styleMap}
          toolbar={editorToolbarOptions}
        />
      </RPSListContainer>
    </RPSCard>
  );
};
