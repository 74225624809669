import { createContext } from 'react';
import type { CustomOptionsEditorContextType } from './types';

const defaultContext: CustomOptionsEditorContextType = {
  autoSumEnabled: true,
  canUndo: false,
  canRedo: false,
  canSave: false,
  hasUnsavedActions: false,
  canClearActions: false,
  isSaving: false,
  showErrors: false,
  editorCustomOptions: [],
  toggleAutoSumEnabled: () => void 0,
  copyOption: () => void 0,
  pasteOption: () => void 0,
  cancelCopy: () => void 0,
  validate: () => true,
  addAction: () => void 0,
  moveActionOffset: () => void 0,
  saveActions: async () => void 0,
  clearActions: () => void 0,
  getCumulativeQty: () => undefined,
};

const CustomOptionsEditorContext =
  createContext<CustomOptionsEditorContextType>(defaultContext);

export default CustomOptionsEditorContext;
