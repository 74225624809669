import { Status, type Validator } from '@odo/screens/deal/editor/types';

export const supplierSectionValidators: Validator[] = [
  {
    validate: product => !!product.supplier?.id,
    message: 'You must select a supplier.',
    status: Status.error,
  },
];

export const buyerSectionValidators: Validator[] = [
  {
    validate: product => !!product.buyer?.id,
    message: 'You must select an account manager.',
    status: Status.error,
  },
  {
    validate: product => !!product.salesAssistant?.id,
    message: 'You must select a loader.',
    status: Status.error,
  },
];

export const merchandisingSectionValidators: Validator[] = [
  {
    validate: product => !!product.priority?.id,
    message: 'You must select a priority.',
    status: Status.error,
  },
  {
    validate: product => !!product.campaign?.id,
    message: 'No campaign selected (eg. none)',
    status: Status.warning,
  },
];

const validators: Validator[] = [
  ...supplierSectionValidators,
  ...buyerSectionValidators,
  ...merchandisingSectionValidators,
];

export default validators;
