import propTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { RPSButtonIcon } from '@rps/web-components/build/react-wrappers';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCurrentDealSource } from '../../hooks/useCurrentDealSource';
import styles from './ProgressTrack.module.css';
import { RPSWizard } from 'components/shared/RPSWizard.jsx';
import { iconNames } from '@rps/web-components/build/web-components';
import ProductInformationScreen from '../deals/ProductInformationScreen';
import ConditionsAndCategoryScreen from '../deals/ConditionsAndCategoryScreen';
import PriceAndCustomOptionsScreen from '../deals/PriceAndCustomOptionsScreen';
import ImagesAndVideosScreen from './../deals/imagesAndVideos/ImagesAndVideosScreen';
import { ShippingScreen } from './../deals/ShippingScreen';
import { SummaryScreen } from './../deals/SummaryScreen';
import { BuyerAndSupplierScreen } from '../deals/BuyerAndSupplierScreen';
import { generatePath, matchPath } from 'react-router-dom';

const ProgressMarker = ({
  label,
  icon,
  currentStep,
  stepNum,
  handleClick,
  validator = null,
}) => {
  const currentDeal = useCurrentDealSource();
  const handleClickInternal = () => {
    if (stepNum !== currentStep && handleClick) {
      handleClick(stepNum);
    }
  };

  let validity = 'valid';
  let validityBGColor = 'white';
  let validityFGColor = 'black';
  if (validator && currentDeal.deal) {
    validity = validator(currentDeal.deal)?.status || 'invalid';
  }
  switch (validity) {
    case 'invalid':
      validityBGColor = '#E50E62';
      validityFGColor = 'white';
      break;
    case 'partial':
      validityBGColor = '#FFC222';
      validityFGColor = 'white';
      break;
    case 'complete':
      validityBGColor = '#B8D72F';
      validityFGColor = 'white';
      break;
    default:
      validityBGColor = 'white';
      break;
  }

  if (currentStep === stepNum && validity === 'valid') {
    validityBGColor = '#0093D0';
    validityFGColor = 'white';
  }

  return (
    <div
      onClick={handleClickInternal}
      className={styles.step}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '2rem',
        background: currentStep === stepNum ? 'white' : 'transparent',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
      }}
    >
      <i
        style={{
          backgroundColor: validityBGColor,
          color: validityFGColor,
          display: 'flex',
          width: '3rem',
          height: '3rem',
          borderRadius: '50%',
          position: 'relative',
          placeItems: 'center',
          placeContent: 'center',
          textAlign: 'center',
        }}
        className={`fa ${icon}`}
      />
      <div
        style={{
          display: 'block',
          textAlign: 'center',
          top: '16px',
          position: 'relative',
          color: currentStep === stepNum ? 'black' : 'white',
          fontSize: '11px',
        }}
      >
        {label}
      </div>
    </div>
  );
};

ProgressMarker.propTypes = {
  label: propTypes.string,
  icon: propTypes.string,
  currentStep: propTypes.number,
  stepNum: propTypes.number,
  handleClick: propTypes.func,
  validator: propTypes.func,
};

export const ProgressTrack = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentDeal = useCurrentDealSource();

  const wizardRef = useRef();

  const [currentStep, setCurrentStep] = useState(0);
  const [selectedId, setSelectedId] = useState(WizardSteps[currentStep].id);

  const generateStatus = stepNum => {
    let validity = '';
    switch (stepNum) {
      case 0:
        validity = currentDeal.deal.buyerAndSupplier.getValidity?.status;
        break;
      case 1:
        validity = currentDeal.deal.product.getValidity?.status;
        break;
      case 2:
        validity = currentDeal.deal.conditionsAndCategory.getValidity?.status;
        break;
      case 3:
        validity = currentDeal.deal.priceAndCustomOptions.getValidity?.status;
        break;
      case 4:
        validity = currentDeal.deal.shipping.getValidity?.status;
        break;
      case 5:
        validity = currentDeal.deal.imagesAndVideos.getValidity?.status;
        break;
      default:
        break;
    }

    switch (validity) {
      case 'complete':
        return 'success';
      case 'partial':
        return 'warning';
      case '': {
        if (stepNum === 6) {
          return 'success';
        } else {
          return 'error';
        }
      }
      default:
        return '';
    }
  };

  const wizardButtons = WizardSteps.map((step, index) => ({
    id: step.id,
    text: step.label,
    svg: step.svg,
    active: currentStep === index,
    status: generateStatus(index),
  }));

  const saveBeforeNavigate = onNavigate => {
    // if (currentDeal.deal.canUndo()) {
    //   if (dealStatus === 'partial' || dealStatus === 'complete') {
    //     // Ask if user wants to upload the deal to Magento
    //     saveCurrentDeal(onNavigate);
    //   } else {
    //     onNavigate();
    //   }
    // } else {
    //   onNavigate();
    // }
    onNavigate();
  };

  const gotoNextStep = () => {
    saveBeforeNavigate(() =>
      navigate(
        generatePath(
          `${BASE_PATH}/:dealId?/${WizardSteps[currentStep + 1].to}`,
          {
            dealId: currentDeal.deal.id || undefined,
          }
        )
      )
    );
  };

  const gotoPrevStep = () => {
    saveBeforeNavigate(() =>
      navigate(
        generatePath(
          `${BASE_PATH}/:dealId?/${WizardSteps[currentStep - 1].to}`,
          {
            dealId: currentDeal.deal.id || undefined,
          }
        )
      )
    );
  };

  /**
   * Figure out which step to show.
   */
  useEffect(() => {
    const foundStep = WizardSteps.findIndex(step => {
      return (
        !!matchPath(`${BASE_PATH}/:dealId/${step.to}`, location.pathname) ||
        !!matchPath(`${BASE_PATH}/${step.to}`, location.pathname)
      );
    });

    if (foundStep !== -1) {
      setCurrentStep(foundStep);
    }
  }, [location]);

  /**
   * Update selected step & active button.
   */
  useEffect(() => {
    setSelectedId(WizardSteps[currentStep].id);
    if (wizardRef.current) {
      // done inside of a timeout to ensure the button is available (ie. after initial render)
      setTimeout(() => {
        wizardRef.current &&
          wizardRef.current.pressButton(WizardSteps[currentStep].id);
      }, 0);
    }
  }, [wizardRef, currentStep]);

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        alignItems: 'center',
        marginTop: 'calc(var(--content-padding) * -1)',
      }}
    >
      <RPSButtonIcon
        cbClick={gotoPrevStep}
        disabled={currentStep === 0}
        className="large outline secondary"
        svg={iconNames.angleLeft}
      />

      <div style={{ padding: '2rem var(--content-padding)' }}>
        <RPSWizard
          innerRef={wizardRef}
          buttons={wizardButtons}
          selectedId={selectedId}
          cbClick={ev => {
            const stepIndex = WizardSteps.findIndex(
              s => s.id === ev.detail.buttonId
            );
            if (stepIndex !== -1) {
              setCurrentStep(stepIndex);
              navigate(
                generatePath(
                  `${BASE_PATH}/:dealId?/${WizardSteps[stepIndex].to}`,
                  {
                    dealId: currentDeal.deal.id || undefined,
                  }
                )
              );
            }
          }}
        />
      </div>

      <RPSButtonIcon
        cbClick={gotoNextStep}
        disabled={currentStep >= WizardSteps.length - 1}
        className="large outline secondary"
        svg={iconNames.angleRight}
      />
    </div>
  );
};

ProgressTrack.propTypes = {
  steps: propTypes.array,
  currentStep: propTypes.number,
};

export const BASE_PATH = '/deals/editor';

export const WizardSteps = [
  {
    label: 'Buyer & Supplier',
    svg: iconNames.wizard.checklist,
    id: 'buyer-supplier',
    to: 'buyer-and-supplier',
    component: <BuyerAndSupplierScreen />,
    validator: x => x.buyerAndSupplier.getValidity,
  },
  {
    label: 'Product',
    svg: iconNames.wizard.product,
    id: 'product',
    to: 'product',
    component: <ProductInformationScreen />,
    validator: x => x.product.getValidity,
  },
  {
    label: 'Conditions & Category',
    svg: iconNames.wizard.category,
    id: 'conditions-and-category',
    to: 'conditions-and-category',
    component: <ConditionsAndCategoryScreen />,
    validator: x => x.conditionsAndCategory.getValidity,
  },
  {
    label: 'Price & Custom Options',
    svg: iconNames.wizard.pricing,
    id: 'price-and-custom-options',
    to: 'price-and-custom-options',
    component: <PriceAndCustomOptionsScreen />,
    validator: x => x.priceAndCustomOptions.getValidity,
  },
  {
    label: 'Shipping & Inventory',
    svg: iconNames.wizard.delivery,
    id: 'shipping-and-inventory',
    to: 'shipping-and-inventory',
    component: <ShippingScreen />,
    validator: x => x.shipping.getValidity,
  },
  {
    label: 'Images & Videos',
    svg: iconNames.wizard.media,
    id: 'images-and-videos',
    to: 'images-and-videos',
    component: <ImagesAndVideosScreen />,
    validator: x => x.imagesAndVideos.getValidity,
  },
  {
    label: 'Summary',
    svg: iconNames.wizard.signOff,
    id: 'summary',
    to: 'summary',
    component: <SummaryScreen />,
  },
];
