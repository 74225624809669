import { html, css } from 'lit';
import { ref } from 'lit/directives/ref.js';
import { BaseInputControl } from '../baseClasses/BaseInputControl';
import '../../rps-svg';
import { iconNames } from '../../svg-icons';
import { styles } from './css/plusMinus.css.js';
export class PlusMinusButtons extends BaseInputControl {
	static styles = styles;

	static get properties() {
		return {
			min: { type: Number },
			max: { type: Number },
			minLength: { type: Number },
			maxLength: { type: Number },
			hideLabel: { type: Boolean },
		};
	}

	constructor() {
		super();
		this.label = "num label";
		this.min = 0;
		this.max = 30;
		this.minLength = 0;
		this.maxLength = 30;
	}

	/**
	 * When connected to the DOM
	 * If there is no default value or it does not fall into the range of valid values, set it to the min value
	 *
	 * @memberof PlusMinusButtons
	 */
	connectedCallback() {
		super.connectedCallback();

		const def = +this.defaultValue;

		// If there is no default value or it does not fall into the range of valid values, set it to the min value
		if (def < this.min || def > this.max) {
			this.defaultValue = this.min;
		}
	}

	/**
	 * Event is fired when the user clicks on the "-" button
	 *
	 * @param {*} event
	 * @memberof PlusMinus
	 */
	minusClick(event) {
		const current = +this.inputControl.value;	// current value as a number

		event.stopPropagation();

		console.debug('plusMinus:minusClick', event);
		if (this.isValid && current - 1 >= this.min) {
			this.inputControl.value = current - 1;
			this.inputChanged(event);
		}

	}

	/**
	 * Event is fired when the user clicks on the "+" button
	 *
	 * @param {*} event
	 * @memberof PlusMinus
	 */
	plusClick(event) {
		const current = +this.inputControl.value;	// current value as a number

		event.stopPropagation();

		console.debug('plusMinus:plusClick', event);
		if (this.isValid && current + 1 <= this.max) {
			this.inputControl.value = current + 1;
			this.inputChanged(event);
		}
	}

	render() {
		const smaller = this.classList.contains('plus-minus')
			? html`<rps-svg class="sy" svg="${iconNames.indeterminate}"></rps-svg>`
			: html`<rps-svg class="ar" svg="${iconNames.angleDown}"></rps-svg>`;

		const bigger = this.classList.contains('plus-minus')
			? html`<rps-svg class="sy" svg="${iconNames.add}"></rps-svg>`
			: html`<rps-svg class="ar" svg="${iconNames.angleUp}"></rps-svg>`;

		const label = this.hideLabel
			? ''
			: html`
				<rps-input-label 
					class="${this.className}" 
					text="${this.label}" 
					${ref(this.labelCtrl)}
					?required=${this.required} ?disabled=${this.disabled} hide=${this.hideLabel}
				></rps-input-label>
			`;

		return html`
			${this.addCss()}
			<div class="input-container ${this.className}" ${ref(this.container)}>
				${label}
				<div class="input">
					<span @click=${this.minusClick}>
						${smaller}
					</span>

					<input class="${this.className}" name="${this.name}" type="number" id="${this.name + 'Input'}"
						${ref(this.inputCtrl)}
						value="${this.defaultValue}"
						pattern="${this.pattern}"
						placeholder="${this.placeHolder}"
						minlength=${this.minLength} maxlength=${this.maxLength}
						min=${this.min} max=${this.max}
						${this.required ? 'required' : ''} ${this.disabled ? 'disabled' : ''} ${this.readonly ? 'readonly' : ''}
						@input=${this.inputChanged}>

					<span @click=${this.plusClick}>
						${bigger}
					</span>
				</div>
			</div>
		`;
	}
}
