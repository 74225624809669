import { createReactWrapper } from './createReactWrapper.jsx';
import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useCurrentDealSource } from '../../hooks/useCurrentDealSource';
const InternalRPSWizard = createReactWrapper('rps-wizard');

export const RPSWizard = ({ innerRef, buttons, ...props }) => {
  const wrapperRef = useRef();
  const ref = innerRef || wrapperRef;
  const currentDeal = useCurrentDealSource();

  const setupClasses = useCallback(() => {
    const wizard = ref.current;
    if (wizard) {
      buttons.forEach(button => {
        wizard.resetButtonClass(button.id);
        wizard.toggleButtonClass(button.id, button.status);
        // button.class = `${button.status} ${button.active ? 'active' : ''}`;
      });
    }
  }, [buttons, ref]);

  useEffect(() => {
    setTimeout(setupClasses, 100);
  }, [currentDeal.deal, setupClasses]);

  useEffect(() => {
    setTimeout(setupClasses, 100);
  });

  useEffect(() => {
    ref?.current?.requestUpdate();
  }, [currentDeal, buttons, props, ref]);

  setupClasses();

  return <InternalRPSWizard buttons={buttons} {...props} innerRef={ref} />;
};

RPSWizard.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.any),
  innerRef: PropTypes.any,
};
