import { html } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { styles } from './css/titlebar.css.js';
import { iconNames } from '../../svg-icons';
import '../../rps-svg';
import '../../rps-toolbar';

export class TitleBar extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			buttons: { type: Array },
		};
	}

	constructor() {
		super();
		this.buttons = [
			{ id: "cut", svg: `${iconNames.format.cut}`, title: "Cut" },
			{ id: "copy", svg: `${iconNames.format.copy}`, title: "Copy" },
			{ id: "paste", svg: `${iconNames.format.paste}`, title: "Paste" },
		];
	}


	render() {
		return html`
		${this.addCss()}
			<div class="title-bar-container">
				<div class="heading ${this.className}">
					<span><slot></slot></span>

					<rps-toolbar .buttons=${this.buttons} class="title-bar-toolbar"></rps-toolbar>
				</div>
			</div>
		`;
	}
}
