import type { PaymentMethod, PaymentMethodID } from './types';

/**
 * NOTE: the VAT rate exists in magento config but rarely changes and is hardcoded in ODO admin price calculator.
 */
export const VAT_RATE = 0.15;

export const DEFAULT_ADMIN_COST = 17.5;

/**
 * NOTE: the below data is a combination of hardcoded weights and the "latest" DB entries for percentage/surcharge.
 *
 * Weights:
 * @see https://gitlab.com/onedayonly/magento_1/-/blob/master/odo/resources/price-calculator/includes.php#L14-21
 *
 * Percentage/surcharge:
 * @see `odo_admin_paymentmethodcosts`
 *
 * TODO: get these from a dynamic source (shared with ODO admin).
 */
export const PAYMENT_METHOD_COSTS: Record<PaymentMethodID, PaymentMethod> = {
  // weighted
  peachpayments: {
    label: 'Credit Card',
    percentage: 1.78,
    surcharge: 0.95,
    weight: 0.6,
  },
  payflex: {
    label: 'Payflex',
    percentage: 4.4,
    surcharge: 4,
    weight: 0.1,
  },
  ipay: {
    label: 'OZOW',
    percentage: 1.1,
    surcharge: 0.9,
    weight: 0.09,
  },
  snapscan: {
    label: 'SnapScan',
    percentage: 2,
    surcharge: 0,
    weight: 0.08,
  },
  eft: {
    label: 'EFT',
    percentage: 0,
    surcharge: 0,
    weight: 0.05,
  },
  ebucks: {
    label: 'eBucks',
    percentage: 3.51,
    surcharge: 0,
    weight: 0.03,
  },
  zapper: {
    label: 'Zapper',
    percentage: 2,
    surcharge: 0,
    weight: 0.03,
  },
  discoverymiles: {
    label: 'Discovery Miles',
    percentage: 5,
    surcharge: 0.98,
    weight: 0.02,
  },
  // unweighted
  visamastercard: {
    label: 'Credit Card',
    percentage: 2.45,
    surcharge: 1.05,
  },
  amex: {
    label: 'American Express',
    percentage: 2.3,
    surcharge: 1.8,
  },
  vodapay: {
    label: 'Vodapay',
    percentage: 2.2, // was originally 2.1, then went down to 1.91, then back up to 2.2
    surcharge: 0,
  },
  mobicred: {
    label: 'MobiCred',
    percentage: 2.5,
    surcharge: 0.95,
  },
  '1voucher': {
    label: '1Voucher',
    percentage: 3.5,
    surcharge: 0,
  },
  stitch: {
    label: 'Stitch',
    percentage: 0.9,
    surcharge: 0,
  },
  dinersclub: {
    label: 'Diners Club',
    percentage: 2.85,
    surcharge: 1.8,
  },
  payucc: {
    label: 'PayU',
    percentage: 1.95,
    surcharge: 1.05,
  },
  // NOTE: this used to be weighted at 0.01, but was removed from the ODO admin calculator at some point.
  ucount: {
    label: 'uCount',
    percentage: 3.54,
    surcharge: 1.8,
  },
  // TODO: consider removing wallet & free. no idea why they have values in costs table in the first place.
  wallet: {
    label: 'Wallet',
    percentage: 0,
    surcharge: 0,
  },
  free: {
    label: 'Free',
    percentage: 0,
    surcharge: 0,
  },
};
