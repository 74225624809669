/**
 * Typescript version of @see https://www.joshwcomeau.com/snippets/javascript/debounce/
 * Which is almost exactly the same as the RP debounce util.
 */
export const debounce = <T extends unknown[]>(
  callback: (...args: T) => void,
  wait: number,
  signal?: AbortSignal
) => {
  let timeoutId: number | undefined;
  // cancel timeout if we receive an abort signal
  if (signal) {
    signal.addEventListener('abort', () => window.clearTimeout(timeoutId));
  }
  return (...args: T) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => callback(...args), wait);
  };
};
