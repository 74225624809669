import type { Attribute, AttributeID } from '@odo/contexts/attributes';
import AttributeContext from '@odo/contexts/attributes';
import { useContext, useEffect, useState } from 'react';

export const useAttributeContext = () => useContext(AttributeContext);

export const useAttribute = (id: AttributeID) => {
  const { isReady, getAttribute } = useAttributeContext();
  const [attribute, setAttribute] = useState<Attribute | undefined>();

  useEffect(() => {
    isReady && setAttribute(getAttribute(id));
  }, [id, isReady, getAttribute]);

  return attribute;
};

export const useAttributeOptions = (id: AttributeID) =>
  useAttribute(id)?.options || [];
