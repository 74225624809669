import { html, css } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { styles } from './css/spinnerPause.css.js';
export class SpinnerPause extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			heading: { type: String },
		};
	}

	constructor() {
		super();
		this.heading = '';
	}


	/**
	 * Hide this spinner
	 *
	 * @memberof SpinnerPause1
	 */
	hide() {
		this.setAttribute('style', 'display: none');
	}

	/**
	 * Display this spinner if it is hidden
	 *
	 * @memberof SpinnerPause1
	 */
	show() {
		this.setAttribute('style', 'display: inline-block');
	}

	render() {
		return html`
		${this.addCss()}

		<div class="spinner-container">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" class="spinner"><circle fill="none" stroke-miterlimit="10" stroke-width="8" cx="32" cy="32" r="26"/></svg>
			<span>${this.heading}</span>
		</div>
	`;
	}
}
