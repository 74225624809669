import React from 'react';
import ImageCard from './ImageCard';
import styles from './css/ImageList.module.css';
import PropTypes from 'prop-types';
import {
  RPSButton,
  RPSModal,
  RPSListContainer,
} from '@rps/web-components/build/react-wrappers';
import { useImageList } from './useImageList';
import { DropZone } from 'components/attributes/Variations/CategoryDropZone';

const ImageList = ({ images }) => {
  const controller = useImageList(images);

  const handleDropEnd = ev => {
    const source = ev.source?.index;
    const dest = ev.destination?.index;
    controller.handleShiftPositions(source, dest);
  };

  return (
    <>
      <RPSListContainer className="vertical">
        <div>
          <button
            disabled={controller.tileSelected}
            className={[
              styles.link,
              ...(controller.tileSelected ? [styles.unselectable] : []),
            ].join(' ')}
            onClick={controller.handleDisplayModeChange('tile')}
          >
            Tile View
          </button>{' '}
          |{' '}
          <button
            disabled={controller.listSelected}
            className={[
              styles.link,
              ...(controller.listSelected ? [styles.unselectable] : []),
            ].join(' ')}
            onClick={controller.handleDisplayModeChange('list')}
          >
            List View
          </button>
        </div>

        <RPSListContainer css="#list-container { height: 2rem; align-items: baseline; }">
          <RPSButton className="outline" cbClick={controller.handleSelectAll}>
            {controller.hasSelection ? 'Deselect All' : 'Select All'}
          </RPSButton>

          {controller.hasSelection && (
            <>
              <RPSButton
                className="outline error"
                cbClick={controller.showDeleteConfirm}
              >
                Delete Selected
              </RPSButton>

              <RPSButton
                className="outline warning"
                cbClick={controller.handleDisableSelected}
              >
                Exclude Selected
              </RPSButton>

              <RPSButton
                className="outline success"
                cbClick={controller.handleEnableSelected}
              >
                Include Selected
              </RPSButton>
            </>
          )}
        </RPSListContainer>

        <RPSListContainer
          className={controller.tileSelected ? 'horizontal' : 'vertical'}
          css="#list-container { padding: 2rem 0; }"
        >
          <DropZone
            name="image-list"
            direction={controller.tileSelected ? 'horizontal' : 'vertical'}
            overrides={{
              style: {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '2rem',
                ...(!controller.tileSelected
                  ? {
                      flexFlow: 'column nowrap',
                      padding: '10px 20px 10px 4px',
                      maxHeight: 'calc(90dvh - 50px)',
                      overflowY: 'auto',
                      borderTop: '1px solid #e8e8f4',
                      borderBottom: '1px solid #e8e8f4',
                    }
                  : {}),
              },
            }}
            onDragEnd={handleDropEnd}
          >
            {images
              ?.filter(image => !!image)
              .map((image, index) => {
                return (
                  <ImageCard
                    row={controller.tileSelected ? undefined : true}
                    key={image.id}
                    title={image.id}
                    data={image.url}
                    index={index}
                    selected={controller.selected.includes(index)}
                    onSelect={controller.handleSelect}
                    onImageTypeSelect={controller.handleImageTypeSelect(index)}
                    onImageTypeDeselect={controller.handleImageTypeDeselect(
                      index
                    )}
                    image={image}
                    onUpdate={controller.handleUpdate(index)}
                    imageList={images}
                    onSwapPositions={controller.handleSwapPositions}
                    renderCounter={controller.renderCounter}
                  />
                );
              })}
          </DropZone>
        </RPSListContainer>
      </RPSListContainer>

      <RPSModal
        popup
        opened={controller.deleteModalOpened}
        cbConfirm={controller.handleDeleteSelected}
        cbCancel={controller.closeDeleteConfirm}
      >
        Are you sure you want to delete {controller.selected.length} images?
      </RPSModal>
    </>
  );
};

ImageList.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      filename: PropTypes.string,
      data: PropTypes.string,
      size: PropTypes.number,
      changed: PropTypes.bool,
    })
  ),
};

export default ImageList;
