import React from 'react';
import AppContainer from 'components/screenLayouts/AppContainer';
import AppContainerNew from '@odo/components/layouts/app-container';
import Dashboard from 'components/Dashboard';
import { Routes, Route, Outlet as RouteChildren } from 'react-router-dom';
import MinimalContainer from 'components/screenLayouts/MinimalContainer';
import { PrivateRoute } from './PrivateRoute.jsx';
// Authentication
import Login from 'components/authentication/Login';
// Deals
import DealCreate from 'components/deals/Create';
import DealHistoric from 'components/deals/historic/Manage';
import DealUpload from 'components/deals/Upload';
import Calculator from 'components/calculator/DefaultCalculator';
import { DealList } from 'components/deals/DealList';
import { DuplicateDeal } from 'components/deals/Duplicate';
import { DealWizard } from 'components/deals/DealWizard';
import { LoginCheck } from 'components/authentication/LoginCheck';
import { LoginLoader } from 'components/authentication/LoginLoader';
import { WizardSteps } from 'components/shared/ProgressTrack';
import { ThemeProvider } from '@odo/contexts/theme';

// search
import { SearchProviders } from '@odo/contexts/search';
import SearchToolbar from '@odo/screens/deal/search/widgets/toolbar';

// new deal editor
import DealEditorProviders from '@odo/screens/deal/editor/providers';
import AttributeProvider from '@odo/contexts/attributes/provider';
import DealEditorRoot from '@odo/screens/deal/editor/root';
import DealEditorNav from '@odo/screens/deal/editor/widgets/nav';
import DealEditorTitle from '@odo/screens/deal/editor/widgets/title';
import DealEditorToolbar from '@odo/screens/deal/editor/widgets/toolbar';
import { CustomOptionsToolbar } from '@odo/screens/deal/editor/price-and-custom-options/widgets';
import BuyerAndSupplierScreen from '@odo/screens/deal/editor/buyer-and-supplier';
import ProductScreen from '@odo/screens/deal/editor/product';
import ConditionsAndCategoryScreen from '@odo/screens/deal/editor/conditions-and-category';
import PriceAndCustomOptionsScreen from '@odo/screens/deal/editor/price-and-custom-options';
import ShippingAndInventoryScreen from '@odo/screens/deal/editor/shipping-and-inventory';
import ImagesAndVideosScreen from '@odo/screens/deal/editor/images-and-videos';
import SummaryScreen from '@odo/screens/deal/editor/summary';

const RoutesComponent = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/new"
          element={
            <PrivateRoute>
              <ThemeProvider>
                <RouteChildren />
              </ThemeProvider>
            </PrivateRoute>
          }
        >
          <Route path="deals" element={<RouteChildren />}>
            {/* TODO: search page here as a sibling to editor */}
            {/* TODO: create page here as a sibling to editor */}
            <Route
              path="editor/:dealId"
              element={
                <AttributeProvider>
                  <DealEditorProviders>
                    <DealEditorRoot>
                      <RouteChildren />
                    </DealEditorRoot>
                  </DealEditorProviders>
                </AttributeProvider>
              }
            >
              <Route
                element={
                  <AppContainerNew
                    title={<DealEditorTitle />}
                    toolbar={<DealEditorToolbar />}
                    nav={<DealEditorNav />}
                  >
                    <RouteChildren />
                  </AppContainerNew>
                }
              >
                <Route
                  path="buyer-and-supplier"
                  element={<BuyerAndSupplierScreen />}
                />
                <Route path="product" element={<ProductScreen />} />
                <Route
                  path="conditions-and-category"
                  element={<ConditionsAndCategoryScreen />}
                />
                <Route
                  path="shipping-and-inventory"
                  element={<ShippingAndInventoryScreen />}
                />
                <Route
                  path="images-and-videos"
                  element={<ImagesAndVideosScreen />}
                />
                <Route path="summary" element={<SummaryScreen />} />
              </Route>

              <Route
                element={
                  <AppContainerNew
                    title={<DealEditorTitle />}
                    toolbar={<DealEditorToolbar />}
                    nav={<DealEditorNav />}
                    footer={<CustomOptionsToolbar />}
                  >
                    <RouteChildren />
                  </AppContainerNew>
                }
              >
                <Route
                  path="price-and-custom-options"
                  element={<PriceAndCustomOptionsScreen />}
                />
              </Route>
            </Route>
          </Route>
        </Route>

        {/* AUTH */}
        <Route
          path="/auth/login"
          element={
            <MinimalContainer>
              <Login />
            </MinimalContainer>
          }
        />
        <Route
          path="/check-login"
          element={
            <MinimalContainer>
              <LoginCheck />
            </MinimalContainer>
          }
        />
        <Route
          path="/auth/loading"
          element={
            <MinimalContainer>
              <LoginLoader />
            </MinimalContainer>
          }
        />

        {/* DEALS */}
        {/*
					For routes beginning /deals we first match to some specific pages (i.e.: Duplicate Deal, Manage Deal)
					else we fall through to the DealWizard (See below).
				*/}
        <Route
          path="/deals/create"
          element={
            <PrivateRoute>
              <AppContainer>
                <DealCreate />
              </AppContainer>
            </PrivateRoute>
          }
        />
        <Route
          path="/deals/duplicate"
          exact
          element={
            <PrivateRoute>
              <MinimalContainer>
                <DuplicateDeal />
              </MinimalContainer>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/deals/search"
          element={
            <PrivateRoute>
              <ThemeProvider>
                <AttributeProvider>
                  <SearchProviders>
                    <AppContainerNew toolbar={<SearchToolbar />}>
                      <DealHistoric />
                    </AppContainerNew>
                  </SearchProviders>
                </AttributeProvider>
              </ThemeProvider>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/deals/upload"
          element={
            <PrivateRoute>
              <AppContainer>
                <DealUpload />
              </AppContainer>
            </PrivateRoute>
          }
        />
        {/* Editor Deal List */}
        <Route
          exact
          path="/deals/list"
          element={
            <PrivateRoute>
              <AppContainer>
                <DealList />
              </AppContainer>
            </PrivateRoute>
          }
        />

        {/* Direct deal editor routes to ensure dealId param can be used. Necessary because the catch-all route below results in no param being available from react-router. */}
        <Route
          path="/deals/editor"
          element={
            <PrivateRoute>
              <AppContainer hasWizard>
                <DealWizard />
              </AppContainer>
            </PrivateRoute>
          }
        >
          {/* new deals */}
          {WizardSteps.map(step => (
            <Route key={step.id} path={`${step.to}`} element={step.component} />
          ))}
          {/* existing deals */}
          {WizardSteps.map(step => (
            <Route
              key={step.id}
              path={`:dealId/${step.to}`}
              element={step.component}
            />
          ))}
        </Route>

        {/* For non-matching routes we pass them along to DealWizard -
					this allows the DealWizard router to match paths like /deals/editor/buyer-and-supplier. */}
        <Route
          path="/deals"
          element={
            <PrivateRoute>
              <AppContainer hasWizard>
                <DealWizard />
              </AppContainer>
            </PrivateRoute>
          }
        />

        <Route
          exact
          path="/dashboard"
          element={
            <PrivateRoute>
              <AppContainer>
                <Dashboard />
              </AppContainer>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/odo-buyers"
          element={
            <PrivateRoute>
              <AppContainer>
                <Dashboard />
              </AppContainer>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/calculator"
          element={
            <PrivateRoute>
              <AppContainer>
                <Calculator />
              </AppContainer>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/"
          element={
            <PrivateRoute>
              <AppContainer>
                <Dashboard />
              </AppContainer>
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            <PrivateRoute>
              <AppContainer>
                <Dashboard />
              </AppContainer>
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
};

export default RoutesComponent;
