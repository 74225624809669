import type { ButtonHTMLAttributes, MouseEvent, ReactNode } from 'react';
import styles from './button.module.css';

const Button = ({
  children,
  size,
  primary,
  colorless,
  outlined,
  plain,
  ...restProps
}: {
  children: ReactNode;
  size?: 'full' | 'medium' | 'small';
  primary?: boolean;
  colorless?: boolean;
  outlined?: boolean;
  plain?: boolean;
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
} & ButtonHTMLAttributes<HTMLButtonElement>) => (
  <button
    className={[
      styles.button,
      ...(size === 'full'
        ? [styles.full]
        : size === 'medium'
        ? [styles.medium]
        : size === 'small'
        ? [styles.small]
        : []),
      ...(primary ? [styles.primary] : []),
      ...(colorless ? [styles.colorless] : []),
      ...(outlined ? [styles.outline] : []),
      ...(plain ? [styles.plain] : []),
    ].join(' ')}
    {...restProps}
  >
    <div className={styles.contentWrapper}>{children}</div>
  </button>
);

export default Button;
