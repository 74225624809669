import type { ReactNode } from 'react';
import { useMemo, useState } from 'react';
import type { EditorProductInterface } from '@odo/types/portal';
import ProductContext from './context';
import { EMPTY_PRODUCT } from '@odo/contexts/product-editor';

const ProductProvider = ({ children }: { children: ReactNode }) => {
  const [product, setProduct] = useState<EditorProductInterface>(EMPTY_PRODUCT);

  const value = useMemo(() => ({ product, setProduct }), [product]);

  return (
    <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
  );
};

export default ProductProvider;
