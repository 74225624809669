import { html } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { styles } from './css/cardFlip.css.js';

/**
 * A Card control that has 2 faces and can flip between them.
 *
 * @export
 * @class CardFlip
 * @extends {CustomLitElement}
 */
export class CardFlip extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			width: { type: String },
			height: { type: String },
			cbClick: { attribute: false },
		};
	}


	constructor() {
		super();
		this.width = '20rem';
		this.height = '26rem';
	}


	/**
	 * Card is being flipped
	 *
	 * @param {Event} event
	 * @memberof CardFlip
	 * @private
	 */
	flip(event) {
		console.debug('CardFlip: Flip', event);
		const card = event.target.parentElement;
		card.classList.toggle('is-flipped');

		// dont cancelBubble as this bubbles up the the card-flip-holder that needs it
		// event.cancelBubble = true; // Stop input events from bubbling up
		// event.preventDefault();

		const detail = {
			source: this.tagName,
			id: this.id,
		};
		event = new CustomEvent('click', { detail, bubbles: true, composed: true, cancelable: true });
		this.dispatchEvent(event);
		console.debug('CardFlipHolder: Flip', event);

		if (this.cbClick) this.cbClick(event);
	}

	render() {
		return html`
		${this.addCss()}

		<div class="scene" style="width: ${this.width}; height: ${this.height};">
			<div class="card" @click=${this.flip}>
				<div class="card-face card-face-front">
					<div class="card-inner">
						<slot name="front"></slot>
					</div>
				</div>
				<div class="card-face card-face-back" >
					<div class="card-inner">
						<slot name="back"></slot>
					</div>
				</div>
			</div>
		</div>
	 `;
	}
}
