import { html } from 'lit';
import { ref } from 'lit/directives/ref.js';
import { BaseInputControl } from '../baseClasses/BaseInputControl';
import '../../rps-input-label.js';
import { styles } from './css/radio.css.js';
export class Radio extends BaseInputControl {
	static styles = styles;

	static get properties() {
		return {
			layout: { type: String },
			items: { type: Array },
			labelOnLeft: { type: Boolean },
		};
	}

	constructor() {
		super();
		this.defaultValue = "";
		this.layout = "column";							// "column" or "row"
		this.items = [];
		this.labelOnLeft = false;
		this.internalValue = this.defaultValue;	// the radio control differs from other input controls as it does not have a "input control"
	}

	/**
	 * Fires an event and callback when the user clicks on a radio button
	 *
	 * @param {*} e
	 * @memberof Radio
	 */
	inputChanged(e) {
		e.stopPropagation();

		console.debug('Radio:inputChanged', e);
		this.validate();
		this.cache = e.target.value;
		this.internalValue = e.target.value;		// save so the (get value) works

		const detail = {
			value: e.target.value,
			source: this.tagName,
			id: e.target.id,
			name: this.name,
			radio: e.target,
		};
		e = new CustomEvent('input', { bubbles: true, cancelable: true, composed: true, detail });
		this.dispatchEvent(e);

		if (this.cbInput) this.cbInput(e);
	}


	/**
	* return the value for this control
	*
	* @readonly
	* @memberof Radio
	*/
	get value() {
		return this.internalValue;
	}


	/**
	 * Set the value of this control from code.
	 *
	 * @readonly
	 * @memberof Radio
	 */
	set value(newValue) {
		this.internalValue = newValue;
		this.requestUpdate();

		return this.internalValue;
	}

	/**
	 * Change the class to reflect the validity of the control
	 *
	 * @memberof Radio
	 */
	validate() {
		let isValid = false;

		if (this.required === false) {
			isValid = true;
		} else {
			const nodes = this.renderRoot.querySelectorAll('input');
			nodes.forEach(node => {
				if (node.checked) {
					isValid = true;
				}
			});
		}
		console.debug('Radio:validate', isValid);

		this.labelControl.classList.toggle('warning', !isValid);
		this.labelText(isValid);

		return isValid;
	}



	/**
	 * Render the radio buttons
	 *
	 * @return {Html} 
	 * @memberof Radio
	 * @private
	 */
	renderItems() {
		return this.items.map((e, key) => {
			const cache = (this.cache && this.cache.length > 0) ? JSON.parse(this.cache) : undefined;
			const initial = (cache) ? cache.value : this.internalValue;					// use cached value or defaultValue
			const checked = initial === e.value;												// allow for string and number

			return html`
			<div class="radio-layout ${this.css}" >
				<input name=${this.name} type="radio" 
					id=${e.label}
					value=${e.value}
					?required=${this.required} ?disabled=${this.disabled} ?checked=${checked}
					@input=${this.inputChanged} @blur=${this.inputChanged}>
				<span></span>
				<rps-input-label class="inline-label-slot inline ${this.className}" 
					text="${e.label}" ${ref(this.labelCtrl)}
				></rps-input-label>
			</div>`
		})
	}

	render() {
		const alignment = this.labelOnLeft ? 'label-left' : 'label-right';

		return html`
			<div class="radio-button ${this.className}" ${ref(this.container)}>
				<rps-input-label 
					class="${this.className}" 
					text="${this.label}" 
					${ref(this.labelCtrl)}
					?required=${this.required} ?disabled=${this.disabled} hide=${this.hideLabel}
				></rps-input-label>
				<div class="radio-items ${this.layout} ${alignment}">
					${this.renderItems()}
				</div>
			</div>
		`;
	}

}

