import { html } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { styles } from './css/keyValueTable.css.js';

/**
 * KeyValueTable
 * Renders data in a simple tabulated format: Labels on left, values on right.
 * @class
 * @element
 */
export class KeyValueTable extends CustomLitElement {
	static get properties() {
		return {
			data: { type: Object },
			rows: { type: Array }
		}
	}

	static styles = styles;

	constructor() {
		super();

		/** @type {Array.<KeyValueTableRow>} */
		this.rows = [];
		/** @type {Object} */
		this.data = {};
	}

	/**
	 * Creates a row child element.
	 * Returns undefined for rows that don't contain any data
	 *
	 * @private
	 * @param {KeyValueTableRow} row - row definition for current row
	 * @returns {LitElement|undefined}
	 */
	_row(row) {
		let value = this.data[row.key];

		// Check if this row has a transform function attached to it.
		if (row.transform && typeof row.transform === 'function') {
			try {
				// Set value to result of transform
				value = row.transform(this.data[row.key], this.data, row);
			} catch (err) {
				console.warn(`KeyValueTable:row() exception thrown: ${err}`);
			}
		}

		// If no value is present in this.data[row.key] AND no value is
		// computed as the result of calling a transform method, return
		// undefined (Skips rendering this row entirely)
		if (value === undefined) return;

		return html`
			<dt class="rowCell rowLabel">${row.label}</dt>
			<dd class="rowCell">${value}</dd>
		`
	}

	render() {
		return html`
			${this.addCss()}
			<dl class="container">
				${this.rows.map(row => this._row(row))}
			</dl>
		`;
	}
}

/**
 * @callback KeyValueTransformFunc
 * @param {any} [value] - Incoming value for the current row (May be null/undefined for computed rows)
 * @param {Object} [data] - The full data set passed to this KeyValueTable
 * @param {Array.<KeyValueTableRow>} [rows] - The row definitions object associated with this KeyValueTable instance
 * @returns {any} Value to render in this cell
 */

/**
 * @typedef {Object} KeyValueTableRow
 * @property {String} label - Label to display for this row
 * @property {String} key - Key name to bind this row to (i.e.: 'name' accesses data.name)
 * @property {KeyValueTransformFunc} [transform] - Transform function to run on this row
 */

/**
 * @typedef {CustomLitElement} KeyValueTable
 * @property {Array.<KeyValueTableRow>} rows - Row definitions
 * @property {Object} data - Data to render
 */

/**
 * @typedef {KeyValueTable} rps-key-value-table
 */
