import { html } from 'lit';
import { ref, createRef } from 'lit/directives/ref.js';
import '../../rps-modal';
import { styles } from './css/input-modal.css.js';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import '../../rps-input';

export class InputModal extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			opened: { type: Boolean },
			headerText: { type: String },
			label: { type: String },
			type: { type: String },
			cbConfirm: { attribute: false },
			cbCancel: { attribute: false },
		};
	}

	constructor() {
		super();
		this.opened = false;
		this.headerText = "Enter value";
		this.label = "Value";
		this.type = "text";
		this.modalRef = createRef();
		this.inputRef = createRef();
	}

	/**
	 * Return a reference to the <rps-input>
	 *
	 * @readonly
	 * @memberof OkModal
	 */
	get inputControl() {
		return this.inputRef.value;
	}

	/**
	 * Return a reference to the <rps-modal>
	 *
	 * @readonly
	 * @memberof OkModal
	 */
	get modalControl() {
		return this.modalRef.value;
	}

	/**
	 * Hide the modal
	 *
	 * @param {Event} event
	 * @memberof InputModal
	 */
	hide() {
		this.modalControl.show();
	}


	/**
	 * Show the modal
	 *
	 * @memberof InputModal
	 */
	show() {
		this.modalControl.show();
	}

	/**
	 * Fired when the user clicks on the cancel button
	 *
	 * @param {Event} event
	 * @memberof OkModal
	 */
	_confirm(event) {
		event.stopPropagation()
		event.preventDefault();

		console.debug('InputModal: confirm', event);
		const detail = {
			source: this.tagName,
			id: this.id,
			name: this.name,
			control: this.inputControl,
			value: this.inputControl.value,
		};
		const e = new CustomEvent('confirm', { detail, bubbles: true, composed: true, cancelable: true });
		this.dispatchEvent(e);
		if (this.cbConfirm) this.cbConfirm(e);
	}

	/**
	 * Fired when the user clicks on the Cancel button
	 *
	 * @param {event} event
	 * @memberof OkModal
	 */
	_cancel(event) {
		event.stopPropagation()
		event.preventDefault();

		console.debug('InputModal: cancel');
		const detail = {
			source: this.tagName,
			id: this.id,
			name: this.name,
			control: this.inputControl,
			value: this.inputControl.value,
		};
		const e = new CustomEvent('cancel', { detail, bubbles: true, composed: true, cancelable: true });
		this.dispatchEvent(e);

		if (this.cbCancel) this.cbCancel(event);

		this.hide(event);
	}

	/**
	 * Check the key that was pressed.
	 * If 'Enter' Confirm this modal.
	 * If 'Escape' Cancel
	 *
	 * @param {Event} event
	 * @memberof InputModal
	 */
	keyPressed(event) {
		//console.debug('InputModal:keyPressed', event);
		if (event.key === 'Enter') this.modalControl._confirm(event);
		if (event.key === 'Escape') this.modalControl._cancel(event);
	}

	render() {
		return html`
			${this.addCss()}

			<div class="input-modal-container">
				<rps-modal ?opened=${this.opened} popup ${ref(this.modalRef)}
					@confirm="${this._confirm}" @cancel="${this._cancel}"
					class="${this.className}" headerText=${this.headerText}
				>
					<rps-input name="input-modal-input" type="${this.type}" label="${this.label}" ${ref(this.inputRef)} @keydown=${this.keyPressed}></rps-input>
				</rps-modal>
			</div>
		`;
	}
}
