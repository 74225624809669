import { gql } from '@apollo/client';

export const GET_DROPDOWN_VALUES = gql`
  query GetDropDownValues($name: AttributeCodeEnum) {
    getDropDownValues(name: $name) {
      key
      value
    }
  }
`;

const CATEGORY_FRAGMENT = gql`
  fragment CategoryDetails on CategoryTree {
    id
    parentCategory
    level
    name
    isActive
    urlKey
    includeInMenu
    displayMode
  }
`;

// NOTE: please be very careful using this request. It seems to be quite expensive.
export const GET_CATEGORY_TREES = gql`
  ${CATEGORY_FRAGMENT}
  query getCategoryTrees {
    getCategoryTrees {
      ...CategoryDetails
      children {
        ...CategoryDetails
        children {
          ...CategoryDetails
        }
      }
    }
  }
`;

export const DROPDOWN = {
  APPLY_MAP: 'APPLY_MAP',
  DISPLAY_ACTUAL_PRICE: 'DISPLAY_ACTUAL_PRICE',
  WARRANTY_PERIOD: 'WARRANTY_PERIOD',
  TAX_CLASS: 'TAX_CLASS',
  PLATFORM: 'PLATFORM',
  SUPPLIER_REPACKS: 'SUPPLIER_REPACKS',
  ATTRIBUTE_SET_ENUM: 'ATTRIBUTE_SET_ENUM',
  PRODUCT_TYPE_ENUM: 'PRODUCT_TYPE_ENUM',
  AREA: 'AREA',
  CONDITION: 'CONDITION',
  BUYERS: 'BUYERS',
  SUPPLIERS: 'SUPPLIERS',
  PRIORITY: 'PRIORITY',
  CAMPAIGN: 'CAMPAIGN',
  DEAL_TYPE: 'DEAL_TYPE',
  BUY_IN_STOCK_TYPE: 'BUY_IN_STOCK_TYPE',
  CUSTOMER_DELIVERY_TIME: 'CUSTOMER_DELIVERY_TIME',
  SURCHARGES: 'SURCHARGES',
};
