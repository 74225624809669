import { keyframes, styled } from 'goober';
import type { ButtonHTMLAttributes, ComponentType, ReactNode } from 'react';

const LOADING_INDICATOR_SIZE = 24;

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  backgroundColor?: string;
  color?: string;
  size?: 'none' | 'small' | 'medium' | 'large' | 'full';
  padding?: 'none' | 'small' | 'medium' | 'large';
  fontSize?: 'small' | 'medium' | 'large';
  btnType?: 'primary' | 'secondary' | 'outline' | 'plain' | 'error' | 'success';
  disabled?: boolean;
  onClick?: () => void;
  iconLeft?: ComponentType;
  iconRight?: ComponentType;
  shadow?: 'none' | 'sm' | 'base' | 'md' | 'lg' | 'xl';
  children: ReactNode;
  loading?: boolean;
}

const Button = ({
  size = 'medium',
  padding,
  fontSize,
  btnType = 'primary',
  backgroundColor,
  color,
  iconLeft: IconLeft,
  iconRight: IconRight,
  shadow = 'none',
  children,
  loading = false,
  ...props
}: ButtonProps) => (
  <ButtonContainer
    className={[
      'button',
      `button--${size}`,
      `button--${btnType}`,
      `shadow--${shadow}`,
      ...(padding ? [`padding--${padding}`] : []),
      ...(fontSize ? [`fontSize--${fontSize}`] : []),
      ...(loading ? ['loading'] : []),
    ].join(' ')}
    style={{ backgroundColor, color }}
    {...props}
  >
    {IconLeft && <IconLeft />}
    {children}
    {IconRight && <IconRight />}
  </ButtonContainer>
);

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
`;

const ButtonContainer = styled('button')`
  &.button {
    font-weight: 700;
    border: 0;
    border-radius: 4px;
    cursor: pointer;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    line-height: 1;
    transition: all 0.3s ease;
    grid-column-gap: 1rem;
    column-gap: 10px;
    letter-spacing: 0.04em;
    position: relative;
    outline-offset: 4px;
    &:hover {
      opacity: 0.7;
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      background: var(--state-disabled);
      border-color: var(--state-disabled);
      color: var(--background-primary);
    }
    &.loading::before {
      content: '';
      display: inline-block;
      position: absolute;
      border: 2px solid transparent;
      border-left-color: white;
      border-radius: 50%;
      box-sizing: border-box;

      top: calc(50% - ${LOADING_INDICATOR_SIZE / 2}px);
      left: calc(50% - ${LOADING_INDICATOR_SIZE / 2}px);
      width: ${LOADING_INDICATOR_SIZE}px;
      height: ${LOADING_INDICATOR_SIZE}px;

      animation: ${rotateAnimation} 0.8s linear infinite;
    }
  }
  &.button--primary {
    color: var(--background-primary);
    background-color: var(--brand-primary);
    border: 1px solid var(--brand-primary);
  }
  &.button--secondary {
    color: #333;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
  &.button--outline {
    color: var(--brand-primary);
    background-color: transparent;
    border: 1px solid var(--brand-primary);
    &:hover {
      opacity: 1;
      color: var(--background-primary);
      background-color: var(--brand-primary);
    }
    &:disabled {
      color: var(--state-disabled);
      background-color: transparent;
    }
  }
  &.button--plain {
    color: var(--brand-primary);
    background-color: transparent;
    &:disabled {
      color: var(--state-disabled);
      background-color: transparent;
    }
  }
  &.button--error {
    color: var(--background-primary);
    background-color: var(--brand-alt-pink-dark);
  }
  &.button--success {
    color: var(--background-primary);
    background-color: var(--brand-alt-green-dark);
  }

  &.button--none {
    font: var(--button-text-s);
    padding: 0;
  }
  &.button--small {
    font: var(--button-text-s);
    padding: 10px 16px;
  }
  &.button--medium {
    font: var(--button-text-m);
    padding: 11px 20px;
  }
  &.button--large {
    font: var(--button-text-l);
    padding: 12px 24px;
  }
  &.button--full {
    font: var(--button-text-l);
    padding: 12px 24px;
    width: 100%;
    display: flex;
  }
  > .left {
    position: absolute;
    left: 10px;
  }
  > .right {
    position: absolute;
    right: 10px;
  }

  &.padding--none {
    padding: 0;
  }
  &.padding--small {
    padding: 10px 16px;
  }
  &.padding--medium {
    padding: 11px 20px;
  }
  &.padding--large {
    padding: 12px 24px;
  }

  &.fontSize--small {
    font: var(--button-text-s);
  }
  &.fontSize--medium {
    font: var(--button-text-m);
  }
  &.fontSize--large {
    font: var(--button-text-l);
  }
`;

export default Button;
