import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { RPSInput } from '@rps/web-components/build/react-wrappers';
import { parseDecimal } from './../../utils/parseDecimal';

const decimalRegex = /^(([\-]?\d+([\.,]\d*)?)|([\.,]\d+))$/;
/**
 * Wrapper around RPSInput to allow for strict decimal inputs with
 * support for either comma or periods as the separator.
 *
 * Also overrides the paste event to allow copying of fields that contain numbers and text i.e.: "R1,321.55" or "55.2kg"
 *
 * @param {Object} props
 * @param {*} props.value
 * @param {String} [props.type="text"]
 * @param {String} props.label
 * @param {Function} props.cbInput
 */
export const DecimalInput = ({
  label,
  value,
  cbInput,
  type = 'text',
  ...props
}) => {
  const ref = useRef();

  const handleInput = useCallback(
    ev => {
      const val = ev.detail.value;
      const match = val.match(decimalRegex);
      if (match) {
        ev.detail.value = match[0];
        if (cbInput) {
          cbInput(ev);
        }
      } else {
        if (ref.current) {
          if (val !== '') {
            if (val === '-') {
              ev.detail.value = '-';
              if (cbInput) {
                cbInput(ev);
              }
            }
            ref.current.value = value;
          } else {
            if (cbInput) {
              cbInput({
                detail: {
                  source: 'RPS-INPUT',
                  value: '',
                },
              });
            }
          }
        }
      }
    },
    [cbInput, value]
  );

  useEffect(() => {
    let inputRef = ref.current;

    const handlePaste = ev => {
      ev.preventDefault();

      let inputData = (ev.clipboardData || window.clipboardData).getData(
        'text'
      );
      if (type === 'text') {
        return inputData;
      }
      inputData = parseDecimal(inputData);

      if (!isNaN(inputData)) {
        inputRef.value = inputData;
        handleInput({ detail: { value: inputData.toString() } });
      }
    };

    if (inputRef) {
      inputRef.addEventListener('paste', handlePaste);
    }

    return () => {
      if (inputRef) inputRef.removeEventListener('paste', handlePaste);
    };
  }, [handleInput, type]);

  return (
    <RPSInput
      label={label}
      innerRef={ref}
      value={value}
      cbInput={handleInput}
      type={type}
      {...props}
    />
  );
};

DecimalInput.propTypes = {
  value: PropTypes.any,
  cbInput: PropTypes.func,
  label: PropTypes.string,
  type: PropTypes.string,
};
