import { html } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { styles } from './css/spinnerDots.css.js';
export class SpinnerDots extends CustomLitElement {
	static styles = styles;

	/**
	 * Hide this spinner
	 *
	 * @memberof SpinnerDots
	 */
	hide() {
		this.setAttribute('style', 'display: none');
	}

	/**
	 * Display this spinner if it is hidden
	 *
	 * @memberof SpinnerDots
	 */
	show() {
		this.setAttribute('style', 'display: inline-block');
	}

	render() {
		return html`
			${this.addCss()}
			<div class="container">
				<div class="ball"></div>
				<div class="ball"></div>
				<div class="ball"></div>
			</div>
		`;
	}
}
