import type { GridProduct } from '@odo/components/search/types';
import { Flex } from '@odo/components/elements/layout';
import { Text } from '../grid';

const Deal = ({ data }: { data: GridProduct }) => {
  return (
    <Flex flexDirection="column" justifyContent="start" rowGap="5px">
      <Text style={{ fontWeight: 'bold' }}>Deal Type:</Text>
      <Text>{data.dealType ? (data.dealType || []).join(', ') : ''}</Text>
      <Text style={{ fontWeight: 'bold' }}>Campaign:</Text>
      <Text>
        {data.campaign?.toLowerCase() === 'none' ? '' : data.campaign}
      </Text>
    </Flex>
  );
};

export default Deal;
