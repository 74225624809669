/**
 * @name rps-input
 * @type {Input}
 * 	A component that provides various controls based on the <input> control
 * 	See "type" attribute for the various types of controls
 *
 * @attributes + @properties 						| Changes trigger a re-render
 * Casing usages:
 * 	* Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String}		culture	 		| Default is 'en-ZA' used internally for currencies
 * 	@property {String}		currency 		| Default is 'ZAR' used internally for currencies
 * 	@property {Boolean}		required 		| is input required
 * 	@property {Boolean}		readonly 		| is this control a readonly control?
 * 	@property {String}		name 				| form name
 * 	@property {String}		label 			| label to display]
 * 	@property {Number}		min 				| minimum number (or date) value
 * 	@property {Number}		max		 		| maximum number (or date) value
 * 	@property {Number}		minLength 		| minimum text length
 * 	@property {Number}		maxLength 		| maximum text length
 * 	@property {String}		defaultValue 	| default value
 * 	@property {Boolean} 		caching 			| does this control use Caching when reading and writing values
 * 	@property {String}		type 				| default is "text"
 * 															Possible values are:
 * 															currency, tel, url, email, number, location,
 * 															datetime, month, week, time, calendar, password, search, file
 * 	@property {String}		accept			| Used to specify the type of file to be able to select when the type='file'
 * 	@property {String}		pattern 			| RegEx expression for validation, default is '*
 * 	@property {String}		errorMessage 	| Optional error message that displays in place of the label when the input is invalid
 * 	@property {String}		placeHolder 	| text to display when the input is empty
 * 	@property {String}		fileReaderType	= 'readAsDataURL'	| How do you want the file data to be read in?
 *
 *		@property {Boolean}		[selectOnFocus=false]  | Should input contents be selected when the element is focused?
 * 																					possible values are: ['readAsDataURL', 'readAsArrayBuffer', 'readAsBinaryString', 'readAsText']
 * 	@property {attribute: false}	filesData = []					| Contains the results of the files that were read in where the type='file'
 * 	@property {String}		customCss 		| Specify a custom .css file to include, use cssPath to override elements,
 * 															DO NOT override the styling definition by re-defining the css styling
 * 															Put this file into the *window.cssPath* folder @see {link README.md}
 * 															eg: /public/css (where the other css files are)
 * 															Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}		css				| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 *
 *
 * @methods
 * 	validate()	 									| returns whether the input is valid or not
 * 	clear()											| Clear all the cached value used by this control (or any other control using the same name)
 * 	labelText(newLabelText)						| Change the label for this control
 * 	currencySymbol()								| return the Currency symbol for the culture and currency
 * 	focus()											| Focus the cursor into the input control
 *
 * @properties (JS Only)
 * 	inputControl (get only)						| Return a reference to the input control
 * 	labelControl (get only)						| Return a reference to the label control
 * 	value (get and set)							| allow getters and setters for the value of the select.
 * 														| eg: oControl.value = "newValue";
 * 														| ie: console.log(oControl.value);
 * 	isValid (get only)							| Is this controls state valid?
 *
 * @events
 * 	input												| fires whenever input value is changed
 * 	iconClick										| fires whenever the icon is clicked
 *  focus											| fires when the input control gains focus
 *  blur											| fires when the input control loses focus
 *
 * @callbacks
 * 	cbInput											| when the user input changes
 * 	cbIconClick										| when the icon is clicked
 *  cbFocus											| when the input control gains focus
 *  cbBlur											| when the input control loses focus
 *
 * @slots
 * 	No slots
 *
 *
 * @example @Lit
 * @basic
 * ==============================
 * import '@rps-wc/wc/rps-input';
 *
 * 	<rps-input name="oldmanname" label="First name" required minLength={3} maxLength={5}
 * 		${ref(input)}
 * 		defaultvalue="Billy" pattern="[a-z]{3,15}"
 * 		errormessage="enter a name between 3 and 15 characters long"
 * 		@input=${(e) => console.log('input', e)}
 * 		@iconClick=${(e) => console.log('icon click', e)}
 * 		caching
 * 	>
 * 	</rps-input>
 *
 *
 * @example @ReactJS
 * @basic
 * ==============================
 * import '@rps-wc/wc/rps-input';
 *
 *  	useEffect(() => {
 *				input.current.cbInput = (e) => console.log(e);
 *				input.current.cbIconInput = (e) => console.log(e);
 *  	}, [])
 *
 * return (
 * 	<rps-input name="oldmanname" label="First name" required minLength={3} maxLength={5}
 * 		ref={input}
 * 		defaultvalue="Billy" pattern="[a-z]{3,15}"
 * 		errormessage="enter a name between 3 and 15 characters long"
 * 	>
 * 	</rps-input>
 * )
 *
 *
*/

import { Input } from './src/input/Input';
if (!window.customElements.get('rps-input'))
	window.customElements.define('rps-input', Input);

