/**
 * @name rps-line-chart2d
 * @type {LineChart2d} 
 * 	a Line chart control
 * @Notes
 * 	An Array of arrays needs to be used. [xval, yval]
 * 	eg: [["Jan",9200], ["Feb",8600]]
 * 
 * @attributes + @properties 				| Changes trigger a re-render
 * Casing usages								| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String}	customCss	| Specify a custom .css file to include, use cssPath to override elements,
 * 													DO NOT override the styling definition by re-defining the css styling
 * 													Put this file into the *window.cssPath* folder @see {link README.md}
 * 													eg: /public/css (where the other css files are)
 * 													Suggested use: use sub-folders, dont put it all into the base
 * 												
 * 	@property {String}	css			| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 
 * 	@property {String}	heading 		| Heading for Chart
 * 	@property {Array}		data 			| Array of objects to display
 * 												eg: [
 * 														[
 * 															[0, 120], [20, 60], [40, 80], [60, 20], [80, 80], [100, 80], [120, 60], [140, 100],
 * 															[160, 90], [180, 80], [200, 110], [220, 10], [240, 70], [260, 100], [280, 100],
 * 															[300, 40], [320, 0], [340, 100], [360, 100], [380, 120], [400, 60], [420, 70], [440, 80]
 * 														],
 * 														[
 * 															[0, 50], [20, 95], [40, 65], [60, 105], [80, 80], [100, 77], [120, 43], [140, 90],
 * 															[160, 60], [180, 50], [200, 70], [220, 90], [240, 70], [260, 60], [280, 110],
 * 															[300, 120], [320, 70], [340, 100], [360, 88], [380, 120], [400, 35], [420, 120], [440, 25]
 * 														],
 * 													];
 * 													NB: the first element in the array is the x-axis value. eg: months or years names
 * 														The second element in the array is the actual value to plot
 * 												
 * 	@property {Array} colors				| Optional: Array of colors to use for the lines
 * 														default: ['blue', 'red', 'black', 'green', 'yellow', 'purple']
 * 												
 * 	@property {Number} yStep 				| Optional: default=20 every [x] elements should be used in the y-Axis
 * 	@property {Number} xStep 				| Optional: default=2 every [x] elements should be used in the x-Axis
 * 	@property {String} yAxisLabel 		| Label for x-axis
 * 	@property {String} xAxisLabel 		| Label for y-axis
 * 	@property {Number} circleRadius 		| Optional: default=4 The radius (size) of the line points
 * 	@property {Number} xAxisPadding 		| Optional: default=10 Padding from the yAxis line to "pad". ie: start lines after this padding
 * 	@property {Number} strokeWidth 		| Optional: default=2 the thickness of each line
 * 	@property {Array} legend 				| Array of legends for the data. ie: the descriptions for each line in an array of strings
 * 
 * 
 * @methods
 * 	setData(newData)							| Use this method to change to charts data manually
 * 														When setting new data the "legend" will probably have to change as well.
 * 														Dont forget it
 * 
 * @events
 * 	click()										| returns the element that was clicked.
 * 														The chartValue(value of this element in data)
 * 														and pos(index on data array) are returned
 * @callbacks
 * 	cbClick										| called when the click event is fired
 * 
 * @slots
 * 	None
 * 
 * @example+ @lit
 * @Basic
 * ======
 * import '@rps-wc/rps-line-chart2d';
 * 
 * 	<rps-bar-chart2d>
 * 	</rps-bar-chart2d>
 * 
 * 
*/
import { LineChart2d } from './src/charts/LineChart2d';
if (!window.customElements.get('rps-line-chart2d'))
	window.customElements.define('rps-line-chart2d', LineChart2d);

