import { html } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { ifDefined } from 'lit/directives/if-defined.js';
import { ref, createRef } from 'lit/directives/ref.js';
import { styles } from './css/video.css.js';


export class Video extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			src: { type: String },
			needsIframe: { type: Boolean },
			videoSources: { type: Array },
			label: { type: String },
			controls: { type: Boolean },
			crossOrigin: { type: String },		// possible values are  ['anonymous','use-credentials']
		};
	}

	constructor() {
		super();
		this.label = ""
		this.videoSources = [];
		this.needsIframe = false;
		this.controls = false;
		this.crossOrigin = '';
		this.videoRef = createRef();
	}

	/**
	 * Return a reference to the video control.
	 * @description Will be 'undefined' if this.needsIframe === true
	 *
	 * @readonly
	 * @memberof Video
	 */
	get videoControl() {
		return this.videoRef?.value;
	}


	/**
	 * Generate all the video sources
	 *
	 * @return {Html} 
	 * @memberof Video
	 * @private
	 */
	_videoSources() {
		return this.videoSources.map(source => {
			return html`<source media=${ifDefined(source.media)} srcset=${ifDefined(source.srcset)} src=${ifDefined(source.src)} 
				type=${ifDefined(source.type)} sizes=${ifDefined(source.sizes)}>`;
		});
	}


	/**
	 * Generate the video control
	 *
	 * @return {Html} 
	 * @memberof Video
	 */
	generateVideo() {
		const cors = this.crossOrigin === '' ? '' : `crossorigin="this.crossOrigin"`;

		if (this.needsIframe === false) {
			return html`
			<figure>
				<video src=${ifDefined(this.src)}
					?controls=${this.controls}
					${cors}
					${ref(this.videoRef)}
				>
					${this._videoSources()}
					Your browser does not support the
            	<code>video</code> element.
				</video>
				<figcaption>${this.label}</figcaption>
			</figure>
			`;
		}

		return html`
			<div class="video-place-holder">
				<figure>
					${this._videoSources()}
					<iframe src="${this.src}"></iframe>
					<figcaption>${this.label}</figcaption>
				</figure>
			</div>
		`;
	}

	render() {
		return html`
			${this.addCss()}
			<div class="video-container ${this.className}">
				${this.generateVideo()}
			</div>
		`;
	}
}


