export function checkIfLiveDeal(deal) {
  if (!deal) return false;

  const today = new Date();
  const dealStart = new Date(deal.product.activeFromDate);
  const dealEnd = new Date(deal.product.activeToDate);
  dealStart.setHours(0);
  dealEnd.setHours(23, 59, 59);

  return today >= dealStart && today <= dealEnd;
}
