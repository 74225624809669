/*
https://developer.mozilla.org/en-US/docs/Web/CSS/repeat
https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-columns

This forms the base of the <rps-grid>
styling, sorting, filtering, paging, headers and footers code is here
Code splitted for maintainability and readability
*/

import { html } from 'lit';
import { ref, createRef } from 'lit/directives/ref.js';
import '../../rps-pagination.js';
import '../../rps-input.js';
import { iconNames } from '../../svg-icons';
import '../../rps-svg';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { styles } from './css/gridBase.css.js';

export class GridBase extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			data: { type: Array },
			columns: { type: Array },
			rowSize: { type: String },
			culture: { type: String },
			currencySymbol: { type: String },
			pageSize: { type: Number },
			currentPage: { type: Number },
			hasFilter: { type: Boolean },
			filterOnColumns: { type: Array },
			columnSize: { type: String },
			hideHeader: { type: Boolean },
			template: { type: String },	// Must this display in a template fashion or not? if a template, specify here
			templatesInRow: { type: Number },	// ONLY applicable if you are using a template
			cbFirstUpdated: { attribute: false },
			condenseCells: { type: Boolean },
			layoutButtons: { attribute: false },
			toolbarButtons: { type: Array },
		};
	}

	constructor() {
		super();
		this.rowSize = "medium";			// or "small" or "large"
		this.lastSortedColumn;				// the last column that was sorted by the user
		this.culture = 'en-ZA';				// default culture to use
		this.currencySymbol = "ZAR";		// ISO symbol for the desired currency
		this.pageSize = 10;					// default amount of items on 1 page
		this.currentPage = 1;				// start on page 1 as a default
		this.data = [];
		this.columns = [];
		this.itemCount = 0;					// how many items are in the Array to display
		this.hasFilter = false;				// does this grid have filtering
		this.filteredData = undefined;	// Contains the filtered data for the grid
		this.filterOnColumns = undefined	// default is to use all columns to filter, override this to specify columns to use
		this.previousFilterText = undefined	// What was the last search string?, used to optimize filtering
		this.filterCtrl = createRef();
		this.pageCtrl = createRef();
		this.toolbarRef = createRef();
		this.columnSize = 'max-content'; // 'max-content', 'min-content', '1fr'
		this.hideHeader = false;			// must the header be hidden?
		this.condenseCells = false;
		this.layoutButtons = [
			{ id: "gridView", svg: `${iconNames.gridView}`, title: "Grid view" },
			{ id: "cardView", svg: `${iconNames.cardView}`, title: "Card view" },
		];
		this.toolbarButtons = [];
	}

	/**
	 * Return the nth() header control
	 *
	 * @param {Number} columnNumber
	 * @returns
	 * @memberof GridBase
	 */
	getHeaderColumn(columnNumber) {
		if (columnNumber < 0 || columnNumber >= this.getColumns().length) {
			console.error('GridBase: getColumn must be >= 0 and smaller than the amount of columns', columnNumber);
		}
		columnNumber++		//NB: css selectors start at 1 and not 0
		return this.renderRoot.querySelector(`.grid .header:nth-of-type(${columnNumber})`)
	}

	/**
	 * Display all the columns in their "mix-size" and add an extra column at the end that fills in the "remaining space"
	 *
	 * @return {Array} Columns + optional virtual blank column
	 * @memberof GridBase
	 */
	getColumns() {
		if (this.condenseCells) {
			return [...(this.columns || []), { headerText: "", key: '_' }]
		} else {
			return this.columns;
		}
	}

	/**
	 * Retrieve a reference to the filter input control
	 *
	 * @readonly
	 * @memberof GridBase
	 */
	get filterControl() {
		return this.filterCtrl?.value;
	}

	/**
	 * Fire an event when the grid has rendered the first time.
	 * Hook into this event to perform operations on the grid when it has rendered the first time
	 *
	 * @memberof GridBase
	 */
	firstUpdated() {
		const detail = {
			source: this.tagName,
		};

		const event = new CustomEvent('firstUpdated', { detail, bubbles: true, cancelable: true, composed: true })
		this.dispatchEvent(event);

		if (this.cbFirstUpdated) this.cbFirstUpdated(event)

		if (this.template) {
			// give time for the toolbar to display, then set the cardView as selected
			setTimeout(() => {
				this.toolbarControl.setActive("cardView");
			}, 100);
		}
	}


	/**
	 * Retrieve the page control
	 * Call dynamically as it might be added ot removed based on amount of items in the grid
	 * @readonly
	 * @memberof GridBase
	 */
	get pageControl() {
		return this.pageCtrl.value;
	}

	/**
	 * A reference to the toolbar control
	 *
	 * @readonly
	 * @memberof GridBase
	 */
	get toolbarControl() {
		return this.toolbarRef?.value;
	}

	/**
	 * The data that must be used as a datasource can change.
	 * It can be the data source, or the filtered data
	 *
	 * @readonly
	 * @memberof Grid
	 * @returns - The data to use (source or filtered)
	 */
	get data2Display() {
		if (this.filteredData) {
			this.itemCount = this.filteredData.length;
			return this.filteredData;
		} else {
			this.itemCount = this.data.length;
			return this.data;
		}
	}

	/**
	 * Main sort event
	 * This extracts the information needed to sort from the column clicked on, and calls the _sortData() method with that information
	 * Also updated the display of the each header sort display
	 *
	 * @param {*} event
	 * @memberof GridBase
	 */
	sort(event) {
		console.debug('grid:SORT', event.target);

		let header = event.target;
		if (event.target.tagName !== 'SPAN') {
			// if not on span, then take parent element
			header = event.target.parentElement;
		}
		const rpsSvg = header.querySelector('rps-svg');
		const sortOn = header.getAttribute('sorton');		// column name to sort on

		// no 'sort-asc' then its 'default' or in 'sort-desc'
		if (header.classList.contains('sort-asc') === false) {
			// sort ascending
			header.classList.remove('sort-desc');
			header.classList.add('sort-asc');
			rpsSvg.svg = iconNames.sortAscending;
			this._sortData(sortOn, true, header);
		} else {
			// sort descending
			header.classList.remove('sort-asc');
			header.classList.add('sort-desc');
			rpsSvg.svg = iconNames.sortDescending;
			this._sortData(sortOn, false, header);
		}

	}

	/**
	 * Sort the actual data (base or filtered)
	 *
	 * @param {Object} column - the Column to sort
	 * @param {Number} ascending - asc or desc as a number
	 * @memberof GridBase
	 */
	_sortData(column, ascending, header) {

		function _compare(val1, val2, column, ascending) {
			// convert to lowercase if a string for comparison
			const direction = ascending ? 1 : -1;
			const first = typeof val1[column] === 'string' ? val1[column].toLowerCase() : val1[column];
			const next = typeof val2[column] === 'string' ? val2[column].toLowerCase() : val2[column];

			if (first < next) {
				return -1 * direction;
			} else if (first === next) {
				return 0;
			} else {
				return 1 * direction;
			}
		}


		this.data2Display.sort((a, b) => {
			const columnDef = this.getColumns().find(col => col.key === column);
			let resultA = { ...a };
			let resultB = { ...b };
			if (columnDef && columnDef.data && typeof columnDef.data.transform === 'function') {
				resultA[column] = columnDef.data.transform(a[column], a, this.data);
				resultB[column] = columnDef.data.transform(b[column], b, this.data);
			}
			return _compare(resultA, resultB, column, ascending)
		});

		if (this.lastSortedColumn && this.lastSortedColumn != header) {
			this.lastSortedColumn.classList.remove('sort-desc');
			this.lastSortedColumn.classList.remove('sort-asc');
			const rpsSvg = this.lastSortedColumn.querySelector('rps-svg');
			rpsSvg.svg = iconNames.sort;
		}
		this.lastSortedColumn = header;

		this.requestUpdate();
	}

	/**
	 * Render the header, with sorting and alignment
	 *
	 * @returns
	 * @memberof GridBase
	 */
	header() {
		if (this.hideHeader) return;

		const cols = this.getColumns().map(col => {
			let textStyle = '';
			let rightPadded = '';

			if (col.data && col.data.type === 'number' || col.data && col.data.type === 'currency') {
				textStyle = 'right';
				if (col.sortable) {
					rightPadded = 'right-padded';
				}
			}

			if (col.sortable === true) {
				return html`
				<span class="header sortable ${textStyle}" @click=${this.sort} sorton=${col.key}>
					<rps-svg svg="${iconNames.sort}"></rps-svg>
					<strong class="${textStyle} ${rightPadded}">${col.headerText}</strong>
				</span>`
			} else {
				return html`
				<span class="header ${textStyle}">
					<strong class="${textStyle}">${col.headerText}</strong>
				</span>`
			}
		});

		if (this.template) {
			return html`<div class="header-row">${cols}</div>`;
		} else {
			return cols;
		}
	}

	/**
	 * After connecting to the DOM, determine the length of the items for the grid before rendering
	 *
	 * @memberof GridBase
	 */
	connectedCallback() {
		super.connectedCallback();
		this.itemCount = this.data.length;
		this.hasFooters = this.getColumns().findIndex(e => e.footer !== undefined) >= 0;
		// need to know whether a re-draw must be performed after a plus-minus action
		this.hasCustomFooters = this.getColumns().findIndex(e => e.footer !== undefined && e.footer.render) >= 0;
	}


	/**
	 * Format the data according to the column definition
	 *
	 * @param {*} data
	 * @param {Object} col - column containing the definition for this data
	 * @returns - the cssClass and the formatted data
	 * @memberof Grid
	 */
	_formatData(data, col, row = null) {
		let textStyle = '';

		if (col.data && col.data.transform) {
			if (typeof col.data.transform === 'function') {
				data = col.data.transform(data, row, this.data);
			} else {
				data = col.data.transform;
			}
		}

		if (col.data && col.data.type === 'date') {
			data = new Date(data).toLocaleString(this.culture);
			!(col.data.hasTime === true) ? data = data.substr(0, 10) : data;
			textStyle = 'date';
		} else if (col.data && col.data.type === 'number') {
			const decimals = col.data.decimals ? col.data.decimals : 0;
			if (typeof data === 'string') data = data * 1;		// ensure it is a number
			if (!data) data = 0;
			data = data.toFixed(decimals);
			textStyle = 'right number';
		} else if (col.data && col.data.type === 'currency') {
			data = new Intl.NumberFormat(this.culture, { style: 'currency', currency: this.currencySymbol }).format(data)
			textStyle = 'right currency';
		}

		return { data, textStyle };
	}

	/**
	 * Scan the rows data searching each column and check if the filter text is contained in this row
	 *
	 * @param {String} filterText
	 * @param {Object} row
	 * @returns {Boolean} - Row contains filter text
	 * @memberof GridBase
	 */
	_rowContainsFilter(filterText, row) {
		const columnMap = {};
		this.getColumns().forEach(column => columnMap[column.key] = column);

		const __transformColumn = (key, col) => {
			let colDef = columnMap[key];

			if (colDef) {
				if (colDef.data && colDef.data.transform) {
					if (typeof colDef.data.transform === 'function') {
						col = colDef.data.transform(col, row, this.data);
					} else {
						col = colDef.data.transform;
					}
				}
			}

			if (!col) {
				return '';
			} else {
				return col;
			}
		}

		if (this.filterOnColumns) {
			// Filter on columns specified by user.
			const len = this.filterOnColumns.length;
			for (let i = 0; i < len; i++) {
				const key = this.filterOnColumns[i];
				let col = row[key];
				col = __transformColumn(key, col);

				if (typeof col !== 'string') col = col.toLocaleString();
				col = col.toLowerCase();

				if (col.indexOf(filterText) >= 0) return true;
			}
		} else {
			// Filter on all columns
			for (const key in columnMap) {
				let col = row[key];
				col = __transformColumn(key, col);

				if (typeof col !== 'string') col = col.toLocaleString();
				col = col.toLowerCase();

				if (col.indexOf(filterText) >= 0) return true;
			}
		}

		return false;
	}

	/**
	 * Filter the data for the Grid
	 * @description - This routine uses the previous filtered data items where possible as a way to reduce filtering
	 * @param {Event} event
	 * @returns
	 * @memberof GridBase
	 */
	filterChange(event) {
		const filterText = event.target.value.trim().toLowerCase();
		const startMs = new Date().getMilliseconds();

		if (this.pageControl) this.pageControl.currentPage = 1;

		if (filterText.length === 0) {
			this.filteredData = filterText;
			this.itemCount = this.data.length;
			if (this.pageControl) this.pageControl.itemCount = this.data.length;
			this.previousFilterText = filterText;		// save this as the old filter for the next comparison

			this.requestUpdate();
			return;
		}

		// if there was a previous search string and the new one is the same, but longer.
		// ie: a more precise search where the first chars match
		if (this.previousFilterText && this.previousFilterText.length > 0
			&& this.previousFilterText === filterText.substr(0, this.previousFilterText.length)) {
			// optimized filtering using the current filtered data and filtering on that
			const subFiltered = this.filteredData.filter((row) => {
				return this._rowContainsFilter(filterText, row);
			}, this);
			this.filteredData = subFiltered;
		} else {
			// normal filtering
			this.filteredData = this.data.filter((row) => {
				return this._rowContainsFilter(filterText, row);
			}, this);
		}

		this.previousFilterText = filterText;
		this.itemCount = this.filteredData.length;
		if (this.pageControl) this.pageControl.itemCount = this.itemCount;

		console.debug('Grid: Filter[', filterText, "] ms:", new Date().getMilliseconds() - startMs);
		this.currentPage = 1;			// After filtering, navigate to first page
		this.requestUpdate();
	}

	/**
	 * If this grid contains filtering, render the input control
	 *
	 * @returns
	 * @memberof GridBase
	 */
	filter() {
		if (!this.hasFilter) return;

		return html`<rps-input class="filter" ${ref(this.filterCtrl)} name="filter" label="Filter" @input=${this.filterChange}></rps-input>`;
	}


	/**
	 * Fired when the paging control has changed page
	 * Display the new pages data
	 *
	 * @param {Event} event
	 * @memberof GridBase
	 */
	pageChanged(event) {
		event.preventDefault();
		console.info("page changed", event.detail);
		if (this.currentPage === event.detail.currentPage) return;

		this.currentPage = event.detail.currentPage;			// enough to force a re-render
	}

	/**
	 * If there is more than 1 page of data then render the paging control
	 *
	 * @returns
	 * @memberof GridBase
	 */
	pagination() {
		if (this.pageSize >= this.itemCount) return;

		return html`
			<rps-pagination ${ref(this.pageCtrl)} pagesize=${this.pageSize} itemcount=${this.itemCount}
				@pageHasChanged=${this.pageChanged}
			 />
		`;
	}

	/**
	 * This control uses css Grid.
	 * The css can ONLY be generated at runtime as the column-repeat needs to be set in the css
	 *
	 * @returns
	 * @memberof GridBase
	 */
	customStyling() {
		let padding = '1.2rem';

		// Allow for customization of the cells in a row
		let colLength = this.getColumns().length;
		if (this.template && this.templatesInRow > 0) {
			colLength = this.templatesInRow;
		}

		if (this.rowSize === "small") padding = "0.8rem";
		if (this.rowSize === "large") padding = "1.2rem";

		const columnStyle = this.condenseCells
			? `repeat(${colLength - 1}, auto) 1fr`
			: `repeat(${colLength}, 1fr)`;

		return html`
			<style>
				.grid {
					grid-template-columns: ${columnStyle};
				}

				.grid > span {
					padding: ${padding};
				}

				${this.template}
			</style>
		`;
	}

	/**
	 * Render the footer control if the column definition for the footer has been defined
	 * Also render the data (from functions) in the footer
	 *
	 * @returns
	 * @memberof GridBase
	 */
	footer() {
		if (!this.getColumns().find(e => e.footer !== undefined)) return;		// if no footers dont show

		const columns = this.getColumns().map(col => {
			if (!col.footer) return html`<span class="footer"></span>`;

			let footerValue = (col.data.type === 'number' || col.data.type === 'currency') ? 0 : "";

			// if there is a custom function, then use it
			if (col.footer.render) {
				footerValue = col.footer.render(this.data2Display, col);
			} else if (col.footer.type === 'sum' || col.footer.type === 'average') {
				footerValue = this.data2Display.reduce((a, b) => a + b[col.key], 0);
				if (col.footer.type === 'average') {
					footerValue = footerValue / this.itemCount;
				}
			} else if (col.footer.type === 'min' || col.footer.type === 'max') {
				const vals = this.data2Display.map(e => e[col.key]);
				if (col.footer.type === 'max') {
					footerValue = Math.max(...vals);
				} else {
					footerValue = Math.min(...vals);
				}
			}

			const { data, textStyle } = this._formatData(footerValue, col);

			let handler = (event, detail) => { console.debug('footer click', event, detail) };
			if (col.footer.onClick) {
				handler = col.footer.onClick;
			}

			return html`
				<span class="footer ${textStyle}">
					<strong class="${textStyle}" @click=${(event) => handler(event, { col, type: "row-button-click", source: this.tagName })}>${data}</strong>
				</span>
			`;

		}, this);

		if (this.template) {
			return html`<div class="footer-row">${columns}</div>`;
		} else {
			return columns;
		}

	}

}
