/**
 * Copied directly from the website project.
 */
import type { RefObject } from 'react';
import { useEffect, useState } from 'react';

export const useOnScreen = ({
  ref,
  rootMargin,
  showByDefault,
  onlyOnce,
}: {
  ref: RefObject<HTMLElement>;
  rootMargin: string;
  showByDefault?: boolean;
  onlyOnce?: boolean;
}) => {
  const [show, setShow] = useState(showByDefault || false);

  useEffect(() => {
    if (ref.current) {
      const element = ref.current;

      const observer = new IntersectionObserver(
        ([entry]) => {
          if (onlyOnce && entry.isIntersecting) {
            setShow(true);
            observer.disconnect();
          } else {
            setShow(entry.isIntersecting);
          }
        },
        { rootMargin }
      );

      observer.observe(element);

      /**
       * `disconnect` chosen over `unobserve` because we are creating a new
       * instance of the observer on each iteration of the useEffect.
       */
      return () => observer.disconnect();
    }
  }, [ref, rootMargin, onlyOnce]);

  return show;
};

export const useResized = ({
  ref,
  callback,
  observeFirstChild,
}: {
  ref: RefObject<HTMLElement>;
  callback: (entry: ResizeObserverEntry) => void;
  observeFirstChild?: boolean;
}) => {
  useEffect(() => {
    if (ref && ref.current) {
      const observer = new ResizeObserver(entries => {
        for (const entry of entries) {
          callback(entry);
        }
      });
      const target = observeFirstChild ? ref.current.children[0] : ref.current;
      observer.observe(target);
      return () => observer.disconnect();
    }
  }, [ref, callback, observeFirstChild]);
};
