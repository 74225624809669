/**
 * NOTE: these validators are a bit loose with actual number types,
 * they don't validate any further if they receive a number.
 * But as they're built to be used for validating input,
 * this is relatively safe, as all inputs are going to be strings.
 * The condition put in place for numbers is simply to ensure they don't
 * fail when receiving numbers back from the API (which we assume to be valid already).
 *
 * At present we don't have need for a version that enforces negative values.
 * All inputs supporting negative values currently work best when it's optional.
 * Those that require negative values will automatically format onBlur.
 *
 * TODO: the onChange versions are more for input masking than validation, perhaps split them later.
 */
const integerRegex = new RegExp(/^-?(\d+)$/);
const positiveIntegerRegex = new RegExp(/^(\d+)$/);
const numericRegex = new RegExp(/^-?(\d+\.?\d*|\.\d+)$/);
const positiveNumericRegex = new RegExp(/^(\d+\.?\d*|\.\d+)$/);
const currencyRegex = new RegExp(/^-?(\d+\.?\d?\d?|\.\d\d?)$/);
const positiveCurrencyRegex = new RegExp(/^(\d+\.?\d?\d?|\.\d\d?)$/);

/**
 * NOTE: the distinction of an onChange regex is that it needs to be more flexible,
 * because it's run constantly while they user is still busy typing.
 * If it was as strict as the final check, it would prevent the user from inputting certain characters
 * needed to create a valid input or even clearing the input.
 * For example, the onChange versions will allow the input to be cleared.
 */
const integerOnChangeRegex = new RegExp(/^-?(\d*)$/);
const positiveIntegerOnChangeRegex = new RegExp(/^(\d*)$/);
const numericOnChangeRegex = new RegExp(/^-?(\d*\.?\d*|\.\d+)$/);
const positiveNumericOnChangeRegex = new RegExp(/^(\d*\.?\d*|\.\d+)$/);
const currencyOnChangeRegex = new RegExp(/^-?\d*\.?\d?\d?$/);
const positiveCurrencyOnChangeRegex = new RegExp(/^\d*\.?\d?\d?$/);

export const isInteger = (value: number | string | unknown) =>
  (typeof value === 'number' && Number.isInteger(value)) ||
  (typeof value === 'string' && integerRegex.test(value));

export const isPositiveInteger = (value: number | string | unknown) =>
  (typeof value === 'number' && value >= 0 && Number.isInteger(value)) ||
  (typeof value === 'string' && positiveIntegerRegex.test(value));

export const isNumeric = (value: number | string | unknown) =>
  typeof value === 'number' ||
  (typeof value === 'string' && numericRegex.test(value));

export const isPositiveNumeric = (value: number | string | unknown) =>
  (typeof value === 'number' && value >= 0) ||
  (typeof value === 'string' && positiveNumericRegex.test(value));

export const isCurrency = (value: number | string | unknown) =>
  typeof value === 'number' ||
  (typeof value === 'string' && currencyRegex.test(value));

export const isPositiveCurrency = (value: number | string | unknown) =>
  (typeof value === 'number' && value >= 0) ||
  (typeof value === 'string' && positiveCurrencyRegex.test(value));

export const isIntegerOnChange = (value: number | string | unknown) =>
  (typeof value === 'number' && Number.isInteger(value)) ||
  (typeof value === 'string' && integerOnChangeRegex.test(value));

export const isPositiveIntegerOnChange = (value: number | string | unknown) =>
  (typeof value === 'number' && value >= 0 && Number.isInteger(value)) ||
  (typeof value === 'string' && positiveIntegerOnChangeRegex.test(value));

export const isNumericOnChange = (value: number | string | unknown) =>
  typeof value === 'number' ||
  (typeof value === 'string' && numericOnChangeRegex.test(value));

export const isPositiveNumericOnChange = (value: number | string | unknown) =>
  (typeof value === 'number' && value >= 0) ||
  (typeof value === 'string' && positiveNumericOnChangeRegex.test(value));

export const isCurrencyOnChange = (value: number | string | unknown) =>
  typeof value === 'number' ||
  (typeof value === 'string' && currencyOnChangeRegex.test(value));

export const isPositiveCurrencyOnChange = (value: number | string | unknown) =>
  (typeof value === 'number' && value >= 0) ||
  (typeof value === 'string' && positiveCurrencyOnChangeRegex.test(value));
