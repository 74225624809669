import { useCurrentProduct } from '@odo/contexts/product-editor';
import {
  calcSavingsInPercentage,
  calcSavingsInRands,
} from '@odo/helpers/calculations/general';
import { useMemo } from 'react';

const useSavings = () => {
  const currentProduct = useCurrentProduct();

  const savings = useMemo(() => {
    // TODO: BP-799: MAG prioritizes `minPrice` over `price` for this. find out how to calculate that.
    const price = currentProduct.price?.number;
    const retail = currentProduct.retail?.number;
    if (typeof price !== 'number' || typeof retail !== 'number') {
      return;
    }

    return {
      fixed: calcSavingsInRands(price, retail),
      percentage: calcSavingsInPercentage(price, retail),
    };
  }, [currentProduct.price?.number, currentProduct.retail?.number]);

  return savings;
};

export default useSavings;
