import type { Action } from '@odo/contexts/custom-options-editor/types';
import { ActionTypeEnum } from '@odo/contexts/custom-options-editor/types';
import type { ProductID } from '@odo/contexts/product';
import toast from 'react-hot-toast';
import { notification } from '@odo/utils/toast';

const CACHE_KEY = 'custom-options';
const CACHE_VERSION = 1.1;

interface CustomOptionsCacheProduct {
  actionList: Action[];
  actionOffset: number;
  autoSumEnabled?: boolean;
}

interface CustomOptionsCache {
  v: number;
  products: {
    [key: ProductID]: CustomOptionsCacheProduct;
  };
}

const isValidAction = (action: Action | unknown): action is Action =>
  typeof (action as Action).type === 'string' &&
  Object.values(ActionTypeEnum).includes((action as Action).type);

const isValidCustomOptionsCacheProduct = (
  product: CustomOptionsCacheProduct | unknown
): product is CustomOptionsCacheProduct =>
  typeof (product as CustomOptionsCacheProduct).actionOffset === 'number' &&
  typeof (product as CustomOptionsCacheProduct).actionList !== 'undefined' &&
  Array.isArray((product as CustomOptionsCacheProduct).actionList) &&
  (product as CustomOptionsCacheProduct).actionList.every(isValidAction) &&
  ['boolean', 'undefined'].includes(
    typeof (product as CustomOptionsCacheProduct).autoSumEnabled
  );

const isValidCustomOptionsCache = (
  options: CustomOptionsCache | unknown
): options is CustomOptionsCache =>
  typeof (options as CustomOptionsCache).v === 'number' &&
  typeof (options as CustomOptionsCache).products === 'object' &&
  Object.keys((options as CustomOptionsCache).products).every(
    productId => typeof productId === 'string'
  ) &&
  Object.values((options as CustomOptionsCache).products).every(
    isValidCustomOptionsCacheProduct
  );

const getCache = () => {
  try {
    const cached = window.localStorage.getItem(CACHE_KEY);
    if (cached) {
      const parsed: unknown = JSON.parse(cached);
      // ensure options are valid and from the current version
      // TODO: later we can consider having migrations scripts that we run if the version is outdated
      if (
        isValidCustomOptionsCache(parsed) &&
        Math.floor(parsed.v) === Math.floor(CACHE_VERSION)
      ) {
        return parsed;
      } else {
        toast.error('Cached options are no longer valid');
        window.localStorage.removeItem(CACHE_KEY);
      }
    }
  } catch (e) {
    console.log('Error while loading options from local');
    console.error(e);
  }
};

const setCache = (cache: CustomOptionsCache) => {
  try {
    const stringified = JSON.stringify(cache);
    if (stringified) {
      window.localStorage.setItem(CACHE_KEY, stringified);
    }
  } catch (e) {
    console.error('Failed to stringify cache');
  }
};

export const removeActionList = ({ productId }: { productId: ProductID }) => {
  const cache = getCache();
  if (cache && productId in cache.products) {
    delete cache.products[productId];
    setCache(cache);
  }
};

export const loadActionList = ({ productId }: { productId: ProductID }) => {
  const cache = getCache();
  if (cache && productId in cache.products) {
    const customOptionsCacheProduct = cache.products[productId];
    if (customOptionsCacheProduct.actionList.length > 0) {
      notification('You have unsaved custom option changes');
    }
    return customOptionsCacheProduct;
  }
};

export const persistActionList = ({
  productId,
  actionList,
  actionOffset,
  autoSumEnabled = true,
}: {
  productId: ProductID;
  actionList: Action[];
  actionOffset: number;
  autoSumEnabled?: boolean;
}) => {
  if (productId) {
    try {
      let cache = getCache();
      if (!cache) {
        cache = { v: CACHE_VERSION, products: {} };
      }

      // if there are unsaved actions or the user prefers to disable auto-sum, then let's cache it
      if (actionList.length > 0 || autoSumEnabled === false) {
        cache.products[productId] = {
          actionList,
          actionOffset,
          autoSumEnabled,
        };
      } else if (productId in cache.products) {
        delete cache.products[productId];
      }

      setCache(cache);
    } catch (e) {
      toast.error('Failed to cache custom options');
      console.error(e);
    }
  }
};
