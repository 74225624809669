import { html } from 'lit';
import '../../rps-modal.js';
import { CustomLitElement } from '../baseClasses/CustomLitElement';

export class ReloadPage extends CustomLitElement {

	static get properties() {
		return {
			headerText: { type: String },
			confirmText: { type: String },
		};
	}

	constructor() {
		super();
		this.confirmText = "Retry";
		this.headerText = "Error: Failed to retrieve data";
	}

	/**
	 * Confirm button clicked
	 *
	 * @param {Event} event
	 * @memberof ReloadPage
	 */
	_confirm(event) {
		window.location.reload();
	}


	render() {
		return html`
			<rps-modal opened popup
				id="reload-page-modal" class=${this.className || "error"} confirmText=${this.confirmText}
				headerText=${this.headerText}
				@confirm=${this._confirm}
			>
				<slot>
					Click "Retry" to re-connect.<br/>
					Or contact support
				</slot>
			</rps-modal>
		`;
	}
}

