import { html } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { styles } from './css/progress-bar.css.js';

export class ProgressBar extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			min: { type: Number },
			max: { type: Number },
			value: { type: Number },
			indeterminate: { type: Boolean }
		}
	}

	render() {
		return html`
		${this.addCss()}
			<div class="progress-bar-container ${this.className}">
				${this.indeterminate ? html`
					<progress></progress>
				` : html`
					<progress min=${this.min} max=${this.max} value=${this.value}></progress>
				`}
				<span><slot></slot></span>
			</div>
		`;
	}
}
