import { css } from 'lit';

export const styles = css`
	/* START: Button Specific Controls */
	button {
		position: relative;
		display: inline-flex;
		vertical-align: middle;
		align-self: flex-start;
		justify-content: center;
		flex-flow: row nowrap;
		align-items: center;
		box-sizing: border-box;
		margin: 0;
		outline: 0;
		cursor: pointer;
		border-radius: var(--button-radius);
		transition: background 183ms ease, box-shadow 183ms ease;
		will-change: background, box-shadow;

		/* DEFAULT SETTINGS
			- Primary
			- Medium
			- Solid
		*/
		padding: var(--button-padding-m);
		background: var(--brand-primary);
		box-shadow: var(--button-shadow);
		border: var(--button-border);

		--icon-fill: var(--text-quinary);
		--spinner-color: var(--text-quinary);
		--icon-display: block;
		--spinner-display: block;

		/* DEFAULT SETTINGS */
		-webkit-user-select: none; /* Safari */        
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* IE10+/Edge */
		user-select: none; /* Standard */
	}
	button:hover {
		background: var(--brand-primary-tint);
		box-shadow: var(--button-shadow-hover);
		opacity: 0.8;
	}
	button:focus {
		border-color: rgba(0,0,0,0.3);
	}
	button:active {
		transform: scale(0.98);
	}
	button .slot-default {
		transition: color 183ms ease;
		will-change: color;
		color: var(--text-quinary);
		font:  var(--button-text-m);
		text-transform: var(--button-text-transform);
	}
	button .slot-left,
	button .slot-right {
		display: flex;
		align-items: center;
		height: 2.4rem;
		width: auto;
		pointer-events: none;
	}
	/* SMALL BUTTON OVERRIDE */
	button.small {
		padding: var(--button-padding-s);
		--icon-height: 1.6rem;
		--spinner-height: 1.6rem;
		min-height: var(--line-height);
		line-height: 1.8rem;
	}
	button.small .slot-default {
		font: var(--button-text-s);
	}
	button.small .slot-left {
		--icon-gap-right: var(--button-icon-spacing-s);
		--spinner-gap-right: var(--button-icon-spacing-s);
		height: 2rem;
	}
	button.small .slot-right {
		--icon-gap-left: var(--button-icon-spacing-s);
		--spinner-gap-left: var(--button-icon-spacing-s);
		height: 2rem;
	}
	/* SMALL BUTTON OVERRIDE */

	/* MEDIUM BUTTON OVERRIDE */
	button.medium {
		padding: var(--button-padding-m);
	}
	button.medium .slot-default {
		font: var(--button-text-m);
	}
	button .slot-left,
	button.medium .slot-left {
		--icon-gap-right: var(--button-icon-spacing-m);
		--spinner-gap-right: var(--button-icon-spacing-m);
	}
	button .slot-right,
	button.medium .slot-right {
		--icon-gap-left: var(--button-icon-spacing-m);
		--spinner-gap-left: var(--button-icon-spacing-m);
	}
	/* MEDIUM BUTTON OVERRIDE */

	/* LARGE BUTTON OVERRIDE */
	button.large {
		padding: var(--button-padding-l);
	}
	button.large .slot-default {
		font: var(--button-text-l);
	}
	button.large .slot-left {
		--icon-gap-right: var(--button-icon-spacing-l);
		--spinner-gap-right: var(--button-icon-spacing-l);
	}
	button.large .slot-right {
		--icon-gap-left: var(--button-icon-spacing-l);
		--spinner-gap-left: var(--button-icon-spacing-l);
	}
	/* LARGE BUTTON OVERRIDE */

	/* STRETCH BUTTON OVERRIDE */
	button.stretch {
		width: 100%;
	}
	/* STRETCH BUTTON OVERRIDE */

	/* SOLID BUTTON OVERRIDE */
	button.secondary {
		--icon-fill: var(--text-secondary);
		--spinner-color: var(--text-secondary);
		background: var(--brand-secondary);
	}
	button.secondary .slot-default {
		color: var(--text-secondary);
	}
	button.secondary:hover {
		background: var(--brand-secondary-tint);
	}
	button.tertiary {
		background: var(--brand-primary-tint);
	}
	button.tertiary:hover {
		background: var(--brand-primary);
	}

	button.info {
		background: var(--state-info);
	}
	button.success {
		background: var(--state-success);
	}
	button.warning {
		background: var(--state-warning);
	}
	button.error {
		background: var(--state-error);
	}
	button.help {
		background: var(--state-help);
	}
	button.info:hover,
	button.success:hover,
	button.warning:hover,
	button.error:hover,
	button.help:hover {
		opacity: 0.8;
	}
	button[disabled] {
		cursor: not-allowed;
		pointer-events: none;
		background: var(--state-disabled-light);
		--icon-fill: var(--text-quinary);
		--spinner-color: var(--text-quinary);
	}
	button[disabled] .slot-default {
		color: var(--text-quinary);
	}
	/* SOLID BUTTON OVERRIDE */

	/* OUTLINE BUTTON OVERRIDE */
	button.outline {
		background: transparent;
		border-color: var(--brand-primary);
		--icon-fill: var(--brand-primary);
		--spinner-color: var(--brand-primary);
	}
	button.outline .slot-default {
		color: var(--brand-primary);
	}
	button.outline:hover {
		background: transparent;
		border-color: var(--brand-primary-tint);
		--icon-fill: var(--brand-primary-tint);
		--spinner-color: var(--brand-primary-tint);
	}
	button.outline:hover .slot-default {
		color: var(--brand-primary-tint);
	}
	/* Secondary */
	button.secondary.outline {
		background: transparent;
		border-color: var(--brand-secondary-shade);
		--icon-fill: var(--text-secondary);
		--spinner-color: var(--text-secondary);
	}
	button.secondary.outline .slot-default {
		color: var(--text-secondary);
	}
	button.secondary.outline:hover {
		background: var(--brand-secondary);
	}
	/* Tertiary */
	button.tertiary.outline {
		border-color: var(--brand-primary-tint);
		--icon-fill: var(--brand-primary-tint);
		--spinner-color: var(--brand-primary-tint);
	}
	button.tertiary.outline .slot-default {
		color: var(--brand-primary-tint);
	}
	button.tertiary.outline:hover {
		border-color: var(--brand-primary);
		--icon-fill: var(--brand-primary);
		--spinner-color: var(--brand-primary);
	}
	button.tertiary.outline:hover .slot-default {
		color: var(--brand-primary);
	}
	/* States */
	button.info.outline {
		border-color: var(--state-info);
		--icon-fill: var(--state-info);
		--spinner-color: var(--state-info);
	}
	button.info.outline .slot-default {
		color: var(--state-info);
	}
	button.success.outline {
		border-color: var(--state-success);
		--icon-fill: var(--state-success);
		--spinner-color: var(--state-success);
	}
	button.success.outline .slot-default {
		color: var(--state-success);
	}
	button.warning.outline {
		border-color: var(--state-warning);
		--icon-fill: var(--state-warning);
		--spinner-color: var(--state-warning);
	}
	button.warning.outline .slot-default {
		color: var(--state-warning);
	}
	button.error.outline {
		border-color: var(--state-error);
		--icon-fill: var(--state-error);
		--spinner-color: var(--state-error);
	}
	button.error.outline .slot-default {
		color: var(--state-error);
	}
	button.help.outline {
		border-color: var(--state-help);
		--icon-fill: var(--state-help);
		--spinner-color: var(--state-help);
	}
	button.help.outline .slot-default {
		color: var(--state-help);
	}
	/* Disbaled */
	button[disabled].outline {
		border-color: var(--state-disabled-light);
		--icon-fill: var(--state-disabled-light);
		--spinner-color: var(--state-disabled-light);
		cursor: not-allowed;
	}
	button[disabled].outline .slot-default {
		color: var(--state-disabled-light);
	}
	/* OUTLINE BUTTON OVERRIDE */

	/* STRIP BUTTON OVERRIDE */
	button.strip {
		padding: var(--button-group-padding-i);
		border-radius: var(--button-group-radius-inner);
		--icon-fill: var(--text-secondary);
		--spinner-color: var(--text-secondary);
		border: none;
		background: transparent;
		margin: 0;
		height: 100%;
	}
	button.strip:hover {
		background: var(--brand-secondary);
		color: var(--text-primary);
		opacity: 1;
	}
	button.strip .slot-default {
		color: var(--text-secondary);
	}
	button.strip[selected] {
		background: var(--brand-primary-tint);
		--icon-fill: var(--text-quinary);
		--spinner-color: var(--text-quinary);
	}
	button.primary.strip[selected] { background: var(--brand-primary-tint); }
	button.secondary.strip[selected] { background: var(--brand-secondary-tint); }
	button.tertiary.strip[selected] { background: var(--brand-tertiary-tint); }
	button.info.strip[selected] { background: var(--state-info); }
	button.help.strip[selected] { background: var(--state-help); }
	button.success.strip[selected] { background: var(--state-success); }
	button.warning.strip[selected] { background: var(--state-warning); }
	button.error.strip[selected] { background: var(--state-error); }
	button.strip[selected] .slot-default {
		color: var(--text-quinary);
	}
	button.strip[disabled] {
		background: transparent;
		--icon-fill: var(--state-disabled-light);
		--spinner-color: var(--state-disabled-light);
	}
	button.strip[disabled] .slot-default { color: var(--state-disabled-light); }
	/* STRIP BUTTON OVERRIDE */

	/* WIZARD BUTTON OVERRIDE */
	button.wizard,
	button.wizard:hover {
		padding: 0.4rem;
		border-radius: 999px;
		--icon-display: block;
		--icon-height: 4.0rem;
		--icon-fill: var(--component-primary);
		border-color: var(--component-primary);
	}
	button.wizard.success,
	button.wizard.success:hover {
		--icon-fill: var(--text-secondary);
		border-color: var(--state-success);
	}
	button.wizard.warning,
	button.wizard.warning:hover {
		--icon-fill: var(--state-warning);
		border-color: var(--state-warning);
	}
	button.wizard.error,
	button.wizard.error:hover {
		--icon-fill: var(--state-error);
		border-color: var(--state-error);
	}
	button.wizard.active,
	button.wizard.active:hover {
		--icon-fill: var(--text-quinary);
		background: var(--state-success);
		border-color: var(--state-success);
	}
	/* WIZARD BUTTON OVERRIDE */
`;

