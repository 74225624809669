/**
 * @name rps-switch
 * @type {Switch}
 * 
 * 
 * @attributes + @properties 				| Changes trigger a re-render
 * Casing usages:								| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {Boolean} readonly		| is this control a readonly control?
 * 	@property {Boolean} checked		| is this checkbox checked
 * 	@property {String} name				| form name
 * 	@property {Boolean} caching		| does this control use Caching when reading and writing values
 * 	@property {String} customCss		| Specify a custom .css file to include, use cssPath to override elements,
 * 													DO NOT override the styling definition by re-defining the css styling
 * 													Put this file into the *window.cssPath* folder @see {link README.md}
 * 													eg: /public/css (where the other css files are)
 * 													Suggested use: use sub-folders, dont put it all into the base
 * 												
 * 	@property {String} onColor			| Color to display when the switch is checked
 * 	@property {String} borderColor	| Border color of the switch
 * 	@property {String} offColor		| Color to display when the switch is un-checked
 * 	@property {String} preText			| Text to display before the switch
 * 	@property {String} postText		| Text to display after the switch
 * 
 * 
 * @property (JS Only)
 * 	@property getcache					| Return the cached value or undefined if there is no cached value
 * 	@property gethasCache				| Return whether caching is being used, and whether there is a cached value
 * 	@property value(get and set)		| allow getters and setters for the value of the select.
 * 													eg: oControl.value = "newValue";
 * 													oe: console.log(oControl.value);
 * 	inputControl (get only)				| Return a reference to the input control
 * 
 * 
 * @methods
 * 	clear()									| Clear all the cached value used by this control (or any other control using the same name)
 * 
 * @events
 * 	input										| fires whenever checkbox is clicked
 * 
 * @slots
 * 	None
 * 
*/

import { Switch } from './src/input/Switch.js';
if (!window.customElements.get('rps-switch'))
	window.customElements.define('rps-switch', Switch);

