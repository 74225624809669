import { html } from 'lit';
import { createRef, ref } from 'lit/directives/ref.js';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { styles } from './css/button.css.js';
export class Button extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			disabled: { type: Boolean },
			selected: { type: Boolean },
		};
	}

	constructor() {
		super();
		this.button = createRef();
		this.disabled = false;
		this.selected = false;
	}

	/**
	 * Fired when the button is clicked on
	 *
	 * @param {Event} event
	 * @memberof Button
	 */
	buttonClicked(e) {
		e.stopPropagation();

		const detail = {
			event: 'click',
			source: this.tagName,
			id: this.id,
			classes: this.className,		// list of class names used for this control
		};
		e = new CustomEvent('click', { detail, bubbles: true, composed: true, cancelable: true });
		this.dispatchEvent(e);
		console.debug('Button: Click', e);

		if (this.cbClick) this.cbClick(e);
	}

	/**
	 * Easy way to retrieve the button control externally
	 *
	 * @readonly
	 * @memberof Button
	 */
	get buttonControl() {
		return this.button.value;
	}


	/**
	 * A method to toggle a class for the button control
	 *
	 * @param {String} className - the name of the class to add or remove
	 * @param {boolean} [toAdd=true]
	 * @memberof Button
	 */
	toggleClass(className, toAdd = true) {
		if (className) { // Avoid attempting to toggle empty className, causes exception
			this.classList.toggle(className, toAdd);
			this.buttonControl.classList.toggle(className, toAdd);
			this.requestUpdate();
		}
	}

	render() {
		return html`
			${this.addCss()}
			<button class="${this.className}"
				?disabled=${this.disabled} ?selected=${this.selected}
				${ref(this.button)}
				@click=${this.buttonClicked}
			>
				<div class="slot-left">
					<slot name="slot-left"></slot>
				</div>
				<div class="slot-default">
					<slot></slot>
				</div>
				<div class="slot-right">
					<slot name="slot-right"></slot>
				</div>
			</button>
		`;
	}

}

