import { html } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { styles } from './css/pieChart2d.css.js';

export class PieChart2d extends CustomLitElement {

	/**
	 * @description - Makes extensive use of the var(--someval) here.
	 * The CSS variables that are used are generated in the "style" for each HTML element, and can then be accessed
	 * Per ELEMENT in the styling.
	 *
	 * @readonly
	 * @static
	 * @memberof PieChart2d
	 */
	static styles = styles;

	static get properties() {
		return {
			sizeRem: { type: Number },							// Size of chart in REM
			heading: { type: String },							// Heading for Chart
			data: { type: Array },								// Array of data points. Each point contains an object like this:
			colors: { type: Array },							// Array of colors to use for the pie chart items
		};
	}

	constructor() {
		super();
		this.sizeRem = 30;
		this.heading = "Pie chart";
		this.colors = ["#db0a5b", "#22a7f0", "#2ecc71", "#4d05e8", "blue", "red", "green", "yellow", "purple"];
		this.data = [{ text: "Africa", value: 25 }, { text: "Asia", value: 10 }, { text: "North America", value: 60 }, { text: "South America", value: 5 }];
	}



	/**
	 * @description - The style is declared for each <div> element, and in that style
	 * the css Variables are set that are used in this classes CSS.
	 * eg: var(--value, 45)
	 *
	 * @return {*} 
	 * @memberof PieChart2d
	 */
	renderItems() {
		let running = 0;
		const colorLen = this.colors.length;

		const generated = this.data.map((item, i) => {
			const val = item.value;
			const color = this.colors[i % colorLen];
			const slice = html`<div title="${item.text}" class="pie_segment" style="--offset: ${running}; --value: ${val}; --bg: ${color}; ${val > 50 ? ' --over50: 1;' : ''}"></div>`;
			running += val;
			return slice;
		});

		return generated;
	}

	render() {
		return html`
			${this.addCss()}

			<div class="pie-container">
				<h3>${this.heading}</h3>
				<div class="pie" style="--size: ${this.sizeRem}rem;">
					${this.renderItems().map(e => e)}
				</div>
			</div>
			`;
	}
}

