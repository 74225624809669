import { ODOAdminUrl } from '../../config';

const imgixDomainMap = {
  'bf-cdn.onedayonly.co.za': 'bf-cdni.onedayonly.co.za',
  'cdn.onedayonly.co.za': 'odo-cdn.imgix.net',
};

export const getODOAdminUrl = ({ path }: { path?: string }) =>
  `${ODOAdminUrl}${path || ''}`;

export const getImgixAssetUrl = ({
  url,
  width,
  height,
}: {
  url: string;
  width?: number;
  height?: number;
}) => {
  let finalUrl = url;
  for (const key in imgixDomainMap) {
    finalUrl = finalUrl.replace(key, imgixDomainMap[key]);
  }

  finalUrl += '?auto=compress&fit=clip';
  if (width) {
    finalUrl += `&w=${width}`;
  }
  if (height) {
    finalUrl += `&h=${width}`;
  }

  return finalUrl;
};
