import configJson from './config.json';

interface ConfigType {
  customOptions: {
    automaticallyLinkAllChildOptionValues: boolean;
  };
}

const defaultConfig: ConfigType = {
  customOptions: {
    automaticallyLinkAllChildOptionValues: true,
  },
};

type ConfigJson = ConfigType | unknown;

const isValidConfig = (config: ConfigJson): config is ConfigType =>
  typeof config === 'object' &&
  config !== null &&
  typeof (config as ConfigType).customOptions === 'object' &&
  (config as ConfigType).customOptions !== null &&
  typeof (config as ConfigType).customOptions
    .automaticallyLinkAllChildOptionValues === 'boolean';

const config: ConfigType = isValidConfig(configJson)
  ? configJson
  : defaultConfig;

export default config;
