/**
 * @name rps-key-value-table
 * @type {KeyValueTable}
 *		Renders data as a two-column table with labels on the left and data on the right.
 *
 * 	Where rps-grid is best suited for rendering arrays of data, this table is best used to
 *		render lots of data from a single complex object (i.e.: One data point).
 *		@see {rps-grid} if you need to render sets of data.
 *
 * 
  * @notes on transforms
 * 	Transforms are functions that get applied to a row before rendering.
 * 	A transform function receives three arguments:
 * 		value - the incoming value (Null if key doesn't exist - key doesn't have to exist, i.e.: For computed rows)
 * 		data - the full data set (Use to reference other data and compute a value)
 * 		row - the row definition itself
 * 	The transform must return the value to render.
 *
 * 	A row definition can be assigned a key that doesn't exist on the target data object,
 * 	with the specific intent that it be a newly computed row (See examples)
 * 
 *	@properties (JS Only)
 * 	@property {Array} 		rows			| Row definitions
 * 														i.e.: ```js
 * 														const rows = [
 * 															{
 * 																key: 'price', 								// Key to look up for this row
 * 																label: 'Price Incl. VAT', 				// Label to give to this row
 * 																transform: (value, data, row) => { 	// Data transformer (See below)
 * 																	return value * VAT;
 * 																},
 * 															},
 * 															{  // Simple row
 * 																key: 'cost',
 * 																label: 'Cost'
 * 															},
 * 															{	// Computed row
 * 																key: 'profit',
 * 																label: 'Profit Potential',
 * 																transform: (value, data, row) => {
 * 																	return (data.price * VAT) - data.cost;
 * 																}
 * 															}
 * 														];
 * 														```
 *		@property {Object}		data			| Data to render using the row definitions above.
 * 													  i.e.: ```js
 * 														const data = {
 * 															price: 500,
 * 															cost: 300
 * 														}
 * @methods
 *    none
 *
 * @events
 * 	none
 *
 * @callbacks
 * 	none
 *
 * @slots
 * 	no slots
 *
 * @example @Lit
 * ==================================
 * import '@rps-wc/wc/rps-key-value-table';
 *	const rows = [
 * 	{ 	key: 'price', label: 'Price (Excl VAT)' },
 * 	{ 	key: 'priceVAT', label: 'Price (Incl VAT)'},
 * 	{ 	key: 'cost', label: 'Cost' },
 * 	{ 	key: 'quantity', label: 'Units Available'},
 * 	{ 	key: 'profit_unit',
 * 	  	label: 'Profit Potential Per Unit',
 * 	  	transform: (value, data) => {
 * 			return (data.price * 1.15) - data.cost
 * 		}
 * 	},
 * 	{ 	key: 'profit_total',
 * 		label: 'Total Profit Potential',
 * 		transform: (value, data) => {
 * 			return ((data.price * 1.15) - data.cost) * data.quantity;
 * 		}
 * 	}
 * }
 * ];
 *
 * const data = {
 * 	price: 500,
 * 	cost: 300,
 * 	quantity: 10
 * };
 *
 * return html`
 * 	<rps-key-value-table .rows=${rows} .data=${data}></rps-key-value-table>
 * `;
 *
 * @example @ReactJS
 * Without React Wrapper
 * ===============================
 * import '@rps-wc/wc/rps-key-value-table';
 *	const rows = [
 * 	{ 	key: 'price', label: 'Price (Excl VAT)' },
 * 	{ 	key: 'priceVAT', label: 'Price (Incl VAT)'},
 * 	{ 	key: 'cost', label: 'Cost' },
 * 	{ 	key: 'quantity', label: 'Units Available'},
 * 	{ 	key: 'profit_unit',
 * 	  	label: 'Profit Potential Per Unit',
 * 	  	transform: (value, data) => {
 * 			return (data.price * 1.15) - data.cost
 * 		}
 * 	},
 * 	{ 	key: 'profit_total',
 * 		label: 'Total Profit Potential',
 * 		transform: (value, data) => {
 * 			return ((data.price * 1.15) - data.cost) * data.quantity;
 * 		}
 * 	}
 * }
 * ];
 *
 * const data = {
 * 	price: 500,
 * 	cost: 300,
 * 	quantity: 10
 * };
 *
 * const tableRef = useRef();
 * useEffect(() => {
 * 	tableRef.current.rows = rows;
 * 	tableRef.current.data = data;
 * },[rows, data]);
 *
 * return (
 * 	<rps-key-value-table ref={tableRef}></rps-key-value-table>
 * )
 *
 * @example @ReactJS
 * With React Wrapper
 * ===============================
 * import {RPSKeyValueTable } from '@rps/web-components/react';
 *	const rows = [
 * 	{ 	key: 'price', label: 'Price (Excl VAT)' },
 * 	{ 	key: 'priceVAT', label: 'Price (Incl VAT)'},
 * 	{ 	key: 'cost', label: 'Cost' },
 * 	{ 	key: 'quantity', label: 'Units Available'},
 * 	{ 	key: 'profit_unit',
 * 	  	label: 'Profit Potential Per Unit',
 * 	  	transform: (value, data) => {
 * 			return (data.price * 1.15) - data.cost
 * 		}
 * 	},
 * 	{ 	key: 'profit_total',
 * 		label: 'Total Profit Potential',
 * 		transform: (value, data) => {
 * 			return ((data.price * 1.15) - data.cost) * data.quantity;
 * 		}
 * 	}
 * }
 * ];
 *
 * const data = {
 * 	price: 500,
 * 	cost: 300,
 * 	quantity: 10
 * };
 *
 * return (
 * 	<RPSKeyValueTable rows={rows} data={data}></RPSKeyValueTable>
 * )
 */

import { KeyValueTable } from './src/grid/KeyValueTable';
if (!window.customElements.get('rps-key-value-table'))
	window.customElements.define('rps-key-value-table', KeyValueTable);
