import { checkFonts } from "./textEditorCheckFonts";
import { iconNames } from "../../svg-icons";

export const textEditorCommands = (cbCommand, context, tags, selection) => {
	return [
		{
			icon: iconNames.format.clear,
			command: 'removeFormat'
		},
		{
			icon: iconNames.format.bold,
			command: 'bold',
			active: tags.includes('b')
		},
		{
			icon: iconNames.format.italic,
			command: 'italic',
			active: tags.includes('i')
		},
		{
			icon: iconNames.format.underline,
			command: 'underline',
			active: tags.includes('u')
		},
		{
			icon: iconNames.format.alignLeft,
			command: 'justifyLeft'
		},
		{
			icon: iconNames.format.alignCenter,
			command: 'justifycenter'
		},
		{
			icon: iconNames.format.alignRight,
			command: 'justifyright'
		},
		{
			icon: iconNames.format.orderedList,
			command: 'insertorderedlist',
			active: tags.includes('ol')
		},
		{
			icon: iconNames.format.unorderedList,
			command: 'insertunorderedlist',
			active: tags.includes('ul')
		},
		{
			icon: iconNames.format.quote,
			command: 'formatblock',
			command_value: 'blockquote'
		},
		{
			icon: iconNames.format.outdent,
			command: 'outdent'
		},
		{
			icon: iconNames.format.indent,
			command: 'indent'
		},
		{
			icon: iconNames.format.link,
			command: () => {
				const savedRange = selection ? {
					collapsed: selection.collapsed,
					commonAncestorContainer: selection.commonAncestorContainer,
					endContainer: selection.endContainer,
					endOffset: selection.endOffset,
					startContainer: selection.startContainer,
					startOffset: selection.startOffset
				} : null;

				context.showLinkModal((link) => {
					if (link && link !== '') {
						if (savedRange) {
							const range = new Range();
							range.setEnd(savedRange.endContainer, savedRange.endOffset);
							range.setStart(savedRange.startContainer, savedRange.startOffset);

							window.getSelection().removeAllRanges();
							window.getSelection().addRange(range);
							cbCommand('createLink', link, range);
						}
					}
				});
			}
		},
		{
			icon: iconNames.format.unlink,
			command: 'unlink'
		},
		{
			icon: iconNames.format.textColor,
			command: () => {
				const selectColor = (e) => {
					const val = e.target.value;
					cbCommand('forecolor', val);
					context.fgPicker.value.removeEventListener('input', selectColor);
				}
				context.fgPicker.value.addEventListener('input', selectColor);
				context.fgPicker.value.click();
			},
			type: 'color'
		},
		{
			icon: iconNames.format.border,
			command: () => {
				const selectColor = (e) => {
					const val = e.target.value;
					cbCommand('backcolor', val);
					context.bgPicker.value.removeEventListener('input', selectColor);
				}
				context.bgPicker.value.addEventListener('input', selectColor);
				context.bgPicker.value.click();
			},
			type: 'color'
		},
		{
			command: 'formatblock',
			name: "Formatting",
			values: [
				{ name: 'Normal Text', value: '--' },
				{ name: 'Heading 1', value: 'h1' },
				{ name: 'Heading 2', value: 'h2' },
				{ name: 'Heading 3', value: 'h3' },
				{ name: 'Heading 4', value: 'h4' },
				{ name: 'Heading 5', value: 'h5' },
				{ name: 'Heading 6', value: 'h6' },
				{ name: 'Paragraph', value: 'p' },
				{ name: 'Pre-Formatted', value: 'pre' }
			],
		},
		{
			command: 'fontname',
			name: "Font Family",
			values: [
				{ name: 'Font Name', value: '--' },
				...[...checkFonts()].map(f => ({ name: f, value: f, font: true }))
			]
		},
		{
			command: 'fontsize',
			name: "Font Size",
			values: [
				{ name: 'Font Size', value: '--' },
				{ name: 'Very Small', value: 1 },
				{ name: 'Small', value: 2 },
				{ name: 'Normal', value: 3 },
				{ name: 'Medium Large', value: 4 },
				{ name: 'Large', value: 5 },
				{ name: 'Very Large', value: 6 },
				{ name: 'Extra Large', value: 7 },
			]
		},
		{
			icon: iconNames.format.undo,
			command: 'undo'
		},
		{
			icon: iconNames.format.redo,
			command: 'redo',
		},
		{
			icon: iconNames.format.cut,
			command: 'cut',
		},
		{
			icon: iconNames.format.copy,
			command: 'copy'
		},
		{
			icon: iconNames.format.paste,
			command: 'paste'
		}
	];
};
