export function debounce(fn, timeout = 200) {
  let timer = undefined;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, timeout);
  };
}
