import { Grid } from '@odo/components/elements/layout/grid';
import { Checkbox, Fieldset } from '@odo/components/elements/form-fields';
import type { CheckboxProps } from './checkbox';

const CheckboxGroup = ({
  options,
  label,
  color,
}: {
  options: (CheckboxProps & { id: string | number })[];
  label: string;
  color?: string; // can be overridden per checkbox or set once here for all of them
}) => (
  <Fieldset>
    <legend>{label}</legend>
    <Grid gap={[2, 3]}>
      {options.map(option => (
        <Checkbox key={option.id} color={color} {...option} />
      ))}
    </Grid>
  </Fieldset>
);

export default CheckboxGroup;
