import { useCurrentProduct } from '@odo/contexts/product-editor';
import { updateDraft } from '@odo/data/product/draft/cache';
import { useDealId } from '@odo/screens/deal/editor/hooks';
import { dateObjectToIso } from '@odo/utils/date';
import { debounce } from '@odo/utils/debounce';
import { dismiss, notification } from '@odo/utils/toast';
import { produce } from 'immer';
import { useEffect } from 'react';

const PERSIST_DRAFT_ERROR_TOAST_ID = 'persist-draft-error';

const PersistDraftChanges = () => {
  const { id, isDraft } = useDealId();
  const currentProduct = useCurrentProduct();

  useEffect(() => {
    if (!id || !isDraft) return;

    const syncDraft = async () => {
      try {
        // quickly set the updatedAt field in the draft metadata before persisting
        const syncProduct = produce(currentProduct, draft => {
          if (draft.draft) {
            draft.draft.updatedAt = dateObjectToIso(new Date(), true);
          }
        });
        await updateDraft(id, syncProduct);
        dismiss(PERSIST_DRAFT_ERROR_TOAST_ID);
      } catch (e) {
        console.error(e);
        notification('Failed to cache draft changes.', {
          id: PERSIST_DRAFT_ERROR_TOAST_ID,
        });
      }
    };

    const controller = new AbortController();
    debounce(syncDraft, 500, controller.signal)();

    return () => controller.abort();
  }, [id, isDraft, currentProduct]);

  return null;
};

export default PersistDraftChanges;
