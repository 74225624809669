import type { BoxProps } from '@odo/components/elements/layout/box';
import { Box } from '@odo/components/elements/layout/box';
import type { FlexBoxProps } from '@odo/components/elements/layout/flex';
import { Flex } from '@odo/components/elements/layout/flex';
import styled from '@odo/lib/styled';
import { cssColor } from '@odo/utils/css-color';
import type { HTMLAttributes } from 'react';
import { forwardRef, type ReactNode } from 'react';

type BaseCardProps = FlexBoxProps;

export const BaseCard = styled(Flex)<BaseCardProps>`
  box-shadow: 1px 2px 4px -2px hsl(240deg 33.33% 80% / 50%),
    1px 2px 8px -2px hsl(240deg 33.33% 80% / 25%);
`;

BaseCard.defaultProps = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: cssColor('border'),
  borderRadius: '0.75rem',
  flexDirection: 'column',
  backgroundColor: cssColor('foreground'),
  height: 'min-content',
};

type CardHeaderProps = Omit<FlexBoxProps, 'color'>;

const CardHeader = styled(Flex)<CardHeaderProps>``;

CardHeader.defaultProps = {
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: cssColor('border'),
  px: [2, 3],
  py: [1, 2],
};

type CardFooterProps = Omit<FlexBoxProps, 'color'>;

const CardFooter = styled(Flex)<CardFooterProps>`
  border-top: 1px solid ${cssColor('border')};
`;

CardFooter.defaultProps = {
  px: [2, 3],
  py: [1, 2],
};

type CardMainProps = Omit<BoxProps, 'color'>;

const CardMain = styled(Box)<CardMainProps>``;

CardMain.defaultProps = {
  px: [2, 3],
  py: [2, 3],
};

type CardProps = {
  children: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  headerProps?: CardHeaderProps;
  footerProps?: CardFooterProps;
  mainProps?: CardMainProps;
  isSelected?: boolean;
} & HTMLAttributes<HTMLDivElement> &
  BaseCardProps;

const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      children,
      header,
      footer,
      headerProps,
      footerProps,
      mainProps,
      isSelected,
      ...rest
    },
    ref
  ) => (
    <BaseCard
      ref={ref}
      borderColor={isSelected ? cssColor('palette-blue') : undefined}
      {...rest}
    >
      {header && <CardHeader {...headerProps}>{header}</CardHeader>}

      <CardMain {...mainProps}>{children}</CardMain>

      {footer && <CardFooter {...footerProps}>{footer}</CardFooter>}
    </BaseCard>
  )
);

export default Card;
