import { gql } from '@apollo/client';
import { ApolloClients } from './../services/apolloClients';

const attributeQuery = gql`
  query GetAttributes {
    getAttributes {
      name
      data {
        key
        value
      }
    }
  }
`;

// TODO: find all usages of this and see if we can optimize by re-using or caching
export const getAttributeValues = async field => {
  const client = new ApolloClients().odo;

  const { data } = await client.query({
    query: attributeQuery,
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  });

  if (data?.getAttributes?.length > 0) {
    const leadTimeEnums = data.getAttributes.find(x => x.name === field);
    if (leadTimeEnums) {
      return leadTimeEnums.data;
    } else {
      return [];
    }
  } else {
    return [];
  }
};
