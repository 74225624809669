import { html } from 'lit';
import { createRef, ref } from 'lit/directives/ref.js';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import '../../rps-input';
import '../../rps-button-icon';
import '../../rps-chip';
import { styles } from './css/search.css.js';
import { iconNames } from '../../svg-icons';

export class Search extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			layout: { type: String },							// "row" or "column"		// display the lookup results as a column or a row
			maxItems: { type: Number },						// max amount of items in the selection
			placeholder: { type: String },
			label: { type: String },
			removeTitle: { type: String },
			items: { type: Array },								// An object in the array must contain a property "id" along with other desired properties (displayable or otherwise)
			displayFunction: { type: Object },				// A function callback that renders the items in the [to select]. NB: return {id, text}
			selectedDisplayFunction: { type: Object },	// A function callback that renders the items in the [selected]. NB: return {id, text}
			displayItems: { type: Array },					// Pass an object that will receive the array item, and output the text to display
			selectedItems: { type: Array },					// All the items that have been selected
			required: { type: Boolean },						// is input required
			readonly: { type: Boolean },						// is this control a readonly control?
			disabled: { type: Boolean },
			hideLabel: { type: Boolean },						// Must the input-label be hidden?
			name: { type: String },								// form name
			cbAddItem: { attribute: false },
			cbRemoveItem: { attribute: false },
			cbReset: { attribute: false },
			singleSelection: { type: Boolean },				// Can the user only make a single selection.
			sortSelection: { type: Boolean },				// sort selected items automatically
		};
	}

	constructor() {
		super();
		this.layout = "row";  							// "column";
		this.placeholder = "Filter string";
		this.label = 'Search';
		this.name = 'search';
		this.removeTitle = "Remove all";
		this.items = [
			{
				"id": 1,
				"firstName": "John",
				"lastName": "Smith",
				"email": "john.smith@redpandasoftware.co.za",
				"class": ""
			},
			{
				"id": 2,
				"firstName": "Mackie",
				"lastName": "Smith",
				"email": "mackie.smith@redpandasoftware.co.za",
				"class": ""
			},
			{
				"id": 3,
				"firstName": "Billy",
				"lastName": "Bob",
				"email": "billy.bob@redpandasoftware.co.za",
				"class": ""
			},
		];
		this.displayItems = Object.keys(this.items[0]).filter(e => e != "id" && e != "class");		// Display all properties other than the id and class
		this.displayArray = undefined;				// the array that will contain the list of items to display
		this.maxItems = 10;
		this.filteredItems = this.displayArray;
		this.inputRef = createRef();
		this.chipsRef = createRef();
		this.selectedItems = [];
		this.required = false;
		this.disabled = false;
		this.hideLabel = false;
		this.readonly = false;
		this.singleSelection = false;
		this.sortSelection = false;
	}


	/**
	 * When this object if first connected to the DOM, generate the display array
	 *
	 * @memberof Search
	 */
	connectedCallback() {
		super.connectedCallback();
		this._createDisplayArray();
	}

	/**
	 * Return a reference to the input control
	 *
	 * @readonly
	 * @memberof Search
	 */
	get inputControl() {
		return this.inputRef.value;
	}

	/**
	 * A reference to the Chips container
	 *
	 * @readonly
	 * @memberof Search
	 */
	get chipsContainer() {
		return this.chipsRef.value;
	}

	/**
	 * Fires when the keyup event occurs
	 *
	 * @param {event} event
	 * @memberof Search
	 */
	onInput(event) {
		const filter = event.target.value.toLowerCase();
		this.filteredItems = this.displayArray.filter(e => e.text.toLowerCase().indexOf(filter) >= 0);
		this.requestUpdate();
	}

	/**
	 * Create the display string necessary for an item in the "dropdown"
	 *
	 * @param {Object} fromObj Object to read properties from
	 * @return {String}
	 * @memberof Search
	 */
	_createDisplay(fromObj) {
		let display = '';
		this.displayItems.forEach(key => {
			display += `${fromObj[key]} `;
		});
		return display;
	}

	/**
	 * Set the array to display using the function, or list of keys to display
	 * Generate the display array based on a custom function of the properties that determine the display
	 * @memberof Search
	 */
	_createDisplayArray() {
		this.displayArray = this.items.map(e => {
			return { id: e.id, text: this._createDisplay(e) };
		}, this);

		this.filteredItems = this.displayArray;
		this.displayArray.sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase());
	}

	/**
	 * Generate an object in the format needed to display it on the screen
	 *
	 * @param {Object} e
	 * @return {Object} {id,text}
	 * @memberof Search
	 */
	filteredItemText(e) {
		if (this.displayFunction) {
			// NB: lookup original in array and pass that to the custom render function
			// ignore int to string comparison problems by using "=="
			const original = this.items.find(orig => orig.id == e.id);
			return this.displayFunction(original);
		}

		return e.text;
	}

	/**
	 * Generate an object in the format needed to display it on the screen
	 *
	 * @param {Object} e
	 * @return {Object} {id,text}
	 * @memberof Search
	 */
	selectedItemText(e) {
		if (this.selectedDisplayFunction) {
			return this.selectedDisplayFunction(e);
		} else if (this.displayFunction) {
			return this.displayFunction(e);
		}

		return this._createDisplay(e);
	}

	/**
	 * Pre-select the following items
	 *
	 * @param {Array} ids An array if [ID's] of the selected items
	 * @memberof Search
	 */
	preSelect(ids) {
		ids.forEach(e => this.moveItem(e, this.displayArray, this.selectedItems, true));
		this.requestUpdate();
	}

	/**
	 * add an item into the selected list
	 *
	 * @param {Event} event
	 * @memberof Search
	 */
	addItem(event) {
		this.moveItem(event.target.id, this.displayArray, this.selectedItems, true);
		this.requestUpdate();

		event.preventDefault();
		event.stopPropagation();

		const detail = {
			id: event.target.id,
			source: this.tagName,
		};
		event = new CustomEvent('addItem', { bubbles: true, cancelable: true, composed: true, detail });
		this.dispatchEvent(event);
		if (this.cbAddItem) this.cbAddItem(event);
	}

	/**
	 * Remove a single item from the selected list
	 *
	 * @param {Event} event
	 * @memberof Search
	 */
	removeItem(event) {
		console.debug('Search:removeItem', event);
		this.moveItem(event.target.id, this.selectedItems, this.displayArray, false);
		this.displayArray.sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase());
		this.requestUpdate();

		event.preventDefault();
		event.stopPropagation();

		const detail = {
			id: event.target.id,
			source: this.tagName,
		};
		event = new CustomEvent('removeItem', { bubbles: true, cancelable: true, composed: true, detail });
		this.dispatchEvent(event);
		if (this.cbRemoveItem) this.cbRemoveItem(event);
	}


	/**
	 * Move an item from [available to selected] or [selected to available]
	 *
	 * @param {Id} id The items Id
	 * @param {Array} from The list to remove the item from
	 * @param {Array} to The list to add the item to
	 * @return {*}
	 * @memberof Search
	 */
	moveItem(id, from, to, addToSelected) {
		const index = from.findIndex(e => e.id == id);						// == to ignore string and number differences
		if (index < 0) {
			console.error('Search:moveItem: Failed to find item with id:', id);
			return;
		}

		const selected = from.splice(index, 1);								// remove it from the selected list
		let toAdd = selected[0];

		if (addToSelected) {
			toAdd = this.items.find(e => e.id == toAdd.id);					// remove potential string to int matching problems
		} else {
			toAdd = { id: id, text: this._createDisplay(toAdd) };
		}
		to.push(toAdd);																// we dont want an entire array, only the item that was unselected
	}


	/**
	 * Remove all the "selected" items and add them back into the "list for selection"
	 *
	 * @memberof Search
	 */
	_reset(event) {
		console.debug('Search:_reset', event);
		this.inputControl.inputControl.value = '';
		this.inputControl.focus();
		this._createDisplayArray();
		this.selectedItems = [];

		const detail = {
			source: this.tagName
		};

		const ev = new CustomEvent('reset', { bubbles: true, cancelable: true, composed: true, detail });
		this.dispatchEvent(ev);

		if (this.cbReset) {
			this.cbReset(detail);
		}
	}

	/**
	 * Set the list of items to a totally new set if items, and re-render the control
	 * Set the Items after the control has been rendered
	 * @example
	 * setItems([
	 * { "id": 1, "firstName": "John", "lastName": "Smith", "email": "john.smith@redpandasoftware.co.za", "class": "" },
	 * { "id": 2, "firstName": "Mackie", "lastName": "Smith", "email": "mackie.smith@redpandasoftware.co.za", "class": "" },
	 *	])
	 * @param {Array} items New items for the control
	 * @param {Array} newDisplayItems Optionally a new array of displayItems, if not passed then they are re-generated using the "items" 
	 * @memberof Search
	 */
	setItems(items, newDisplayItems) {
		this.items = items;

		if (!newDisplayItems) {
			// compatibility for ReactWrapper
			if (Array.isArray(items) && items.length > 0) {
				this.displayItems = Object.keys(this.items[0]).filter(e => e != "id" && e != "class");		// Display all properties other than the id and class
			}
		} else {
			this.displayItems = newDisplayItems;
		}

		this.displayArray = undefined;
		this.filteredItems = this.displayArray;
		this._createDisplayArray();
	}

	/**
	 * Change the displayItems, and re-render the control
	 *
	 * @param {Array} newDisplayItems
	 * @memberof Search
	 */
	setDisplayItems(newDisplayItems) {
		this.displayItems = newDisplayItems;

		this.displayArray = undefined;
		this.filteredItems = this.displayArray;
		this._createDisplayArray();
	}

	/**
	 * Create a list of filtered items based on the input of the user
	 *
	 * @return {Event}
	 * @memberof Search
	 */
	renderDropdownItems() {
		if (this.singleSelection === true && this.selectedItems.length > 0) return;

		let items = this.filteredItems.slice(0, this.maxItems)
			.map(e => html`
				<li id=${e.id} @click=${this.addItem} class="search-filtered-item ${e.class ? e.class : ""} list-group-item">
					${this.filteredItemText(e)}
				</li>`
			);

		return html`<ul class="search-filtered ${this.layout}">${items}</ul>`;
	}


	/**
	 * Display the selected items in the <rps-input> slot
	 *
	 * @return {html}
	 * @memberof Search
	 */
	renderSelectedItems() {
		if (this.sortSelection) {
			this.selectedItems.sort((a, b) => {
				const display1 = this.selectedItemText(a);
				const display2 = this.selectedItemText(b);
				if (typeof display1 === 'object') {
					return true;
				} else {
					return display1.toLowerCase() > display2.toLowerCase();
				}
			});
		}

		return this.selectedItems.map(e => {
			const display = this.selectedItemText(e);

			// Custom renderer is used
			if (typeof display === 'object') {
				return html`
					<div class="selected" id="${e.id}" title="remove" @click=${this.removeItem}>
						${display}
					</div>
				`;
			}
			return html`
				<div class="selected">
					<rps-chip id="${e.id}" text="${display}" deleteMode="manual" @delete=${this.removeItem}></rps-chip>
				</div>
			`;
		});

	}

	render() {
		return html`
			${this.addCss()}
			<div class="search-container">
				<div class="search-input-container">
					<rps-input
						id="${this.name + 'Search'}" name="${this.name}"
						label="${this.label}"
						class="${this.className}" css="${this.css}"
						?required=${this.required} ?disabled=${this.disabled} ?readonly=${this.readonly} hide=${this.hideLabel}
						${ref(this.inputRef)}
						@input=${this.onInput}
						type="search"
						placeholder="${this.placeholder}"
						caching
					>
						<div slot="chips" ${ref(this.chipsRef)}>
							${this.renderSelectedItems()}
						</div>
					</rps-input>
					<rps-button-icon title="${this.removeTitle}" class="secondary"
						css="${this.css}" svg="${iconNames.del}" @click=${this._reset}>
					</rps-button-icon>
				</div>
				${this.renderDropdownItems()}
			</div>
		`;
	}
}

