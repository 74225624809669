/**
 * @name rps-tree-relationship
 * @type {TreeRelationShip}
 * 	A generic tree-view control
 * 
 * @description
 *  Everything that can be used in the <rps-tree> can be used here as well as this is a wrapper component.
 *  @see {link <rps-tree> for more details}
 *
 * @notes
 * 	A composite component that wraps a <rps-tree> and renders a dropdown to the right of the tree item.
 * 	Additionally an array of items for the dropdown is passed that can be used to associate the dropdown items to elements in the <rps-tree>
 * 	There are 2 methods [resultsFlat, resultsGrouped] that give the results of the mapping
 * 
 * @example
 * 	An example usage would be for mapping user-groups to menu items for Authorization
 *
 *
 * @attributes + @properties 				| Changes trigger a re-render
 * Casing usages:								| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String} customCss 		| Specify a custom .css file to include, use cssPath to override elements,
 * 													DO NOT override the styling definition by re-defining the css styling
 * 													Put this file into the *window.cssPath* folder @see {link README.md}
 * 													eg: /public/css (where the other css files are)
 * 													Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}css				| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 	@property {Array} treeViewData 	| @see {link <rps-tree> and demo link below}
 * 	@property {Array} dropDownData 	| @see {link <rps-dropdown>}
 * 													Example array [{id,text}]
 * 	@property {Object} customRenderer | The custom renderer that renders the <rps-dropdown> or any code you wish to the right of the tree item.
 * 													default= this.customRenderer = (item) => html`
 * 														<div style="display: flex; gap: 1.5rem; margin-left: 0.5rem; align-items: center;">
 * 															${item.text}
 * 															<rps-dropdown class="small" .items=${this.dropDownData} 
 * 																defaultValue=${item.dropDownDataId || ''}
 * 																@click=${this.dropdownClick} @input=${this.dropdownInput}>
 * 															</rps-dropdown>
 * 														</div>`
 * 
 * 													@see {link /demo/easy/treeview/tree-relationship.html}
 * 														for examples
 * 													Override this for customizing this control.
 *
 * 
 * @Properties (JS only)
 * 	get treeViewControl					| Return a reference to the tree-view control
 *
 * @methods
 * 	resultsFlat()							| Return all the treeView items as a flat array with the dropDownDataId in the object property
 * 	resultsGrouped()						| Return all the treeView items as a Map() object that contains the [dropDownDataId], then an array of [id] that belong to the [dropDownDataId]
 * 	resultsTree()							| Return the tree-view data containing the complete tree along with the associated dropdownId's
 *
 * @events
 * 	None
 *
 * @callbacks
 * 	None
 *
 * @slots
 * 	@none
 *
 * @examples + @Lit
 * @see {link /demo/easy/treeview/tree-relationship.html}
 *
*/
import { TreeRelationship } from './src/treeView/TreeRelationship';
if (!window.customElements.get('rps-tree-relationship'))
	window.customElements.define('rps-tree-relationship', TreeRelationship);

