import Button from '@odo/components/elements/button';
import { FaPhotoVideo as IconImage } from 'react-icons/fa';
import { IoIosLink as IconLink } from 'react-icons/io';
import type { BaseEditorImage } from '@odo/types/portal';
import {
  getImageSrc,
  isAnchorElement,
  downloadImage,
} from '@odo/screens/deal/editor/images-and-videos/helpers';
import Tooltip from '@odo/components/widgets/tooltip';

export const ImageActionDownload = ({ image }: { image: BaseEditorImage }) => (
  <Tooltip showDelay={1250} content={() => 'Download Image'}>
    <Button
      hue="grey"
      variant="flat"
      px={0}
      py={0}
      onClick={() => downloadImage(image)}
    >
      <IconImage size={16} />
    </Button>
  </Tooltip>
);

export const ImageActionOpenInNewTab = ({
  image,
}: {
  image: BaseEditorImage;
}) => (
  <Tooltip showDelay={1250} content={() => 'Open Image in a New Tab'}>
    <Button hue="grey" variant="flat" px={0} py={0}>
      <a
        href={getImageSrc({ url: image.url, file: image.file })}
        onLoad={e =>
          e.target instanceof Element &&
          isAnchorElement(e.target) &&
          // image urls generated with createObjectURL need to be revoked and will have the `blob:` protocol
          e.target.href.startsWith('blob:') &&
          URL.revokeObjectURL(e.target.href)
        }
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: 'inherit', display: 'inline-flex' }}
      >
        <IconLink size={16} />
      </a>
    </Button>
  </Tooltip>
);
