import { html, css } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import '../../rps-modal';
import '../../rps-checkbox';
import '../../rps-form';

/**
 * Change the general settings for this user
 * Log levels are "changed" for 30 mins
 *
 * @export
 * @class GeneralSettings
 * @extends {CustomLitElement}
 */
export class GeneralSettings extends CustomLitElement {
	static styles = css`
			:host {
			}
    `;

	static get properties() {
		return {
			cbConfirm: { attribute: false },
			cbCancel: { attribute: false },
		};
	}

	constructor() {
		super();
		// Used for testing in this project, just uncomment (logging.js is not part of this project)....
		// console.methods = {																	// create an object containing the logging methods
		// 	debug: { method: console.debug, use: false },
		// 	info: { method: console.info, use: false },								// and whether they must be used or not
		// 	log: { method: console.log, use: false },									// and whether they must be used or not
		// 	warn: { method: console.warn, use: true },								// console.methods.debug.use = false;
		// 	error: { method: console.error, use: true },								// disable one like this:
		// };

		this.loggingLevels = this._getLogLevels();
		console.debug('GeneralSettings', 'constructor', this.loggingLevels);
	}

	/**
	 * Fired when the user changes/saves the general settings
	 *
	 * @param {Event} event
	 * @return {*} 
	 * @memberof GeneralSettings
	 */
	okHandler(event) {
		event.stopPropagation();

		console.debug('GeneralSettings', 'ok clicked');

		if (!this._setLogLevels()) return;

		const detail = {
			logLevels: this._getLogLevels(),
			source: this.tagName
		};
		event = new CustomEvent('confirm', { detail, bubbles: true, cancelable: true, composed: false });
		this.dispatchEvent(event);

		if (this.cbConfirm) this.cbConfirm(event);

		const mins = 1000 * 60;
		if (this.loggingLevels) {
			setTimeout(() => {
				this.loggingLevels.forEach(l => {
					console.methods[l.id].use = l.checked;		// set back to defaults
				});

				console.debug('GeneralSettings', 'RESET values', console.methods);

			}, 30 * mins)
		}

	}

	/**
	 * Used to retrieve the console methods
	 * @see {link js-utils project}
	 *
	 * @return {*} 
	 * @memberof GeneralSettings
	 */
	_getLogLevels() {
		if (!console.methods) {
			console.error('GeneralSettings: "logging" NOT used in project, import logger library first');
			return;
		}

		return Object.keys(console.methods).map(k => {
			return { id: k, checked: console.methods[k].use };
		});

	}

	/**
	 * Change the log levels temporarily
	 *
	 * @return {Boolean} 
	 * @memberof GeneralSettings
	 */
	_setLogLevels() {
		if (!console.methods) {
			console.error('GeneralSettings: "logging" NOT used in project, import first');
			return false;
		}

		const inputs = this.shadowRoot.querySelectorAll('input[type="checkbox"]:checked');							// select all inputs that are checked
		const names = Array.from(inputs).map(e => {																				// create array of checked items
			return { id: e.id, checked: true };
		});
		const toDo = { ...this.loggingLevels, ...names };																		// merge arrays
		const toDoAsArray = Object.keys(toDo).map(e => toDo[e]);																// turn object into array

		toDoAsArray.forEach(p => console.methods[p.id].use = p.checked);													// update console logging

		console.debug('_setLogLevels', console.methods);
		return true;
	}

	/**
	 * User cancelled the modification of the general-settings
	 *
	 * @param {Event} event
	 * @memberof GeneralSettings
	 */
	cancelHandler(event) {
		event.stopPropagation();
		console.debug('GeneralSettings', 'cancel clicked');

		const detail = {
			logLevels: this._getLogLevels(),
			source: this.tagName
		};
		event = new CustomEvent('cancel', { detail, bubbles: true, cancelable: true, composed: false });
		this.dispatchEvent(event);

		if (this.cbCancel) this.cbCancel(event);

	}

	render() {
		// crashes as soon as I put this into the html...
		// will find a way later to set the checked value
		// const debug = this.loggingLevels.find(e => e.id === "debug").checked ? 'checked' : '';
		// const info = this.loggingLevels.find(e => e.id === "info").checked ? 'checked' : '';
		// const log = this.loggingLevels.find(e => e.id === "log").checked ? 'checked' : '';

		return html`
		<rps-form @submit=${this.okHandler} @cancel="${this.cancelHandler}">
			<h5>Logging level</h5>
			<rps-checkbox name="debug" label="Enable [debug] logging"></rps-checkbox>
			<rps-checkbox name="info" label="Enable [info] logging"></rps-checkbox>
			<rps-checkbox name="log" label="Enable [log] logging"></rps-checkbox>
		</rps-modal>
	`;
	}
}
