import React from 'react';
import PropTypes from 'prop-types';
import styles from './Popup.module.css';

export const BusyPopup = ({ show, message = 'Busy...' }) => {
  return (
    <>
      {show && (
        <div className={styles.busySpinnerContainer}>
          <rps-card>
            <div className={styles.busySpinner}>
              <rps-spinner-pause />
              {message}
            </div>
          </rps-card>
        </div>
      )}
    </>
  );
};

BusyPopup.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.string,
};
