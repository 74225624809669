import React from "react";
import { createReactWrapper } from "../util/createReactWrapper.jsx";
import PropTypes from "prop-types";

/**
 * RPS Input Component
 * @component
 * @type {Function}
 */
const InternalRPSInput = createReactWrapper("rps-input");

export const RPSInput = ({ value, children, ...rest }) => {
	let safeValue = value || "";
	if (value === 0 || value === "0") {
		safeValue = "0";
	}
	return (
		<InternalRPSInput value={safeValue} {...rest}>
			{children}
		</InternalRPSInput>
	);
};

RPSInput.propTypes = {
	value: PropTypes.any,
	children: PropTypes.any,
};
