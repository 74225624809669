/**
 * @name rps-general-settings
 * @type {GeneralSettings}
 * A control for Changing the web-sites settings for this user.
 * 
 * @attributes + @properties 				| Changes trigger a re-render
 * Casing usages:								| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String} customCss		| Specify a custom .css file to include, use cssPath to override elements,
 * 													DO NOT override the styling definition by re-defining the css styling
 * 													Put this file into the *window.cssPath* folder @see {link README.md}
 * 													eg: /public/css (where the other css files are)
 * 													Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String} css				| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 
 * @methods
 * 
 * @events
 * 	confirm									|  Fired when the user makes the changes
 * 	cancel									| Fired when the user cancels the operation
 * 
 * @callbacks
 * 	cbConfirm								|  Fired when the user makes the changes
 * 	cbCancel									| Fired when the user cancels the operation
 * 
 * @slots
 * 	header
 * 	default (ie: the children elements other than slotted content )
 * 
 * @example+ @Lit
 * 	<rps-general-settings
 * 		@confirm="${e => console.log(e)}"
 * 		@cancel="${e => console.log(e)}"
 * 	>
 * 	</rps-general-settings>
 * 
*/

import { GeneralSettings } from './src/settings/GeneralSettings';
if (!window.customElements.get('rps-general-settings'))
	window.customElements.define('rps-general-settings', GeneralSettings);

