import { html } from "lit";
import { ref, createRef } from 'lit/directives/ref.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { CustomLitElement } from "../baseClasses/CustomLitElement";
import '../../rps-input';
import '../../rps-button-icon';
import { textEditorCommands } from "./textEditorCommands";
import { textEditorStyles } from "./css/textEditor.css";
import '../../rps-modal';
import '../../rps-dropdown';

export class TextEditor extends CustomLitElement {
	static styles = textEditorStyles;

	static properties = {
		content: { type: String },
		root: { attribute: false },
		bgPicker: { attribute: false },
		fgPicker: { attribute: false },
		cbInput: { type: Function },
		modal: { attribute: false }
	}

	constructor() {
		super();
		if (this.children && this.children[0]?.innerHTML) {
			this.content = this.children[0].innerHTML.trim();
		}
		this.bgPicker = createRef();
		this.fgPicker = createRef();
		this.linkModalRef = createRef();
		this.cbInput;
		this.modal = null;
	}

	showLinkModal(cbCommand, defaultValue = '') {
		const handleClose = () => {
			this.modal = null;
		}

		const handleSubmit = () => {
			cbCommand(this.linkModalRef.value.value);
			handleClose();
		}

		this.modal = html`<rps-modal opened popup @confirm=${handleSubmit} @cancel=${handleClose}>
            <rps-input ${ref(this.linkModalRef)} id="modal-input" defaultValue="${defaultValue}" label="Enter a URL:" type="text"></rps-input>
        </rps-modal>`;
	}

	async firstUpdated() {
		this.reset();

		window.addEventListener('resize', () => { this.requestUpdate(); }, false);
	}

	get value() {
		const elem = this.root;
		return elem.innerHTML;
	}

	set value(value) {
		this.content = value;
		this.reset();
	}

	handleChange(ev) {
		console.debug('TextEditor:handleChange', this.value.length);
		ev.preventDefault();
		ev.stopPropagation();
		const detail = { control: this, value: this.value, source: this.tagName };

		ev = new CustomEvent('input', { bubbles: true, cancelable: true, composed: true, detail });
		this.dispatchEvent(ev);
		if (this.cbInput) {
			this.cbInput(ev);
		}
	}

	reset() {
		const parser = new DOMParser();
		const doc = parser.parseFromString(this.content, "text/html");
		document.execCommand("defaultParagraphSeparator", false, "br");
		document.addEventListener("selectionchange", () => {
			this.requestUpdate();
		});

		const newRoot = doc.querySelector('body');
		if (newRoot) {
			newRoot.setAttribute('contenteditable', 'true');
			newRoot.addEventListener('input', (ev) => this.handleChange(ev));
		}

		this.root = newRoot;
	}

	renderToolbar(command) {
		const selection = this.shadowRoot.getSelection ? this.shadowRoot.getSelection() : null;
		const tags = [];

		if (selection) {
			let parentNode = selection.baseNode;
			if (parentNode) {
				const checkNode = () => {
					const parentTagName = parentNode.tagName?.toLowerCase().trim();
					if (parentTagName) {
						tags.push(parentTagName);
					}
				}

				while (parentNode) {
					checkNode();
					parentNode = parentNode.parentNode;
				}
			}
		}

		const savedRange = selection?.rangeCount > 0 ? selection.getRangeAt(0).cloneRange() : null;

		const commands = textEditorCommands(command, this, tags, savedRange);

		return html`
        ${commands.map((n, index) => {
			let selectedDropdownValue = null;

			if (n.values) {
				if (!selectedDropdownValue) {
					selectedDropdownValue = n.values.find(val => tags.includes(val.value));
				}
			}

			return html`
                ${n.values ? html`
                    <div style="display: inline-block; width: fit-content; gap: 1rem;">
                    <rps-dropdown id="${n.command}-${index}" defaultValue=${selectedDropdownValue?.value || '--'} class="small inline" label="${n.name}: " .items=${n.values.map(x => ({ id: x.value, text: x.name }))} @input=${(e) => {
						const val = e.detail.value;

						if (val === '--') {
							command(n.command, 'div');
						} else if (typeof n.command === 'string') {
							command(n.command, val);
						}
					}}
                    >
                    </rps-dropdown>
                </div>
                ` : html`
                        <rps-button-icon
                            svg="${ifDefined(n.icon) || ""}"
									 css="${n.active ? 'button { outline: 2px solid yellow !important; background-color: rgba(190,200,10,0.4) !important;}' : ''}"
                            class="small secondary"

                            @click=${() => {
						if (n.values) { }
						else if (typeof n.command === 'string') {
							command(n.command, n.command_value);
						} else {
							n.command();
						}
					}}
                        ></rps-button-icon>
                `}
            `;
		})}`;
	}

	render() {
		return html`<main>
            <input ${ref(this.bgPicker)} type="color" style="display:none" />
            <input ${ref(this.fgPicker)} type="color" style="display:none" />
            <div id="editor-actions">
                <div id="toolbar">
                    ${this.renderToolbar((command, val, range) => {

			console.debug("Editor command:", command, val);
			if (command === 'createLink') {
				const selection = this.shadowRoot.getSelection();
				document.execCommand(command, false, val);

				if (selection.anchorNode?.parentElement) {
					selection.anchorNode.parentElement.target = "_blank";
				}
			} else {
				document.execCommand(command, false, val);
			}

			this.requestUpdate();
		}, this.content)}
                </div>
            </div>
            <div id="editor">${this.root}</div>
            ${this.modal}
    </main>`;
	}

}

