/**
 * @name rps-plus-minus
 * @type {PlusMinusButtons}
 * 
 * @notes
 * This control is used to provide a numeric control [+-] control
 * 
 * @attributes + @properties 				| Changes trigger a re-render
 * Casing usages								| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {Boolean} 	disabled		| Is this control disabled? 
 * 	@property {Boolean} 	readonly 	| is this control readonly?
 * 	@property {String} 	customCss 	| Specify a custom .css file to include, use cssPath to override elements,
 * 													DO NOT override the styling definition by re-defining the css styling
 * 													Put this file into the *window.cssPath* folder @see {link README.md}
 * 													eg: /public/css (where the other css files are)
 * 													Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css			| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 
 * @styling
 * 	symbols [default]						| display a "up" and "down" buttons instead of a "+" and a "-" buttons
 * 	plus-minus								| display a "+" and a "-" buttons  instead of "up" and "down" buttons
 * 	small										| display the control in a minified format (eg: can be used inline in the grid)
 * 	
 * 
 * @methods
 * 	none
 * 
 * @events
 * 	click										| "click" for plus button
 * 	click										| "click" for minus button
 * 
 * @callbacks
 * 	None
 * 
 * @slots
 * 	None
 * 
*/
import { PlusMinusButtons } from './src/input/PlusMinus';

if (!window.customElements.get('rps-plus-minus'))
	window.customElements.define('rps-plus-minus', PlusMinusButtons);

