import { Box, Flex, Grid } from '@odo/components/elements/layout';
import { Heading, Text } from '@odo/components/elements/typography';
import { useAttributeContext } from '@odo/hooks/attributes';
import { cssColor } from '@odo/utils/css-color';
import type { Dispatch, SetStateAction } from 'react';
import { useRef, useState, useEffect } from 'react';
import { error } from '@odo/utils/toast';
import { queryProduct } from '@odo/graphql/product-new';
import type { ApiCategoryBreadcrumb, ApiCustomOption } from '@odo/types/api';
import { queryCustomOptions } from '@odo/graphql/product/custom-options';
import { ReactComponent as IconValid } from '@odo/assets/svg/check-circle.svg';
import { ReactComponent as IconSpinner } from '@odo/assets/svg/tube-spinner.svg';
import { ReactComponent as IconExclamation } from '@odo/assets/svg/exclamation-circle.svg';
import { motion, AnimatePresence } from 'framer-motion';
import type { GetProductInterface } from '@odo/types/api-new';
import { produce } from 'immer';
import { getProductToEditorProduct } from '@odo/transformers/product';
import { exportApiLogs } from '@odo/data/api-logs/cache';
import { loadBreadcrumbs } from '@odo/data/product/category';
import { getDraft } from '@odo/data/product/draft/cache';
import type { EditorProductInterface } from '@odo/types/portal';
import { Link } from 'react-router-dom';

const PRODUCT_ERROR_TOAST_ID = 'load-product-error';
const CUSTOM_OPTIONS_ERROR_TOAST_ID = 'load-custom-options-error';

interface RawProductData {
  product?: GetProductInterface;
  customOptions?: ApiCustomOption[];
  breadcrumbs?: ApiCategoryBreadcrumb[];
}

enum LoadingMessageStatus {
  loading = 'loading',
  success = 'success',
  error = 'error',
}

interface LoadingMessage {
  id: string;
  label: string;
  status: LoadingMessageStatus;
}

const loadProduct = async ({
  id,
  signal,
  setLoadingMessages,
  setRawProductData,
  notFound,
}: {
  id: string;
  signal: AbortSignal;
  setLoadingMessages: Dispatch<SetStateAction<LoadingMessage[]>>;
  setRawProductData: Dispatch<SetStateAction<RawProductData>>;
  notFound: () => void;
}) => {
  let isActive = true;

  signal.addEventListener('abort', () => (isActive = false));

  try {
    const product = await queryProduct({ id });

    // just exit if we've dismounted while the API call was running
    if (!isActive) return;

    if (!product) {
      throw new Error('Failed to load product');
    }

    setLoadingMessages(messages => [
      ...messages.map(message =>
        message.id === 'getProduct'
          ? { ...message, status: LoadingMessageStatus.success }
          : message
      ),
    ]);

    const breadcrumbs = await loadBreadcrumbs({
      categories: product.categories,
      setLoading: (status: boolean) => {
        if (status) {
          setLoadingMessages(messages => [
            ...messages.filter(({ id }) => id !== 'getBreadcrumbs'),
            {
              id: 'getBreadcrumbs',
              label: 'Category Breadcrumbs...',
              status: LoadingMessageStatus.loading,
            },
          ]);
        }
      },
      onComplete: () => {
        setLoadingMessages(messages => [
          ...messages.map(message =>
            message.id === 'getBreadcrumbs'
              ? { ...message, status: LoadingMessageStatus.success }
              : message
          ),
        ]);
      },
      onError: () => {
        setLoadingMessages(messages => [
          ...messages.map(message =>
            message.id === 'getBreadcrumbs'
              ? { ...message, status: LoadingMessageStatus.error }
              : message
          ),
        ]);
      },
    });

    setRawProductData(
      produce(next => {
        next.product = product;
        next.breadcrumbs = breadcrumbs;
        return next;
      })
    );
  } catch (e) {
    if (!isActive) return;

    console.error(e);
    error(
      e instanceof Error && typeof e.message === 'string'
        ? e.message
        : 'Error loading product',
      { id: PRODUCT_ERROR_TOAST_ID, position: 'top-center' }
    );

    setLoadingMessages(messages => [
      ...messages.map(message =>
        message.id === 'getProduct'
          ? { ...message, status: LoadingMessageStatus.error }
          : message
      ),
    ]);

    notFound();
  }
};

const loadCustomOptions = async ({
  id,
  signal,
  setLoadingMessages,
  setRawProductData,
}: {
  id: string;
  signal: AbortSignal;
  setLoadingMessages: Dispatch<SetStateAction<LoadingMessage[]>>;
  setRawProductData: Dispatch<SetStateAction<RawProductData>>;
}) => {
  let isActive = true;

  signal.addEventListener('abort', () => (isActive = false));

  try {
    const customOptions = await queryCustomOptions({ productId: id });

    // just exit if we've dismounted while the API call was running
    if (!isActive) return;

    if (!customOptions) {
      throw new Error('Failed to load custom options');
    }

    setLoadingMessages(messages => [
      ...messages.map(message =>
        message.id === 'getCustomOptions'
          ? { ...message, status: LoadingMessageStatus.success }
          : message
      ),
    ]);

    setRawProductData(
      produce(next => {
        next.customOptions = customOptions;
        return next;
      })
    );
  } catch (e) {
    if (!isActive) return;

    console.error(e);
    error(
      e instanceof Error && typeof e.message === 'string'
        ? e.message
        : 'Error loading custom options',
      { id: CUSTOM_OPTIONS_ERROR_TOAST_ID, position: 'top-center' }
    );

    setLoadingMessages(messages => [
      ...messages.map(message =>
        message.id === 'getCustomOptions'
          ? { ...message, status: LoadingMessageStatus.error }
          : message
      ),
    ]);
  }
};

const loadDraft = async ({
  id,
  signal,
  setLoadingMessages,
  setDraftProductData,
  notFound,
}: {
  id: number;
  signal: AbortSignal;
  setLoadingMessages: Dispatch<SetStateAction<LoadingMessage[]>>;
  setDraftProductData: Dispatch<
    SetStateAction<EditorProductInterface | undefined>
  >;
  notFound: () => void;
}) => {
  let isActive = true;

  signal.addEventListener('abort', () => (isActive = false));

  try {
    const draft = await getDraft(id);

    // just exit if we've dismounted while the loading the draft
    if (!isActive) return;

    setLoadingMessages(messages => [
      ...messages.map(message =>
        message.id === 'getDraft'
          ? { ...message, status: LoadingMessageStatus.success }
          : message
      ),
    ]);

    setDraftProductData(draft);
  } catch (e) {
    if (!isActive) return;

    console.error(e);
    error(
      e instanceof Error && typeof e.message === 'string'
        ? e.message
        : 'Error loading draft from cache',
      { id: CUSTOM_OPTIONS_ERROR_TOAST_ID, position: 'top-center' }
    );

    notFound();
  }
};

const useBaseLoader = () => {
  const { isReady: areAttributesLoaded, attributes } = useAttributeContext();

  const [productNotFound, setProductNotFound] = useState(false);

  const [loadingMessages, setLoadingMessages] = useState<LoadingMessage[]>([
    {
      id: 'getAttributes',
      label: 'Attributes...',
      status: areAttributesLoaded
        ? LoadingMessageStatus.success
        : LoadingMessageStatus.loading,
    },
  ]);

  /**
   * Update attributes loading message.
   */
  useEffect(() => {
    if (areAttributesLoaded) {
      setLoadingMessages(messages => [
        ...messages.map(message =>
          message.id === 'getAttributes'
            ? { ...message, status: LoadingMessageStatus.success }
            : message
        ),
      ]);
    }
  }, [areAttributesLoaded]);

  return {
    areAttributesLoaded,
    attributes,
    productNotFound,
    setProductNotFound,
    loadingMessages,
    setLoadingMessages,
  };
};

const LoaderOutput = ({
  productNotFound,
  loadingMessages,
  isDraft,
}: {
  productNotFound?: boolean;
  loadingMessages: LoadingMessage[];
  isDraft?: boolean;
}) => {
  if (productNotFound) {
    return (
      <Grid width="450px" gap={[2, 3]}>
        <Heading fontSize={[3, 4]} textAlign="center">
          Product Not Found.
        </Heading>

        <Text textAlign="center">
          Are you sure you have the correct link?
          {isDraft && (
            <>
              <br />
              This might've been the URL for a draft that no longer exists.
            </>
          )}
        </Text>

        <Text textAlign="center">
          If you're sure you're in the right place, try refreshing the page.
        </Text>

        <Text textAlign="center">
          Otherwise click here to return to the{' '}
          <Link
            to="/"
            style={{
              all: 'unset',
              fontWeight: 800,
              textDecoration: 'underline',
            }}
          >
            dashboard
          </Link>
          .
        </Text>
      </Grid>
    );
  }

  return (
    <Box width="300px">
      <Heading textAlign="center" color={cssColor('text')}>
        Loading
      </Heading>

      {loadingMessages.length > 0 && (
        <Grid mt={4} gap={[2, 3]}>
          <AnimatePresence>
            {loadingMessages.map(message => (
              <motion.div
                key={message.id}
                layout
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 20, opacity: 0 }}
              >
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  gap={2}
                >
                  <Text color={cssColor('text')}>{message.label}</Text>

                  {message.status === LoadingMessageStatus.loading && (
                    <IconSpinner
                      width={16}
                      height={16}
                      color={cssColor('palette-blue')}
                    />
                  )}

                  {message.status === LoadingMessageStatus.success && (
                    <IconValid
                      width={16}
                      height={16}
                      color={cssColor('palette-turquoise')}
                    />
                  )}

                  {message.status === LoadingMessageStatus.error && (
                    <IconExclamation
                      width={16}
                      height={16}
                      color={cssColor('palette-pink')}
                    />
                  )}
                </Flex>
              </motion.div>
            ))}
          </AnimatePresence>
        </Grid>
      )}
    </Box>
  );
};

export interface ProductLoaderResponse {
  editorProduct: EditorProductInterface;
  rawProductData: RawProductData;
}

export const ProductLoader = ({
  id,
  callback,
}: {
  id?: number;
  callback: (res: ProductLoaderResponse) => void;
}) => {
  const {
    areAttributesLoaded,
    attributes,
    productNotFound,
    loadingMessages,
    setLoadingMessages,
    setProductNotFound,
  } = useBaseLoader();

  const productLoadedRef = useRef(false);

  const [rawProductData, setRawProductData] = useState<RawProductData>({
    product: undefined,
    customOptions: undefined,
    breadcrumbs: undefined,
  });

  /**
   * Existing deal prep.
   */
  useEffect(() => {
    if (!id || productLoadedRef.current) return;

    const controller = new AbortController();

    // set loading messages
    setLoadingMessages(messages => [
      ...messages.filter(
        ({ id }) => !['getProduct', 'getCustomOptions'].includes(id)
      ),
      {
        id: 'getProduct',
        label: 'Product...',
        status: LoadingMessageStatus.loading,
      },
      {
        id: 'getCustomOptions',
        label: 'Custom Options...',
        status: LoadingMessageStatus.loading,
      },
    ]);

    // running allSettled so that they can all run in parallel and it will wait for them all to finish.
    (async () => {
      await Promise.allSettled([
        loadProduct({
          id: id.toString(),
          signal: controller.signal,
          setLoadingMessages,
          setRawProductData,
          notFound: () => setProductNotFound(true),
        }),
        loadCustomOptions({
          id: id.toString(),
          signal: controller.signal,
          setLoadingMessages,
          setRawProductData,
        }),
      ]);
    })();

    return () => controller.abort();
  }, [id, setLoadingMessages, setProductNotFound]);

  /**
   * Wait for attributes and all of our raw product data.
   * Once available transform the data and apply defaults, then set on our contexts.
   */
  useEffect(() => {
    if (
      areAttributesLoaded &&
      rawProductData.product &&
      rawProductData.customOptions
    ) {
      productLoadedRef.current = true;

      try {
        const editorProduct = getProductToEditorProduct({
          product: rawProductData.product,
          customOptions: rawProductData.customOptions,
          breadcrumbs: rawProductData.breadcrumbs,
          attributes,
        });
        if (!editorProduct) {
          throw new Error('Product data is invalid');
        }

        callback({ editorProduct, rawProductData });
      } catch (e) {
        error(
          e instanceof Error && typeof e.message === 'string'
            ? e.message
            : 'Product data is invalid',
          {
            position: 'top-center',
            messageOptions: {
              action: { label: 'Export Log', callback: exportApiLogs },
            },
          }
        );
      }
    }
  }, [rawProductData, areAttributesLoaded, attributes, callback]);

  return (
    <LoaderOutput
      productNotFound={productNotFound}
      loadingMessages={loadingMessages}
    />
  );
};

export const DraftLoader = ({
  id,
  callback,
}: {
  id?: number;
  callback: (draft: EditorProductInterface) => void;
}) => {
  const {
    areAttributesLoaded,
    attributes,
    productNotFound,
    loadingMessages,
    setLoadingMessages,
    setProductNotFound,
  } = useBaseLoader();

  const productLoadedRef = useRef(false);

  const [draftProductData, setDraftProductData] = useState<
    EditorProductInterface | undefined
  >();

  /**
   * Draft deal prep.
   */
  useEffect(() => {
    if (!id || productLoadedRef.current) return;

    const controller = new AbortController();

    // set loading messages
    setLoadingMessages(messages => [
      ...messages.filter(({ id }) => !['getDraft'].includes(id)),
      {
        id: 'getDraft',
        label: 'Draft Product...',
        status: LoadingMessageStatus.loading,
      },
    ]);

    loadDraft({
      id,
      signal: controller.signal,
      setLoadingMessages,
      setDraftProductData,
      notFound: () => setProductNotFound(true),
    });

    return () => controller.abort();
  }, [id, setLoadingMessages, setProductNotFound]);

  /**
   * Wait for attributes and our draft product data.
   * Once available, set the data on our contexts.
   */
  useEffect(() => {
    if (areAttributesLoaded && draftProductData) {
      productLoadedRef.current = true;

      try {
        callback(draftProductData);
      } catch (e) {
        error(
          e instanceof Error && typeof e.message === 'string'
            ? e.message
            : 'Draft data is invalid',
          {
            position: 'top-center',
            messageOptions: {
              action: { label: 'Export Log', callback: exportApiLogs },
            },
          }
        );
      }
    }
  }, [id, draftProductData, areAttributesLoaded, attributes, callback]);

  return (
    <LoaderOutput
      productNotFound={productNotFound}
      loadingMessages={loadingMessages}
      isDraft
    />
  );
};
