import { html } from 'lit';
import { BaseInputControl } from '../baseClasses/BaseInputControl';
import '../../rps-input-label';
import '../../rps-button';
import '../../rps-svg';
import { iconNames } from '../../svg-icons';
import { styles } from './css/buttonStrip.css.js';

export class ButtonStrip extends BaseInputControl {
	static styles = styles;

	static get properties() {
		return {
			buttons: { type: Array },
			required: { type: Boolean },			// affects the Label
			multiSelect: { type: Boolean },		// Can multiple buttons be selected
			text: { type: String },
			selected: { type: String },
			cbClick: { attribute: false },
		};
	}

	constructor() {
		super();

		this.required = false;
		this.multiSelect = false;
		this.text = "Label";

		this.buttons = [
			{ id: "one", text: "First" },
			{ id: "two", text: "Second", leftSlot: iconNames.add },
			{ id: "three", text: "Third", rightSlot: iconNames.add, selected: true },
			{ id: "four", text: "Fourth", leftSlot: iconNames.add, rightSlot: iconNames.del },
			{ id: "five", text: "Fifth" },
			{ id: "six", text: "Sixth" },
		];
	}



	/**
	 * Disable the button that has been pressed, and enable the others
	 *
	 * @param {String} buttonId - the Buttons Id that must be "pressed"
	 * @memberof ButtonStrip
	 */
	setStatus(buttonId) {
		const pressed = this.renderRoot.querySelector(`#${buttonId}`);

		if (this.multiSelect) {
			pressed.toggleAttribute('selected');
			return;
		}

		const all = this.renderRoot.querySelectorAll(`rps-button`);
		all.forEach(e => e.removeAttribute('selected'));

		if (pressed) pressed.setAttribute('selected', '');
	}

	/**
	 * Retrieve an Array all the selected ID's
	 *
	 * @readonly
	 * @returns {Array}
	 * @memberof ButtonStrip
	 */
	get selectedIds() {
		const allNodes = this.renderRoot.querySelectorAll(`rps-button`);
		const nodes = Array.from(allNodes);

		const selected = nodes.filter(e => e.selected);
		const ids = [];
		selected.forEach(elem => ids.push(elem.id));
		return ids;
		//return nodes.filter(e => e.selected);
	}

	/**
	 * Click event
	 *
	 * @param {Event} event
	 * @memberof ButtonStrip
	 */
	click(event) {
		event.stopPropagation();

		this.setStatus(event.target.id);
		const detail = { event: 'click', source: this.tagName, buttonId: event.target.id };
		const e = new CustomEvent('click', { detail, bubbles: true, composed: true, cancelable: true });
		this.dispatchEvent(e);
		console.debug('ButtonStrip: Click', e);

		if (this.cbClick) this.cbClick(e);
	}

	createButtons() {
		return html`${this.buttons.map(e => {
			let leftSlot = '';
			if (e.leftSlot) {
				leftSlot = html`
				<div slot="slot-left">
					<rps-svg svg="${e.leftSlot}"></rps-svg>
				</div>
			`;
			}

			let rightSlot = '';
			if (e.rightSlot) {
				rightSlot = html`
				<div slot="slot-right">
					<rps-svg svg="${e.rightSlot}"></rps-svg>
				</div>
			`;
			}


			return html`
				<rps-button id="${e.id}" class="${this.className} stretch strip" @click=${this.click}
					?disabled=${this.disabled} ?selected=${e.selected}
				>${e.text}
					${leftSlot}
					${rightSlot}
				</rps-button>
				`
		})}`;
	}

	render() {
		return html`
			${this.addCss()}

			<div class="input-container ${this.className}">
				<rps-input-label class="${this.className}" ?required=${this.required} text="${this.text}"></rps-input-label>
				<div class="input">
					${this.createButtons()}
				</div>
			</div>
		`;
	}
}
