import { html } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import "../../rps-button";
import "../../rps-checkbox";
import "../../rps-button-icon";
import "../../rps-input-label";
import "../../rps-svg";
import { iconNames } from '../../svg-icons';
import { DOM } from '../functions/DOM';
import { styles } from './css/accordion.css.js';

export class Accordion extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			panels: { type: Array },
			cbClick: { attribute: false },
		};
	}

	constructor() {
		super();
		this.panels = [
			{
				id: "pan1",
				control: "label",
				label: "Section 1",
				classNames: '',
				required: true,
				disabled: false,
			},
			{
				id: "pan2",
				control: "checkbox",
				label: "Section 2",
				classNames: 'error',
				required: true,
				disabled: false,
			},
			{
				id: "pan3",
				control: "label",
				label: "Section 3",
				classNames: 'warning',
				required: true,
				disabled: false,
			},
			{
				id: "pan4",
				control: "text",
				label: "Section 4",
				iconName: iconNames.cardView,
				classNames: '',
				required: true,
				disabled: false,
			},
			{
				id: "pan5",
				control: "text",
				label: "Section 5",
				iconName: iconNames.undo,
				classNames: '',
				required: true,
				disabled: false,
			},
		];
	}


	/**
	 * Get all the buttons
	 *
	 * @readonly
	 * @memberof Accordion
	 */
	get buttonControls() {
		const buttons = this.renderRoot.querySelectorAll('.header');
		return buttons;
	}

	/**
	 * Get al the panels
	 *
	 * @readonly
	 * @memberof Accordion
	 */
	get panelControls() {
		const panels = this.renderRoot.querySelectorAll('.panel');
		return panels;
	}

	/**
	 * When the Panel item is clicked
	 *
	 * @param {Event} event
	 * @memberof Accordion
	 */
	_panelClicked(event) {
		const dom = new DOM();
		const parent = dom.walkUpByClass(event.target, "accordion");
		const currButton = parent.querySelector('.header');
		const thisPanel = parent.querySelector('.panel');
		const panels = this.panelControls;
		const buttons = this.buttonControls;
		let expanded = false;

		panels.forEach(panel => {
			if (panel.id !== thisPanel.id) {
				panel.style.display = "none";
			}
		});

		buttons.forEach(button => {
			button.classList.toggle("active", currButton.id === button.id);
		});

		/* Toggle between hiding and showing the active panel */
		if (thisPanel.style.display === "block") {
			thisPanel.style.display = "none";
			currButton.classList.toggle("active", false);
		} else {
			expanded = true;
			thisPanel.style.display = "block";
		}

		console.debug('Accordion:click', event.target);
		event.preventDefault();
		event.stopPropagation();
		
		const detail = {
			button: event.target,
			panel: thisPanel,
			source: this.tagName,
			panels,
			buttons: this.buttonControls,
			expanded,
		};
		const e = new CustomEvent('click', { bubbles: true, cancelable: true, composed: true, detail });
		this.dispatchEvent(e);

		if (this.cbClick) this.cbClick(e);
	}

	/**
	 * Create a checkbox in the header of the accordion
	 *
	 * @param {Object} panel The definition of this panel
	 * @return {html} 
	 * @memberof Accordion
	 */
	_createCheckbox(panel) {
		return html`
			<div class="accordion">
				<div class="header" @click=${this._panelClicked} id="${panel.id}Header" >
					<rps-checkbox label="${panel.label}" class="${panel.classNames}" ?required=${panel.required}>
					</rps-checkbox>
					<rps-svg class="close-icon" svg="${iconNames.angleDown}"></rps-svg>
				</div>
				<div class="panel" id="${panel.id}Panel">
					<slot name="${panel.id}"></slot>
				</div>
			</div>
		`;
	}

	/**
	 * Create a button-icon in the header of the accordion
	 *
	 * @param {Object} panel The definition of this panel
	 * @return {html} 
	 * @memberof Accordion
	 */
	_createInputLabel(panel) {
		return html`
			<div class="accordion">
				<div class="header" @click=${this._panelClicked} id="${panel.id}Header">
					<rps-input-label
						?required=${panel.required}
						text=${panel.label}
						class="${this.className} inline ${panel.classNames}"
					></rps-input-label>
					<rps-svg class="close-icon" svg="${iconNames.angleDown}"></rps-svg>
				</div>
				<div class="panel" id="${panel.id}Panel">
					<slot name="${panel.id}"></slot>
				</div>
			</div>
		`;
	}

	/**
	 * Create text in the header of the accordion
	 *
	 * @param {Object} panel The definition of this panel
	 * @return {html} 
	 * @memberof Accordion
	 */
	_createText(panel) {
		return html`
			<div class="accordion">
				<div class="header" @click=${this._panelClicked} id="${panel.id}Header">
					<div slot="icon">
						<rps-svg svg="${panel.iconName ?? iconNames.comments}"></rps-svg>
					</div>
					${panel.label}
					<rps-svg class="close-icon" svg="${iconNames.angleDown}"></rps-svg>
				</div>
				<div class="panel" id="${panel.id}Panel">
					<slot name="${panel.id}"></slot>
				</div>
			</div>
		`;
	}

	/**
	 * Create the buttons and panels for the Accordion
	 *
	 * @return {html} 
	 * @memberof Accordion
	 */
	_displayPanels() {
		// button, checkbox, text, icon
		return this.panels.map(panel => {
			if (panel.control === 'checkbox') {
				return this._createCheckbox(panel);
			} else if (panel.control === 'text') {
				return this._createText(panel);
			} else if (panel.control === 'label') {
				return this._createInputLabel(panel);
			}
		});
	}


	render() {
		return html`
			${this.addCss(true)}
			${this._displayPanels()}
		`;
	}

}

