import { html } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import '../../rps-svg';
import { iconNames } from '../../svg-icons';
import { styles } from './css/inputLabel.css.js';
export class InputLabel extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			text: { type: String },
			hide: { Boolean },
			required: { type: Boolean },			// is input required
			disabled: { type: Boolean },
			hideStatusIcon: { type: Boolean },
		};
	}

	constructor() {
		super();
		this.hide = false;
		this.required = false;
		this.disabled = false;
		this.hideStatusIcon = false;
	}

	render() {
		let statusIcon = '';

		if (this.hideStatusIcon) {
			// dont display the status icon
		} else if (this.classList.contains('info')) {
			statusIcon = html`<rps-svg svg="${iconNames.info}" />`;
		} else if (this.classList.contains('help')) {
			statusIcon = html`<rps-svg svg="${iconNames.help}" />`;
		} else if (this.classList.contains('success')) {
			statusIcon = html`<rps-svg svg="${iconNames.check}" />`;
		} else if (this.classList.contains('warning')) {
			statusIcon = html`<rps-svg svg="${iconNames.warning}" />`;
		} else if (this.classList.contains('error')) {
			statusIcon = html`<rps-svg svg="${iconNames.error}" />`;
		}

		let requiredIcon = '';
		if (this.required) {
			requiredIcon = html`<rps-svg class="asterisk" svg="${iconNames.required}" />`;
		}


		return html`
			<div class="${this.className}">
				${statusIcon}
				<slot>${this.text}</slot>
				${requiredIcon}
			</div>
		`;
	}

}