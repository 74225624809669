import type {
  CreateProductInput,
  ProductSizeChartImageInput,
  UpdateProductInput,
} from '@odo/types/api-new';
import type {
  EditorProductInterface,
  EditorProductSizeInfoImage,
} from '@odo/types/portal';
import { isEmptyHTML } from '@odo/utils/html';
import { readFileAsBase64 } from '@odo/data/product/images';

const prepareSizeChartImageInput = async (
  image: EditorProductSizeInfoImage
): Promise<ProductSizeChartImageInput | undefined> => {
  if (image.file) {
    // if this is a new image we need to read it in as a base64 string
    const { mimeType, base64 } = await readFileAsBase64({ file: image.file });

    // we can ignore all the other props in favour of these upload props
    return {
      fileName: image.file.name,
      fileMimeType: mimeType,
      fileLabel: image.file.name,
      fileContents: base64,
    };
  }

  if (!image.url || image.willBeConvertedFromImage) return undefined;

  return {
    url: image.url,
    filePath: image.filePath,
    ...(image.shouldDelete ? { delete: true } : {}),
  };
};

/**
 * NOTE: this transformer is currently async coz it needs to read the file as base64.
 * When we move to GCS we might want to change this a bit.
 */
export const editorSizeInfoToCreateSizeChartInput = async (
  sizeInfo: EditorProductInterface['sizeInfo']
): Promise<CreateProductInput['sizeChart']> => ({
  ...(sizeInfo.recommendation && !isEmptyHTML(sizeInfo.recommendation)
    ? { recommendation: sizeInfo.recommendation }
    : {}),
  ...(sizeInfo.measurement?.id
    ? { measurement: parseInt(sizeInfo.measurement.id, 10) }
    : {}),
  mobile: await prepareSizeChartImageInput(sizeInfo.mobile),
  tablet: await prepareSizeChartImageInput(sizeInfo.tablet),
  desktop: await prepareSizeChartImageInput(sizeInfo.desktop),
});

/**
 * NOTE: this transformer is currently async coz it needs to read the file as base64.
 * When we move to GCS we might want to change this a bit.
 */
export const editorSizeInfoToUpdateSizeChartInput = async (
  sizeInfo: EditorProductInterface['sizeInfo']
): Promise<UpdateProductInput['sizeChart']> => ({
  id: sizeInfo.id,
  recommendation:
    sizeInfo.recommendation && !isEmptyHTML(sizeInfo.recommendation)
      ? sizeInfo.recommendation
      : null,
  measurement: sizeInfo.measurement?.id
    ? parseInt(sizeInfo.measurement.id, 10)
    : null,
  mobile: await prepareSizeChartImageInput(sizeInfo.mobile),
  tablet: await prepareSizeChartImageInput(sizeInfo.tablet),
  desktop: await prepareSizeChartImageInput(sizeInfo.desktop),
});
