import { html } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { ref, createRef } from 'lit/directives/ref.js';
import { styles } from './css/carousel.css.js';

export class Carousel extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			values: { type: Array },
			isHorizontal: { type: Boolean },
			selectedIndex: { type: Number },
			widthRem: { type: String },
			heightRem: { type: String },
			fontSizeRem: { type: String },
			fontWeight: { type: String },
			textColor: { type: String },
			textAlign: { type: String },
			showFrame: { type: Boolean },
			perspectiveRem: { type: String },
			backgroundColors: { type: Boolean },
			background: { type: String },
			cbClick: { attribute: false },
		};
	}


	constructor() {
		super();
		this.values = [1, 2, 3, 4, 5, 6, 7, 8, 9];
		this.selectedIndex = 0;
		this.isHorizontal = true;
		this.rotateFn = this.isHorizontal ? 'rotateY' : 'rotateX';
		this.widthRem = 21;
		this.heightRem = 15;
		this.fontSizeRem = 8;
		this.fontWeight = 'bold';
		this.textColor = 'white';
		this.textAlign = 'center';
		this.showFrame = false;
		this.perspectiveRem = "100";
		this.backgroundColors = false;
		this.preSelectedColors = html`
		.carousel_cell:nth-child(9n+1) { background: hsla(  0, 100%, 50%, 0.8); }
		.carousel_cell:nth-child(9n+2) { background: hsla( 40, 100%, 50%, 0.8); }
		.carousel_cell:nth-child(9n+3) { background: hsla( 80, 100%, 50%, 0.8); }
		.carousel_cell:nth-child(9n+4) { background: hsla(120, 100%, 50%, 0.8); }
		.carousel_cell:nth-child(9n+5) { background: hsla(160, 100%, 50%, 0.8); }
		.carousel_cell:nth-child(9n+6) { background: hsla(200, 100%, 50%, 0.8); }
		.carousel_cell:nth-child(9n+7) { background: hsla(240, 100%, 50%, 0.8); }
		.carousel_cell:nth-child(9n+8) { background: hsla(280, 100%, 50%, 0.8); }
		.carousel_cell:nth-child(9n+0) { background: hsla(320, 100%, 50%, 0.8); }
	  `;
		this.background = 'rgba(76, 175, 80, 0.8)';

		// create a ref object
		this.carousel = createRef();
	}


	/**
	 * Update the Carousel with a new set of values, and re-render it
	 *
	 * @param {Array} values
	 * @memberof Carousel
	 */
	setValues(values) {
		this.values = values;
		this.rotateCarousel();
	}

	/**
	 * Update the maths values needed to redraw the carousel correctly
	 *
	 * @memberof Carousel
	 */
	_setMathsValues() {
		const cellCount = this.values.length;
		this.theta = 360 / cellCount;
		const cellSize = this.isHorizontal ? this.cellWidth : this.cellHeight;
		this.radius = Math.round((cellSize / 2) / Math.tan(Math.PI / cellCount));
	}

	/**
	 * Rotate the carousel using the selected index
	 *
	 * @memberof Carousel
	 */
	rotateCarousel() {
		this._setMathsValues();
		const angle = this.theta * this.selectedIndex * -1;
		this.carousel.value.style.transform = 'translateZ(' + -this.radius + 'px) ' + this.rotateFn + '(' + angle + 'deg)';
	}


	/**
	 * After the first render has occurred set class variables to DOM elements for future use
	 *
	 * @param {Array} changedProperties
	 * @memberof Carousel
	 */
	firstUpdated(changedProperties) {
		super.firstUpdated(changedProperties);
		this.cellWidth = this.carousel.value.offsetWidth;
		this.cellHeight = this.carousel.value.offsetHeight;

		// force a redraw after it displays so the transformations can take place
		this.selectedIndex--;
		this.selectedIndex++;
		this.rotateCarousel();
	}


	/**
	 * Changes the orientation from horizontal <-> vertical
	 *
	 * @memberof Carousel
	 */
	orientationChange() {
		this.isHorizontal = !this.isHorizontal;
		this.rotateFn = this.isHorizontal ? 'rotateY' : 'rotateX';
		this.rotateCarousel();
	}

	/**
	 * Rotate 1 back
	 *
	 * @memberof Carousel
	 */
	previous() {
		console.debug('Carousel:previous');
		this.selectedIndex--;
		this.rotateCarousel();
	}

	/**
	 * Rotate 1 forwards
	 *
	 * @memberof Carousel
	 */
	next() {
		console.debug('Carousel:next');
		this.selectedIndex++;
		this.rotateCarousel();
	}

	/**
	 * Manually set the active page
	 *
	 * @param {Number} currentPage
	 * @memberof Carousel
	 */
	setCurrentPage(currentPage) {
		console.debug('Carousel:next');
		this.selectedIndex = currentPage;
		this.rotateCarousel();
	}


	/**
	 * Event that is fired when one of the "Cells" is clicked
	 *
	 * @param {Event} event - normal click event
	 * @param {Value} val - the value that is attached to this cell
	 * @param {Number} index - The index of the cell tat was clicked
	 * @memberof Carousel
	 */
	cellClicked(event, val, index) {
		const detail = {
			cell: val,
			type: "cell-click",
			source: this.tagName,
			clickedIndex: index
		};

		event.cancelBubble = true; // Stop input events from bubbling up
		event.stopPropagation();

		event = new CustomEvent('click', { detail, bubbles: true, cancelable: true, composed: true })
		this.dispatchEvent(event);
		console.debug('Carousel:cellClicked', val, event);

		if (this.cbClick) this.cbClick(event);
	}

	/**
	 * render the array of items passed to the carousel
	 *
	 * @returns
	 * @memberof Carousel
	 */
	renderCells() {
		//return this.values.map(val => html`<div class="carousel_cell" @click=${(e) => this.cellClicked(e, val)}>${val}</div>`);

		const cellCount = this.values.length;
		let cellAngle;
		let opacity = 1;

		return this.values.map((val, i) => {
			if (i < cellCount) cellAngle = this.theta * i;

			return html`
				<div class="carousel_cell"
					style="opacity: ${opacity}; transform: ${this.rotateFn}(${cellAngle}deg) translateZ(${this.radius}px);"
					@click=${(e) => this.cellClicked(e, val, i)}>${val}</div>
				`;
		});

	}

	render() {
		return html`
		${this.addCss()}
		<style>
		.scene {
			width: ${this.widthRem}rem;
			height: ${this.heightRem}rem;
			${this.showFrame === true ? `border: 1px solid #CCC;` : ''}
			perspective: ${this.perspectiveRem}rem;
		}

		.carousel_cell {
			left: ${this.widthRem * 0.1}rem;
			top: ${this.widthRem * 0.1}rem;
			width: ${this.widthRem * 0.9}rem;
			height: ${this.heightRem * 0.9}rem;
			font-size: ${this.fontSizeRem}rem;
			font-weight: ${this.fontWeight};
			color: ${this.textColor};
			text-align: ${this.textAlign};
		 }

		${this.backgroundColors ? this.preSelectedColors : `.carousel_cell { background: ${this.background} }`}

		</style>

	<div class="scene">
		<div class="carousel" ${ref(this.carousel)}>
			${this.renderCells()}
		</div>
	</div>
`;
	}
}
