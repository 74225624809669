import React from 'react';
import PropTypes from 'prop-types';
import styles from './css/LoadingPopup.module.css';

export const LoadingPopup = ({ open, message }) => {
  return (
    <>
      {open && (
        <div className={styles.busySpinnerContainer}>
          <rps-card>
            <div className={styles.busySpinner}>
              <rps-spinner-pause />
              {message}
            </div>
          </rps-card>
        </div>
      )}
    </>
  );
};

LoadingPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};
