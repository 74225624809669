import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { ODOGraphQLEndpoint } from 'config';
import { hasAuthError, isAuthError } from './../utils/hasAuthError';
export class ApolloClients {
  constructor() {
    if (!ApolloClients._instance) {
      this.odoClient = null;
      this.odoClientNoAuth = null;
      this.__init();
      ApolloClients._instance = this;
    } else {
      return ApolloClients._instance;
    }
  }

  __init() {
    const authCheckMiddleware = new ApolloLink((operation, forward) => {
      return forward(operation).map(data => {
        // Disabled for performance impact issues
        // console.debug(`[ApolloClients:odoClient] Received response from upstream: Status ${JSON.stringify(data)}`);
        if (hasAuthError(data)) {
          console.debug(
            '[ApolloClients:odoClient] Authentication error - session expired, logging out.'
          );
          localStorage.removeItem('user');
          localStorage.setItem(
            'authError',
            'Session Expired, please log in again before continuing. (Note: Your work is safe!)'
          );
          localStorage.setItem('lastLocation', window.location.pathname);
          window.location.reload();
        }
        return data;
      });
    });

    const handleError = onError(({ graphQLErrors, networkErrors }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(err => {
          if (isAuthError(err)) {
            console.debug(
              '[ApolloClients:odoClient] Authentication error - session expired, logging out.'
            );
            localStorage.clear();
            localStorage.setItem(
              'authError',
              'Session Expired, please log in again before continuing. (Note: Your work is safe!)'
            );
            localStorage.setItem('lastLocation', window.location.pathname);
            window.location.reload();
          } else {
            console.error(
              `[ApolloClients:odoClient] GraphQL Error: `,
              JSON.stringify(err)
            );
          }
        });
      }
      if (networkErrors) {
        console.error('NETWORK ERROR:', JSON.stringify(networkErrors));
      }
    });

    const httpLink = ApolloLink.from([
      handleError,
      authCheckMiddleware,
      new HttpLink({
        uri: ODOGraphQLEndpoint,
        credentials: 'include',
        fetchOptions: {
          mode: 'cors',
        },
      }),
    ]);

    this.odoClient = new ApolloClient({
      link: httpLink,
      cache: new InMemoryCache(),
    });

    this.odoClientNoAuth = new ApolloClient({
      uri: ODOGraphQLEndpoint,
      credentials: 'include',
      fetchOptions: {
        mode: 'cors',
      },
      cache: new InMemoryCache(),
    });
  }

  /** @type {ApolloClient} */
  get odo() {
    return this.odoClient;
  }

  /** @type {ApolloClient} */
  get odoNoAuth() {
    return this.odoClientNoAuth;
  }
}
