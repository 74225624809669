/** 
 * @name rps-form
 * @type {Form}
 * A form component that does validations, extracts the JSON and adds the buttons needed
 * 
 * 
 * @attributes + @properties 					| Changes trigger a re-render
 * Casing usages									| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String} cancelText			| default="Cancel", The text to display on the cancel button
 * 	@property {String} confirmText		| default="Submit", the text to display on the confirm button
 * 	@property {Boolean} hideCancelButton| Optionally hide the cancel (back button)
 * 	@property {Boolean} hideButtons		| Optionally hide the <rps-standard-buttons> fpr the form
 * 	@property {String} customCss			| Specify a custom .css file to include, use cssPath to override elements,
 * 														DO NOT override the styling definition by re-defining the css styling
 * 														Put this file into the *window.cssPath* folder @see {link README.md}
 * 														eg: /public/css (where the other css files are)
 * 														Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String} css					| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 												
 * 
 * @methods
 * 	getJSON										| Return a JSON object containing the name and value of these form values
 * 	fillJSON										| The JSON object you wish to be filled from this form
 * 	clear()										| Clear all the cached value used by this control (or any other control using the same name)
 * 	getControl(selector)						| Retrieve a child control in this form
 * 														Example: const form = myParent.getControl('input| name="userName"');
 * 	validate 									| Validate the form and return its validity along with a list of error messages (in an object)
 * 														and the relevant controls { isValid: {Boolean}, errorMessages: {Array} }
 * 												
 * @events
 * 	submit										| form submit
 * 	cancel										| form cancel
 * 
 * @callbacks
 * 	cbSubmit										| Callback for submit event
 * 	cbCancel										| Callback for cancel event
 * 
 * 
 * @slots
 * 	The default slot is used to insert all the child elements of this form control
 * 
 * @examples
 * See /demo/form.html for html example
 * See demo/reactJS/Form.js for reactJS example
 * 
*/
import { Form } from './src/input/Form';
if (!window.customElements.get('rps-form'))
	window.customElements.define('rps-form', Form);

