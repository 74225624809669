import { createClient } from '@odo/services/urql';
import type {
  MutationCreateCustomOptionsArgs,
  MutationCreateCustomOptionsOutput,
  MutationRemoveCustomOptionArgs,
  MutationRemoveCustomOptionOutput,
  MutationUpdateCustomOptionArgs,
  MutationUpdateCustomOptionOutput,
  QueryCustomOptionsArgs,
  QueryCustomOptionsOutput,
} from '@odo/types/api';
import { throwGraphQLError } from '@odo/utils/graphql';
import { gql } from 'urql';

const GET_CUSTOM_OPTIONS = gql`
  query getCustomOptions($productId: ID!) {
    getCustomOptions(productId: $productId) {
      ... on CustomOptions {
        id
        productId
        title
        type
        isRequired
        dependency
        isOneTime
        sortOrder
        description

        values {
          valueId
          title
          priceType
          sku
          sortOrder
          quantity
          childrenGroupIds
          cost
          price
          default
          groupId
        }
      }
    }
  }
`;

const CREATE_CUSTOM_OPTIONS = gql`
  mutation createCustomOptions(
    $productId: ID!
    $customOptions: [CustomOptionsInput!]!
  ) {
    createCustomOptions(productId: $productId, customOptions: $customOptions) {
      ... on ResponseMessage {
        status
        code
        message
      }
    }
  }
`;

const UPDATE_CUSTOM_OPTION = gql`
  mutation updateCustomOption(
    $optionId: ID!
    $customOption: UpdateOptionInput!
  ) {
    updateCustomOption(optionId: $optionId, customOption: $customOption) {
      ... on ResponseMessage {
        status
        code
        message
      }
    }
  }
`;

const REMOVE_CUSTOM_OPTION = gql`
  mutation removeCustomOption($optionId: ID!) {
    removeCustomOption(optionId: $optionId) {
      ... on ResponseMessage {
        status
        code
        message
      }
    }
  }
`;

interface ClientParams {
  signal?: AbortSignal;
}

type QueryCustomOptionsParams = QueryCustomOptionsArgs & ClientParams;

type MutationCreateCustomOptionsParams = MutationCreateCustomOptionsArgs &
  ClientParams;

type MutationUpdateCustomOptionParams = MutationUpdateCustomOptionArgs &
  ClientParams;

type MutationRemoveCustomOptionParams = MutationRemoveCustomOptionArgs &
  ClientParams;

/**
 * Custom options query.
 */
export const queryCustomOptions = async ({
  productId,
  signal,
}: QueryCustomOptionsParams) => {
  const { data, error } = await createClient({ signal })
    .query<QueryCustomOptionsOutput, QueryCustomOptionsArgs>(
      GET_CUSTOM_OPTIONS,
      { productId },
      { requestPolicy: 'network-only' }
    )
    .toPromise();

  // only throw errors if we don't get any data back
  if (!(data && data.getCustomOptions) && error) {
    throwGraphQLError(error);
  }

  return data && (data.getCustomOptions || []);
};

/**
 * Create custom options mutation.
 */
export const mutationCreateCustomOptions = async ({
  productId,
  customOptions,
  signal,
}: MutationCreateCustomOptionsParams) => {
  const { data, error } = await createClient({ signal })
    .mutation<
      MutationCreateCustomOptionsOutput,
      MutationCreateCustomOptionsArgs
    >(CREATE_CUSTOM_OPTIONS, { productId, customOptions })
    .toPromise();

  // only throw errors if we don't get any data back
  if (!(data && data.createCustomOptions) && error) {
    throwGraphQLError(error);
  }

  return data && (data.createCustomOptions || false);
};

/**
 * Update custom option mutation.
 */
export const mutationUpdateCustomOption = async ({
  optionId,
  customOption,
  signal,
}: MutationUpdateCustomOptionParams) => {
  const { data, error } = await createClient({ signal })
    .mutation<MutationUpdateCustomOptionOutput, MutationUpdateCustomOptionArgs>(
      UPDATE_CUSTOM_OPTION,
      { optionId, customOption }
    )
    .toPromise();

  // only throw errors if we don't get any data back
  if (!(data && data.updateCustomOption) && error) {
    throwGraphQLError(error);
  }

  return data && (data.updateCustomOption || false);
};

/**
 * Remove custom option mutation.
 */
export const mutationRemoveCustomOption = async ({
  optionId,
  signal,
}: MutationRemoveCustomOptionParams) => {
  const { data, error } = await createClient({ signal })
    .mutation<MutationRemoveCustomOptionOutput, MutationRemoveCustomOptionArgs>(
      REMOVE_CUSTOM_OPTION,
      { optionId }
    )
    .toPromise();

  // only throw errors if we don't get any data back
  if (!(data && data.removeCustomOption) && error) {
    throwGraphQLError(error);
  }

  return data && (data.removeCustomOption || false);
};
