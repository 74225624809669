/**
 * @name rps-toast
 * @type Toast
 * A toast control
 * 
 * 
 * 
 * @attributes + @properties 								| Changes trigger a re-render
 * Casing usages:												| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String}	text		 					| The text to display in the toast
 * 	@property {Boolean}	required 					| Show this as required?
 * 	@property {Boolean}	disabled 					| display as disabled?
 * 	@property {Number}	expireSeconds = 5			| After [x] seconds hide the toast
 * 	@property {String}	position = "topCenter"	| "bottomLeft", "bottomCenter", "bottomRight", "topLeft", "topCenter", "topRight"
 * 	@property {String}	effect = "slide"			| "fade", "slide"
 * 	@property {Boolean}	repeat 						| Must this keep repeating until the user responds?
 * 	@property {Number}	repeatSeconds = 0			| ONLY used if repeat === true. After [x] seconds re-display the toast in a loop until the user clicks on the close button
 * 	@property {Boolean}	opened 						| Display this display in opened mode?
 * 	@property {String}	customCss					| Specify a custom .css file to include, use cssPath to override elements,
 * 																	DO NOT override the styling definition by re-defining the css styling
 * 																	Put this file into the *window.cssPath* folder @see {link README.md}
 * 																	eg: /public/css (where the other css files are)
 * 																	Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css							| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 												
 * 
 * 
 * @Styling (Default: primary, large, solid)
 * ========================================
 * @Colors
 * 	primary:		Primary colors used for the button
 * 	secondary:  Secondary colors used for the button
 * 	tertiary: 	Tertiary colors used for the button
 * @Sizes
 * 	small:		Small button
 * 	medium:		Medium size button
 * 	large:		Large size button
 * disabled:		Disable this button
 * @Filling		
 * 	outline: 	Only an outline for the button displays, no filling
 * 	solid:		The contents of the button are filled, not only the outline
 * @Shape and Shading:
 * 	square:		Square Shape
 * 	rounded:		Rounded corners
 * 	shaded:		Shading for tile
 * @Orientation allow slots to display top,bottom OR left,right
 * 	vertical:	Display slotted items in a vertical layout
 * 	horizontal:	Display slotted items in a horizontal layout
 * 
 * @properties (JS Only)
 * 	get svgControl								| Get a reference to the <rps-svg> control
 * 	get labelControl							| Get a reference to the <rps-input-label> control
 * 	get cardControl							| Get a reference to the <rps-card> control
 * 
 * @methods
 * 	hide											| Hide the toast
 * 	show											| Display the toast
 * 
 * @events
 * 	close											| Fired when the toast is closed by the user
 * 	expire										| Fired when expiry time is reached and the toast automatically closes
 * 
 * @callbacks
 * 	cbClose 										| Callback on close event
 * 	cbExpire 									| Callback on expired event
 * 
 * @slots
 * 	header										| Optional slotted content in the "main" section of the toast
 * 	right											| Optional slotted content to the right. Close button is usually here
 * 
 * 
 * 
 * @example+ @lit
 * @basic
 * =====
 * import '@rps-wc/rps-toast';
 * 
 * 		<rps-toast text="Leverage agile frameworks to provide a robust synopsis for high level overviews" class="error" 
 * 			position="topRight" effect="slide" repeat expireseconds=8
 * 			@close=${(e) => console.log('Close event',e)}
 * 			@expire=${(e) => console.log('Expired event',e)}
 * 		>
 * 		</rps-toast>
 * 
 * 
 * 
 * @example+ @React
 * @basic
 * =====
 * import '@rps-wc/rps-toast';
 * 
 *  	useEffect(() => {
 *				toast.current.cbClose = (e) => console.log(e);
 *				toast.current.cbExpire = (e) => console.log(e);
 *  	}, [])
 * 
 * 		<rps-toast text="Leverage agile frameworks to provide a robust synopsis for high level overviews" class="error" 
 * 			position="topRight" effect="slide" repeat expireseconds=8
 * 		>
 * 		</rps-toast>
 * 
 * 
 * 
*/
import { Toast } from './src/statusDisplays/Toast';
if (!window.customElements.get('rps-toast'))
	window.customElements.define('rps-toast', Toast);

