/* eslint-disable react/prop-types */

import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * Create a wrapper around a Web Component, returning a React
 * friendly component that "just works" without having to worry
 * about manually handling event listeners.
 *
 * Note that to set the class attribute of a web component wrapped
 * in this way one must set `className` as React normally expects.
 *
 * @param {String} componentName - e.g.: rps-button
 * @returns {Wrapper<component>}
 */
export const createReactWrapper = (componentName) => {
	const Wrapper = ({ children, innerRef = undefined, ...props }) => {
		const ref = innerRef || useRef();

		useEffect(() => {
			if (ref.current) {
				Object.keys(props).forEach((key) => {
					// if (props[key] === undefined) return;

					if (key === "className") {
						ref.current.setAttribute("class", props[key]);
						ref.current["class"] = props[key];
						ref.current.requestUpdate();
						return;
					}

					const propType = typeof props[key];
					const keyLower = key.toLowerCase();

					if (propType !== "object" && propType !== "function") {
						if (typeof props[key] === "boolean") {
							ref.current.removeAttribute(keyLower);
							ref.current[key] = props[key];
							if (props[key] === true) {
								ref.current.setAttribute(keyLower, "true");
							}
							ref.current.requestUpdate();
							return;
						} else {
							ref.current.setAttribute(keyLower, props[key]?.toString());
						}
					}

					ref.current[key] = props[key];

					ref.current.requestUpdate();
				});
			}
		}, [props]);

		return <>{React.createElement(componentName, { ref: ref }, children)}</>;
	};

	Wrapper.propTypes = {
		children: PropTypes.any,
		innerRef: PropTypes.any,
	};

	return Wrapper;
};
