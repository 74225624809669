/**
 * @name rps-dropdown
 * @type {DropDown}
 * A Dropdown component.
 *
 *
 * @attributes + @properties 				|Changes trigger a re-render
 * Casing usages								| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String} 	name 			| form name
 * 	@property {String} 	label 		| label to display
 * 	@property {String}	defaultValue| default value
 * 	@property {Array}		items			| An array of objects to display, [{id,text}] OR a string array, eg: ["ant", "sam"]
 * 	@property {Boolean} 	required 	| is input required
 * 	@property {Boolean} 	readonly 	| is input readonly
 * 	@property {Boolean} 	disabled 	| is this control disabled?
 * 	@property {Boolean} 	caching 		| does this control use Caching when reading and writing values
 * 	@property {Callback} cbInput		| Callback function that can be used in place of an event listener
 * 	@property {String} 	customCss 	| Specify a custom .css file to include, use cssPath to override elements,
 * 													DO NOT override the styling definition by re-defining the css styling
 * 													Put this file into the *window.cssPath* folder @see {link README.md}
 * 													eg: /public/css (where the other css files are)
 * 													Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css			| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 *
 *
 * @methods
 * 	clear()									| Clear all the cached value used by this control (or any other control using the same name)
 * 	labelText(newLabelText)				| Change the label for this control
 *
 * @properties (JS Only)
 * ====================
 * 	value (get and set)					| allow getters and setters for the value of the select.
 * 													eg: oControl.value = "newValue";
 * 													oe: console.log(oControl.value);
 * 	get isChecked							| Is this checkbox checked?
 * 	inputControl (get only)				| Return a reference to the input control
 * 	labelControl (get only)				| Return a reference to the label control
 *
 *
 * @events
 * 	input										| fires whenever checkbox is clicked
 *
 * @callbacks
 * 	cbInput									| when the user input changes
 *
 * @slots
 * 	No slots
 *
 * @example @Lit
 * @basic
 * ==============================
 * 	import '@rps-wc/wc/rps-dropdown';
 *
 * 	const banks = ["Nedbank", "Standard Bank", "First National Bank"];
 *
 * 	<rps-dropdown label="Bank Name" name="bankName"
 * 		items=${JSON.stringify(banks)}
 * 		defaultvalue="Standard Bank"
 * 		@input=${(e) => console.log('dropdown', e.detail)}
 * 		required caching
 * 	></rps-dropdown>
 *
 *
 * @example @ReactJS
 * @basic
 * ==============================
 * 	import '@rps-wc/wc/rps-dropdown';
 *
 * 	const banks = ["Nedbank", "Standard Bank", "First National Bank"];
 *
 *  	useEffect(() => {
 *				input.current.cbInput = (e) => console.log(e);
 *  	}, [])
 *
 * return (
 * 	<rps-dropdown label="Bank Name" name="bankName"
 * 		ref={input}
 * 		items={JSON.stringify(banks)}
 * 		defaultvalue="Standard Bank"
 * 		required caching
 * 	></rps-dropdown>
 * )
 *
*/
import { DropDown } from './src/input/DropDown';
if (!window.customElements.get('rps-dropdown'))
	window.customElements.define('rps-dropdown', DropDown);

