import { createReactWrapper } from "../util/createReactWrapper.jsx";
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const InternalRPSInputModal = createReactWrapper('rps-input-modal');

export const RPSInputModal = ({ opened, innerRef, cbConfirm, cbCancel, ...rest }) => {
	const ref = innerRef || useRef();

	useEffect(() => {
		// Have to give ref time to bind after modal opens
		// or else ref.current will be undefined.
		setTimeout(() => {
			if (opened && ref.current?.inputControl) {
				ref.current.inputControl.inputControl.focus();
			}
		}, 100);
	}, [opened]);

	const handleConfirm = (ev) => {
		if (cbConfirm) {
			cbConfirm(ev);
		}
		if (ref.current) {
			ref.current.inputControl.value = '';
		}
	}

	const handleCancel = (ev) => {
		if (cbCancel) {
			cbCancel(ev);
		}
		if (ref.current) {
			ref.current.inputControl.value = '';
		}
	}

	return <InternalRPSInputModal innerRef={ref} cbConfirm={handleConfirm} cbCancel={handleCancel} {...rest} opened={opened} ></InternalRPSInputModal>
}

RPSInputModal.propTypes = {
	opened: PropTypes.bool,
	innerRef: PropTypes.any,
	cbConfirm: PropTypes.func,
	cbCancel: PropTypes.func
}

