import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { css, html } from 'lit';
import { ref, createRef } from 'lit/directives/ref.js';
import { isDate } from './internal/dateUtils';
import '../../rps-input.js';
import '../../rps-form.js';
import '../../rps-svg';
import '../../rps-button-icon';
import { iconNames } from '../../svg-icons';

/**
 * DateSelectRange - rps-date-select-range
 * _______________________________________
 * @class {CustomLitElement} DateSelectRange
 * @name rps-date-select-range
 *	@description
 *		Composite component that allows for selecting two dates in a range,
 *		preventing overlap.
 *
 * 	Supports caching.
 *
 * @fires InputEvent
 *
 * @property {String} fromLabel 		- Label used for left side of input (Default: "Start Date")
 * @property {String} toLabel 		- Label used for right side of input (Default: "End Date")
 * @property {String} name 			- Name used to build up keys for caching
 * @property {Boolean} caching 		- If true, enables auto cache for both date inputs
 * @property {Boolean} readonly 		- If true, sets both input fields to read only
 * @property {Boolean} minDate		- Minimum date. NB: MUST be in the format 'yyyy-MM-dd' (web standard)
 * @property {Boolean} maxDate		- Maximum date. NB: MUST be in the format 'yyyy-MM-dd' (web standard)
 * @property {Object} cbInput			- callback function that gets fired when an input event occurs
 * @property {String} required 		- required attribute
 * @property {String} errorMessage 	- error message
 * @property {String} defaultValue 	- default value for the control

*/
export class DateSelectRange extends CustomLitElement {
	static get styles() {
		return css`
		.container {
			display: flex;
			flex-flow: row wrap;
			align-items: flex-end;
			width: 100%;
			gap: 0.8rem 2.4rem;
		}
		rps-input {
			flex: 1;
		}
		.small.container  {
			gap: 0.8rem;
		}
	`;
	}

	static get properties() {
		return {
			fromLabel: { type: String },
			toLabel: { type: String },
			name: { type: String },
			caching: { type: Boolean },
			readonly: { type: Boolean },
			cbInput: { attribute: false },
			required: { type: Boolean },
			errorMessage: { type: String },
			minDate: { type: String },						// NB: MUST be in the format 'yyyy-MM-dd' (web standard)
			maxDate: { type: String },						// NB: MUST be in the format 'yyyy-MM-dd' (web standard)
			defaultValue: { type: String },				// default value for the control
		}
	}

	constructor() {
		super();
		this.fromLabel = "From Date";
		this.toLabel = "To Date";
		this.cbInput = null;
		this.name = null;
		this.caching = false;
		this.readonly = false;
		this.required = false;
		this.errorMessage = "Enter a valid date";
		this._fromDateRef = createRef();
		this._toDateRef = createRef();
	}

	/**
	 * Get the internal `rps-input` component used to select the start date.
	 *
	 * @return {Input} - Input element
	 * @memberof DateSelectRange
	 */
	get fromDateControl() {
		return this._fromDateRef.value;
	}

	/**
	 * Get the internal `rps-input` component used to select the end date.
	 *
	 * @return {Input} - Input element
	 * @memberof DateSelectRange
	 */
	get toDateControl() {
		return this._toDateRef.value;
	}


	/**
	 * Set the min and  max values for the date ranges
	 * after the user "clears" the selected date ([x] clicked in control)
	 *
	 * @private
	 * @memberof DateSelectRange
	 */
	_setMinMax() {
		this.fromDateControl.minDate = this.minDate;
		this.fromDateControl.maxDate = this.maxDate;

		this.toDateControl.minDate = this.minDate;
		this.toDateControl.maxDate = this.maxDate;
	}

	/**
	 * Event handler that fires when the start date is changed.
	 *
	 * @param {Event} e - the input event from the start date input field
	 * @memberof DateSelectRange
	 * @private
	 */
	handleFromDate(e) {
		e.stopPropagation();

		// User clicked on the [x] of the date control, thus undoing the date selection.
		// Reset the max and min dates
		if (this.fromDateControl.value === '') {
			console.debug(`DateSelectRange:handleFromDate user resetting the date. set max and min to defaults`, this.minDate, this.maxDate);
			this._setMinMax();
			this.fromDateControl.value = this.defaultValue;
			return;
		}

		const newMin = this.fromDateControl.value;
		console.debug(`DateSelectRange:handleFromDate setting the new min value for maxDate Control = ${newMin}`);
		this.toDateControl.minDate = newMin;

		this.requestUpdate();
		this.handleDispatch();
	}

	/**
	 * Event handler that fires when the end date is changed.
	 *
	 * @param {Event} e - the input event from the end date input field
	 * @memberof DateSelectRange
	 * @private
	 */
	handleToDate(e) {
		e.stopPropagation(); // Stop input events from rps-input from filtering up to the outside.

		// User clicked on the [x] of the date control, thus undoing the date selection.
		// Reset the max and min dates
		if (this.toDateControl.value === '') {
			console.debug(`DateSelectRange:handleToDate user resetting the date. set max and min to defaults`, this.minDate, this.maxDate);
			this._setMinMax();
			this.toDateControl.value = "";
			return;
		}


		const newMax = this.toDateControl.value;
		console.debug(`DateSelectRange:handleToDate setting the new max value for minDate Control = ${newMax}`);
		this.fromDateControl.maxDate = newMax;

		this.handleDispatch();
	}

	/**
	 * Check the validity of the child components, and this component
	 * @returns {Boolean} - is component in valid state?
	 */
	validate() {
		const from = this.fromDateControl;
		const to = this.toDateControl;

		// if required and the input value is empty
		if (from.inputControl.required && from.inputControl.value === '') return false;
		if (to.inputControl.required && from.inputControl.value === '') return false;

		const validControls = from.inputControl.checkValidity() && to.inputControl.checkValidity();
		const rangeValid = from.value <= to.value;

		return validControls && rangeValid;
	}

	/**
	 * Function called to dispatch an input event when both fields contain valid
	 * data.
	 *
	 * @memberof DateSelectRange
	 * @private
	 */
	handleDispatch() {
		if (this.fromDateControl.value && this.toDateControl.value) {
			const startDate = new Date(this.fromDateControl.value);
			const endDate = new Date(this.toDateControl.value);

			if (endDate < startDate) {
				console.debug(`DateSelectRange:handleDispatch endDate < startDate, exit method`, endDate, startDate);
				return;
			}

			if (!this.validate()) {
				console.debug(`DateSelectRange:handleDispatch control is not valid, exit method`);
				return;
			}

			const eventDetail = {
				startDate,
				endDate,
				source: this.tagName,
				id: this.id,
				fromDateControl: this.fromDateControl,
				toDateControl: this.toDateControl
			};

			const ev = new CustomEvent('input', { detail: eventDetail });

			this.dispatchEvent(ev);

			if (this.cbInput) {
				this.cbInput(ev);
			}
		}
	}

	/**
	 * Reset the values and defaults
	 * @private
	 * @param {Event} ev
	 * @memberof DateSelectRange
	 */
	_reset(ev) {
		console.debug(`DateSelectRange:_reset values and defaults`);

		this._setMinMax();
		this.fromDateControl.value = this.defaultValue;
		this.toDateControl.value = this.defaultValue;
	}

	/**
	 * If using caching, then remove the cached value from the sessionStorage
	 *
	 * @memberof DateSelectRange
	 */
	clear() {
		this.fromDateControl.clear();
		this.toDateControl.clear();
	}


	render() {
		return html`
			<div class="container ${this.className}">
				<rps-input name="${this.name}FromDate" id="from-date" label=${this.fromLabel}
					minDate=${this.minDate} maxDate=${this.maxDate} defaultvalue=${this.defaultValue}
					errormessage=${this.errorMessage}
					type="date"
					?caching=${this.caching} ?required=${this.required}
					?readonly=${this.readonly} ?disabled=${this.disabled}
					@input=${this.handleFromDate}
					${ref(this._fromDateRef)}
					class="${this.className}"
				>
				</rps-input>
				<rps-input name="${this.name}ToDate" id="to-date" label=${this.toLabel}
					minDate=${this.minDate} maxDate=${this.maxDate} defaultvalue=${this.defaultValue}
					errormessage=${this.errorMessage}
					type="date"
					?caching=${this.caching} ?required=${this.required}
					?readonly=${this.readonly} ?disabled=${this.disabled}
					@input=${this.handleToDate}
					${ref(this._toDateRef)}
					class="${this.className}"
				>
				</rps-input>
				<rps-button-icon class="secondary ${this.className}" svg="${iconNames.del}" @click=${this._reset}></rps-button-icon>
			</div>
			`
	}
}
