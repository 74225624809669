/**
 * @name rps-tree
 * @type {Tree}
 * A generic tree-view control
 *
 * @notes
 * 	Can render a simple treeview, menu or complex objects ion s tree-view structure
 *
 *
 * @attributes + @properties 				| Changes trigger a re-render
 * Casing usages:								| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String} customCss 		| Specify a custom .css file to include, use cssPath to override elements,
 * 													DO NOT override the styling definition by re-defining the css styling
 * 													Put this file into the *window.cssPath* folder @see {link README.md}
 * 													eg: /public/css (where the other css files are)
 * 													Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}css				| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 	@property {Array}	data				| An array of items to display in the tree
 * 													example item in array: (ONLY id and text) are required
 * 														{
 * 															id: 'a',
 * 															text: 'Dans Family',
 * 															leftSvg: iconNames.calendar,	// optional: Icon to display to the left
 * 															customRender: (item) => html`	// optional: Override the default rendering
 * 																<h5>${item.text}</h6>
 * 																<rps-grid .columns=${columns} .data=${item.data} pageSize="4" />
 * 															`,
 * 															data: data1,						// optional properties
 * 															middle: {
 * 																customRender: (item) => html` // optional: Override render of text with custom renderer
 * 																	<rps-svg svg="checkBox"></rps-svg> ITEM: ${item.text}
 * 																`
 * 															}
 * 															right: {								// optional: icon or custom html to display in the right of the tree-view item
 * 																svg: iconNames.error,
 * 																tooltip: "my tooltip!!",
 *		 															customRender: (item) => html`	// optional: Override the default rendering
 *		 																<rps-chip text="${item.text}" />
 *		 															`,
 * 															},
 * 															children: []						// Array of objects, same as parent
 * 														}
 * 													@see {link /demo/easy/treeview.html}
 * 													@see {link /demo/complex/treeview}
 * 													for examples
 *
 * @classes
 * @styling + @layout
 * 	default									| The default tree-view display (auto added if not specified)
 * 	aside										| used in the layout (menu)
 * 	simple									| display as a "traditional" simple windows tree-view (condensed display)
 *
 *
 * @methods
 * 	collapseUnder(id)						| Collapse the tree and its children
 * 													Toggles the "open" class on all <li> elements fot this "id" and all children
 *
 * @events
 * 	click										| Fired when the item or its left icon are clicked on
 * 	clickOptions							| Fired when the item or its left icon are clicked on
 *
 * @callbacks
 * 	cbClick									| Called when the item or its left icon are clicked on
 * 	cbClickOptions							| Called when the item or its left icon are clicked on
 *
 * @slots
 * 	@none
 *
 * @examples + @Lit
 * @basic
 * import '@rps-wc/rps-tree';
 *
 * 		<rps-tree @click=${okHandler} @clickOptions=${optionsHandler}>
 * 		</rps-tree>
 *
 * @advanced @see {link /demo/complex/treeview}
 * 	There are many advanced examples in this folder
 *
 *
 *
 * @examples + @React
 * @basic
 * import '@rps-wc/rps-tree';
 *
 * 		// using React wrappers
 * 		<RPSTree cbClick={okHandler} cbClickOptions={optionsHandler}>
 * 		</RPSTree>
*/
import { Tree } from './src/treeView/Tree.js';
if (!window.customElements.get('rps-tree'))
	window.customElements.define('rps-tree', Tree);

