import { createReactWrapper } from "../util/createReactWrapper.jsx";
import React, { useRef, useEffect } from 'react';

const InternalRPSSearch = createReactWrapper('rps-search');

export const RPSSearch = ({ items, innerRef, ...rest }) => {
	const ref = innerRef || useRef();

	useEffect(() => {
		if (Array.isArray(items))
			ref.current.setItems(items);
	}, [items]);

	return <InternalRPSSearch innerRef={ref} {...rest} ></InternalRPSSearch>
}
