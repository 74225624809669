import type { SpaceProps } from '@odo/lib/styled';
import styled, { compose, space } from '@odo/lib/styled';
import { cssColor } from '@odo/utils/css-color';

type FieldsetProps = SpaceProps;

const Fieldset = styled.fieldset<FieldsetProps>`
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  color: ${cssColor('text')};

  & legend {
    padding: 0 8px;
    user-select: none;
    font-weight: 700;
  }

  ${compose(space)}
`;

Fieldset.defaultProps = {
  p: '16px 16px 20px',
};

export default Fieldset;
