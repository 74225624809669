import type { ApiCustomOption } from '@odo/types/api';
import {
  ViewModeEnum,
  PriceTypeEnum,
  DependencyEnum,
  InputTypeEnum,
} from '@odo/types/api';
import {
  ActionTypeEnum,
  isActionAddOption,
  isActionAddValue,
  isActionUpdateOption,
  isActionUpdateValue,
  isActionSwapOptions,
  isActionSwapValues,
  isActionRemoveOption,
  isActionRemoveValue,
  isActionPasteOptions,
} from '@odo/contexts/custom-options-editor/types';
import type {
  Action,
  SaveCustomOptionsMutations,
  SaveUtilities,
  CustomOptionsInputWithTmpId,
  CustomOptionValuesInputWithTmpId,
  CreateCustomOptionsMutation,
  UpdateOptionInputWithTmpId,
  UpdateCustomOptionValuesInputWithTmpId,
  UpdateCustomOptionMutation,
  RemoveCustomOptionMutation,
} from '@odo/contexts/custom-options-editor/types';
import {
  getMaxSortOrder,
  findOrMakeValueMutation,
  findMutationByOption,
  findMutationByValue,
  findCustomOptionInMutation,
  findCustomOptionAndValueInMutation,
  findCustomOptionAndValueInRemoteOptions,
  makeUpdateMutation,
  removeCustomOptionMutation,
  findOrMakeOptionMutation,
  findCustomOptionAndValueInOptionInputs,
} from '@odo/data/custom-options/utils';
import uuid, { isNewId } from '@odo/utils/uuid';

const removeChildGroupIdsFromValue = ({
  value,
  groupIds,
}: {
  value:
    | CustomOptionValuesInputWithTmpId
    | UpdateCustomOptionValuesInputWithTmpId;
  groupIds: string[];
}) => {
  // set children group ids
  if (value.childrenGroupIds) {
    value.childrenGroupIds = value.childrenGroupIds
      .split(',')
      .filter(id => !groupIds.includes(id))
      .join(',');
  }
};

export const actionReducerPrepMutations: Record<
  ActionTypeEnum,
  (args: {
    action: Action;
    customOptions: ApiCustomOption[];
    mutations: SaveCustomOptionsMutations;
    utilities: SaveUtilities;
  }) => void
> = {
  /**
   * Paste Options.
   */
  [ActionTypeEnum.PasteOptions]: ({
    customOptions,
    action,
    mutations,
    utilities,
  }) => {
    if (isActionPasteOptions(action)) {
      const newCustomOptions: CustomOptionsInputWithTmpId[] = [];

      action.options.forEach(pastedOption => {
        const newGroupIds: number[] = [];

        const newCustomOption: CustomOptionsInputWithTmpId = {
          tmpOptionId: pastedOption.tmpOptionId,
          title: pastedOption.title,
          type: InputTypeEnum.dropdown,
          viewMode: ViewModeEnum.visible,
          dependency:
            typeof pastedOption.parentValueId !== 'undefined'
              ? DependencyEnum.or
              : DependencyEnum.no,
          sortOrder: utilities.getNewSortOrderForOption(),
          values: pastedOption.values.map(value => {
            const newGroupId = utilities.getNewGroupId();
            newGroupIds.push(newGroupId);
            return {
              tmpValueId: value.tmpValueId,
              title: value.title,
              sku: value.sku,
              priceType: PriceTypeEnum.fixed,
              price: value.price,
              cost: value.cost,
              quantity: value.quantity,
              sortOrder: value.sortOrder,
              groupId: newGroupId,
              childrenGroupIds: '',
            };
          }),
        };

        // look for parent in newCustomOptions, customOptions, and mutations; then add the newGroupIds
        if (typeof pastedOption.parentValueId !== 'undefined') {
          // see if it's parent is one of the new options (in which case we won't need another mutation)
          const { customOption: parentPastedOption, value: parentPastedValue } =
            findCustomOptionAndValueInOptionInputs({
              valueId: pastedOption.parentValueId,
              customOptions: newCustomOptions,
            });

          if (parentPastedOption && parentPastedValue) {
            newCustomOptions.push(newCustomOption);

            // update parent values childrenGroupIds
            parentPastedValue.childrenGroupIds =
              parentPastedValue.childrenGroupIds
                ? parentPastedValue.childrenGroupIds.concat(
                    `,${newGroupIds.join(',')}`
                  )
                : newGroupIds.join(',');
          } else {
            // the normal find or make mutation flow
            const {
              value: parentValue,
              mutation: parentMutation,
              mutationCallback: parentMutationCallback,
            } = findOrMakeValueMutation({
              valueId: pastedOption.parentValueId,
              customOptions,
              action,
              mutations,
            });

            if (parentMutation && parentValue) {
              newCustomOptions.push(newCustomOption);

              // update parent values childrenGroupIds
              if (parentValue) {
                parentValue.childrenGroupIds = parentValue.childrenGroupIds
                  ? parentValue.childrenGroupIds.concat(
                      `,${newGroupIds.join(',')}`
                    )
                  : newGroupIds.join(',');
              }

              // run parent mutation callback (which will either update meta or add to mutation list)
              parentMutationCallback();
            } else {
              // dead-end
              console.warn(
                'Cannot find or create the parent for a pasted custom option'
              );
            }
          }
        } else {
          newCustomOptions.push(newCustomOption);
        }
      });

      const [firstNewCustomOption, ...restNewCustomOptions] = newCustomOptions;
      if (firstNewCustomOption) {
        // we can use a single mutation for ALL of these pasted options in one go
        const createMutation: CreateCustomOptionsMutation = {
          meta: {
            mutationId: uuid(),
            actions: [action],
            customOptionIds: newCustomOptions.map(
              ({ tmpOptionId }) => tmpOptionId
            ),
            valueIds: newCustomOptions.reduce<string[]>((ids, option) => {
              option.values.forEach(({ tmpValueId }) => ids.push(tmpValueId));
              return ids;
            }, []),
            completed: false,
            ready: true,
          },
          args: {
            productId: utilities.getProductId(),
            customOptions: [firstNewCustomOption, ...restNewCustomOptions],
          },
        };

        // add new mutation to list
        mutations.createCustomOptions.push(createMutation);
      }
    }
  },

  /**
   * Add Option.
   *
   * Scenarios:
   * - new root option
   * - new child option on an existing value in an existing option
   * - new child option on a new value in an existing option
   * - new child option on a new value in a new option
   */
  [ActionTypeEnum.AddOption]: ({
    customOptions,
    action,
    mutations,
    utilities,
  }) => {
    if (isActionAddOption(action)) {
      const newGroupId = utilities.getNewGroupId();

      // when adding a new option, there's no way other than to run create options
      const newCustomOption: CustomOptionsInputWithTmpId = {
        tmpOptionId: action.tmpOptionId,
        title: '',
        type: InputTypeEnum.dropdown,
        viewMode: ViewModeEnum.visible,
        dependency:
          typeof action.parentValueId !== 'undefined'
            ? DependencyEnum.or
            : DependencyEnum.no,
        sortOrder: utilities.getNewSortOrderForOption(),
        values: [
          {
            tmpValueId: action.tmpValueId,
            title: '',
            sku: '',
            priceType: PriceTypeEnum.fixed,
            price: 0,
            cost: 0,
            quantity: 0,
            sortOrder: 1,
            groupId: newGroupId,
            childrenGroupIds: '',
          },
        ],
      };

      const createMutation: CreateCustomOptionsMutation = {
        meta: {
          mutationId: uuid(),
          actions: [action],
          customOptionIds: [action.tmpOptionId],
          valueIds: [action.tmpValueId],
          completed: false,
          // NOTE: new options mutations are invalid when the titles/skus are empty
          // we will need to flag this mutation as ready once they've been added
          ready: false,
        },
        args: {
          productId: utilities.getProductId(),
          customOptions: [newCustomOption],
        },
      };

      // look for, or make, parent mutation (only if there's a parent value)
      const {
        value: parentValue,
        mutation: parentMutation,
        mutationCallback: parentMutationCallback,
      } = findOrMakeValueMutation({
        valueId: action.parentValueId,
        customOptions,
        action,
        mutations,
      });

      // check if we have everything we need
      if (
        typeof action.parentValueId === 'undefined' ||
        (parentMutation && parentValue)
      ) {
        // add new mutation to list
        mutations.createCustomOptions.push(createMutation);

        // add parent mutation id
        if (parentMutation) {
          createMutation.meta.dependentId = parentMutation.meta.mutationId;
        }

        // update parent values childrenGroupIds
        if (parentValue) {
          parentValue.childrenGroupIds = parentValue.childrenGroupIds
            ? parentValue.childrenGroupIds.concat(`,${newGroupId.toString()}`)
            : newGroupId.toString();
        }

        // run parent mutation callback (which will either update meta or add to mutation list)
        parentMutationCallback();
      } else {
        // dead-end
        console.warn('We needed the parent set up, but failed to do so');
      }
    }
  },

  /**
   * Add Value to Option
   *
   * Scenarios:
   * - new value in an existing root option
   * - new value in a new root option
   * - new value in an existing child option
   * - new value in a new child option of an existing value
   * - new value in a new child option of a new value in an existing option
   * - new value in a new child option of a new value in a new option
   */
  [ActionTypeEnum.AddValue]: ({
    customOptions,
    action,
    mutations,
    utilities,
  }) => {
    if (isActionAddValue(action)) {
      const newGroupId = utilities.getNewGroupId();

      const newValue = {
        tmpValueId: action.tmpValueId,
        title: '',
        sku: '',
        priceType: PriceTypeEnum.fixed,
        price: 0,
        cost: 0,
        quantity: 0,
        sortOrder: 0,
        groupId: newGroupId,
        childrenGroupIds: '',
      };

      // look for, or make, parent mutation (only if there's a parent value)
      const {
        value: parentValue,
        mutation: parentMutation,
        mutationCallback: parentMutationCallback,
      } = findOrMakeValueMutation({
        valueId: action.parentValueId,
        customOptions,
        action,
        mutations,
      });

      // see if we're good to go ahead with creating the children
      if (
        typeof action.parentValueId === 'undefined' ||
        (parentMutation && parentValue)
      ) {
        let newChildMutation: UpdateCustomOptionMutation | undefined;

        const childMutation = findMutationByOption({
          optionId: action.optionId,
          mutations,
        });
        if (childMutation) {
          const customOption = findCustomOptionInMutation({
            optionId: action.optionId,
            mutation: childMutation,
          });
          if (customOption) {
            customOption.values = customOption.values || [];
            customOption.values.push({
              ...newValue,
              sortOrder: getMaxSortOrder(customOption.values) + 1,
            });

            // update mutation meta
            childMutation.meta.actions.push(action);
            childMutation.meta.valueIds.push(action.tmpValueId);

            // add parent mutation dependentId
            if (parentMutation) {
              childMutation.meta.dependentId = parentMutation.meta.mutationId;
            }
          } else {
            // dead-end
            console.warn(
              'Found the mutation, but not the option in it somehow'
            );
          }
        } else {
          // couldn't find the mutation, so look for the remote custom option and make an update mutation for it
          const customOption = customOptions.find(
            ({ id }) => id === action.optionId
          );
          if (customOption) {
            // make a new update mutation
            newChildMutation = makeUpdateMutation({
              customOption,
              newValueId: action.tmpValueId,
              newValue,
              action,
            });

            // update mutation meta and add them to the list
            mutations.updateCustomOption.push(newChildMutation);

            // add parent mutation dependentId
            if (parentMutation) {
              newChildMutation.meta.dependentId =
                parentMutation.meta.mutationId;
            }
          } else {
            // dead-end
            console.warn('Could not find a new or existing custom option');
          }
        }

        if (childMutation || newChildMutation) {
          // update parent values childrenGroupIds
          if (parentValue) {
            parentValue.childrenGroupIds = parentValue.childrenGroupIds
              ? parentValue.childrenGroupIds.concat(`,${newGroupId.toString()}`)
              : newGroupId.toString();
          }

          // update mutation meta and add them to the list
          parentMutationCallback();
        }
      } else {
        // dead-end
        console.warn('We needed the parent set up, but failed to do so');
      }
    }
  },

  /**
   * Update Option
   *
   * Scenarios:
   * - updating existing option
   * - updating new option
   */
  [ActionTypeEnum.UpdateOption]: ({ customOptions, action, mutations }) => {
    if (isActionUpdateOption(action)) {
      let newMutation: UpdateCustomOptionMutation | undefined;
      let option:
        | CustomOptionsInputWithTmpId
        | UpdateOptionInputWithTmpId
        | undefined;

      const existingMutation = findMutationByOption({
        optionId: action.id,
        mutations,
      });
      if (existingMutation) {
        // find the option
        option = findCustomOptionInMutation({
          optionId: action.id,
          mutation: existingMutation,
        });
        if (option) {
          // update mutation meta
          existingMutation.meta.actions.push(action);
        }
      } else if (!isNewId(action.id)) {
        const customOption = customOptions.find(({ id }) => id === action.id);
        if (customOption) {
          // new update mutation
          newMutation = makeUpdateMutation({
            customOption,
            action,
          });

          option = newMutation.args.customOption;

          // add to mutation list
          mutations.updateCustomOption.push(newMutation);
        } else {
          // dead-end
          console.warn('Could not find a new or existing custom option');
        }
      } else {
        // dead-end
        console.warn(
          "Option is new, but we couldn't find a mutation for it somehow, so fail"
        );
      }

      // update option fields
      if (option) {
        for (const field in action.fields) {
          option[field] = action.fields[field];
        }

        // flag the mutation as ready
        const mutation = existingMutation || newMutation;
        if (mutation && !mutation.meta.ready && option.title) {
          mutation.meta.ready = true;
        }
      }
    }
  },

  /**
   * Update Value
   *
   * Scenarios:
   * - update existing value
   * - update new value on existing option
   * - update new value on new option
   */
  [ActionTypeEnum.UpdateValue]: ({ customOptions, action, mutations }) => {
    if (isActionUpdateValue(action)) {
      let newMutation: UpdateCustomOptionMutation | undefined;
      let value:
        | CustomOptionValuesInputWithTmpId
        | UpdateCustomOptionValuesInputWithTmpId
        | undefined;

      const existingMutation = findMutationByValue({
        valueId: action.valueId,
        mutations,
      });
      if (existingMutation) {
        const { customOption, value: foundValue } =
          findCustomOptionAndValueInMutation({
            valueId: action.valueId,
            mutation: existingMutation,
          });
        if (customOption && foundValue) {
          value = foundValue;

          existingMutation.meta.actions.push(action);
        }
      } else if (!isNewId(action.valueId)) {
        const { customOption, value: foundValue } =
          findCustomOptionAndValueInRemoteOptions({
            valueId: action.valueId,
            customOptions,
          });
        if (customOption && foundValue) {
          // new update mutation
          newMutation = makeUpdateMutation({
            customOption,
            action,
          });

          value = newMutation.args.customOption.values.find(
            ({ valueId }) => valueId === action.valueId
          );

          // add to mutation list
          if (value) {
            mutations.updateCustomOption.push(newMutation);
          }
        } else {
          // dead-end
          console.warn('Could not find value in our options');
        }
      } else {
        // dead-end
        console.warn(
          "Value is new, but we couldn't find a mutation for it somehow, so fail"
        );
      }

      if (value) {
        for (const field in action.fields) {
          value[field] = action.fields[field];
        }

        // flag the mutation as ready
        const mutation = existingMutation || newMutation;
        if (mutation && !mutation.meta.ready && value.title && value.sku) {
          mutation.meta.ready = true;
        }
      }
    }
  },

  /**
   * Swap Options
   */
  [ActionTypeEnum.SwapOptions]: ({ customOptions, action, mutations }) => {
    if (isActionSwapOptions(action)) {
      const {
        option: optionA,
        mutation: mutationA,
        mutationCallback: mutationACallback,
      } = findOrMakeOptionMutation({
        customOptions,
        mutations,
        optionId: action.optionAId,
      });

      const {
        option: optionB,
        mutation: mutationB,
        mutationCallback: mutationBCallback,
      } = findOrMakeOptionMutation({
        customOptions,
        mutations,
        optionId: action.optionBId,
      });

      if (
        optionA &&
        optionB &&
        mutationA &&
        mutationB &&
        typeof optionA.sortOrder !== 'undefined' &&
        typeof optionB.sortOrder !== 'undefined'
      ) {
        const sortOrderSwapping = optionA.sortOrder;
        optionA.sortOrder = optionB.sortOrder;
        optionB.sortOrder = sortOrderSwapping;

        mutationACallback();
        mutationBCallback();
      }
    }
  },

  /**
   * Swap Values
   */
  [ActionTypeEnum.SwapValues]: ({ customOptions, action, mutations }) => {
    if (isActionSwapValues(action)) {
      const { mutation, mutationCallback } = findOrMakeValueMutation({
        customOptions,
        mutations,
        action,
        valueId: action.valueAId,
      });

      // we know that both values must be in the same mutation coz they're in the same option
      if (mutation) {
        const { value: valueA } = findCustomOptionAndValueInMutation({
          valueId: action.valueAId,
          mutation,
        });
        const { value: valueB } = findCustomOptionAndValueInMutation({
          valueId: action.valueBId,
          mutation,
        });

        if (
          valueA &&
          valueB &&
          typeof valueA.sortOrder !== 'undefined' &&
          typeof valueB.sortOrder !== 'undefined'
        ) {
          const sortOrderSwapping = valueA.sortOrder;
          valueA.sortOrder = valueB.sortOrder;
          valueB.sortOrder = sortOrderSwapping;

          mutationCallback();
        }
      }
    }
  },

  /**
   * Remove Option
   *
   * Scenarios:
   * - remove existing option
   * - remove new option
   */
  [ActionTypeEnum.RemoveOption]: ({ customOptions, action, mutations }) => {
    if (isActionRemoveOption(action)) {
      // remove group ID from parent value
      if (typeof action.parentValueId !== 'undefined') {
        // build up the list of value groupIds
        const groupIds: string[] = [];
        if (isNewId(action.id)) {
          const optionMutation = mutations.createCustomOptions.find(
            ({ meta }) => meta.customOptionIds.includes(action.id)
          );
          if (optionMutation) {
            const option = optionMutation.args.customOptions.find(
              ({ tmpOptionId }) => tmpOptionId === action.id
            );
            if (option) {
              (option.values || []).forEach(({ groupId }) => {
                if (typeof groupId !== 'undefined') {
                  groupIds.push(groupId.toString());
                }
              });
            }
          }
        } else {
          const option = customOptions.find(({ id }) => id === action.id);
          if (option) {
            (option.values || []).forEach(({ groupId }) => {
              if (typeof groupId !== 'undefined') {
                groupIds.push(groupId.toString());
              }
            });
          }
        }

        // remove group ID from parent value
        if (groupIds.length > 0) {
          const { value, mutation, mutationCallback } = findOrMakeValueMutation(
            {
              valueId: action.parentValueId,
              customOptions,
              action,
              mutations,
            }
          );

          if (value && mutation) {
            removeChildGroupIdsFromValue({ value, groupIds });
            mutationCallback();
          }
        }
      }

      // remove any mutations we've made for this option, as they're all redundant now
      removeCustomOptionMutation({ optionId: action.id, mutations });

      // if the option exists on magento we must run a mutation to remove it
      if (!isNewId(action.id)) {
        const removeMutation: RemoveCustomOptionMutation = {
          meta: {
            mutationId: uuid(),
            actions: [action],
            customOptionIds: [action.id],
            valueIds: [],
            completed: false,
            ready: true,
          },
          args: {
            optionId: action.id,
          },
        };
        mutations.removeCustomOption.push(removeMutation);
      }
    }
  },

  /**
   * Remove Value
   *
   * Scenarios:
   * - remove existing value
   * - remove new value from existing option
   * - remove new value from new option
   */
  [ActionTypeEnum.RemoveValue]: ({ customOptions, action, mutations }) => {
    if (isActionRemoveValue(action)) {
      const { value, mutation, mutationCallback } = findOrMakeValueMutation({
        valueId: action.valueId,
        customOptions,
        action,
        mutations,
      });
      if (value && mutation) {
        const { customOption } = findCustomOptionAndValueInMutation({
          valueId: action.valueId,
          mutation,
        });
        if (customOption) {
          customOption.values = customOption.values.filter(
            ({ valueId, tmpValueId }) =>
              valueId !== action.valueId && tmpValueId !== action.valueId
          );

          mutationCallback();

          // remove group ID from parent value
          if (
            typeof action.parentValueId !== 'undefined' &&
            typeof value.groupId !== 'undefined'
          ) {
            const {
              value: parentValue,
              mutation: parentMutation,
              mutationCallback: parentMutationCallback,
            } = findOrMakeValueMutation({
              valueId: action.parentValueId,
              customOptions,
              action,
              mutations,
            });

            if (parentValue && parentMutation) {
              removeChildGroupIdsFromValue({
                value: parentValue,
                groupIds: [value.groupId.toString()],
              });

              parentMutationCallback();
            }
          }
        }
      }
    }
  },
};
