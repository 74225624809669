/**
 * @name rps-progress-bar
 * @type {ProgressBar}
 * 	A generic progress-bar control
 *
 * @attributes + @properties 				| Changes trigger a re-render
 * Casing usages:								| Attribute in html (lower case), Property in JS (Camel Case)
 *
 * 	@property {Number} [min=0] 		| Minimum value represented by the progress bar
 * 	@property {Number} [max=1]			| Maximum value represented by the progress bar
 * 	@property {Number} value			| Value to render
 * 	@property {Boolean} indeterminate | If present, renders the progress bar in an "indetermined" state (i.e.: For tasks of unknown length)
 * 	@property {String} customCss 		| Specify a custom .css file to include, use cssPath to override elements,
 * 													DO NOT override the styling definition by re-defining the css styling
 * 													Put this file into the *window.cssPath* folder @see {link README.md}
 * 													eg: /public/css (where the other css files are)
 * 													Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String} css				| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 *
 * @styling (classNames)
 * 	@example 								| eg: <rps-progress-bar class="large error">My Progress Bar</rps-progress-bar>
 * 	info										| Change the color of the progress bar to info state color
 * 	success										| Change the color of the progress bar to success state color
 * 	warning										| Change the color of the progress bar to warning state color
 * 	error										| Change the color of the progress bar to error state color
 * 	help										| Change the color of the progress bar to help state color
 * 	small										| Change the height to small of the Progress Bar
 * 	medium										| Change the height to Medium which is the default of the Progress Bar
 * 	large										| Change the height to Large of the Progress Bar
 *
 * @methods
 * 	None
 *
 * @events
 * 	See @link {./rps-toolbar.js} for more details
 *
 * @slots
 * 	default									| Progress to display. (the text or HTML that is used for the "progress")
 *
 *
 * @examples + @generic + @Lit
 * @basic
 * import '@rps-wc/rps-progress-bar';
 *
 * <rps-progress-bar class="small help">My Progress Bar</rps-progress-bar>
 *
 *
*/
import { ProgressBar } from './src/general/ProgressBar.js';
if (!window.customElements.get('rps-progress-bar'))
	window.customElements.define('rps-progress-bar', ProgressBar);

