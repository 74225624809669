import { html } from 'lit';
import { ref } from 'lit/directives/ref.js';
import { Modal } from './Modal';
import '../../rps-button.js';
import '../../rps-svg.js';
import { iconNames } from '../../svg-icons.js';

export class OkModal extends Modal {

	constructor() {
		super();
		this.confirmText = "Ok";
	}

	/**
	 * The <rps-standard-buttons> used
	 *
	 * @readonly
	 * @memberof Modal
	 */
	get standardButtonsControl() {
		console.error('<rps-ok-modal> does not have a <rps-standard-buttons> control. Do not call "okModal.standardButtonsControl"');
		return undefined;
	}

	/**
	 * User pressed a Key
	 *
	 * @param {event} event
	 * @memberof Modal
	 */
	 keyPressed(event) {
		if (this.allowNoConfirm) {
			console.debug('OkModal:keyPressed', event.key);
			if (event.key === 'Enter') this._cancel(event);
			if (event.key === 'Escape') this._cancel(event);
		}
	}


	render() {
		const op = this.getAttribute('opened');
		const display = !!(op !== undefined & op !== null);

		setTimeout(() => {
			// Set Focus to the modal so they keyboard event can be intercepted to confirm or close it
			if (this.allowNoConfirm) {
				this.renderRoot.querySelector('.popup-container').focus();
			}
		}, 100);

		return html`
			${this.addCss(true)}

			<div class="popup-container ${display ? 'show' : ''}" ?popup=${this.popup} aria-hidden="${display}" @click="${this.containerClick}">
				<div class="modal ${this.className} ${display ? 'show' : ''}"
					${ref(this.modalContainer)} 
					id="modal-container" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" 
					aria-hidden="${display}">
					<div class="header">
						<div class="state-icon">${this._getStatusIcon()}</div>
						<slot name="header">
							<h6>${this.headerText}</h6>
						</slot>
						<rps-svg aria-label="Close" @click=${this._cancel} id="modal-close" 
							svg="${iconNames.del}" ${ref(this.svg)}>
						</rps-svg>
					</div>
					<div class="body">
						<slot></slot>
					</div>
					<slot name="footer">
					</slot>
				</div>
			</div>
		`;
	}
}
