import { html } from 'lit';
import { ref } from 'lit/directives/ref.js';
import { BaseInputControl } from '../baseClasses/BaseInputControl';
import { iconNames } from '../../svg-icons';
import '../../rps-svg';
import '../../rps-input-label';
import { styles } from './css/dropDown.css.js';

export class DropDown extends BaseInputControl {
	static styles = styles;

	static get properties() {
		return {
			name: { type: String },
			defaultValue: { type: String },
			items: { type: Array },
			cbInput: { attribute: false },
		};
	}

	constructor() {
		super();
		this.defaultValue = "";
		this.items = [];
	}

	createItems() {
		return this.items.map(e => {
			const val = typeof e === "object" ? e.id : e;							// allows for an object with [{id,text}] or ["val1","val2"]
			const cache = (this.cache && this.cache.length > 0) ? JSON.parse(this.cache) : undefined;
			const initial = (cache) ? cache.value : this.defaultValue;			// use cached value or defaultValue
			const selected = initial === val;												// allow for string and number

			return html`
				<option
					key=${typeof e === "object" ? e.id : e}
					value=${val}
					?selected=${selected}
				>
					${typeof e === "object" ? e.text : e}
				</option>`;
		})
	}

	render() {
		return html`
			<div class="select ${this.className}">
				<rps-input-label
					class="" text="${this.label}"
					?required=${this.required} ?disabled=${this.disabled} hide=${this.hideLabel}
					${ref(this.labelCtrl)}
				></rps-input-label>

				<div class="select-container" ${ref(this.container)}>
					<select name=${this.name} value=${this.value}
						?disabled=${this.disabled} ?readonly=${this.readonly} ?required=${this.required}
						@input=${this.inputChanged} ${ref(this.inputCtrl)}>
						${this.createItems()}
					</select>
					<slot class="slot-icon">
						<rps-svg class="angle-down-icon" svg="${iconNames.angleDown}"></rps-svg>
						<rps-svg class="readonly-icon" svg="${iconNames.readOnly}"></rps-svg>
					</slot>
				</div>
			</div>
		`;
	}

}

