/**
 * @name rps-count-down
 * @type {CountDown}
 * 
 * 
 * @attributes + @properties 				| Changes trigger a re-render
 * Casing usages:								| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String} preText			| text to display before the remaining time
 * 	@property {String} postText		| text to display after the remaining time
 * 	@property {Number} hours			| Optional: Default is 0. Hours until countdown is completed
 * 	@property {Number} minutes			| Optional: Default is 1. Minutes until countdown is completed
 * 	@property {Number} seconds			| Optional: Default is 0. Seconds until countdown is completed
 * 	@property {Number} start			| From when to start the countdown, override with your own custom time, or leave to use "new Date()"
 * 	@property {String} customCss		| Specify a custom .css file to include, use cssPath to override elements,
 * 													DO NOT override the styling definition by re-defining the css styling
 * 													Put this file into the *window.cssPath* folder @see {link README.md}
 * 													eg: /public/css (where the other css files are)
 * 													Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String} css				| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 												
 * 
 * @methods
 * 	startTimer(start)						| Optional, gets fired automatically when component first renders.
 * 													(start) parameter defaults the the time that the control was created
 * 													Override example: .startTimer( new Date().getTime() ) // start from now
 * 													You can call this to restart the countdown. Pass the current date if called manually
 * 												
 * 
 * @events
 * 	stop										| Fires when the time has elapsed
 * 
 * @callbacks
 * 	cbStop									| Called when the time has elapsed
 * 
 * @slots
 * 	preTextSlot								| Optional: use HTML for this slot
 * 													Will override the "preText" attribute with your custom HTML
 * 												
 * 	postTextSlot							| Optional: use HTML for this slot
 * 													Will override the "postText" attribute with your custom HTML
 * 												
 * 
 * @example+ @lit
 * @basic
 * ===========
 * import '@rps-wc/rps-count-down';
 * 
 * <rps-count-down @stop=${e => console.log(e)}>
 * </rps-count-down>
 * 
 * @advanced
 * ==============
 * import '@rps-wc/rps-count-down';
 * 
 * <rps-count-down hours="0" minutes="0" seconds="10" @stop=${e => console.log(e)}>
 * 	<span slot="preTextSlot">
 * 		<strong style="font-size:large;">Before</strong>
 * 	</span>
 * 	<span slot="postTextSlot">
 * 		<strong style="font-size:smaller; color:purple;">After</strong>
 * 	</span>
 * </rps-count-down>
 * 
 * 
 * @example+ @ReactJS
 * @basic
 * ===========
 * import '@rps-wc/rps-count-down';
 * 
 * 	useEffect(() => {
 * 		count.current.cbStop= {e => console.log(e)}
 * 	}, [])
 * 
 * 	return <rps-count-down ref={count}></rps-count-down>
 * 
 * @advanced
 * ==============
 * import '@rps-wc/rps-count-down';
 * 
 * 	useEffect(() => {
 * 		count.current.cbStop= {e => console.log(e)}
 * 	}, [])
 * 
 * 	return (
 * 		<rps-count-down hours="0" minutes="0" seconds="10" ref={count}>
 * 			<span slot="preTextSlot">
 * 				<strong style="font-size:large;">Before</strong>
 * 			</span>
 * 			<span slot="postTextSlot">
 * 				<strong style="font-size:smaller; color:purple;">After</strong>
 * 			</span>
 * 		</rps-count-down>
 * 	)
*/
import { CountDown } from './src/general/CountDown';
if (!window.customElements.get('rps-count-down'))
	window.customElements.define('rps-count-down', CountDown);

