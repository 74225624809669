import { html, css } from 'lit';
import { createRef, ref } from 'lit/directives/ref.js';
import { BaseInputControl } from '../baseClasses/BaseInputControl';
import '../../rps-form';
import '../../rps-input';
import '../../rps-card';
import { iconNames } from '../../svg-icons';

export class Login extends BaseInputControl {

	static styles = css`
		/*:host {
			display: block;
		}*/
		rps-svg {
			display: block;
			--icon-display: block;
			--icon-height: 6.4rem;
			--icon-gap-left: auto;
			--icon-gap-right: auto;
		}
	 `;

	static get properties() {
		return {
			icon: { type: String },
			userNameLabel: { type: String },
			passwordLabel: { type: String },

			userNamePattern: { type: String },
			passwordPattern: { type: String },

			userNamePlaceholder: { type: String },
			passwordPlaceholder: { type: String },

			userNameErrorMessage: { type: String },
			passwordErrorMessage: { type: String },

			confirmButtonText: { type: String },
			forgotLinkText: { type: String },
			hideCancelButton: { type: Boolean },

			userNameType: { type: String },

			cbForgot: { attribute: false },
			cbSubmit: { attribute: false },
			cbCancel: { attribute: false },
		};
	}

	constructor() {
		super();
		this.icon = iconNames.odo.blueLogo;
		this.userNameLabel = "User name";
		this.passwordLabel = "Password";
		this.userNameType = 'email';

		this.userNamePattern = '.{5,}'; 				//'[A-Za-z]{3,}';		// at least 3 long characters
		this.passwordPattern = '.{8,}';				// at least 8 long
		this.userNamePlaceholder = "Enter your email";
		this.passwordPlaceholder = "Enter Password";
		this.userNameErrorMessage = 'Usernames must be a valid email';
		this.passwordErrorMessage = 'Passwords must be at least 8 long';
		this.confirmButtonText = "Login";
		this.forgotLinkText = "Forgot password";
		this.hideCancelButton = false;

		this.userName = createRef();
		this.password = createRef();
	}

	/**
	 * return a reference to the userName control
	 *
	 * @readonly
	 * @memberof Login
	 */
	get userNameControl() {
		return this.userName.value;
	}

	/**
	 * return a reference to the password control
	 *
	 * @readonly
	 * @memberof Login
	 */
	get passwordControl() {
		return this.password.value;
	}


	/**
	 * Fires when the user clicks on the "forgot password" link
	 *
	 * @param {Event} e
	 * @memberof Login
	 * @private
	 */
	forgot(e) {
		e.stopPropagation();
		console.debug('Login:forgot', 'forgot password clicked');

		const detail = {
			source: this.tagName,
			id: this.id,
			userName: this.userNameControl.value,
			password: this.passwordControl.value,
			userNameControl: this.userNameControl,
			passwordControl: this.passwordControl,
		};
		e = new CustomEvent('forgot', { detail, bubbles: true, composed: true, cancelable: true });
		this.dispatchEvent(e);

		if (this.cbForgot) this.cbForgot(e);
	}

	/**
	 * Fires when the user clicks on the "login" link
	 *
	 * @param {Event} e
	 * @memberof Login
	 * @private
	 */
	login(e) {
		e.stopPropagation();
		console.debug('Login:login', e);

		const detail = {
			source: this.tagName,
			id: this.id,
			userName: this.userNameControl.value,
			password: this.passwordControl.value,
			userNameControl: this.userNameControl,
			passwordControl: this.passwordControl,
		};
		e = new CustomEvent('submit', { detail, bubbles: true, composed: true, cancelable: true });
		this.dispatchEvent(e);

		if (this.cbSubmit) this.cbSubmit(e);
	}

	/**
	 * Fires when the user clicks on the "login" link
	 *
	 * @param {Event} e
	 * @memberof Login
	 * @private
	 */
	cancel(e) {
		e.stopPropagation();
		console.debug('Login:cancel', e);

		const detail = {
			source: this.tagName,
			id: this.id,
			userName: this.userNameControl.value,
			password: this.passwordControl.value,
			userNameControl: this.userNameControl,
			passwordControl: this.passwordControl,
		};
		e = new CustomEvent('cancel', { detail, bubbles: true, composed: true, cancelable: true });
		this.dispatchEvent(e);

		if (this.cbCancel) this.cbCancel(e);
	}


	render() {
		return html`
		${this.addCss(true)}
	
		<rps-card>
			<div slot="header">
				<rps-svg svg="${this.icon}"></rps-svg>
			</div>
			<rps-form 
				@submit=${this.login} @cancel=${this.cancel} 
				confirmText="${this.confirmButtonText}" 
				?hideCancelButton=${this.hideCancelButton}>
				<rps-input name="userName" label="${this.userNameLabel}" required
					placeholder="${this.userNamePlaceholder}"
					errormessage="${this.userNameErrorMessage}"
					pattern="${this.userNamePattern}"
					type="${this.userNameType}"
					caching
					${ref(this.userName)} 
				>
				</rps-input>

				<rps-input name="password" label="${this.passwordLabel}" required
					placeholder="${this.passwordPlaceholder}"
					errormessage="${this.passwordErrorMessage}"
					type="password"
					pattern="${this.passwordPattern}"
					caching
					${ref(this.password)}
				>
				</rps-input>

				<a href="#" @click=${this.forgot}>${this.forgotLinkText}</a>
			</rps-form>
		</rps-card>
		`;
	}
}

