// TODO: remove Goober and replace with https://styled-components.com/
import { styled, keyframes } from 'goober';
import { cssColor } from '@odo/utils/css-color';

const swing = keyframes`
  0%, 100% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(20deg);
  }

  75% {
    transform: rotate(-20deg);
  }
`;

export const SlowSwing = styled('span')`
  will-change: transform;
  &.active {
    animation: ${swing} 3.75s linear infinite;
  }
`;

export const Hidable = styled('span')`
  display: inline-flex;
  justify-content: center;
  opacity: 1;
  transition: opacity 0.15s linear;
  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
`;

export const Heading = styled('h3')`
  margin-bottom: 15px;
`;

export const SmallHeading = styled('h4')``;

export const ChildWrapper = styled('div')`
  &.hide {
    display: none;
  }
`;

export const ToolbarWrapper = styled('menu')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  position: sticky;
  top: -1rem;
  z-index: 7;
  background: #fff;
  border: 1px solid #c2c2e2;
  padding: 6px;
  margin-bottom: 30px;
`;

export const ToolbarPart = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const ToolbarSeparator = styled('span')`
  height: 34px;
  width: 1px;
  background: #c2c2e2;
`;

export const OptionsWrapper = styled('div')``;

export const RowInner = styled('fieldset')`
  display: flex;
  flex-direction: row;
  margin: 6px 0;
  overflow-y: scroll;
  background: ${cssColor('foreground-alt')};
  &:hover {
    background: ${cssColor('palette-blue-muted')};
  }
`;

export const TierGraphWrapper = styled('div')`
  display: flex;
  flex-direction: row;
`;

export const TierGraphItem = styled('span')<{
  borderColor: string;
  backgroundColor?: string;
}>`
  padding-right: 12px;
  height: 100%;
  border-left: 4px solid ${props => props.borderColor};
  background: ${props => props.backgroundColor};
`;

export const TierGraphInfoWrapper = styled('div')<{
  color: string;
}>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  padding-right: 20px;
  padding-left: 6px;
  align-content: center;
  color: ${props => props.color};
`;

export const TierGraphInfoLabel = styled('span')`
  font-size: 9px;
  line-height: 9px;
  letter-spacing: 0.065rem;
  text-transform: uppercase;
`;

export const TierGraphInfoNumber = styled('span')`
  font-size: 34px;
  line-height: 34px;
  font-variant-numeric: tabular-nums;
`;

export const ToggleCollapseWrapper = styled('div')`
  height: 100%;
  display: flex;
  align-items: center;
  &.disabled {
    opacity: 0;
    pointer-events: none;
  }
`;

export const ValueAndActionsWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  padding: 8px 8px 8px 0;
  width: 100%;
  max-width: 1400px;
`;

export const SortActionsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const InputWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
`;

export const ActionsWrapper = styled('div')`
  display: grid;
  align-items: center;
  align-content: center;
  grid-template-columns: repeat(3, 35px);
  gap: 8px;
`;

export const ErrorMessage = styled('span')`
  color: var(--brand-alt-pink-dark);
  font: var(--text-m);
  margin-bottom: 15px;
  display: inline-block;
`;
