/**
 * @name rps-grid
 * @type {Grid}
 * A grid component.
 *
 * @Important
 * 	NB: each row **MUST** contain a "id" property. This is used to identify the row to edit, delete etc.
 *
 * @important + @ReactJS
 * 	DO NOT assign the .columns property when the column definition contains javascript or html code
 * 	Consider using the @see {reactWrapper} for this grid when using ReactJS
 *
 * @ReactJS where @JS and @HTML are in the column definitions
 * @important + @Example + @WRONG
 * 	// JS and HTML  in columns lost in JSON parsing (component wont render correctly)
 * 	<rps-grid columns={JSON.stringify(columns)} data={JSON.stringify(data)} pageSize="7" hasfilter />
 *
 * @important + @ReactJS + @Example + @CORRECT
 *  	useEffect(() => {
 * 		const grid = document.querySelector('rps-grid');
 * 		grid.columns = columns;
 * 		grid.data = data;
 * 	}, [])
 *
 * 	<rps-grid pageSize="7" hasfilter />
 *
 * @notes + @data_transformer
 * The output of data can be transformed before being shown in the grid by providing a transform function definition on a column.
 * See column definitions below.
 * Transformed data is factored into filtering and sorting (i.e.: The sort or filter happens on the result of the transformed data).
 *
 * @notes + @general
 * If assigning a large array to the dataGrid is it more performant to NOT use a JSON.stringify() in the HTML but assign the array directly in code.
 * ie: Assign in code for runtimes where that must be done like ReactJS.
 * In web-components you dont have this restriction as the array can be assigned directly.
 * See this example for web-components
 * <rps-grid .columns=${columns} .data=${data} pageSize="7" hasfilter />
 *
 *
 * Each object (in a row of the grid) can contain as many properties as desired.
 * The column definitions don't need to match the data.
 * The columns display only what is specified in the definitions, extra properties per row do not matter
 *
 * If this control edits data then read the <rps-grid>".data" property to get the data the grid uses.
 * This is the same property you use to pass in the data.
 *
 * @column @definition
 * 	headerText: 					| String [required]
 * 	key: 								| String [required] if this column displays data. - The property name of the object to display.
 * 	sortable: true					| [optional] - determines if this column is sortable
 * 	template:						| [optional] - only required if this grid is rendered with a template (See properties below)
 * 	{
 * 		slot: 'example'			| grid area to render this cell at (See template property below and the Grid template demo: @see {@link http://localhost:8000/demo/easy/gridTemplate.html})
 * 	}
 * 	data: 							| Object, required if this column displays data
 * 	{
 * 		type: 'number', 			| date, number, currency, string) Default is "string"
 * 		decimals: 0, 				| [optional], only applies if type is number] default is 0
 * 		hasTime: true,				| [optional], only applies if type is date] default is false ie: only time displayed
 * 		transform: function		| [optional], callback that transforms incoming data before displaying it. See examples.
 * 		control: {					| [optional]
 * 			// the different types of controls are listed here.
 * 			// choose ONLY 1 of them
 * 			deleteButton: {
 * 				classes: 'big-button',				[optional, Put in all custom class names if desired]
 * 				cbClick: (detail) => {console.log('CUSTOM EVENT HANDLER FOR DELETEBUTTON', detail)},
 * 			},
 * 			textButton : {
 * 				text: 'text to display' or function(cellValue, rowId, columnNr, control, classes),		[Text or function for the button]
 * 				classes: 'small-button underlined',			[optional, Put in all custom class names if desired]
 * 				cbClick: (detail) => {console.log('CUSTOM EVENT HANDLER FOR TEXTBUTTON', detail)},
 * 			},
 * 			imageButton: {
 * 				image: '${iconName.menu}',			[optional, svg name.]
 * 				alt: 	 'altText',						[optional, alt text for image.]
 * 				classes: 'outline secondary',		[optional, Put in all the classes (and/or HTML classes) you wish to use]
 * 				cbClick: (detail) => {console.log('CUSTOM EVENT HANDLER FOR IMAGEBUTTON', detail)},
 * 			},
 * 			customControl: {
 * 				// NB: return ONLY plain HTML, js, css OR: a litElement. (no react controls etc).
 * 				// return using html`` eg: see line below
 * 				// import { html } from 'lit';	// include in your page as an import
 * 				// rowId and columnNUmber should be added so you can get the originating row and column after a click has happened
 * 				render: (data, row, rowId, columnNumber, control, classes) => {
 * 								return html`
 * 									<span rowid=${rowId} columnnumber=${columnNumber}
 * 										@click="${customControlClickHandler}"
 * 									>${row.description}
 * 									</span>`
 * 							}
 * 				classes: 'outline secondary',	[optional, Put in all the classes (and/or HTML classes) you wish to use]
 * 			},
 * 		}
 *
 * 	}
 * 	footer: 			[Object: optional]
 * 	{
 * 		type: 'max'	(min, max, average, sum),
 * 		render: (data2Display, col) => {
 * 			return data2Display.reduce((a, b) => a + b[col.key], 0)	// example of a "Sum" action
 * 		},		// optional, use "type" or "render"
 * 		// Example of a custom cbInput event handler
 * 		cbClick: (event,detail) => {console.log('CUSTOM EVENT HANDLER FOR FOOTER', detail)},
 * 	}
 *
 *
 * ===================================================================================================
 *
 * 	@const columns = [
 * 		{ headerText: "Cat/grp/product", key: "description", sortable: true },
 * 		{
 * 			headerText: "region1", key: "region1",
 * 			data: { type: 'number', decimals: 0 }, footer: { type: 'max' }
 * 		},		// or 'min'
 * 		{
 * 			headerText: "region2", key: "region2",
 * 			data: {
 * 				type: 'number', decimals: 2,
 * 				control: {
 * 					plusMinus: {
 * 					}
 * 				}
 * 			},
 * 			footer: { type: 'average' }
 * 		},
 * 		{
 * 			headerText: "date", key: "date", sortable: true,
 * 			data: {
 * 				type: 'date',
 * 				hasTime: true,
 * 				transform: (value) => {
 * 					// Transform value if it's in an unexpected format
 * 					if(typeof value === 'string') {
 * 						return new Date(value);
 * 					}
 * 				}
 * 			}
 * 		},
 * 		{
 * 			headerText: "Total", key: "total",
 * 			data: {
 * 				type: 'currency',
 *				transform: (value, row) => {
 *  				// Compute entirely new value based on other columns
 *	 				return row['price'] * row['quantity'];
 * 				}
 * 			}, sortable: true, footer: { type: 'sum' }
 * 		},
 * 		{
 * 			headerText: "Is Unique", key: "unique",
 * 			data: {
 * 				transform: (value, row, data) => {
 * 					// Performing an operation on all data in the grid
 * 					let res = 'Unique';
 * 					data.every(item => {
 * 						if(item !== row && item['description'] === row['description']) {
 * 							res = 'Not Unique';
 * 							return false;
 * 						}
 *
 * 						return true;
 * 					});
 *
 * 					return res;
 * 				}
 * 			}
 * 		},
 * 		{
 * 			headerText: "Delete",
 * 			data: {
 * 				control:
 * 				{
 * 					deleteButton: {
 * 						cbClick: (detail) => {console.log('CUSTOM EVENT HANDLER FOR DELETEBUTTON', detail)},
 * 					}
 * 				}
 * 			}
 * 		},
 * 		{
 * 			headerText: "TextButton",
 * 			data: {
 * 				control:
 * 				{
 * 					textButton: {
 * 						// Return a customized text/button using a function
 * 						text: (row, rowId, columnNumber) => { return "Add " + row.description },
 * 						//text: "Click",		// just using text
 * 						classes: "btn btn-primary btn-sm",
 * 						cbClick: (detail) => {console.log('CUSTOM EVENT HANDLER FOR TEXTBUTTON', detail)},
 * 					}
 * 				}
 * 			}
 * 		},
 * 		{
 * 			headerText: "ImageButton",
 * 			data: {
 * 				control:
 * 				{
 * 					imageButton: {
 * 						image: "${iconNames.add}",
 * 						//alt: "alt text for image",
 * 						classes: "outline secondary",
 * 						cbClick: (detail) => {console.log('CUSTOM EVENT HANDLER FOR IMAGEBUTTON', detail)},
 * 					}
 * 				}
 * 			}
 * 		},
 * 		{
 * 			headerText: "Custom",
 * 			data: {
 * 				control:
 * 				{
 * 					customControl: {
 * 						render: (data, row, rowId, columnNumber, classes) => {
 * 							return html`
 * 								<span rowid=${rowId} columnnumber=${columnNumber}
 * 									@click="${customControlClickHandler}"
 * 								>${row.description}
 * 								</span>`
 * 						},
 * 						classes: "fa-address-card fa-1x"
 * 					}
 * 				}
 * 			}
 * 		},
 * 	];
 *
 *
 * 	@const rows = [];
 * 	@const categories = ["Household", "Kitchenware", "Cooking", "other", "lounge", "kitchen","bathroom","garage","storage"];
 *
 * 	for (let cat = 0; cat < 9; cat++) {
 * 		const row = {
 * 			id: uuid(),
 * 			description: categories[cat],						// actually category, mapped with same name for ease
 * 			region1: cat + 1,
 * 			region2: cat + 2,
 * 			date: new Date(2020, 8, cat, cat + 1, cat * 2, cat * 3).toISOString(),
 * 			total: cat + 44,
 * 			sku: cat + 100,
 * 			price: faker.commerce.price()
 * 		}
 * 		rows.push(row);
 * 	}
 * 	setData(rows);
 *
 *
 * @properties and @attributes			| Changes trigger a re-render]
 * 	Casing usages:
 * 	* Attribute in html (lower case),
 * 	* Property in JS (Camel Case)
 * 	@property {Array} 	data 					| array of data for grid
 * 	@property {Array} 	columns 				| column definition
 * 	@property {String} 	rowSize 				| 'small' 'medium' or 'large' default is medium (padding etc for rows in grid)
 * 	@property {String} 	culture 				| default is 'en-ZA', use for formatting dates and currency
 * 	@property {String} 	currencySymbol 	| default is "ZAR", iso code for RSA currency symbol
 * 	@property {Number} 	pageSize 			| default is 10. how may items to display on 1 page in the grid
 * 	@property {Number} 	currentPage 		| default is 1. The current page to display
 * 	@property {Boolean} 	hasFilter 			| Boolean, default is false. Does this grid have a filter
 * 	@property {Boolean}  condenseCells 		| Boolean, default is false. If true, condenses cells down to fit content, padding with an empty cell at the end of the row.
 * 	@property {Array}		filterOnColumns	| Default is to search on all columns, override to specify properties to search
 * 															eg: ["Description", "Title"]	// Now filtering will only be done on these columns
 * 	@property {String}	template				| Specify a template layout for the grid in place of a normal table layout
 *		 														@see {link /demo/easy/gridTemplate.html} for an example
 * 	@property {Number}	templatesInRow		| When using a template, you can specify the amount of "templates" ie: cells to display on each row
 * 	@property {Boolean} 	hideHeader 			| Hide the header in a grid. Most often used when rendering templates
 * 	@property {String} 	columnSize 			| Options are: 'max-content', 'min-content', '1fr'
 * 	@property {Array}		toolbarButtons		| An array of buttons used to create a custom toolbar for the grid.
 * 															@see {link rps-toolbar} for details
 * 															@description This is an additional toolbar. It is separate from the "grid/card" toolbar that is auto generated.
 * 	@property {String} 	customCss 			| Specify a custom .css file to include, use cssPath to override elements,
 * 															DO NOT override the styling definition by re-defining the css styling
 * 															Put this file into the *window.cssPath* folder @see {link README.md}
 * 															eg: /public/css (where the other css files are)
 * 															Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String} css						| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 *
 * @Styling
 * Sizes:
 * 	compact											| Small size grid cells
 * 	default											| Medium size grid cells
 * 	expanded											| Large size grid cells
 * 
 * 
 * @Properties (Code only)
 * 	data2Display (get only)						| Retrieve the data that is being displayed in the grid, ie: filtered and sorted by user.
 * 	get pageControl										| The Paging <rps-pagination> control.
 * 															If there is only 1 page this will be undefined
 * 	get filterControl								| A reference to the filter control if it exists 
 * 															Gives a reference to the <rps-input> for filtering
 * 
 *
 * 	template:										| [optional] - allows for highly specific cell layouts and complex rendering (See the Grid Template Demo @see {@link http://localhost:8000/demo/easy/gridTemplate.html})
 * 	{
 * 		areas: `										| CSS grid-template-areas definition - @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-areas}
 * 			"header header header"
 * 			"left   center  right"
 * 			"footer footer footer"
 * 		`,
 * 		minWidth: '12rem'							| minimum width a cell should occupy
 * 		maxWidth: 'auto'							| maximum width a cell should occupy
 * 		gap: '0rem'									| gap between cells
 * 	}
 *
 * @methods
 * 	getHeaderColumn(columnNumber)				| Return the nth() header control.
 * 															must be >= 0 and smaller than the amount of columns
 * 															NB: Columns numbers
 * 	filterChange(event)							| Manually perform the filtering for the grid.
 * 															eg: 	React: <input onInput={e => grid.current.filterChange(e) }>
 * 																	Html:  <input oninput={e => grid.current.filterChange(e) }>
 *
 * @events
 * 	click() 											| Generic handler that listens to all <rps-input> click events unless you override the custom handler
 * 															these values will help you find the correct row/column in the data grid
 *		firstUpdated()									| Fire an event when the grid has rendered the first time.
 * 															Hook into this event to perform operations on the grid when it has rendered the first time
 *
 * @callbacks
 * 	cbFirstUpdated									| Fire an event when the grid has rendered the first time.
 * 															Hook into this event to perform operations on the grid when it has rendered the first time
 *
 * @slots
 * 	None
 * 
 * 
 * @Styling
 * Sizes:
 * 	compact:		Small size grid cells
 * 	default:		Medium size grid cells
 * 	expanded:		Large size grid cells
*/
import { Grid } from './src/grid/Grid';
if (!window.customElements.get('rps-grid'))
	window.customElements.define('rps-grid', Grid);

