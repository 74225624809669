import { html } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { ifDefined } from 'lit/directives/if-defined.js';
import { ref, createRef } from 'lit/directives/ref.js';
import { styles } from './css/image.css.js';
import '../../rps-spinner-pause';
import '../../rps-ok-modal';
import '../../rps-toolbar';
import { iconNames } from '../../svg-icons';

export class Image extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			src: { type: String },
			imageSources: { type: Array },			// list of image sources
			images: { type: Array },					// list of images to use based on internet speed
			label: { type: String },
			alt: { type: String },
			canExpand: { type: Boolean },
			cbClick: { attribute: false },
			cbToolbarClick: { attribute: false },
			buttons: { type: Array },
			layout: { type: String },
			showModal: { attribute: false },			// used to render /display modal when needed
		};
	}

	constructor() {
		super();
		this.images = [];
		this.label = ""
		this.alt = "image";
		this.layout = "row";
		this.imageSources = [];
		this.modalRef = createRef();
		this.imgRef = createRef();
		this.canExpand = false;
		this.showModal = false;
		this.buttons = [];
		this.expand = [
			{ id: "expand", svg: iconNames.search, title: "Zoom in" }
		];
	}

	/**
	 * The modal on the form
	 *
	 * @readonly
	 * @memberof Image
	 */
	get modalControl() {
		return this.modalRef.value;
	}

	/**
	 * The <img> control
	 *
	 * @readonly
	 * @memberof Image
	 */
	get imageControl() {
		return this.imgRef.value;
	}


	/**
	 * Generate a scaled image from the original.
	 * @description Pass either the scale or newWidth
	 *
	 * @param {Image} [img = this.imageControl] The image used as the base for the scaling.
	 * If not passed, then the image control is used
	 * @param {Number} scale [Optional] The scale to apply
	 * @param {Number} newWidth [Optional] The width in px to use
	 * @return {ImageData}
	 * @memberof Image
	 */
	generateScaledImage(img, scale, newWidth) {
		if (!img) img = this.imageControl;
		const { naturalWidth, naturalHeight } = img;
		if (!newWidth) newWidth = this.thumbNailWidth;
		if (!scale) scale = newWidth / naturalWidth;

		const canvas = document.createElement("canvas");
		canvas.width = Math.round(naturalWidth * scale);
		canvas.height = Math.round(naturalHeight * scale);

		const ctx = canvas.getContext("2d");
		ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

		return canvas.toDataURL(img.type);
	}

	/**
	 * Fired when the user clicks on the <a>
	 *
	 * @param {Event} e
	 * @memberof Image
	 */
	_click(e) {
		console.debug('Image:Click', e);

		e.stopPropagation();
		e.preventDefault();

		const detail = {
			control: e.target,
			source: this.tagName,
			id: this.id,
			name: this.name,
			imageControl: this.imageControl
		};

		e = new CustomEvent('click', { bubbles: true, cancelable: true, composed: true, detail });
		this.dispatchEvent(e);

		this.toggleSelected();
		if (this.cbClick) this.cbClick(e);

		if (this.canExpand) {
			//this.modalControl.show();
			this.showModal = true;
			this.requestUpdate();
		}

	}

	/**
	 * Toggle the state of the selected class
	 *
	 * @param {Boolean} addSelected if undefined the class is toggled. if true then forcefully added, if false, then forcefully removed
	 * @memberof Image
	 */
	toggleSelected(addSelected) {
		if (this.imageControl) {
			this.imageControl.classList.toggle('selected', addSelected)
		}
	}

	/**
	 * Fired when the user clicks on the toolbar
	 *
	 * @param {Event} e
	 * @memberof Image
	 */
	_toolbarClick(e) {
		console.debug('Image:toolbarClick', e);

		e.stopPropagation();
		e.preventDefault();
		const buttonDetail = e.detail;
		const buttonId = e.detail.buttonId;

		const detail = {
			control: e.target,
			source: this.tagName,
			id: this.id,
			name: this.name,
		};

		e = new CustomEvent('toolbarClick', { bubbles: true, cancelable: true, composed: true, detail: { ...detail, ...buttonDetail } });
		this.dispatchEvent(e);

		if (this.cbToolbarClick) this.cbToolbarClick(e);

		if (buttonId === "expand") {
			//this.modalControl.show();
			this.showModal = true;
			this.requestUpdate();
		}

	}


	/**
	 * Generate all the image sources
	 *
	 * @return {Html}
	 * @memberof Image
	 * @private
	 */
	_imageSources() {
		// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/source
		return this.imageSources.map(source => {
			return html`<source media=${ifDefined(source.media)} srcset=${ifDefined(source.srcset)} src=${ifDefined(source.src)}
				type=${ifDefined(source.type)} sizes=${ifDefined(source.sizes)}>`;
		});
	}

	/**
	 * Set the images for this control, and re-render
	 * @example
	 * 	const images = [
	 * 		{ src: "url1", speed: "fast" },
	 * 		{ src: "url2", speed: "medium" },
	 * 		{ src: "url3", speed: "slow" }
	 * 	];
	 *
	 * @param {Array} images An array of images that have 2 properties. The url, and the speed for this url
	 * @memberof Image
	 */
	setImages(images) {
		this.images = images;
		this.requestUpdate();
	}

	/**
	 * Create the toolbar for the Image
	 *
	 * @return {Html}
	 * @memberof Image
	 */
	createToolbar() {
		if (!this.canExpand && this.buttons.length === 0) return '';

		const buttons = this.buttons.length > 0 ? [...this.buttons, ...this.expand] : this.expand;

		return html`<rps-toolbar class="image-toolbar" .buttons=${buttons} @click=${this._toolbarClick}></rps-toolbar>`;
	}

	/**
	 * Generate the image or placeholder
	 *
	 * @return {Html}
	 * @memberof Image
	 */
	generateImage() {
		if (this.images?.length > 0) {
			const speed = window.internetSpeed || 'fast';
			const item = this.images.find(elem => elem.speed === speed);
			const image = item ? item.src : this.images[0].src;

			return html`
				<figure>
					<picture>
						<img src="${image}"
							crossorigin='anonymous'
							?canexpand=${this.canExpand}
							${ref(this.imgRef)}
							@click=${this._click}
							alt=${this.alt}
							layout="${this.layout}" class="${this.className}"
							loading="lazy"
						>
						${this.createToolbar()}
						</picture>
					<figcaption>${this.label}</figcaption>
				</figure>
			`;
		}

		if (this.src) {
			return html`
				<figure>
					<picture>
						${this._imageSources()}
						<img src="${this.src}"
							crossorigin='anonymous'
							?canexpand=${this.canExpand}
							${ref(this.imgRef)}
							@click=${this._click}
							alt=${this.alt}
							layout="${this.layout}" class="${this.className}"
							loading="lazy"
						>
						${this.createToolbar()}
						</picture>
					<figcaption>${this.label}</figcaption>
				</figure >
			`;
		}

		return html`
			<div class="image-place-holder">
				<rps-spinner-pause></rps-spinner-pause>
			</div>
		`;
	}

	render() {
		let modal = '';
		if (this.showModal) {
			modal = html`
			<rps-ok-modal popup opened
				${ref(this.modalRef)} headerText="" class="carousel-modal"
				@cancel=${e => this.showModal = false} allowNoConfirm>
				<img src="${this.src}" alt=${this.alt} crossorigin='anonymous' loading="lazy">
					<div slot="header">${this.label}</div>
			</rps-ok-modal>
		`;
		}

		return html`
			${this.addCss()}
			<div class="image-container ${this.className}">
				${modal}
				${this.generateImage()}
			</div>
			`;
	}
}


