import { useSafeEffect } from './useSafeEffect';

/**
 * Add drag-and-drop events to the Window level, allowing you to intercept files dropped anywhere on the screen
 * while this effect is active.
 *
 * @param {DropzoneEffectCallback} fnAction - a callback to process dropped items
 * @param {*[]} dependencies - standard useEffect dependencies array
 */
export const useWindowDropzoneEffect = (fnAction, dependencies) => {
  useSafeEffect(isMounted => {
    const handleFileDrop = ev => {
      ev.preventDefault();
      if (ev.dataTransfer.items) {
        const items = [];
        for (let i = 0; i < ev.dataTransfer.items.length; i++) {
          items.push(ev.dataTransfer.items[i].getAsFile());
        }
        if (isMounted) {
          fnAction(items);
        }
      }
    };

    const handleDragOver = ev => {
      ev.preventDefault();
    };

    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('drop', handleFileDrop);

    return () => {
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('drop', handleFileDrop);
    };
  }, dependencies);
};

/**
 * @callback DropzoneEffectCallback
 * @param {File[]} files - Array of File objects received from the drop event
 */
