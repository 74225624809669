/**
 * @name rps-pagination
 * @type {Pagination}
 * 	A pagination control used within the Grid, but can be used as a stand-alone component
 * 
 * 
 * @attributes + @properties 							| Changes trigger a re-render
 * Casing usages:
 * 	* Attribute in html (lower case), 
 * 	* Property in JS (Camel Case)
 * 	@property  {Number}	pageSize					| default of 10
 * 	@property  {Number}	itemCount				| needed: amount of items in collection that needs paging
 * 	@property  {Number}	currentPage				| default of 1
 * 	@property  {String}	customCss				| Specify a custom .css file to include, use cssPath to override elements
 * 															  DO NOT override the styling definition by re-defining the css styling
 * 															  Put this file into the *window.cssPath* folder @see {link README.md}
 * 															  eg: /public/css (where the other css files are)
 * 															  Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css						| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 
 * @properties (JS Only)
 * 	get pageCount										| Page count
 * 	get fullPageCount									| The amount of pages that display a full page. does not include the pages that arenot full
 * 	get lastPageItemCount							| The amount of items on thelast page *  * 
 * 
 * 
 * @methods 
 * 	firstPage()											| Move to the first Page
 * 	lastPage()											| Move to the last Page
 * 	nextPage()											| Move to the next Page
 * 	previousPage()										| Move to the previous Page
 * 
 * @events
 * 	pageHasChanged
 * 
 * @callbacks
 * 	onPageHasChanged(currentPage)					| callback function that can be used in place of an event listener
 * 
 * @slots
 * No slots
 * 
 * 
 * @example {Lit}
 * import '@rps-wc/rps-pagination';
 * 
 * 
 * 	pageChanged(ev) {
 * 		console.log(ev.detail);
 * 	}
 * 
 * <rps-pagination pagesize=10 itemcount=50 @pageHasChanged=${this.pageChanged} />
 *
 *
 * @example {React}
 * 	@description React cannot bind to all events so this needs to be done manually with addEventListener for this class
 * 	React can bind to events like @click and @input for this reason most events are implemented as "input" or "click"
 * 
 * import '@rps-wc/rps-pagination';
 * 
 * 	useEffect(() => {
 *			document.querySelector('rps-pagination').addEventListener('onPageChange', onPageChange);
 * 		return () => {
 * 			document.querySelector('rps-pagination').removeEventListener('onPageChange', onPageChange);
 * 		}
 * 	
 * }, [])
 * 
 * 
 * 	const onPageChange = (ev) => {
 * 		console.log(ev.detail);
 * 	}
 *
 * 	return <rps-pagination pagesize=10 itemcount=50 />
 * 
 * 
*/


import { Pagination } from './src/navigation/Pagination';
if (!window.customElements.get('rps-pagination'))
	window.customElements.define('rps-pagination', Pagination);

