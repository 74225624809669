import type { ApiCustomOption, ApiProduct } from '@odo/types/api';
import type { Dispatch, SetStateAction } from 'react';

export type ProductID = string;

export enum ProductSource {
  magento = 'magento',
  // NOTE: portal is for products that have not been saved yet
  portal = 'portal',
}

export interface BaseProduct {
  source: ProductSource;
  id: ProductID;
  product?: Partial<ApiProduct>;
  customOptions?: ApiCustomOption[];
}

export type MagentoProduct = BaseProduct & {
  source: ProductSource.magento;
  id: ApiProduct['id'];
};

export type PortalProduct = BaseProduct & {
  source: ProductSource.portal;
  id: string; // uuid
};

export type Product = MagentoProduct | PortalProduct;

export const isMagentoProduct = (product: Product): product is MagentoProduct =>
  (product as MagentoProduct).source === ProductSource.magento;

export interface ProductContextType {
  activeProduct?: Product;
  setActiveProduct: Dispatch<SetStateAction<Product | undefined>>;
}
