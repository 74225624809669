/**
 * @name rps-chip
 * @type {Chip}
 * A generic chip control that has built in edit and delete functionality
 * 
 * @attributes + @properties 							| Changes trigger a re-render
 * Casing usages: Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String}	text 						| The text to display in this Chip
 * 	@property {Boolean}	canEdit 					| Optional: Must a edit button be displayed on the chip?
 * 	@property {String}	deleteMode = 'none' 	| Optional: Values are ['none','confirm','quick','manual']
 * 																'none' no delete possible
 * 																'confirm' = Ask user with a modal
 * 																'quick' = just delete it
 * 																'manual' = just the event is fired, and the user must handle the delete action
 * 	@property {String}	modalHeader 			| Optional: Text to display in the modal popup when deleting, default="Are you Sure?"
 * 	@property {String}	modalContent 			| Optional: Text to display in the body of the delete Modal, default="Delete Chip id: [${this.id}]"
 * 	@property {String}	customCss				| Specify a custom .css file to include, use cssPath to override elements,
 * 																DO NOT override the styling definition by re-defining the css styling
 * 																Put this file into the *window.cssPath* folder @see {link README.md}
 * 																eg: /public/css (where the other css files are)
 * 																Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css						| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 	@property {Callback}	onClick					| Callback function for Click on chip
 * 	@property {Callback}	onEdit 					| Callback function for Edit on chip
 * 	@property {Callback}	onDelete					| Callback function for Delete on chip
 * 
 * 
 *  @properties (JS only) 
 *  	modal													| A reference to the modal. (NB: only exists if deleteMode === 'confirm')
 * 	chip													| A reference to the chip
 * 
 * @methods
 * 	No public methods
 * 
 * @events
 * 	click													| Fires when the chip is clicked on
 * 	edit													| Fires when a edit button is clicked
 * 	delete												| Fires when a delete is confirmed, or the edit button is clicked
 * 
 * @slots
 * 	slot-left 											| Slot content to left of text
 * 	slot-right											| Slot content to right of text
 * 
 * @callbacks
 * 	onDelete												| Callback function
 * 	onEdit												| Callback function
 * 	onClick												| Callback function
 * 
 * 
 * @example @Lit
 * @basic
 * ===========
 * import '@rps-wc/rps-chip';
 * 
 * <rps-chip text="Uploaded file"></rps-chip>
 * 
 * 
 * @example @Lit
 * @advanced
 * ===========
 * import '@rps-wc/rps-chip';
 * 
 * 	<rps-chip text="myFile"
 * 		@click=${e => console.log(e)}
 * 		@edit=${e => console.log(e)}
 * 		@delete=${e => console.log(e)}
 * 	>
 * 		<div slot="slot-left">custom HTML here</div>
 * 		<div slot="slot-right">custom HTML here</div>
 * 	</rps-chip>
 * 
 * 
 * @example @ReactJS
 * @basic
 * ===========
 * 	import '@rps-wc/rps-chip';
 * 
 * 	return <rps-chip text="Uploaded file"></rps-chip>
 * 
 * 
 * @example @ReactJS
 * @advanced
 * ===========
 * 	import '@rps-wc/rps-chip';
 * 
 * 	const onClick = (ev) => { console.log('click') }
 * 	const onEdit = (ev) => { console.log('edit') }
 * 	const onDelete = (ev) => { console.log('delete') }
 * 
 *  	useEffect(() => {
 *				input.current.cbInput = (e) => onDatesChange;
 *				input.current.cbEdit = (e) => onDatesChange;
 *				input.current.cbDelete = (e) => onDatesChange;
 *  	}, [])
 * 
 * return (
 * 	<rps-chip text="myFile" ref={input}>
 * 		<div slot="slot-left">custom HTML here</div>
 * 		<div slot="slot-right">custom HTML here</div>
 * 	</rps-chip>
 * )
 * 
*/
import { Chip } from './src/lists/Chip';
if (!window.customElements.get('rps-chip'))
	window.customElements.define('rps-chip', Chip);

