/**
 * Recursively scan a JSON object and search by using a custom callback function
 * @example const obj = findByCallback(myJSON, (item) => item.id === 'myid')
 *
 * @param {JSON} data JSON object to scan
 * @param {Function} cb The callback function used match an object
 * @return {Object}  The found object
 */
function findByCallback(data, cb) {
	if (data === null || data === undefined) return;		// dont try if it is a null || undefined

	if (cb(data))
		return data;

	for (const key in data) {
		const entry = data[key]

		if (typeof entry === 'object') {
			const found = findByCallback(entry, cb)

			if (found)
				return found
		}
	}
}

/**
 * Add an attribute to parent object in a recursive manner
 *
 * @param {JSON} data The object and its children to process
 * @param {String} newAttrName The attribute name to add
 * @param {object} newAttrValue The attribute value to add
 */
function addAttribute(data, newAttrName, newAttrValue) {
	for (const key in data) {
		const entry = data[key];
		if (key === newAttrName) {
			data[key] = newAttrValue;
			continue;
		}

		if (Array.isArray(entry)) {
			entry.forEach(child => {
				if (!child[newAttrName]) child[newAttrName] = newAttrValue;
				addAttribute(child, newAttrName, newAttrValue);
			});
		} else if (typeof entry === 'object') {
			if (!entry[newAttrName]) entry[newAttrName] = newAttrValue;
			addAttribute(entry, newAttrName, newAttrValue);
		}
	}
}

export {
	findByCallback,
	addAttribute,
}