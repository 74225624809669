import { html, css } from 'lit';
import {CustomLitElement} from '../baseClasses/CustomLitElement';
import '../../rps-search';
import '../../rps-card';

export class UpdateUser extends CustomLitElement {
	static styles = css`
			rps-card {
				display: flex;
				margin-bottom: var(--margin-large);
			}

			.selected-user {
				padding-left: var(--margin-medium);
				padding-right: var(--margin-medium);
			}

			.selected-user::after {
				content: "";
			}
    `;

	static get properties() {
		return {
			includeCss: { type: String },
			placeholder: { type: String },
			headerTitle: { type: String },
			claimDisplayFields: { type: Array },			// The fields in the user array to display, ie: what user was selected
			userDisplayFields: { type: Array },				// The fields in the user array to display, ie: what user was selected
			claims: { type: Array },							// An object in the array must contain a property "id" along with other desired properties (displayable or otherwise)
			existingClaims: { type: Array },					// An array of objects. A list of claims that this user already has, only the "id" property is used
			users: { type: Array },								// An object in the array must contain a property "id" along with other desired properties (displayable or otherwise)
		};
	}

	constructor() {
		super();
		this.headerTitle = "Select User's rights";
		this.users = [];																			// contains the users that were selected to grant rights to
		this.claimDisplayFields = ["firstName", "lastName"];
		this.claims = [{ id: 1, text: "reports" }, { id: 2, text: "CRUD" }];		// default values
		this.existingClaims = [{ id: 1, hasAccess: true }];							// a list of claims that this user already has
		this.selectedUserList = [];															// contains the users that were selected
		this.placeholder = "enter user's name";
	}

	_usersSelected(event) {
		//console.debug("invite event:", event.detail);
		this.selectedUserList = event.detail.selected;
		this.requestUpdate();
	}

	selectedUsers() {
		return html`
			${this.selectedUserList.map(e => html`<span class="selected-user">${this.claimDisplayFields.map(f => `${e[f]} `)}</span>`)}
		`;
	}

	_claimsSelected(event) {
		//console.debug(event.detail, this.users);
		const detail = { users: this.selectedUserList, claims: event.detail, source: this.tagName };
		event = new CustomEvent("users-selected", { detail, bubbles: true, composed: true });
		this.dispatchEvent(event);

		this.selectedUserList = [];
		this.requestUpdate();
	}

	_displayClaims(event) {
		if (this.selectedUserList.length <= 0) return;

		return html`
			<rps-card>
				<div slot="header">
					Set users rights (${this.selectedUsers()})
				</div>
				<h3 class="card-title">${this.title}</h3>
				<p class="card-text">
					<div class="update-user-container">
						<rps-search
							mode="multi"
							displayItems=["text"]
							.items=${this.claims}
							.preSelectItems=${this.existingClaims}
							.includeCss="${this.includeCss}"
							@items-selected=${this._claimsSelected}
						>
						</rps-search>
					</div>
				</p>
			</rps-card>
		`;
	}

	render() {
		return html`
		<div>
			<rps-card header="Invite user's">
				<h3 class="card-title">Select User's to invite</h3>
				<p class="card-text">
					<rps-search mode="multi"
						placeholder="${this.placeholder}" .includeCss="${this.includeCss}"
						.items=${this.users}
						.displayItems=${this.userDisplayFields}
						@items-selected=${this._usersSelected}
					>
					</rps-search>
				</p>
			</rps-card>
			${this._displayClaims()}
		</div>
    `;
	}
}
