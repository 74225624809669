import { html } from 'lit';
import { unsafeSVG } from 'lit/directives/unsafe-svg.js';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { iconNames, getIcon } from '../../svg-icons';
import { styles } from './css/svgIcon.css.js';
export class SvgIcon extends CustomLitElement {

	static styles = styles;

	static get properties() {
		return {
			svg: { type: String },
		};
	}

	constructor() {
		super();
		this.svg = iconNames.add;
	}

	render() {
		let svgData = getIcon(this.svg);
		if (!svgData) {
			svgData = getIcon(iconNames.error);
		}

		svgData = svgData.replace('<svg ', `<svg class="${this.className}" `)


		return html`
			${unsafeSVG(svgData)}
		`;
	}

}

