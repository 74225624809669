/**
 * Copied directly from the website project.
 */

export const chunkArray = <T>(array: T[], size: number): T[][] => {
  const chunked: T[][] = [];
  let index = 0;
  while (index < array.length) {
    chunked.push(array.slice(index, size + index));
    index += size;
  }
  return chunked;
};

export const splitArray = <T>(
  array: T[],
  condition: (item: T, idx: number) => boolean
) => {
  const { trueHalf, falseHalf } = array.reduce(
    (result, item, idx) => {
      condition(item, idx)
        ? result.trueHalf.push(item)
        : result.falseHalf.push(item);
      return result;
    },
    { trueHalf: [] as T[], falseHalf: [] as T[] }
  );
  return [trueHalf, falseHalf];
};

/**
 * Slightly reworked version of @rps/js-utils/array
 */
export const range = (start: number, end: number, step = 1): number[] => {
  if (start >= end) {
    return [];
  } else if (step < 1) {
    return [];
  }

  let range: number[] = [];

  let reverse = false;
  if (end < start) {
    [start, end] = [end, start];
    reverse = true;
  }

  if (step === 1) {
    end += 1; // range(5, 10) results in [5, 6, 7, 8, 9] add an extra number so the end number gets added as well
    range = Array.from(
      { length: Math.floor((end - start) / step) },
      (_, k) => (k + start) * step
    );
  } else {
    for (let i = start; i <= end; i += step) {
      range.push(i);
    }
  }

  if (reverse) {
    range.reverse();
  }

  return range;
};

export const randomItem = <T>(array: T[]): T =>
  array[Math.floor(Math.random() * array.length)];

interface SwapOrShiftProps<T> {
  list: T[];
  from: number;
  to: number;
  getPosition: (item: T) => number;
  setPosition: (item: T, position: number) => void;
}

export const shiftItem = <T>({
  list,
  from: fromIdx,
  to: toIdx,
  placement,
  getPosition,
  setPosition,
}: SwapOrShiftProps<T> & { placement?: 'before' | 'after' }) => {
  if (fromIdx === toIdx) return;

  const itemsToShift: T[] = [];
  if (fromIdx < toIdx) {
    // moving down
    itemsToShift.push(
      ...list.slice(fromIdx, toIdx + (placement === 'before' ? 0 : 1))
    );
  } else {
    // moving up (reverse)
    itemsToShift.push(
      ...list
        .slice(toIdx + (placement === 'after' ? 1 : 0), fromIdx + 1)
        .reverse()
    );
  }

  const to = itemsToShift.at(-1);
  const [from, ...middle] = itemsToShift;

  // ensure we have all the items needed and we aren't wasting any more time
  if (!from || !to || from === to || getPosition(from) === getPosition(to)) {
    return;
  }

  // store the from position for use in the loop, and then give it the to position
  let curr = getPosition(from);
  setPosition(from, getPosition(to));

  middle.forEach(item => {
    const next = curr++;
    curr = getPosition(item);
    setPosition(item, next);
  });
};

export const swapItem = <T>({
  list,
  from,
  to,
  getPosition,
  setPosition,
}: SwapOrShiftProps<T>) => {
  const a = list.at(from);
  const b = list.at(to);

  if (!a || !b || a === b || getPosition(a) === getPosition(b)) return;

  // swap a & b positions
  const hold = getPosition(a);
  setPosition(a, getPosition(b));
  setPosition(b, hold);
};
