import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { useCurrentDealSource } from '../../../hooks/useCurrentDealSource';
import {
  RPSCard,
  RPSButtonStrip,
  RPSCheckbox,
} from '@rps/web-components/build/react-wrappers';
import { Deal } from 'models/Deal.jsx';
import { hasCustomOptionsButtons } from './data';
import { calculateProfit } from 'utils/calculator/priceCalculator';
import { DealPriceAndCustomOptions } from 'models/PriceAndCustomOptions.jsx';
import { DealShipping } from 'models/Shipping.jsx';
import { getAdminCostValue } from 'utils/getAdminCostValue';
import Divider from '@odo/components/elements/divider';
import { useAttributeOptions } from '@odo/hooks/attributes';
import { AttributeCode } from '@odo/types/api';
import {
  getSurchargeTotal,
  SURCHARGE_WEIGHT_KEY,
} from 'components/deals/DealSideEffects';
import {
  isCurrency,
  isCurrencyOnChange,
  isPositiveCurrencyOnChange,
  isPositiveNumeric,
} from '@odo/utils/validation.ts';
import PropTypes from 'prop-types';
import {
  InputCurrency,
  InputNumeric,
  Select,
} from '@odo/components/elements/form-fields';
import { handleODOInput } from 'utils/odo-migration/forms';
import { useProductContext } from '@odo/contexts/product';
import { Flex, Grid } from '@odo/components/elements/layout';
import Tooltip from '@odo/components/widgets/tooltip';
import { cssColor } from '@odo/utils/css-color';
import { FiAlertCircle } from 'react-icons/fi';

const SURCHARGE_INSURANCE = 'INSURANCE';

const SurchargeInput = ({
  value: valueProp,
  surchargeType,
  surcharges,
  deal,
  update,
  ...rest
}) => {
  const [value, setValue] = useState(valueProp);

  const validation = useMemo(
    () =>
      (surchargeType.metadata || []).find(({ key }) => key === 'validation')
        ?.value || '',
    [surchargeType]
  );

  const onBlur = useCallback(
    e => {
      const surchargeField = {
        key: surchargeType.key,
        value: '',
      };

      const value = e.target.value;
      if (isCurrency(value)) {
        let formattedVal = value;
        if (validation === '-') {
          formattedVal = Math.abs(value) * -1;
        } else if (validation === '+') {
          formattedVal = Math.abs(value);
        }
        surchargeField.value = parseFloat(formattedVal).toFixed(2);
      }

      setValue(surchargeField.value);

      const allSurcharges = [
        ...(surcharges || []).filter(s => s.key !== surchargeType.key),
        surchargeField,
      ];

      const total = getSurchargeTotal(allSurcharges);

      deal.set(
        Deal.MODELS.PRICE_AND_CUSTOM_OPTIONS,
        DealPriceAndCustomOptions.FIELDS.SURCHARGES,
        allSurcharges
      );
      deal.set(
        Deal.MODELS.PRICE_AND_CUSTOM_OPTIONS,
        DealPriceAndCustomOptions.FIELDS.SURCHARGE,
        parseFloat(total).toFixed(2)
      );

      update();
    },
    [surchargeType.key, surcharges, deal, update, validation]
  );

  /**
   * NOTE: we just need this to sync with the model state.
   * Not ideal, but will avoid in the deal editor refactor.
   */
  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  return (
    <InputCurrency
      {...rest}
      value={value}
      onChange={e =>
        isCurrencyOnChange(e.target.value) && setValue(e.target.value)
      }
      onBlur={onBlur}
    />
  );
};

SurchargeInput.propTypes = {
  value: PropTypes.any,
  surchargeType: PropTypes.any,
  model: PropTypes.any,
  deal: PropTypes.any,
  update: PropTypes.func,
  rest: PropTypes.any,
};

export const PriceSection = () => {
  const { setActiveProduct } = useProductContext();

  const currentDeal = useCurrentDealSource();

  const model = currentDeal.deal.priceAndCustomOptions;
  const shippingModel = currentDeal.deal.shipping;

  const surchargeTypeOptions = useAttributeOptions(AttributeCode.surcharges);
  const adminCostOptions = useAttributeOptions(AttributeCode.adminCost);

  const handleInput = currentDeal.createInputHandler(
    Deal.MODELS.PRICE_AND_CUSTOM_OPTIONS
  );

  const handleInsurance = useCallback(
    autoCalcInsurance => {
      /**
       * NOTE: it's inefficient to recalculate all of this when the insurance calculation is so simple.
       * When we refactor away from RP deal editor we can do this better.
       */
      let adminCost = getAdminCostValue(model.adminCost);

      const profit = calculateProfit({
        price: +model.price,
        retail: +model.retail,
        cost: +model.cost,
        vat: true,
        insurance: autoCalcInsurance,
        rebate: +model.rebateDiscount,
        admin: adminCost,
        surcharge: +model.surcharge,
        soh: +shippingModel.originalStock,
      });

      const nextSurcharges = [
        ...(currentDeal.deal.priceAndCustomOptions.surcharges || []).filter(
          s => s.key !== SURCHARGE_INSURANCE
        ),
        {
          key: SURCHARGE_INSURANCE,
          value:
            typeof profit.insurance === 'number'
              ? profit.insurance.toFixed(2)
              : profit.insurance,
        },
      ];

      const total = getSurchargeTotal(nextSurcharges);

      currentDeal.deal.set(
        Deal.MODELS.PRICE_AND_CUSTOM_OPTIONS,
        DealPriceAndCustomOptions.FIELDS.SURCHARGES,
        nextSurcharges
      );
      currentDeal.deal.set(
        Deal.MODELS.PRICE_AND_CUSTOM_OPTIONS,
        DealPriceAndCustomOptions.FIELDS.SURCHARGE,
        parseFloat(total).toFixed(2)
      );
      currentDeal.update();
    },
    [
      model.adminCost,
      model.price,
      model.retail,
      model.cost,
      model.rebateDiscount,
      model.surcharge,
      shippingModel.originalStock,
      currentDeal,
    ]
  );

  const calcResult = useMemo(() => {
    let adminCost = getAdminCostValue(model.adminCost);
    const res = calculateProfit({
      price: +model.price,
      retail: +model.retail,
      cost: +model.cost,
      vat: true,
      insurance: model.insurance,
      rebate: +model.rebateDiscount,
      admin: adminCost,
      surcharge: +model.surcharge,
      soh: +shippingModel.originalStock,
    });
    return res;
  }, [
    model.adminCost,
    model.cost,
    model.insurance,
    model.price,
    model.rebateDiscount,
    model.retail,
    model.surcharge,
    shippingModel.originalStock,
  ]);

  /**
   * NOTE: this syncs price updates into the custom options context for showing sale prices.
   */
  useEffect(() => {
    setActiveProduct(activeProduct => ({
      ...activeProduct,
      product: {
        ...(activeProduct?.product ? activeProduct.product : {}),
        price: parseFloat(model.price),
      },
    }));
  }, [model.price, setActiveProduct]);

  // Saving In Rands/Percentages
  const savingsPercent = (price, retail) => (1 - price / retail) * 100;
  const savingsRands = (price, retail) => (retail - price).toPrecision(2);

  const formatter = new Intl.NumberFormat({ currency: 'ZAR' });
  const savingsOptionsButtons = [
    {
      id: 'rands',
      text: `SAVE R${formatter.format(
        savingsRands(model.price, model.retail)
      )}`,
    },
    {
      id: 'percentage',
      text: `SAVE ${Math.round(savingsPercent(model.price, model.retail))}%`,
    },
  ];

  const handleSelectSavingsStatus = ev => {
    currentDeal.deal.set(
      Deal.MODELS.PRICE_AND_CUSTOM_OPTIONS,
      DealPriceAndCustomOptions.FIELDS.IS_SAVINGS_IN_RANDS,
      ev.detail.buttonId === 'rands' ? true : false
    );
    currentDeal.update();
  };

  return (
    <RPSCard>
      <div slot="header">
        <h5>Price Options</h5>
      </div>
      <div style={{ display: 'none' }}>
        <RPSButtonStrip
          text="Custom Options"
          cbClick={handleInput(
            DealPriceAndCustomOptions.FIELDS.HAS_CUSTOM_OPTIONS
          )}
          buttons={hasCustomOptionsButtons}
          selected={model.hasCustomOptions}
        />
      </div>

      <Grid gap="15px" gridTemplateColumns="1fr 1fr 1fr" alignItems="end">
        <InputCurrency
          label="Cost"
          value={model.cost}
          onChange={e =>
            isPositiveCurrencyOnChange(e.target.value) &&
            handleODOInput({
              currentDeal,
              model: Deal.MODELS.PRICE_AND_CUSTOM_OPTIONS,
              field: DealPriceAndCustomOptions.FIELDS.COST,
            })(e)
          }
          required
          selectOnFocus
          matchRPStyles
        />

        <InputCurrency
          label="Price"
          value={model.price}
          onChange={e =>
            isPositiveCurrencyOnChange(e.target.value) &&
            handleODOInput({
              currentDeal,
              model: Deal.MODELS.PRICE_AND_CUSTOM_OPTIONS,
              field: DealPriceAndCustomOptions.FIELDS.PRICE,
            })(e)
          }
          required
          selectOnFocus
          matchRPStyles
        />

        <InputCurrency
          label="Retail"
          value={model.retail}
          onChange={e =>
            isPositiveCurrencyOnChange(e.target.value) &&
            handleODOInput({
              currentDeal,
              model: Deal.MODELS.PRICE_AND_CUSTOM_OPTIONS,
              field: DealPriceAndCustomOptions.FIELDS.RETAIL,
            })(e)
          }
          selectOnFocus
          matchRPStyles
        />

        <InputNumeric
          label="Original Stock"
          value={shippingModel.originalStock}
          onChange={e =>
            isPositiveNumeric(e.target.value) &&
            handleODOInput({
              currentDeal,
              model: Deal.MODELS.SHIPPING,
              field: DealShipping.FIELDS.ORIGINAL_STOCK,
            })(e)
          }
          selectOnFocus
          matchRPStyles
        />

        <InputNumeric
          label="Rebate (%)"
          value={model.rebateDiscount}
          onChange={handleODOInput({
            currentDeal,
            model: Deal.MODELS.PRICE_AND_CUSTOM_OPTIONS,
            field: DealPriceAndCustomOptions.FIELDS.REBATE,
          })}
          disabled
          matchRPStyles
        />
      </Grid>

      <Divider text="SURCHARGES" rounded />

      <Flex
        justifyContent="start"
        alignItems="center"
        gap={1}
        style={{
          position: 'relative',
        }}
      >
        <RPSCheckbox
          label="Auto Calculator Insurance"
          checked={!!model.insurance}
          cbInput={e => {
            handleInsurance(e.detail.checked);
            handleInput(DealPriceAndCustomOptions.FIELDS.INSURANCE)(e);
          }}
        />
        <Tooltip
          maxWidth={420}
          showDelay={500}
          hideDelay={100}
          content={() =>
            'Calculate Insurance based on Cost Price. Re-tick to recalculate. Apply the Insurance manually by un-ticking the field'
          }
          color={cssColor('palette-blue')}
          placement="right"
        >
          <FiAlertCircle size="2rem" color={cssColor('palette-blue')} />
        </Tooltip>
      </Flex>
      <Grid gap="15px" gridTemplateColumns="1fr 1fr" alignItems="end">
        {surchargeTypeOptions.map(surchargeType => (
          <SurchargeInput
            key={surchargeType.value}
            // NOTE: we automatically calculate this value in DealSideEffects.js
            disabled={
              surchargeType.value === SURCHARGE_WEIGHT_KEY ||
              (surchargeType.value === SURCHARGE_INSURANCE && model.insurance)
            }
            label={surchargeType.originalData.value}
            value={
              (model.surcharges || []).find(s => s.key === surchargeType.value)
                ?.value || ''
            }
            surchargeType={surchargeType}
            surcharges={model.surcharges}
            deal={currentDeal.deal}
            update={currentDeal.update}
            selectOnFocus
            matchRPStyles
          />
        ))}

        <InputCurrency
          label="Surcharge (TOTAL)"
          value={
            typeof model.surcharge === 'number'
              ? model.surcharge.toFixed(2)
              : model.surcharge
          }
          readOnly
          selectOnFocus
          matchRPStyles
          disabled
        />
      </Grid>

      <Divider text="SURCHARGES - END" rounded />

      <Select
        label="Admin Cost"
        value={model.adminCost}
        onChange={handleODOInput({
          currentDeal,
          model: Deal.MODELS.PRICE_AND_CUSTOM_OPTIONS,
          field: DealPriceAndCustomOptions.FIELDS.ADMIN,
        })}
        options={[
          { id: '', value: '', label: 'Please select...' },
          ...adminCostOptions.map(option => ({
            id: option.value,
            value: option.value,
            label: option.label,
          })),
        ]}
        matchRPStyles
      />

      <RPSCheckbox
        label="VAT Included"
        readonly
        disabled
        checked={true}
        cbInput={handleInput(DealPriceAndCustomOptions.FIELDS.VAT)}
      />

      <Flex
        flexDirection="column"
        justifyContent="start"
        gap={2}
        style={{
          width: 'fit-content',
        }}
      >
        <RPSCheckbox
          checked={model.isDisplayRetail}
          cbInput={handleInput(
            DealPriceAndCustomOptions.FIELDS.IS_DISPLAY_RETAIL
          )}
          label="Show Retail Price on deal?"
        />
      </Flex>

      <RPSButtonStrip
        text="Customer Savings in Rands/Percentage"
        cbClick={handleSelectSavingsStatus}
        buttons={savingsOptionsButtons}
        selected={
          typeof model.isSavingsInRands === 'undefined'
            ? undefined
            : model.isSavingsInRands
            ? 'rands'
            : 'percentage'
        }
      />

      <InputCurrency
        label="Profit Potential"
        value={calcResult.profits.potential}
        readOnly
        withPrefix
        matchRPStyles
      />
    </RPSCard>
  );
};
