import React from 'react';
import { useCurrentDealSource } from 'hooks/useCurrentDealSource';
import { Deal } from 'models/Deal.jsx';
import { RPSCard, RPSCheckbox } from '@rps/web-components/build/react-wrappers';
import { DealShipping } from 'models/Shipping.jsx';
import { Input, Select } from '@odo/components/elements/form-fields';
import { handleODOInput } from 'utils/odo-migration/forms';
import { isNumericOnChange, isPositiveNumeric } from './validation'; // Regex validation for RP

const stockStatusItems = [
  { id: 'no-stock', value: 'no-stock', label: 'Out of Stock' },
  { id: 'in-stock', value: 'in-stock', label: 'In Stock' },
];

export const InventorySection = () => {
  const currentDeal = useCurrentDealSource();
  const handleInput = currentDeal.createInputHandler(Deal.MODELS.SHIPPING);
  const model = currentDeal.deal.shipping;

  const handleSelectStockStatus = ev => {
    if (ev.target.value === 'in-stock') {
      currentDeal.deal.set(
        Deal.MODELS.SHIPPING,
        DealShipping.FIELDS.AVAILABLE,
        true
      );
    } else {
      currentDeal.deal.set(
        Deal.MODELS.SHIPPING,
        DealShipping.FIELDS.AVAILABLE,
        false
      );
    }
    currentDeal.update();
  };

  return (
    <RPSCard>
      <h5>Inventory</h5>

      <Select
        label="Inventory Status"
        value={model.isInStock ? 'in-stock' : 'no-stock'}
        onChange={handleSelectStockStatus}
        options={stockStatusItems}
        matchRPStyles
      />

      <Input
        label="Original Stock"
        value={model.originalStock}
        onChange={e =>
          isPositiveNumeric(e.target.value) &&
          handleODOInput({
            currentDeal,
            model: Deal.MODELS.SHIPPING,
            field: DealShipping.FIELDS.ORIGINAL_STOCK,
          })(e)
        }
        inputMode="numeric"
        min={0}
        max={Number.MAX_SAFE_INTEGER}
        selectOnFocus
        matchRPStyles
      />

      <Input
        label="Quantity Available"
        value={model.qty}
        onChange={e =>
          isNumericOnChange(e.target.value) &&
          handleODOInput({
            currentDeal,
            model: Deal.MODELS.SHIPPING,
            field: DealShipping.FIELDS.X_QUANTITY_LEFT,
          })(e)
        }
        max={Number.MAX_SAFE_INTEGER}
        selectOnFocus
        matchRPStyles
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          gap: '2rem',
          width: 'fit-content',
        }}
      >
        <RPSCheckbox
          label="Use configured minimum quantity in cart?"
          checked={model.useConfigMinSaleQty}
          cbInput={handleInput(DealShipping.FIELDS.USE_CONFIG_MIN_QTY_CART)}
        />
      </div>
      {!model.useConfigMinSaleQty && (
        <Input
          label="Min Quantity In Cart"
          value={model.minSaleQuantity}
          onChange={handleODOInput({
            currentDeal,
            model: Deal.MODELS.SHIPPING,
            field: DealShipping.FIELDS.MIN_QTY_IN_SHOPPING_CART,
          })}
          disabled={model.useConfigMinSaleQty}
          inputMode="numeric"
          min={0}
          max={Number.MAX_SAFE_INTEGER}
          selectOnFocus
          matchRPStyles
        />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          gap: '2rem',
          width: 'fit-content',
        }}
      >
        <RPSCheckbox
          label="Use configured maximum quantity in cart?"
          checked={model.useConfigMaxSaleQty}
          cbInput={handleInput(DealShipping.FIELDS.USE_CONFIG_MAX_QTY_CART)}
        />
      </div>
      {!model.useConfigMaxSaleQty && (
        <Input
          label="Max Quantity In Cart"
          value={model.maximumSaleQuantity}
          onChange={handleODOInput({
            currentDeal,
            model: Deal.MODELS.SHIPPING,
            field: DealShipping.FIELDS.MAX_QTY_IN_SHOPPING_CART,
          })}
          disabled={model.useConfigMaxSaleQty}
          inputMode="numeric"
          min={0}
          max={Number.MAX_SAFE_INTEGER}
          selectOnFocus
          matchRPStyles
        />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          gap: '2rem',
          width: 'fit-content',
        }}
      >
        <RPSCheckbox
          label="Apply maximum sale quantity to product options?"
          checked={model.isApplyMaxSaleQtyToProductOptions}
          cbInput={handleInput(
            DealShipping.FIELDS.IS_APPLY_MAX_SALE_QTY_TO_PRODUCT_OPTIONS
          )}
        />
        <RPSCheckbox
          label="Apply maximum sale quantity to customer profile?"
          checked={model.isApplyMaxSaleQtyCustomerProfile}
          cbInput={handleInput(
            DealShipping.FIELDS.IS_APPLY_MAX_SALE_QTY_TO_CUSTOMER_PROFILE
          )}
        />
      </div>
    </RPSCard>
  );
};
