/**
 * @name rps-toolbar
 * @type {Toolbar}
 * A control that displays many buttons as a single contrtol
 * 
 * 
 * @attributes + @properties 				| Changes trigger a re-render
 * Casing usages:								| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {Array } buttons			| A list of buttons to create
 * 													Default is [
 * 														{ id: "search", svg: iconNames.search, title: "Search"},
 * 													];
 * 	@property {String } customCss		| Specify a custom .css file to include, use cssPath to override elements,
 * 													DO NOT override the styling definition by re-defining the css styling
 * 													Put this file into the *window.cssPath* folder @see {link README.md}
 * 													eg: /public/css (where the other css files are)
 * 													Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css			| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 
 * @methods
 * 	None
 * 
 * @events
 * 	click										| Button click event
 * 
 * @callbacks
 * 	cbClick									| Click event callback
 * 
 * @slots
 * 	None
 * 
 * 
 * @example {lit}
 * BASIC
 * =====
 * 	import '@rps-wc/rps-buttons-strip';
 * 
 * 	<rps-buttons-strip @click=${e => console.log(e)}>
 * 	</rps-buttons-strip>
 * 
 * @example {ReactJs}
 * BASIC
 * =====
 * 	import '@rps-wc/rps-buttons-strip';
 * 
 * 	useEffect(() => {
 * 		strip.current.cbClick= {e => console.log(e)}
 * 	}, [])
 * 
 * 	return (
 * 		<rps-buttons-strip ref={strip}>
 * 		</rps-buttons-strip>
 * 	)
 * 
 * 
*/
import { Toolbar } from './src/buttons/Toolbar.js';
if (!window.customElements.get('rps-toolbar'))
	window.customElements.define('rps-toolbar', Toolbar);

