/**
 * @name rps-bread-crumb
 * @type {BreadCrumb}
 * A bread crumb control representing 
 * 
 * 
 * 
 * @attributes + @properties 				| Changes trigger a re-render
 * Casing usages:								| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String} separator		| svg to use as a separator between the links
 * 	@property {Array} pages				|  A custom OPTIONAL list of html/icons to display for each page
 * 													Default = | 
 * 														{ text: "Add Customer", href: "/customer/1", id: 1 },
 * 														{ text: "Edit John", href: "/customer/1/edit", id: 2 },
 * 														{ text: "Address", href: "/customer/1/edit/address", id: 3 },
 * 													;
 * 												
 * 	@property {String} customCss		| Specify a custom .css file to include, use cssPath to override elements,
 * 													DO NOT override the styling definition by re-defining the css styling
 * 													Put this file into the *window.cssPath* folder @see {link README.md}
 * 													eg: /public/css (where the other css files are)
 * 													Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css			| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 												
 * 
 * @methods
 * 	setPages(newList)						| replace the pages with this new list of pages
 * 													eg: document.querySelector('rps-bread-crumb').setPages(| 
 * 														{ text: "New link1", href: "/newlink/1", id: 1 },
 * 														{ text: "New link2", href: "/newlink/2", id: 2 },
 * 														{ text: "New link3", href: "/newlink/3", id: 3 },
 * 														{ text: "New link4", href: "/newlink/4", id: 4 },
 * 													);
 * 												
 * 	push(page)								| Push a new page to the bread-crumb
 * 													eg: document.querySelector('rps-bread-crumb').push(
 * 														{ text: "New link", href: "/newlink/", id: 5 }
 * 													);
 * 												
 * 	pop()										| remove the last page from te list
 * 													eg: document.querySelector('rps-bread-crumb').pop();
 * 												
 * 
 * @events
 * 	click										| Fires when the user clicks on a link
 * 
 * @callbacks
 * 	cbClick									| Callback when the user clicks on a link
 * 
 * SLOTS:
 * 	None
 * 
 * @example+ @Lit +@basic
 * import '@rps-wc/rps-bread-crumb';
 * 
 * 	<rps-bread-crumb @click={e => console.log(e)}><rps-bread-crumb>
 * 
 * 
 * @example+ @Lit +@advanced
 * import '@rps-wc/rps-bread-crumb';
 * import { iconNames } from '@rps-wc/svg-icons';
 * 
 * const pages = [
 * 		{ text: "Add Customer", href: "/customer/1", id: 1 },
 * 		{ text: "Edit John", href: "/customer/1/edit", id: 2 },
 * 		{ text: "Address", href: "/customer/1/edit/address", id: 3 },
 * 	];
 *  
 * 	<rps-bread-crumb 
 * 		pages=${JSON.stringify(pages)} 
 * 		separator=${iconNames.angleLeft}
 * 		@click=${e => console.log(e)}>
 * 	<rps-bread-crumb>
 * 
 * 
 * @example+ @React +@basic
 * import '@rps-wc/rps-bread-crumb';
 * 
 * 	<rps-bread-crumb onClick={e => console.log(e)}><rps-bread-crumb>
 * 
 * @example+ @React +@advanced
 * import '@rps-wc/rps-bread-crumb';
 * import { iconNames } from '@rps-wc/svg-icons';
 * 
 * const pages = [
 * 		{ text: "Add Customer", href: "/customer/1", id: 1 },
 * 		{ text: "Edit John", href: "/customer/1/edit", id: 2 },
 * 		{ text: "Address", href: "/customer/1/edit/address", id: 3 },
 * 	];
 * 
 * 	useEffect(() => {
 * 		crumb.current.cbClick= {e => console.log(e)}
 * 	}, [])
 * 
 *  
 * 	return (<rps-bread-crumb 
 * 			pages={JSON.stringify(pages)} 
 * 			separator={iconNames.angleLeft}
 * 			ref={crumb}>
 * 		<rps-bread-crumb>
 * 	)
 * 
 * 
 */
import { BreadCrumb } from './src/navigation/BreadCrumb.js';
if (!window.customElements.get('rps-bread-crumb'))
	window.customElements.define('rps-bread-crumb', BreadCrumb);

