import type { EditorProductInterface } from '@odo/types/portal';
import { SkuAvailability, SizeInfoImageKey } from '@odo/types/portal';
import { produce } from 'immer';
import {
  excludedBuyers,
  removedItems as removedBuyerOptions,
} from '@odo/constants/buyers';
import type { Attribute } from '@odo/contexts/attributes';
import {
  getSkuBrandPrefix,
  incrementSku,
} from '@odo/data/product/duplication/helpers';
import draftDefaults from '@odo/data/product/draft/defaults';
import { isEditorCategoryBreadcrumb } from '@odo/types/guards';

const duplicateAsRepeat = ({
  product,
  attributes,
  asTemplate,
}: {
  product: EditorProductInterface;
  attributes: Attribute[];
  /**
   * NOTE: we're temporarily making templates ALMOST the same as repeats.
   * They're gonna have some really basic differences but are otherwise the same.
   * So instead of duplicateAsTemplate we'll do them here with a flag.
   * TODO: BP-866: remove this flag and it's associated code
   */
  asTemplate?: boolean;
}) =>
  produce(product, draft => {
    draftDefaults(draft, attributes);

    // we delete the ID when setting our defaults draft metadata, but for redundancy we'll delete it again here
    delete draft.id;

    delete draft.skuAvailability;
    if (draft.sku) {
      draft.sku = incrementSku(draft.sku);
    }

    if (asTemplate) {
      draft.sku = draft.sku ? getSkuBrandPrefix(draft.sku) : undefined;
      draft.skuAvailability = SkuAvailability.isTemplate;
      delete draft.supplierRepacks;
    }

    draft.status = false;
    draft.isPreviewOnly = false;
    draft.isTimedDeal = false;
    draft.isSupplierNew = false;
    draft.isLunchtimeProduct = false;
    draft.isBestSeller = false;
    draft.isMainDeal = false;

    delete draft.url;
    delete draft.activeFromDate;
    delete draft.activeToDate;
    delete draft.activeFromTime;
    delete draft.activeToTime;
    delete draft.campaign;
    delete draft.buyInStockType;
    delete draft.isSampleReceived;
    delete draft.isPhotographedByStudio;

    delete draft.salesAssistant;
    if (
      [...removedBuyerOptions, ...excludedBuyers].includes(
        draft.buyer?.id || ''
      )
    ) {
      delete draft.buyer;
    }

    draft.campaignMailer = [];
    draft.shops = [];

    // remove all "other" categories eg. "recurring deals" or the parent "permanent shops" category
    draft.categories = (draft.categories || []).filter(
      category =>
        isEditorCategoryBreadcrumb(category) ||
        category.breadcrumb?.type !== 'other'
    );

    // clear XTD flags and permanent shop
    draft.isXTD = false;
    draft.permanentShops = (draft.permanentShops || []).filter(
      shop =>
        isEditorCategoryBreadcrumb(shop) ||
        !shop.breadcrumb?.name ||
        // although the category is currently called "extra time deals"
        // it's safer to add just a little bit extra safety here
        !['extra time deals', 'extra time'].includes(
          shop.breadcrumb.name.toLowerCase()
        )
    );

    // only keep "supplier voucher" & "gift voucher" deal types
    draft.dealType = (draft.dealType || []).filter(dealType =>
      ['supplier_voucher', 'gift_voucher'].includes(dealType.id.toLowerCase())
    );

    // basic inventory resets
    draft.inventory = draft.inventory
      ? {
          ...draft.inventory,
          isInStock: false,
          qty: { string: '0', number: 0 },
          minSaleQuantity: { string: '0', number: 0 },
        }
      : undefined;

    /**
     * NOTE: it seems that when creating a product with an image that has the "sizing guide" label,
     * that image will automatically be converted by MAG into the size info desktop image.
     * Even if there already is a desktop image.
     *
     * For the purposes of the BP editor, we need to show that this will be the result without messing with the data.
     * So we're gonna have a custom flag for "hiding" the image in the images section,
     * and put its url on the size info desktop image so that it's shown there.
     */
    delete draft.sizeInfo.id;
    const sizingGuideImage = (draft.images || []).find(
      ({ label }) => label && label.toUpperCase().trim() === 'SIZING GUIDE'
    );
    if (sizingGuideImage) {
      sizingGuideImage.imageTypes = [];
      sizingGuideImage.willBeConvertedToSizeInfo = true;
      draft.sizeInfo = {
        ...draft.sizeInfo,
        desktop: {
          id: SizeInfoImageKey.desktop,
          label: 'Desktop',
          url: sizingGuideImage.url,
          willBeConvertedFromImage: true,
        },
      };
    }
  });

export default duplicateAsRepeat;
