import React from 'react';
import PropTypes from 'prop-types';
import {
  RPSListContainer,
  RPSCard,
} from '@rps/web-components/build/react-wrappers';
import { iconNames } from '@rps/web-components/build/web-components';
import { useCurrentDealSource } from '../../hooks/useCurrentDealSource';

export const ErrorCard = ({ model = '' }) => {
  const currentDeal = useCurrentDealSource();
  const errors =
    (currentDeal.deal?.getErrors && currentDeal.deal.getErrors(model)) || {};
  const hasErrors =
    errors?.required?.length > 0 || errors?.optional?.length > 0;
  if (!hasErrors) {
    return <></>;
  }

  return (
    <RPSCard
      css={`
        :host {
          width: 100%;
          display: ${hasErrors ? 'unset' : 'none'};
        }
      `}
    >
      <div slot="header">
        <h6>Some Fields Require Attention</h6>
      </div>

      <div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
        {errors?.required.length > 0 && (
          <RPSListContainer className="vertical">
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <rps-svg class="error" svg={iconNames.error} />
              <strong>Required</strong>
              <ul>
                {errors.required.map((error, i) => (
                  <li key={i}>{error}</li>
                ))}
              </ul>
            </div>
          </RPSListContainer>
        )}

        {errors?.optional.length > 0 && (
          <RPSListContainer className="vertical">
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <rps-svg class="warning" svg={iconNames.warning} />
              <strong>Optional</strong>
              <ul>
                {errors.optional.map((error, i) => (
                  <li key={i}>{error}</li>
                ))}
              </ul>
            </div>
          </RPSListContainer>
        )}
      </div>
    </RPSCard>
  );
};

ErrorCard.propTypes = {
  model: PropTypes.string,
};
