import React, { useState, useContext } from 'react';
import { ModalContext } from '../../contexts/Modal';
import PropTypes from 'prop-types';
import { RPSButton, RPSModal } from '@rps/web-components/build/react-wrappers';
import { useCurrentDealSource } from '../../hooks/useCurrentDealSource';
import { iconNames } from '@rps/web-components/build/web-components';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './css/BusySpinner.module.css';
import { useCustomOptionsEditorContext } from '@odo/contexts/custom-options-editor';
import { saveChanges } from 'utils/odo-migration/save';
import styled from '@odo/lib/styled';
import { cssColor } from '@odo/utils/css-color';

export const DealUndoToolbar = ({ model }) => {
  const currentDeal = useCurrentDealSource();
  const navigate = useNavigate();
  const location = useLocation();

  const modal = useContext(ModalContext);
  const [showModal, setShowModal] = useState(false);

  const {
    canSave: canSaveCustomOptions,
    validate,
    saveActions,
  } = useCustomOptionsEditorContext();

  const reset = async () => {
    currentDeal.deal.undoAll(model);
    await currentDeal.update({ immediateCache: true });
    navigate(0); // Reloads current page - this is to force re-rendering of some components.
  };

  const handleSaveChanges = async () => {
    saveChanges({
      currentDeal,
      canSaveCustomOptions,
      validate,
      saveActions,
      modal,
      navigate,
      location,
    });
  };

  const showSaveModal = () => {
    modal.open(
      <RPSModal
        popup
        opened
        cbConfirm={handleSaveChanges}
        cbCancel={modal.close}
      >
        Upload this deal to Magento?
        {currentDeal.deal.hasSalesHistory && (
          <Warning>Please note: This deal contains sales history</Warning>
        )}
      </RPSModal>
    );
  };

  const isActive = currentDeal.deal?.canUndo && currentDeal.deal.canUndo(model);
  const canSave =
    currentDeal.deal?.getValidity?.status !== '' || canSaveCustomOptions;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '2rem',
        width: '100%',
        padding: '1rem',
        background: 'white',
        borderRadius: 'var(--border-radius-l)',
        boxShadow: 'var(--box-shadow-l)',
      }}
    >
      <RPSButton disabled={!canSave} cbClick={showSaveModal}>
        SAVE CHANGES
        <div slot="slot-left">
          <rps-svg svg={iconNames.uploadOutline} />
        </div>
      </RPSButton>

      <RPSButton
        disabled={isActive === false}
        className="warning"
        cbClick={() => setShowModal(true)}
      >
        RESET CHANGES
        <div slot="slot-left">
          <rps-svg svg={iconNames.undo} />
        </div>
      </RPSButton>

      {currentDeal.deal?.canUndo && currentDeal.deal.canUndo() && (
        <em style={{ paddingLeft: '2rem' }}>
          *You have unsaved changes to this deal
        </em>
      )}

      <RPSModal
        opened={showModal}
        popup
        cbCancel={() => {
          setShowModal(false);
        }}
        cbConfirm={() => {
          reset();
          setShowModal(false);
        }}
      >
        Are you sure you want to revert all changes to this page?
      </RPSModal>

      {currentDeal.busyUploading && (
        <div className={styles.busySpinnerContainer}>
          <rps-card>
            <div className={styles.busySpinner}>
              <rps-spinner-pause />
              Uploading...
            </div>
          </rps-card>
        </div>
      )}

      {!!currentDeal.deal.product.xtdDaysConfirmed && (
        <div style={{ marginLeft: 'auto' }}>
          <label className={styles.xtdLabel}>XTD</label>
        </div>
      )}
    </div>
  );
};

const Warning = styled.strong`
  font-weight: 800;
  color: ${cssColor('palette-pink')};
`;

DealUndoToolbar.propTypes = {
  model: PropTypes.string,
};
