import { useParams } from 'react-router-dom';

const TMP_ID_PREFIX = 'tmp-';

const isTmpDealId = (dealId: string) => dealId.startsWith(TMP_ID_PREFIX);

const useDealId = () => {
  const { dealId } = useParams();

  if (!dealId) {
    return { id: undefined, isDraft: false };
  }

  const isDraft = isTmpDealId(dealId);
  const integer = parseInt(
    isDraft ? dealId.replace(TMP_ID_PREFIX, '') : dealId,
    10
  );
  const id = !isNaN(integer) ? integer : undefined;

  return { id, isDraft };
};

export default useDealId;
