import { html, css } from 'lit';
import { createRef, ref } from 'lit/directives/ref.js';
import { BaseInputControl } from '../baseClasses/BaseInputControl';
import '../../rps-button';
import '../../rps-spinner-pause';

export class StandardButtons extends BaseInputControl {

	static styles = css`
		.button-container {
			position: relative;
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			gap: 2.4rem;
			margin-top: 0.8rem;
		}
		.button-container rps-button:only-child {
			margin-left: auto;
		}
	`;

	static get properties() {
		return {
			cancelText: { type: String },
			confirmText: { type: String },
			busyText: { type: String },
			hasSpinner: { type: Boolean },
			hideCancelButton: { type: Boolean },
			cbCancel: { attribute: false },
			cbConfirm: { attribute: false },
		};
	}

	constructor() {
		super();

		this.cancelText = "Cancel";
		this.confirmText = "Confirm";
		this.busyText = "Busy";
		this.hasSpinner = false;					// does this control have a "spinner" indicating that it is busy?
		this.spinnerVisible = false;				// is using the spinner, then this contains whether its displaying or nor
		this.hideCancelButton = false;			// by default it has both buttons
		this.status = "reset";						// status of buttons
		this.confirmButton = createRef()
		this.cancelButton = createRef()
	}

	/**
	 * Generate a cancel event and callback
	 *
	 * @param {*} event
	 * @memberof StandardButtons
	 */
	cancel(event) {
		event.preventDefault();

		this.setStatus("reset");
		const detail = {
			id: this.id,
			name: this.name,
			source: this.tagName,
			cancelButton: this.cancelButton?.value,
			confirmButton: this.confirmButton?.value,
		};
		const e = new CustomEvent('cancel', { detail, bubbles: true, composed: true, cancelable: true });
		this.dispatchEvent(e);

		if (this.cbCancel) this.cbCancel(e);
	}

	/**
	 * Generate a confirm event and callback
	 *
	 * @param {Event} event
	 * @memberof StandardButtons
	 */
	confirm(event) {
		event.preventDefault();

		this.setStatus("busy");
		const detail = {
			id: this.id,
			name: this.name,
			source: this.tagName,
			cancelButton: this.cancelButton?.value,
			confirmButton: this.confirmButton?.value,
		};
		const e = new CustomEvent('confirm', { detail, bubbles: true, composed: true, cancelable: true });
		this.dispatchEvent(e);

		if (this.cbConfirm) this.cbConfirm(e);
	}

	/**
	 * Set the status of the buttons.
	 *
	 * @param {String} status 'busy', or 'reset'
	 * @return {*}
	 * @memberof StandardButtons
	 */
	setStatus(status) {
		if (!this.hasSpinner) return;

		const cancel = this.cancelButton?.value;						// if this.hideCancelButton === false this will be null, spo code below is ok.
		const confirm = this.confirmButton.value.querySelector('span');

		this.status = status;

		if (status === "busy") {
			if (!this.hideCancelButton) cancel.textContent = this.cancelText;
			confirm.textContent = this.busyText;
			this.spinnerVisible = true;
			this.confirmButton.value.toggleAttribute('disabled', true);
			this.requestUpdate();
		} else if (status === "reset") {
			if (!this.hideCancelButton) cancel.textContent = this.cancelText;
			confirm.textContent = this.confirmText;
			this.spinnerVisible = false;
			this.confirmButton.value.toggleAttribute('disabled', false);
			this.requestUpdate();
		}
	}

	/**
	 * Generate a spinner in the button
	 *
	 * @return {html}
	 * @memberof StandardButtons
	 * @private
	 */
	_spinner() {
		if (!this.hasSpinner || !this.spinnerVisible) return;

		return html`<rps-spinner-pause></rps-spinner-pause>`;
	}

	render() {
		// only render cancel-button if desired
		const cancelButton = !this.hideCancelButton
			? html`<rps-button id="cancel" class="secondary" type="button" ${ref(this.cancelButton)} @click=${this.cancel}>${this.cancelText}</rps-button>`
			: '';

		return html`
		${this.addCss()}
		<div class="button-container">
			${cancelButton}
			<rps-button
				id="submit"
				@click=${this.confirm} ${ref(this.confirmButton)}
				class="${this.className}">
				<div slot="slot-left">
					${this._spinner()}
				</div>
				<span>${this.confirmText}</span>
			</rps-button>
		</div>
	 `;
	}
}
