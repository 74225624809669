import { html } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { DOM } from '../functions/DOM';
import { styles } from './css/barChart3d.css.js';

/**
 * Display a BAR chart using CSS3
 *
 * @export
 * @class BarChart
 * @extends {CustomLitElement}
 */
export class BarChart3d extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			heading: { type: String },							// Heading for Chart
			data: { type: Array },								// Array of objects to display
			barHeightRem: { type: Number },					// Height of a single Bar element
			barWidthRem: { type: Number },					// Width of a single Bar element
			innerBarWidthRem: { type: Number },				// The width of the "inner part" of a Bar. (Difference between barWidthRem is used for the 3D effect)
			barOffsetRem: { type: Number },					// The offset used for the starting point of the "3D effect", allows for the effect to be "deeper" or "shallower"
			yAxis: { type: Array },								// The points to display on the yAxis
			cbClick: { attribute: false },
		};
	}

	constructor() {
		super();
		this.heading = "3D Bar Chart with CSS3";
		this.data = [
			{ label: "2008", value: 25, title: `<h3>Sales for 2008</h3>` },
			{ label: "2009", value: 42 },
			{ label: "2010", value: 75, title: `<h3>Sales for 2010</h3>` },
			{ label: "2011", value: 100 },
			{ label: "2012", value: 50, title: `<h3>Sales for 2012</h3>` },
			{ label: "2013", value: 60 },
			{ label: "2014", value: 89, title: `<h3>Sales for 2014</h3>` },
		];
		this.barHeightRem = 22;
		this.barWidthRem = 12.5;
		this.innerBarWidthRem = this.barWidthRem * 0.8;
		this.barOffsetRem = this.barWidthRem * 0.2;

		this.floating = undefined;		// store the floating elements reference here so CPU is not wasted.

		this.yAxis = [
			{ label: "25%", value: 25 },
			{ label: "50%", value: 50 },
			{ label: "75%", value: 75 },
			{ label: "100%", value: 100 },
		];

		this.convertToPercentage();
	}

	/**
	 * Process the Y-Axis values and data values. Add a property that is a % of the max amount.
	 * This % is needed to display the horizontal line on the chart.
	 * @memberof BarChart
	 */
	convertToPercentage() {
		// Get the maximum of the y-Axis and the data.
		const yAxisMax = Math.max(...this.yAxis.map(e => e.value));
		const dataMax = Math.max(...this.data.map(e => e.value));
		const max = Math.max(yAxisMax, dataMax);

		console.debug("BarChart3d:convertToPercentage yAxis max value", max);

		this.yAxis.forEach(element => {
			element.percentage = (element.value / max * 100).toFixed(0) + "%";
		});
		console.debug(this.yAxis);

		let pos = 0;
		this.data.forEach(element => {
			// Store the element number as well.
			// This is passed back to the user when they click on the chart, so the developer knows what item was clicked on
			element.pos = pos;
			element.percentage = (element.value / max * 100).toFixed(0) + "%";
			pos++;
		});
	}


	/**
	 * Allows the changing of the data values after the chart has been rendered.
	 *
	 * @param {Array} newData
	 * @memberof BarChart
	 */
	setData(newData) {
		this.data = newData;
		this.convertToPercentage();
		this.requestUpdate();
	}

	/**
	 * Allows the changing of the Y-Axis values after the chart has been rendered.
	 *
	 * @param {Array} newYAxis
	 * @memberof BarChart
	 */
	setYAxis(newYAxis) {
		this.yAxis = newYAxis;
		this.convertToPercentage();
		this.requestUpdate();
	}


	/**
	 * Make a property that stores and returns the floating tooltip
	 *
	 * @readonly
	 * @memberof BarChart
	 */
	get tooltip() {
		if (!this.floating)
			this.floating = new DOM().findId(document.body, 'moreInfo');

		return this.floating;
	}

	/**
	 *
	 * Fires when the mouse moves over a Bar element.
	 * Display it only once until mouse moves away from this bar
	 * @param {Event} event
	 * @returns
	 * @memberof BarChart
	 */
	showTitle(event) {
		const floating = this.tooltip;
		if (floating.innerHTML !== "") return;

		const parent = new DOM().walkUp(event.target, "li")
		floating.style.position = "absolute";
		floating.style.zIndex = 10;

		floating.style.left = event.clientX - 70 + "px";
		floating.style.top = event.clientY - 70 + "px";

		let inner = parent.getAttribute("elemtitle");
		if (inner === undefined || inner === "undefined") inner = `<h3>Value: ${parent.getAttribute("chartvalue")}</h3>`;
		floating.innerHTML = inner;
	}


	/**
	 * Fires when the mouse moves out from a bar
	 * Hide the floating tooltip
	 *
	 * @param {Event} event
	 * @memberof BarChart
	 */
	hideTitle(event) {
		this.tooltip.innerHTML = "";
	}

	/**
	 * Fire an event when an item is clicked on the chart.
	 * Returns the value and position of the chart value that was clicked
	 *
	 * @param {Event} event
	 * @memberof BarChart
	 */
	barClick(event) {
		const parent = new DOM().walkUp(event.target, "li")
		console.debug("BarChart3d: Click", parent);

		const detail = {
			source: this.tagName,
			chartValue: parent.getAttribute("chartvalue"),
			position: parent.getAttribute("pos"),
			label: parent.getAttribute("label"),
		};
		const e = new CustomEvent('click', { detail, bubbles: true, composed: true, cancelable: true });
		this.dispatchEvent(e);

		if (this.cbClick) this.cbClick(e);
	}

	/**
	 * Draw all of the "Bars" for he chart.
	 *
	 * @returns
	 * @memberof BarChart
	 */
	renderBars() {
		return this.data.map(elem => {
			return html`
				<li elemTitle=${elem.title} chartValue=${elem.value} pos=${elem.pos} label=${elem.label}
					@mouseover=${this.showTitle} @mouseout=${this.hideTitle}
					@click=${this.barClick}
				>
					<span>${elem.label}</span>
					<div class="bar-wrapper">
						 <div class="bar-container" style="height: ${this.barHeightRem}rem;">
							  <div class="bar-background"></div>
							  <div class="bar-inner" style="height: ${elem.percentage}; bottom:0;">25</div>
							  <div class="bar-foreground"></div>
						 </div>
					</div>
			  </li>
			`;
		});
	}


	/**
	 * Draw the y-axis
	 *
	 * @returns
	 * @memberof BarChart
	 */
	yAxisLabels() {
		return html`
			<li style="height: ${this.barHeightRem}rem;">
				<ul class="graph-marker-container">
					${this.yAxis.map(elem => html`<li style="bottom:${elem.percentage};"><span>${elem.label}</span></li>`)}
				</ul>
			</li>
		`;
	}


	/**
	 * Generate the custom styling used in this chart
	 *
	 * @returns Custom Styling
	 * @memberof BarChart
	 */
	customCss() {
		return html`
			<style>
			.graph-container {
				background-position: 0 -${this.barOffsetRem}rem;
			}

			.graph-container > li:nth-last-child(2) {
				margin-right: ${this.barOffsetRem}rem;
			}


			.graph-container:before {
				height: ${this.barOffsetRem}rem;
				left: -${this.barOffsetRem / 2}rem;
			}


			.graph-container:after {
				height: ${this.barHeightRem}rem;
				top: ${this.barOffsetRem / 2}rem;
				left: -${this.barOffsetRem}rem;
				width: ${this.barOffsetRem}rem;
			}

			.bar-container {
				width: ${this.barWidthRem}rem;
				position: relative;
				margin-top: ${this.barOffsetRem}rem;
			}

			.bar-background {
				width: ${this.innerBarWidthRem}rem;
				top: -${this.barOffsetRem}rem;
				left: ${this.barOffsetRem}rem;
				z-index: 1; /* just for reference */
			}

			.bar-background:before {
				bottom: -${this.barOffsetRem}rem;
				right: ${this.barOffsetRem / 2}rem;
				width: ${this.innerBarWidthRem}rem;
				height: ${this.barOffsetRem}rem;
			}

			/* Left back panel */
			.bar-background:after {
				top: ${this.barOffsetRem / 2}rem;
				right: ${this.innerBarWidthRem}rem;
				width: ${this.barOffsetRem}rem;
			}

			.bar-background, .bar-background:before, .bar-inner {
				width: ${this.innerBarWidthRem}rem;
			}

			.bar-foreground,
			.bar-inner {
				width: ${this.innerBarWidthRem}rem;
			}

			/* Right front panel */
			.bar-foreground:before,
			.bar-inner:before {
				top: -${this.barOffsetRem / 2}rem;
				right: -${this.barOffsetRem}rem;
				width: ${this.barOffsetRem}rem;
			}

			/* Top front panel */
			.bar-foreground:after,
			.bar-inner:after {
				top: -${this.barOffsetRem}rem;
				right: -${this.barOffsetRem / 2}rem;
				height: ${this.barOffsetRem}rem;
			}

			/** BAR's inner block **/
			.bar-inner {
				bottom: -${this.barOffsetRem}rem;
			}


			.bar-container:before {
				border-width: 0 0 ${this.barOffsetRem}rem ${this.barOffsetRem}rem;
			}

			.bar-background:after {
				width: ${this.barOffsetRem}rem;
				left: -${this.barOffsetRem}rem;
			}

			.graph-marker-container > li {
				left: -${this.barOffsetRem}rem;
				margin-bottom: ${this.barOffsetRem}rem;
			}

			.graph-marker-container li:after {
				left: ${this.barOffsetRem}rem;
			}

		</style>
	`;
	}

	render() {
		return html`
			${this.addCss()}
			${this.customCss()}
			<div class="container">
				<header>
					<h1>${this.heading}</h1>
				</header>

				<section class="main">
					<ul class="graph-container">
						<div id="moreInfo"></div>
						${this.renderBars()}
						${this.yAxisLabels()}
					</ul>
				</section>
		  </div>
	`;
	}
}
