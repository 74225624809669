import { html } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { createRef, ref } from 'lit/directives/ref.js';
import { styles } from './css/tree-relationship.css.js';
import '../../rps-svg.js';
import '../../rps-tree.js';
import '../../rps-dropdown.js';
import { iconNames } from '../../svg-icons.js';
import { DOM, findByCallback, deepFlatten, groupByProps, addAttribute, uuid } from '../../rps-functions';

export class TreeRelationship extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			treeViewData: { type: Array },
			dropDownData: { type: Array },
			customRenderer: { type: Object },
		};
	}


	constructor() {
		super();
		this.treeViewData = [
			{
				id: 'a',
				text: 'Dans Family',
				leftSvg: iconNames.calendar,
				right: {
					svg: iconNames.error,
					tooltip: "my tooltip!!"
				},
				children: [
					{
						id: 'a1',
						text: 'family jannie',
						leftSvg: iconNames.dealAdd,
						children: [
							{
								id: 'a11',
								text: 'Mackie pet',
								leftSvg: iconNames.dealEdit,
							},
							{
								id: 'a12',
								text: 'Fluffy Pet',
								leftSvg: iconNames.dealUpload,
							},
						],
					},
					{
						id: 'a2',
						text: 'family sarie',
						rightSvg: iconNames.angleDown,
					},
				],
			},
			{
				id: 'b',
				text: 'Mikes family',
				leftSvg: iconNames.search,
				dropDownDataId: '2',
			},
			{
				id: 'c',
				text: 'Sammys family',
				text: 'Sammys family',
				dropDownDataId: '3',
			},
		];
		this.dropDownData = [
			{ id: '1', text: "Admin" },
			{ id: '2', text: "Clerk" },
			{ id: '3', text: "Reporter" },
		];
		this.customRenderer = (item) => html`
			<div style="display: flex; gap: 1.5rem; margin-left: 0.5rem; align-items: center;">
				${item.text}
				<rps-dropdown id=${uuid()} class="small" .items=${this.dropDownData} 
					defaultValue=${item.dropDownDataId || ''}
					@click=${this.dropdownClick} @input=${this.dropdownInput}>
				</rps-dropdown>
			</div>`;
		this.treeViewRef = createRef();
	}

	/**
	 * Return a reference to the tree-view control
	 *
	 * @readonly
	 * @memberof TreeRelationship
	 */
	get treeViewControl() {
		return this.treeViewRef.value;
	}

	/**
	 * Make sure the click event stops and does not collapse/expand the item
	 * The dropdown's click event bubbles up and causes the tree-view to collapse/expand
	 * Prevent this action.
	 *
	 * @param {Event} e
	 * @memberof TreeRelationship
	 */
	dropdownClick(e) {
		e.preventDefault();
		e.stopPropagation();
	}

	/**
	 * Fired when the dropdown value changes
	 * @note The reference to [this] returns the tree-view in this method, therefore you need to walkup until the rps-tree-relationship object is found
	 *
	 * @param {Event} e
	 * @return {*} 
	 * @memberof TreeRelationship
	 */
	dropdownInput(e) {
		//e.preventDefault();
		//e.cancelBubble = true;

		console.log('TreeRelationship:dropdownInput', e);
		const dom = new DOM();
		const li = dom.walkUp(e.target, 'LI');

		const relation = dom.walkUp(this, 'rps-tree-relationship');
		if (!relation) {
			console.error('TreeRelationship:dropdownInput', 'Cannot find [rps-tree-relationship]');
			return;
		}

		const tvItem = findByCallback(relation.treeViewData, (item) => item.id === li.id);
		if (!tvItem) {
			console.error('TreeRelationship:dropdownInput', 'Cannot find item in [treeViewData]');
			return;
		}

		tvItem.dropDownDataId = e.detail.value;
	}

	/**
	 * Return all the treeView items as a flat array with the dropDownDataId in the object property
	 *
	 * @return {Array} 
	 * @memberof TreeRelationship
	 */
	resultsFlat() {
		const flat = deepFlatten(this.treeViewData);
		const filtered = flat.filter(e => e.id !== undefined);
		const shaped = filtered.map(item => {
			return { id: item.id, dropDownDataId: item.dropDownDataId };
		});
		return shaped;
	}

	/**
	 * Return all the treeView items as a Map() object that contains the [dropDownDataId], then an array of [id] that belong to the [dropDownDataId]
	 *
	 * @return {Map} 
	 * @memberof TreeRelationship
	 */
	resultsGrouped() {
		const shaped = this.resultsFlat();
		const grouped = groupByProps(shaped, 'dropDownDataId', 'id');

		return grouped;
	}

	/**
	 * Return the tree-view data containing the complete tree along with the associated dropdownId's
	 *
	 * @return {Array} 
	 * @memberof TreeRelationship
	 */
	resultsTree() {
		return this.treeViewData;
	}

	render() {
		// Make sure that the tree-view data has the needed attribute to render thr dropdown
		addAttribute(this.treeViewData, "middle", { customRender: this.customRenderer });

		return html`
		${this.addCss()}
		<div class="tree-relationship-container ${this.className || 'default'}">
			<rps-tree class="simple" .data=${this.treeViewData} ${ref(this.treeViewRef)}>
			</rps-tree>
		</div>
		`;
	}
}


