import { html, css } from 'lit';
import { ref, createRef } from 'lit/directives/ref.js';
import '../../rps-ok-modal';
import '../../rps-standard-buttons';
import { CustomLitElement } from '../baseClasses/CustomLitElement';

export class Form extends CustomLitElement {
	static styles = css`
			/*:host {
				display: block;
				width: max-content;
			}*/
			form {
				display: flex;
				flex-flow: column wrap;
				gap: 2.4rem;
			}
		`;

	static get properties() {
		return {
			cancelText: { type: String },
			confirmText: { type: String },
			hideCancelButton: { type: Boolean },
			cbSubmit: { attribute: false },
			cbCancel: { attribute: false },
			errorMessages: { type: Array },
			hideButtons: { type: Boolean },
		};
	}

	constructor() {
		super();
		this.cancelText = "Cancel";
		this.confirmText = "Submit";
		this.hideCancelButton = false;			// by default it has both buttons
		this.modal = createRef();
		this.errorMessages = [];
		this.hideButtons = false;
	}


	/**
	 * Retrieve a child control in this form using a css selector
	 *
	 * @example const form = myParent.getControl('input[name="userName"]');
	 * @param {Css Selector} selector
	 * @return {Child control in form}
	 * @memberof Form
	 */
	getControl(selector) {
		const rpsControl = this.renderRoot.host.querySelector(selector);
		return rpsControl;
	}

	/**
	 * Retrieve child controls in this form using a css selector
	 *
	 * @example const form = myParent.getControls('input[name="userName"]');
	 * @param {Css Selector} selector
	 * @return {Array} Children controls in this form
	 * @memberof Form
	 */
	getControls(selector) {
		const rpsControls = this.renderRoot.host.querySelectorAll(selector);
		return rpsControls;
	}

	/**
	 * Return the modal control
	 *
	 * @readonly
	 * @memberof Form
	 */
	get modalControl() {
		return this.modal.value;
	}


	/**
	 * when the "confirm" button has been clicked, validate all controls on the form
	 * and display invalid control
	 * @param {Event} event
	 * @memberof Form
	 */
	onConfirm(event) {
		event.preventDefault();
		event.stopPropagation();

		console.debug('Form: confirm event');
		event.target.setStatus('reset');

		this.modalControl.hide();
		const valid = this.validate();
		this.errorMessages = valid.errorMessages;

		if (valid.isValid) {
			console.debug('Form: firing submit event');

			const detail = {
				source: this.tagName,
				id: this.id,
				name: this.name,
			};
			const newEvent = new SubmitEvent('submit', { bubbles: true, composed: true, cancelable: true, detail });
			this.dispatchEvent(newEvent);

			if (this.cbSubmit) this.cbSubmit(newEvent);
		}
		else {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}

	}


	/**
	 * Validate the form and return its validity along with a list of error messages and the relevant controls
	 *
	 * @return {*}
	 * @memberof Form
	 */
	validate() {
		const allInputs = this._getInputNodes();
		const messages = [];
		let isValid = true;

		allInputs.forEach(e => {
			if (!e.validate()) {
				let label = e.label;
				if (e.tagName === 'RPS-DATE-SELECT-RANGE') {
					label = e.fromLabel + ' + ' + e.toLabel;
				}
				this.modalControl.show();
				isValid = false;
				messages.push({
					id: e.id,									// id of <rps component>
					name: e.name,								// name of <rps component>
					inputControl: e.inputControl,			// <input> control ref
					label: label,								// label of <rps component>
					message: e.errorMessage,				// custom error message for <rps component>
				});
			}
		});

		return { isValid, errorMessages: messages };
	}

	/**
	 * Cancel button clicked
	 *
	 * @param {*} e
	 * @memberof Form
	 * @private
	 */
	cancel(e) {
		console.debug('Form: Cancel');
		e.preventDefault();
		e.stopPropagation();

		const detail = {
			source: this.tagName,
			id: this.id,
			name: this.name,
		};
		const newEvent = new SubmitEvent('cancel', { bubbles: true, composed: true, cancelable: true });
		this.dispatchEvent(newEvent);

		if (this.cbCancel) this.cbCancel(newEvent);
	}


	/**
	 * Retrieve all input and select elements on this form
	 *
	 * @returns
	 * @memberof Form
	 */
	_getInputNodes() {
		const selectNodes = this.getControls('rps-dropdown');
		const switches = this.getControls('rps-switch', 'rps-checkbox', 'rps-dropdown');
		const inpNodes = this.getControls('rps-input, rps-plus-minus');
		const radios = this.getControls('rps-radio');
		const checkboxes = this.getControls('rps-checkbox');
		const dateRangeNodes = this.getControls('rps-date-select-range');

		const inputs = [...switches, ...inpNodes, ...dateRangeNodes, ...selectNodes, ...radios, ...checkboxes];

		return inputs;
	}

	/**
	 * Return a JSON object containing the name and value of these form values
	 *
	 * @returns - JSON object containing the name and value of these form values
	 * @memberof Form
	 */
	getJSON() {
		const inputNodes = this._getInputNodes();
		let jsonValues = {};

		inputNodes.forEach(e => {
			if ((e.tagName === 'RPS-CHECKBOX')) {
				jsonValues[e.name] = e.checked;
			} else if (e.tagName === 'RPS-DATE-SELECT-RANGE') {
				jsonValues[e.name] = {
					fromDateControl: e.fromDateControl.value,
					toDateControl: e.toDateControl.value
				}
			} else {
				jsonValues[e.name] = e.value;
			}
		});

		return jsonValues;
	}

	/**
	 * Clear all the cached values used by this form
	 *
	 * @memberof Form
	 */
	clear() {
		const inputNodes = this._getInputNodes();

		inputNodes.forEach(e => {
			e.clear();
		});
	}


	/**
	 *
	 *
	 * @param {Json} jsonStructure - The JSON object you wish to be filled from this form
	 * @returns
	 * @memberof Form
	 */
	fillJSON(jsonStructure) {
		const inputNodes = this._getInputNodes();
		let jsonValues = {};

		const baseValues = this._fillObject(jsonStructure, jsonValues, Array.from(inputNodes));
		jsonValues = { ...jsonValues, ...baseValues };
		console.debug("Form: fillJSON", JSON.stringify(jsonValues, undefined, 2));

		return jsonValues;
	}


	/**
	 * Return a JSON object based on a defined structure, using this forms values as a basis for the values
	 * If caching is used for an item, use it, otherwise use the inputs value
	 * This gets called in a recursive manner to retrieve all values
	 * @param {JSON} jsonStructure - JSON structure to create
	 * @param {JSON} jsonValues - JSON object to fill
	 * @param {JSON} inputNodes - This forms Nodelist of input and select controls
	 * @returns Filled JSON object
	 * @memberof Form
	 */
	_fillObject(jsonStructure, jsonValues, inputNodes) {
		const values = {};

		for (const prop in jsonStructure) {
			if (typeof jsonStructure[prop] === 'object') {
				const filled = this._fillObject(jsonStructure[prop], jsonValues, inputNodes);
				jsonValues[prop] = filled;
			} else {
				let item = inputNodes.find(e => e.name === jsonStructure[prop]);					// find the input item on this form
				if (!item) {
					console.error('Form:_fillObject', 'cant find', jsonStructure[prop]);
				} else {
					// if its a radio button, then find the checked one ()there are multiple radio items with the same name
					if (item.tagName === 'RPS-RADIO') {
						// if there is a selected item, use it.
						const selectedRadio = inputNodes.find(e => e.name === jsonStructure[prop] && e.checked);
						if (selectedRadio) item = selectedRadio;
					}

					// if the control does not exist that is ok, we can try get it from cache
					if (item.hasCache) {
						const cachedJSON = JSON.parse(item.cache);
						values[prop] = cachedJSON.value;
					} else if (item) {
						if (item.tagName === 'RPS-DATE-SELECT-RANGE') {
							values[prop] = {
								fromDateControl: item.fromDateControl.value,
								toDateControl: item.toDateControl.value
							}
						} else {
							values[prop] = item.tagName === 'RPS-CHECKBOX' ? item.checked : item.value;
						}
					}
				}
			}
		}

		return values;
	}

	/**
	 * Generate the Html to display the error messages for the form
	 *
	 * @return {html}
	 * @memberof Form
	 * @private
	 */
	_errorMessages() {
		const items = this.errorMessages.map(e => {
			//return html`<span>${e.label}: ${e.message}</span><br/>`;
			return html`<li><b>${e.label}</b>: ${e.message}</li>`;
		});

		return html`<ul>${items}</ul>`;
	}

	render() {
		return html`
			<div class="form ${this.css}">
				<rps-ok-modal class="warning" ${ref(this.modal)} headertext='Please correct all fields'>
					${this._errorMessages()}
				</rps-ok-modal>
				<form>
					<slot></slot>
					<div class="row">
						${this.hideButtons === false
				? html`<rps-standard-buttons
								@confirm=${this.onConfirm} @cancel=${this.cancel}
								canceltext="${this.cancelText}" confirmtext="${this.confirmText}"
								?hidecancelbutton=${this.hideCancelButton}
							/>`
				: ''}
					</div>
				</form>
			</div>
		`;
	}

}
