import type { ApiCustomOptionValue } from '@odo/types/api';
import type {
  PastedOption,
  PastedOptionValue,
} from '@odo/contexts/custom-options-editor/types';
import uuid from '@odo/utils/uuid';
import type { CustomOptionTree } from '@odo/types/portal';

const prepPastedOptionsRecursive = ({
  options,
  option,
  parentValueId,
  getSortOrder,
}: {
  options: PastedOption[];
  option: CustomOptionTree;
  parentValueId?: ApiCustomOptionValue['valueId'];
  getSortOrder: () => number;
}) => {
  const valueTmpIdMap: Record<
    ApiCustomOptionValue['valueId'],
    ApiCustomOptionValue['valueId']
  > = {};

  let valueSortOrder = 0;
  const pastedOption: PastedOption = {
    tmpOptionId: uuid(),
    title: option.title,
    parentValueId,
    sortOrder: getSortOrder(),
    values: option.values.map(value => {
      const tmpValueId = uuid();
      const pastedValue: PastedOptionValue = {
        tmpValueId,
        title: value.title,
        sku: value.sku,
        price: value.price,
        cost: value.cost || 0,
        quantity: value.quantity?.number || 0,
        sortOrder: ++valueSortOrder,
      };
      valueTmpIdMap[value.valueId] = tmpValueId;
      return pastedValue;
    }),
  };

  options.push(pastedOption);

  option.values.forEach(({ valueId, childOptions }) =>
    childOptions.forEach(childOption =>
      prepPastedOptionsRecursive({
        options,
        option: childOption,
        parentValueId: valueTmpIdMap[valueId] || undefined,
        getSortOrder,
      })
    )
  );
};

export const copyCustomOptionTrees = ({
  customOptions,
  parentValueId,
}: {
  customOptions: CustomOptionTree[];
  parentValueId?: ApiCustomOptionValue['valueId'];
}) => {
  const pastedOptions: PastedOption[] = [];

  let rootOptionsSortOrder = 0;
  customOptions.forEach(option =>
    prepPastedOptionsRecursive({
      options: pastedOptions,
      option,
      parentValueId,
      getSortOrder: () => ++rootOptionsSortOrder,
    })
  );

  return pastedOptions;
};

export const copyCustomOptionTree = ({
  customOption,
  parentValueId,
  rootOptionSortOrder: rootOptionSortOrderProp,
}: {
  customOption: CustomOptionTree;
  parentValueId?: ApiCustomOptionValue['valueId'];
  rootOptionSortOrder?: number;
}) => {
  const pastedOptions: PastedOption[] = [];

  let rootOptionSortOrder = rootOptionSortOrderProp || 0;
  prepPastedOptionsRecursive({
    options: pastedOptions,
    option: customOption,
    parentValueId,
    getSortOrder: () => ++rootOptionSortOrder,
  });

  const [rootOption, ...childOptions] = pastedOptions;

  return { rootOption, childOptions };
};
