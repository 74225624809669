import { html } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { ref, createRef } from 'lit/directives/ref.js';
import { styles } from './css/imageCarousel.css.js';
import '../../rps-svg';
import '../../rps-forwards-backwards';
import '../../rps-image';
import '../../rps-list-container';

export class ImageCarousel extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			currentPage: { type: Number },
			visibleItems: { type: Number },
			images: { type: Array },
			hideConfirmButton: { type: Boolean },
			cbPageHasChanged: { attribute: false },
			cbFinished: { attribute: false },
			layout: { type: String },								// 'column' or 'row' Default='row'
		};
	}

	constructor() {
		super()
		this.currentPage = 0;
		this.visibleItems = 3;
		this.hideConfirmButton = false;
		this.images = [];
		this.layout = 'row';
		this.previewRef = createRef();
	}

	/**
	 * Reference to all the image controls
	 *
	 * @readonly
	 * @memberof ImageCarousel
	 */
	get imageControls() {
		const images = this.renderRoot.querySelectorAll('rps-image');
		return Array.from(images);
	}

	/**
	 * Reference to the image preview control
	 *
	 * @readonly
	 * @memberof ImageCarousel
	 */
	get previewControl() {
		return this.previewRef.value;
	}

	/**
	 * User clicked on the forwards or backwards button which changes the page
	 *
	 * @param {Event} event
	 * @return {*} 
	 * @memberof ImageCarousel
	 */
	_pageHasChanged(event) {
		event.preventDefault();
		event.stopPropagation();

		//this.previewControl.src = '#';				// uncomment this if the preview should "disappear" when the page is changed
		let wanted = event.detail.currentPage;
		console.debug(`ImageCarousel:_pageHasChanged old:${this.currentPage} new:${wanted}`, event);
		this.currentPage = wanted;
	}

	/**
	 * Toggle the state of the selected class for all images
	 *
	 * @param {Boolean} addSelected if undefined the class is toggled. if true then forcefully added, if false, then forcefully removed
	 * @memberof Image
	 */
	toggleSelectedAllImages(addSelected) {
		this.imageControls.forEach(image => image.toggleSelected(addSelected));
	}

	/**
	 * Ensure that all images do not have the "selected" class except the image that was clicked on
	 *
	 * @param {event} event
	 * @memberof ImageCarousel
	 */
	_imageClick(event) {
		this.imageControls.forEach(image => {
			const match = event.target.src === image.src;
			if (!match) {
				image.toggleSelected(false)
			} else {
				this.previewControl.src = event.target.src;
			}
		});
	}

	generateImages() {
		console.debug(`generate page [${this.currentPage}]`);
		this.toggleSelectedAllImages(false);

		const images = [];
		let start = this.currentPage * this.visibleItems;
		let end = Math.min(start + this.visibleItems, this.images.length);

		for (let i = start; i < end; i++) {
			const image = this.images[i];
			images.push(html`
				<rps-image
					id=${'image-' + i}
					@click=${this._imageClick}
					src=${image.src} class="thumbnail" layout="${this.layout}">
				</rps-image>`);
		}
		return images;
	}

	render() {
		const listLayout = this.layout === "row" ? '' : 'vertical';

		return html`
			${this.addCss()}

			<div id="image-carousel" layout="${this.layout}">
				<div class="carousel">
					<rps-forwards-backwards
						class="image-controls"
						hideConfirmButton
						@pageHasChanged=${this._pageHasChanged} 
						hidePages
						layout="${this.layout}"
						.pages=${new Array(Math.ceil(this.images.length / this.visibleItems))}
					>
						<rps-list-container class="${listLayout}">
							${this.generateImages()}
						</rps-list-container>
					</rps-forwards-backwards>
				</div>
				<div class="preview">
					<rps-image 
						src='#'
						${ref(this.previewRef)}
						layout="${this.layout}" 
						canExpand
						id='image-preview'
					></rps-image>
				</div>
			</div>
		`;
	}
}

