/**
 * @name rps-pie-chart2d
 * @type {PieChart2d}
 * 
 * @notes
 * There are no "Click events" as in most controls for the <rps-pie-chart2d>.
 * The reason for this is that the PIE chart is built using CSS3, and the position's are rotated etc.
 * resulting in multiple elements being on the same place and click events can return the incorrect element. 
 * Therefore no click events are exposed.
 * 
 * 
 * @attributes + @properties 					| Changes trigger a re-render
 * Casing usages									| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {Number} 	sizeRem			| Size of chart in REM. Default = 30
 * 	@property {Array} 	data				| Array of data points.
 * 														eg: [{ text: "Africa", value: 25 }, { text: "Asia", value: 10 }]
 * 												
 * 	@property {Array} 	colors			| Array of colors to use for the pie chart items
 * 														eg: ["#4d05e8", "blue"]
 * 												
 * 	@property {String} 	customCss		| Specify a custom .css file to include, use cssPath to override elements,
 * 														DO NOT override the styling definition by re-defining the css styling
 * 														Put this file into the *window.cssPath* folder @see {link README.md}
 * 														eg: /public/css (where the other css files are)
 * 														Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css				| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 	@property {String} 	heading			| Heading for Chart
 * 
 * 
 * @methods
 * 	None
 * 
 * @events
 * 	None
 * 
 * @callbacks
 * 	None
 * 
 * @slots
 * 	None
 * 
*/
import { PieChart2d } from './src/charts/PieChart2d';

if (!window.customElements.get('rps-pie-chart2d'))
	window.customElements.define('rps-pie-chart2d', PieChart2d);

