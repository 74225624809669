import React, { useState, useCallback, useEffect } from 'react';
import { useCurrentDealSource } from '../../../hooks/useCurrentDealSource';
import { RPSCard } from '@rps/web-components/build/react-wrappers';
import Search from '@odo/components/search';
import { useDealModel } from '@odo/hooks/deal.ts';
import DuplicateDeal from './DuplicateNew';
import toast from 'react-hot-toast';
import { Deal } from 'models/Deal.jsx';

const Manage = () => {
  const {
    dealModel: loadedDealModel,
    setDealModel,
    loadFullDealModel,
  } = useDealModel();

  const { selectTempDeal } = useCurrentDealSource();

  const [isDuplicateLoading, setIsDuplicateLoading] = useState(false);
  const [isDuplicateReady, setIsDuplicateReady] = useState(false);

  /**
   * Because deal loading is completely async right now,
   * we need to trigger the load in the callback function,
   * and then wait here in a useEffect for the value to be populated.
   * Then we can show the duplicate popup.
   */
  useEffect(() => {
    if (loadedDealModel) {
      selectTempDeal(loadedDealModel);
      setIsDuplicateReady(true);
      setIsDuplicateLoading(false);

      toast.dismiss('loading-duplication');
    }
  }, [loadedDealModel, selectTempDeal]);

  const initiateDuplicatePopup = useCallback(
    dealId => {
      toast.loading('Loading fields for duplication', {
        id: 'loading-duplication',
      });

      setIsDuplicateLoading(true);
      loadFullDealModel(dealId);
    },
    [loadFullDealModel]
  );

  const close = useCallback(() => {
    setIsDuplicateReady(false);
    setIsDuplicateLoading(false);
    selectTempDeal(new Deal());
    setDealModel(undefined);
  }, [selectTempDeal, setDealModel]);

  return (
    <>
      <DuplicateDeal
        isLoading={isDuplicateLoading}
        isReady={isDuplicateReady}
        close={close}
      />

      <RPSCard>
        <Search duplicateDeal={initiateDuplicatePopup} />
      </RPSCard>
    </>
  );
};

export default Manage;
