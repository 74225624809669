import { createClient } from '@odo/services/urql';
import {
  CategoryTypeEnum,
  type QueryCategoryBreadcrumbsArgs,
  type QueryCategoryBreadcrumbsOutput,
  type QueryCategorySearchBreadcrumbsArgs,
  type QueryCategorySearchBreadcrumbsOutput,
} from '@odo/types/api';
import { throwGraphQLError } from '@odo/utils/graphql';
import { gql } from 'urql';

const BREADCRUMB_FRAGMENT = gql`
  fragment Breadcrumb on CategoryBreadcrumbs {
    id
    name
    breadcrumb
    activeFromDate
    activeToDate
    type
    relevance
  }
`;

const GET_CATEGORY_BREADCRUMBS = gql`
  ${BREADCRUMB_FRAGMENT}
  query getCategoryBreadcrumbs($categoryIds: [ID!]!) {
    getCategoryBreadcrumbs(categoryIds: $categoryIds) {
      count
      breadcrumbs {
        ... on CategoryBreadcrumbs {
          ...Breadcrumb
        }
      }
    }
  }
`;

const GET_CATEGORY_SEARCH_BREADCRUMBS = gql`
  ${BREADCRUMB_FRAGMENT}
  query getCategorySearchBreadcrumbs(
    $term: String!
    $searchType: CategorySearchTypeEnum
    $categoryType: CategoryTypeEnum
  ) {
    getCategorySearchBreadcrumbs(
      term: $term
      searchType: $searchType
      categoryType: $categoryType
    ) {
      count
      breadcrumbs {
        ... on CategoryBreadcrumbs {
          ...Breadcrumb
        }
      }
    }
  }
`;

interface ClientParams {
  signal?: AbortSignal;
}

type QueryCategoryBreadcrumbsParams = QueryCategoryBreadcrumbsArgs &
  ClientParams;

type QueryCategorySearchBreadcrumbsParams = QueryCategorySearchBreadcrumbsArgs &
  ClientParams;

export const queryCategoryBreadcrumbs = async ({
  categoryIds,
  signal,
}: QueryCategoryBreadcrumbsParams) => {
  const { data, error } = await createClient({ signal })
    .query<QueryCategoryBreadcrumbsOutput, QueryCategoryBreadcrumbsArgs>(
      GET_CATEGORY_BREADCRUMBS,
      { categoryIds },
      { requestPolicy: 'network-only' }
    )
    .toPromise();

  // only throw errors if we don't get any data back
  if (!(data && data.getCategoryBreadcrumbs) && error) {
    throwGraphQLError(error);
  }

  return data && (data.getCategoryBreadcrumbs || undefined);
};

export const queryCategorySearchBreadcrumbs = async ({
  term,
  searchType = 'LIKE',
  categoryType = CategoryTypeEnum.category,
  signal,
}: QueryCategorySearchBreadcrumbsParams) => {
  const { data, error } = await createClient({ signal })
    .query<
      QueryCategorySearchBreadcrumbsOutput,
      QueryCategorySearchBreadcrumbsArgs
    >(
      GET_CATEGORY_SEARCH_BREADCRUMBS,
      { term, searchType, categoryType },
      { requestPolicy: 'network-only' }
    )
    .toPromise();

  // only throw errors if we don't get any data back
  if (!(data && data.getCategorySearchBreadcrumbs) && error) {
    throwGraphQLError(error);
  }

  return data && (data.getCategorySearchBreadcrumbs || undefined);
};
