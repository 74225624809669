import { html } from 'lit';
import { ref, createRef } from 'lit/directives/ref.js';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import '../../rps-modal';
import { iconNames } from '../../svg-icons';
import '../../rps-svg';
import '../../rps-modal';
import { styles } from './css/chip.css.js';

/**
 * Container for data information
 * @description - This class it commonly used as a "container" for information as an item in a list.
 * Example a list of these items would be out into a container element and displayed to the client.
 * eg: drag/drop items onto a container and display them each as "Chip's"
 *
 * @export
 * @class Chip
 * @extends {CustomLitElement}
 */
export class Chip extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			text: { type: String },
			canEdit: { type: Boolean },
			deleteMode: { type: String },			// ['none','confirm','quick','manual']
			modalHeader: { type: String },
			modalContent: { type: String },
			cbDelete: { attribute: false },
			cbEdit: { attribute: false },
			cbClick: { attribute: false },
		};
	}

	constructor() {
		super();
		this.canEdit = false;
		this.deleteMode = 'none';
		this.modalHeader = "Are you sure?";
		this.modalContent = "";
		this.modalRef = createRef();
		this.chipRef = createRef();
	}

	/**
	 * A reference to the Modal
	 *
	 * @readonly
	 * @memberof Chip
	 */
	get modal() {
		return this.modalRef?.value;
	}

	/**
	 * A reference to the Chip
	 *
	 * @readonly
	 * @memberof Chip
	 */
	get chip() {
		return this.chipRef.value;
	}


	/**
	 * User clicked the "Delete" button.
	 * This opens the modal asking for confirmation.
	 *
	 * @param {Event} event
	 * @memberof Chip
	 * @private
	 */
	deleteChip(event) {
		this.modal.show();
		event.stopPropagation();
	}


	/**
	 * User clicked on the "Edit" button.
	 * The user MUST implement the action on their own.
	 * This only fires the event for the user to act on.
	 *
	 * @param {Event} event
	 * @memberof Chip
	 * @private
	 */
	editChip(event) {
		event.preventDefault();
		event.stopPropagation();

		this.notifyClients('edit');
		if (this.cbEdit) this.cbEdit(event);
	}


	/**
	 * Fire a click event for clients to listen to
	 *
	 * @param {string} type - the type of event (name) to be fired
	 * @memberof Chip
	 * @private
	 */
	notifyClients(type) {
		const detail = {
			type,
			source: this.tagName,
			text: this.text,
			id: this.id,
		};
		console.debug(`Chip:notifyClients`, detail);

		const event = new CustomEvent(type, { detail, bubbles: true, cancelable: true, composed: true })
		this.dispatchEvent(event);
	}

	/**
	 * Fires an event when the chip is clicked on
	 *
	 * @param {Event} event
	 * @memberof Chip
	 * @private
	 */
	clicked(event) {
		event.stopPropagation();
		event.preventDefault();

		this.notifyClients('click');
		if (this.cbClick) this.cbClick(event);
	}


	/**
	 * Fires when the user confirms that Chip must be deleted
	 *
	 * @param {Event} event - the
	 * @memberof Chip
	 * @private
	 */
	modalConfirm(event) {
		event.preventDefault();
		event.stopPropagation();

		console.debug('Chip:modalConfirm', event);

		this.notifyClients('delete');
		if (this.deleteMode !== 'manual') this.chip.remove();		// user wants to manually control this
		if (this.cbDelete) this.cbDelete(event);
	}


	render() {
		const editButton = this.canEdit ? html`<rps-svg svg="${iconNames.edit}" title="Edit" @click=${this.editChip}></rps-svg>` : '';
		let modal = '';
		let deleteButton = '';
		// If the user does not pass custom content, then
		let content = this.modalContent;
		if (!content) content = `Delete: [${this.text}]`;

		if (this.deleteMode === 'quick' || this.deleteMode === 'manual') {
			deleteButton = html`<rps-svg svg="${iconNames.del}" title="Delete" @click=${this.modalConfirm}></rps-svg>`;
		} else if (this.deleteMode === 'confirm') {
			deleteButton = html`<rps-svg svg="${iconNames.del}" title="Delete" @click=${this.deleteChip}></rps-svg>`;
			modal = html`
					<rps-modal popup @confirm="${this.modalConfirm}" headertext="${this.modalHeader} ${ref(this.modalRef)}">
						${content}
					</rps-modal>`;
		}

		return html`
			${this.addCss()}
			<span>
				<div class="chip ${this.className}" @click=${this.clicked} ${ref(this.chipRef)}>
					<slot name="slot-left" class="slot-left">
						${editButton}
					</slot>
					<div class="slot-default">${this.text}</div>
					<slot name="slot-right" class="slot-right">
						${deleteButton}
					</slot>
				</div>
				${modal}				
			</span>
		 `;
	}
}
