import React, { useState, useMemo, useEffect } from 'react';
import styled from '@odo/lib/styled';
import { useCurrentDealSource } from '../../hooks/useCurrentDealSource';
import {
  RPSCard,
  RPSListContainer,
  RPSImage,
  RPSButton,
} from '@rps/web-components/build/react-wrappers';
import { toCurrency } from '@rps/js-utils/number';
import { iconNames } from '@rps/web-components/build/web-components';
import { RPSSummary } from 'components/shared/RPSSummary.jsx';
import { Deal } from './../../models/Deal.jsx';
import { ErrorCard } from 'components/uiComponents/ErrorCard';
import PropTypes from 'prop-types';
import { ApolloClients } from 'services/apolloClients';
import { GET_ODO_IMAGES } from 'gql/deals/getODOImages';
import { gql } from '@apollo/client';
import {
  GET_PRODUCT_CATEGORIES,
  GET_CATEGORY,
} from './../../gql/deals/getCategories';
import { useSafeEffect } from './../../hooks/useSafeEffect';
import { GET_DROPDOWN_VALUES } from './../../gql/dropdowns/getDropdownValues';
import { DROPDOWN as DropDowns } from 'gql/dropdowns/getDropdownValues';
import { useAttributeOptions } from '@odo/hooks/attributes';
import { AttributeCode } from '@odo/types/api';
import { FormLayout } from './shared/FormLayout.jsx';
import { Flex } from '@odo/components/elements/layout/flex';
import Divider from '@odo/components/elements/divider';
import { ODOSignoffBaseUrl } from '../../config';

const GET_PRODUCT_URL = gql`
  query GetProductUrl($productId: ID!) {
    getProduct(productId: $productId) {
      preview
    }
  }
`;

// TODO: BP-381: config/constant
const ESSENTIAL_TAB_CATEGORY_ID = '27572';
const MAX_CATEGORY_RECURSION_DEPTH = 5;

const client = new ApolloClients();

export const SummaryScreen = ({ overrideDeal, displayErrors = false }) => {
  const currentDeal = useCurrentDealSource();
  const deal = overrideDeal || currentDeal.deal || new Deal();
  const data = deal?.toFlatDeal && deal.toFlatDeal();

  const areaOptions = useAttributeOptions(AttributeCode.area);

  const buyerSupplierRows = [
    {
      term: 'Account Manager',
      id: 'buyer',
      description: data?.buyer || 'No Account Manager Selected',
    },
    {
      term: 'Loader',
      id: 'salesAssistant',
      description: data?.salesAssistant || 'No Loader Selected',
    },
    {
      term: 'Supplier',
      id: 'shop',
      description: data?.supplier || 'No Supplier Selected',
    },
    {
      term: 'Brand',
      id: 'brand',
      description: data?.brand || 'No Brand Selected',
    },
  ];

  const [images, setImages] = useState([]);
  const [loadingImage, setLoadingImage] = useState(true);
  const [areaLabel, setAreaLabel] = useState(deal.product.area);

  useEffect(() => {
    if (deal.product.area && areaOptions.length > 0) {
      setAreaLabel(
        areaOptions.find(option => option.key === deal.product.area)?.label ||
          deal.product.area
      );
    }
  }, [deal.product.area, areaOptions]);

  // Effect: Fetch deal images on load
  useSafeEffect(
    isMounted => {
      // TODO: images are now available on the product interface... let's refactor
      if (!deal.id && !deal.meta?.id) return;
      const fetchImages = async () => {
        try {
          const result = await client.odoClient.query({
            query: GET_ODO_IMAGES,
            variables: {
              productId: deal.id || deal.meta?.id,
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'ignore',
          });

          if (result?.data?.getProductImages) {
            if (isMounted) {
              setImages(result.data.getProductImages);
              setLoadingImage(false);
            }
          }
        } catch (e) {
          console.debug(
            '[SummaryScreen::useEffect.fetchImages] ProductId not found - may be an unsaved deal'
          );
        } finally {
          if (isMounted) {
            setLoadingImage(false);
          }
        }
      };

      fetchImages();
    },
    [deal]
  );

  const imageUri = useMemo(() => {
    if (images.length > 0) {
      for (let img of images) {
        if (img.imageTypes.length > 0) {
          return img.url;
        }
      }
      return images[0].url;
    }
    return;
  }, [images]);

  const [productUrl, setProductUrl] = useState(deal.product.preview);
  useSafeEffect(
    isMounted => {
      if ((!deal.id && !deal.meta?.id) || productUrl) return;

      const fetchProductUrl = async () => {
        let url = '';

        try {
          const res = await client.odoClient.query({
            query: GET_PRODUCT_URL,
            variables: {
              productId: deal.id || deal.meta?.id,
            },
          });

          if (res?.data?.getProduct) {
            url = res.data.getProduct.preview;
          }
          if (isMounted) {
            setProductUrl(url);
          }
        } catch (e) {
          console.debug(
            '[SummaryScreen::useEffect.fetchProductUrl] ProductId not found - may be a new deal.'
          );
          if (isMounted) {
            setProductUrl('');
          }
        }
      };
      fetchProductUrl();
    },
    [deal, productUrl]
  );

  const [categoryBreadcrumbs, setCategoryBreadcrumbs] = useState([]);
  const [categoryBusy, setCategoryBusy] = useState(false);
  const [essentialTabShop, setEssentialTabShop] = useState(false);

  useSafeEffect(
    isMounted => {
      const ROOT_CATEGORY = 10982;
      let results = [];
      let depth = 0;

      if (!deal.id && !deal.meta?.id) {
        return;
      }

      const fetchCategoryAndPrepend = async (array, categoryId) => {
        if (!categoryId || depth >= MAX_CATEGORY_RECURSION_DEPTH) {
          return;
        }

        depth++;

        const res = await client.odoClient.query({
          query: GET_CATEGORY,
          variables: {
            categoryId: categoryId,
          },
        });

        if (res?.data?.getCategory) {
          array.unshift(res.data.getCategory.name);
          if (
            res.data.getCategory.parentCategory &&
            res.data.getCategory.parentCategory != ROOT_CATEGORY
          ) {
            await fetchCategoryAndPrepend(
              array,
              res.data.getCategory.parentCategory
            );
          }
        }
      };

      const fetchProductCategories = async () => {
        try {
          const res = await client.odoClient.query({
            query: GET_PRODUCT_CATEGORIES,
            variables: {
              productId: deal.id || deal.meta?.id,
            },
          });

          if (res?.data?.getProductCategories) {
            for (const item of res.data.getProductCategories) {
              if (!item.categoryId) continue;
              if (item.categoryId == ESSENTIAL_TAB_CATEGORY_ID && isMounted) {
                setEssentialTabShop(true);
              }
              let newCategory = [];
              await fetchCategoryAndPrepend(newCategory, item.categoryId);
              if (newCategory.length > 0) {
                results.push(newCategory);
              }
            }
          }
        } catch (e) {
          console.debug(
            '[SummaryScreen::useEffect.fetchProductCategories] Failed to find ProductId - may be a new deal'
          );
        }
      };

      const buildCategoryBreadcrumbs = async () => {
        if (isMounted) {
          setCategoryBusy(true);
        }
        await fetchProductCategories();
        if (isMounted) {
          setCategoryBreadcrumbs(results);
          setCategoryBusy(false);
        }
      };

      buildCategoryBreadcrumbs();
    },
    [deal]
  );

  const VOLUMETRIC_WEIGHT_COEFFICIENT = 4000;
  const greaterWeightValue = data
    ? (data.width * data.height * data.length) / VOLUMETRIC_WEIGHT_COEFFICIENT
    : 0;
  const displayWeightValue = () => {
    if (greaterWeightValue > data.weight) {
      return greaterWeightValue.toString() + 'kg' + ' (Volumetric Weight)';
    } else {
      return data.weight?.toString() + 'kg' + ' (Manual Weight)';
    }
  };

  const [priorityValue, setPriorityValue] = useState('Fetching...');
  useSafeEffect(
    isMounted => {
      if (deal.buyerAndSupplier.priority) {
        const matchToPriority = async () => {
          const { data } = await client.odo.query({
            query: GET_DROPDOWN_VALUES,
            variables: {
              name: DropDowns.PRIORITY,
            },
            errorPolicy: 'ignore',
            fetchPolicy: 'cache-first',
          });

          if (
            data?.getDropDownValues &&
            Array.isArray(data.getDropDownValues)
          ) {
            const match = data.getDropDownValues.find(
              x => x.value == deal.buyerAndSupplier.priority
            );
            if (match) {
              if (isMounted) setPriorityValue(match.key);
            } else {
              if (isMounted)
                setPriorityValue(
                  `Priority match not found for ID ${deal.buyerAndSupplier.priority}`
                );
            }
          }
        };
        matchToPriority();
      }
    },
    [deal]
  );

  const productInformationRows = [
    {
      term: 'Status',
      id: 'status',
      description: data.status ? 'Enabled' : 'Disabled',
    },
    { term: 'Name', id: 'name', description: data.name },
    { term: 'Short Name', id: 'shortName', description: data.shortName },
    { term: 'Priority', id: 'priority', description: priorityValue },
    { term: 'Base SKU', id: 'sku', description: data.sku },
    {
      term: 'Deal Date (From)',
      id: 'activeFromDate',
      description: data.activeFromDate
        ? new Date(data.activeFromDate).toLocaleDateString()
        : 'No Date Selected',
    },
    {
      term: 'Deal Date (To)',
      id: 'activeToDate',
      description: data.activeToDate
        ? new Date(data.activeToDate).toLocaleDateString()
        : 'No Date Selected',
    },
    {
      term: 'Features',
      id: 'features',
      description: data.features ? data.features.split('|').join(' | ') : '',
    },
    { term: 'Pill One', id: 'pill1', description: data.pillOne },
    { term: 'Pill Two', id: 'pill2', description: data.pillTwo },
    { term: 'Taxable Class', id: 'taxableClass', description: data.taxClass },
    { term: 'Area', id: 'area', description: areaLabel },
    { term: 'URL', id: 'url', description: `${productUrl}` },
    {
      term: 'Status Inventory',
      id: '',
      description: data.inventory?.isInStock ? 'In Stock' : 'Out of Stock',
    },
    { term: 'Quantity in Stock', id: '', description: data.inventory.qty },
    { term: 'Original Stock', id: '', description: data.originalStock },
    {
      term: 'Essential Tab Shop',
      id: '',
      description: essentialTabShop ? 'Yes' : 'No',
    },
    {
      term: 'Is Lunchtime Deal?',
      id: 'isLunchtimeDeal',
      description: data.isLunchtimeDeal ? 'Yes' : 'No',
    },
    {
      term: 'Included in campaign mailers?',
      id: 'campaign',
      description: data.campaign?.length > 0 ? 'Yes' : 'No',
    },
    {
      term: 'Is Bestseller?',
      id: 'isBestseller',
      description: data.isBestseller ? 'Yes' : 'No',
    },
    {
      term: 'Preview Only?',
      id: 'isPreviewOnly',
      description: data.isPreviewOnly ? 'Yes' : 'No',
    },
    {
      term: 'Platforms Available',
      id: 'platform',
      description: Array.isArray(data.platform)
        ? data.platform?.join(' | ')
        : data.platform,
    },
    {
      term: 'Greater Weight',
      id: 'greaterWeight',
      description: displayWeightValue(),
    },
    // NOTE: we don't load custom options into the RP deal model anymore
    // and I don't want to load any extra context into this screen
    // our new summary screen (when it comes) can show this value instead
    // {
    //   term: 'Has Custom Options?',
    //   id: 'customOptions',
    //   description: deal.customOptions?.length > 0 ? 'Yes' : 'No',
    // },
  ];

  const savingsPercent = (price, retail) => (1 - price / retail) * 100;
  const savingsRands = (price, retail) => retail - price;

  const costingInfoRows = [
    {
      term: 'Cost Price',
      id: 'cost',
      description: toCurrency(data.cost, 'ZAR', 'en-ZA'),
    },
    {
      term: 'ODO Price',
      id: 'price',
      description: toCurrency(data.price, 'ZAR', 'en-ZA'),
    },
    {
      term: 'Savings',
      id: 'savings',
      description: data.isSavingsInRands
        ? `${toCurrency(savingsRands(data.price, data.retail), 'ZAR', 'en-ZA')}`
        : `${Math.round(savingsPercent(data.price, data.retail).toFixed(2))}%`,
    },
    {
      term: 'Retail Price',
      id: 'retail',
      description: toCurrency(data.retail, 'ZAR', 'en-ZA'),
    },
    {
      term: '',
      id: 'spacer1',
      description: '',
    },
    {
      term: '',
      id: 'spacer2',
      description: '',
    },
    {
      term: 'Surcharge',
      id: 'surcharge',
      description: toCurrency(data.surcharge, 'ZAR', 'en-ZA'),
    },
    {
      term: 'Rebate (%)',
      id: 'rebate',
      description: `${data.rebateDiscount}%`,
    },
    {
      term: 'Delivered by Supplier?',
      id: 'supplierDelivers',
      description: data.isDeliveredBySupplier ? 'Yes' : 'No',
    },
  ];

  const openSupplierSignoffLink = () => {
    const url = `${ODOSignoffBaseUrl}?product_id=${deal.id}`;
    console.debug(`Opening supplier sign off link: ${url}`);
    window.open(url, '_blank');
  };

  const openBuyerSignoffLink = () => {
    const url = `${ODOSignoffBaseUrl}buyer/?product_id=${deal.id}`;
    console.debug(`Opening buyer sign off link: ${url}`);
    window.open(url, '_blank');
  };

  const openDealPreviewLink = () => {
    console.debug(`Opening deal preview link: ${productUrl}`);
    window.open(productUrl, '_blank');
  };

  return (
    <FormLayout>
      {displayErrors && <ErrorCard />}
      <Container>
        <RPSCard css=":host { min-width: 75%; }">
          <div slot="header">
            <h5>{data.name}</h5>
          </div>
          <RPSListContainer className="horizontal">
            <RPSButton cbClick={openBuyerSignoffLink} className="medium">
              ACCOUNT MANAGER SIGN OFF
              <rps-svg slot="slot-right" svg={iconNames.expand} />
            </RPSButton>
            <RPSButton cbClick={openSupplierSignoffLink} className="medium">
              SUPPLIER SIGN OFF
              <rps-svg slot="slot-right" svg={iconNames.expand} />
            </RPSButton>
            <RPSButton
              cbClick={openDealPreviewLink}
              className="medium"
              disabled={!data.url || !data.status}
            >
              OPEN DEAL PREVIEW
              <rps-svg slot="slot-right" svg={iconNames.expand} />
            </RPSButton>
          </RPSListContainer>
          <RPSSummary
            className="space-between"
            data={buyerSupplierRows}
            header="Buyer &amp; Supplier Information"
          />
          <h5>Selected Categories</h5>
          {categoryBusy ? (
            <rps-spinner-pause />
          ) : categoryBreadcrumbs.length > 0 ? (
            <>
              {categoryBreadcrumbs.map((item, index) => {
                return <li key={index}>{item.join(' > ')}</li>;
              })}
            </>
          ) : (
            <em>No categories set for this product</em>
          )}
          <Divider />
          <Container gap={4} space={4}>
            {loadingImage ? (
              <rps-spinner-pause />
            ) : (
              <Flex flexDirection="column">
                <RPSImage
                  css="picture img { object-fit: contain !important; max-width: 30rem; max-height: 30rem;}"
                  canExpand
                  src={imageUri}
                />
              </Flex>
            )}

            <RPSSummary
              className="space-between"
              data={productInformationRows}
              header="Product Information"
            />
          </Container>
        </RPSCard>
        <Container justifyContent="flex-start" flexDirection="column" gap={2}>
          <RPSCard>
            <div slot="header">
              <RPSSummary
                data={costingInfoRows}
                header="Costing Info"
                className="space-between"
              />
            </div>
          </RPSCard>
          <RPSCard>
            <div slot="header">
              <h5>SKU and SKU Additions</h5>
              <span>
                <b>Base SKU:</b> {deal.product.sku}
              </span>
            </div>
          </RPSCard>
        </Container>
      </Container>
    </FormLayout>
  );
};

SummaryScreen.propTypes = {
  overrideDeal: PropTypes.any,
  displayErrors: PropTypes.bool,
};

const Container = styled(Flex)`
  width: 100%;
`;

Container.defaultProps = {
  justifyContent: 'space-between',
  gap: '1rem',
  width: '100%',
  flexWrap: ['wrap', null, 'nowrap'],
  flexGrow: 0,
};
