/**
 * NOTE: calculations of floats in JS (and computing in general) won't always give what you'd expect
 * this is because of how computers store these numbers in binary floating point.
 *
 * You can read more about this here:
 * @see https://floating-point-gui.de/
 * @see https://stackoverflow.com/questions/588004/is-floating-point-math-broken
 *
 * For our sake in BP, I found this function that seems to match excels rounding behaviour.
 * Which I think is close enough for most cases, as biz will mostly be using excel outside of this context.
 *
 * Possible alternative for flooring:
 * Math.floor(Math.pow(10, 2) * num + 0.5) * Math.pow(10, -2)
 */
export const excelRounding = (
  num: number,
  type: 'round' | 'up' | 'down' = 'up',
  precision = 2
) =>
  type === 'up'
    ? Math.ceil(num * Math.pow(10, precision)) / Math.pow(10, precision)
    : type === 'down'
    ? Math.floor(num * Math.pow(10, precision)) / Math.pow(10, precision)
    : Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision);

/**
 * The savings functions both use JS equivalents of the PHP code from MAG `Mage_Catalog_Model_Product.getSaving`:
 * @see https://gitlab.com/onedayonly/magento_1/-/blob/development/app/code/core/Mage/Catalog/Model/Product.php?ref_type=heads#L2630
 */
export const calcSavingsInRands = (price: number, retail: number) =>
  Math.round((retail - price) / 10) * 10;

export const calcSavingsInPercentage = (price: number, retail: number) => {
  const decimal = (1 - price / retail) * 100;
  let percentage = price > 0 ? Math.round(decimal) : 100;
  if (percentage === 100 && price > 0) {
    percentage = Math.floor(decimal);
  }
  return percentage;
};

const COST_INSURANCE_MULTIPLIER = 0.016;

export const calcSurchargeInsurance = (cost: number) =>
  excelRounding(cost * COST_INSURANCE_MULTIPLIER * -1);
