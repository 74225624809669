import { html, css } from 'lit';
import {CustomLitElement} from '../baseClasses/CustomLitElement';
import '../../rps-search';
import '../../rps-card';

export class RemoveUser extends CustomLitElement {
	static styles = css`
			.update-user-container {
			}
    `;

	static get properties() {
		return {
			includeCss: { type: String },
			placeholder: { type: String },
			headerTitle: { type: String },
			userDisplayFields: { type: Array },			// The fields in the user array to display, ie: what user was selected
			/* istanbul ignore */
			users: { type: Array },						   // An object in the array must contain a property "id" along with other desired properties (displayable or otherwise)
			preSelectItems: { type: Array },				// a list of items that must be pre-selected. Only the "id" property is used in this object in the array
		};
	}

	constructor() {
		super();
		this.headerTitle = "Select User's to remove";
		this.users = [];																		// contains the users that were selected to grant rights to
		this.claimDisplayFields = ["firstName", "lastName"];
		this.placeholder = "enter user's name";
		this.preSelectItems = [];
	}

	/* istanbul ignore */
	_usersSelected(event) {
		const detail = { users: event.detail, source: this.tagName };
		event = new CustomEvent("users-selected", { detail, bubbles: true, composed: true });
		this.dispatchEvent(event);
	}

	render() {
		return html`
			<rps-card header="${this.headerTitle}">
				<rps-search mode="multi"
					placeholder="${this.placeholder}" .includeCss="${this.includeCss}"
					.items=${this.users}
					.displayItems=${this.userDisplayFields}
					.preSelectItems=${this.preSelectItems}
					@items-selected=${this._usersSelected}
				>
				</rps-search>
			</rps-card>
    `;
	}
}
