import { html, css } from 'lit';
import { ref, createRef } from 'lit/directives/ref.js';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import '../../rps-card-flip';

/**
 * A holder for a card-flip
 * This allows you to add meta data/html in a container around the card-flip that does not flip with the card
 *
 * @export
 * @class CardFlipHolder
 * @extends {CustomLitElement}
 */
export class CardFlipHolder extends CustomLitElement {
	static styles = css`
			:host {
				display: block;
			}

			h1 {
				margin : var(--margin-large);
				text-align: center;
			}
		`;

	static get properties() {
		return {
			frontTitle: { type: String },
			backTitle: { type: String },
			width: { type: String },
			height: { type: String },
			margin: { type: String },
			cbClick: { attribute: false },
		};
	}


	constructor() {
		super();
		this.width = '20rem';
		this.height = '26rem';
		this.frontTitle = "Front";
		this.backTitle = "Back";
		this.margin = '1rem';
		this.header = createRef();
	}

	/**
	 * Add the slotted contents to the card
	 *
	 * @memberof CardFlipHolder
	 * @private
	 */
	firstUpdated() {
		super.firstUpdated();
		const frontSlot = this.renderRoot.host.querySelector('div[slot="front"]');
		const backSlot = this.renderRoot.host.querySelector('div[slot="back"]');

		const card = this.renderRoot.querySelector('rps-card-flip');
		card.appendChild(frontSlot);
		card.appendChild(backSlot);
	}


	/**
	 * Flip the card
	 *
	 * @param {Event} event
	 * @return {*} 
	 * @memberof CardFlipHolder
	 * @private
	 */
	flip(event) {
		console.debug('CardFlipHolder: Flip', event);

		// Only listen to the click on the rps-card-flip, not other elements
		if (event.target.tagName !== 'RPS-CARD-FLIP') return;

		if (this.header.textContent === this.frontTitle) {
			this.header.textContent = this.backTitle;
		} else {
			this.header.textContent = this.frontTitle;
		}

		event.stopPropagation();

		const detail = {
			source: this.tagName,
			id: this.id,
		};
		event = new CustomEvent('click', { detail, bubbles: true, composed: true, cancelable: true });
		this.dispatchEvent(event);
		console.debug('CardFlipHolder: Flip', event);

		if (this.cbClick) this.cbClick(event);
	}

	render() {
		return html`
		${this.addCss()}

		<div style="width: ${this.width}">
			<h1 style="width: ${this.width}" ${ref(this.header)}>${this.frontTitle}</h1>

			<rps-card-flip
				style="display: block; width:${this.width}; height:${this.height}; margin: ${this.margin};"
				@click=${this.flip}
			>
				<div slot="front"></div>
				<div slot="back"></div>
			</rps-card-flip>
		</div>
	`;
	}
}
