import { CustomLitElement } from './CustomLitElement';
import { createRef, ref } from 'lit/directives/ref.js';

/**
 *
 *
 * @export
 * @class BaseInputControl - render into the BaseInputControl, not the shadow DOM
 * @extends {LitElement}
 */
export class BaseInputControl extends CustomLitElement {

	static get properties() {
		return {
			checked: { type: Boolean },			// is this checkbox checked (if this is a checkbox control)
			caching: { type: Boolean },			// does this control use Caching when reading and writing values
			required: { type: Boolean },			// is input required
			readonly: { type: Boolean },			// is this control a readonly control?
			name: { type: String },					// form name
			label: { type: String },				// label to display
			defaultValue: { type: String },		// default value
			hideLabel: { type: Boolean },			// Must the input-label be hidden?
			disabled: { type: Boolean },
			errorMessage: { String },
			placeHolder: { type: String },
			pattern: { type: String },
			cbInput: { attribute: false },
			cbClick: { attribute: false },
			cbBlur: { attribute: false },
			cbFocus: { attribute: false },
		};
	}

	constructor() {
		super();
		this.errorMessage = '';
		this.placeHolder = '';
		this.defaultValue = "";
		this.pattern = ".*";
		this.caching = false;
		this.checked = false;
		this.required = false;
		this.disabled = false;
		this.hideLabel = false;
		this.readonly = false;
		this.inputCtrl = createRef();
		this.labelCtrl = createRef();
		this.container = createRef();
	}

	/**
	 * return a reference to the input control
	 *
	 * @readonly
	 * @memberof BaseInputControl
	 */
	get inputControl() {
		return this.inputCtrl?.value;
	}


	/**
	 * return a reference to the label control
	 *
	 * @readonly
	 * @memberof BaseInputControl
	 */
	get labelControl() {
		return this.labelCtrl?.value;
	}

	/**
	 * return a reference to the container for this control
	 *
	 * @readonly
	 * @memberof BaseInputControl
	 */
	get containerControl() {
		return this.containerControl?.value;
	}

	/**
	 * Focus the cursor in the input control
	 *
	 * @memberof BaseInputControl
	 */
	focus() {
		this.inputControl.focus();
	}

	/**
	 * Method to change the label control's text
	 *
	 * @param {Boolean} isValid
	 * @returns
	 * @memberof BaseInputControl
	 */
	labelText(isValid) {
		if (!this.errorMessage) return;

		if (isValid) {
			this.labelControl.text = this.label;
		} else {
			this.labelControl.text = this.errorMessage;
		}
	}

	/**
	 * With each "keystroke" or value change perform a validation of the control, and change the warning class and message
	 *
	 * @return {Boolean}
	 * @memberof BaseInputControl
	 */
	validate() {
		// if required and the input value is empty
		if (this.required && this.value === '') return false;

		console.debug('BaseInputControl:validate', this.inputControl.validity.valid);
		// the label control can be hidden
		if (this.labelControl)
			this.labelControl.classList.toggle('warning', !this.inputControl.validity.valid);
		this.container.value.classList.toggle('warning', !this.inputControl.validity.valid);
		return this.inputControl.validity.valid;
	}


	/**
	 * When the control is attached to the DOM read out the cached value if there is one and set the value of the control to this
	 *
	 * @returns
	 * @memberof BaseInputControl
	 */
	connectedCallback() {
		super.connectedCallback();
		const jsonString = this.cache;
		if (!jsonString) return;

		const cachedJSON = JSON.parse(jsonString)

		if (cachedJSON.type === 'RPS-CHECKBOX' || cachedJSON.type === 'RPS-SWITCH') {
			this.checked = cachedJSON.value;
		} else
			this.defaultValue = cachedJSON.value;
	}


	/**
	 * If this control uses caching, then read the data from the cache, and return it
	 *
	 * @memberof BaseInputControl
	 */
	get cache() {
		//console.debug(this.tagName, this.caching, 'get:cache');
		if (!this.caching) return undefined;

		return sessionStorage.getItem(this.name);
	}

	/**
	 * If using caching, then set the cache value
	 *
	 * @memberof BaseInputControl
	 */
	set cache(value) {
		//console.debug(this.tagName, this.caching, 'setting cache:', value);
		if (!this.caching) return;

		const json = { type: this.tagName, value: value };
		sessionStorage.setItem(this.name, JSON.stringify(json))
	}

	/**
	 * If using caching, then remove the cached value from the sessionStorage
	 *
	 * @memberof BaseInputControl
	 */
	clear() {
		//console.debug(this.tagName, this.caching, 'removing cache:', value);
		if (!this.caching) return;

		sessionStorage.removeItem(this.name)
	}

	/**
	 * Does this control have caching, and has a value been set
	 *
	 * @readonly
	 * @memberof BaseInputControl
	 */
	get hasCache() {
		return this.caching && this.cache;
	}

	/**
	* return the value in this control
	*
	* @readonly
	* @memberof BaseInputControl
	*/
	get value() {
		// When controls are being rendered it is possible for the rps-input (eg) to exist but the "<input" to not exist yet
		if (!this.inputControl) {
			// this can happen in controls like rps-grid-input and the rendering sequence, and the calling of this method
			//console.debug(`${this.tagName}: id${this.id} does not have an <input> control yet`);
			return;
		}
		return this.inputControl.value;
	}


	/**
	 * Set the value of this input control from code.
	 *
	 * @readonly
	 * @memberof BaseInputControl
	 */
	set value(newValue) {
		// When controls are being rendered it is possible for the rps-input (eg) to exist but the "<input" to not exist yet
		if (!this.inputControl) {
			// this can happen in controls like rps-grid-input and the rendering sequence, and the calling of this method
			//console.debug(`${this.tagName}: id${this.id} does not have an <input> control yet`);
			return;
		}
		return this.inputControl.value = newValue;
	}

	/**
	 * Is this control valid?
	 *
	 * @readonly
	 * @memberof BaseInputControl
	 */
	get isValid() {
		return this.inputControl?.validity.valid;
	}

	/**
	 * Is this control checked?
	 * @description Only used in checkbox and switch
	 *
	 * @readonly
	 * @returns {Boolean}
	 * @memberof BaseInputControl
	 */
	get isChecked() {
		return this.inputControl ? this.inputControl.checked : false;
	}

	/**
	 * Every time the value for the control changes, fire this event
	 *
	 * @param {Event} e - the Event that fired this method
	 * @memberof baseClasses
	 */
	inputChanged(e) {
		e.stopPropagation();

		if (this.getFileData) this.getFileData(e);		// Only exists on rps-input

		const isValid = this.validate();
		const input = this.inputControl;
		this.cache = this.inputControl.value;
		const detail = {
			value: input.value,
			checked: this.isChecked,
			source: this.tagName,
			id: this.id,
			name: this.name,
			inputControl: input,
			isValid,
		};

		e = new CustomEvent('input', { bubbles: true, cancelable: true, composed: true, detail });
		this.dispatchEvent(e);

		if (this.cbInput) this.cbInput(e);
	}

	/**
	 * Fires when the input control gains focus
	 * @param {Event} event
	 * @memberof BaseInputControl
	 */
	_focus(event) {
		console.debug('BaseInputControl:focus', event.target.tagName);
		event.preventDefault();
		event.stopPropagation();

		const detail = {
			control: this,
			source: this.tagName,
			value: this.inputControl.value
		}

		event = new CustomEvent('focus', { bubbles: true, cancelable: true, composed: true, detail });
		this.dispatchEvent(event);
		if (this.cbFocus) this.cbFocus(event);
	}

	/**
	 * Fires when the input control loses focus
	 * @param {Event} event
	 * @memberof BaseInputControl
	 */
	_blur(event) {
		console.debug('BaseInputControl:blur', event.target.tagName);
		event.preventDefault();
		event.stopPropagation();

		const detail = {
			control: this,
			source: this.tagName,
			value: this.inputControl.value
		}

		event = new CustomEvent('blur', { bubbles: true, cancelable: true, composed: true, detail });
		this.dispatchEvent(event);
		if (this.cbBlur) this.cbBlur(event);
	}
}
