import { html } from 'lit';
import { createRef, ref } from 'lit/directives/ref.js';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { styles } from './css/listContainer.css.js';
export class ListContainer extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
		};
	}

	constructor() {
		super();
		this.listControl = createRef();
	}

	/**
	 * Easy way to retrieve the TileItem control externally
	 *
	 * @param {String} id - The Id of the Item to retrieve
	 * @readonly
	 * @memberof ListContainer
	 */
	itemControl(id) {
		return this.listControl.value.querySelector(`#${id}`);
	}

	render() {
		return html`
			${this.addCss()}
			<div id="list-container" class="${this.className}" ${ref(this.listControl)}>
				<slot class="slot-main"></slot>
			</div>
		`;
	}

}

