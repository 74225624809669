/**
 * @name rps-date-select-range
 * @type {DateSelectRange}
 * 	Composite component that combines two simple rps-input[type=date] components into a
 * 	single component for selecting a start date and end date.
 *		Provides basic
 *		The input event fires off once both pickers have a date selected.
 *
 *	@attributes + @properties
 * 	@property {String} 	leftLabel = "Start Date"| Label to render over the left picker (Default: "Start Date")
 * 	@property {String} 	rightLabel = "End Date"	| Label to render over the right picker (Default: "End Date")
 * 	@property {String} 	name = '' 					| Name used to build caching keys for this input and its children
 *		@property {Boolean} 	caching = false			| Enable auto-caching?
 *		@property {Boolean} 	readonly = false			| Set inputs to read only
 * 	@property {String}	customCss					| Specify a custom .css file to include, use cssPath to override elements,
 * 																	DO NOT override the styling definition by re-defining the css styling
 * 																	Put this file into the *window.cssPath* folder @see {link README.md}
 * 																	eg: /public/css (where the other css files are)
 * 																	Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css							| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 *
 * @events
 * 	@fires InputEvent input 							| Receives selected dates in `ev.detail.startDate` and `ev.detail.endDate`
 *
 * @callbacks
 * 	@callback cbInput 									| Same as the input event, provides mechanism for React style prop callback invocation
 *
 * @methods
 * 	validate													| Return whether this control input data is valid
 *
 * @properties
 * 	@get {Input}	startDateControl 					| Gets the rps-input component used for the Start Date
 * 	@get {Input}	endDateControl 					| Gets the rps-input component used for the End Date
 *
 *	@slots
 * 	No slots
 *
 * @example @React
 * 	const onDatesChange = (ev) => {
 * 		console.log(ev.detail.startDate);
 * 		console.log(ev.detail.endDate);
 * 	}
 * 
 *  	useEffect(() => {
 *				input.current.cbInput = (e) => onDatesChange;
 *				input.current.cbIconInput = (e) => console.log(e);
 *  	}, [])
 * 
 * 	return <rps-date-select-range cbInput={onDatesChange}><rps-date-select-range>
 */

import { DateSelectRange } from './src/date/DateSelectRange';
if (!window.customElements.get('rps-date-select-range'))
	window.customElements.define('rps-date-select-range', DateSelectRange);
