import { InputNumeric, Switch } from '@odo/components/elements/form-fields';
import { Flex, Grid, GridItem } from '@odo/components/elements/layout';
import { FaChevronRight as IconRight } from 'react-icons/fa';
import Button from '@odo/components/elements/button';
import styled from '@odo/lib/styled';
import {
  useChangeProduct,
  useCurrentProduct,
} from '@odo/contexts/product-editor';
import { cssColor } from '@odo/utils/css-color';
import Tooltip from '@odo/components/widgets/tooltip';

/**
 * NOTE: time fields will be hidden underneath their respective date fields and slide down when needed.
 */
const TimeFieldWrapper = styled(Flex)`
  transition: all 0.25s ease-in-out;
  transform: translateY(-45px);
  opacity: 0;
  height: 0;

  &.active {
    transform: translateX(0);
    opacity: 1;
    margin-top: 16px;
    height: 35px;
  }
`;

/**
 * NOTE: we use grid areas to reposition elements on mobile/desktop
 *
 * mobile:
 * deal time switch
 * from date, same button, to date
 * from time, gap, to time
 *
 * desktop:
 * from date, same button, to date, deal time switch
 * from time, gap, to time
 */
const ActiveDateTime = ({
  mobileTimedSwitchAsButton,
}: {
  mobileTimedSwitchAsButton?: boolean;
}) => {
  const currentProduct = useCurrentProduct();
  const change = useChangeProduct();

  return (
    <Grid
      gridTemplateColumns={['repeat(3, auto)', null, 'repeat(4, auto)']}
      gridTemplateAreas={[
        '"timed-mobile . ." "from-date same to-date" "from-time . to-time"',
        null,
        '"from-date same to-date timed" "from-time . to-time ."',
      ]}
      justifyContent="flex-start"
      columnGap={3}
      rowGap={0}
    >
      <GridItem
        gridArea="from-date"
        style={{ zIndex: 1, background: cssColor('foreground') }}
      >
        <InputNumeric
          required
          type="date"
          max="9999-12-31"
          label="Active From"
          value={currentProduct.activeFromDate || ''}
          onChange={e => {
            const value = e.target.value;
            change({
              fieldId: 'activeFromDate',
              label: 'Active From Date',
              apply: to => (to.activeFromDate = value),
            });
          }}
          style={{ padding: '7px 5px', textIndent: '2px' }}
        />
      </GridItem>

      <GridItem gridArea="same" alignSelf="flex-end">
        <Tooltip
          showDelay={500}
          content={() => 'Make active to date the same as from date'}
        >
          <Button
            circular
            variant="outlined"
            hue="grey"
            borderWidth="1px"
            px={1}
            py={1}
            mb={1}
            onClick={() =>
              change({
                fieldId: 'activeToDate',
                label: 'Active To Date',
                apply: to => (to.activeToDate = currentProduct.activeFromDate),
              })
            }
          >
            <IconRight />
          </Button>
        </Tooltip>
      </GridItem>

      <GridItem
        gridArea="to-date"
        style={{ zIndex: 1, background: cssColor('foreground') }}
      >
        <InputNumeric
          required
          type="date"
          max="9999-12-31"
          label="Active To"
          value={currentProduct.activeToDate || ''}
          onChange={e => {
            const value = e.target.value;
            change({
              fieldId: 'activeToDate',
              label: 'Active To Date',
              apply: to => (to.activeToDate = value),
            });
          }}
          style={{ padding: '7px 5px', textIndent: '2px' }}
        />
      </GridItem>

      <GridItem
        gridArea="timed-mobile"
        display={[null, null, 'none']}
        alignSelf="flex-end"
        pb={3}
      >
        {!!mobileTimedSwitchAsButton ? (
          <Button
            variant="outlined"
            hue="grey"
            borderWidth="1px"
            onClick={() => {
              const checked = !currentProduct.isTimedDeal;
              change({
                fieldId: 'isTimedDeal',
                label: 'Timed Deal',
                apply: to => (to.isTimedDeal = checked),
              });
            }}
          >
            TIME
            <Switch
              checked={!!currentProduct.isTimedDeal}
              width="2.5em"
              tabIndex={-1}
              readOnly
            />
          </Button>
        ) : (
          <Switch
            label="Time"
            width="3.5em"
            checked={!!currentProduct.isTimedDeal}
            onChange={e => {
              const checked = !!e.target.checked;
              change({
                fieldId: 'isTimedDeal',
                label: 'Timed Deal',
                apply: to => (to.isTimedDeal = checked),
              });
            }}
          />
        )}
      </GridItem>

      <GridItem
        gridArea="timed"
        display={['none', null, 'block']}
        alignSelf="flex-end"
      >
        <Button
          variant="outlined"
          hue="grey"
          borderWidth="1px"
          onClick={() => {
            const checked = !currentProduct.isTimedDeal;
            change({
              fieldId: 'isTimedDeal',
              label: 'Timed Deal',
              apply: to => (to.isTimedDeal = checked),
            });
          }}
        >
          Time
          <Switch
            checked={!!currentProduct.isTimedDeal}
            width="2.5em"
            tabIndex={-1}
            readOnly
          />
        </Button>
      </GridItem>

      <GridItem gridArea="from-time">
        <TimeFieldWrapper
          className={currentProduct.isTimedDeal ? 'active' : undefined}
        >
          <InputNumeric
            type="time"
            min="00:00"
            max="23:59"
            value={currentProduct.activeFromTime || ''}
            tabIndex={!currentProduct.isTimedDeal ? -1 : undefined}
            onChange={e => {
              const value = e.target.value;
              change({
                fieldId: 'activeFromTime',
                label: 'Active From Time',
                apply: to => (to.activeFromTime = value),
              });
            }}
            style={{ padding: '6px 8px', textIndent: 'unset', width: 'auto' }}
          />
        </TimeFieldWrapper>
      </GridItem>

      <GridItem gridArea="to-time">
        <TimeFieldWrapper
          className={currentProduct.isTimedDeal ? 'active' : undefined}
        >
          <InputNumeric
            type="time"
            min="00:00"
            max="23:59"
            value={currentProduct.activeToTime || ''}
            tabIndex={!currentProduct.isTimedDeal ? -1 : undefined}
            onChange={e => {
              const value = e.target.value;
              change({
                fieldId: 'activeToTime',
                label: 'Active To Time',
                apply: to => (to.activeToTime = value),
              });
            }}
            style={{ padding: '6px 8px', textIndent: 'unset', width: 'auto' }}
          />
        </TimeFieldWrapper>
      </GridItem>
    </Grid>
  );
};

export default ActiveDateTime;
