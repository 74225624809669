import { Checkbox } from '@odo/components/elements/form-fields';
import { useChangeProduct } from '@odo/contexts/product-editor';
import type { EditorProductImage } from '@odo/types/portal';
import { ImageType } from '@odo/types/api';

const OptionCheckbox = ({
  id,
  imageTypes,
  type,
  typeFieldId,
  typeLabel,
}: {
  id: EditorProductImage['id'];
  imageTypes: EditorProductImage['imageTypes'];
  type: ImageType;
  typeFieldId: string;
  typeLabel: string;
}) => {
  const change = useChangeProduct();

  return (
    <Checkbox
      label={typeLabel}
      checked={(imageTypes || []).includes(type)}
      onChange={e => {
        const checked = !!e.target.checked;
        change({
          // NOTE: there can only be one image with each type
          // so we use the imageType as the fieldId instead of the image.id
          fieldId: `images.imageTypes.${typeFieldId}`,
          label: `Toggle Image for ${typeLabel}`,
          apply: to => {
            to.images = to.images
              ? to.images.map(i => ({
                  ...i,
                  imageTypes: [
                    ...(i.imageTypes || []).filter(
                      imageType => imageType !== type
                    ),
                    ...(i.id === id && checked ? [type] : []),
                  ],
                }))
              : to.images;
          },
        });
      }}
    />
  );
};

const ImageTypeOptions = ({ image }: { image: EditorProductImage }) => (
  <>
    <OptionCheckbox
      id={image.id}
      imageTypes={image.imageTypes}
      type={ImageType.email}
      typeFieldId="email"
      typeLabel="Email"
    />

    <OptionCheckbox
      id={image.id}
      imageTypes={image.imageTypes}
      type={ImageType.website}
      typeFieldId="website"
      typeLabel="Website"
    />

    <OptionCheckbox
      id={image.id}
      imageTypes={image.imageTypes}
      type={ImageType.emailThumbnail}
      typeFieldId="emailThumbnail"
      typeLabel="Thumbnail"
    />
  </>
);

export default ImageTypeOptions;
