import React, { useMemo } from 'react';
import {
  RPSCard,
  RPSListContainer,
  RPSKeyValueTable,
  RPSButton,
} from '@rps/web-components/build/react-wrappers';
import { iconNames } from '@rps/web-components/build/web-components';
import { calculateProfit } from 'utils/calculator/priceCalculator';
import { useCurrentDealSource } from 'hooks/useCurrentDealSource';
import {
  resultRowProviders,
  resultRowsBasics,
} from 'components/calculator/ResultRows';
import { getAdminCostValue } from './../../../utils/getAdminCostValue';

export const CalculationSection = () => {
  const currentDeal = useCurrentDealSource();
  const model = currentDeal.deal.priceAndCustomOptions;
  const shippingModel = currentDeal.deal.shipping;

  const calcResult = useMemo(() => {
    let adminCost = getAdminCostValue(model.adminCost);
    const res = calculateProfit({
      price: +model.price,
      retail: +model.retail,
      cost: +model.cost,
      vat: true,
      insurance: model.insurance,
      rebate: +model.rebateDiscount,
      admin: adminCost,
      surcharge: +model.surcharge,
      soh: shippingModel.originalStock,
    });
    return res;
  }, [
    model.adminCost,
    model.cost,
    model.insurance,
    model.price,
    model.rebateDiscount,
    model.retail,
    model.surcharge,
    shippingModel.originalStock,
  ]);

  const openPriceCalculatorLink = () => {
    const url = `${process.env.REACT_APP_ODO_BASE_URL}/price_calculator?deal=${currentDeal.deal.id}`;
    window.open(url, '_blank');
  };

  return (
    <RPSCard>
      <div slot="header">
        <h5>Price Calculator</h5>
      </div>
      <RPSListContainer className="vertical">
        <RPSListContainer className="vertical">
          {!!currentDeal.deal.id && (
            <RPSButton cbClick={openPriceCalculatorLink} className="medium">
              ODO ADMIN PRICE CALCULATOR
              <rps-svg slot="slot-right" svg={iconNames.expand} />
            </RPSButton>
          )}
          <RPSKeyValueTable
            css=":host { width: 100%; margin: 0; } .rowCell {text-align: right;} "
            rows={resultRowsBasics}
            data={calcResult}
          />
          <RPSKeyValueTable
            css=":host { width: 100%; margin: 0; } .rowCell {text-align: right;} "
            rows={resultRowProviders}
            data={calcResult}
          />
        </RPSListContainer>
      </RPSListContainer>
    </RPSCard>
  );
};
