import { html } from 'lit';
import { ref, createRef } from 'lit/directives/ref.js';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import '../../rps-profile';
import { styles } from './css/layoutHeader.css.js';
import { iconNames } from '../../svg-icons';
import '../../rps-svg';
import '../../rps-toolbar';
export class LayoutHeader extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			headerTitle: { type: String },
			logo: { type: String },
			version: { type: String },
			toolBarButtons: { type: Array },
		};
	}

	constructor() {
		super();
		this.logo = "../../images/company-logo.svg";
		this.headerTitle = 'Admin Portal';
		this.burgerDivRef = createRef();
		this.offlineRef = createRef();
		this.offlineToastRef = createRef();
		this.toolBarButtons = [
			{ id: "settings", svg: `${iconNames.settings}`, title: "Settings" },
			{ id: "user", svg: `${iconNames.user}`, title: "User" },
		];

		window.addEventListener('online', e => this._onLine(this));
		window.addEventListener('offline', e => this._offLine(this));
	}

	/**
	 * Show the icon in thew toolbar, and show the toast
	 *
	 * @param {this} me
	 * @memberof LayoutHeader
	 */
	_offLine(me) {
		console.debug('LayoutHeader: Internet [offline]');
		me.offlineControl.classList.remove('hidden');
		me.offlineToastControl.show();
	}

	/**
	 * Hide the icon in thew toolbar, and hide the toast
	 *
	 * @param {this} me
	 * @memberof LayoutHeader
	 */
	_onLine(me) {
		console.debug('LayoutHeader: Internet [online]');
		me.offlineControl.classList.add('hidden');
		me.offlineToastControl.hide();
	}

	/**
	 * A reference to the burger menu control
	 *
	 * @readonly
	 * @memberof LayoutHeader
	 */
	get burgerDivControl() {
		return this.burgerDivRef.value;
	}

	/**
	 * A reference to the offline control
	 *
	 * @readonly
	 * @memberof LayoutHeader
	 */
	get offlineControl() {
		return this.offlineRef.value;
	}

	/**
	 * A reference to the offline toast control
	 *
	 * @readonly
	 * @memberof LayoutHeader
	 */
	get offlineToastControl() {
		return this.offlineToastRef.value;
	}

	/**
	 * Open (show) this menu item
	 *
	 * @param {Event} event
	 * @memberof LayoutHeader
	 */
	menuDisplayToggle() {
		document.querySelector('rps-layout').toggleMenu();
		this.burgerDivControl.classList.toggle('toggle');
	}

	/**
	 * Open or close the right menu
	 *
	 * @memberof LayoutHeader
	 */
	rightMenuDisplayToggle() {
		document.querySelector('rps-layout').toggleRightMenu();
	}

	/**
	 * Look at the events fired from the toolbar and open / close the right menu if the "user" was clicked on
	 *
	 * @param {event} event
	 * @memberof LayoutHeader
	 */
	toolbarClick(event) {
		console.debug('LayoutHeader:toolbarClick', event);
		if (event.detail.buttonId === 'user') {
			this.rightMenuDisplayToggle();
		}
	}

	/**
	 * Fires when the logo is clicked on
	 * @param {Event} ev
	 * @memberof LayoutHeader
	 */
	clickLogo(ev) {
		console.debug('LayoutHeader:clickLogo', ev);

		ev.preventDefault();
		ev.stopPropagation();

		const detail = {
			source: this.tagName
		};

		const event = new CustomEvent('clickLogo', { bubbles: true, cancelable: true, composed: true, detail });
		this.dispatchEvent(event);
	}

	render() {
		return html`
			${this.addCss(true)}

			<rps-toast text="Your internet connection is offline" class="warning"
				position="topCenter" effect="fade" repeat repeatseconds=5 expireseconds=5
				${ref(this.offlineToastRef)}
			>
			</rps-toast>

			<nav class="${this.className}">
				<div class="menu-toggle" ${ref(this.burgerDivRef)}>
					<div class="burger-icon" @click=${this.menuDisplayToggle}><span></span></div>
				</div>
				<ul>
					<li class="logo-title" @click=${this.clickLogo}>
						<a href="#">
							<img src="${this.logo}" alt=""/>
						</a>
					</li>
					<li class="header-title">
						<h6>${this.headerTitle}</h6>
						<span>${this.version}</span>
					</li>
					<li class="status hidden" ${ref(this.offlineRef)}>
						<rps-svg svg="${iconNames.noConnection}"></rps-svg>
						<span>Offline</span>
					</li>
				</ul>
				<rps-toolbar .buttons=${this.toolBarButtons} class="layout-toolbar" @click=${this.toolbarClick}></rps-toolbar>
			</nav>
			`;
	}
}

