import { html } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { styles } from './css/card.css.js';

export class Card extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			header: { type: String },
			footer: { type: String },
		};
	}


	render() {
		const hasFooter = !!this.footer;
		let footerText = hasFooter ? html`<div class="footer">${this.footer}</div>` : '';

		return html`
		${this.addCss()}
		<div class="card-container">
		<div class="card ${this.className}">
			<div class="card-media">
				<slot name="media"></slot>
			</div>
			<div class="card-content">
				<slot name="navigation"></slot>

				<div class="card-header">
					<slot name="left"></slot>
					<slot name="header">${this.header}</slot>
					<slot name="right"></slot>
				</div>

				<slot></slot>

				<slot name="footer">${footerText}</slot>
			</div>
		</div>
	</div>
		`;
	}
}
