/**
 * @name rps-file-dragdrop
 * @type {FileDragDrop}
 * A control that handles files being dragged/dropped onto it
 * 
 * 
 * @attributes + @properties 				| Changes trigger a re-render
 * Casing usages:								| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String} fileMask		| Optional: default = "*" eg: "image/png, image/jpeg"
 * 	@property {Object} createChip		| Optional: Override the Chip generation
 * 													eg:
 * 														<rps-file-dragdrop
 * 															.createChip=${(file, id) => html`<rps-chip id="${id}" text="${file.name}" class="primary"></rps-chip>`
 * 														</rps-file-dragdrop>
 * 												
 * 	@property {String} customCss		| Specify a custom .css file to include, use cssPath to override elements,
 * 													DO NOT override the styling definition by re-defining the css styling
 * 													Put this file into the *window.cssPath* folder @see {link README.md}
 * 													eg: /public/css (where the other css files are)
 * 													Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css			| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 
 * 
 * @properties (JS Only)
 * ====================
 * 	fileObjects								| Optional, eg:
 * 													const files = document.querySelector('rps-file-dragdrop').fileObjects;
 * 													Retrieves the File objects that have been added to the drop container
 * 	get dropContainer						| get a reference to the dropContainer
 * 												
 * 
 * @methods
 * 	clear()									| Remove all the dropped files from the drop container
 * 
 * @events
 * 	drop										| Fires when an item is deleted or edited
 * 	dragover									| Fires when an item is dragged over the drop container for the first time
 * 	dragleave								| Fires when an item is dragged out of this control
 * 
 * @callbacks
 * 	cbPreDrop								| Called before the drop event is fired. a false prevents the default behaviour and leaves it all to the developer
 * 	cbDrop									| Called when An item is deleted or edited
 * 	cbDragOver								| Called when an item is dragged over the drop container for the first time
 * 	cbDragLeave								| Called when an item is dragged out of this control
 * 
 * @slots
 * 	None
 * 
 * @example+ @lit
 * @basic
 * ======
 * import '@rps-wc/rps-file-dragdrop';
 * 
 * 	<rps-file-dragdrop @click=${e => console.log(e)}>
 * 	</rps-file-dragdrop>
 * 
 * @advanced
 * =========
 * import '@rps-wc/rps-file-dragdrop';
 * 
 * 	<rps-file-dragdrop @click=${e => console.log(e)}>
 *			.createChip=${(file, id) => html`<rps-chip id="${id}" text="${file.name}" class="primary"></rps-chip>`
 * 	</rps-file-dragdrop>
 * 
 * @example+ @ReactJS
 * @basic
 * ======
 * import '@rps-wc/rps-file-dragdrop';
 * 
 * 	<rps-file-dragdrop>
 * 	</rps-file-dragdrop>
 * 
 * @advanced
 * =========
 * import '@rps-wc/rps-file-dragdrop';
 * 
 * 	useEffect(() => {
 * 		drag.current.cbPreDrop = {e => console.log(e)}
 * 		drag.current.cbDrop = {e => console.log(e)}
 * 		drag.current.cbDragOver = {e => console.log(e)}
 * 		drag.current.cbDragLeave = {e => console.log(e)}
 * 		drag.current.createChip=${(file, id) => html`<rps-chip id="${id}" text="${file.name}" class="primary"></rps-chip>`
 * 	}, [])
 * 
 * 	<rps-file-dragdrop ref={drag}>
 * 	</rps-file-dragdrop>
 * 
 * 
*/

import { FileDragDrop } from './src/dragdrop/FileDragDrop';
if (!window.customElements.get('rps-file-dragdrop'))
	window.customElements.define('rps-file-dragdrop', FileDragDrop);

