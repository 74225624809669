/**
 * @name rps-button-icon
 * @type {ButtonIcon}
 * 	Composite component that combines two simple rps-input[type=date] components into a
 * 	single component for selecting a start date and end date.
 *		Provides basic
 *		The input event fires off once both pickers have a date selected.
 *
 *	@attributes + @properties
 * 	@property {String}	customCss						| Specify a custom .css file to include, use cssPath to override elements,
 * 																		DO NOT override the styling definition by re-defining the css styling
 * 																		Put this file into the *window.cssPath* folder @see {link README.md}
 * 																		eg: /public/css (where the other css files are)
 * 																		Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String}	css								| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 *		@property {Boolean} 	disabled = false				| Is this control disabled
 *
 * @events
 * 	@fires click												| Fired when the button is clicked on
 *
 * @callbacks
 * 	cbClick														| Called when the button is clicked
 *
 * @methods
 * 	get buttonControl											| Retrieves the <button> control
 * 	toggleClass(className, toAdd = true)				| A method to toggle a class for the button control
 * 																	| className = the className to add or remove
 *
 * @properties
 * 	No properties
 *
 *	@slots															|Typically used to display a .svg. use <rps-svg>
 * 	default: 													| ie: the HTML between the opening and closing tags of this control
 * 	slot-left													| Optional slotted content to the left of the button text]
 * 	slot-right													| Optional slotted content to the right of the button text]
 * 
 * @Styling
 * Colors:
 * 	primary:		Primary colors used for the button
 * 	secondary:  Secondary colors used for the button
 * 	tertiary: 	Tertiary colors used for the button
 * Sizes:
 * 	small:		Small button
 * 	medium:		Medium size button
 * 	large:		Large size button
 * Filling:		
 * 	outline: 	Only an outline for the button displays, no filling
 * 	solid:		The contents of the button are filled, not only the outline
 * Status:		
 * 	help: 		Displays the background color in the "help color"
 * 	info:			Displays the background color in the "info color"
 * 	success:		Displays the background color in the "success color"
 * 	warning:		Displays the background color in the "warning color"
 * 	error:		Displays the background color in the "error color"
 * 
 * 
 * @example+ @Lit + @basic
 * import '@rps-wc/rps-button.js';
 * 
 * 	onClicked(ev) {
 * 		console.log(ev.detail);
 * 	}
 *
 * <rps-button @click=${onClicked} id='one'>
 * </rps-button>
 * 
 * 
 * @example+ @Lit + @advanced
 * import '@rps-wc/rps-button.js';
 * import { iconNames } from '@rps-wc/svg-icons';
 * 
 * 	<rps-button @click=${e => console.log(e)} class="large secondary outline">
 * 		<div slot="slot-left">
 * 			<rps-svg svg="${iconNames.comments}"></rps-svg>					
 * 		</div>
 * 		Submit
 * 		<div slot="slot-right">
 * 			<rps-svg svg="${iconNames.success}"></rps-svg>
 * 		</div>
 * 	</rps-button>
 * 
 *
 * @example+ @React + @basic
 * import '@rps-wc/rps-button.js';
 * 
 * 	const onClicked = (ev) => {
 * 		console.log(ev.detail);
 * 	}
 *
 * 	return <rps-button onClick={onClicked}><rps-button>
 * 
 * 
 * @example+ @react + @advanced
 * import '@rps-wc/rps-button.js';
 * import { iconNames } from '@rps-wc/svg-icons';
 * 
 * 	useEffect(() => {
 * 		button.current.cbClick= {e => console.log(e)}
 * 	}, [])
 * 
 * 
 * <rps-button class="large secondary outline" ref={button}>
 * 	<div slot="slot-left">
 * 		<rps-svg svg={iconNames.comments}></rps-svg>					
 * 	</div>
 * 	Submit
 * 	<div slot="slot-right">
 * 		<rps-svg svg={iconNames.success}></rps-svg>
 * 	</div>
 * </rps-button>
 * 
 */
import { Button } from './src/buttons/Button.js';
if (!window.customElements.get('rps-button'))
	window.customElements.define('rps-button', Button);

