/**
 * @name rps-summary
 * @type {Summary}
 * 	A generic summary control
 * 
 * @attributes + @properties 				| Changes trigger a re-render
 * Casing usages:								| Attribute in html (lower case), Property in JS (Camel Case)
 * 
 * 	@property {Array}	data				| An array of items to display in the summary body
 * 													example item in array: id, term and description are required (itemState is optional)
 * 													[
 * 														{
 * 															id: 'a',
 * 															term: 'Term',
 * 															description: 'Description',
 * 															itemState: 'error',
 * 														},
 * 														{
 * 															id: 'b',
 * 															term: 'Term',
 * 															description: 'Description',
 * 														},
 * 													]
 * 	
 * 	@property {Array}	result				| An array of items to display in the tree
 * 														example item in array: id, term and description are required (itemState is optional)
 * 														[
 * 															{
 * 																id: 'f',
 * 																footerTerm: 'Footer Term',
 * 																footerDescription: 'Footer Description',
 * 																itemState: 'warning',
 * 															},
 * 														]
 * 									
 *		@see {link /demo/easy/summary.html}
 *		@see {link /demo/easy/summary-finances.html}
 *		@see {link /demo/easy/summary-clean.html}
 *		for examples
 * 
 * 
 * 
 * 
 * 
 * 	@property {String} customCss 			| Specify a custom .css file to include, use cssPath to override elements,
 * 														DO NOT override the styling definition by re-defining the css styling
 * 														Put this file into the *window.cssPath* folder @see {link README.md}
 * 														eg: /public/css (where the other css files are)
 * 														Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String} css					| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 	
 * @styling (classNames)	
 * 	@example 									| eg: <rps-summary class="space-between"></rps-summary>
 *  align-left (Default)						| Align both columns of text to the left
 *  align-right									| Align both columns of text to the Right
 *  center											| Align both columns of text to the Center
 *  space-between									| Align both columns to the sides of the conatiner
 *  flip-weight									| Flip the font weight of term and definition around from bold to regular
 * 	@itemState	
 *  info												| Change text color to blue
 *  success											| Change text color to success
 *  warning											| Change text color to warning
 *  error											| Change text color to red
 *  disabled										| Change text color to grey
 *  bold												| Make text bold
 * 
 * @methods
 * 	None
 * 
 * @events
 * 	See @link {./rps-summary.js} for more details
 * 
 * @slots
 * 	left											| Slot on the left of heading. (text or HTML)
 * 	right											| Slot on the right of heading. (text or HTML)
 * 	default										| Summary to display. (the text or HTML that is used for the "summary")
 * 
 * 
 * @examples + @generic + @Lit
 * @basic
 * import '@rps-wc/rps-summary';
 * 
 * 	<rps-summary header="Ghosts & Stuff" footer="Little footer details" class="">
 * 		<div slot="left">
 * 			<rps-svg svg="${iconNames.link}"></rps-svg>
 * 		</div>
 * 		<div slot="right">
 * 			<rps-svg svg="${iconNames.expand}"></rps-svg>
 * 		</div>
 * 		Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line.
 * 	</rps-summary>
 * 
*/
import { Summary } from './src/general/Summary.js';
if (!window.customElements.get('rps-summary'))
	window.customElements.define('rps-summary', Summary);

