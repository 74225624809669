/**
 * @name rps-card
 * @type {Card}
 * A generic card control
 * 
 * @notes
 * 	Use either the "header" attribute, opr the header slot for more customization
 * 
 * 
 * @attributes + @properties 				| Changes trigger a re-render
 * Casing usages:								| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String} header 			| 
 * 	@property {String} footer 			| 
 * 	@property {String} customCss 		| Specify a custom .css file to include, use cssPath to override elements,
 * 													DO NOT override the styling definition by re-defining the css styling
 * 													Put this file into the *window.cssPath* folder @see {link README.md}
 * 													eg: /public/css (where the other css files are)
 * 													Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String} css				| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 
 * @styling
 * 	small										| Display the card in a small size
 * 	medium									| Display the card in a medium size
 * 	large										| Display the card in a large size
 * 
 * @methods
 * 	None
 * 
 * @events
 * 	None
 * 
 * @slots
 * 	@header									| slots in header
 * 	left										| Slot in header on left side
 * 	header									| Slot in header on center side
 * 	right										| Slot in header on right side
 * 
 * 	default (ie: the children elements other than slotted content )
 * 
 * 	footer									| 
 * 
 * @examples + @generic + @Lit
 * @basic
 * import '@rps-wc/rps-card';
 * 
 * <rps-card header="add user">
 * 	<h4>Light card title</h4>
 * 	Some quick example text to build on the card title and make up the bulk of the card's content.
 * </rps-card>
 * 
 * 
 * @advanced usage, use slots for custom html
 * import '@rps-wc/rps-card';
 * 
 * <rps-card>
 * 	<div slot="header">Header text</div>
 * 
 * 	<h4>Light card title</h4>
 * 	Some quick example text to build on the card title and make up the bulk of the card's content.
 * 
 * 	<div slot="footer">Footer text</div>
 * </rps-card>
 * 
*/
import { Card } from './src/general/Card';
if (!window.customElements.get('rps-card'))
	window.customElements.define('rps-card', Card);

