/**
 * 
 * @name rps-svg
 * @type {SvgIcon}
 * 	A component that displays an svg file
 * 
 * 	Files are chosen from the list:
 * 		import { iconNames } from '@rps-wc/svg-icons';
 *
 *	@attributes + @properties
 * 	@property {String} 	svg = .svg file to display	| Default is "iconNames.add"
 *
 * @events
 * 	No events
 *
 * @callbacks
 * 	No callbacks
 *
 * @methods
 * 	No public methods
 *
 * @properties
 * 	No Properties
 *
 *	@slots
 * 	No slots
 * 
 * @styles				(Default: primary, medium, solid)
 * 	primary:			Primary colors used for the button
 * 	secondary:  	Secondary colors used for the button
 *		tertiary: 		Tertiary colors used for the button
 * @Sizes
 * 	small:			Small button
 * 	medium:			Medium size button
 * 	large:			Large size button
 * @Statuses
 * 	help: 			Changes the color to "help"
 * 	info:				Changes the color to "info"
 * 	success:			Changes the color to "success"
 * 	warning:			Changes the color to "warning"
 * 	error:			Changes the color to "error"
 * 
 * @example {Lit}
 * import { iconNames } from '@rps-wc/svg-icons';
 * import '@rps-wc/rps-svg';
 * 
 * 	<rps-svg svg="${iconNames.uploadSolid}">
 * 	</rps-svg>
 *
 * @example {React}
 * import { iconNames } from '@rps-wc/svg-icons';
 * import '@rps-wc/rps-svg';
 * 
 * return 	<rps-svg svg={iconNames.uploadSolid}>
 * 			</rps-svg>
 *
 */
import { SvgIcon } from './src/general/SvgIcon.js';
if (!window.customElements.get('rps-svg'))
	window.customElements.define('rps-svg', SvgIcon);

