import Button from '@odo/components/elements/button';
import { Flex } from '@odo/components/elements/layout';
import { NavItem } from '@odo/components/layouts/app-container';
import Filters from '@odo/components/search/filters';
import Dialog from '@odo/components/widgets/dialog';
import {
  type FilterInterface,
  useSearchEditorContext,
  useSearchFiltersContext,
  applyFilterDefault,
  initialFilters,
} from '@odo/contexts/search';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FaFilter as IconFilter,
  FaTimes as IconClose,
  FaCloudUploadAlt as IconUpload,
} from 'react-icons/fa';

const FilterActions = () => {
  const [showFiltersDialog, setShowFiltersDialog] = useState(false);
  const [showFiltersOnLoad, setShowFiltersOnLoad] = useState(true);

  const { isReady, filters, setFilters, isLoadingDeals } =
    useSearchFiltersContext();

  const activeFilters = useMemo(
    () => filters.filter(f => !!f.active),
    [filters]
  );

  const clearFilters = useCallback(
    () =>
      setFilters(filters => [
        ...filters.map(f => ({
          ...f,
          active: false,
          exact: initialFilters.find(i => i.key === f.key)?.exact || false,
          value: applyFilterDefault(f),
        })),
      ]),
    [setFilters]
  );

  /**
   * Automatically open the filters when ready.
   */
  useEffect(() => {
    if (activeFilters.length === 0 && showFiltersOnLoad && isReady) {
      setShowFiltersDialog(true);
      setShowFiltersOnLoad(false);
    }
  }, [activeFilters, showFiltersOnLoad, isReady]);

  return (
    <>
      <NavItem gap={1}>
        <Button
          hue="blue"
          variant="outlined"
          disabled={isLoadingDeals}
          onClick={() => setShowFiltersDialog(true)}
        >
          <IconFilter />
          Select filters
        </Button>

        {activeFilters.length > 0 && (
          <Button
            hue="grey"
            variant="flat"
            disabled={isLoadingDeals}
            onClick={clearFilters}
            circular
            px={1}
            py={1}
          >
            <IconClose size={14} />
          </Button>
        )}
      </NavItem>

      <Dialog
        title="Search Products"
        isOpen={showFiltersDialog}
        close={() => setShowFiltersDialog(false)}
      >
        <Filters
          filters={[...filters]}
          confirm={(nextFilters: FilterInterface[]) => {
            setShowFiltersDialog(false);
            setFilters([...nextFilters]);
          }}
          close={() => setShowFiltersDialog(false)}
        />
      </Dialog>
    </>
  );
};

const EditingActions = () => {
  const { isUploading, uploadChanges, changes, setChanges } =
    useSearchEditorContext();

  return (
    <NavItem>
      <Button
        hue="blue"
        variant="solid"
        disabled={isUploading || changes.length === 0}
        onClick={uploadChanges}
      >
        <IconUpload />
        Upload changes
        {changes.length > 0 && <>&nbsp;({changes.length})</>}
      </Button>

      {changes.length > 0 && (
        <Button
          hue="yellow"
          variant="outlined"
          disabled={isUploading}
          onClick={() => setChanges([])}
        >
          Clear changes
          <IconClose />
        </Button>
      )}
    </NavItem>
  );
};

const Toolbar = () => (
  <Flex flexDirection="row" gap={2}>
    <FilterActions />
    <EditingActions />
  </Flex>
);

export default Toolbar;
