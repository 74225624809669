/**
 * Async, Manually triggered Query hook that provides a similar mechanism to useMutation (In that we can await on the result at the callsite)
 */

import { useQuery } from '@apollo/client';

export const useManualQuery = (query, options = {}) => {
  const { refetch } = useQuery(query, { ...options, skip: true });

  const execute = variables => {
    return refetch(variables);
  };

  return execute;
};
