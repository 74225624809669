// Returns a date in a normalized YYYY-MM-dd 00:00:00 format
// Intl.DateTimeFormat only returns / separators for date by default.

const dateOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

export const sanitizeDate = date => {
  if (!(date instanceof Date) || isNaN(date)) return '';
  date = new Date(date);
  date.setHours(0, 0, 0, 0);
  let dateString = new Intl.DateTimeFormat('en-ZA', dateOptions).format(date);

  dateString = dateString.replace(/\//g, '-');
  return `${dateString} 00:00:00`;
};

export const toDateInputDate = date => {
  if (!(date instanceof Date) || isNaN(date)) return '';
  date = new Date(date);

  date.setHours(0, 0, 0, 0);
  let dateString = new Intl.DateTimeFormat('en-ZA', dateOptions).format(date);
  return dateString.replace(/\//g, '-');
};
