import type { ClientOptions } from 'urql';
import {
  createClient as createUrqlClient,
  cacheExchange,
  fetchExchange,
  errorExchange,
} from 'urql';
import { ODOGraphQLEndpoint as url } from 'config';
import { authErrorCallback, isAuthError } from '@odo/utils/graphql';
import { apiLogsExchange } from '@odo/data/api-logs/exchange';
import { error } from '@odo/utils/toast';
import { exportApiLogs } from '@odo/data/api-logs/cache';

const NETWORK_ERROR_TOAST_ID = 'generic-network-error';
const NETWORK_ERROR_MSG =
  'There seems to be an issue. Resume your ongoing task. If the error message persists, please contact support.';

// exported in case we need it elsewhere for extending
export const clientOptions: ClientOptions = {
  url,
  fetchOptions: { mode: 'cors', credentials: 'include' },
  exchanges: [
    cacheExchange,
    /**
     * NOTE: the errorExchange needs to be before the fetch in order to receive the results thereof.
     * This is because the exchanges run as a forwarding pipe, which then reverses and feeds back at the end.
     * Eg. first -> second -> third -> response -> third -> second -> first.
     * So in order to get the response data after the fetch, we need our errorExchange to be "above" it.
     * @see https://formidable.com/open-source/urql/docs/advanced/authoring-exchanges/#forward-and-return-composition
     */
    errorExchange({
      onError: ({ graphQLErrors: [firstGraphQLError], networkError }) => {
        if (networkError && networkError.name !== 'AbortError') {
          error(NETWORK_ERROR_MSG, {
            id: NETWORK_ERROR_TOAST_ID,
            messageOptions: {
              action: { label: 'Export Log', callback: exportApiLogs },
            },
          });
        }

        firstGraphQLError &&
          isAuthError(firstGraphQLError) &&
          authErrorCallback();
      },
    }),
    /**
     * Our custom API log/tracking exchange.
     * NOTE: must be right before the fetch due to how the exchange pipe works.
     */
    apiLogsExchange,
    fetchExchange,
  ],
};

const client = createUrqlClient(clientOptions);

/**
 * Custom function for creating client with extra config (eg. AbortSignal support).
 *
 * NOTE: passing an AbortSignal to client.query.fetchOptions doesn't seem to work.
 */
export const createClient = ({ signal }: { signal?: AbortSignal } = {}) =>
  createUrqlClient({
    ...clientOptions,
    fetch: (input: RequestInfo | URL, init?: RequestInit) =>
      fetch(input, { ...init, signal }),
  });

export default client;
