import { html } from 'lit';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { styles } from './css/tooltip.css.js';

export class Tooltip extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			text: { type: String },
		};
	}


	render() {
		return html`
		${this.addCss()}
			<div class="tooltip-container">
				<div class="slot">
					<slot>${this.text}</slot>
				</div>
			</div>
		`;
	}
}
