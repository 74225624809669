import { createReactWrapper } from '../util/createReactWrapper.jsx';
import PropTypes from 'prop-types';
const InternalRPSButtonStrip = createReactWrapper('rps-button-strip');
import React from 'react';

export const RPSButtonStrip = ({ selected, buttons, ...props }) => {
	return <InternalRPSButtonStrip {...props} buttons={buttons.map(b => ({ ...b, selected: b.id === selected }))} />
}

RPSButtonStrip.propTypes = {
	selected: PropTypes.any,
	buttons: PropTypes.arrayOf(PropTypes.object)
}
