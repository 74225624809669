const last10Columns = [
  { headerText: 'Cost', key: 'cost', data: { type: 'currency' } },
  { headerText: 'Price', key: 'price', data: { type: 'currency' } },
  { headerText: 'Retail', key: 'retail', data: { type: 'currency' } },
  {
    headerText: 'Weighted Average',
    key: 'weighted_average',
    data: { type: 'currency' },
  },
];

const last10Data = [];

export { last10Columns, last10Data };
