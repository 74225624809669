import type { ReactNode } from 'react';
import { useState, memo } from 'react';
import ProductContext from './context';
import type { Product } from './types';

const ProductProvider = ({ children }: { children: ReactNode }) => {
  const [activeProduct, setActiveProduct] = useState<Product | undefined>(
    undefined
  );

  return (
    <ProductContext.Provider value={{ activeProduct, setActiveProduct }}>
      {children}
    </ProductContext.Provider>
  );
};

export default memo(ProductProvider);
