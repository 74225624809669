/**
 * @name rps-search
 * @type {Search}
 * 	A generic search component, that displays like a dropdown, but selected items become chips.
 * 	Custom rendering for both the available and selected can be used.
 * 	@see {link /demo/complex/search.html} for an example
 *
 * @attributes + @properties 										| Changes trigger a re-render
 * Casing usages														| Attribute in html (lower case), Property in JS (Camel Case)
 *  	@property {String}	layout = "column"					| "row" or "column".
 * 																			display the lookup results as a column or a row
 * 	@property {Number}	maxItems = 10						| max amount of items to display in the 'dropdown'
 * 	@property {String}	placeholder = 'Filter string'	| The text to display in the placeholder
 * 	@property {String}	label = 'Search'					| The input label
 * 	@property {String}	removeTitle = 'Remove all'		| The title (tooltip) of the delete all button
 * 	@property {Array}		items									| An object in the array must contain a property "id" along with other desired properties (displayable or otherwise)
 * 																		| An array of objects.
 * 																			NB: The 'id' is required.
 * 																			If the class is specified then this allows for custom styling per element.
 * 																			All other properties are optional
 * 																			default= [
 * 																				{
 * 																					"id": 1,
 * 																					"firstName": "John",
 * 																					"lastName": "Smith",
 * 																					"email": "john.smith@redpandasoftware.co.za",
 * 																					"class": ""
 * 																				},
 * 																				{
 * 																					"id": 2,
 * 																					"firstName": "Mackie",
 * 																					"lastName": "Smith",
 * 																					"email": "mackie.smith@redpandasoftware.co.za",
 * 																					"class": ""
 * 																				},
 * 																				{
 * 																					"id": 3,
 * 																					"firstName": "Billy",
 * 																					"lastName": "Bob",
 * 																					"email": "billy.bob@redpandasoftware.co.za",
 * 																					"class": ""
 * 																				},
 * 																			];
 * 	@property {Object}	displayFunction					| A function callback that renders the items in the [to select. ie: dropdown].
 * 																			@see {link demo/complex/search.html for example}
 * 	@property {Object}	selectedDisplayFunction			| A function callback that renders the "selected" items in the [selected].
 * 																			@see {link demo/complex/search.html for example}
 * 																			If @selectedDisplayFunction is specified it will be used to render selected items.
 * 																			If not specified, but the @displayFunction is, then that will be used to render selected items
 * 	@property {Array}		displayItems						| The array of property names on the object used to render the items in the 'dropdown' and 'selected' items
 * 																			default = Object.keys(this.items[0]).filter(e => e != "id" && e != "class");
 * 																				ie: Display all properties other than the id and class
 * 																			In the example above a logical choice would be ['firstName','lastName']
 * 	@property {Array}		selectedItems						| All the items that have been selected.
 * 																			@NB Dont set this value. Use it to retrieve a list of the items that have been selected.
 * 	@property {Boolean}	required 							| Is a choice required
 * 	@property {Boolean} 	disabled 							| Is this control disabled?
 * 	@property {Boolean} 	readonly 							| Is this control readonly?
 * 	@property {Boolean} 	hideLabel 							| Must the label be hidden?
 * 	@property {Boolean}	singleSelection					| The user only make a single selection.
 * 	@property {String}	name 									| The form name
 * 	@property {Boolean}	caching 								| Does this control use Caching when reading and writing values
 * 	@property {String}	customCss 							| Specify a custom .css file to include, use cssPath to override elements,
 * 																			DO NOT override the styling definition by re-defining the css styling
 * 																			Put this file into the *window.cssPath* folder @see {link README.md}
 * 																			eg: /public/css (where the other css files are)
 * 																			Suggested use: use sub-folders, dont put it all into the base
 * 	@property {Boolean}	sortSelection						| Sort selected items alphabetically in the "selection box".
 * 																			NB: Only sorts if there is NO custom render as the custom render generates HTML that cant be use for sorting.
 * 	@property {String}	css									| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 *
 *
 * @properties (JS Only)
 * 	get inputControl												| A reference to the input control.
 * 	get chipsContainer											| A reference to the Chips container
 *
 * @styling classes
 * 	help 																| Displays the color in the "help color"
 * 	info																| Displays the color in the "info color"
 * 	success															| Displays the color in the "success color"
 * 	warning															| Displays the color in the "warning color"
 * 	error																| Displays the color in the "error color"
 *
 * @methods
 * 	setItems(items, newDisplayItems)							| Set the list of items to a totally new set if items, and re-render the control
 * 		@param {Array} items										| New items for the control
 * 		@param {Array} newDisplayItems 						| Optionally a new array of displayItems, if not passed then they are re-generated using the "items" 
 * 	setDisplayItems(newDisplayItems)							| Change the displayItems, and re-render the control
 *
 * @events
 * 	addItem															| Fires whenever an item is selected. (added to the selected items)
 * 	removeItem														| Fires whenever an item is un-selected. (removed from the selected items)
 * 	reset																| Fires whenever the input is reset (All chips removed)
 *
 * @callbacks
 * 	cbAddItem														| Called whenever an item is selected. (added to the selected items)
 * 	cbRemoveItem													| Called whenever an item is un-selected. (removed from the selected items)
 * 	cbReset															| Called whenever the input is reset (All chips removed)
 *
 * @slots
 * 	No slots
 *
 *
 * @example @Lit
 * @basic
 * ==============================
 * 	import '@rps-wc/wc/rps-search';
 *
 * 	const myItems = [
 * 		{ "id": 1, "firstName": "John", "lastName": "Smith", "email": "john.smith@redpandasoftware.co.za", "class": "" },
 * 		{ "id": 2, "firstName": "Mackie", "lastName": "Smith", "email": "mackie.smith@redpandasoftware.co.za", "class": "" },
 * 		{ "id": 3, "firstName": "Billy", "lastName": "Bob", "email": "billy.bob@redpandasoftware.co.za", "class": "" },
 * 		{ "id": 4, "firstName": "baby", "lastName": "Bob", "email": "billy.baby@redpandasoftware.co.za", "class": "" }
 * 	];
 *
 *
 * 	<rps-search
 * 		layout="column"
 * 		placeholder="users name"
 * 		maxItems=8
 * 		displayItems=${JSON.stringify(["firstName"])}
 * 		items=${JSON.stringify(myItems)}
 * 	>
 * 	</rps-search>
 *
 *
 * @example @Lit
 * @Advanced
 * @see {link /demo/complex/search.html} for an example
 *
*/

import { Search } from './src/search/Search.js';
if (!window.customElements.get('rps-search'))
	window.customElements.define('rps-search', Search);

