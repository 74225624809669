import { html } from 'lit';
import { unsafeSVG } from 'lit/directives/unsafe-svg.js';
import { CustomLitElement } from '../baseClasses/CustomLitElement';
import { styles } from './css/lineChart2d.css.js';
import { DOM } from '../functions/DOM';		// workaround as SVG components cant have click handler
window.domFunction = DOM;

export class LineChart2d extends CustomLitElement {
	static styles = styles;

	static get properties() {
		return {
			heading: { type: String },							// Heading for Chart
			data: { type: Array },								// Array of objects to display
			legend: { type: Array },							// Array of legends for the data
			colors: { type: Array },							// Array of colors to use for the lines
			yStep: { type: Number },							// every [x] elements should be used in the y-Axis
			xStep: { type: Number },							// every [x] elements should be used in the x-Axis
			yAxisLabel: { type: String },						// Label for x-axis
			xAxisLabel: { type: String },						// Label for y-axis
			circleRadius: { type: Number },					// The radius (size) of the line points
			xAxisPadding: { type: Number },					// Padding from the yAxis line to "pad". ie: start lines after this padding
			strokeWidth: { type: Number },					// the thickness of each line
			cbClick: { attribute: false },						// click callback
		};
	}

	constructor() {
		super();
		this.heading = "A Line Chart";
		this.data = [
			[
				[0, 120], [20, 60], [40, 80], [60, 20], [80, 80], [100, 80], [120, 60], [140, 100],
				[160, 90], [180, 80], [200, 110], [220, 10], [240, 70], [260, 100], [280, 100],
				[300, 40], [320, 0], [340, 100], [360, 100], [380, 120], [400, 60], [420, 70], [440, 80]
			],
			[
				[0, 50], [20, 95], [40, 65], [60, 105], [80, 80], [100, 77], [120, 43], [140, 90],
				[160, 60], [180, 50], [200, 70], [220, 90], [240, 70], [260, 60], [280, 110],
				[300, 120], [320, 70], [340, 100], [360, 88], [380, 120], [400, 35], [420, 120], [440, 25]
			],
		];
		this.legend = ["Sales 2021", "Sales 2022"];
		this.axisOffset = 50;
		this.yStep = 20;
		this.xStep = 2;
		this.max = 0;
		this.xAxisLabel = "X-Axis label";
		this.yAxisLabel = "Y-Axis label";
		this.circleRadius = 4;
		this.xAxisPadding = 10;
		this.strokeWidth = 2
		this.colors = ['blue', 'red', 'black', 'green', 'yellow', 'purple'];
	}

	/**
	 * Allows the changing of the data values after the chart has been rendered.
	 * @description - NB: When changing the data, the legend will also probably have to change.
	 * Dont forget it!
	 *
	 * @param {Array} newData - The New set of data to display
	 * @memberof BarChart
	 */
	setData(newData) {
		this.data = newData;
		this.requestUpdate();
	}

	/**
	 * Fire an event when an item is clicked on the chart.
	 * Returns the value and position of the chart value that was clicked
	 * @description - This method cant be called from itself.
	 * For some strange reason SVG elements cant have the normal event handlers in web-components
	 * Workaround is to fire a function, and find this control, and then invoke the method.
	 *
	 * @param {Object} control
	 * @memberof BarChart
	 */
	pointClick(control) {
		const detail = {
			source: this.tagName,
			value: control.getAttribute("value"),
			yValue: control.getAttribute("yvalue"),
			position: control.getAttribute("pos"),
		};
		const e = new CustomEvent('click', { detail, bubbles: true, composed: true, cancelable: true });
		this.dispatchEvent(e);
		if (this.cbClick) this.cbClick(e);
	}


	/**
	 * Calculate needed values for the chart to render correctly
	 *
	 * @memberof LineChart2d
	 */
	calcValues() {
		this.max = 0;
		// traverse all the data arrays and get the max value
		this.data.map(point => {
			point.map(elem => this.max = elem[1] > this.max ? elem[1] : this.max);
		}, this);

		// get the width between items to draw
		this.xStepElement = this.offsetWidth / (this.data[0].length + 1);
		this.yMultiplier = ((this.offsetHeight - this.axisOffset * 2) / (this.max)).toFixed(2) * 1;
	}


	/**
	 * Draw the X and Y axis
	 *
	 * @returns
	 * @memberof LineChart2d
	*/
	drawAxis() {
		const bottomLine = this.offsetHeight - this.axisOffset;
		const points = this.data[0];
		let xAxisString = '';

		// draw the values for the X-axis values
		for (let i = 0; i < points.length; i += this.xStep) {
			const elem = points[i];
			xAxisString += `<text x="${this.axisOffset + this.xAxisPadding + this.xStepElement * i}" y="${bottomLine + this.xAxisPadding + 5}">${elem[0]}</text>`;
		}

		// draw the values for the Y-axis values
		let yAxisString = '';
		for (let i = 0; i <= this.max; i += this.yStep) {
			const val = this.yMultiplier * i + this.axisOffset;
			yAxisString += `<text x="${this.axisOffset}" y="${val}">${this.max - i}</text>`;
		}

		// draw the lines and values for the Axis
		const axis = `
		<text x="10" y="10" class="label-title" style="margin-left: 1rem">${this.yAxisLabel}</text>
		<g class="grid x-grid" id="xGrid">
				<line x1="${this.axisOffset}" y1="5" x2="${this.axisOffset}" y2="${bottomLine}"></line>
			</g>
			<g class="grid y-grid" id="yGrid">
				<line x1="${this.axisOffset}" y1="${bottomLine}" x2="${this.offsetWidth}" y2="${bottomLine}"></line>
			</g>
				<g class="labels x-labels">
				${xAxisString}
			</g>
			<g class="labels y-labels">
				${yAxisString}
				<text x="${this.offsetWidth}" y="${this.offsetHeight - 10}" class="label-title">${this.yAxisLabel}</text>
			</g>
		`;

		return axis;
	}


	/**
	 * Draw a line on the chart
	 *
	 * @param {Array} points - Points with tooltip, and click event to draw on the line
	 * @param {Number} i -The position in the data array that is being rendered
	 * @returns
	 * @memberof LineChart2d
	*/
	drawLine(points, i) {
		const num = (i >= this.colors.length) ? this.colors.length / (i + 1) : i;
		const color = this.colors[num];

		const dataPoints = points.map((point, i) => {
			const val = (this.max - point[1]) * this.yMultiplier + this.axisOffset;

			return `
				<circle cx="${this.axisOffset + this.xAxisPadding + this.xStepElement * i}" cy="${val}" r="${this.circleRadius}"
					stroke="black" fill="${color}" pos=${i} value=${point[1]} yvalue=${point[0]}
					onclick="new window.domFunction().walkUp(this, 'rps-line-chart2d').pointClick(this)"
				>
					<title>${point[1]}</title>
				</circle>`
		}, this);
		const dataString = dataPoints.join('');

		const linePoints = points.map((point, i) => {
			const val = (this.max - point[1]) * this.yMultiplier + this.axisOffset;
			return `${this.axisOffset + this.xAxisPadding + this.xStepElement * i}, ${val}
			`;
		}, this);
		const lineString = linePoints.join('');

		return { lineString, dataString };
	}

	/**
	 * @description - SVG needs to be created with the "unsafeSvg() method"
	 *
	 * @param {array} changedProperties
	 * @memberof LineChart2d
	 */
	createSvg() {
		this.calcValues();

		const axis = this.drawAxis();
		const lineData = [];
		let lines = '';
		let dots = '';

		// create the HTML (lines and click points) for each line
		this.data.forEach((line, i) => {
			const { lineString, dataString } = this.drawLine(line, i);
			lineData.push({ lineString, dataString });
		});

		// draw each line and click points
		lineData.forEach((line, i) => {
			const num = (i >= this.colors.length) ? this.colors.length / (i + 1) : i;
			const color = this.colors[num];
			lines += `<polyline fill="none" stroke="${color}" stroke-width="${this.strokeWidth}"
					points="${line.lineString}"
				/>`
			dots += `${line.dataString}`;
		});

		return `<svg viewBox="0 0 ${this.offsetWidth} ${this.offsetHeight}" class="chart graph" version="1.2"
				aria-labelledby="title" role="img"
			>
			${axis}
				${lines}
				${dots}
			</svg>`;
	}


	/**
	 * Draw the legends for this chart.
	 * @description - Use an array of text values describing each line in the chart
	 * @example - chart.legend = ["Sales by month","Profit by month"];
	 *
	 * @returns
	 * @memberof LineChart2d
	*/
	drawLegend() {
		return html`
		<div class="chart-legend">
			${this.legend.map((legend, i) => {
			const num = (i >= this.colors.length) ? this.colors.length / (i + 1) : i;
			const color = this.colors[num];
			return html`<div style = "color:${color}">${legend}</div >`;
		})}
		</div>
		`;
	}


	render() {
		return html`
			${this.addCss()}

			<div class="chart-container">
				<div class="chart-header">
					<figure>
						<figcaption>${this.heading}</figcaption>
					</figure>
					${this.drawLegend()}
				</div>
				<div class="chart-body">
					${unsafeSVG(this.createSvg())}
				</div>
			</div>
		`;
	}
}

