import { html } from 'lit';
import { ref } from 'lit/directives/ref.js';
import { BaseInputControl } from '../baseClasses/BaseInputControl';
import { iconNames } from '../../svg-icons.js';
import '../../rps-svg.js';
import '../../rps-input-label.js';
import { styles } from './css/checkBox.css.js';

export class CheckBox extends BaseInputControl {
	static styles = styles;

	static get properties() {
		return {
			labelOnLeft: { type: Boolean },
		};
	}

	constructor() {
		super();
		this.name = "label";
		this.label = "Label";
		this.labelOnLeft = false;
	}

	render() {
		const alignment = this.labelOnLeft ? 'label-left' : 'label-right';
		const cache = (this.cache && this.cache.length > 0) ? JSON.parse(this.cache) : undefined;
		const initial = (cache) ? cache.value : this.checked;							// use cached value or checked
		const selected = initial ? initial : this.checked;								// allow for string and number

		return html`
			<div class="checkbox ${alignment} ${this.className}" ${ref(this.container)}>
				<input id="${this.name + 'Checkbox'}" type="checkbox" name="${this.name}" 
					@input=${this.inputChanged}
					${ref(this.inputCtrl)}
					?checked=${selected} ?required=${this.required} ?disabled=${this.disabled}>

				<span>
					<rps-svg class="checked" svg="${iconNames.checked}"></rps-svg>
					<rps-svg class="indeterminate" svg="${iconNames.indeterminate}"></rps-svg>
				</span>

				<rps-input-label class="inline" 
					${ref(this.labelCtrl)}
					?required=${this.required} ?disabled=${this.disabled} hide=${this.hideLabel}
					text="${this.label}"
				></rps-input-label>
			</div>
		`;
	}

}
