/**
 * @name rps-video
 * @type {Video}
 * A video player for videos
 * 
 * @attributes + @properties 				| Changes trigger a re-render
 * Casing usages:								| Attribute in html (lower case), Property in JS (Camel Case)
 * 	@property {String} src	 			| The video URL
 * 	@property {Boolean} needsIframe 	| Does this need to be inside an IFrame?
 * 	@property {String} label 			| The label for the video
 * 	@property {Array} videoSources 	| A list of video sources.
 * 													used to offer the same media content in multiple file formats 
 * 													in order to provide compatibility with a broad range of browsers 
 * 													given their differing support for image file formats and media file formats.
 * 												
 * 	@property {String} customCss		| Specify a custom .css file to include, use cssPath to override elements,
 * 													DO NOT override the styling definition by re-defining the css styling
 * 													Put this file into the *window.cssPath* folder @see {link README.md}
 * 													eg: /public/css (where the other css files are)
 * 													Suggested use: use sub-folders, dont put it all into the base
 * 	@property {String} css				| A css string to insert into the ShadowDOM. Use ".customCss" instead when the styling will be use more than once.
 * 	@property {Boolean} controls 		| If this attribute is present, the browser will offer controls to allow the user to control video playback, including volume, seeking, and pause/resume playback.
 * 													Only applicable if 'needsIframe' is NOT specified.
 * 	@property {String} crossOrigin	| Possible values are ['','anonymous','use-credentials']
 * 													@see {link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video#attr-crossorigin}
 * 												
 * 
 * @methods
 * 	None
 * 
 * @events
 * 	None
 * 
 * @callbacks
 * 	None
 * 
 * @slots
 * 	None
 * 
*/
import { Video } from './src/media/Video';
if (!window.customElements.get('rps-video'))
	window.customElements.define('rps-video', Video);

