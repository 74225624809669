import React from 'react';
import { PropTypes } from 'prop-types';

export class PortalErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorObject: {}, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, errorObject: { error } };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    this.setState({
      ...this.state,
      error,
      errorObject: {
        error,
        errorInfo,
      },
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <b style={{ color: 'red' }}>
            An error occurred in the following boundary:{' '}
            {this.props.boundary || 'unspecified'}
          </b>
          <p>Error: {JSON.stringify(this.state.error)}</p>
          <p>StackTrace: {JSON.stringify(this.state.errorObject)}</p>
        </div>
      );
    }

    return this.props.children;
  }
}

PortalErrorBoundary.propTypes = {
  children: PropTypes.any,
  boundary: PropTypes.string,
};
