import { html } from 'lit';
import { BaseInputControl } from '../baseClasses/BaseInputControl';
import '../../rps-input-label';
import '../../rps-button';
import '../../rps-svg';
import { styles } from './css/toolbar.css.js';
import { iconNames } from '../../svg-icons';

export class Toolbar extends BaseInputControl {
	static styles = styles;

	static get properties() {
		return {
			buttons: { type: Array },
			cbClick: { attribute: false },
		};
	}

	constructor() {
		super();
		this.buttons = [
			{ id: "search", svg: iconNames.search, title: "Search" }
		];
	}

	/**
	 * Click event
	 *
	 * @param {Event} event
	 * @memberof ButtonStrip
	 */
	_click(event) {
		event.stopPropagation();
		event.preventDefault();

		const detail = {
			event: 'click',
			source: this.tagName,
			buttonId: event.target.id,
			target: event.target,
		};
		const e = new CustomEvent('click', { detail, bubbles: true, composed: true, cancelable: true });
		this.dispatchEvent(e);
		console.debug('Toolbar: Click', e);

		if (this.cbClick) this.cbClick(e);
	}

	/**
	 * Create the toolbar items
	 *
	 * @return {Html} 
	 * @memberof Toolbar
	 */
	createButtons() {
		return this.buttons.map(elem => {
			return html`
				<div class="svg-container">
					<rps-svg id="${elem.id}" class="${this.className}" svg="${elem.svg}" title="${elem.title}" @click=${this._click}></rps-svg>
				</div>
			`;
		});
	}

	setActive(id) {
		const setAsSelected = this.renderRoot.querySelector(`#${id}`);
		const items = this.renderRoot.querySelectorAll('rps-svg');
		items.forEach(item => item.classList.toggle('selected', false));

		setAsSelected.classList.toggle('selected', true);
	}

	render() {
		return html`
			${this.addCss()}

			<div class="toolbar-container ${this.className}">
				${this.createButtons()}
			</div>
		`;
	}

}
